import React from 'react'
import { Dot } from '../styled'
import {
  Text
} from './styled'

const AdditionalDetail = ({ msg }) => {
  return (
    <>
      <Dot />
      <Text>{msg}</Text>
    </>
  )
}

export default AdditionalDetail

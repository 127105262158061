import React from 'react'
import {
  ActivityContentItem, ActivityContentList,
  ActivityFieldChange,
  ActivityFieldName, ActivityItemDate, ActivityItemEditMessage,
  ActivityItemWrapper
} from './index'
import {
  areValuesEqual,
  getEditMessage,
  getFieldChange
} from './activityPanelUtils'
import {
  getDateTimeWithComma,
  getTimeAgo
} from '../../../utilities/dateTimeUtil'
import { useAuth0 } from '../../Auth0Provider/Auth0Provider'
import { has, xor } from 'lodash'

const ActivityItem = ({ activity, nextActivity, activityIndex, activitiesCount }) => {
  const {
    fields: {
      author_email: author,
      editor_email: editor,
      updated_time: date,
      old_val: fields,
      comment
    } = {}
  } = activity || {}
  const {
    fields: {
      old_val: nextChangedFields
    } = {}
  } = nextActivity || {}
  const firstActivity = activitiesCount > 1 && activityIndex === 0

  const type = author ? 'comment' : 'edit'
  const { user: { email: currentUserEmail } = {} } = useAuth0() || {}
  const editMessage = getEditMessage({
    type,
    editor,
    author,
    currentUserEmail
  })

  const getTimeAgoFromDate = getTimeAgo(date)
  const dateText = getTimeAgoFromDate || getDateTimeWithComma(date)

  if (type === 'edit') {
    const accessorsList = Object.keys(fields || {})
    if (editor === undefined) return null
    const createdByFieldChange = fields?.['created_by']?.email
    return (
      <ActivityItemWrapper>
        <ActivityItemEditMessage>{editMessage}</ActivityItemEditMessage>
        <ActivityItemDate>{dateText}</ActivityItemDate>
        <ActivityContentList>
          {accessorsList.map((accessor, index) => {
            const shouldSkipField =
              (fields && !has(fields, accessor)) ||
              (nextChangedFields && !has(nextChangedFields, accessor)) ||
              areValuesEqual(fields?.[accessor], nextChangedFields?.[accessor])
            const fieldChange = getFieldChange(fields?.[accessor])
            const nextFieldChange = getFieldChange(
              nextChangedFields?.[accessor]
            )
            const isArray = Array.isArray(fieldChange)
            const isObject =
              typeof fields?.[accessor] === 'object' && !isArray

            if (shouldSkipField || isObject) return null

            if (isArray) {
              const changes = xor(
                fieldChange,
                nextFieldChange
              )

              return changes.map((value, idx) => (
                <ActivityContentItem key={idx}>
                  {idx === 0 && <ActivityFieldName>{accessor}</ActivityFieldName>}
                  <ActivityFieldChange>{value?.id ?? value}</ActivityFieldChange>
                </ActivityContentItem>
              ))
            }

            return (
              <ActivityContentItem key={index}>
                <ActivityFieldName>{accessor}</ActivityFieldName>
                <ActivityFieldChange>
                  {firstActivity ? nextFieldChange : fieldChange}
                </ActivityFieldChange>
              </ActivityContentItem>
            )
          }
          )}
          {createdByFieldChange && (
            <ActivityContentItem>
              <ActivityFieldName>created_by</ActivityFieldName>
              <ActivityFieldChange>{createdByFieldChange}</ActivityFieldChange>
            </ActivityContentItem>
          )}
        </ActivityContentList>
      </ActivityItemWrapper>
    )
  }
  if (type === 'comment') {
    return (
      <ActivityItemWrapper>
        <ActivityItemEditMessage>{editMessage}</ActivityItemEditMessage>
        <ActivityItemDate>{dateText}</ActivityItemDate>
        <ActivityContentList>
          {comment}
        </ActivityContentList>
      </ActivityItemWrapper>
    )
  }
  return null
}

ActivityItem.displayName = 'ActivityItem'
export default ActivityItem

import styled, { css } from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import Select from 'react-select'
import {
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import checkMark from '../../../assets/icons/check-mark.svg'

export const Container = styled.div`
  padding: 64px 64px 0 36px;
  margin: 0 auto;
  ${mediaqueries.desktop`
    min-width: 100%;
    max-width: 100%;
  `}
  ${mediaqueries.mobile`
    padding: 32px 32px 32px 24px;
  `}
`

export const Title = styled.h1`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--border-primary);
  font-weight: 300;
  font-size: 32px;
  text-transform: uppercase;
  color: var(--text-primary);
`

export const CustomFormGroup = styled(FormGroup)`
  width: 400px;

  ${mediaqueries.mobile`
    width: 100%;
  `}
`

export const CustomLabel = styled(Label)`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
`

export const inputStyles = css`
  min-height: 40px;
  height: 40px;
  margin-bottom: ${({ reduced }) => reduced ? '15px' : '24px'};
  background-color: var(--input-primary);
  border: 1px solid ${props => props.error
    ? 'var(--error-color)' : 'var(--border-primary)'};
  color: var(--text-primary);
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  &:disabled {
    background-color: var(--input-primary);
    border: 1px solid var(--border-primary);
    color: var(--text-primary);
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    box-shadow: var(--shadow-primary);
  }
  &:focus, &:hover:not(:disabled) {
    border: 1px solid var(--brand-regular);
    background-color: var(--input-primary);
    color: var(--text-primary);
    box-shadow: none;
  }
`

export const CustomInput = styled(Input)`
  ${inputStyles}
`

export const CustomSelect = styled(Select)`
  min-height: 40px;
  margin-bottom: ${({ reduced }) => reduced ? '15px' : '24px'};
  background-color: var(--input-primary);

  & > .select__control {
    width: 100%;
    height: auto;
    min-height: 40px;

    &.select__control--is-focused {
      border: 1px solid var(--brand-regular) !important;
    }

    .select__indicators {
      border-left: 1px solid var(--border-primary);
      padding-left: 7px;
      margin: 3px auto;

      .select__clear-indicator {
        padding-left: 1px;
      }
    }
  }
`

export const CustomCheckBox = styled.label`
  display: block;
  width: max-content;
  position: relative;
  padding-left: 36px;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* hide the default checkbox */
  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }
  /* create a custom radio button */
  span {
    position: absolute;
    top: -3px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #161618;
    border: ${props => props.disabled ? '2px solid #5E636B' : '2px solid #5FC4B8'};
    box-sizing: border-box;
    border-radius: 5px;
  }
  /* When the checkbox is checked */
  input:checked ~ span {
    background-color: #5FC4B8;
    border: 3px solid #5FC4B8;
    box-sizing: border-box;
    border-radius: 5px;
  }
  /* Create the check-mark/indicator */
  span::after {
    display: none;
    position: absolute;
    content: url(${checkMark});
  }
  /* Show the check-mark when checked */
  input:checked ~ span::after {
    left: 2px;
    display: block;
  }
`

export const ErrorText = styled.span`
  float: left;
  margin-top: -19px;
  margin-bottom: -2px;
  font-size: 14px;
  color: var(--error-color);
`

import createAuth0Client from '@auth0/auth0-spa-js'

let client

export default getClient

async function getClient () {
  if (client) return client

  client = await createAuth0Client({
    domain: process.env.REACT_APP_DOMAIN,
    client_id: process.env.REACT_APP_CLIENT_ID,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    responseType: 'token id_token'
  })

  client.getIdToken = async function () {
    await client.getTokenSilently()
    const { cache } = client.cache
    return cache[Object.keys(cache)[0]].id_token
  }

  return client
}

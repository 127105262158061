import React, { useState } from 'react'
import { Tab, TabsContainer } from './styles'
import PendingApproval from './PendingApproval'
import FlagsAndReports from './FlagsAndReports'

const TABLES = {
  pendingApproval: 'pendingApproval',
  flagsAndReports: 'flagsAndReports'
}

function AcademicIntegrityTables () {
  const [activeTable, setActiveTable] = useState(TABLES.pendingApproval)
  const [totalPendingApprovals, setTotalPendingApprovals] = useState('-')

  const isReviewTable = activeTable === TABLES.pendingApproval
  const isApprovedTable = activeTable === TABLES.flagsAndReports

  const changeTab = (table) => {
    if (activeTable === table) return
    setActiveTable(table)
  }

  return (
    <>
      <TabsContainer>
        <Tab
          isActive={isReviewTable}
          onClick={() => changeTab(TABLES.pendingApproval)}
        >
          Pending approval ({totalPendingApprovals})
        </Tab>

        <Tab
          isActive={isApprovedTable}
          onClick={() => changeTab(TABLES.flagsAndReports)}
        >
          Flags & reports
        </Tab>
      </TabsContainer>
      {activeTable === TABLES.pendingApproval && (
        <PendingApproval setTotalPendingApprovals={setTotalPendingApprovals} />
      )}
      {activeTable === TABLES.flagsAndReports && <FlagsAndReports />}
    </>
  )
}

export default AcademicIntegrityTables

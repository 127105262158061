export const PRACTICE_EXAM = 'Practice Exam'
export const MIDTERM_EXAM_1 = 'Midterm Exam 1'
export const MIDTERM_EXAM_1A = 'Midterm Exam 1a'
export const MIDTERM_EXAM_2 = 'Midterm Exam 2'
export const MIDTERM_EXAM_2A = 'Midterm Exam 2a'
export const FINAL_EXAM = 'Final Exam'
export const FINAL_EXAM_A = 'Final Exam a'
export const SINGLE_MIDTERM_EXAM = 'Midterm Exam'
export const VERSIONS = 'abcdefghijklmnopqrstuvwxyz'
export const VERSION_B = 'Version B'

// 39 week cohorts
export const EXAM_1 = 'Exam 1'
export const EXAM_2 = 'Exam 2'
export const EXAM_3 = 'Exam 3'
export const EXAM_4 = 'Exam 4'
export const FINAL_EXAM_I = 'Final Exam Part I'
export const FINAL_EXAM_II = 'Final Exam Part II'

export const EXAM_DATE_KEYS = [
  {
    start: 'midTerm1StartTime',
    end: 'midTerm1EndTime',
    extension: 'midterm1DeadlineExt',
    keyOverride: 'midTerm1KeyOverride'
  },
  {
    start: 'midTerm2StartTime',
    end: 'midTerm2EndTime',
    extension: 'midterm2DeadlineExt',
    keyOverride: 'midTerm2KeyOverride'
  },
  {
    start: 'exam3StartTime',
    end: 'exam3EndTime',
    extension: 'exam3DeadlineExt',
    keyOverride: 'exam3KeyOverride'
  },
  {
    start: 'exam4StartTime',
    end: 'exam4EndTime',
    extension: 'exam4DeadlineExt',
    keyOverride: 'exam4KeyOverride'
  },
  {
    start: 'exam5StartTime',
    end: 'exam5EndTime',
    extension: 'exam5DeadlineExt',
    keyOverride: 'exam5KeyOverride'
  },
  {
    start: 'finalExamStartTime',
    end: 'finalExamEndTime',
    extension: 'finalDeadlineExt',
    keyOverride: 'finalExamKeyOverride'
  }
]

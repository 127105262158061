import styled, { css } from 'styled-components'
import { Input } from 'reactstrap'
import mediaqueries from '../../../../mediaQueries'
import CustomSelect from '../../../CustomSelect/UpdatedSelect'
import { PrimaryButton, Separator } from '../../../global.styled'

export const Container = styled.div`
  padding: 0 24px 64px;

  ${mediaqueries.desktop`
    padding-bottom: 48px;
  `}

  ${mediaqueries.tablet`
    padding-bottom: 0;
  `}
`

export const GoToPreviousPageButtonWrapper = styled.div`
  padding: 16px 0 16px;
`

export const Header = styled.h2`
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--border-secondary);
  margin-bottom: 24px;
`

export const HeaderDescription = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
`

export const PartnerDetailsContainer = styled.div`
  padding: 32px 0;
  border-bottom: 1px solid var(--border-secondary);
  margin-bottom: 32px;
`

export const PartnerDetailsTitle = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 16px;
`

export const PartnerDetailsContent = styled.div`
  padding-top: 12px;
  display: flex;
  gap: 48px;

  ${mediaqueries.tablet`
    flex-direction: column;
    gap: 32px;
  `}
`

export const PartnerSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const PartnerDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const PartnerDetailsSectionTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
`

export const PartnerSelectError = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #FF8064;
`

export const PartnerSelect = styled(CustomSelect)`
  width: 400px;
  min-height: 48px;

  & > .select__control {
    min-height: 48px;
    ${props => props.showError && `
      border: 1px solid #FF8064;
    `}
  }

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`

export const PartnerAboutSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const AboutTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: var(--sub-text-secondary);
  margin-bottom: 8px;
  display: flex;
`

export const AboutContent = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  display: flex;
  flex-wrap: wrap;
`

export const AboutContentListItem = styled.div`
  display: flex;
  align-items: center;
`

export const ToolTipContainer = styled.div`
  margin-left: 8px;
  position: relative;
  display: flex;
  &:hover {
    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const ToolTip = styled.span`
  visibility: hidden;
  width: 255px;
  height: 72px;
  padding: 12px;
  position: absolute;
  left: 29px;
  line-height: 24px;
  color: #FFFFFF;
  text-transform: none;
  font-size: 16px;
  font-weight: normal;
  background-color: #25272B;
  top: 50%;
  transform: translateY(-50%);
  letter-spacing: normal;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
`

export const Dot = styled.div`
  display: inline-block;
  margin: 0 8px;
  height: 2px;
  width: 2px;
  border-radius: 2px;
  background: #98A4AE;
`

export const ToastHeader = styled.div`
  display: flex;
  gap: 8px;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`

export const ToastMessage = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;

  a {
    color: var(--brand-regular);
    text-decoration: underline;
  }
`

export const AvailableCoursesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const AvailableCoursesTitle = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
`

export const CourseFilterContainer = styled.div`
  padding-bottom: 6px;
  display: flex;
  gap: 24px;
`

export const SmallFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const SmallFilterLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`

export const CourseSelect = styled(CustomSelect)`
  width: 327px;
  min-height: 48px;

  & > .select__control {
    min-height: 48px;
  }

`

export const LargeFilterContainer = styled.div`
  display: flex;
  gap: 24px;
`

export const LargeFilterItem = styled.div`
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 12px;

  color: ${({ selected }) =>
    selected ? 'var(--text-primary)' : 'var(--text-dark-off)'};
  ${({ selected }) => selected && 'border-bottom: 2px solid white;'}
`

export const CourseListContainer = styled.div`
  display: flex;
  gap: 24px;

  ${mediaqueries.tablet`
    flex-direction: column;
  `}
`

export const CourseListTable = styled.div`
  height: 596px;
  overflow-y: scroll;
  padding-right: 5px;
  margin-right: 5px;
  border-bottom: 1px solid var(--border-secondary);
  flex: 1 0 0;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    height: 557px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: var(--border-secondary);
  }

  ${mediaqueries.tablet`
    flex: 0 1 auto;
  `}

  ${mediaqueries.smallMobile`
    height: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  `}
`

export const CourseListTableHeader = styled.div`
  display: flex;
  gap: 24px;
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border-secondary);
`

export const HeaderCourse = styled.div`
  flex: 1 0 0;
`

export const HeaderDuration = styled.div`
  width: 81px;
`

export const HeaderLicenses = styled.div`
  width: 112px;
`

export const CourseListTableRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  padding: 12px 0;
  border-bottom: 1px solid rgba(255,255,255, 0.15);
`

export const RowCourse = styled.div`
  flex: 1 0 0;
`

export const RowDuration = styled.div`
  width: 70px;
  color: var(--text-dark-off);
  text-align: right;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-left: 16px;
  margin-right: 24px;
`

export const RowLicenses = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const RowLicenseImg = styled.img`
  cursor: pointer;
  ${props => props.isDisabled && css`
    pointer-events: none;
    opacity: 0.3;
  `}
`

export const inputStyles = css`
  height: 38px;
  background-color: var(--black-tertiary);
  border: 1px solid var(--border-secondary);
  border-radius: 5px;
  padding: 6px;
  width: 48px;
  text-align: center;
  color: var(--text-primary);
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  &:disabled {
    background-color: var(--input-primary);
    border: 1px solid var(--border-primary);
    box-shadow: var(--shadow-primary);
  }
  &:focus, &:hover:not(:disabled) {
    border: 1px solid var(--brand-regular);
    background-color: var(--input-primary);
    color: var(--text-primary);
    box-shadow: none;
  }
`

export const RowLicenseCount = styled(Input)`
  ${inputStyles}
`

export const SelectedCoursesContainer = styled.div`
  width: 445px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  border: 1px solid var(--border-secondary);
  border-radius: 5px;

  ${mediaqueries.tablet`
    width: 100%;
  `}
`

export const SelectedCoursesTitle = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
`

export const EmptyCoursesList = styled.div`
  flex: 1 0 0;
  align-self: stretch;

  font-size: 18px;
  font-weight: 300;
  line-height: 24px;

  min-height: 60px;
`

export const SelectedCoursesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-self: stretch;
  align-items: flex-start;
  padding-bottom: 24px;
`

export const SelectedCourse = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  align-self: stretch;

  font-size: 16px;
  font-weight: 400;
  line-height: normal;
`

export const SelectedCourseName = styled.div`
  flex: 1 0 0;
  color: var(--sub-text-secondary);

  span {
    color: var(--text-dark-off);
  }
`

export const SelectedCoursesCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
`

export const SelectedCoursesCountTitle = styled.div`
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const SelectedCoursesCount = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
`

export const ButtonSeparator = styled(Separator)`
  width: 100%;
  height: 1px;
  background-color: var(--border-primary);
  margin: 16px 0;
`

export const SubmitError = styled.div`
  padding: 19px 16px;
  border-radius: 5px;
  background: #351915;

  display: flex;
  gap: 16px;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`

export const SubmitButton = styled(PrimaryButton)`
  width: 100%;
`

import styled from 'styled-components'

export const Container = styled.div`
  .navbar {
    background: var(--canvas-dark);
    padding: 0 24px;
    height: 64px;
  }
  @media (max-width: 576px){
    .navbar-nav{
      flex-direction: row;
    } 
  }

  @media (min-width: 576px){
    .navbar-nav{
      flex-direction: row;
    }
  }
  
  .admin-logo {
    padding: 0;
    img{
       height: 24px;
    }
  }
`

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ModalComponent from '../ModalComponent/ModalComponent'
import './EditGradeModal.css'
import {
  EditGRadeWrapper,
  InputContainer, InputLabel,
  PrimaryButton, QuestionStatus,
  SecondaryButton, SectionTitle, TextError,
  TextInput
} from './styles'
import { isValidUrl } from '../../utilities/gradeUtils'
import CustomSelect from '../CustomSelect/UpdatedSelect'
import { ButtonContainer } from '../DatabaseViewer/DBViewerRedesign/styles'

const changeReasons = [
  {
    value: 'Academic integrity violation',
    label: 'Academic integrity violation'
  },
  {
    value: 'Ambiguous question or multiple choice answer',
    label: 'Ambiguous question or multiple choice answer'
  },
  {
    value: 'Answer format discrepancy',
    label: 'Answer format discrepancy'
  },
  {
    value: 'Answer was incorrect in the backend',
    label: 'Answer was incorrect in the backend'
  },
  {
    value: 'Frontend bug or display error',
    label: 'Frontend bug or display error'
  },
  {
    value: 'Question content was not yet covered in course',
    label: 'Question content was not yet covered in course'
  },
  {
    value: 'Student submitted correct, equivalent answer',
    label: 'Student submitted correct, equivalent answer'
  },
  {
    value: 'Answer format requirements were not clear',
    label: 'Answer format requirements were not clear'
  },
  {
    value: 'Student error',
    label: 'Student error'
  },
  { value: 'Other', label: 'Other' }
]

const selectReason = 'Please select a reason for this change'

function EditGradeModalV2 (
  {
    setShowGradeEditModal,
    showEditGradeModal,
    changes,
    setChanges,
    question
  }) {
  const [questionChanges] = useState(changes.find(change => change.questionUuid === question.Question_uuid) || {})
  const [notes, setNotes] = useState(questionChanges.note || '')
  const [zendeskUrl, setzendeskUrl] = useState(questionChanges.zendeskUrl || '')
  const [reason, setReason] = useState(questionChanges.reason || selectReason)
  const [error, setError] = useState(false)
  const [urlError, setUrlError] = useState(false)

  const checkFields = () => {
    if ((notes === '' && reason === 'Other') || zendeskUrl === '' || reason === selectReason) return setError(true)
    setError(false)
    if (!isValidUrl(zendeskUrl)) return setUrlError(true)
    if (notes !== '') {
      setChanges({
        questionUuid: question.Question_uuid,
        correct: !question.correct,
        note: notes,
        zendeskUrl,
        reason
      })
    } else {
      setChanges({
        questionUuid: question.Question_uuid,
        correct: !question.correct,
        zendeskUrl,
        reason
      })
    }
    setShowGradeEditModal(false)
  }

  const { correct } = question
  const isDisabled =
    !zendeskUrl ||
    !changeReasons.includes(reason)

  return (
    <ModalComponent
      title={'Modify question results'}
      show={showEditGradeModal}
      handleClose={() => setShowGradeEditModal(false)}
    >
      <EditGRadeWrapper>
        <SectionTitle>Result</SectionTitle>
        <QuestionStatus correct={correct}>
          The current recorded result is <span>{correct ? 'Correct' : 'Incorrect'}</span>
        </QuestionStatus>
        <QuestionStatus correct={!correct}>
          Saving this change will update the recorded result
          to <span>{correct ? 'Incorrect' : 'Correct'}</span>
        </QuestionStatus>
        <InputContainer>
          <InputLabel>
            Change Reason
          </InputLabel>
          {error && reason === selectReason &&
            <TextError>Select a reason for the change</TextError>}
          <CustomSelect
            value={reason}
            placeholder={'Select a reason for this change'}
            options={changeReasons}
            onChange={(e) => {
              setReason(e)
            }}
            width={'100%'}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Zendesk ticket</InputLabel>
          {error && zendeskUrl === '' &&
            <TextError>Add a Zendesk Ticket URL</TextError>}
          {!error && urlError &&
            <TextError> Add a Valid Zendesk Ticket URL</TextError>}
          <TextInput
            placeholder={'Paste ticket URL'}
            isValid={!((error && zendeskUrl === '') || urlError)}
            width={'100%'}
            value={zendeskUrl}
            onChange={(e) => {
              setzendeskUrl(e.target.value)
            }}
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>Notes</InputLabel>
          {error && (notes === '' && reason === 'Other') &&
            <div className='text-error'>Add a note about the change</div>}
          <TextInput
            placeholder={'Internal notes'}
            isValid={!(error && (notes === '' && reason === 'Other'))}
            width={'100%'}
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value)
            }}
          />
        </InputContainer>
      </EditGRadeWrapper>

      <ButtonContainer>
        <PrimaryButton
          className='btn-custom btn-primary button'
          disabled={isDisabled}
          onClick={checkFields}
        >
          SUBMIT
        </PrimaryButton>
        <SecondaryButton
          className='btn-custom btn-secondary button'
          onClick={() => setShowGradeEditModal(false)}
        >
          CANCEL
        </SecondaryButton>
      </ButtonContainer>
    </ModalComponent>
  )
}

EditGradeModalV2.propTypes = {
  setShowGradeEditModal: PropTypes.func.isRequired,
  showEditGradeModal: PropTypes.bool.isRequired,
  changes: PropTypes.array,
  setChanges: PropTypes.func.isRequired
}

export default EditGradeModalV2

import styled from 'styled-components'

export const EditorWrapper = styled.div`
  width: 100%;
  .quill {
    
    .ql-container {
      height: auto;
      border-color: #606060;
      border-radius: ${props => props.disabled ? '5px' : '0px 0px 5px 5px'};

      .ql-editor {
        height: 92px;
        resize: vertical;
      }
    }
  }

  .ql-toolbar {
    border: 1px solid #606060;
    border-radius: 5px 5px 0px 0px;
    height: 36px;
    padding: 4px 2px;
    
    .ql-fill {
      fill: #fff;
    }

    .ql-formats {
      height: 32px;
      margin: 0px;
      padding: 2px 0px;

      .ql-header {
        width: 90px;

        .ql-picker-options {
          background-color: #000;
          color: #fff;
          margin-top: 5px;
        }
      }

      .ql-stroke {
        stroke: #fff;
        stroke-width: 1.5px;
      }
      
      .ql-picker-label {
        color: #fff;
        margin-top: 2px;
        &:hover {
          .ql-stroke {
            stroke: rgba(255, 255, 255, 0.6);
          }
        }
      }
      
      button {
        margin: 0px 2px 0px 0px;
        padding: 0px 0px 0px 4px;
        &:hover {
          .ql-stroke, .ql-fill {
            stroke: rgba(255, 255, 255, 0.6);
            fill: transparent;
          }
        }
      }
      button.ql-active {
        background-color: #606060;
        border-radius: 4px;
      }
      button.ql-active .ql-fill {
        fill: #FFF;
      }

      .ql-active .ql-stroke {
        stroke: #FFF;
      }
    }

    .ql-picker-item:hover, .ql-picker-item:active {
      color: #606060;
    }

    .ql-picker-item.ql-selected {
      color: #fff
    }

    button svg {
      height: 22px;
    }
  }

  .ql-snow .ql-picker-options .ql-picker-item {
      font-size: 12px; 
      &:active {
        color: rgba(255, 255, 255, 0.6);
      }
  }
`

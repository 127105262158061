import React from 'react'
import unselectedIcon from '../../assets/icons/unselected.svg'
import selectedIcon from '../../assets/icons/selected.svg'
import {
  AccountDetailsItemContainer,
  DetailsTitleContainer,
  DetailsTitle,
  DetailsTitleIcon,
  DetailsItemContent,
  DetailsItemContentSeparator,
  DetailsItemContentTitle,
  DetailsItemContentValue
} from './styled'

function AccountDetails (props) {
  const {
    accountTitle,
    email,
    primaryAccount,
    setPrimaryAccount,
    studentId,
    numberOfAttemptRecords,
    doesAccountContainProgress
  } = props

  const isPrimaryAccount = primaryAccount === accountTitle
  const hasProgress = doesAccountContainProgress === undefined
    ? '--'
    : doesAccountContainProgress
      ? 'Yes'
      : 'No'

  const handleClick = () => {
    setPrimaryAccount(accountTitle)
  }

  return (
    <AccountDetailsItemContainer
      isPrimaryAccount={isPrimaryAccount}
      onClick={handleClick}
    >
      <DetailsTitleContainer>
        <DetailsTitle>
          {accountTitle}
        </DetailsTitle>
        <DetailsTitleIcon
          src={isPrimaryAccount ? selectedIcon : unselectedIcon}
        />
      </DetailsTitleContainer>
      <DetailsItemContent>
        <DetailsItemContentTitle>
          Email
        </DetailsItemContentTitle>
        <DetailsItemContentValue>
          {email || '--'}
        </DetailsItemContentValue>
      </DetailsItemContent>
      <DetailsItemContent>
        <DetailsItemContentTitle>
          Student Id
        </DetailsItemContentTitle>
        <DetailsItemContentValue>
          {studentId || '--'}
        </DetailsItemContentValue>
      </DetailsItemContent>
      <DetailsItemContentSeparator />
      <DetailsItemContent>
        <DetailsItemContentTitle>
          Number of attempt records
        </DetailsItemContentTitle>
        <DetailsItemContentValue>
          {numberOfAttemptRecords ?? '--'}
        </DetailsItemContentValue>
      </DetailsItemContent>
      <DetailsItemContent>
        <DetailsItemContentTitle>
          Does the account contain progress?
        </DetailsItemContentTitle>
        <DetailsItemContentValue>
          {hasProgress}
        </DetailsItemContentValue>
      </DetailsItemContent>
    </AccountDetailsItemContainer>
  )
}

export default AccountDetails

import styled, { css } from 'styled-components'
import mediaqueries from '../../mediaQueries'
import { SecondaryButton } from '../../Components/global.styled'

// PAGE

export const PageWrapper = styled.div`
  padding: 48px 24px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 32px;

  h1, h2, p {
    margin: 0;
  }
`

export const PageTitle = styled.h1`
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  color: var(--text-primary);
`

// SECTIONS

export const TitleWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
`

export const SectionTitle = styled.h2`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--text-primary);
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${props => props.gap || '8'}px;
  padding: ${props => props.padding};
`

export const InputWrapper = styled(SectionWrapper)`
  width: 350px;

  @media (max-width: 375px) {
    width: 100%;
  }
`

// TEXT

export const NormalText = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`

export const SelectedText = styled.p`
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;

  > span {
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px;
    margin-left: 12px;
  }
`

export const SummaryText = styled.div`
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 8px;

  max-width: 500px;
  border-radius: 5px;
  background: var(--black-secondary);

  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;

  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--sub-text-secondary);
  }

  b {
    font-weight: 400;
  }
`

// BUTTONS

export const EditButton = styled.span`
  color: var(--brand-regular);
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  user-select: none;

  &:hover {
    color: var(--brand-regular-hover);
  }

  &:active {
    color: var(--brand-regular-active);
  }
`

export const ButtonsWrapper = styled.div`
  width: fit-content;
  display: flex;
  gap: 12px;
  padding-top: 32px;
`

export const BackButton = styled(SecondaryButton)`
  width: fit-content;
  background-color: var(--content-background-color);

  &:hover, &:focus, &:active {
    background-color: var(--content-background-color) !important;
  }
`

// LINES

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--border-primary);
`

export const PaleLine = styled.div`
  width: 100%;
  height: 1px;
  opacity: .1;
  background-color: #FFF;
`

export const SecondaryBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--border-secondary);
`

// FILTERS

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 24px 16px;
  flex-wrap: wrap;
  align-items: flex-end;

  ${mediaqueries.smallMobile`
    flex-direction: column;
    align-items: flex-start;
  `}
`

export const Label = styled.div`
  span {
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 8px;
    color: var(--sub-text-secondary);
  }

  ${mediaqueries.smallMobile`
    > div {
      > div {
        width: 350px;
      }
    }
  `}

  @media (max-width: 375px) {
    width: 100%;
    > div {
      width: 100%;
      > div {
        width: 100%;
      }
    }
  }
`

// STUDENTS TABLE

export const StudentsTableWrapper = styled.div`
  padding: 0 16px;
`

export const Row = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${props => !props.isStudentRow && '8px'};
  padding: ${props => props.isStudentRow ? '8px 0px' : '24px 0px 20px'};
  border-bottom: ${props => !props.isLastItem && '1px solid var(--gray-primary)'};

  ${props => props.isDisabled && css`
    opacity: .5;
    pointer-events: none;
  `}

  @media (max-width: 375px) {
    padding: 16px 0px;
  }
`

export const RowContent = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 375px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
  }
`

export const HeaderCell = styled.div`
  position: relative;
  width: ${props => props.isEmail ? '320px' : props.isReview ? '100%' : '180px'};
  height: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--text-secondary);
  overflow-wrap: anywhere;

  ${mediaqueries.mobile`
    width: ${props => props.info ? '156' : props.isEmail ? '240' : '160'}px;
  `}

  ${mediaqueries.smallMobile`
    width: ${({ isEmail, isReview, info }) =>
    isEmail ? '200px' : info ? '114px' : isReview ? '100%' : '100px'};
  `}

  @media (max-width: 490px) {
    ${props => props.isName && css`
      display: none;
    `}
  }

  @media (max-width: 375px) {
    width: ${props => props.info ? '90px' : '100%'};
  }
`

export const StudentCell = styled(HeaderCell)`
  height: 40px;
  font-weight: 400;
  font-size: ${props => props.isNew ? '18' : '16'}px;
  line-height: normal;
  display: flex;
  align-items: center;
  text-transform: none;
  color: ${props => props.isNew || !props.info
    ? 'var(--white)' : 'var(--sub-text-secondary)'};
  ${props => props.strike && css`
    text-decoration: line-through;
  `}

  ${mediaqueries.smallMobile`
    height: fit-content;
  `}
`

export const OptionCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
  background-color: ${props => props.isChecked && 'var(--brand-regular)'};
  border: 1px solid var(--brand-regular);
  border-radius: 5px;
  cursor: pointer;

  ${props => props.disabled && css`
    opacity: .5;
    pointer-events: none;
  `}

  ::after {
    content: '';
    height: 35%;
    width: 60%;
    bottom: 2px;
    position: relative;
    display: ${props => props.isChecked ? 'block' : 'none'};
    border-left: solid 2px var(--text-dark);
    border-bottom: solid 2px var(--text-dark);
    transform: rotate(-45deg);
  }
`

export const LoadingWrapper = styled.div`
  position: relative;
  height: 150px;
`

// REVIEW PAGE

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  height: fit-content;

  ${mediaqueries.smallMobile`
    grid-template-columns: 1fr;
  `}

  > div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
`

export const DetailCell = styled(HeaderCell)`
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: var(--sub-text-secondary);
  text-transform: none;
`

// INPUTS

export const CustomInput = styled.input`
  height: 56px;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--text-primary);
  border-radius: 5px;
  background-color: var(--black-tertiary);
  border: 1px solid var(--border-secondary);

  &::placeholder{
    color: var(--border-secondary);
  }

  &:focus, &:active, &:hover {
    border-color: #fff;
  }
`

import React, { useEffect } from 'react'
import {
  ModalCloseButton,
  ModalContainer, ModalHeaderContainer,
  ModalOverlay,
  ModalTitle,
  ModalWrapper
} from './styles/modalComponent'
import { CloseIcon } from '../Guild/style'

function ModalComponent (props) {
  const {
    show, handleClose, children, position, title, width, isResponsive
  } = props

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeClick, false)
    return () => {
      document.removeEventListener('keydown', handleEscapeClick, false)
    }
    // eslint-disable-next-line
  }, [])

  const handleEscapeClick = (event) => {
    if (event.keyCode === 27) handleClose()
  }

  return (
    <ModalContainer show={show} position={position} width={width}>
      <ModalOverlay
        onClick={handleClose}
      />
      <ModalWrapper
        width={width} className='modal-wrapper' isResponsive={isResponsive}
      >
        {title && <ModalHeaderContainer>
          <ModalTitle>{title}</ModalTitle>
          <ModalCloseButton onClick={handleClose}>
            <CloseIcon />
          </ModalCloseButton>
        </ModalHeaderContainer>}
        {children}
      </ModalWrapper>
    </ModalContainer>
  )
}

export default ModalComponent

import React, { useState } from 'react'
import { CustomModal, ChildWrapper, ButtonWrapper } from '../styled'
import Button from '../../Button/Button'
import { CustomLabel, CustomSelect } from '../../Students/style'
import { components } from 'react-select'
import { ReactComponent as RemoveIcon } from '../../../assets/icons/modal-close.svg'
import { getVoucherDenialReason } from '../../../utilities/voucher'
import { courseSelectStyles } from '../../global.styled'

const ConfirmationModal = ({
  setApiCallInProgress,
  showModalHandler,
  apiCallInProgress,
  setResponse,
  response,
  submit,
  show
}) => {
  const [notes, setNotes] = useState([])

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <RemoveIcon />
      </components.MultiValueRemove>
    )
  }

  let modalContent = (
    <>
      <CustomLabel>Denial Note</CustomLabel>
      <CustomSelect
        components={{ MultiValueRemove }}
        onChange={(notes) => setNotes(notes)}
        value={notes}
        styles={courseSelectStyles}
        classNamePrefix='select'
        options={getVoucherDenialReason()}
        isClearable={false}
        placeholder=''
        isMulti
      />
    </>
  )

  if (response === 'success') {
    modalContent = <p>Status changed successfully!</p>
  }

  if (response === 'failed') {
    modalContent = <p>Something went wrong!</p>
  }

  return (
    <CustomModal show={show}>
      <ChildWrapper>
        {modalContent}
        <ButtonWrapper>
          {!response && (
            <Button
              disabled={!notes?.length || apiCallInProgress}
              className={{
                'btn-custom': true,
                'btn-primary': true
              }}
              onClick={() => {
                submit(notes)
              }}
            >
              {!apiCallInProgress ? (
                'Confirm'
              ) : (
                <i className='fa fa-spinner fa-spin' />
              )}
            </Button>
          )}
          <Button
            className={{
              'btn-custom': true,
              'btn-primary': true
            }}
            disabled={apiCallInProgress}
            onClick={() => {
              setApiCallInProgress(false)
              showModalHandler()
              setResponse()
              setNotes([])
            }}
          >
            {response ? 'Close' : 'Cancel'}
          </Button>
        </ButtonWrapper>
      </ChildWrapper>
    </CustomModal>
  )
}

export default ConfirmationModal

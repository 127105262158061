import React from 'react'
import { createPortal } from 'react-dom'
import { Slide, toast } from 'react-toastify'
import ModalComponent from '../ModalComponent/ModalComponent'
import { PrimaryButton, SecondaryButton } from '../global.styled'
import { ModalBody, UnlinkedRecordToastContainer } from './styled'
import { ButtonAsLink } from '../shared/buttons'
import api from '../../api'
import { ReactComponent as CheckIcon } from '../../assets/icons/circle-checked-white.svg'

const UnlinkedRecordToast = ({ closeToast, cell }) => {
  const handleRelinkRecord = async () => {
    closeToast()
    const newRecord = {
      [cell.column.id]: cell.value
    }

    cell.column.updateTable(cell.row.index, cell.column.id, cell.value)

    await api.updateRecord(
      newRecord,
      cell.column.metaData.tableName,
      cell.row.original.id
    )
  }
  return (
    <UnlinkedRecordToastContainer data-testid='unlinked-record-toast'>
      <CheckIcon />
      <span>Record unlinked</span>
      <ButtonAsLink
        onClick={handleRelinkRecord}
        style={{ marginLeft: 'auto' }}
        data-testid='relink-record'
      >
        Undo
      </ButtonAsLink>
    </UnlinkedRecordToastContainer>
  )
}

function UnlinkRecordConfirmation ({
  closeUnlinkRecordModal,
  unlinkRecordConfirmation,
  cell
}) {
  const handleUnlinkRecord = async () => {
    const { record } = unlinkRecordConfirmation

    const filteredItems = cell.value.filter((item) => item.id !== record.id)

    const newRecord = {
      [cell.column.id]: filteredItems
    }

    toast(<UnlinkedRecordToast cell={cell} />, {
      className: 'toastContainer',
      autoClose: 10000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      closeButton: false,
      transition: Slide
    })

    cell.column.updateTable(cell.row.index, cell.column.id, filteredItems)
    closeUnlinkRecordModal()

    await api.updateRecord(
      newRecord,
      cell.column.metaData.tableName,
      cell.row.original.id
    )
  }
  return createPortal(
    <div
      onContextMenu={(event) => event.stopPropagation()}
      data-testid='unlink-record-confirmation'
    >
      <ModalComponent show>
        <ModalBody>
          <p>Are you sure your want to unlink the record?</p>
          <PrimaryButton
            data-testid='go-back-button'
            marginRight='12px'
            onClick={closeUnlinkRecordModal}
          >
            No
          </PrimaryButton>
          <SecondaryButton
            data-testid='confirm-unlink-record-button'
            onClick={handleUnlinkRecord}
          >
            Yes
          </SecondaryButton>
        </ModalBody>
      </ModalComponent>
    </div>,
    document.body
  )
}

UnlinkRecordConfirmation.displayName = 'UnlinkRecordConfirmation'
export default UnlinkRecordConfirmation

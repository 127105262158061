import React, { useEffect, useState } from 'react'
import { Container, Header, Search, InputGroup, SearchIcon } from './styled'
import HistoryTable from './HistoryTable'
import searchIcon from '../../assets/icons/icon-magnify.svg'
import { isMatchCaseInsensitive } from '../../utilities'
import {
  getRequestsHistory,
  getRestructuredRefundRequests,
  useSortableData
} from './utils'
import { sortRequests } from '../../utilities/amazonRefunds'
import { getDateString } from '../../utilities/dateTimeUtil'

const AmazonRefundsHistory = ({ refundRequests }) => {
  const formattedRefundRequests = getRestructuredRefundRequests(
    getRequestsHistory(refundRequests)
  )
  const [records, setRecords] = useState(formattedRefundRequests)

  useEffect(() => {
    if (!refundRequests?.length) return setRecords([])

    setRecords(sortRequests(formattedRefundRequests, 'requestReceived'))
    // eslint-disable-next-line
  }, [refundRequests])

  const { items, sortBy } = useSortableData(records)

  const searchData = (val, records) => {
    if (val.length < 3 || !records.length) {
      return setRecords(formattedRefundRequests)
    }
    const filteredRecord = records.filter(
      ({
        studentEmail,
        voucherNumber,
        requestReceived,
        refundAmount,
        decision
      }) => {
        const requestDate = getDateString(new Date(requestReceived), 'MM/DD/YY')
        return (
          isMatchCaseInsensitive(studentEmail, val) ||
          isMatchCaseInsensitive(decision, val) ||
          isMatchCaseInsensitive(decision, val) ||
          requestDate.includes(val) ||
          refundAmount.includes(val) ||
          voucherNumber.includes(val)
        )
      }
    )
    setRecords(filteredRecord)
  }
  return (
    <Container>
      <Header>History</Header>
      <InputGroup>
        <Search
          onChange={(e) => searchData(e.target.value, items)}
          placeholder='Voucher, email, etc.'
        />
        <SearchIcon src={searchIcon} />
      </InputGroup>
      <HistoryTable data={items} sortBy={sortBy} />
    </Container>
  )
}

export default AmazonRefundsHistory

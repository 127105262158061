import React, { useEffect, useRef, useState } from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import Select from 'react-select'
import {
  CsvError,
  CustomDatePicker,
  DatePickerContainer,
  DownloadCSVTemplateContainer,
  DownloadTemplate,
  FileUploaderHelperText,
  FormWrapper,
  Separator,
  ToastTitleContainer,
  UploaderContainer,
  VipCheckBox,
  selectInputStyles
} from './styled'
import api from '../../api'
import {
  getAdditionalCourseWithAtId,
  getValidCourses
} from '../../utilities/courseUtils'
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg'
import FileUploader from '../FileUploader/FileUploader'
import {
  CREDIT_GRANTING_INSTITUTIONS,
  sampleCSV
} from '../../Constants/vipTool'
import { validateCSV } from './utils'
import Toast from '../ToastComponent/Toast'
import { ReactComponent as CheckIcon } from '../../assets/icons/circle-checked-white.svg'
import { additionalCourseIdToName } from '../../config'
import cloneDeep from 'lodash/cloneDeep'
import CourseSelect from './CourseSelect'

const initialState = {
  csvFile: null,
  students: [],
  csvHasErrors: false,
  creditGrantingInstitution: null,
  courses: [],
  cohorts: [],
  date: '',
  vipGradedContent: false,
  sendEmail: false,
  contractorEmployee: false,
  notes: ''
}

function MultipleVipUsers () {
  const [formState, setFormState] = useState(initialState)
  const [allCourses, setAllCourses] = useState([])
  const toast = useRef()

  const fetchCoursesAndCohorts = async () => {
    const courses = await api.getCourses()
    const totalCourses = getValidCourses(courses)

    setAllCourses(
      totalCourses.map((course) => ({
        ...course,
        value: course.id,
        label: course.name
      }))
    )
  }

  useEffect(() => {
    fetchCoursesAndCohorts()
  }, [])

  const {
    creditGrantingInstitution,
    courses = [],
    date,
    notes,
    sendEmail,
    contractorEmployee,
    vipGradedContent,
    csvFile,
    csvHasErrors
  } = formState

  const onChange = (name, value) => {
    setFormState((prevState) => ({ ...prevState, [name]: value }))
  }

  const onFileSelect = async (file) => {
    onChange('csvHasErrors', false)
    onChange('csvFile', file)
    const { error, data } = await validateCSV(file)

    onChange('csvHasErrors', error)
    onChange('students', data)
  }

  const handleSubmit = async () => {
    const clonedCourses = cloneDeep(courses)
    const additionalIdExists = clonedCourses.findIndex(
      (course) => additionalCourseIdToName(course.id)?.length > 1
    )
    if (additionalIdExists > -1) {
      clonedCourses[additionalIdExists].value =
        getAdditionalCourseWithAtId(
          clonedCourses[additionalIdExists].at_id,
          allCourses
        )?.id || clonedCourses[additionalIdExists].value
    }

    const requestBody = {
      students: formState.students.map((student) => ({
        email: student['Email'].trim(),
        name: student['Full Name'].trim()
      })),
      courses: clonedCourses.map((course) => course.value),
      dateToRemoveAccess: date || null,
      notes,
      vipGradedContent,
      contractorEmployee,
      sendEmail,
      vip: true
    }

    await api.upsertBulkVipStudents(requestBody)
    setFormState(initialState)

    const { current: { display } = {} } = toast
    display(
      <ToastTitleContainer data-testid='request-received'>
        <CheckIcon />
        <p>VIP creation request received!</p>
      </ToastTitleContainer>,
      'We have received your request and we are working on it.'
    )
  }

  return (
    <>
      <Toast toast={toast} />
      <DownloadCSVTemplateContainer>
        <p>Add the emails and names to the template below</p>
        <DownloadTemplate
          data={sampleCSV}
          filename='Sample VIP bulk upload.csv'
        >
          <DownloadIcon />
          download template
        </DownloadTemplate>
      </DownloadCSVTemplateContainer>
      <Separator margin={32} />
      <FormWrapper className='form-wrapper' style={{ margin: '24px 0' }}>
        <FormGroup>
          <Label className='field-label'>List of VIP users</Label>
          <UploaderContainer csvHasErrors={csvHasErrors}>
            <FileUploader
              isSmallInput
              uploadedContent={csvFile}
              onFileSelect={onFileSelect}
              onEmpty={() => {
                onChange('csvFile', null)
                onChange('csvHasErrors', false)
              }}
            />
            {csvHasErrors && (
              <CsvError data-testid='csv-error'>
                <p>
                  File does not meet the requirements. Please ensure that the
                  file contains:
                </p>
                <ul>
                  <li>original headers</li>
                  <li>a first and last name for every email address listed</li>
                </ul>
              </CsvError>
            )}
            <FileUploaderHelperText>
              File must include email and full name
            </FileUploaderHelperText>
          </UploaderContainer>
          <Label className='field-label'>Credit-granting institution</Label>
          <div className='text-field'>
            <Select
              data-testid='select-input'
              options={CREDIT_GRANTING_INSTITUTIONS}
              value={creditGrantingInstitution}
              onChange={(creditGrantingInstitution) => {
                onChange('creditGrantingInstitution', creditGrantingInstitution)
              }}
              placeholder='Select institution'
              styles={selectInputStyles}
            />
          </div>
          <Label className='field-label'>Courses</Label>
          <div className='text-field'>
            <CourseSelect
              options={allCourses.filter((course) =>
                creditGrantingInstitution?.value
                  ? course.creditGrantingInstitution ===
                    creditGrantingInstitution?.value
                  : true
              )}
              selectedCourses={courses}
              setSelectedCourses={(selectedCourses) => {
                onChange('courses', selectedCourses)
              }}
            />
          </div>
          <Label className='field-label'>Remove Access Date (optional)</Label>
          <div className='text-field'>
            <DatePickerContainer>
              <CustomDatePicker
                data-testid='date-field'
                className='date'
                autoComplete='off'
                placeholderText='mm/dd/yyyy'
                selected={date ? new Date(date) : date}
                onChange={(date) =>
                  onChange(
                    'date',
                    date ? date.toISOString().split('T')[0] : null
                  )
                }
              />
            </DatePickerContainer>
          </div>
          <Label className='field-label radio-label'>
            Access to Graded Content
          </Label>
          <div className='text-field radio'>
            <Label className='container'>
              Yes
              <Input
                className='checkbox'
                type='radio'
                name='vipGradedContent'
                checked={vipGradedContent}
                onChange={() => onChange('vipGradedContent', true)}
              />
              <span className='check-mark' />
            </Label>

            <Label className='container'>
              No
              <Input
                className='checkbox'
                type='radio'
                name='vipGradedContent'
                checked={!vipGradedContent}
                onChange={() => onChange('vipGradedContent', false)}
              />
              <span className='check-mark' />
            </Label>
          </div>
          <Label className='field-label radio-label'>
            Send Automated Email
          </Label>
          <div className='text-field radio'>
            <Label className='container'>
              Yes
              <Input
                data-testid='sendEmail-true'
                className='checkbox'
                type='radio'
                name='sendEmail'
                checked={sendEmail}
                onChange={() => onChange('sendEmail', true)}
              />
              <span className='check-mark' />
            </Label>

            <Label className='container'>
              No
              <Input
                data-testid='sendEmail-false'
                className='checkbox'
                type='radio'
                name='sendEmail'
                checked={!sendEmail}
                onChange={() => onChange('sendEmail', false)}
              />
              <span className='check-mark' />
            </Label>
          </div>
          <Label className='field-label'>
            Internal Notes (VIP will not see)
          </Label>
          <div className='text-field'>
            <Input
              data-testid='notes-field'
              type='textArea'
              value={notes}
              placeholder='Notes'
              onChange={(e) => onChange('notes', e.target.value)}
            />
          </div>
          <VipCheckBox>
            Contractor Employee
            <input
              type='checkbox'
              data-testid='contractorEmployee'
              id='contractorEmployee'
              name='contractorEmployee'
              value={contractorEmployee}
              onChange={(e) =>
                onChange('contractorEmployee', !contractorEmployee)
              }
              checked={contractorEmployee}
            />
            <span />
          </VipCheckBox>
          <Button
            disabled={!csvFile || csvHasErrors || !creditGrantingInstitution}
            className='submit-button mb-5'
            color='primary'
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </FormGroup>
      </FormWrapper>
    </>
  )
}

MultipleVipUsers.displayName = 'MultipleVipUsers'
export default MultipleVipUsers

import moment from 'moment'
import isArray from 'lodash/isArray'
import {
  getFormatTime12PM,
  getDateAfterDays,
  getDateAfterWeeks,
  getDateString,
  getMondayBeforeDate,
  convertEasternTimeToLocalTime, dateToSecondsSinceEpoch
} from './dateTimeUtil'
import {
  ASTRONOMY_NAME,
  CALCULUS_NAME,
  MACROECONOMICS_NAME,
  MICROECONOMICS_NAME,
  PHILOSOPHY_NAME,
  PRECALCULUS_NAME,
  STATISTICS_NAME,
  FINANCIAL_ACCOUNTING_NAME,
  PSYCHOLOGY_NAME,
  SOCIOLOGY_NAME,
  PRINCIPLES_OF_ECONOMICS_NAME,
  BUSINESS_NAME,
  COLLEGE_WRITING_I_NAME,
  COLLEGE_ALGEBRA,
  COMPUTER_SCIENCE_I_NAME
} from '../Constants/courses'
import { isAuditCohort } from './courseUtils'

export {
  getDefaultCommonDates,
  getDefaultMidtermDates,
  getMondayBeforeWeek,
  sortByDateDescOrder,
  getActiveCohorts,
  getActiveCohortSortedVipFirst,
  isVipCohort,
  getIsCohortActive,
  getRelationshipCohorts,
  getIsExtendedCohort,
  getNextDropEligibleCohort,
  getGGUCohorts,
  getActiveCohortsOptions
}

const COURSES = [
  ASTRONOMY_NAME,
  MACROECONOMICS_NAME,
  PRECALCULUS_NAME,
  MICROECONOMICS_NAME,
  STATISTICS_NAME,
  CALCULUS_NAME,
  PHILOSOPHY_NAME,
  FINANCIAL_ACCOUNTING_NAME,
  PSYCHOLOGY_NAME,
  SOCIOLOGY_NAME,
  PRINCIPLES_OF_ECONOMICS_NAME,
  BUSINESS_NAME,
  COLLEGE_WRITING_I_NAME,
  COLLEGE_ALGEBRA,
  COMPUTER_SCIENCE_I_NAME
]

const COURSES_MONDAY_OF_WEEKS_6_AND_10 = [
  ASTRONOMY_NAME,
  PRECALCULUS_NAME,
  PRINCIPLES_OF_ECONOMICS_NAME,
  MACROECONOMICS_NAME,
  SOCIOLOGY_NAME,
  COMPUTER_SCIENCE_I_NAME
]

const COURSES_MONDAY_OF_WEEKS_5_AND_9 = [
  STATISTICS_NAME,
  PSYCHOLOGY_NAME,
  MICROECONOMICS_NAME
]

const EASTERN_TIME = 'America/New_York'

function sortByDateDescOrder (data) {
  const sortedData = data.sort((a, b) => {
    if (!a.dateStart) return 1
    if (!b.dateStart) return -1
    return new Date(b.dateStart) - new Date(a.dateStart)
  })
  return sortedData
}

export const cohort = {
  cohortId: '1',
  name: 'Cohort 1',
  course: 'Calculus I',
  dateStart: '2020-10-23',
  courseInfoUrl: 'https:www.resources.outlier.org/welcome',
  isPartnerCohort: true,
  notes: 'simply dummy text of the printing and typesetting',
  duration: '6',
  finalDropDate: '2020-10-20',
  finalWithdrawalDate: '2020-10-21',
  midTerm1StartTime: '2020-10-27T19:51',
  midTerm1EndTime: '2020-10-28T15:47',
  midTerm2StartTime: '2020-10-21T17:49',
  midTerm2EndTime: '2020-10-28T19:51',
  finalExamStartTime: '2020-10-21T18:50',
  finalExamEndTime: '2020-10-28T20:52',
  gradesSubmittedToPitt: true,
  finalRegistrationDate: '2020-10-30'
}

function getDefaultCommonDates (dateStart, duration, course) {
  if (!dateStart || !duration) return {}

  const duration7or8 = duration === 7 || duration === 8
  const duration14or15 = duration === 14 || duration === 15
  const courseName = course?.label

  let defaultCommonDates
  let finalExamStartTime = getMondayBeforeWeek(dateStart, duration - 2)

  if (duration7or8) {
    finalExamStartTime = getFridayOfWeek(dateStart, duration - 2)
  }

  const isPhilo = [PHILOSOPHY_NAME].includes(courseName)

  if (duration7or8) {
    const finalDropDateWeeks = 1
    const finalWithdrawalWeeks = 4
    const finalExamEndTimeDays = 3
    defaultCommonDates = getCommonDatesDuration({
      dateStart,
      finalExamStartTime,
      finalDropDateWeeks,
      finalWithdrawalWeeks,
      finalExamEndTimeDays
    })
  }

  if (duration14or15) {
    const finalDropDateWeeks = 2
    const finalWithdrawalWeeks = 9
    const finalExamEndTimeDays = 7
    defaultCommonDates = getCommonDatesDuration({
      dateStart,
      finalExamStartTime,
      finalDropDateWeeks,
      finalWithdrawalWeeks,
      finalExamEndTimeDays
    })
  }

  finalExamStartTime = isPhilo ? undefined : getFormatTime12PM(finalExamStartTime, EASTERN_TIME)

  return {
    ...defaultCommonDates,
    finalExamStartTime
  }
}

function getCommonDatesDuration ({
  dateStart,
  finalExamStartTime,
  finalDropDateWeeks,
  finalWithdrawalWeeks,
  finalExamEndTimeDays
}) {
  const dateFormat = 'YYYY-MM-DD'
  let finalDropDate = getDateAfterWeeks(dateStart, finalDropDateWeeks)
  finalDropDate = finalDropDate?.setDate(finalDropDate.getDate() + 1)
  let finalWithdrawalDate =
    getDateAfterWeeks(dateStart, finalWithdrawalWeeks)
  finalWithdrawalDate = finalWithdrawalDate?.setDate(finalWithdrawalDate.getDate() + 1)
  let finalExamEndTime =
    getDateAfterDays(finalExamStartTime, finalExamEndTimeDays)

  finalExamEndTime = getFormatTime12PM(finalExamEndTime, EASTERN_TIME)
  finalDropDate = getDateString(finalDropDate, dateFormat)
  finalWithdrawalDate = getDateString(finalWithdrawalDate, dateFormat)

  return {
    finalDropDate,
    finalWithdrawalDate,
    finalExamEndTime
  }
}

function getMondayBeforeWeek (dateStart, week) {
  const endDate = getDateAfterWeeks(dateStart, week)
  const mondayBeforeEndDate = getMondayBeforeDate(endDate)
  return mondayBeforeEndDate
}

function getFridayOfWeek (dateStart, week) {
  const firdayOfEndDate = moment(dateStart).add(week, 'weeks').day('Friday').format()
  return new Date(firdayOfEndDate)
}

function getDefaultMidtermDates (course, dateStart, duration) {
  const courseName = course?.label

  if (!courseName || !dateStart || !duration) return {}

  if (!COURSES.includes(courseName)) return {}

  const duration14or15 = duration === 14 || duration === 15
  const duration7or8 = duration === 7 || duration === 8

  let defaultMidtermDates

  if (duration14or15) {
    defaultMidtermDates = getMidtermDatesDuration14or15(courseName, dateStart)
  }

  if (duration7or8) {
    defaultMidtermDates = getMidtermDatesDuration7or8(courseName, dateStart)
  }

  return defaultMidtermDates
}

function getMidtermDatesDuration14or15 (course, dateStart) {
  let midTerm1StartTimeWeek
  const midTerm1EndTimeDays = 7
  let midTerm2StartTimeWeek
  const midTerm2EndTimeDays = 7

  if (COURSES_MONDAY_OF_WEEKS_6_AND_10.includes(course)) {
    midTerm1StartTimeWeek = 5
    midTerm2StartTimeWeek = 9
  }
  if (COURSES_MONDAY_OF_WEEKS_5_AND_9.includes(course)) {
    midTerm1StartTimeWeek = 4
    midTerm2StartTimeWeek = 8
  }
  if ([CALCULUS_NAME].includes(course)) {
    midTerm1StartTimeWeek = 3
    midTerm2StartTimeWeek = 9
  }
  if ([FINANCIAL_ACCOUNTING_NAME].includes(course)) {
    midTerm1StartTimeWeek = 4
    midTerm2StartTimeWeek = 9
  }
  if ([COLLEGE_WRITING_I_NAME].includes(course)) {
    midTerm1StartTimeWeek = undefined
    midTerm2StartTimeWeek = undefined
  }
  if ([BUSINESS_NAME, COLLEGE_ALGEBRA].includes(course)) {
    midTerm1StartTimeWeek = 5
    midTerm2StartTimeWeek = 11
  }
  if ([PHILOSOPHY_NAME].includes(course)) {
    midTerm1StartTimeWeek = 8
    let midTerm1StartTime = getMondayBeforeWeek(dateStart, midTerm1StartTimeWeek)
    midTerm1StartTime = getFormatTime12PM(midTerm1StartTime, EASTERN_TIME)
    midTerm2StartTimeWeek = undefined
    let midTerm1EndTime =
      getDateAfterDays(midTerm1StartTime, midTerm1EndTimeDays)
    midTerm1EndTime = getFormatTime12PM(midTerm1EndTime, EASTERN_TIME)
    return {
      midTerm1StartTime,
      midTerm1EndTime
    }
  }

  const midtermDatesDuration14or15Weeks = getMidtermDatesDuration14or15Weeks({
    dateStart,
    midTerm1StartTimeWeek,
    midTerm2StartTimeWeek
  })

  const midtermDates = getMidtermDates({
    ...midtermDatesDuration14or15Weeks,
    midTerm1EndTimeDays,
    midTerm2EndTimeDays
  })

  return {
    ...midtermDates,
    ...midtermDatesDuration14or15Weeks
  }
}

function getMidtermDatesDuration7or8 (course, dateStart) {
  let midTerm1StartTimeWeek = 2
  const midTerm1EndTimeDays = 3
  let midTerm2StartTimeWeek = 4
  const midTerm2EndTimeDays = 3

  if ([COLLEGE_ALGEBRA].includes(course)) {
    midTerm1StartTimeWeek = 2
    midTerm2StartTimeWeek = 5
  }
  if ([MICROECONOMICS_NAME, CALCULUS_NAME, STATISTICS_NAME].includes(course)) {
    midTerm1StartTimeWeek = 1
    midTerm2StartTimeWeek = 4
  }
  if ([MICROECONOMICS_NAME, PSYCHOLOGY_NAME].includes(course)) {
    midTerm1StartTimeWeek = 1
    midTerm2StartTimeWeek = 3
  }
  if ([COLLEGE_WRITING_I_NAME].includes(course)) return {}
  if ([PHILOSOPHY_NAME].includes(course)) {
    let midTerm1StartTime = getFridayOfWeek(dateStart, 3)
    midTerm1StartTime = getFormatTime12PM(midTerm1StartTime, EASTERN_TIME)
    let midTerm1EndTime = getDateAfterDays(midTerm1StartTime, 3)
    midTerm1EndTime = getFormatTime12PM(midTerm1EndTime, EASTERN_TIME)
    midTerm2StartTimeWeek = undefined

    return {
      midTerm1StartTime,
      midTerm1EndTime
    }
  }

  const midtermDatesDuration7or8Weeks = getMidtermDatesDuration7or8Weeks({
    dateStart,
    midTerm1StartTimeWeek,
    midTerm2StartTimeWeek
  })

  const midtermDates = getMidtermDates({
    ...midtermDatesDuration7or8Weeks,
    midTerm1EndTimeDays,
    midTerm2EndTimeDays
  })

  return {
    ...midtermDates,
    ...midtermDatesDuration7or8Weeks
  }
}

function getMidtermDatesDuration7or8Weeks ({
  dateStart,
  midTerm1StartTimeWeek,
  midTerm2StartTimeWeek
}) {
  let midTerm1StartTime = getFridayOfWeek(dateStart, midTerm1StartTimeWeek)
  midTerm1StartTime = getFormatTime12PM(midTerm1StartTime, EASTERN_TIME)
  let midTerm2StartTime = getFridayOfWeek(dateStart, midTerm2StartTimeWeek)
  midTerm2StartTime = getFormatTime12PM(midTerm2StartTime, EASTERN_TIME)
  return {
    midTerm1StartTime,
    midTerm2StartTime
  }
}

function getMidtermDatesDuration14or15Weeks ({
  dateStart,
  midTerm1StartTimeWeek,
  midTerm2StartTimeWeek
}) {
  let midTerm1StartTime = getMondayBeforeWeek(dateStart, midTerm1StartTimeWeek)
  midTerm1StartTime = getFormatTime12PM(midTerm1StartTime, EASTERN_TIME)
  let midTerm2StartTime = getMondayBeforeWeek(dateStart, midTerm2StartTimeWeek)
  midTerm2StartTime = getFormatTime12PM(midTerm2StartTime, EASTERN_TIME)
  return {
    midTerm1StartTime,
    midTerm2StartTime
  }
}

function getMidtermDates ({
  midTerm1StartTime,
  midTerm2StartTime,
  midTerm1EndTimeDays,
  midTerm2EndTimeDays
}) {
  let midTerm1EndTime = getDateAfterDays(midTerm1StartTime, midTerm1EndTimeDays)
  midTerm1EndTime = getFormatTime12PM(midTerm1EndTime, EASTERN_TIME)
  let midTerm2EndTime = getDateAfterDays(midTerm2StartTime, midTerm2EndTimeDays)
  midTerm2EndTime = getFormatTime12PM(midTerm2EndTime, EASTERN_TIME)
  return {
    midTerm1EndTime,
    midTerm2EndTime
  }
}

function getNextDropEligibleCohort (cohorts) {
  if (!isArray(cohorts) || !cohorts?.length) return

  const preDropDateCohorts = cohorts.filter(cohort => {
    const { finalDropDate, testCohort } = cohort

    const finalDropDateLocal = convertEasternTimeToLocalTime(
      finalDropDate,
      'T00:00:00'
    )

    return moment().isBefore(finalDropDateLocal) && !testCohort
  }).sort((a, b) => {
    const aDate = new Date(a.finalDropDate)
    const bDate = new Date(b.finalDropDate)
    return aDate - bDate
  })

  return preDropDateCohorts[0]
}

function isVipCohort (cohort) {
  const { name } = cohort || {}
  return name?.toLowerCase()?.includes('vip')
}

const isOngoingCohort = (cohort) => {
  const { name, dateStart } = cohort || {}
  const isNotVIP = !name?.toLowerCase()?.includes('vip')
  const hasAlreadyStarted = moment().isAfter(dateStart)

  return isNotVIP && hasAlreadyStarted
}

const isFutureCohorts = (cohort) => {
  const { name, dateStart } = cohort || {}
  const isNotVIP = !name?.toLowerCase()?.includes('vip')
  const willStartInFuture = moment().isBefore(dateStart)

  return isNotVIP && willStartInFuture
}

const sortByStartDate = (cohorts) => cohorts.sort((a, b) => {
  const aDate = new Date(a?.dateStart)
  const bDate = new Date(b?.dateStart)
  return aDate - bDate
})

function getIsCohortActive (cohort) {
  if (!cohort) return cohort

  const { dateStart, finalExamEndTime, endTime } = cohort || {}
  const today = Date.now()
  const startDate = new Date(dateStart).getTime()
  const endDate = new Date(endTime || finalExamEndTime).getTime()

  return startDate <= today && today <= endDate
}

function getActiveCohorts (cohorts) {
  const activeCohorts = {}

  cohorts.forEach(cohort => {
    const { name, finalExamEndTime, endTime } = cohort || {}
    const courseEndTime = endTime || finalExamEndTime
    const hasCourseEnded = moment().isAfter(courseEndTime)

    if (hasCourseEnded) return

    const courseName = name?.split('-')[0]

    const addItemToActiveCohorts = () => {
      if (isVipCohort(cohort)) activeCohorts[courseName].vip.push(cohort)
      if (isOngoingCohort(cohort)) activeCohorts[courseName].ongoing.push(cohort)
      if (isFutureCohorts(cohort)) activeCohorts[courseName].future.push(cohort)
    }

    if (activeCohorts[courseName]) {
      addItemToActiveCohorts()
    } else {
      activeCohorts[courseName] = {
        vip: [],
        ongoing: [],
        future: []
      }
      addItemToActiveCohorts()
    }
  })

  return activeCohorts
}

function getActiveCohortSortedVipFirst (cohorts) {
  if (!isArray(cohorts) || !cohorts?.length) return []

  const activeCohorts = getActiveCohorts(cohorts)
  const result = []

  Object.values(activeCohorts).forEach(cohorts => {
    const VIPCohortByStartDate = sortByStartDate(cohorts.vip)
    result.push(...VIPCohortByStartDate)
  })

  Object.values(activeCohorts).forEach(cohorts => {
    const ongoingCohortByStartDate = sortByStartDate(cohorts.ongoing)
    result.push(...ongoingCohortByStartDate)
  })

  Object.values(activeCohorts).forEach(cohorts => {
    const futureCohortByStartDate = sortByStartDate(cohorts.future)
    result.push(...futureCohortByStartDate)
  })

  return result
}

function getRelationshipCohorts (relationship) {
  const attempts = relationship?.attempts || {}
  if (!attempts?.length) return []

  const cohorts = attempts.map(attempt => {
    const { fields } = attempt || {}
    const { cohort = [] } = fields || {}
    return cohort?.[0]
  })

  const uniqueCohorts = [...new Set(cohorts)]

  return uniqueCohorts
}

function getIsExtendedCohort (cohort) {
  return cohort?.name?.toLowerCase()?.includes('ext') || cohort?.duration === 39
}

function getFarthestFinalDeadline (examExtensions) {
  if (!examExtensions?.length) return

  return examExtensions.sort(
    (a, b) =>
      new Date(b?.fields?.finalDeadlineExt) -
      new Date(a?.fields?.finalDeadlineExt)
  )[0]?.fields?.finalDeadlineExt
}

function getActiveCohortsOptions (cohorts, courseId) {
  if (!cohorts?.length) return []

  const activeCohorts = cohorts.filter(cohort => {
    const {
      dateStart,
      finalExamEndTime,
      endTime,
      examExtensions,
      course,
      testCohort
    } = cohort || {}

    const finalDeadlineExt = getFarthestFinalDeadline(examExtensions)

    const now = moment(new Date())
    const cohortEndTime = finalDeadlineExt || endTime || finalExamEndTime
    const isActive = now.isBetween(dateStart, cohortEndTime)

    return isActive && !isAuditCohort(cohort) && !testCohort &&
      !isVipCohort(cohort) && courseId === course?.[0]
  }).map(cohort => {
    const { dateStart, duration } = cohort || {}
    const startDate = moment(dateStart).format('MM/DD/YYYY')
    const cohortLabel = `${startDate} - ${duration} weeks`

    return {
      ...cohort,
      label: cohortLabel,
      value: cohort.at_id
    }
  })

  return activeCohorts
}

function getGGUCohorts (cohorts) {
  if (!cohorts?.length) return []

  return cohorts.filter(cohort => {
    const { name } = cohort || {}
    return name?.toLowerCase()?.includes('ggu')
  })
}

export const hasCohortEndFourYearsAgoOrMore = (cohortEndTime) => {
  if (!cohortEndTime) return undefined

  const currentDate = new Date()
  const fourYearsAgoDate = new Date(
    currentDate.setFullYear(currentDate.getFullYear() - 4)
  )

  const fourYearsAgoEpochTime =
    dateToSecondsSinceEpoch(fourYearsAgoDate)

  return cohortEndTime &&
    cohortEndTime <= fourYearsAgoEpochTime
}

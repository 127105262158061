import React, { useMemo, useState } from 'react'
import api from '../../api'
import { PAGES } from './constants'
import { saveAs } from 'file-saver'
import { getDateTimeFormatted } from './utils'
import Pagination from '../AmazonRefundsHistory/Pagination'
import {
  BackArrowIcon, BackLink, PageContainer, PageHeader, TableContainer,
  TableHeader, HeaderItem, TableItem, TableRow
} from './style'
import { SortIcon } from '../global.styled'
import filterIcon from '../../assets/icons/arrow-top-bottom.svg'
import { useSortableData } from '../AmazonRefundsHistory/utils'

const COLUMN_HEADER = [
  { key: 'ELT File', value: 'name' },
  { key: 'Upload Date', value: 'createdAt' }
]

function ELTArchive ({
  setActivePage, fetchedELTFiles
}) {
  const pageSize = 10
  const [currentPage, setCurrentPage] = useState(1)

  const { items: ELTFiles, sortBy } = useSortableData(fetchedELTFiles)

  const currentTableData = useMemo(() => {
    const pageStartIndex = (currentPage - 1) * pageSize
    const pageFinishIndex = pageStartIndex + pageSize
    return ELTFiles?.slice(pageStartIndex, pageFinishIndex)
  }, [ELTFiles, currentPage, pageSize])

  const onBackClick = () => {
    setActivePage(PAGES.main)
  }

  const onDownloadFileClick = async (fileName) => {
    const { data } = await api.getUploadedELTFiles(fileName)
    const blob = new Blob([data], { type: 'text/csv' })
    saveAs(blob, fileName)
  }

  const getHideBorder = (index) => {
    const isLastRow = index === currentTableData.length - 1
    const havePagination = ELTFiles?.length > pageSize
    return isLastRow && havePagination
  }

  return (
    <PageContainer>
      <BackLink
        onClick={onBackClick}
      >
        <BackArrowIcon />
        go back
      </BackLink>

      <PageHeader>
        Guild ELT Archive
      </PageHeader>

      <TableContainer>
        <TableHeader>
          {COLUMN_HEADER.map((item, index) => (
            <HeaderItem key={index} >
              {item.key}
              <SortIcon src={filterIcon} onClick={() => sortBy(item.value)} />
            </HeaderItem>
          ))}
        </TableHeader>

        {currentTableData.map((item, index) => {
          const { name, createdAt } = item
          const uploadDate = getDateTimeFormatted(createdAt)

          return (
            <TableRow
              key={index}
              hideBorder={getHideBorder(index)}
            >
              <TableItem>{name}</TableItem>
              <TableItem>{uploadDate}</TableItem>
              <TableItem
                hover
                onClick={() => onDownloadFileClick(name)}
              >
                download file
              </TableItem>
            </TableRow>
          )
        })}
      </TableContainer>

      <Pagination
        isUpdateStyle
        pageSize={pageSize}
        totalCount={ELTFiles?.length}
        currentPage={currentPage}
        onPageChange={page => setCurrentPage(page)}
      />
    </PageContainer>
  )
}

ELTArchive.displayName = 'ELTArchive'
export default ELTArchive

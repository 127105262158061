import { useEffect, useRef } from 'react'

export default function useClickAway (callback, { onlyIn } = {}) {
  const ref = useRef(null)

  useEffect(() => {
    const onEventHandler = (e) => {
      const clickOutsideElement = ref.current && !ref.current.contains(e.target)
      const clickInsideContainer =
        clickOutsideElement &&
        onlyIn?.current &&
        onlyIn?.current?.contains(e.target)

      const detectClick = onlyIn ? clickInsideContainer : clickOutsideElement

      if (detectClick) {
        callback()
      }
    }
    document.addEventListener('mousedown', onEventHandler)
    document.addEventListener('touchstart', onEventHandler)

    return () => {
      document.removeEventListener('mousedown', onEventHandler)
      document.removeEventListener('touchstart', onEventHandler)
    }
  }, [callback, onlyIn])

  return ref
}

import React, { useState, useEffect, forwardRef, useRef } from 'react'
import {
  CustomFormGroup,
  CustomLabel,
  CustomInput,
  CustomSelect,
  CustomButton,
  SubHeader,
  InfoText,
  CourseInfo,
  DateContainer,
  CustomButtonSecondary,
  CustomCurrencyInput,
  RecordHeading,
  Info,
  ErrorText,
  CustomToolTip,
  RequiredText
} from './style'
import AlertModal from '../AlertModal/AlertModal'
import { CheckBox } from '../CSVDownloadTool/style'
import cuid from 'cuid'
import DatePicker from 'react-datepicker'
import api from '../../api'
import { addYearsToDate } from '../../utilities/dateTimeUtil'
import { useHistory, useLocation } from 'react-router-dom'
import { PARTNERSHIP } from '../../Constants'
import { generateOrderNumber, parseOrderNumber } from '../../utilities/addStudentUtils'
import { courseSelectStyles } from '../global.styled'

const COMMENTS_TOOLTIP = 'Notes or comments left in this field will appear on the Attempts, Purchases, and Tokens tabs'
const PURCHASED_DATE_TOOLTIP = 'The purchase date is set to when the record is created. Feel free to update the purchase date and time below.'
const RETURN_TO_TOOLTIP = 'This determines whether the token is returned to the student or partner in eligible situations (like a course drop). It’s typically set to “studentReturn” but may differ by relationship.'
const REFUNDABILITY_TOOLTIP = 'This sets whether the token can be returned for a refund. It’s typically set to “studentRefundable” but may differ by relationship.'
const REQUIRED_FIELDS = ['status']

function AttemptsAndPurchases (props) {
  const {
    formState,
    initialState,
    studentNotesState,
    studentStatusesState,
    paymentStatusesState,
    studentRelationshipState
  } = props

  const history = useHistory()
  const location = useLocation()
  const { allowView } = location.state || {}

  const [formData, setFormData] = formState
  const {
    partnerships: { orderNumber: partnershipOrderNumber = '' } = {},
    scholarships: { orderNumber: scholarshipOrderNumber = '' } = {}
  } = formData.lastOrders || {}
  const partnershipLastNumber = parseOrderNumber(partnershipOrderNumber)
  const scholarshipLastNumber = parseOrderNumber(scholarshipOrderNumber)
  const lastPartnershipOrderNumber = useRef(partnershipLastNumber)
  const lastScholarshipOrderNumber = useRef(scholarshipLastNumber)
  const [allStudentStatuses, setStudentStatuses] = studentStatusesState
  const [allStudentNotes, setStudentNotes] = studentNotesState
  const [allPaymentStatuses, setPaymentStatuses] = paymentStatusesState
  const [isAlertModal, setIsAlertModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState()
  const [allStudentRelationships] = studentRelationshipState
  const mountedRef = useRef(false)

  useEffect(() => {
    CustomToolTip.rebuild()
  })

  useEffect(() => {
    const shouldFetch =
    !allStudentStatuses.length ||
    !allStudentNotes.length ||
    !allPaymentStatuses.length

    if (shouldFetch) fetchDropdownData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    mountedRef.current = true

    if (mountedRef.current) {
      generateTokenNumbers()
      formData.orders.forEach((order, index) => handleAutomaticFields(index))
    }
    return () => {
      mountedRef.current = false
    }
    // eslint-disable-next-line
  }, [])

  const generateTokenNumbers = () => {
    formData.orders.forEach((order, index) => {
      setFormData((prevState) => {
        prevState.orders[index]['token'] = {
          ...prevState.orders[index]['token'],
          tokenNumber: `t${cuid()}`,
          cohort: order.cohort.at_id
        }
        return { ...prevState }
      })
    })
  }

  function getRelationshipType (relationship) {
    return relationship?.relationshipType?.[0] ||
    relationship?.fields?.relationshipType?.[0]
  }

  const handleAutomaticFields = (index) => {
    setFormData((prevState) => {
      const { relationship } = prevState.orders[index]['token']
      const relType = getRelationshipType(relationship)

      prevState.orders[index].purchases = {
        ...prevState.orders[index]?.purchases,
        isPartnerStudent: relType === PARTNERSHIP
      }

      prevState.orders[index]['token'] = {
        ...prevState.orders[index].token,
        refundability: (relationship && relationship.refundability)
          ? relationship.refundability
          : 'studentRefundable',
        returnTo: (relationship && relationship.returnTo) || 'studentReturn',
        issueDate: new Date(),
        expirationDate: relationship && relationship.tokenExpirationDate
          ? new Date(relationship.tokenExpirationDate) : addYearsToDate(new Date(), 2)
      }

      return { ...prevState }
    })
  }

  const fetchDropdownData = async () => {
    setLoading(true)
    const { getStudentStatuses, getStatusNotes, getPaymentStatuses } = api
    const promises = [
      getStudentStatuses(),
      getStatusNotes(),
      getPaymentStatuses()
    ]
    const [statuses, notes, paymentStatuses] = await Promise.all(promises)
    statuses &&
      setStudentStatuses(
        statuses.map((status) => ({ value: status.id, label: status.name }))
      )
    notes &&
      setStudentNotes(
        notes.map((note) => ({ value: note.id, label: note.name }))
      )
    !paymentStatuses.error &&
      setPaymentStatuses(
        paymentStatuses.map((payment) => ({
          value: payment.id,
          label: payment.name
        }))
      )
    setLoading(false)
  }

  const CustomDateInput =
  forwardRef(({ label, tooltip, value, onClick, onChange }, ref) => (
    <>
      <CustomLabel>
        {label} {tooltip}
      </CustomLabel>

      <CustomInput
        reduced
        defaultValue={value}
        onFocus={onClick}
        onClick={onClick}
        onBlur={onChange}
        ref={ref} />
    </>
  ))

  const handleChange = (index, recordType, key, value) => {
    setFormData((prevState) => {
      if (REQUIRED_FIELDS.includes(key) && prevState.orders[index].errors) {
        prevState.orders[index].errors = {}
      }
      prevState.orders[index][recordType] = {
        ...prevState.orders[index][recordType],
        [key]: value
      }
      return { ...prevState }
    })
  }

  const goBack = (previousClicked = true) => history.push({
    pathname: 'student-information',
    state: {
      previousClicked
    }
  })

  const modalCloseHandler = () => {
    setIsAlertModal(false)
    !errorMessage && setFormData(initialState)
    goBack(errorMessage)
  }

  const getAttemptsData = (orders, relationshipId) =>
    orders.map(({ attempts: { note, status, ...data } }) => ({
      ...data,
      ...(relationshipId ? { relationship: [relationshipId] } : {}),
      ...(note ? { note: note.label } : {}),
      status: status.label
    }))

  const getPurchasesData = (orders, relationshipId) =>
    orders.map(
      ({
        attempts: { comments: noteAttributes },
        purchases: { purchaseDate, paymentStatus, paidAmount, ...data }
      }) => ({
        ...data,
        paidAmount: parseFloat(paidAmount),
        paymentStatus: paymentStatus.label,
        ...(relationshipId ? { relationship: [relationshipId] } : {}),
        noteAttributes,
        purchaseDate: purchaseDate.toISOString()
      })
    )

  const getTokenData = (orders) => {
    return orders.map(
      ({
        token: { tokenComments, issueDate, expirationDate, relationship, ...data }
      }) => ({
        ...data,
        relationship: relationship ? relationship.id : null,
        comments: tokenComments || '',
        tokenExpirationDate: expirationDate.toISOString(),
        tokenIssueDate: issueDate.toISOString()
      })
    )
  }

  const getValues = (list) => list.map(item => item.value)

  const getCoursesId = (cohorts) => {
    return cohorts.map(({ course }) => course[0])
  }
  const updateOrderNumbers = () => {
    lastPartnershipOrderNumber.current = partnershipLastNumber
    lastScholarshipOrderNumber.current = scholarshipLastNumber

    const { orders = [] } = formData
    for (let i = 0; i < orders?.length; i++) {
      const {
        token: { relationship: { relationshipType: [type] = [] } = {} }
      } = orders[i]
      const isPartnership = type === PARTNERSHIP

      if (isPartnership) {
        lastPartnershipOrderNumber.current = lastPartnershipOrderNumber.current + 1
      } else {
        lastScholarshipOrderNumber.current = lastScholarshipOrderNumber.current + 1
      }

      handleChange(
        i,
        'purchases',
        'orderNumber',
        generateOrderNumber(
          isPartnership
            ? lastPartnershipOrderNumber.current
            : lastScholarshipOrderNumber.current,
          isPartnership
        )
      )
    }
  }

  const onSubmit = async () => {
    const { orders, cohorts, relationship: { id: relationshipId = '' } } = formData
    let isError = false
    orders.forEach(({ attempts, purchases }, index) => {
      for (const key of REQUIRED_FIELDS) {
        if (
          (purchases[key] !== undefined && !purchases[key]) ||
          (attempts[key] !== undefined && !attempts[key])
        ) {
          isError = true
          orders[index].errors = {
            [key]: 'field is required'
          }
        }
      }
    })
    setFormData((prevState) => ({
      ...prevState,
      orders
    }))

    if (isError) {
      return
    }

    const studentData = {
      ...formData,
      cohorts: getValues(cohorts),
      courses: getCoursesId(cohorts),
      attempts: getAttemptsData(orders, relationshipId),
      purchases: getPurchasesData(orders, relationshipId),
      tokens: getTokenData(orders)
    }
    delete studentData.orders
    delete studentData.relationship
    delete studentData.emailExistError
    delete studentData.lastOrders

    try {
      setLoading(true)
      const { success, message } = await api.addNewStudent(studentData)
      success ? setIsAlertModal(true) : setErrorMessage(message)
    } catch ({ message }) {
      setErrorMessage(message)
    } finally {
      setLoading(false)
      setIsAlertModal(true)
    }
  }

  if (!allowView) {
    history.push('/students/student-information')
    return null
  }

  const {
    id,
    email,
    orders
  } = formData

  return (
    <CustomFormGroup>
      <CustomToolTip
        className='tooltip'
        multiline
        effect='solid'
        place='right'
      />
      <SubHeader>Student Information</SubHeader>
      <InfoText>Student ID: {id}</InfoText>
      <InfoText>Email: {email}</InfoText>
      {orders.map((node, index) => {
        const {
          errors,
          cohort: { label },
          purchases = {},
          attempts = {},
          token = {}
        } = node
        const {
          orderNumber,
          purchaseDate,
          paidAmount,
          paymentStatus = '',
          pmtMethod,
          testOrder
        } = purchases
        const {
          attemptID,
          status,
          note,
          testAttempt,
          comments,
          scholarship
        } = attempts
        const {
          relationship,
          tokenNumber,
          refundability,
          returnTo,
          issueDate,
          expirationDate
        } = token
        return (
          <div key={label}>
            <CourseInfo>{label}</CourseInfo>

            <RecordHeading>Purchase Record</RecordHeading>
            <CustomLabel>Order Number</CustomLabel>
            <CustomInput reduced disabled value={orderNumber} type='text' />

            <CustomLabel>Paid Amount</CustomLabel>
            <div>
              <CustomCurrencyInput
                onValueChange={(value) =>
                  handleChange(index, 'purchases', 'paidAmount', value)
                }
                placeholder='Enter paid amount'
                value={paidAmount}
                className='form-control'
                prefix='$'
                reduced
              />
              <ErrorText>{errors && errors.paidAmount}</ErrorText>
            </div>

            <CustomLabel>Payment Status</CustomLabel>
            <div>
              <CustomSelect
                value={paymentStatus}
                onChange={(status) =>
                  handleChange(index, 'purchases', 'paymentStatus', status)
                }
                placeholder='Select payment status'
                options={allPaymentStatuses}
                styles={courseSelectStyles}
                classNamePrefix='select'
                isClearable
                reduced
              />
              <ErrorText>{errors && errors.paymentStatus}</ErrorText>
            </div>

            <CustomLabel>Payment Method</CustomLabel>
            <div>
              <CustomInput
                reduced
                value={pmtMethod || ''}
                onChange={(e) =>
                  handleChange(index, 'purchases', 'pmtMethod', e.target.value)
                }
                type='text'
                placeholder='Enter payment method'
              />
              <ErrorText>{errors && errors.pmtMethod}</ErrorText>
            </div>

            <DateContainer>
              <DatePicker
                onChange={(date) =>
                  handleChange(index, 'purchases', 'purchaseDate', date)}
                selected={purchaseDate || new Date()}
                customInput={
                  <CustomDateInput
                    label='Purchase Date'
                    tooltip={
                      <Info data-tip={PURCHASED_DATE_TOOLTIP} />
                    } />
                }
                dateFormat='MM/dd/yyyy, h:mm aa'
                showTimeSelect
              />
            </DateContainer>

            <CheckBox>
              Test Order
              <input
                onChange={(e) =>
                  handleChange(index, 'purchases', 'testOrder', e.target.checked)
                }
                checked={!!testOrder}
                type='checkbox'
              />
              <span />
            </CheckBox>

            <RecordHeading>Attempt Record</RecordHeading>
            <CustomLabel>Attempt ID</CustomLabel>
            <CustomInput reduced disabled value={attemptID} type='text' />

            <CustomLabel>Student Status*</CustomLabel>
            <div>
              <CustomSelect
                value={status}
                onChange={(status) =>
                  handleChange(index, 'attempts', 'status', status)
                }
                placeholder='Select student status'
                options={allStudentStatuses}
                styles={courseSelectStyles}
                classNamePrefix='select'
                reduced
              />
              <ErrorText>{errors && errors.status}</ErrorText>
            </div>

            <CustomLabel>Status Note</CustomLabel>
            <div>
              <CustomSelect
                value={note}
                onChange={(note) =>
                  handleChange(index, 'attempts', 'note', note)
                }
                placeholder='Select a status note'
                options={allStudentNotes}
                styles={courseSelectStyles}
                classNamePrefix='select'
                isClearable
                reduced
              />
              <ErrorText>{errors && errors.note}</ErrorText>
            </div>

            <CheckBox marginTop='6px'>
              Scholarship Student
              <input
                onChange={(e) =>
                  handleChange(index, 'attempts', 'scholarship', e.target.checked)
                }
                checked={!!scholarship}
                type='checkbox'
              />
              <span />
            </CheckBox>
            <CheckBox>
              Test Attempt
              <input
                onChange={(e) =>
                  handleChange(index, 'attempts', 'testAttempt', e.target.checked)
                }
                checked={!!testAttempt}
                type='checkbox'
              />
              <span />
            </CheckBox>

            <CustomLabel>
              Comments <Info data-tip={COMMENTS_TOOLTIP} />
            </CustomLabel>
            <CustomInput
              onChange={(e) =>
                handleChange(index, 'attempts', 'comments', e.target.value)
              }
              value={comments}
              type='textarea'
            />

            <RecordHeading>Token Record</RecordHeading>

            <CustomLabel>Relationship</CustomLabel>
            <div>
              <CustomSelect
                value={relationship}
                onChange={(relationship) => {
                  handleChange(index, 'token', 'relationship', relationship)
                  handleAutomaticFields(index)

                  const { id: relationshipId } = relationship || {}
                  handleChange(index, 'attempts', 'relationship', [relationshipId])
                  handleChange(index, 'purchases', 'relationship', [relationshipId])

                  updateOrderNumbers()
                }}
                placeholder='Select a partnership or scholarship'
                options={allStudentRelationships}
                styles={courseSelectStyles}
                classNamePrefix='select'
                reduced
              />
            </div>

            <CustomLabel>Token Number</CustomLabel>
            <CustomInput
              reduced
              disabled
              value={tokenNumber || ''}
              type='text'
            />

            <CustomLabel>
  Return to <Info data-tip={RETURN_TO_TOOLTIP} />
            </CustomLabel>
            <CustomInput
              reduced
              disabled
              value={returnTo || 'studentReturn'}
              type='text'
            />

            <CustomLabel>
  Refundability <Info data-tip={REFUNDABILITY_TOOLTIP} />
            </CustomLabel>
            <CustomInput
              reduced
              disabled
              value={refundability || 'studentRefundable'}
              type='text'
            />

            <DateContainer>
              <DatePicker
                onChange={(date) =>
                  handleChange(index, 'token', 'issueDate', date)}
                selected={issueDate || new Date()}
                customInput={<CustomDateInput label='Issue Date' />}
                dateFormat='MM/dd/yyyy, h:mm aa'
                showTimeSelect
              />
            </DateContainer>

            <DateContainer>
              <DatePicker
                onChange={(date) =>
                  handleChange(index, 'token', 'expirationDate', date)}
                selected={expirationDate || new Date()}
                customInput={<CustomDateInput label='Expiration Date' />}
                dateFormat='MM/dd/yyyy, h:mm aa'
                showTimeSelect
              />
            </DateContainer>
          </div>
        )
      })}
      {loading ? (
        <i className='fa fa-spinner fa-spin' />
      ) : (
        <>
          <RequiredText>* Required</RequiredText>
          <div>
            <CustomButton
              data-testid='submit-button'
              onClick={onSubmit}
              className='btn-custom btn-primary'
            >
              Submit
            </CustomButton>
            <CustomButtonSecondary
              onClick={goBack}
              className='btn-custom'
            >
              Previous
            </CustomButtonSecondary>
          </div>
        </>
      )}
      <AlertModal
        showModalHandler={modalCloseHandler}
        confirm={{
          text: 'ok',
          handler: modalCloseHandler
        }}
        show={isAlertModal}
      >
        {errorMessage || 'Student added successfully'}
      </AlertModal>
    </CustomFormGroup>
  )
}

AttemptsAndPurchases.displayName = 'AttemptsAndPurchases'
export default AttemptsAndPurchases

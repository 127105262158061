import styled from 'styled-components'
import AsyncSelect from 'react-select/async'
import mediaqueries from '../../../mediaQueries'
import { CustomDatePicker } from '../../VipUser/styled'
import {
  CustomCurrencyInput,
  CustomSelect,
  CustomInput,
  CustomLabel,
  CustomButton
} from '../../Students/style'
import { courseSelectStyles } from '../../global.styled'

export const ConfirmationModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 21px;
  gap: 24px;
  background: #25272B;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  color: white;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
`

export const ModalText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`

export const ModalButtons = styled.div`
  display: flex;
  gap: 12px;
`

export const ModalOKButton = styled.button`
  border: 3px solid #5FC4B8;
  border-radius: 5px !important;
  background: #5FC4B8;
  padding: 13px 21px;
  color: #161618;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
`

export const ModalContinueButton = styled(ModalOKButton)`
  background: #25272B;
  color: #5FC4B8;
`

export const Container = styled.div`
  padding: 48px 24px 64px;
`

export const PageHeading = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  margin-bottom: 0;
`

export const Separator = styled.hr`
  margin: 32px 0px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 0px;
`

export const OptionsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  max-width: 1200px;
`

export const OptionContainer = styled.a`
  height: 152px;
  width: 341px;
  background: #25272B;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  gap: 16px;
  color: unset;

  &:hover {
    color: unset;
    text-decoration: unset;
  }

  ${mediaqueries.mobile`
    width: 100%;
  `}
`

export const TitleContainer = styled.div`
  display: flex;
`

export const Title = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 0 8px 0 16px;
`

export const Description = styled.div`
  color: #B1BFC5;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

export const Label = styled(CustomLabel)`
  width: fit-content!important;
  color: #B1BFC5;
  display: block;
`
export const StandAloneLabel = styled(Label)`
  margin-bottom: 6px;
  span {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-image: url(/tooltip-icon.svg) ;
    margin-left: 5px;
  }
`
export const Input = styled(CustomInput)`
  ${({ invalid }) => `border-color: ${invalid ? 'red' : 'rgba(255,255,255,0.3)'}!important`};
  min-height: 41px;
  height: 41px;
  width: 300px;
  margin-top: 6px;
  font-size: 18px !important;
  line-height: 22px !important;
`

export const Select = styled(CustomSelect)`
  width: 300px;
  min-height: 41px;
  margin-bottom: 21px;
`

export const AsyncSelectComponent = styled(AsyncSelect)`
  width: 300px;
  min-height: 41px;
  margin-bottom: 21px;
`

export const componentStyles = {
  ...courseSelectStyles,
  control: (base, state) => ({
    ...base,
    ...courseSelectStyles.control({}),
    width: '300px',
    height: 'auto',
    minHeight: '41px'
  }),
  menu: (base) => ({
    ...base,
    ...courseSelectStyles.menu({}),
    width: '300px'
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'white'
  })
}
export const Button = styled(CustomButton)`
  width: 200px !important;
  padding: 16px 24px;
  height: 49px;
  line-height: unset;
  margin-top: 4px;
`
export const DatePicker = styled(CustomDatePicker)`
  width: 300px;
`

export const CurrencyInput = styled(CustomCurrencyInput)`
  border-radius: 5px;
  display: block;
  width: 300px;
  margin-top: 6px;
  padding: 6px 12px;
`
export const CourseAccessSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 60px;
`

import { ORIENTATION, REVIEW } from '../Constants/chapter'

export const isOrientationChapter = chapter => {
  return chapter.type === ORIENTATION
}

export const isReviewChapter = chapter => {
  return chapter.type === REVIEW
}

export const isNonGradedChapter = (chapter) => {
  return isOrientationChapter(chapter) || isReviewChapter(chapter)
}

export const getSectionByType = (sections, sectionTypes) => {
  if (!sectionTypes?.length) return sections

  return sections?.filter(section => {
    return sectionTypes.includes(section.assignmentType)
  }) || []
}

import React from 'react'
import { Spinner, Bounce1, Bounce2, Bounce } from './styles'

function LoadingSpinner (props) {
  return (
    <Spinner
      left={props.left}
      position={props.position}
      marginTop={props.marginTop}
      height={props.height}
      data-testid='loadingSpinner'
    >
      <Bounce1 />
      <Bounce2 />
      <Bounce />
    </Spinner>
  )
}

export default LoadingSpinner

import React from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  PageTitle,
  Separator,
  Description,
  OptionContainer,
  OptionsContainer, Title,
  TitleContainer
} from '../global.styled'

import enrollStudentIcon from '../../assets/icons/person-add.svg'
import chevronRight from '../../assets/icons/chevron-right.svg'
import bulkEnrollStudentIcon from '../../assets/icons/people.svg'

const EnrollStudentsTool = () => {
  return (
    <Container>
      <PageTitle>Enroll Students</PageTitle>
      <Separator />
      <OptionsContainer>
        <OptionContainer as={Link} to='/enroll-students/enroll'>
          <TitleContainer>
            <img src={enrollStudentIcon} alt='Enroll a student' />
            <Title>
              Enroll a student
            </Title>
            <img src={chevronRight} alt='Navigate to enrollment page' />
          </TitleContainer>
          <Description>
            Enroll a student into a course or setup a test account
          </Description>
        </OptionContainer>
        <OptionContainer as={Link} to='/students-csv-import'>
          <TitleContainer>
            <img src={bulkEnrollStudentIcon} alt='Bulk enroll students' />
            <Title>
              Bulk enroll students
            </Title>
            <img src={chevronRight} alt='Navigate to bulk enrollment page' />
          </TitleContainer>
          <Description>
            Enroll a group of students via CSV upload
          </Description>
        </OptionContainer>
      </OptionsContainer>
    </Container>
  )
}

export default EnrollStudentsTool

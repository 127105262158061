import styled, { css } from 'styled-components'
import DatePicker from 'react-datepicker'
import calendarSvg from '../../../assets/icons/calendar.svg'
import checkMark from '../../../assets/icons/check-mark.svg'
import { CSVLink } from 'react-csv'

export const VipCheckBox = styled.label`
  display: flex;
  align-items: center;
  width: auto;
  position: relative;
  padding-left: 32px;
  margin-bottom: ${({ margin }) => margin || '48px'};
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* hide the default checkbox */
  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  /* create a custom checkbox */
  span {
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--canvas-primary);
    border: 2px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 5px;
  }

  /* When the checkbox is checked */
  input:checked ~ span {
    background-color: var(--brand-regular);
    border: 3px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 5px;
  }

  /* When the checkbox is disabled */
  input:disabled ~ span {
    border: 2px solid var(--brand-regular-disabled);
    cursor: not-allowed;
  }

  input:disabled ~ span::after {
    margin: 1px;
  }

  /* Create the check-mark/indicator */
  span::after {
    display: none;
    position: absolute;
    content: url(${checkMark});
  }

  /* Show the check-mark when checked */
  input:checked ~ span::after {
    top: -3px;
    left: 0;
    width: 12px;
    height: 9.55px;
    display: block;
  }
`

export const DatePickerContainer = styled.div`
  position: relative;

  .react-datepicker {
    background: var(--black-quaternary);
    box-shadow: 0px 0px 20px var(--shadow-primary);
    border: 2px solid var(--border-primary);
    border-radius: 5px;
    color: var(--text-primary);
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
  }

  .react-datepicker__header {
    background: var(--black-quaternary);
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--border-primary);
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: var(--text-primary);
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    border-radius: 5px;
    width: 24px;
    height: 24px;
    font-weight: normal;
    background-color: var(--brand-regular);
    color: var(--text-dark);
  }

  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__day:hover {
    background-color: var(--brand-regular-hover);
    color: var(--text-dark);
  }

  .react-datepicker__input-container::after {
    position: absolute;
    pointer-events: none;
    filter: brightness(0) invert(1);
    ${({ disabled }) => disabled && 'opacity: 0.3;'}
    content: url(${calendarSvg});
    right: 12px;
    bottom: 7px;
  }
`

export const CustomDatePicker = styled(DatePicker)`
  background: var(--input-primary);
  outline: none;
  border: 1px solid var(--border-primary);
  padding-left: 12px;
  border-radius: 5px;
  color: var(--text-primary);
  width: 400px;
  height: 40px;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--text-dark-off);
    font-size: 16px;
    line-height: 19px;
  }

  &:hover:not(:disabled) {
    border: 1px solid var(--brand-regular);
  }

  &:focus {
    border: 1px solid var(--brand-regular);
  }

  &:disabled {
    color: var(--gray-tertiary);
    background: var(--input-primary);
    border: 1px solid var(--border-primary);

    &::placeholder,
    &::-webkit-input-placeholder {
      color: var(--gray-tertiary);
    }
  }
`

export const selectInputStyles = {
  indicatorSeparator: (base, state) => ({
    ...base,
    margin: '4px 0',
    backgroundColor: state.isDisabled
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(255, 255, 255, 0.3)'
  }),
  control: (base, state) => ({
    ...base,
    minHeight: '40px',
    background: state.isDisabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.3)',
    color: 'var(--text-primary)',
    border: `1px solid ${
      state.selectProps.hasError
        ? 'var(--error-color)'
        : state.isDisabled
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.3)'
    }`,
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid rgba(255, 255, 255, 0.3)'
    }
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.isDisabled ? '#495057' : '#6c757d',
    marginLeft: '5px',
    fontSize: '1rem'
  }),
  menu: (base) => ({
    ...base,
    background: ' #060606',
    color: 'var(--text-primary)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    marginTop: '15px'
  }),
  input: (base) => ({
    ...base,
    color: 'var(--text-primary)'
  }),
  menuList: (base) => ({
    ...base,
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: !state.isSelected ? '#060606' : 'rgba(255, 255, 255, 0.1)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none',
      borderRadius: '0 0 5px 5px'
    },
    '&:first-of-type': {
      borderRadius: '5px 5px 0 0'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled && 'rgba(255, 255, 255, 0.3)'
  }),
  multiValueLabel: (base) => ({
    ...base,
    color: 'var(--text-primary)',
    display: 'flex',
    fontSize: '16px',
    lineHeight: '19px'
  }),
  multiValue: (base) => ({
    ...base,
    background: 'var(--black-secondary)',
    color: 'var(--text-primary)'
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--text-primary)'
  })
}

export const Separator = styled.hr`
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  margin: ${({ margin }) => `${margin}px 0`};
`

export const WarningWrapper = styled.div`
  display: flex;
  max-width: 400px;
  padding: 19px 16px;
  gap: 16px;
  border-radius: 5px;
  background: var(--warning-bg);

  & > p {
    margin: 0;
    font-size: 14px;
  }

  & > svg {
    min-width: 16px;
    height: 16px;
    align-self: center;
    color: var(--warning-color);
  }
`
export const ToolDescription = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 32px;
  max-width: 600px;
`
export const ChooseToolMode = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  & > p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 0;
  }
  & > div,
  & label {
    display: flex;
  }
  & label,
  & input {
    cursor: pointer;
  }
`
export const DownloadCSVTemplateContainer = styled.div`
  & > p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 12px;
  }
`
export const DownloadTemplate = styled(CSVLink)`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  display: flex;
  height: 56px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background-color: var(--brand-dark);
  color: var(--brand-regular);
  width: fit-content;
  text-decoration: none;

  &:hover {
    background-color: #214b52;
    color: var(--brand-regular-hover);
    text-decoration: none;
  }

  :active {
    opacity: 0.3;
  }

  :focus {
    outline: none;
  }
  :focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
`
export const FormWrapper = styled.div`
  & > .form-group {
    display: flex;
    flex-direction: column;
  }
`
export const UploaderContainer = styled.div`
  max-width: 400px;
  margin-bottom: 22px;

  ${({ csvHasErrors }) =>
    csvHasErrors &&
    css`
      & div:has(#remove) :is(p, a) {
        color: #ff8064 !important;
      }
    `}
`
export const CsvError = styled.div`
  color: #ff8064;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 8px;
  & > p {
    margin-bottom: 8px;
  }
  & > ul {
    padding-left: 22px;
    margin: 0;
  }
`
export const FileUploaderHelperText = styled.p`
  color: var(--text-dark-off);
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
  margin-bottom: 0;
`
export const ToastTitleContainer = styled.span`
  display: flex;
  gap: 8px;
  align-items: center;

  & > p {
    margin: 0;
  }
`

import styled from 'styled-components'
import { ReactComponent as BackArrowSVG } from '../../../../assets/icons/modal-back-arrow.svg'
import mediaqueries from '../../../../mediaQueries'
import CustomSelect from '../../../CustomSelect/UpdatedSelect'

export const BackLink = styled.a`
  display: flex;
  width: fit-content;
  margin-bottom: 24px;
  align-items: center;
  cursor: pointer;
  color: var(--brand-regular) !important;
  letter-spacing: 1px;

  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  font-style: normal;
  text-transform: uppercase;
  
  &:hover {
    text-decoration: none;
    color: var(--brand-regular-hover) !important;
  }
`

export const BackArrowIcon = styled(BackArrowSVG)`
  width: 16px;
  height: 16px;
  margin-right: 12px;
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${mediaqueries.mobile`
    flex-direction: column;
    gap: 24px;
    align-items: flex-start;
  `}
`

export const PageHeading = styled.h1`
  font-weight: 300;
  font-size: 32px;
  line-height: normal;
  margin-bottom: 0;
`

export const LinkContainer = styled.div`
  display: flex;
  gap: 24px;
`

export const Link = styled.div`
  color: var(--brand-regular);
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;

  background: var(--brand-dark);
  padding: 8px;
  border-radius: 5px;
`

export const PageContainer = styled.div`
  padding: 24px 32px;
`

export const Separator = styled.hr`
  margin: 24px 0;
  height: 1px;
  border-color: rgba(255, 255, 255, 0.3);
`

export const PageDescription = styled.p`
  color: #FFF;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 24px;
`
export const TableHeaderWrapper = styled.div`
  padding: 0 17px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const RowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  ::before{
    width: ${props => props.expandRow ? '2px' : 0};
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    background-color: #5FC4B8;
    top: 0;
  }
`

export const Row = styled.div`
  display: flex;
  gap: 32px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: start;
  cursor: pointer;
  padding: 6px 0;
`

export const HeaderCell = styled.div`
  width: 315px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--text-secondary);
`

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 654px;
  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: var(--border-secondary) transparent;
  /* For Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent!important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 20px;
  }
`

export const TokenRow = styled.div`
  display: flex;
  gap: 32px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  cursor: pointer;

  :hover {
    background-color: var(--black-secondary);
  }
`
export const TokenCell = styled(HeaderCell)`
  padding: 24px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  color: var(--text-primary);
  text-transform: none;
  word-break: break-word;
`

export const ExpandedRowWrapper = styled.div`
  padding:0 0 32px 24px;
  ${mediaqueries.tablet`
    padding-block-start: 0;
    padding-block-end: 24px;
    padding-inline-start: 17px;
  `}
`
export const ExpandedRowHeaderWrapper = styled(Row)`
  padding: 25px 0 25px 0;
  cursor: auto;
  ${mediaqueries.tablet`
  padding: 0;
  & > div {
    display: none;
  }
  `}
`

export const ExpandedRowHeaderCell = styled(HeaderCell)`
  display: flex;
  color: #89969F;
`

export const ExpandedRowCell = styled(HeaderCell)`
  display: flex;
  color: var(--text-primary);
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: normal;
  text-transform: none;
`

export const FilterHeader = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
`

export const FilterTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21.6px;
`

export const FilterReset = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: var(--brand-regular);
  cursor: pointer;

  ${props => props.disabled && `
    opacity: 30%;
  `}
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 32px;

  ${mediaqueries.smallMobile`
    flex-direction: column;
  `}
`

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const FilterLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #CCD4D8;
`

export const FilterSelect = styled(CustomSelect)`
  width: 400px;
  min-height: 48px;

  & > .select__control {
    min-height: 48px;
  }

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`

export const StatusSelect = styled(CustomSelect)`
  width: 320px;
  min-height: 48px;

  & > .select__control {
    min-height: 48px;
  }

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`

export const PaginationContainer = styled.div`
  background-color: #1D1D1F;
  color: #5FC4B8;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
`
export const PageIndex = styled.button`
  background-color: #1D1D1F;
  border: none;
  padding: 0;
  color: ${props => props.selected ? 'white' : '#5FC4B8'};
  &:hover {
    color: #B8FFF7;
  }
  &:focus {
    outline: none;
  }
  &:active {
    color: #B8FFF7;
  }
`

export const PageIndicator = styled.span`
  color: #B1BFC5;
  margin-left: auto;
`

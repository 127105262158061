import React from 'react'
import StudentSearchForm from './StudentSearchForm'
import { SearchContainer, CustomButton } from './styles'
import CohortSearchForm from './CohortSearchForm'

const SearchCriteria = ({
  selection,
  state,
  onSubmit,
  step,
  handleChange
}) => {
  const { course, cohort, students, startDate, endDate } = state
  let isDisabled = !course || !cohort

  if (selection === 'students') {
    isDisabled = !students.length || isDisabled
  }

  return (
    <SearchContainer>
      <CohortSearchForm
        course={course}
        cohort={cohort}
        handleChange={handleChange}
      />
      {selection === 'students' && (
        <StudentSearchForm
          students={students}
          handleChange={handleChange}
          startDate={startDate}
          endDate={endDate}
        />
      )}
      <CustomButton
        onClick={() => {
          onSubmit()
        }}
        step={step}
        disabled={isDisabled}
        selection={selection}
        className='btn-custom btn-primary'
      >
        Confirm
      </CustomButton>
    </SearchContainer>
  )
}

export default SearchCriteria

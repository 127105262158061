import moment from 'moment'
import api from '../api'
import {
  getCourseIds,
  philosophyUpdatedGradingReleaseDate,
  collegeWritingIUpdatedAssignmentReleaseDate,
  additionalCourseIdToName
} from '../config'
import {
  timeStampToFormattedDate,
  dateToSecondsSinceEpoch,
  getTimezoneShort,
  addDotBtwDateAndTime,
  formatWithoutYear
} from './dateTimeUtil'
import { getCohortStartSecondsSinceEpoch } from './courseUtils'
import { mapLimit } from '.'

export const formatDateStart = date => {
  const formattedDate = moment.utc(date).format('MM/DD/YY')
  return formattedDate
}

export const getCohortDetails = milestone => {
  const { dateStart, duration } = milestone
  const paddedCohortDuration = String(duration).padStart(2, '0')
  return `${formatDateStart(dateStart)} - ${paddedCohortDuration}w`
}

export const getFormattedMilestones = (cohorts, milestones) => {
  return milestones
    .map(milestone => {
      const { dateStart, duration, cohortName } = milestone
      const cohort = cohorts.find(cohort => cohort.name === cohortName)
      const { endTime, finalExamEndTime } = cohort || {}

      const endTimes = [
        dateToSecondsSinceEpoch(new Date(endTime)),
        dateToSecondsSinceEpoch(new Date(finalExamEndTime))
      ].filter(time => !isNaN(time))
      const latestEndTime = Math.max(...endTimes)

      if (!cohort) return []
      return milestone.milestones.map(m => {
        return {
          ...m,
          cohortId: cohort.at_id,
          dateStart,
          duration,
          cohortName,
          cohortEndTime: latestEndTime
        }
      })
    })
    .flat()
}

export const fetchCohortMilestones = async (courseId, cohortName) => {
  const cohorts = await api.getAllCohorts({ courseId })
  const currentCohort = cohorts.find(cohort => cohort.name === cohortName)
  const currentCohortMilestone = await api.getCohortMilestones(currentCohort.at_id)
  if (!currentCohortMilestone) return null

  const updatedCurrentCohortMilestone = {
    ...currentCohortMilestone,
    cohortEndTime: currentCohort.endTime || currentCohort.finalExamEndTime
  }

  return getFormattedMilestones(cohorts, [updatedCurrentCohortMilestone])
}

export const fetchDatoAssignmentDetails = async (courseId, assignmentUUID) => {
  const courseData = await api.getCourseData(courseId)
  return courseData.chapters.find(chapter =>
    chapter.chapter_uuid === assignmentUUID)
}

export const getCurrentAssignment = async ({
  courseId,
  assignmentUUID,
  cohortName
}) => {
  const datoAssignmentDetails = await fetchDatoAssignmentDetails(courseId, assignmentUUID)
  if (!datoAssignmentDetails) return null
  const airtableAssignmentMilestones = await fetchCohortMilestones(courseId, cohortName)
  if (!airtableAssignmentMilestones) return null

  const currentAssignment = airtableAssignmentMilestones.find(
    milestone => (milestone.datoAssignmentUUID === assignmentUUID &&
      milestone.cohortName === cohortName))

  if (!currentAssignment) return null

  const updatedDatoAssignmentDetails = getUpdatedDatoAssignmentDetails({
    courseId,
    currentAssignment,
    datoAssignmentDetails
  })

  return {
    assignmentUUID,
    label: updatedDatoAssignmentDetails.title,
    value: updatedDatoAssignmentDetails.chapter_uuid,
    cohortMilestone: { ...currentAssignment },
    ...updatedDatoAssignmentDetails
  }
}

export const getUpdatedDatoAssignmentDetails = ({
  courseId,
  currentAssignment,
  datoAssignmentDetails
}) => {
  const { dateStart } = currentAssignment

  const {
    assignmentDetails,
    updatedassignmentdetails,
    updatedassignmentdetails2,
    description,
    updateddescription,
    title,
    updateddisplaytitle,
    maxScore,
    updatedmaxscore,
    updatedrubric,
    updatedRubrics,
    rubric
  } = datoAssignmentDetails

  const cohortStartDate = getCohortStartSecondsSinceEpoch({ dateStart })

  const isCollegeWritingI = courseId === getCourseIds()['collegewriting-i'] ||
    courseId === getCourseIds().test
  const shouldUseCollegeWritingIUpdatedAssignment = isCollegeWritingI &&
    cohortStartDate >= collegeWritingIUpdatedAssignmentReleaseDate

  const isPhilosophy = courseId === getCourseIds().philosophy
  const shouldUsePhilosophyNewGrading = isPhilosophy &&
    cohortStartDate >= philosophyUpdatedGradingReleaseDate

  const cohortRubric = getRubric({
    rubric,
    updatedRubric: updatedrubric,
    useUpdatedRubric: shouldUsePhilosophyNewGrading,
    rubrics: updatedRubrics,
    cohortStartDate: cohortStartDate
  })

  if (shouldUseCollegeWritingIUpdatedAssignment) {
    return {
      ...datoAssignmentDetails,
      assignmentDetails: updatedassignmentdetails2 || assignmentDetails,
      rubric: cohortRubric
    }
  }

  if (!shouldUsePhilosophyNewGrading) return { ...datoAssignmentDetails, rubric: cohortRubric }

  return {
    ...datoAssignmentDetails,
    assignmentDetails: updatedassignmentdetails || assignmentDetails,
    description: updateddescription || description,
    title: updateddisplaytitle || title,
    maxScore: getUpdatedMaxScore(updatedmaxscore) || maxScore,
    rubric: cohortRubric
  }
}

export const getUpdatedMaxScore = score => {
  if (!score) return null

  return parseInt(score.split(';')[1])
}

export const getLockTime = (assignment) => {
  const {
    cohortMilestone: {
      lockTime,
      duration,
      dateStart }
  } = assignment

  // if lockTime is empty, student deadline is
  // calculated using [cohort dateStart + (cohort duration * 7) ].
  const newDateStart = new Date(dateStart)
  const totalDuration = duration * 7
  const newLockTime =
    lockTime ||
    newDateStart.setDate(newDateStart.getDate() + totalDuration)
  return newLockTime
}

export const isSubmissionGraded = submission => {
  const { grade } = submission
  // when an assignment is not graded, grade is undefined
  // when an assignment is graded, then it becomes a number (the grade)
  return typeof grade === 'number'
}

export const isSubmissionLate = submission => {
  const { cohortDeadline, submissionTime } = submission
  // the assignment is late when submissionTime is later than
  // the deadline for the assignment
  // this situation only occurs if the student is given extension on deadline
  return submissionTime > cohortDeadline
}

export const filteredSubmissions = (submissions, assignmentList, courseId) => {
  if (!submissions || !submissions.length) return []
  const assignmentUUIDList = assignmentList.map(assignment => assignment.assignmentUUID)

  return submissions
    .flatMap(submission => {
      const { assignments, ...rest } = submission
      return assignments.map(submissionAssignment => {
        const { assignmentUUID, cohortId, deadlineTime } = submissionAssignment
        const assignment = assignmentList.find(
          item => {
            return item.assignmentUUID === assignmentUUID &&
            item.cohortMilestone.cohortId === cohortId
          })
        if (!assignment) return null
        const { cohortMilestone } = assignment
        const cohortDetails = getCohortDetails(cohortMilestone)
        const newLockTime = getLockTime(assignment)
        const cohortDeadline = dateToSecondsSinceEpoch(new Date(newLockTime))
        const deadline = deadlineTime || cohortDeadline

        const updatedAssignment = getUpdatedDatoAssignmentDetails({
          courseId,
          currentAssignment: assignment.cohortMilestone || {},
          datoAssignmentDetails: assignment
        })

        return {
          courseId: submission.courseId || courseId,
          cohortDeadline,
          cohortDetails,
          deadline,
          ...updatedAssignment,
          ...submissionAssignment,
          ...rest
        }
      })
    })
    .filter(submission =>
      submission &&
      assignmentUUIDList.includes(submission.assignmentUUID) &&
      submission.status === 'submitted'
    )
}

export const getGradingStatusFilteredSubmissions = (submissions, selectedGradingStatuses) => {
  if (!submissions || !selectedGradingStatuses) return []
  return submissions
    .map(submission => {
      const isGraded = isSubmissionGraded(submission)
      return { ...submission, isGraded }
    })
    .filter(submission => {
      let result = false
      selectedGradingStatuses.forEach(({ label }) => {
        if (label === 'Grade Pending' && !submission.isGraded) {
          result = true
        }
        if (label === 'Graded' && submission.isGraded) {
          result = true
        }
      })
      return result
    })
}

export const getSubmissionStatusFilteredSubmissions = (submissions, selectedSubmissionStatuses) => {
  if (!submissions || !selectedSubmissionStatuses) return []
  return submissions
    .map(submission => {
      const isLate = isSubmissionLate(submission)
      return { ...submission, isLate }
    })
    .filter(submission => {
      let result = false
      selectedSubmissionStatuses.forEach(({ label }) => {
        if (label === 'On Time' && !submission.isLate) {
          result = true
        }
        if (label === 'Late' && submission.isLate) {
          result = true
        }
      })
      return result
    })
}

export const getFilteredSubmissions = async ({
  courseId,
  cohortNamesList,
  assignmentList,
  fromCache
}) => {
  if (!assignmentList || !assignmentList.length) return []
  let submissions = await mapLimit(cohortNamesList, 4, async (name) => {
    const submissions = await api.getStudentSubmissions(
      courseId,
      `cohortName=${encodeURIComponent(name)}`,
      fromCache
    )
    return submissions
  })

  const additionalCourseId = additionalCourseIdToName(courseId)?.[1]

  if (additionalCourseId) {
    const additionalSubmissions = await mapLimit(cohortNamesList, 4, async (name) => {
      const submissions = await api.getStudentSubmissions(
        additionalCourseId,
        `cohortName=${encodeURIComponent(name)}`,
        fromCache
      )
      return submissions.map(submission => {
        return { ...submission, courseId: additionalCourseId }
      })
    })
    submissions = [...submissions, ...additionalSubmissions]
  }

  if (!submissions || !submissions.length) return []

  return filteredSubmissions(submissions.flat(), assignmentList, courseId)
}

export const getFormattedTime = seconds => {
  if (!seconds) return null
  const timezone = getTimezoneShort(seconds)
  return addDotBtwDateAndTime(
    formatWithoutYear(new Date(seconds * 1000))) + ` ${timezone}`
}

export const getSubmissionTime = seconds => {
  if (!seconds) return null
  const submissionTime = timeStampToFormattedDate(seconds * 1000, true, 'short')
  return submissionTime && submissionTime.replace(/(,[^,]*),/g, '$1')
}

export const getGradedAssignments = assignmentProgress => {
  const gradedAssignments = []
  if (!assignmentProgress) return gradedAssignments

  Object.keys(assignmentProgress).forEach(assignmentUUID => {
    const currentAssignmentProgress = assignmentProgress[assignmentUUID]
    // by default grade key is absent, it becomes a number after grading
    if (typeof assignmentProgress[assignmentUUID].grade === 'number') {
      gradedAssignments.push({
        ...currentAssignmentProgress,
        assignmentUUID
      })
    }
  })

  return gradedAssignments
}

export const getStudentGrade = (grade, maxScore) => {
  if (!grade && grade !== 0) return
  const studentGrade = Math.round(((grade / 100) * maxScore) * 100) / 100

  return `${studentGrade}/${maxScore}`
}

export const getRubric = ({
  rubric,
  updatedRubric,
  useUpdatedRubric,
  rubrics,
  cohortStartDate
}) => {
  if (rubrics?.length) {
    const rubricsWithStartDate = rubrics.map((_rubric) => ({
      ..._rubric,
      startDate: getCohortStartSecondsSinceEpoch({ dateStart: _rubric.cohortStartDate })
    })).sort((a, b) => b.startDate - a.startDate)

    const cohortRubric = rubricsWithStartDate.find(_rubric => cohortStartDate >= _rubric.startDate)
    if (cohortRubric) return cohortRubric.url
  }

  if (useUpdatedRubric) return updatedRubric || rubric
  return rubric
}

export const getCohortIdsOfUngradedAssignments = async (cohorts, courseId) => {
  if (!cohorts.length || !courseId) return []
  // get cohorts that ended in the past 30 days
  const cohortsEndedInPast30Days = cohorts.filter(cohort => {
    const { finalExamEndTime, dateStart, name } = cohort

    const thirtyDaysAgo = moment().add(-30, 'days')

    return moment(finalExamEndTime).isBetween(thirtyDaysAgo, undefined) &&
      moment().isAfter(dateStart) && !name?.includes('Audit')
  })

  // get all assignments of those cohorts
  const promises = cohortsEndedInPast30Days.map(async cohort => (
    api.getStudentSubmissions(
      courseId, `cohortName=${encodeURIComponent(cohort?.name)}`
    )
  ))

  const cohortsAssignments = await Promise.all(promises)

  // get all assignments that are not graded and return cohortIds of those assignments

  if (!cohortsAssignments) return []

  const cohortIdsOfUngradedAssignments = cohortsAssignments.map((submissions) => {
    let cohortId

    submissions.forEach(submission => {
      const { assignments } = submission

      if (!assignments.length) return

      cohortId = assignments.find(assignment => {
        const { grade, status } = assignment
        return !grade && grade !== 0 && status === 'submitted'
      })?.cohortId
    })

    return cohortId
  })

  return cohortIdsOfUngradedAssignments.filter(id => id)
}

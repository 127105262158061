import React, { useState, useEffect } from 'react'
import { FormGroup } from 'reactstrap'
import DropdownIcon from '../../assets/icons/dropdown-arrow.svg'
import {
  DropdownToggleExtended
} from '../PermissionManager/styles'
import {
  requiredStudentCSVColumns, requiredGGUCSVColumns, steps
} from './CSVImportConstants'
import {
  CustomButtonSecondary,
  InstructionsParagraph,
  CustomColumnInput,
  DropdownItemHeader,
  DropdownContainer,
  ErrorTextExtended,
  InstructionsArea,
  ColumnsContainer,
  CustomContainer,
  CustomButton,
  DropdownItem,
  ColumnHeader,
  DropdownMenu
} from './style'

const Dropdown = ({ value, options, onChange }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState(false)

  const find = (text) => {
    if (!options) return []

    const searchSection = (obj) => {
      return Object.values(obj).filter((value) => {
        return value.value.includes(text)
      })
    }

    const resultArr = Object.keys(options).map(option => {
      return searchSection(options[option])
    })

    const resultObj = {}

    Object.keys(options).map((option, i) => {
      resultObj[option] = { ...resultArr[i] }
      return null
    })

    return resultObj
  }

  const renderOptions = () => {
    const list = () => {
      if (search) {
        return find(value)
      }
      return options
    }

    return Object.keys(list()).map((option, index) => (
      <React.Fragment key={index}>
        <DropdownItemHeader>{option}</DropdownItemHeader>
        {Object.values(list()[option]).map((subOption, subIndex) => (
          <DropdownItem
            key={subIndex}
            onClick={() => {
              onChange(subOption.value)
              setSearch(false)
            }}>
            {subOption.name}
          </DropdownItem>
        ))}
      </React.Fragment>
    ))
  }

  return (
    <FormGroup className='group-from' style={{ marginBottom: 0 }}>
      <DropdownContainer
        className='dropdown'
        isOpen={isOpen}

        toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggleExtended style={{ marginBottom: 0 }}>
          <input type='text' value={value || ''} onChange={(e) => {
            onChange(e.target.value)
            if (!isOpen) setIsOpen(true)
            setSearch(true)
          }} />
          <div style={{ display: 'flex' }}>
            <div className='divider' />
            <img
              style={{ transform: isOpen ? 'rotate(180deg)' : '' }}
              src={DropdownIcon}
              alt='dropdown-icon'
            />
          </div>
        </DropdownToggleExtended>
        <DropdownMenu>
          {renderOptions()}
        </DropdownMenu>
      </DropdownContainer>
    </FormGroup>
  )
}

function CSVColumnMapping (props) {
  const {
    csvColumns,
    databaseColumns,
    handleColumnInputChange,
    handleSubmit,
    setStep,
    isGGUAddmissionDecision
  } = props

  const { UPLOAD_CSV } = steps
  const [errorMessage, setErrorMessage] = useState(undefined)
  const [hasValidFields, setHasValidFields] = useState(true)
  const requiredCSVColumns = isGGUAddmissionDecision
    ? requiredGGUCSVColumns : requiredStudentCSVColumns

  useEffect(() => {
    const csvFields = csvColumns.map(column => {
      return column.databaseColumn
    })
    const missingFields = requiredCSVColumns.filter(
      column => !csvFields.includes(column))
    if (missingFields.length) {
      const missingFieldsString = () => missingFields.join(', ')
      setErrorMessage(
        `You’re missing the following required fields: ${missingFieldsString()}`)
      setHasValidFields(false)
    } else {
      setErrorMessage('')
      setHasValidFields(true)
    }
  }, [csvColumns, requiredCSVColumns])

  const airtableColumns = csvColumns.map(col => col.databaseColumn)
  const allAirtableColumnsPresent = airtableColumns.every(x => x)
  const isNextDisabled = !allAirtableColumnsPresent || !hasValidFields

  const databaseColumnsOptions = (databaseColumns) => {
    const result = databaseColumns
    Object.keys(databaseColumns).map(option => {
      return Object.keys(databaseColumns[option]).map(value => {
        result[option][value] = {
          name: value,
          value: `${option}.${value}`
        }
        return value
      })
    })

    return result
  }

  return (
    <FormGroup data-testid='section-column-mapping'>
      <InstructionsArea>
        <CustomContainer>
          <InstructionsParagraph>
            {
              isGGUAddmissionDecision
                ? 'Below, assign only two of the CSV columns, Email and Recommended Action, from your file to prospect.email and prospect.admissionDecision columns in the prospects tab in Airtable. The left column is autopopulated with the headers in your CSV file.'
                : 'Below, assign each CSV column from your file to an Airtable column from following tabs: Students, Attempts, and Purchases. The left column is autopopulated with the headers in your CSV file.'
            }
          </InstructionsParagraph>
        </CustomContainer>
        <CustomContainer margin='16px 0 0 0'>
          <InstructionsParagraph>
            <b>Required Fields:</b> {requiredCSVColumns.join(', ')}
          </InstructionsParagraph>
        </CustomContainer>
      </InstructionsArea>
      <ColumnsContainer>
        <div>
          <ColumnHeader>uploaded csv columns</ColumnHeader>
          {csvColumns.map((column, i) => (
            <CustomColumnInput
              disabled
              key={i}
              onChange={(e) => {
                handleColumnInputChange(i, {
                  csvColumn: e.target.value,
                  databaseColumn: column.databaseColumn
                })
              }}
              value={column.csvColumn} />
          ))}
        </div>
        <div>
          <ColumnHeader>airtable columns</ColumnHeader>
          {csvColumns.map((column, i) => {
            return (
              <div style={{ width: 334, marginTop: 24 }} key={i}>
                <Dropdown
                  value={column.databaseColumn}
                  options={databaseColumnsOptions(databaseColumns)}
                  onChange={(value) => handleColumnInputChange(i, {
                    csvColumn: column.csvColumn,
                    databaseColumn: value
                  })} />
              </div>
            )
          })}
        </div>
      </ColumnsContainer>
      <div style={{ marginTop: 48 }}>
        {errorMessage && (
          <ErrorTextExtended>
            {errorMessage}
          </ErrorTextExtended>
        )}
        <div style={{ marginTop: 18 }}>
          {isNextDisabled
            ? <CustomButtonSecondary
              background='#161618'
              className='btn-custom'
              data-testid='upload-csv-btn-next'
              noMargin
              disabled
            >
              Next Page
            </CustomButtonSecondary>
            : <CustomButton
              onClick={handleSubmit}
              className='btn-custom btn-primary'
              data-testid='upload-csv-btn-next'
              noMargin>
              Next Page
            </CustomButton>
          }
          <CustomButtonSecondary
            onClick={() => {
              setStep(UPLOAD_CSV)
            }}
            background='#161618'
            className='btn-custom'
            noMargin
          >
            Previous
          </CustomButtonSecondary>
        </div>
      </div>
    </FormGroup>
  )
}

CSVColumnMapping.displayName = 'CSVColumnMapping'
export default CSVColumnMapping

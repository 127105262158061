import React from 'react'
import CSVReviewError from './CSVReviewError'
import { steps } from './CSVImportConstants'
import {
  ReviewInstructions,
  ReviewColumnsHeader,
  ReviewColumnsCell,
  ReviewColumnsContainer,
  InstructionsArea,
  CustomContainer,
  CustomButton,
  Link,
  EditLink,
  ReviewTitle
} from './style'

function CSVReview (props) {
  const { COLUMN_MAPPING } = steps
  const {
    file,
    fileUrl,
    csvData,
    csvColumns,
    errorsTable,
    handleUploadedFile,
    handleImport,
    setStep,
    setFile,
    isGGUAddmissionDecision,
    setErrorsTable
  } = props
  const isError = !!errorsTable.length

  return (
    <InstructionsArea>
      <CustomContainer margin='36px 0 0 0'>
        <ReviewInstructions>
          Please review the following information before importing.
        </ReviewInstructions>
      </CustomContainer>
      {file && <CustomContainer margin='32px 0 0 0'>
        <ReviewTitle>
          CSV File <Link download={file.name} href={fileUrl} >DOWNLOAD</Link>
        </ReviewTitle>
        <CustomContainer
          margin='12px 0 0 0'
          style={{ lineHeight: '19px' }}
        >
          <span>{file.name}</span>
        </CustomContainer>
      </CustomContainer>}
      <CustomContainer margin='32px 0 0 0'>
        <ReviewTitle>
          Number of records
        </ReviewTitle>
        <CustomContainer
          margin='12px 0 0 0'
          style={{ lineHeight: '19px' }}
        >
          <span>
            {csvData.length}
          </span>
        </CustomContainer>
      </CustomContainer>
      <CustomContainer margin='32px 0 0 0'>
        <ReviewTitle>
          Column Mapping
          <EditLink
            href='#'
            onClick={() => {
              setStep(COLUMN_MAPPING)
              setErrorsTable([])
            }}
          >
            EDIT
          </EditLink>
        </ReviewTitle>

        <ReviewColumnsContainer>
          <div data-test-id='csvColumns'>
            <ReviewColumnsHeader>csv columns</ReviewColumnsHeader>
            {csvColumns.map((column, key) => (
              <ReviewColumnsCell key={key}>
                <span>{column.csvColumn}</span>
              </ReviewColumnsCell>
            ))}
          </div>
          <div data-test-id='airtableColumns'>
            <ReviewColumnsHeader>airtable columns</ReviewColumnsHeader>
            {csvColumns.map((column, key) => (
              <ReviewColumnsCell key={key}>
                <span>{column.databaseColumn}</span>
              </ReviewColumnsCell>
            ))}
          </div>
        </ReviewColumnsContainer>
      </CustomContainer>
      {isError && <CSVReviewError
        file={file}
        csvColumns={csvColumns}
        errorsTable={errorsTable}
        handleUploadedFile={handleUploadedFile}
        setFile={setFile}
        setStep={setStep}
        isGGUAddmissionDecision={isGGUAddmissionDecision}
        setErrorsTable={setErrorsTable}
      />}
      {!isError && (
        <CustomButton
          className='btn btn-custom btn-primary'
          onClick={() => {
            handleImport()
          }}
          style={{ marginTop: 34 }}
        >
          IMPORT
        </CustomButton>
      )}
    </InstructionsArea>
  )
}

CSVReview.displayName = 'CSVReview'
export default CSVReview

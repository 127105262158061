import React, { Fragment } from 'react'
import moment from 'moment'

export const fields = [
  {
    label: 'Student email',
    key: 'email',
    value: (violation) => violation.email
  },
  {
    label: 'School',
    value: (violation) => violation.relationship[0]?.fields.displayName
  },
  {
    label: 'Cohort',
    value: (violation) => violation.cohort[0]?.fields.name
  },
  {
    label: 'Assessment',
    value: (violation) => violation.assessment
  },
  {
    label: 'Flags',
    value: (violation) => (
      <span>
        {violation.flagsReceived.map((flag, index) => (
          <Fragment key={index}>
            {flag.type} - {moment(flag.date).format('MM/DD/YYYY  h:mma')}
            <br />
          </Fragment>
        ))}
      </span>
    )
  },
  {
    label: 'Reason for proctor report',
    value: (violation) => violation.description
  },
  {
    label: 'Notes',
    value: (violation) => violation.partnerNotes
  }
]

export const sanctionOptions = [
  { label: 'Official warning', value: 'Official warning' },
  { label: 'Grade reduced to 72.5%', value: 'Grade reduced to 72.5%' },
  { label: 'Grade reduced to 0%', value: 'Grade reduced to 0%' },
  {
    label: 'Removed from course and 0% on assessment',
    value: 'Removed from course and 0% on assessment'
  }
]

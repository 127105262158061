import React, { useEffect, useState } from 'react'
import api from '../../api'
import {
  AnnotateSubmissionButton,
  BoxArrowIcon,
  DownloadLink,
  LinksContainer,
  SubmissionFrame,
  SubmissionHeader,
  SubmissionName
} from './style'
import DownloadIcon from '../../assets/icons/download-link.svg'
import useFetchVideo from '../VideoPlayer/useFetchVideo'

const SubmissionsWrapper = ({
  children,
  content: { url, type, fileName, name, streamUrl },
  kamiUploadStatus,
  handleAnnotateSubmission,
  isCohortEndFourYearsAgo
}) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const isMp4 = type?.[0] === '.mp4'
  const { isUrlLoading, isFileNotFound } =
    useFetchVideo(isMp4 ? streamUrl : null)

  const [showDownloadButton, setShowDownloadButton] = useState(true)

  useEffect(() => {
    const shouldHideDownloadButton = isFileNotFound &&
      isCohortEndFourYearsAgo

    if (shouldHideDownloadButton) {
      setShowDownloadButton(false)
    } else {
      setShowDownloadButton(true)
    }
  }, [type, isFileNotFound, isCohortEndFourYearsAgo, isMp4])

  const downloadAssignment = () => {
    const download = (url) => {
      const link = document.createElement('a')
      link.href = url
      link.download = fileName ? fileName + type?.[0] : name
      link.click()
    }

    if (streamUrl) {
      setIsDownloading(true)
      api.getToken()
        .then(token =>
          fetch(streamUrl, { headers: { Authorization: `Bearer ${token}` } }))
        .then(response =>
          response?.headers?.get('content-type')?.includes('application/json')
            ? response.json()
            : response.blob())
        .then(async data => {
          const blob = data?.url
            ? await fetch(data.url, { mode: 'cors' }).then(res => res.blob())
            : data
          return URL.createObjectURL(blob)
        })
        .then(url => download(url))
        .catch(console.error)
        .finally(() => setIsDownloading(false))
      return
    }
    download(url)
  }

  return (
    <SubmissionFrame>
      <SubmissionHeader>
        <SubmissionName>{fileName || name}</SubmissionName>
        <LinksContainer>
          {!streamUrl && (
            <AnnotateSubmissionButton
              disabled={kamiUploadStatus.isLoading}
              error={kamiUploadStatus.isError}
              onClick={handleAnnotateSubmission}
              data-testid='annotate-submission'
            >
              <BoxArrowIcon />
              <p>annotate file</p>
            </AnnotateSubmissionButton>
          )}
          {showDownloadButton && !isUrlLoading && (
            <DownloadLink
              onClick={downloadAssignment}
              disabled={isDownloading}
              data-testid='download-file'
            >
              <img src={DownloadIcon} alt='Download' />
              {isDownloading ? <i className='fa fa-spinner fa-spin' /> : 'Download'}
            </DownloadLink>
          )}
        </LinksContainer>
      </SubmissionHeader>
      {children}
    </SubmissionFrame>
  )
}

SubmissionsWrapper.displayName = 'SubmissionsWrapper'

export default SubmissionsWrapper

import styled, { css } from 'styled-components'
import checkMark from '../assets/icons/check-mark.svg'
import DatePicker from 'react-datepicker'

export const courseSelectStyles = {
  indicatorSeparator: styles => ({ display: 'none' }),
  control: (base, state) => ({
    ...base,
    width: '100%',
    height: '40px',
    background: 'var(--input-primary)',
    color: 'white',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid var(--brand-regular)'
    }
  }),
  menu: base => ({
    ...base,
    width: '100%',
    zIndex: 1000,
    background: 'var(--canvas-dark)',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    marginTop: '0px'
  }),
  menuList: base => ({
    ...base,
    width: '100%',
    height: '100%',
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    width: '100%',
    backgroundColor: !state.isSelected ? 'var(--canvas-dark)' : 'var(--canvas-dark-off)',
    borderBottom: '1px solid var(--border-primary)',
    cursor: 'pointer',
    fontSize: '18px',
    lineHeight: '22px',
    '&:last-child': {
      borderBottom: 'none',
      borderRadius: '0 0 5px 5px'
    },
    '&:first-of-type': {
      borderRadius: '5px 5px 0 0'
    },
    '&:hover': {
      backgroundColor: 'var(--canvas-dark-off)'
    }
  }),
  placeholder: base => ({
    ...base,
    color: 'var(--white)',
    marginLeft: '5px',
    fontSize: '18px',
    lineHeight: '22px'
  }),
  singleValue: base => ({
    ...base,
    color: 'var(--white)',
    paddingLeft: '6px',
    margin: 0,
    fontSize: '18px',
    lineHeight: '22px'
  }),
  dropdownIndicator: base => ({
    ...base,
    color: 'var(--white)',
    padding: '0 8px 0 0',
    '&:hover': {
      color: 'var(--white)'
    }
  }),
  indicatorContainer: base => ({
    ...base,
    paddingLeft: 0
  })
}

export const dropDownStyles = {
  ...courseSelectStyles,
  control: (base, state) => ({
    ...base,
    width: state.selectProps.width ? state.selectProps.width : '285px',
    minHeight: state.selectProps.height ? state.selectProps.height : '40px',
    height: 'auto',
    background: 'var(--black-tertiary)',
    color: 'white',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    marginTop: '7px',
    opacity: state.isDisabled ? 0.3 : 1,
    '&:hover': {
      border: '1px solid var(--border-primary)'
    },
    '&:focus-within': {
      borderColor: 'var(--brand-regular)'
    }
  }),
  menu: (base, state) => ({
    ...base,
    width: state.selectProps.width ? state.selectProps.width : '285px',
    background: ' #060606',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    zIndex: 1000,
    marginTop: '15px'
  }),
  valueContainer: (base, state) => ({
    ...base,
    fontSize: '16px',
    paddingLeft: '13px',
    backgroundColor: 'inherit',
    color: 'var(--text-primary)',
    lineHeight: '19px',
    border: 'none',
    borderRadius: '5px',
    maxHeight: state.selectProps.isScrollable && '150px',
    overflowY: state.selectProps.isScrollable && 'auto'
  }),
  singleValue: base => ({
    ...base,
    color: 'white',
    paddingLeft: '6px',
    margin: 0,
    fontSize: '16px',
    lineHeight: '19px'
  }),
  input: base => ({
    ...base,
    color: 'var(--text-primary)',
    height: 'auto'
  }),
  multiValue: base => ({
    ...base,
    background: '#1D1D1F',
    color: 'white'
  }),
  multiValueLabel: base => ({
    ...base,
    color: 'white',
    fontSize: '18px',
    lineHeight: '22px'
  })
}

export const SortIcon = styled.img`
  height: 14px;
  width: 16px;
  margin-left: 8px;
  cursor: pointer;
  filter: ${({ active }) => active && 'brightness(2)'};
  &:hover {
    filter: brightness(2);
  }

  &:active {
    filter: brightness(2);
  }
`

export const CustomCheckBox = styled.label`
  display: block;
  width: auto;
  position: relative;
  padding-left: 20px;
  margin-bottom: -10px;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: var(--text-dark);
    border: 1px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 5px;
  }

  input:disabled ~ span {
    border: 2px solid var(--brand-regular-disabled);
  }

  input:checked ~ span {
    background-color: var(--brand-regular);
    border: 1px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 5px;
  }

  span::after {
    display: none;
    position: absolute;
    content: url(${checkMark});
  }

  input:checked ~ span::after {
    left: 2px;
    display: block;
  }
`

export const CustomButton = styled.button`
  border-radius: 5px !important;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 16px 24px;

  &:not(:disabled):not(.disabled):active {
    background-color: transparent;
    border-color: transparent;
  }
`

export const PrimaryButton = styled(CustomButton)`
  background-color: var(--brand-regular);
  color: var(--text-dark);
  border: none;
  width: ${props => props.width || 'auto'};
  margin-right: ${props => props.marginRight || '0'};
  ${props => props.small && 'padding: 8px;'}

  &:active {
    background-color: var(--brand-regular) !important;
    color: var(--text-dark) !important;
    border: 0 none !important;
  }

  &:focus {
    outline: 0;
    background-color: var(--brand-regular);
    color: var(--text-dark);
    border: none;
    box-shadow: unset;
  }

  &:hover {
    background-color: var(--brand-regular-hover);
    color: var(--text-dark);
    border: none;
  }

  &:disabled {
    color: var(--text-dark);
    background-color: var(--brand-regular-disabled);
    border-color: var(--brand-regular-disabled);
    pointer-events: none;
  }
`

export const SecondaryButton = styled(CustomButton)`
  background-color: var(--black-secondary);
  color: var(--brand-regular);
  border: 1px solid var(--brand-regular);
  width: ${props => props.width || 'auto'};

  &:active {
    background-color: var(--gray-primary) !important;
    color: var(--brand-regular) !important;
    border: 1px solid var(--brand-regular) !important;
  }

  &:focus {
    outline: 0;
    background-color: var(--gray-primary);
    color: var(--brand-regular);
    border: 1px solid var(--brand-regular);
    box-shadow: unset;
  }

  &:hover {
    color: var(--brand-regular-hover);
    border: 1px solid var(--brand-regular-hover);
    background-color: var(--gray-primary);
  }

  &:disabled {
    background-color: var(--canvas-primary);
    border-color: var(--brand-regular-disabled);
    color: var(--brand-regular-disabled);
    pointer-events: none;
  }
`

export const CustomDatePicker = styled(DatePicker)`
  background: var(--input-primary);
  outline: none;
  border: 1px solid var(--border-primary);
  padding-left: 12px;
  border-radius: 5px;
  color: var(--text-primary);
  width: 300px;
  height: 57px;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--text-dark-off);
    font-size: 16px;
    line-height: 19px;
  }

  &:hover:not(:disabled) {
    border: 1px solid var(--brand-regular);
  }

  &:focus {
    border: 1px solid var(--brand-regular);
  }

  &:disabled {
    color: var(--gray-tertiary);
    background: var(--input-primary);
    border: 1px solid var(--border-primary);

    &::placeholder,
    &::-webkit-input-placeholder {
      color: var(--gray-tertiary);
    }
  }
`

export const Container = styled.div`
  padding: 64px 32px 0 32px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export const PageTitle = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  color: var(--text-primary);
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--border-primary);
  margin: 24px 0 7px;
`

export const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 32px;
  gap: 34px;
`

export const OptionContainer = styled.a`
  height: 152px;
  width: 346px;
  background: #25272B;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: 32px 24px;
  gap: 16px;
  color: unset;

  &:hover {
    color: unset;
    text-decoration: unset;
  }
`

export const TitleContainer = styled.div`
  display: flex;
`

export const Title = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  padding: 0 8px 0 16px;
`

export const Description = styled.div`
  color: #B1BFC5;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`

export const inputStyles = css`
  min-height: 40px;
  height: 40px;
  margin-bottom: ${({ reduced }) => reduced ? '15px' : '24px'};
  background-color: var(--input-primary);
  border: 1px solid ${props => props.error
    ? 'var(--error-color)' : 'var(--border-primary)'};
  color: var(--text-primary);
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  &:disabled {
    background-color: var(--input-primary);
    border: 1px solid var(--border-primary);
    color: var(--text-primary);
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    box-shadow: var(--shadow-primary);
  }
  &:focus, &:hover:not(:disabled) {
    border: 1px solid var(--brand-regular);
    background-color: var(--input-primary);
    color: var(--text-primary);
    box-shadow: none;
  }
  &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
`

export const LinkStyles = css`
  color: var(--brand-regular);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  :hover {
    color: var(--brand-regular-hover);
  }
  :active {
    color: var(--brand-regular-active);
  }
  
`

import {
  changeTimezone,
  dateToSecondsSinceEpoch,
  weeksToSeconds } from './dateTimeUtil'
import cloneDeep from 'lodash/cloneDeep'
import trim from 'lodash/trim'
import { CHAPTER, COURSERA_ACTIVITY, ESSAY, EXAM, SECTION } from '../Constants/chapter'
import { getSectionByType } from './chapterUtil'
import { additionalCourseIdToName, courseIdToName, getCourseIds } from '../config'
import { COURSES_WITHOUT_FINAL_EXAM, DATE_OVERLAP_VALUE } from '../Constants'
import { GGU } from '../Constants/institutions'
import {
  EXCLUDE_STATUSES_IDS
} from '../Constants/studentStatus'

export {
  isCourseWithoutFinalExam,
  getCohortStartSecondsSinceEpoch,
  getDefaultCourse,
  getCourseByName,
  getCohortByName,
  getCourseNameById,
  isCohortCompleted,
  getCohortEndDate,
  isValidSpecialDay,
  isAuditCohort,
  getRelationshipsById,
  getSpecialDayDiffInSeconds,
  mergeCohortsOfDuplicateCourses,
  getCoursesWithCourseId,
  getCoursesWithOutCourseId,
  getAdditionalCourseWithAtId,
  getAdditionalCourseDetails,
  addCourseIdToCohort,
  getValidCourses,
  addCourseraActivitiesToChapters,
  addCourseraActivitiesToCourseData,
  getCohortRangeCourse
}

// default course is Calculus I
const courseName = 'Calculus I'
function getDefaultCourse (courses, defaultCourseName = courseName) {
  return courses.find(course => course.name === defaultCourseName)
}

function getCourseByName (courses, courseName) {
  return courses.find(course => course.name === courseName)
}

function getCourseNameById (courses, id) {
  if (!id) return
  const filteredCourse = courses.filter((course) => {
    return course.at_id === id
  })
  return filteredCourse[0] && (filteredCourse[0].label || filteredCourse[0].name)
}

function getCohortByName (attemptData, cohortName) {
  return attemptData.find(attempt => attempt.cohortName === cohortName)
}

function getRelationshipsById (relationships, ids) {
  if (!ids || !relationships?.length) return
  const filteredRelationships = relationships.filter((relationship) => {
    return ids.includes(relationship.id)
  })

  return filteredRelationships
}

export function getCreditGrantingInstitutions (courses) {
  if (!courses?.length) return []

  const institutions = courses.map(course => course.creditGrantingInstitution)
  const uniqueInstitutions = [...new Set(institutions)].filter(Boolean)

  return uniqueInstitutions
}

/**
 * Check if current date is past cohort end date
 * @param {number} cohortEndDate cohort end date in seconds
 * @param {Date} now current date
 * @returns {boolean} is cohort completed
 */
function isCohortCompleted (cohort, now) {
  const cohortEndDate = getCohortEndDate(cohort)
  return dateToSecondsSinceEpoch(now) >= cohortEndDate
}

/**
 * @param {Object} cohort cohort detail
 * @returns {number | undefined} cohort end date in seconds
 */
function getCohortEndDate (cohort) {
  if (!cohort || !cohort.dateStart) return

  const { finalExamEndTime } = cohort
  if (
    finalExamEndTime
  ) return dateToSecondsSinceEpoch(new Date(finalExamEndTime))

  // Cohorts with no duration will have 0 value.
  const { duration = 0, dateStart, specialDaysDates } = cohort
  const cohortStartDate = dateToSecondsSinceEpoch(new Date(dateStart))
  const cohortDuration = weeksToSeconds(duration)
  let cohortEndDate = cohortStartDate + cohortDuration
  if (!specialDaysDates || !specialDaysDates.length) return cohortEndDate
  specialDaysDates.forEach(specialDay => {
    if (
      !isValidSpecialDay(specialDay, cohortStartDate, cohortEndDate)
    ) return
    const diffInSeconds = getSpecialDayDiffInSeconds(specialDay)
    cohortEndDate = cohortEndDate + diffInSeconds
  })
  return cohortEndDate
}

/**
 * @param {Object} specialDay special day object with start and end day
 * @param {number} cohortStartDate cohort start date in seconds
 * @param {number} cohortEndDate cohort end date in seconds
 * @returns {boolean} is special day between cohort start and end dates
 */
function isValidSpecialDay (specialDay, cohortStartDate, cohortEndDate) {
  const { dayStart, dayEnd } = specialDay
  if (!dayStart || !dayEnd) return false
  const specialDayStartDate = dateToSecondsSinceEpoch(new Date(dayStart))
  const specialDayEndDate = dateToSecondsSinceEpoch(new Date(dayEnd))
  if (specialDayEndDate < specialDayStartDate) return false
  if (specialDayStartDate < cohortStartDate) return false
  if (specialDayEndDate > cohortEndDate) return false
  return true
}

/**
 * @param {Object} specialDay specialday object with start and end day
 * @returns {number} the difference between start and end day of the special day
 */
function getSpecialDayDiffInSeconds (specialDay) {
  const { dayStart, dayEnd } = specialDay
  const specialDayStartDate = dateToSecondsSinceEpoch(new Date(dayStart))
  const specialDayEndDate = dateToSecondsSinceEpoch(new Date(dayEnd))
  return specialDayEndDate - specialDayStartDate
}

function getCohortStartSecondsSinceEpoch (cohort) {
  const { dateStart } = cohort || {}
  const date = new Date(dateStart + 'T00:00:00')
  if (date.toString() === 'Invalid Date') return

  const datePST = changeTimezone(date, 'America/Los_Angeles')

  return Math.floor(datePST.getTime() / 1000)
}

function isAuditCohort (cohort) {
  const { name } = cohort
  return name.toLowerCase().includes('audit')
}

/*
 * This function would filter the courses with defined course id
 */

function getCoursesWithCourseId (courses) {
  return courses.filter(course => courseIdToName(trim(course.id)))
}

/*
 * This function would return the courses without defined course id
 */

function getCoursesWithOutCourseId (courses) {
  return courses.filter(course => !courseIdToName(course.id))
}

/*
 * This function would merge cohorts of duplicate courses and return filtered courses
 */
function mergeCohortsOfDuplicateCourses (courses) {
  const coursesWithNoIds = getCoursesWithOutCourseId(courses)
  const coursesWithIds = getCoursesWithCourseId(courses)

  return coursesWithIds.map(course => {
    const { id, cohorts } = course
    const additionalIds = additionalCourseIdToName(id)

    if (additionalIds?.length > 1) {
      const additionalCourses = coursesWithNoIds.filter(course => (
        additionalIds.includes(course.id)
      ))

      return {
        ...course,
        cohorts: [...cohorts, ...addCourseIdToCohort(additionalCourses).flat()]
      }
    }

    return course
  })
    .concat(coursesWithNoIds.filter(({ name, id } = {}) => {
      const additionalIds = additionalCourseIdToName(id)
      return id && !additionalIds
    }))
    .sort((a, b) => {
      const aName = a?.name?.toLowerCase()
      const bName = b?.name?.toLowerCase()
      return aName <= bName ? -1 : 1
    })
}

/*
 * This function would return the additional course object of the course from course at_id
 */
function getAdditionalCourseWithAtId (courseAtId, allCourses) {
  const course = allCourses.find(course => course.at_id === courseAtId)
  if (course) {
    const additionalIds = additionalCourseIdToName(course.id)
    if (additionalIds.length > 1) {
      const additionalCourseId = additionalIds.filter(id => id !== course.id)[0]
      return allCourses.find(course => additionalCourseId.includes(course.id))
    }
    return course
  }
}

/*
  * This function would add courseId to the cohort of the courses
 */

function addCourseIdToCohort (courses) {
  return courses.map(course =>
    course.cohorts.map(cohort => ({ ...cohort, courseId: course.id })))
}

/*
  * This function would check if there exists an additional course for the selected courses,
  * if so then return the additional course details
 */

function getAdditionalCourseDetails (allCourses, selectedCourses) {
  const coursesWithoutIds = getCoursesWithOutCourseId(allCourses)
  return coursesWithoutIds.find(course => {
    const additionalCourseId = additionalCourseIdToName(course.id)
    if (additionalCourseId?.length > 1) {
      return selectedCourses?.find(
        selectedCourse => additionalCourseId.includes(selectedCourse.id))
    }
    return false
  })
}

function getCohortRangeCourse (courses, cohortStartRange) {
  if (!courses?.length || !cohortStartRange) return null

  const courseIds = courses?.map((id) => {
    const additionalIds = additionalCourseIdToName(id)
    if (additionalIds?.length > 1) {
      return additionalIds
    }
    return id
  })
  const isDateRangeOverlap =
    new Date(DATE_OVERLAP_VALUE) <= new Date(cohortStartRange[0]) ||
    new Date(DATE_OVERLAP_VALUE) <= new Date(cohortStartRange[1])

  const allCourses = isDateRangeOverlap ? courseIds?.flat() : courses

  return allCourses
}

function getValidCourses (courses) {
  const irrelevantCourseIds = [
    'ckkmym4io00003f5thag7wd7t',
    'ea88ffd3-5c59-49d5-89b4-b9f009dde9ac',
    'ckww81lw400003e5xl584obx4'
  ]
  return courses.filter(course =>
    course.cohorts &&
    irrelevantCourseIds.includes(course.id) === false
  )
}

function getLastActivityTitle (otherActivities) {
  const hasSectionInOtherActivities = otherActivities?.some(section => {
    return section.assignmentType === SECTION
  })
  const lastActivity = hasSectionInOtherActivities
    ? otherActivities?.reverse()?.find(section => (section.assignmentType === SECTION))
    : otherActivities?.[otherActivities?.length - 1] || {}
  // lastActivityTitle can either be a section number or essay/exam title
  return lastActivity.title?.split(' | ')?.[1]?.trim() || ''
}

function addCourseraActivitiesToChapters (params) {
  const {
    courseraActivities, otherActivities, chapters: paramChapters
  } = params || {}
  const chapters = cloneDeep(paramChapters)
  if (!courseraActivities?.length || !chapters?.length) return chapters

  const lastActivityTitle = getLastActivityTitle(otherActivities)

  courseraActivities.forEach((courseraActivity, activityIndex) => {
    let isAdded = false
    for (let index = 0; index < chapters.length; index++) {
      const { chapterNumber, type, title, sections } = chapters[index] || {}

      if (type === CHAPTER) {
        const lastActivitySection = sections?.filter(section => !section.assignmentType)
          .find((section, sIndex) => {
            return lastActivityTitle === `${chapterNumber}.${sIndex + 1}`
          })
        if (lastActivitySection) {
          const sectionIndex = sections?.findIndex(section => {
            return section.section_uuid === lastActivitySection.section_uuid
          })
          if (sectionIndex >= 0) {
            chapters[index].sections
              .splice(sectionIndex + activityIndex + 1, 0, courseraActivity)
            isAdded = true
            break
          }
        }
      }

      if (title === lastActivityTitle) {
        chapters.splice(index + activityIndex + 1, 0, courseraActivity)
        isAdded = true
        break
      }
    }
    if (!isAdded) chapters.push(courseraActivity)
  })
  return chapters
}

function addCourseraActivitiesToCourseData (datoCourseData, syllabus) {
  if (!datoCourseData || !syllabus) return datoCourseData

  const { syllabusData } = syllabus
  if (!syllabusData?.length) return datoCourseData

  const courseData = cloneDeep(datoCourseData)
  let { chapters } = courseData
  if (!chapters?.length) return datoCourseData

  syllabusData.forEach(syllabus => {
    const { sections } = syllabus || {}
    if (!sections?.length) return

    const courseraActivities = getSectionByType(sections, [COURSERA_ACTIVITY])
    if (!courseraActivities?.length) return

    const otherActivities = getSectionByType(sections, [SECTION, ESSAY, EXAM])

    chapters = addCourseraActivitiesToChapters({
      courseraActivities, otherActivities, chapters
    })
  })

  courseData.chapters = chapters
  return courseData
}

function isCourseWithoutFinalExam (courseId) {
  const courseIds = getCourseIds()
  const courseIdsWithoutFinalExam = COURSES_WITHOUT_FINAL_EXAM.map(courseName => {
    return courseIds[courseName]
  })
  return courseIdsWithoutFinalExam.includes(courseId)
}

export function getGroupedCourses (courses) {
  if (!courses?.length) return []

  const groupedCourses = []
  courses.forEach(course => {
    const { displayName, creditGrantingInstitution } = course

    const existingCourseGroup = groupedCourses.find(groupedCourse => (
      groupedCourse.displayName === displayName
    ))

    if (existingCourseGroup) {
      !existingCourseGroup.institutions.includes(creditGrantingInstitution) &&
        existingCourseGroup.institutions.push(creditGrantingInstitution)
      return existingCourseGroup.courses.push(course)
    }

    groupedCourses.push({
      displayName,
      courses: [course],
      institutions: [creditGrantingInstitution]
    })
  })

  return groupedCourses
}

export function getActiveCoursesByRelationship (courses, relationship) {
  if (!courses?.length || !relationship) return []

  const {
    attempts,
    creditGrantingInstitution,
  } = relationship || {}
  
  const isGGU = creditGrantingInstitution === GGU
  if (isGGU) return courses.filter(
    course => course.creditGrantingInstitution === GGU,
  )

  const courseIds = attempts
    .filter(attempt => !EXCLUDE_STATUSES_IDS.includes(attempt.fields.studentStatus?.[0]))
    .map(attempt => attempt.fields.course?.[0])
  
  return courses.filter(course => courseIds.includes(course.at_id))
}


export function getFilteredCourses (courses, selectedCreditGrantingInstitution) {
  if (!courses?.length) return []

  const filteredCourses = courses.filter(course => {
    return course.creditGrantingInstitution === selectedCreditGrantingInstitution
  })
  const groupedCourses = getGroupedCourses(filteredCourses)

  groupedCourses.forEach(course => {
    course.creditGrantingInstitution = course.institutions[0]
    course.label = course.displayName
    course.value = course.displayName
  })

  return groupedCourses
}
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import api from '../../api'
import {
  BackButton,
  ButtonsWrapper,
  CustomInput,
  DetailCell,
  EditButton,
  GridContainer,
  HeaderCell,
  InputWrapper,
  NormalText,
  Row,
  SecondaryBorder,
  SectionTitle,
  SectionWrapper,
  StudentCell,
  SummaryText,
  TitleWrapper
} from './index.style'
import { COURSE_DETAILS, PAGES, initialSelectedData } from './utils/constants'
import { getSelectableStatuses, getSubmitStudents } from './utils'
import CustomSelect from '../../Components/CustomSelect/UpdatedSelect'
import { PrimaryButton, dropDownStyles } from '../../Components/global.styled'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ErrorMessage, SuccessIcon, WarningIcon } from '../../Components/Guild/style'

function ReviewPage ({
  studentStatuses, selectedData, setSelectedData, setCurrentPage, toastRef
}) {
  const { cohort, selectedStudents } = selectedData || {}
  const selectableStatuses = getSelectableStatuses(studentStatuses)

  const [updatedStatus, setUpdatedStatus] = useState(null)
  const [zendeskUrl, setZendeskUrl] = useState('')
  const [submitState, setSubmitState] = useState({ isLoading: false, error: null })

  const handleSubmitClick = async () => {
    const students = getSubmitStudents(selectedStudents, updatedStatus, zendeskUrl)
    setSubmitState({ isLoading: true, error: null })

    const { success, data } = await api.bulkStudentStatusUpdate(cohort?.id, students)
    if (!success) return setSubmitState({ isLoading: false, error: data })

    setSubmitState({ isLoading: false, error: null })
    setSelectedData({ ...initialSelectedData, statuses: studentStatuses })
    setCurrentPage(PAGES.selection)

    toastRef.current.display(
      <><SuccessIcon /> Enrollment status updated!</>,
      <>{students?.length} student{students.length > 1 ? 's have ' : ' has '}
        been updated to <b>{updatedStatus?.label}</b> for {cohort?.value}
      </>,
      { delay: 0, period: 10000 }
    )
  }

  const handleBackClick = () => {
    setSubmitState({ isLoading: false, error: null })
    setCurrentPage(PAGES.selection)
  }

  return (
    <>
      <NormalText>
        Please review the course and student selections below,
        then choose a new enrollment status to assign.
      </NormalText>

      <SectionWrapper gap='24'>
        <SectionWrapper gap='16' padding='0 12px'>
          <TitleWrapper>
            <SectionTitle>Course details</SectionTitle>
            <EditButton onClick={handleBackClick}>edit</EditButton>
          </TitleWrapper>

          <GridContainer>
            {COURSE_DETAILS.map(({ label, value }) => (
              <div key={value}>
                <HeaderCell isReview>{label}</HeaderCell>
                <DetailCell isReview data-testid='detail-cell'>
                  {selectedData[value]?.label || '--'}
                </DetailCell>
              </div>
            ))}
          </GridContainer>
        </SectionWrapper>

        <SecondaryBorder />

        <SectionWrapper gap='16' padding='0 12px'>
          <TitleWrapper>
            <SectionTitle>Students selected</SectionTitle>
            <EditButton onClick={handleBackClick}>edit</EditButton>
          </TitleWrapper>

          <div>
            <Row style={{ padding: '10px 0 20px' }}>
              <HeaderCell info isName>name</HeaderCell>
              <HeaderCell info isEmail>email</HeaderCell>
              <HeaderCell info>current status</HeaderCell>
              <HeaderCell info>
                {updatedStatus ? 'updated' : 'new'} status
              </HeaderCell>
            </Row>

            {selectedStudents?.map(student => {
              const isLastItem =
                selectedStudents?.length - 1 === selectedStudents?.indexOf(student)
              return (
                <Row key={student.id} isStudentRow isLastItem={isLastItem}>
                  <StudentCell info isName>{student.name}</StudentCell>
                  <StudentCell info isEmail>{student.email}</StudentCell>
                  <StudentCell info strike>{student.studentStatus}</StudentCell>
                  <StudentCell info isNew>{updatedStatus?.label || '--'}</StudentCell>
                </Row>
              )
            })}
          </div>
        </SectionWrapper>

        <SecondaryBorder />

      </SectionWrapper>

      <InputWrapper>
        <SectionTitle>Select a new enrollment status</SectionTitle>
        <CustomSelect
          width='100%'
          isClearable
          value={updatedStatus}
          options={selectableStatuses}
          placeholder='Select a student status'
          onChange={status => setUpdatedStatus(status)}
        />
      </InputWrapper>

      <InputWrapper>
        <SectionTitle>Zendesk ticket URL</SectionTitle>
        <CustomInput
          value={zendeskUrl}
          style={{ ...dropDownStyles }}
          placeholder='Enter a Zendesk ticket URL'
          onChange={e => setZendeskUrl(e.target.value)}
        />
      </InputWrapper>

      {updatedStatus && (
        <SummaryText>
          <span>summary</span>
          <p>
            {selectedStudents?.length} student{selectedStudents.length > 1 ? 's ' : ' '}
            will be updated to <b>{updatedStatus?.label}</b> for {cohort?.value}.
          </p>
        </SummaryText>
      )}

      <div>
        <ButtonsWrapper>
          <PrimaryButton
            disabled={!updatedStatus || submitState.isLoading}
            style={{ width: 'fit-content', minWidth: '107px' }}
            onClick={handleSubmitClick}
          >
            {submitState.isLoading
              ? <FontAwesomeIcon spin icon={faSpinner} />
              : 'submit'
            }
          </PrimaryButton>
          <BackButton
            onClick={handleBackClick}
            data-testid='back-button'
          >
            go back
          </BackButton>
        </ButtonsWrapper>

        {submitState.error && (
          <ErrorMessage style={{ marginTop: '12px' }}>
            <WarningIcon />
            There was an error updating the enrollment status. Please try again.
          </ErrorMessage>
        )}
      </div>
    </>
  )
}

ReviewPage.propTypes = {
  studentStatuses: PropTypes.array.isRequired,
  selectedData: PropTypes.object.isRequired,
  setSelectedData: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired
}

ReviewPage.displayName = 'ReviewPage'
export default ReviewPage

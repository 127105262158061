import styled, { css } from 'styled-components'
import { Description } from '../../../styles/text'
import { ReactComponent as PlusSVG } from '../../../assets/icons/plus.svg'
import { ReactComponent as CloseModalSVG } from '../../../assets/icons/modal-close.svg'
import { ReactComponent as BoxArrowSVG } from '../../../assets/icons/box-arrow-up-right.svg'
import { ReactComponent as EllipseSVG } from '../../../assets/icons/ellipse.svg'
import { TransparentButton } from '../../../styles/buttons'

export const PageWrapper = styled.div`
  margin: 24px 24px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 12px;
`

export const ProspectId = styled(Description)`
  color: var(--text-dark-off, #98a4ae);
  line-height: 24px;
`

// SECTIONS

export const SectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
`

export const SectionsGroup = styled.div`
  display: flex;
  flex-direction: ${props => props.isColumn ? 'column' : 'row'};
  gap: 12px;
  flex: 1;
`

export const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${props => props.padding || '24px'};
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.gap || '16px'};
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--border-secondary, #5b6067);

  ${props => props.fill && css`
    flex: 1;
  `}
`

export const SectionHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 12px;
  align-self: stretch;
`

export const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  gap: ${props => props.gap || '12px'};
`

export const CommentsSection = styled(SectionContainer)`
  min-height: 230px;
  flex: 1;
`

export const ReviewSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const ReviewDescription = styled(Description)`
  color: var(--sub-text-secondary, #CCD4D8);
`

export const ReviewList = styled.ol`
  margin: 0;
  padding-left: 20px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 12px;

  button {
    width: fit-content;
  }
`

// SECTION ELEMENTS

export const SectionElement = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.gap || '16px'};
`

export const DetailItem = styled(Description)`
  ${props => props.minWidth && css`
    min-width: ${props.minWidth}px;
  `}

  ${props => props.isTitle && css`
    color: var(--sub-text-secondary, #CCD4D8);
  `}
`

// COURSES

export const CourseItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`

export const CourseName = styled(Description)`
  display: flex;
  padding: 16px 12px;
  justify-content: flex-start;
  align-items: center;
  flex: 1 0 0;

  border-radius: 5px;
  background: #232327;
`

export const CourseIcon = styled.div`
  display: flex;
  padding: 12px 8px;
  align-items: center;
`

// BUTTONS

export const ColoredButton = styled.button`
  position: relative;
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  background: var(--brand-dark, #0A2A2D);
  cursor: pointer;
  border-radius: 5px;
  border: unset;

  h4 {
    color: var(--brand-regular, #5FC4B8);
  }

  &:hover {
    background: var(--brand-dark-hover, #0F3E43);
  }

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus {
    outline: none;
  }
`

export const PlusIcon = styled(PlusSVG)`
  path {
    color: var(--brand-regular, #5FC4B8);
  }
`

export const BoxArrowIcon = styled(BoxArrowSVG)`
  path {
    color: var(--brand-regular, #5FC4B8);
  }
`

// MODAL

export const ModalWrapper = styled.div`
  position: absolute;
  top: ${props => props.top || '0'};
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  width: 391px;
  height: fit-content;
  max-height: 400px;
  padding-bottom: 8px;
  ${props => props.center && css`
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  `}

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => props.gap || '12px'};

  border-radius: 5px;
  background: var(--gray-primary, #25272B);
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.50);
`

export const ModalHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 16px 16px 0 16px;
`

export const ModalTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  p {
    width: 100%;
    line-height: 24px;
  }
`

export const CloseIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  &:active {
    opacity: 0.3;
  }
`

export const CloseIcon = styled(CloseModalSVG)`
  cursor: pointer;
`

export const ModalBody = styled.div`
  min-height: 50px;
  max-height: 270px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  overflow: auto;

  & {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--border-secondary);
      border-radius: 10px;
      visibility: visible;
    }
  }
`

export const ModalItem = styled(Description)`
  display: flex;
  align-self: stretch;
  padding: 8px 16px;
  cursor: pointer;

  color: var(--sub-text-secondary, #CCD4D8);

  &:hover {
    color: #fff;
    border-radius: 5px;
    border: 1px solid var(--brand-regular, #5FC4B8);
  }
`

// ACADEMIC HISTORY

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
`

export const HistoryTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    font-weight: 400;
  }

  p:first-child {
    color: var(--sub-text-secondary, #CCD4D8);
  }

  p:last-child {
    line-height: normal;
  }
`

export const HistoryBody = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`

export const HistoryItem = styled(Description)`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  color: var(--sub-text-secondary, #CCD4D8);
  border-radius: 5px;
  background: #232327;
`

export const EllipseIcon = styled(EllipseSVG)`
`

export const SeeAllButton = styled(TransparentButton)`
  padding: 6px 0;
`

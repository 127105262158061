import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import ModalComponent from '../ModalComponent/ModalComponent'
import Select from 'react-select'
import { FormGroup, Input, Button, Label, Form } from 'reactstrap'
import {
  customSelectStyles,
  StudentStatusEditModalWrapper,
  TokenMessageText
} from './style'
import api from '../../api'
import { sortBy } from 'lodash'
import {
  updatedStatusOptions,
  IndicatorsContainer,
  statusNoteOptions,
  isValidUrl
} from '../../utilities/studentStatusUtils'
import { isAfterTokensLaunch } from '../../utilities/tokenUtils'
import {
  STUDENT_RETURN,
  PARTNER_RETURN,
  PRE_TOKKEN_MESSAGE,
  POST_TOKKEN_STUDENT_MESSAGE,
  POST_TOKKEN_PARTNER_MESSAGE
} from '../../Constants/token'
import { ADMINISTRATIVE_DROP, DROP, MELT } from '../../Constants/studentStatus'

export default function EditStudentStatusModal ({
  show,
  handleClose,
  studentStatus,
  handleNextClick,
  formFields,
  selectedStudentCohort,
  tokens,
  setFormFields
}) {
  const [studentStatuses, setStudentStatuses] = useState(updatedStatusOptions)
  const [updatedStatusError, setUpdatedStatusError] = useState('')
  const [statusNoteError, setStatusNoteError] = useState('')
  const [zendeskUrlError, setZendeskUrlError] = useState('')
  const [tokenMessage, setTokenMessage] = useState('')
  const { updatedStatus, statusNote, note, zendeskUrl } = formFields

  const fetchStudentStatuses = async () => {
    const studentStatuses = await api.getStudentStatuses() || []
    if (!studentStatuses.length) return

    const sortedStatuses = sortBy(studentStatuses, (status) => status.name)
    const statusOptions = sortedStatuses.map(
      status => ({ value: status.name, label: status.name })
    )
    setStudentStatuses(statusOptions)
  }

  const getTokenMessage = (status) => {
    const allowedStatuses = [ADMINISTRATIVE_DROP, DROP, MELT]
    const shouldDisplayTokenMessage =
      allowedStatuses.includes(status.value) &&
      !allowedStatuses.includes(studentStatus)

    if (!shouldDisplayTokenMessage) return ''

    const isAfterTokens = isAfterTokensLaunch(selectedStudentCohort)
    if (!isAfterTokens) return PRE_TOKKEN_MESSAGE

    const { hasPurchaseRecord } = selectedStudentCohort
    if (hasPurchaseRecord) return PRE_TOKKEN_MESSAGE

    const linkedToken = getLinkedToken()
    if (!linkedToken) return POST_TOKKEN_STUDENT_MESSAGE

    const { returnTo } = linkedToken || {}

    if (returnTo === STUDENT_RETURN) return POST_TOKKEN_STUDENT_MESSAGE
    if (returnTo === PARTNER_RETURN) return POST_TOKKEN_PARTNER_MESSAGE

    return ''
  }

  const getLinkedToken = () => {
    if (!tokens.length) return

    return tokens.find(token => {
      const { usedAttempt } = token
      const attempt = usedAttempt?.[0]?.fields || {}
      return attempt.attemptID === selectedStudentCohort.attemptID
    })
  }

  useEffect(() => {
    fetchStudentStatuses()
  }, [])

  const handleTokenMessage = (name, status) => {
    if (name !== 'updatedStatus' || !selectedStudentCohort) return

    const message = getTokenMessage(status)
    setTokenMessage(message)
  }

  const handleChange = (name, value) => {
    setFormFields(prevFields => ({ ...prevFields, [name]: value }))
    handleTokenMessage(name, value)

    if (name === 'updatedStatus' && updatedStatusError) {
      setUpdatedStatusError('')
    }
    if (name === 'statusNote' && statusNoteError) setStatusNoteError('')
    if (name === 'zendeskUrl' && zendeskUrlError) setZendeskUrlError('')
  }

  const isFormFieldsValid = () => {
    if (!updatedStatus) setUpdatedStatusError('Select a new student status.')
    if (!statusNote) setStatusNoteError('Select a refund or transfer status.')
    if (!zendeskUrl) setZendeskUrlError('Add a Zendesk Ticket URL.')
    if (zendeskUrl && !isValidUrl(zendeskUrl)) {
      setZendeskUrlError('Add a valid URL with http:// or https://.')
      return false
    }

    return !(
      !updatedStatus ||
      !statusNote ||
      !zendeskUrl ||
      updatedStatusError ||
      statusNoteError ||
      zendeskUrlError
    )
  }

  const handleSubmit = () => {
    if (!isFormFieldsValid()) return
    handleNextClick(formFields)
  }

  return (
    <ModalComponent show={show} handleClose={handleClose}>
      <StudentStatusEditModalWrapper>
        <h3>Change Student Status</h3>
        <p>
          Current course status: <span>{studentStatus}</span>
        </p>

        <Form>
          <FormGroup>
            <Label className='section-title'>Updated Status</Label>
            {updatedStatusError && (
              <p className='error'>{updatedStatusError}</p>
            )}
            <Select
              id='updated-status'
              styles={customSelectStyles}
              components={{ IndicatorsContainer }}
              options={studentStatuses}
              error={updatedStatusError}
              value={updatedStatus}
              onChange={selectedStatus =>
                handleChange('updatedStatus', selectedStatus)
              }
              placeholder='Please select a status.'
            />
          </FormGroup>

          <FormGroup>
            <Label className='section-title'>Status Note</Label>
            {tokenMessage && <TokenMessageText id='token-text'>{tokenMessage}</TokenMessageText>}
            {statusNoteError && <p className='error'>{statusNoteError}</p>}
            <Select
              styles={customSelectStyles}
              components={{ IndicatorsContainer }}
              options={statusNoteOptions}
              error={statusNoteError}
              value={statusNote}
              onChange={selectedNote =>
                handleChange('statusNote', selectedNote)
              }
              placeholder='Will the student be issued a refund or voucher?'
            />
          </FormGroup>

          <FormGroup>
            <Label className='section-title'>Change Notes</Label>
            <Input
              type='textarea'
              value={note}
              onChange={e => handleChange('note', e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label className='section-title'>Zendesk URL</Label>
            {zendeskUrlError && <p className='error'>{zendeskUrlError}</p>}
            <Input
              type='text'
              className={`zendesk ${zendeskUrlError && 'input-error-border'}`}
              value={zendeskUrl}
              onChange={e => handleChange('zendeskUrl', e.target.value)}
            />
          </FormGroup>

          <FormGroup className='buttons-container mt-4'>
            <Button
              className='btn btn-custom btn btn-secondary'
              onClick={handleClose}
            >
              CANCEL
            </Button>
            <Button onClick={handleSubmit} className='btn btn-custom next-btn'>
              NEXT
            </Button>
          </FormGroup>
        </Form>
      </StudentStatusEditModalWrapper>
    </ModalComponent>
  )
}

EditStudentStatusModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  studentStatus: PropTypes.string.isRequired,
  handleNextClick: PropTypes.func.isRequired,
  formFields: PropTypes.shape({
    updatedStatus: PropTypes.any.isRequired,
    statusNote: PropTypes.any.isRequired,
    note: PropTypes.string.isRequired,
    zendeskUrl: PropTypes.string.isRequired
  }).isRequired,
  setFormFields: PropTypes.func.isRequired
}

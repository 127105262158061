import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter as Router } from 'react-router-dom'
import { Auth0Provider } from './Components/Auth0Provider/Auth0Provider'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './index.css'
import App from './App'
import AppGuard from './Components/AppGuard/AppGuard'
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary'

import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file

ReactDOM.render(
  <ErrorBoundary>
    <Auth0Provider>
      <Router>
        <AppGuard>
          <App />
        </AppGuard>
      </Router>
    </Auth0Provider>
  </ErrorBoundary>
  , document.getElementById('root'))

import styled from 'styled-components'
import checkMark from '../../assets/icons/check-mark.svg'

export const StyledWrapper = styled.div`
  margin-top: 25px;
`

export const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  line-height: 22px;
  font-size: 18px;
  font-weight: normal;
  text-transform: capitalize;

  span {
    font-weight: bold;  
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #5E636B;
    position: relative;
    top: 4px;
  }
`

export const CheckBox = styled.label`
  margin-top: ${({ marginTop }) => marginTop};
  display: block;
  width: max-content;
  position: relative;
  padding-left: 36px;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* hide the default checkbox */
  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  /* create a custom radio button */
  span {
    position: absolute;
    top: -3px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #161618;
    border: ${props => props.disabled ? '2px solid #5E636B' : '2px solid #5FC4B8'};
    box-sizing: border-box;
    border-radius: 5px;
  }

  /* When the checkbox is checked */
  input:checked ~ span {
    background-color: #5FC4B8;
    border: 3px solid #5FC4B8;
    box-sizing: border-box;
    border-radius: 5px;
  }

  /* Create the check-mark/indicator */
  span::after {
    display: none;
    position: absolute;
    content: url(${checkMark});
  }

  /* Show the check-mark when checked */
  input:checked ~ span::after {
    left: 2px;
    display: block;
  }
`

export const SelectionButton = styled.div`
  color: ${props => props.disabled ? '#558180' : '#5FC4B8'};
  top: 4px;
  position: relative;
  height: fit-content;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
`

export const SelectAllButton = styled(SelectionButton)`
  margin: 0 4px 0 12px;
`

export const DeselectAllButton = styled(SelectionButton)`
  margin-left: 4px;
`
export const selectInputStyles = {
  indicatorSeparator: (base, state) => ({
    ...base,
    margin: '4px 0',
    backgroundColor: state.isDisabled
      ? 'rgba(255, 255, 255, 0.1)'
      : 'rgba(255, 255, 255, 0.3)'
  }),
  control: (base, state) => ({
    ...base,
    minHeight: '40px',
    width: '400px',
    background: state.isDisabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.3)',
    color: '#FFFFFF',
    border: `1px solid ${
      state.isFocused ? '#5FC4B8'
        : state.isDisabled
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.3)'

    }`,
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid #5FC4B8'
    }
  }),
  placeholder: (base, state) => ({
    ...base,
    color: state.isDisabled ? '#495057' : '#FFFFFF',
    marginLeft: '5px',
    fontSize: '1rem'
  }),
  menu: base => ({
    ...base,
    width: '400px',
    background: ' #060606',
    color: 'white',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
    borderRadius: '5px',
    marginTop: '15px'
  }),
  input: base => ({
    ...base,
    color: 'white'
  }),
  menuList: base => ({
    ...base,
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: !state.isSelected ? '#060606' : 'rgba(255, 255, 255, 0.1)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    cursor: 'pointer',
    '&:last-child': {
      borderBottom: 'none',
      borderRadius: '0 0 5px 5px'
    },
    '&:first-of-type': {
      borderRadius: '5px 5px 0 0'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled && 'rgba(255, 255, 255, 0.3)'
  }),
  multiValueLabel: base => ({
    ...base,
    color: 'white',
    display: 'flex',
    fontSize: '16px',
    lineHeight: '19px'
  }),
  multiValue: base => ({
    ...base,
    background: '#1D1D1F',
    color: 'white'
  })
}

/* Customize the label (the container) */
export const CustomOptionContainer = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding-left: 30px;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover input ~ .check-mark {
    background-color: #5c636d;
  }

  input:checked ~ .check-mark {
    background-color: #5fc4b8;
  }

  input ~ .check-mark:after {
    display: none;
  }

  input:checked ~ .check-mark:after {
    display: block;
  }
`

/* Hide the browser's default checkbox */
export const CheckedBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

/* Create a custom checkbox */
export const CustomCheckBox = styled.span`
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background: #5e636b;
  border-radius: 3px;

  :after {
    content: '';
    position: absolute;
    left: 6px;
    top: 3.5px;
    width: 7px;
    height: 10px;
    border: 1px solid #161618;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

import React from 'react'
import {
  NotFoundButton,
  NotFoundButtonsWrapper,
  NotFoundIcon,
  NotFoundText,
  StudentRecordNotFoundWrapper
} from './style'
import studentMagnifierIcon from '../../assets/icons/student-magnifier.svg'

const StudentRecordNotFound = ({ filterText }) => {
  return (
    <StudentRecordNotFoundWrapper>
      <NotFoundIcon src={studentMagnifierIcon} alt='student-not-found' />
      <NotFoundText>Hmm.... we can’t find any results for {filterText}. Please
        check the spelling or enroll the user!
      </NotFoundText>
      <NotFoundButtonsWrapper>
        <NotFoundButton href='#/students/student-information'>
          add student
        </NotFoundButton>
        <NotFoundButton href='#/vip-user'>
          add vip user
        </NotFoundButton>
      </NotFoundButtonsWrapper>
    </StudentRecordNotFoundWrapper>
  )
}
StudentRecordNotFound.displayName = 'StudentRecordNotFound'
export default StudentRecordNotFound

import React, { useState, useCallback, useEffect, useRef } from 'react'
import {
  FeedbackFileUploadWrapper,
  FileUploadHeader,
  FileUploadInput,
  FileUploadBox,
  ReplaceUploadText,
  UploadedFileDetails,
  FileUploadLabel,
  FileName,
  RemoveFileButton,
  Tooltip
} from './style'
import DeleteIcon from '../../assets/icons/delete-icon.svg'
import AttachmentIcon from '../../assets/icons/attachment-icon.svg'
import TooltipIcon from '../../assets/icons/icon-tooltip.svg'
import api from '../../api'

export const FeedbackFileUpload = props => {
  const {
    label,
    marginTop,
    fileUploadMarginTop,
    ...apiProps
  } = props

  const [file, setFile] = useState('')
  const [uploadStatusText, setUploadStatusText] = useState('')
  const replaceOrUploadText = `${file ? 'Replace' : 'Upload a'} file`
  const fileUploadContainerRef = useRef(null)

  const preventDefault = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const dropDraggedFile = useCallback((e) => {
    preventDefault(e)

    const file = e.dataTransfer.files[0]
    handleFileUpload(file)
    // to allow dragging of same file after removal
    e.target.value = ''
    // eslint-disable-next-line
  }, [])

  const uploadFile = async fileObj => {
    const formData = new FormData()
    formData.append('assignment-feedback-file', fileObj)
    setUploadStatusText('Saving...')
    await api.putAssignmentFeedbackFile({ ...apiProps, formData })
    setUploadStatusText('Saved')
  }

  const deleteFile = async () => {
    await api.deleteAssignmentFeedbackFile({ ...apiProps })
  }

  const handleFileUpload = fileObj => {
    setUploadStatusText('')
    const fileExtension = fileObj.name.match(/\.[0-9a-z]+$/i)[0]
    const allowedExtensions = ['.pdf']

    if (fileObj.size > 20000000) {
      alert('Maximum file size: 20MB')
      return
    }

    if (!allowedExtensions.includes(fileExtension)) {
      alert('Only PDF files are allowed')
      return
    }

    setFile(fileObj)
    uploadFile(fileObj)
  }

  const handleFileRemoval = () => {
    setUploadStatusText('')
    deleteFile()
    setFile('')
  }

  useEffect(() => {
    const fileUploadContainerNode = fileUploadContainerRef.current

    fileUploadContainerNode
      .addEventListener('dragenter', preventDefault, false)
    fileUploadContainerNode
      .addEventListener('dragover', preventDefault, false)
    fileUploadContainerNode
      .addEventListener('drop', dropDraggedFile, false)

    return () => {
      fileUploadContainerNode.removeEventListener('dragenter', preventDefault)
      fileUploadContainerNode.removeEventListener('dragover', preventDefault)
      fileUploadContainerNode.removeEventListener('drop', dropDraggedFile)
    }
    // eslint-disable-next-line
  }, [preventDefault, dropDraggedFile])

  return (
    <FeedbackFileUploadWrapper marginTop={marginTop}>
      <FileUploadHeader>
        <span>
          {label || 'File Upload'}
          <Tooltip>
            <img
              src={TooltipIcon}
              className='ml-2 mb-1'
              width='16'
              height='16'
              alt='tool-tip'
            />
            <span>
              <p>Maximum file size: 20MB</p>
              <p>Acceptable file type: .pdf</p>
            </span>
          </Tooltip>
        </span>
        <span>{uploadStatusText}</span>
      </FileUploadHeader>
      <FileUploadBox
        marginTop={fileUploadMarginTop}
        file={file}
        ref={fileUploadContainerRef}
      >
        {file && (
          <UploadedFileDetails>
            <FileName>
              {file.name}
              <img src={AttachmentIcon} alt='attach-icon' />
            </FileName>
            <RemoveFileButton onClick={handleFileRemoval}>
              <img src={DeleteIcon} alt='file-upload' />
              Remove
            </RemoveFileButton>
          </UploadedFileDetails>
        )}
        <FileUploadLabel htmlFor='file' file={file}>
          <ReplaceUploadText file={file}>{replaceOrUploadText}</ReplaceUploadText>
          <span> or drag and drop here.</span>
        </FileUploadLabel>
        <FileUploadInput
          type='file'
          id='file'
          accept='.pdf'
          onChange={evt => {
            handleFileUpload(evt.target.files[0])
            // to allow choosing of same file after removal
            evt.target.value = ''
          }}
        />
      </FileUploadBox>
    </FeedbackFileUploadWrapper>
  )
}

import styled from 'styled-components'
import checkMark from '../../../assets/icons/check-mark.svg'
import Button from '../../Button/Button'
import { paginationSelectStyles } from '../../WritingGradeCenter/styled'
import {
  CustomDatePicker,
  DatePickerContainer
} from '../../VipUser/styled'
import { Input } from 'reactstrap'
import Select from 'react-select'
import { dropDownStyles } from '../../global.styled'

export const highlightDropdownStyles = {
  ...dropDownStyles,
  placeholder: base => ({
    ...base,
    fontSize: '16px',
    lineHeight: '19.2px',
    color: 'var(--text-primary)'
  }),
  option: (base, state) => ({
    ...base,
    width: '100%',
    backgroundColor: !state.isSelected ? 'var(--input-primary)' : 'rgba(255, 255, 255, 0.1)',
    borderBottom: '1px solid var(--border-primary)',
    cursor: 'pointer',
    paddingTop: '9px',
    paddingBottom: '9px',
    fontSize: '16px',
    height: '40px',
    lineHeight: '19.2px',
    '&:last-child': {
      borderBottom: 'none',
      borderRadius: '0 0 5px 5px'
    },
    '&:first-of-type': {
      borderRadius: '5px 5px 0 0'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  }),
  menu: (base, state) => ({
    ...base,
    width: state.selectProps.width ? state.selectProps.width : '285px',
    background: ' #060606',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    zIndex: 1600,
    height: '122px'
  })
}
export const multiTextInputStyles = {
  control: base => ({
    ...base,
    width: '277px',
    marginTop: '10px',
    background: 'var(--input-primary)',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderRadius: '5px',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid var(--border-primary)'
    },
    '&:focus-within': {
      borderColor: 'var(--brand-regular)'
    }
  }),
  multiValue: base => ({
    ...base,
    background: 'var(--black-secondary)',
    borderRadius: '3px'
  }),
  multiValueLabel: base => ({
    ...base,
    color: 'var(--text-primary)',
    fontSize: '16px',
    lineHeight: '19.2px',
    fontWeight: 400
  }),
  multiValueRemove: base => ({
    ...base,
    color: 'var(--text-primary)',
    '&:hover': {
      background: 'var(--black-secondary)',
      color: 'var(--text-primary)'
    },
    svg: {
      height: 24,
      width: 24
    }
  }),
  input: base => ({
    ...base,
    color: 'var(--text-primary)'
  })
}

export const gradePanginationSelectStyles = {
  ...paginationSelectStyles,
  control: base => ({
    ...base,
    width: '73px',
    height: '34px',
    margin: '0 8px',
    background: ' #0D0D0F',
    color: 'white',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid var(--border-primary)'
    },
    '&:focus-within': {
      borderColor: 'var(--brand-regular)'
    }
  })
}

export const GradeReportContainer = styled.div`
  padding: 25px;
`

export const FiltersContainer = styled.span`
  display: flex;
  height: 17px;
`
export const FilterHeading = styled.span`
  font-size: 18px;
  margin-right: 8px;
  font-weight: 400;
  line-height: 21.6px;
`

export const FilterLabel = styled.span`
  font-size: 14px;
  line-height: 16.8px;
  text-transform: uppercase;
  color: var(--brand-regular);
  margin-left: 24px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 3px;

  img {
    margin-right: 5px;
    margin-bottom: 4px;
    height: 16px;
    width: 16px;
  }
`
export const ModalBodyWrapper = styled.div`
  height: 246px;
  width: 100%;
`
export const DropDownContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 17px;
`

export const DropDownWrapper = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;  
`
export const EmailIDMultiTextContainer = styled.div`
  margin: -2px 24px 22px -1px;
  ${({ marginTop }) => `margin-top: ${marginTop}` || 'inherit'};
  ${({ marginBottom }) => `margin-bottom: ${marginBottom}` || 'inherit'};
`
export const DateRangeFilterContainer = styled.div`
  margin: 0px 24px 0px 0px;
`
export const DatePickerSearch = styled(CustomDatePicker)`
  width: 277px;
  margin-top: 1px;
  line-height: 19.2px;
  cursor: pointer;
  &:focus {
    border-color: var(--brand-regular);
  }
  &::placeholder {
    color: var(--text-primary);
  }
  &:-ms-input-placeholder {
    color: var(--text-primary);
  }
  &::-ms-input-placeholder {
    color: var(--text-primary);
  }
`
export const FilterModalDatePickerWrapper = styled(DatePickerContainer)`
  .react-datepicker__input-container::after {
    ${({ disabled }) => disabled && 'opacity: 0.3 !important;'};
  }
`
export const DatePickerSearchFromWrapper = styled(FilterModalDatePickerWrapper)`
  margin: 9px 0px 11px 0px;
`

export const DropDownLabel = styled.span`
  color: var(--text-secondary);
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
`
export const EmailIDMultiTextLabel = styled(DropDownLabel)`
  margin-left: 1px;
`
export const ActiveDateRangeFromLabel = styled(DropDownLabel)`
  margin-top: 12px;
`
export const ActiveDateRangeToLabel = styled(DropDownLabel)`
  color: var(--text-primary);
  margin-left: 12px;
`
export const CheckBox = styled.label`
  display: block;
  width: max-content;
  position: relative;
  padding-left: 22px;
  margin-top: ${props => props.isExamRetakes ? '24px' : '38px'};
  margin-left: 1px;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 1376px) {
    margin-top:  ${props => props.isExamRetakes ? '24px' : '12px'};
  }

  @media (max-width: 1218px) {
    margin-top:  ${props => props.isExamRetakes ? '24px' : '38px'};
  }

  /* hide the default checkbox */
  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  /* create a custom radio button */
  span {
    position: absolute;
    left: 0;
    top: 2px;
    height: 16px;
    width: 16px;
    background-color: var(--canvas-primary);
    border: 1px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 3px;
  }

  /* When the checkbox is checked */
  input:checked ~ span {
    background-color: var(--brand-regular);
    border: 3px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 3px;
  }

  /* Create the check-mark/indicator */
  span::after {
    display: none;
    position: absolute;
    top: -5px !important;
    left: -2px !important;
    content: url(${checkMark});
  }

  /* Show the check-mark when checked */
  input:checked ~ span::after {
    left: 2px;
    display: block;
  }
`

export const ResetButton = styled(FilterLabel)`
  margin-left: 8px;
  cursor: pointer;
`

export const CustomButton = styled(Button)`
  width: 150px;
  margin-top: 14px;
  background-color: var(--brand-regular) !important;
  cursor: pointer !important;

  &:disabled {
    cursor: default !important;
  }
  
  &:active {
    background-color: var(--canvas-primary) !important;
  }
  
  a {
    color: black;
    text-decoration: none;
    
    &:active {
      color: var(--brand-regular) !important;
    }
  }
`

export const TableContainer = styled.div`
  margin-top: 36px;
`

export const TableHeader = styled.span`
  height: 93px;
  font-size: 24px;
  line-height: 28.8px;
  font-weight: 300;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const BottomLine = styled.div`
  border-bottom: 1px solid var(--border-primary);
  margin: 24px 0 23px 0;
`

export const ControlLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`
export const GradeRangeContainer = styled.div`
  margin: -2px 23px 26px 0px;
`
export const LetterGradeRangeContainer = styled.div`
  margin: 0px 0px 22px 0px;
`
export const GradeRangeStartSelect = styled(Select)`
  margin: 1px 12px 12px 0px;
`
export const GradeRangeEndSelect = styled(Select)`
  margin-top: -7px;
`
export const DarkThemedInput = styled(Input)`
  height: 40px;
  width: 200px;
  background: rgba(0, 0, 0, 0.3);
  color: var(--text-primary);
  border: 1px solid var(--border-primary);
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
  line-height: 19.2px;
  &::placeholder {
    color: var(--text-primary);
  }
  &:-ms-input-placeholder {
    color: var(--text-primary);
  }
  &::-ms-input-placeholder {
    color: var(--text-primary);
  }
  &:focus {
    color: var(--text-primary);
    background: var(--input-primary);
    border-color: var(--brand-regular);
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  &:disabled {
    background: rgba(0, 0, 0, 0.3);
    color: #495057;
    border: 1px solid var(--border-primary);
    &::placeholder {
    color: #495057;
    }
    &:-ms-input-placeholder {
      color: #495057;
    }
    &::-ms-input-placeholder {
      color: #495057;
    }
  }
`
export const NumericRangeStartInput = styled(DarkThemedInput)`
  margin: 10px 12px 12px 0px;
`
export const NumericRangeEndInput = styled(DarkThemedInput)`
  margin-top: 0px;
`
export const GradeContainerToLabel = styled.span`
  line-height: 19.2px;
`

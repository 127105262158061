import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
`

export const Text = styled.span`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
`

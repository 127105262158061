import api from '../../../../api'
import { getCourseIds } from '../../../../config'
import { VERSIONS } from '../../../../Constants/examTypes'

export const addExamsInCourse = ({ examChapters, courseUUID }) => {
  const { philosophy } = getCourseIds()
  const isPhilosophy = courseUUID === philosophy
  const allExams = []
  examChapters.forEach((exam) => {
    const examVersions = exam.examVersions
    const isCreateVersions = examVersions.length && isPhilosophy

    if (isCreateVersions) {
      const allVersionExams = examVersions.map((examVersion, index) => ({
        label: `${exam.title}${VERSIONS[index + 1]}`,
        value: examVersion.uuid
      }))
      const firstExamVersion = {
        label: `${exam.title}${VERSIONS[0]}`,
        value: exam.chapter_uuid
      }
      allExams.push(firstExamVersion, ...allVersionExams)
      return allExams
    }
    allExams.push({
      label: exam.title,
      value: exam.chapter_uuid
    })
  })
  return allExams
}

export const fetchCourseDatasPromise = async (courses) => {
  const promises = courses.map(async (course) => {
    const courseData = await api.getCourseData(course.id)
    const courseHasNoChapters =
      !courseData || !courseData.chapters || !courseData.chapters.length

    if (courseHasNoChapters) return course

    const courseUUID = course.id
    const examChapters = courseData.chapters.filter(
      (chapter) => chapter.type === 'exam'
    )

    course.exams = addExamsInCourse({
      examChapters,
      courseUUID
    })

    return course
  })

  return Promise.all(promises)
}

export function filterNullAndJoin (array) {
  return array.filter((item) => item !== null).join(', ')
}

export const groupRecords = (records) => {
  if (!records?.length) {
    return {}
  }

  return records.reduce((acc, record) => {
    const { fields } = record
    const studentId = fields.student[0]?.fields.id
    if (!acc[studentId]) {
      acc[studentId] = []
    }
    acc[studentId].push({ id: record.id, ...fields })
    return acc
  }, {})
}

const classifyRecords = (records, studentId, proctorReports, deniedRecords) => {
  return records.filter((record) => {
    if (record.source === 'proctor report') {
      if (!proctorReports[studentId]) {
        proctorReports[studentId] = []
      }
      proctorReports[studentId].push(record)
      return false
    }
    if (record.approvalStatus === 'Denied') {
      if (!deniedRecords[studentId]) {
        deniedRecords[studentId] = []
      }
      deniedRecords[studentId].push(record)
      return false
    }
    return true
  })
}

const sortRecordsByCreatedTime = (records) => {
  return records.sort(
    (a, b) => new Date(a.created_time) - new Date(b.created_time)
  )
}

const populateWarningsAndRemovals = (
  studentId,
  sortedRecords,
  warnings,
  removals
) => {
  if (sortedRecords.length > 0) {
    if (!warnings[studentId]) {
      warnings[studentId] = []
    }
    warnings[studentId].push({
      ...sortedRecords[0],
      label: 'Warning'
    })
  }
  if (sortedRecords.length > 1) {
    if (!removals[studentId]) {
      removals[studentId] = []
    }
    removals[studentId].push({
      ...sortedRecords[1],
      label: 'Removal'
    })
  }
}

const formatOutput = (records) => {
  return Object.keys(records).map((studentId) => {
    const record = records[studentId]
    return {
      email: record[0].email,
      assessments: record.map((rec) => rec.assessment),
      sanctions: record.map((rec) => rec.sanction),
      outlierNotes: record.map((rec) => rec.outlierNotes),
      studentId
    }
  })
}

export const getTableData = (groupedRecords) => {
  const proctorReports = {}
  const deniedRecords = {}
  const warnings = {}
  const removals = {}

  Object.keys(groupedRecords).forEach((studentId) => {
    const records = groupedRecords[studentId]
    const nonProctorAndNonDenied = classifyRecords(
      records,
      studentId,
      proctorReports,
      deniedRecords
    )
    const sortedRecords = sortRecordsByCreatedTime(nonProctorAndNonDenied)
    populateWarningsAndRemovals(studentId, sortedRecords, warnings, removals)
  })

  return {
    proctorReports: formatOutput(proctorReports),
    deniedRecords: formatOutput(deniedRecords),
    warnings: formatOutput(warnings),
    removals: formatOutput(removals)
  }
}

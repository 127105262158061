import React from 'react'
import { PrimaryButton } from '../../global.styled'
import {
  Container,
  MergeAccountsErrorDescription,
  MergeAccountsErrorMessage,
  Title
} from '../../MergeAccounts/styled'

function ErrorScreen (props) {
  const { setVoidStatus } = props

  return (
    <Container>
      <Title>
        Void course licenses
      </Title>
      <MergeAccountsErrorMessage>
        Oh no! There was an error processing the request.
      </MergeAccountsErrorMessage>
      <MergeAccountsErrorDescription>
        Go back to resubmit or contact Product for further assistance.
      </MergeAccountsErrorDescription>
      <PrimaryButton
        onClick={() => setVoidStatus(null)}
      >
        Go Back
      </PrimaryButton>
    </Container>
  )
}

export default ErrorScreen

import { isArray, isObject } from 'lodash'
import operatorMapping from '../filterOperators'
import { DATE_FIELDS } from './constants'

export function getFilteredMetaData (metaData, hiddenFields) {
  if (!metaData?.length) return []

  const filteredMetaData = metaData.filter(data => !hiddenFields.includes(data.columnName))
  return filteredMetaData
}

export function getModifiedMetaData (metaData = []) {
  if (!metaData?.length) return []

  const modifiedMetaData = metaData.map((data = {}) => {
    if (!data?.columnName || !data?.dataType) return data

    const fieldName = data.columnName.toLowerCase()
    const dateFieldsLowerCase = DATE_FIELDS.map(field => field.toLowerCase())
    const isDate = ['timestamp without time zone', 'date'].includes(data.dataType)
    const shouldBeDate = !isDate && dateFieldsLowerCase.includes(fieldName)
    if (!shouldBeDate) return data

    return {
      ...data,
      dataType: 'date'
    }
  })
  return modifiedMetaData
}

export function getColumnMetaData (value, metaData) {
  if (!metaData?.length) return {}

  const filterValue = (value || '').toLowerCase()
  return metaData.find(data => data.columnName === filterValue) || {}
}

export function getOperators (value, metaData) {
  if (!metaData?.length) return []

  const { dataType, isJoined, isLookup, options, isAutoGenerated } = getColumnMetaData(value, metaData)
  if (dataType === 'timestamp without time zone' || dataType === 'date') { return (operatorMapping.filter(item => item.supportsDate)) }
  if (isAutoGenerated && dataType === 'date') { return (operatorMapping.filter(item => item.supportsDate)) }
  if (dataType === 'boolean') { return (operatorMapping.filter(item => item.supportsBoolean)) }
  if (dataType === 'text' && options) { return (operatorMapping.filter(item => item.supportsOptions)) }
  if (dataType === 'ARRAY' && isJoined) { return (operatorMapping.filter(item => item.supportsString)) }
  if (dataType === 'text') { return (operatorMapping.filter(item => item.supportsString)) }
  if (dataType === 'numeric' || dataType === 'integer') { return (operatorMapping.filter(item => item.supportsNumber)) }
  if (isLookup) { return (operatorMapping.filter(item => item.supportsString)) }
  return []
}

export function getFiltersWithRules (filters, headerOptions) {
  if (!filters) return {}
  if (!headerOptions?.length) return {}
  if (filters.rules?.length) return filters

  const options = getOptions(filters, headerOptions)
  const rules = getRulesFromOptions(options)
  return {
    ...filters,
    rules
  }
}

export function getFilters (options, logicalOperator) {
  if (!options?.length) return {}
  if (!logicalOperator) return {}

  const filters = {}
  options.forEach(option => {
    const {
      filterColumn,
      filterOperator,
      filterValue
    } = option
    if (!filterColumn || !filterOperator) return
    const { value: operator } = filterOperator || {}
    const operation = {
      [operator]: filterValue
    }
    const { value } = filterColumn || {}
    if (filters[value]) {
      const currentOperation = { ...filters[value], ...operation }
      filters[value] = currentOperation
    } else {
      filters[value] = operation
    }
  })

  filters.rules = getRulesFromOptions(options)
  filters.logicalOperator = logicalOperator.value
  return filters
}

export function getOptions (filters, headerOptions) {
  if (!filters) return [{}]
  if (!headerOptions?.length) return [{}]

  const options = []

  const rules = filters?.rules || []
  if (rules?.length) {
    for (const rule of rules) {
      const { field, operator, value } = rule
      const filterColumn = headerOptions.find(option => option.value === field)
      const filterOperator = operatorMapping.find(item => item.value === operator)
      const option = {
        filterColumn,
        filterOperator,
        filterValue: value
      }
      options.push(option)
    }
    return options
  }

  const columns = Object.keys(filters || {})
  for (const column of columns) {
    if (column === 'logicalOperator') continue
    if (column === 'rules') continue
    const filterColumn = headerOptions.find(option => option.value === column)
    const filterObject = filters?.[column] || {}
    const operators = Object.keys(filterObject || {})
    for (const operator of operators) {
      const filterOperator = operatorMapping.find(item => item.value === operator)
      const filterValue = filterObject?.[operator]
      const option = {
        filterColumn,
        filterOperator,
        filterValue
      }
      options.push(option)
    }
  }

  if (!options.length) return [{}]
  return options
}

export const getRulesFromOptions = (options) => {
  if (!options?.length) return []

  const rules = options.map(option => {
    const { filterColumn, filterOperator, filterValue } = option
    if (!filterColumn || !filterOperator) return null
    return {
      field: filterColumn?.value,
      operator: filterOperator.value,
      value: filterValue
    }
  })

  return rules.filter(Boolean)
}

export const getHideFieldsText = (hiddenFieldsCount) => {
  if (hiddenFieldsCount === 1) {
    return `${hiddenFieldsCount} Hidden Field`
  }
  if (hiddenFieldsCount > 1) {
    return `${hiddenFieldsCount} Hidden Fields`
  }

  return 'Hide Fields'
}

export const getOrderedColumns = (columns, columnOrder) => {
  if (!columnOrder || columnOrder.length === 0) {
    return columns
  }
  const columnOrderObj = columnOrder.reduce((obj, item, index) => {
    obj[item.id.toLowerCase()] = index
    return obj
  }, {})

  return [...columns].sort((a, b) => {
    const orderA = columnOrderObj[a.columnName] ?? Number.MAX_VALUE
    const orderB = columnOrderObj[b.columnName] ?? Number.MAX_VALUE
    return orderA - orderB
  })
}

export const getTableColumns = (columns, view) => {
  const orderedColumns = getOrderedColumns(columns, view?.columnOrder)

  if (!view?.hiddenFields?.length) { return { visibleColumns: orderedColumns, hiddenColumns: [] } }

  const partitionedColumns = orderedColumns.reduce(
    (acc, column) => {
      const isHidden =
        view.hiddenFields.includes(column.columnName) ||
        view.hiddenFields.includes(column.camelCase)
      if (isHidden) {
        acc.hiddenColumns.push(column)
      } else {
        acc.visibleColumns.push(column)
      }
      return acc
    },
    { visibleColumns: [], hiddenColumns: [] }
  )

  return partitionedColumns
}

export const getCSVItem = (item) => {
  if (!isNaN(item)) { return item }
  if (isArray(item)) {
    const value = item.map(data => {
      return data?.value ?? data
    }).join(';')
    return value
  }
  if (isObject(item)) {
    return item?.value ?? item?.id ?? `"${JSON.stringify(item)}"`
  }
  return item?.includes(',') ? `"${item}"` : item
}

import React, { useEffect, useState } from 'react'
import api from '../../../api'
import { useAuth0 } from '../../../Components/Auth0Provider/Auth0Provider'
import {
  getDateTimeWithComma,
  getTimeAgo
} from '../../../utilities/dateTimeUtil'
import {
  CardTitle,
  CommentButton,
  CommentButtonContainer,
  CommentInput,
  CommentItemAuthor,
  CommentItemDate,
  CommentItemInfo,
  CommentItemText,
  CommentItemWrapper,
  CommentWrapper,
  CommentsCard,
  CommentsContainer,
  CommentsContainerWrapper,
  CommentsNote,
  CommentsSectionWrapper
} from '../style/comments'
import { TransparentButton } from '../../../styles/buttons'

export const CommentItem = ({ comment }) => {
  const {
    fields: {
      author_email: author,
      updated_time: date,
      comment: commentText
    }
  } = comment || {}

  const { user: { email: currentUserEmail } = {} } = useAuth0() || {}

  const getTimeAgoFromDate = getTimeAgo(date)
  const dateText = getTimeAgoFromDate || getDateTimeWithComma(date)
  const authorText = author === currentUserEmail
    ? 'You commented'
    : `${author} commented`

  return (
    <CommentItemWrapper>
      <CommentItemInfo>
        <CommentItemAuthor>{authorText}</CommentItemAuthor>
        <CommentItemDate>{dateText}</CommentItemDate>
      </CommentItemInfo>
      <CommentItemText>{commentText}</CommentItemText>
    </CommentItemWrapper>
  )
}

function CommentsSection ({
  prospectATId
}) {
  const [commentsList, setCommentsList] = useState([])
  const [commentText, setCommentText] = useState('')
  const [isTextInputFocused, setIsTextInputFocused] = useState(false)

  useEffect(() => {
    if (!prospectATId) return
    fetchComments()
    // eslint-disable-next-line
  }, [prospectATId])

  const fetchComments = async () => {
    const { rows: comments } =
      await api.getTableRecordComments('prospects', prospectATId) || {}
    const sortedComments = comments?.sort((a, b) => {
      const dateA = new Date(a?.fields?.updated_time)
      const dateB = new Date(b?.fields?.updated_time)
      return dateA - dateB
    })
    setCommentsList(sortedComments)
  }

  const saveComment = async () => {
    if (commentText === '') return
    await api.createTableRecordComment('prospects', prospectATId, commentText)
    setCommentText('')
    fetchComments()
  }

  const noComments = commentsList?.length === 0

  return (
    <CommentsSectionWrapper>
      <CommentsCard>
        <CardTitle>Comments</CardTitle>
        {noComments
          ? (
            <CommentsNote>
              Any notes about this student will appear here. Please add an
              internal note about any changes you make to the student's course load.
            </CommentsNote>
          ) : (
            <>
              <CommentsNote>
                Add a comment about any changes you make to the student's enrollment.
              </CommentsNote>
              <CommentsContainerWrapper>
                <CommentsContainer>
                  {commentsList?.map((comment, index) => (
                    <CommentItem key={index} comment={comment} />
                  ))}
                </CommentsContainer>
              </CommentsContainerWrapper>
            </>
          )
        }
      </CommentsCard>
      <CommentWrapper>
        <CommentInput
          onFocus={() => setIsTextInputFocused(true)}
          onBlur={() => setIsTextInputFocused(false)}
          placeholder='Leave a comment'
          autoComplete='off'
          type='text'
          value={commentText}
          onChange={e => setCommentText(e.target.value)}
        />
        <CommentButtonContainer isTextInputFocused={isTextInputFocused}>
          <CommentButton
            onMouseDown={(e) => {
              e.preventDefault()
              saveComment(commentText)
            }}
          >
            comment
          </CommentButton>
          <TransparentButton>cancel</TransparentButton>
        </CommentButtonContainer>
      </CommentWrapper>
    </CommentsSectionWrapper>
  )
}

CommentsSection.displayName = 'CommentsSection'
export default CommentsSection

export const initialFiltersData = {
  advisors: [],
  degrees: [],
  searchText: ''
}

export const initialSelectedData = {
  advisors: null,
  degrees: null
}

export const initialProspectsData = {
  isLoading: false,
  review: [],
  approved: []
}

export const PAGES = {
  main: 'main',
  prospect: 'prospect'
}

export const TABLES = {
  review: 'review',
  approved: 'approved'
}

export const prospectsTableHeaders = [
  { title: 'name', value: 'name' },
  { title: 'email', value: 'email' },
  { title: 'ggu email', value: 'gguEmail' },
  { title: 'ggu id', value: 'gguStudentId' },
  { title: 'degree', value: 'degree' },
  { title: 'advisor', value: 'advisorName' }
]

export const studentDetails = [
  { title: 'Full name', value: 'fullName' },
  { title: 'Email', value: 'email' },
  { title: 'Outlier ID', value: 'outlierId' },
  { title: 'GGU email', value: 'gguEmail' },
  { title: 'GGU ID', value: 'gguStudentId' }
]

export const admissionDetails = [
  { title: 'Admission decision', value: 'admissionDecision' },
  { title: 'Study schedule', value: 'studyMode' },
  { title: 'Advisor', value: 'advisorName' }
]

export const degreeStatuses = [
  'Active',
  'Drop Out',
  'Stop Out',
  'Complete'
]

export const degreeStatusOptions = degreeStatuses.map(
  status => ({ name: status, label: status, value: status })
)

export const HISTORY_LIMIT = 10

export const initialEnrollmentStatus = {
  isLoading: false,
  isError: false
}

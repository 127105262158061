import React, { useState, useEffect } from 'react'
import { ReactComponent as ErrorLicenseSvg } from '../../../assets/icons/error-license.svg'
import {
  VoidAllLicensesContainer,
  VoidAllLicensesSection,
  VoidAllLicensesTitle,
  VoidCoursesLicensesTitleDescription,
  VoidAllLicensesTableHeader,
  VoidCoursesLicensesTableHeaderItem,
  VoidAllLicensesTableRow,
  VoidCoursesLicensesTableRowItem,
  VoidAllCourseName,
  VoidAllDuration,
  VoidCourseNumberToVoidContainer,
  VoidCourseNumberToVoidError,
  VoidCourseNumberToVoid,
  VoidAllLicensesTableFooter,
  VoidCoursesLicensesTableFooterItem,
  VoidAllLicensesMessage
} from './styled'

function VoidCourseLicenses (props) {
  const {
    setTokensToVoid,
    selectedRelationship,
    coursesLicenses
  } = props

  const [numberOfLicensesToVoid, setNumberOfLicensesToVoid] = useState({})

  const availableLicenses =
    coursesLicenses.filter(({ usedStatus }) => !usedStatus)
  const coursesLicensesForSelectedRelationship =
    availableLicenses.filter(({ relationshipName }) =>
      relationshipName === selectedRelationship?.label)
  const groupedCourseLicenses =
    coursesLicensesForSelectedRelationship.reduce((acc, value) => {
      const { courseLicense } = value
      if (!acc[courseLicense]) {
        acc[courseLicense] = []
      }
      acc[courseLicense].push(value)
      return acc
    }, {})

  const totalLicenses =
    Object.entries(numberOfLicensesToVoid).reduce((acc, [key, value]) => {
      if (!value) {
        return acc
      }
      if (value > groupedCourseLicenses[key].length) {
        return acc
      }
      return acc + Number(value)
    }, 0)

  useEffect(() => {
    const tokensToVoid = []
    Object.entries(numberOfLicensesToVoid).forEach(([key, value]) => {
      const keyTokens = groupedCourseLicenses[key]
      const limitedKeyTokens = keyTokens.slice(0, value)
      tokensToVoid.push(...limitedKeyTokens)
    })

    const tokenIds = tokensToVoid.map(({ id }) => id)
    setTokensToVoid(tokenIds)
    // eslint-disable-next-line
    }, [numberOfLicensesToVoid])

  return (
    <VoidAllLicensesContainer>
      <VoidAllLicensesSection>
        <VoidAllLicensesTitle>
          Licenses
        </VoidAllLicensesTitle>
        <VoidCoursesLicensesTitleDescription>
          Enter the number of licenses to void for each course. To skip a course, leave the field blank.
        </VoidCoursesLicensesTitleDescription>
        <VoidAllLicensesTableHeader>
          <VoidCoursesLicensesTableHeaderItem>
            course
          </VoidCoursesLicensesTableHeaderItem>
          <VoidCoursesLicensesTableHeaderItem>
            available
          </VoidCoursesLicensesTableHeaderItem>
          <VoidCoursesLicensesTableHeaderItem>
            number to void
          </VoidCoursesLicensesTableHeaderItem>
        </VoidAllLicensesTableHeader>
        <div>
          {Object.entries(groupedCourseLicenses).map(([key, data]) => (
            <VoidAllLicensesTableRow key={key}>
              <VoidCoursesLicensesTableRowItem>
                <VoidAllCourseName>
                  {data[0].course.displayname}
                </VoidAllCourseName>
                <VoidAllDuration>
                  {data[0].licenseDuration} Weeks
                </VoidAllDuration>
              </VoidCoursesLicensesTableRowItem>
              <VoidCoursesLicensesTableRowItem>
                {data.length}
              </VoidCoursesLicensesTableRowItem>
              <VoidCourseNumberToVoidContainer>
                {
                  numberOfLicensesToVoid[key] > data.length &&
                  <VoidCourseNumberToVoidError>
                    <ErrorLicenseSvg />
                    <div>
                      Cannot exceed available licenses
                    </div>
                  </VoidCourseNumberToVoidError>
                }
                <VoidCourseNumberToVoid
                  type='number'
                  placeholder='0'
                  isError={numberOfLicensesToVoid[key] > data.length}
                  value={numberOfLicensesToVoid[key]}
                  onInput={(e) => {
                    setNumberOfLicensesToVoid({
                      ...numberOfLicensesToVoid,
                      [key]: Math.trunc(e.target.value)
                    })
                  }
                  }
                />
              </VoidCourseNumberToVoidContainer>
            </VoidAllLicensesTableRow>
          ))}
        </div>
        <VoidAllLicensesTableFooter>
          <VoidCoursesLicensesTableFooterItem>
            Total licenses to void
          </VoidCoursesLicensesTableFooterItem>
          <VoidCoursesLicensesTableFooterItem>
            {totalLicenses}
          </VoidCoursesLicensesTableFooterItem>
        </VoidAllLicensesTableFooter>
      </VoidAllLicensesSection>
      <VoidAllLicensesMessage>
        By clicking “submit”, you agree to void {totalLicenses} licenses for {selectedRelationship.label}.
      </VoidAllLicensesMessage>
    </VoidAllLicensesContainer>
  )
}

export default VoidCourseLicenses

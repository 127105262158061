import React, { useState } from 'react'
import {
  ActiveIndicator,
  Container,
  Header,
  HeaderContainer,
  IndicatorsContainer,
  Separator
} from './styled'
import { DOWNLOAD_COMPLETED_CSV, UPLOAD_CSV } from '../../Constants'
import AmazonCSVUpload from './AmazonCSVUpload'
import AmazonDownloadCSV from './AmazonDownloadCSV'

const AmazonStudentProgress = ({ routerProps: { match, history } }) => {
  const [uploadedCSV, setUploadedCSV] = useState(undefined)
  const [fileName, setFileName] = useState('')
  const { step } = match.params

  const setCSVFile = (file) => {
    setUploadedCSV(file)
    history.push(`/amazon-student-progress/${DOWNLOAD_COMPLETED_CSV}`)
  }

  const renderStep = () =>
    ({
      [UPLOAD_CSV]: (
        <AmazonCSVUpload
          setFileName={setFileName}
          setUploadedCSV={(file) => setCSVFile(file)}
        />
      ),
      [DOWNLOAD_COMPLETED_CSV]: (
        <AmazonDownloadCSV
          uploadedCSV={uploadedCSV}
          fileName={fileName}
        />
      )
    }[step])

  return (
    <Container>
      <HeaderContainer>
        <Header>Amazon Student Progress Update</Header>
      </HeaderContainer>
      <Separator />
      <IndicatorsContainer>
        <ActiveIndicator
          onClick={() => {
            step === DOWNLOAD_COMPLETED_CSV &&
            history.push(`/amazon-student-progress/${UPLOAD_CSV}`)
          }}
          clickable={step === DOWNLOAD_COMPLETED_CSV}
          active={step === UPLOAD_CSV}
        >
          <span>1</span> Upload CSV
        </ActiveIndicator>
        <ActiveIndicator active={step === DOWNLOAD_COMPLETED_CSV}>
          <span>2</span> Download completed CSV
        </ActiveIndicator>
      </IndicatorsContainer>
      {renderStep() || history.push(`/amazon-student-progress/${UPLOAD_CSV}`)}
    </Container>
  )
}

export default AmazonStudentProgress

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  Container,
  Header,
  Separator
} from './styled'
import Requests from './Requests'
import api from '../../api'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import AmazonRefundsHistory from '../AmazonRefundsHistory/AmazonRefundsHistory'

const AmazonRefunds = () => {
  const location = useLocation()
  const { content } = location || {}
  const [refundRequests, setRefundRequests] = useState(content || [])
  const [isLoading, setIsLoading] = useState(false)

  const getRefundRequests = async () => {
    setIsLoading(true)
    const requests = await api.getAmazonRefundRequests()
    setIsLoading(false)
    setRefundRequests(requests)
  }

  useEffect(() => {
    if (content?.length) return

    getRefundRequests()

    // eslint-disable-next-line
  }, [content])

  if (isLoading) return <LoadingSpinner />

  return (
    <>
      <Container>
        <Header>Amazon refunds</Header>
        <Separator />
        <Requests
          refundRequests={refundRequests}
          getRefundRequests={getRefundRequests}
        />
      </Container>
      <AmazonRefundsHistory refundRequests={refundRequests} />
    </>
  )
}

AmazonRefunds.displayName = 'AmazonRefunds'

export default AmazonRefunds

import React from 'react'
import {
  Header,
  History,
  NotificationButton,
  NotificationContainer,
  NotificationPageHeading,
  NotificationSeparator
} from './styled'
import NotificationList from './List'

const Notifications = () => {
  return (
    <NotificationContainer>
      <Header>
        <NotificationPageHeading>Notifications</NotificationPageHeading>
        <NotificationButton className='btn-custom btn-primary'>
            Create New
        </NotificationButton>
      </Header>
      <NotificationSeparator />
      <History>
        History
      </History>
      <NotificationList />
    </NotificationContainer>
  )
}

export default Notifications

import React, { useEffect, useMemo, useState } from 'react'
import {
  ClearIcon,
  DarkSearchField,
  FilterContainer,
  FiltersHeader,
  FiltersSection,
  FiltersWrapper,
  SearchButton,
  SearchButtonIcon,
  SearchIcon,
  SearchTextField
} from './styles'
import { ContentTitle } from '../../../../styles/text'
import { TransparentButton } from '../../../../styles/buttons'
import CustomSelect from '../../../../Components/CustomSelect/UpdatedSelect'
import api from '../../../../api'
import { SelectLabel } from '../styles'
import { fetchCourseDatasPromise } from './utils'
import { getSortedCourses } from '../../../../utilities/gradeReportUtils'

function Filters ({ filtersData, setFiltersData, handleReset }) {
  const [initialFiltersData, setInitialFiltersData] = useState({
    courses: [],
    cohorts: [],
    assessments: []
  })
  const [showSearch, setShowSearch] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { courses, cohorts, assessments } = initialFiltersData
  const { course, cohort, assessment, searchText } = filtersData

  useEffect(() => {
    getCoursesWithExams()
    // eslint-disable-next-line
  }, [])

  const getCoursesWithExams = async () => {
    setIsLoading(true)
    const allCourses = await api.getCourses()
    const coursesExamData = await fetchCourseDatasPromise(allCourses)
    const sortedCoursesWithExam = getSortedCourses(coursesExamData)

    const coursesWithExams = sortedCoursesWithExam.filter(
      (course) => course.exams && course.exams.length
    )

    if (!coursesWithExams) return

    setInitialFiltersData({
      ...initialFiltersData,
      courses: coursesWithExams.map((course) => {
        return {
          ...course,
          label: course.name,
          value: course.id
        }
      })
    })
    setIsLoading(false)
  }

  const handleCourseSelectChange = (course) => {
    setFiltersData({ ...filtersData, course, assessment: null, cohort: null })
    const { cohorts, exams } = course

    setInitialFiltersData({
      ...initialFiltersData,
      assessments: exams,
      cohorts
    })
  }

  const handleCohortSelectChange = (cohort) => {
    setFiltersData({ ...filtersData, cohort })
  }

  const cohortOptions = useMemo(() => {
    return cohorts.map((cohort) => {
      return {
        label: cohort.name,
        value: cohort.id
      }
    })
  }, [cohorts])

  return (
    <FiltersSection>
      <FiltersHeader>
        <ContentTitle>Filters</ContentTitle>
        <TransparentButton
          onClick={handleReset}
          disabled={!course || !cohort}
        >
          reset
        </TransparentButton>
      </FiltersHeader>

      <FiltersWrapper>
        <FilterContainer>
          <SelectLabel>Course</SelectLabel>
          <CustomSelect
            height='48px'
            width='250px'
            hideSelectedOptions={false}
            value={course}
            options={courses}
            placeholder='Select course'
            onChange={handleCourseSelectChange}
            isLoading={isLoading}
          />
        </FilterContainer>
        <FilterContainer>
          <SelectLabel>Cohort</SelectLabel>
          <CustomSelect
            height='48px'
            width='250px'
            hideSelectedOptions={false}
            value={cohort}
            options={cohortOptions}
            placeholder='Select cohort'
            onChange={handleCohortSelectChange}
            isLoading={isLoading}
            isDisabled={!course}
          />
        </FilterContainer>
        <FilterContainer>
          <SelectLabel>Assessment</SelectLabel>
          <CustomSelect
            height='48px'
            width='250px'
            hideSelectedOptions={false}
            isDisabled={!course || !cohort}
            value={assessment}
            options={assessments}
            placeholder='Select assessment'
            onChange={(assessment) =>
              setFiltersData({ ...filtersData, assessment })
            }
          />
        </FilterContainer>

        {showSearch ? (
          <DarkSearchField isDark style={{ width: '300' }}>
            <SearchTextField
              placeholder='Enter email address'
              value={searchText}
              onChange={(e) =>
                setFiltersData({ ...filtersData, searchText: e.target.value })
              }
            />
            {searchText ? (
              <ClearIcon
                onClick={() =>
                  setFiltersData({ ...filtersData, searchText: '' })
                }
              />
            ) : (
              <SearchIcon />
            )}
          </DarkSearchField>
        ) : (
          <SearchButton
            onClick={() => setShowSearch(true)}
            disabled={!course || !cohort}
          >
            <SearchButtonIcon />
          </SearchButton>
        )}
      </FiltersWrapper>
    </FiltersSection>
  )
}

export default Filters

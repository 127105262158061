import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import RadioButtonSelector from '../../RadioButtonSelector'

import { getPartnerOptions } from '../../../utilities/EnrollStudentsTool'
import api from '../../../api'
import Select from 'react-select'
import {
  customDropDownStyles,
  DropDownWrapper,
  SoftWarningMessage
} from '../style'
import { Label } from 'recharts'
import { GUILD } from '../../../Constants/relationships'
import { ReactComponent as WarningIcon } from '../../../assets/icons/exclamation-circle.svg'
import { ClearIndicator, DropdownIndicator, Group } from '../../CustomSelect/CustomSelectComponents'

const PartnerEnrollment = ({
  isDegreePlusStudent,
  onBehalfOfPartner,
  degreePlusEnrollment,
  handleDegreePlusChange,
  handlePartnerEnrollmentChange,
  setSelectedRelationship,
  relationshipDetailsRef,
  setPartnerAvailableTokens
}) => {
  const [relationShipsOptions, setRelationShipsOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showGuildEnrollment, setShowGuildEnrollment] = useState(false)

  const showRelationshipsDropdown = onBehalfOfPartner.partner === true

  const fetchRelationShips = useCallback(async () => {
    setIsLoading(true)
    const relationships = await api.getStudentRelationships()
    const creditGrantingInstitutions = {}

    for (const relationship of relationships) {
      const {
        id,
        relationshipName,
        creditGrantingInstitution,
        enrollmentOptions,
        enrollmentcertificate,
        relationshipAdminEmail,
        relationshipOtherEmails
      } = relationship

      if (!creditGrantingInstitutions[creditGrantingInstitution]) {
        creditGrantingInstitutions[creditGrantingInstitution] = []
      }

      creditGrantingInstitutions[creditGrantingInstitution].push({
        relationshipAdminEmail: relationshipAdminEmail || relationshipOtherEmails?.[0],
        enrollmentCertificate: enrollmentcertificate,
        enrollmentOptions: enrollmentOptions,
        creditGrantingInstitution: creditGrantingInstitution,
        label: relationshipName,
        value: id
      })
    }

    const relationShipsOptions = []

    Object.keys(creditGrantingInstitutions).forEach((creditGrantingInstitution) => {
      relationShipsOptions.push({
        label: creditGrantingInstitution,
        options: creditGrantingInstitutions[creditGrantingInstitution]
      })
    })

    setRelationShipsOptions(relationShipsOptions)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!(showRelationshipsDropdown && !relationShipsOptions?.length)) return
    fetchRelationShips()
    // eslint-disable-next-line
  }, [showRelationshipsDropdown])

  const handleRelationshipChange = useCallback(async (value) => {
    const { label, relationshipAdminEmail } = value || {}
    setSelectedRelationship(value)
    label === GUILD
      ? setShowGuildEnrollment(true)
      : setShowGuildEnrollment(false)
    const { availableTokens } =
    await api.getPartnerAvailableTokens(relationshipAdminEmail) || {}
    setPartnerAvailableTokens(availableTokens)
    // eslint-disable-next-line
  }, [])

  return (
    <div ref={relationshipDetailsRef}>
      {(isDegreePlusStudent && (
          degreePlusEnrollment?.degreePlus !== false
      )) &&
        (
          <RadioButtonSelector
            headerText='Would you like to continue with a Degrees+ enrollment?'
            exposeKey='degreePlus'
            options={getPartnerOptions()}
            values={degreePlusEnrollment}
            setValue={handleDegreePlusChange}
          />
        )}
      {(!isDegreePlusStudent ||
        (
          degreePlusEnrollment?.degreePlus === false
        )) && (
        <RadioButtonSelector
          headerText='Would you like to enroll on behalf of a partner?'
          exposeKey='partner'
          options={getPartnerOptions()}
          values={onBehalfOfPartner}
          setValue={handlePartnerEnrollmentChange}
        />
      )}
      {showGuildEnrollment && (
        <SoftWarningMessage>
          <WarningIcon />
          <p>Guild students are enrolled via CSV files.
          Visit the <a href='#/guild'>Guild tool</a> to complete enrollment.
          If you wish to continue, continue to the next section.</p>
        </SoftWarningMessage>
      )}
      {showRelationshipsDropdown && (
        <DropDownWrapper>
          <Label for='creditGrantingInstitution'>Credit Granting Institution</Label>
          <DropDownWrapper>
            <Select
              isClearable
              isLoading={isLoading}
              id='creditGrantingInstitution'
              options={relationShipsOptions}
              name='creditGrantingInstitution'
              data-testid='relationship-dropdown'
              placeholder='Select a relationship'
              styles={{ ...customDropDownStyles, height: '56px' }}
              components={{ DropdownIndicator, ClearIndicator, Group }}
              onChange={handleRelationshipChange}
            />
          </DropDownWrapper>
        </DropDownWrapper>
      )}
    </div>
  )
}

PartnerEnrollment.propTypes = {
  DegreePlusStudent: PropTypes.bool,
  onBehalfOfPartner: PropTypes.object.isRequired,
  handlePartnerEnrollmentChange: PropTypes.func.isRequired
}

export default React.memo(PartnerEnrollment)

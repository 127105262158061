import { getLabelValueOptions } from '.'
import { VOUCHER_DENIAL_REASONS, VOUCHER_STATUS } from '../Constants/voucher'

export const getVoucherStatuses = () => {
  return getLabelValueOptions(VOUCHER_STATUS)
}

export const getVoucherDenialReason = () => {
  return getLabelValueOptions(VOUCHER_DENIAL_REASONS)
}

export const getInvoiceText = voucher => {
  const {
    studentName,
    studentEmail,
    invoiceData: { orderNumber }
  } = voucher

  return `
Tuition Billing Statement

Outlier Org, Inc
240 Kent Avenue, Suite A3
Brooklyn, NY 11249

Bill to: Amazon
Order Number: ${orderNumber}

Student Name: ${studentName}
Student Email: ${studentEmail}

${getCourseInfo(voucher)}`
}

export const getCourseInfo = voucher => {
  const { certificateName, courseName, invoiceData: { courses } } = voucher

  const totalPrice = courses.reduce((total, course) => total + course.price, 0)
  const coursesText = courses.map(
    course => `Course: ${course.courseName} ($${course.price})`
  ).join('\n')

  return `
${getCertificateName(certificateName, courseName)}
${coursesText}
Grand Total: $${totalPrice}`.trim()
}

const getCertificateName = (certificateName) => {
  if (!certificateName) return ''

  return `Certificate: ${certificateName}`
}

export const getDownloadFileName = voucher => {
  const {
    invoiceData: { orderNumber }
  } = voucher

  return `invoice-${orderNumber}.pdf`
}

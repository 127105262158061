import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ModalComponent from '../ModalComponent/ModalComponent'
import './EditGradeModal.css'
import Button from '../Button/Button'
import {
  MIN_PARTICIPATION_GRADE
} from '../../utilities/gradeUtils'
import { useStudentGradeContext } from '../../contexts/StudentGradeContext'

function EditParticipationModal ({
  close,
  show,
  isUpdating,
  participationGrade,
  handleEditParticipation
}) {
  const {
    maxParticipationGrade
  } = useStudentGradeContext()

  const [score, setScore] = useState('')
  const [scoreError, setScoreError] = useState(false)

  const updateParticipation = () => {
    if (
      score === '' ||
      score > maxParticipationGrade ||
      score < MIN_PARTICIPATION_GRADE
    ) return setScoreError(true)

    const editData = {
      from: participationGrade,
      to: parseFloat(parseFloat(score).toFixed(2))
    }

    editData.sectionUuid = 'participation'

    handleEditParticipation(editData)
  }

  const headingText = `Please enter a number between 
    ${MIN_PARTICIPATION_GRADE} and ${maxParticipationGrade}`

  return (
    <ModalComponent show={show} handleClose={close}>
      <div className='edit-grade-wrapper'>
        <span>
          Participation Modification
        </span>
        <span className='section-title'>
          Earned Grade
        </span>
        <div className={classNames({
          questionStatus: true,
          correct: true
        })}>The current recorded score is
          <div>
            &nbsp;{participationGrade}/{maxParticipationGrade}
          </div>
        </div>

        <span className='section-title'>
          Score ({headingText})
        </span>
        {scoreError && <div className='text-error'>{headingText}</div>}
        <input type='number' className={scoreError ? 'text-area-error' : 'text-area'}
          value={score} onChange={(e) => { setScore(e.target.value) }} />
      </div>

      <div className='buttons'>
        <Button className='btn-custom btn-secondary button' onClick={close}>CANCEL</Button>
        <Button className='btn-custom btn-primary button' onClick={() => updateParticipation()}>
          {isUpdating ? <i className='fa fa-spinner fa-spin' /> : 'SAVE'}
        </Button>
      </div>
    </ModalComponent>
  )
}

EditParticipationModal.propTypes = {
  close: PropTypes.func.isRequired,
  handleEditParticipation: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  participationGrade: PropTypes.number.isRequired
}

export default EditParticipationModal

import React from 'react'
import {
  Label,
  CustomCheckBox
} from './styled'

const CheckBox = ({
  label,
  name,
  value,
  onChange,
  checked,
  disabled
}) => {
  return (
    <Label disabled={disabled}>
      <CustomCheckBox
        type='checkbox'
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
      />
      {label && <span>{label}</span>}
    </Label>
  )
}

CheckBox.displayName = 'CheckBox'
export default CheckBox

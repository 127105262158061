import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../ModalComponent/ModalComponent'
import {
  ModalHeader,
  ModalSeparator,
  ModalContent,
  RequestDetail,
  ButtonsContainer,
  PrimaryButton,
  SecondaryButton
} from './styled'
import CloseIcon from '../../assets/icons/cross.svg'
import { getDateString } from '../../utilities/dateTimeUtil'
import DecisionConfirmationModal from './DecisionConfirmationModal'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import api from '../../api'
import {
  DECISION_APPROVED,
  DECISION_DENIED
} from '../../Constants'

function DecisionModal ({
  show,
  setShowDecisionModal,
  getRefundRequests,
  request
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const {
    requestId,
    requestReceived,
    certificateName,
    studentName,
    studentEmail,
    refundAmount,
    latestRefundDate: refundDate,
    earliestDropDate: dropDate,
    passedRequirements,
    totalRequirements,
    approveIcon,
    denyIcon
  } = request || {}
  const requestReceivedDate = new Date(requestReceived)
  const earliestDropDate = new Date(dropDate)
  const latestRefundDate = new Date(refundDate)
  const requestReceivedDateString = getDateString(requestReceivedDate, 'MMM DD, YYYY')
  const latestRefundDateString = getDateString(latestRefundDate, 'MM/DD/YY')
  const earliestDropDateString = getDateString(earliestDropDate, 'MM/DD/YY')
  const canApprove = passedRequirements === totalRequirements
  const withinRefundWindow = requestReceivedDate < latestRefundDate
  const withinDropDeadline = requestReceivedDate < earliestDropDate

  const recommendedActionHandler = async (decision) => {
    setIsLoading(true)
    const result = await api.saveRefundDecision({
      requestId,
      decision
    })
    setIsLoading(false)

    const { message } = result || {}
    if (message) return setError(message)

    getRefundRequests()
    setShowConfirmationModal(false)
    setShowDecisionModal(false)
  }

  const nonRecommendedActionHandler = () => {
    setShowConfirmationModal(true)
  }

  if (isLoading) return <LoadingSpinner />

  return (
    <Modal show={show}>
      <ModalHeader>
        <p>Refund request</p>
        <img
          data-testid='img-close'
          src={CloseIcon}
          alt='Close Exit Modal'
          onClick={() => setShowDecisionModal(false)}
        />
      </ModalHeader>
      <ModalSeparator />
      <ModalContent>
        {error && <RequestDetail color='var(--error-color)'>{error}</RequestDetail>}
        <div>
          <p>Name: {studentName}</p>
          <p>Email: {studentEmail}</p>
          <p>Request received: {requestReceivedDateString}</p>
          <p>Purchase: {certificateName}</p>
          <p>Refund total: {refundAmount}</p>
        </div>
        <div>
          <RequestDetail {...(withinRefundWindow ? {} : { color: 'var(--error-color)' })}>
            <img
              src={withinRefundWindow ? approveIcon : denyIcon}
              alt='Within Refund Window'
            />
            Within refund window ({latestRefundDateString})
          </RequestDetail>
          <RequestDetail {...(withinDropDeadline ? {} : { color: 'var(--error-color)' })}>
            <img
              src={withinDropDeadline ? approveIcon : denyIcon}
              alt='Within Drop Deadline'
            />
            Within drop deadline ({earliestDropDateString})
          </RequestDetail>
        </div>
        <RequestDetail {...(canApprove ? {} : { decisionColor: 'var(--error-color)' })}>
          Recommended:
          <span>
            <strong>{canApprove ? ' Approve' : ' Deny'}</strong>
          </span>
        </RequestDetail>
        <ButtonsContainer>
          <PrimaryButton
            data-testid='btn-recommended'
            onClick={() => {
              recommendedActionHandler(
                canApprove ? DECISION_APPROVED : DECISION_DENIED
              )
            }}
          >
            {canApprove ? 'approve' : 'deny'}
          </PrimaryButton>
          <SecondaryButton
            data-testid='btn-not-recommended'
            onClick={nonRecommendedActionHandler}
          >
            {canApprove ? 'deny' : 'approve'}
          </SecondaryButton>
        </ButtonsContainer>
      </ModalContent>
      {showConfirmationModal && (
        <DecisionConfirmationModal
          show={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          recommendedActionHandler={recommendedActionHandler}
          denyRequest={canApprove}
        />
      )}
    </Modal>
  )
}

DecisionModal.displayName = 'DecisionModal'

DecisionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowDecisionModal: PropTypes.func,
  getRefundRequests: PropTypes.func,
  request: PropTypes.object
}

export default DecisionModal

import React, { useEffect, useState } from 'react'
import { components } from 'react-select'
import api from '../../../api'
import { PRACTICE_EXAM } from '../../../Constants/examTypes'
import { useCoursesActions, useCoursesContext } from '../../../contexts/Courses'
import { addExamsInCourse } from '../utils'
import {
  SubHeading,
  FiltersContainer,
  ResetFilterButton,
  FilterLabel,
  CustomOptionContainer,
  CheckedBox,
  CustomCheckBox,
  filterSelectStyles,
  cohortSelectStyles
} from '../../WritingGradeCenter/styled'
import {
  CustomSelect,
  ExamFilterWrapper,
  ExamSelectFiltersContainer,
  SubHeadingMarginBottom
} from '../styled'
import { courseSelectStyles } from '../../global.styled'
import { getSortedCourses } from '../../../utilities/gradeReportUtils'

export const fetchCourseDatasPromise = async (
  courses,
  isExamGradeBook = true
) => {
  const promises = courses.map(async course => {
    const courseData = await api.getCourseData(course.id)
    const courseHasNoChapters = !courseData || !courseData.chapters ||
      !courseData.chapters.length

    if (courseHasNoChapters) return course

    const courseUUID = course.id
    const examChapters = courseData.chapters
      .filter(chapter => chapter.type === 'exam')

    course.exams = addExamsInCourse({
      examChapters,
      courseUUID,
      isExamGradeBook
    })

    return course
  })

  return Promise.all(promises)
}

const addCohortsToCourses = async courses => {
  const allCohorts = await api.getAllCohorts()

  const coursesWithCohorts = courses.map(course => {
    const { at_id: courseAirtabaleId } = course
    const cohorts = allCohorts.filter(cohort => {
      if (!cohort.course) return false
      return cohort.course.includes(courseAirtabaleId)
    }).sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart))

    return { ...course, cohorts }
  })

  return coursesWithCohorts
}

const Filters = props => {
  const {
    currentCohorts,
    setCurrentCohorts,
    currentExams,
    setCurrentExams,
    currentCourse,
    setCurrentCourse
  } = props

  const [isLoading, setIsLoading] = useState(true)
  const [coursesWithExams, setCoursesWithExams] = useState([])
  const [defaultExams, setDefaultExams] = useState([])
  const [defaultCohorts, setDefaultCohorts] = useState([])

  const { allCourses } = useCoursesContext()
  const { fetchCourses } = useCoursesActions()

  useEffect(() => {
    if (!allCourses || !allCourses.length) {
      fetchCourses()
      return
    }

    getCoursesWithExams()
    // eslint-disable-next-line
  }, [allCourses])

  useEffect(() => {
    initExamsAndCohorts()
    // eslint-disable-next-line
  }, [defaultExams, defaultCohorts])

  const getCoursesWithExams = async () => {
    const courses = await addCohortsToCourses(allCourses)
    const coursesExamData = await fetchCourseDatasPromise(courses)
    const sortedCoursesWithExam = getSortedCourses(coursesExamData)

    const coursesWithExams = sortedCoursesWithExam.filter(
      course => course.exams && course.exams.length
    )

    if (!coursesWithExams) return

    setCoursesWithExams(
      coursesWithExams.map(course => {
        return {
          ...course,
          exams: [
            { label: 'Practice Exam', value: 'ckxh4deha00063f63mpvo1cce' },
            ...course.exams
          ],
          label: course.name,
          value: course.id
        }
      })
    )

    setIsLoading(false)
  }

  // custom option for react-select
  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <CustomOptionContainer>
          <span>{children}</span>
          <CheckedBox type='checkbox' defaultChecked={props.isSelected} />
          <CustomCheckBox className='check-mark' />
        </CustomOptionContainer>
      </components.Option>
    )
  }

  // custom value container for react-select
  const ValueContainer = props => {
    const { getValue, hasValue, children, options } = props

    const newChildren = [...children]
    const selectedValuesCount = getValue().length
    const singleSelectedValue = getValue()[0]
    const allSelected = selectedValuesCount === options.length

    const getChildren = () => {
      if (!hasValue) {
        return newChildren
      } else if (selectedValuesCount === 1) {
        newChildren[0] = singleSelectedValue.label
      } else {
        newChildren[0] = `${allSelected ? 'All' : selectedValuesCount} Selected`
      }
      return newChildren
    }

    return (
      <components.ValueContainer {...props}>
        {getChildren()}
      </components.ValueContainer>
    )
  }

  const handleCourseSelectChange = course => {
    setCurrentCourse(course)

    setDefaultExams(course.exams)
    setDefaultCourseCohorts(course)
  }

  const initExamsAndCohorts = () => {
    const filteredExams = defaultExams.filter(
      exam => exam.label !== PRACTICE_EXAM)
    setCurrentExams(filteredExams)
    setCurrentCohorts([])
  }

  const handleResetFilter = () => {
    initExamsAndCohorts()
  }

  const setDefaultCourseCohorts = course => {
    const { cohorts } = course

    if (!cohorts) return

    const cohortsForSelect = cohorts.map(cohort => {
      const { name } = cohort
      return {
        ...cohort,
        label: name,
        value: name
      }
    })

    setDefaultCohorts(cohortsForSelect)
  }

  return (
    <>
      <SubHeadingMarginBottom hasCourse={currentCourse}>
        {currentCourse
          ? 'Course'
          : 'Select a course to view proctored exams available for reviewing.'
        }
      </SubHeadingMarginBottom>
      <CustomSelect
        options={coursesWithExams}
        value={currentCourse}
        isLoading={isLoading}
        onChange={selectedCourse => {
          handleCourseSelectChange(selectedCourse)
        }}
        placeholder='Select Course'
        styles={courseSelectStyles}
        classNamePrefix='select'
      />
      {currentCourse && (
        <>
          <FiltersContainer>
            <SubHeading hasCourse={currentCourse}>Filters</SubHeading>
            <ResetFilterButton onClick={handleResetFilter}>
              reset filters
            </ResetFilterButton>
          </FiltersContainer>
          <ExamSelectFiltersContainer>
            <ExamFilterWrapper>
              <FilterLabel>Exam</FilterLabel>
              <CustomSelect
                options={defaultExams}
                value={currentExams}
                isLoading={isLoading}
                onChange={selectedExams =>
                  setCurrentExams(selectedExams)
                }
                components={{ Option, ValueContainer }}
                placeholder='Filter By Exam'
                styles={filterSelectStyles}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isSearchable={false}
                isClearable={false}
                isMulti
                classNamePrefix='select'
              />
            </ExamFilterWrapper>
            <ExamFilterWrapper>
              <FilterLabel>Cohort</FilterLabel>
              <CustomSelect
                options={defaultCohorts}
                value={currentCohorts}
                isSearchable
                onChange={cohorts => {
                  setCurrentCohorts(cohorts || [])
                }}
                components={{ Option, ValueContainer }}
                placeholder='Filter By Cohort'
                styles={cohortSelectStyles}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isClearable={false}
                isMulti
                classNamePrefix='select'
              />
            </ExamFilterWrapper>
          </ExamSelectFiltersContainer>
          </>
      )}
    </>
  )
}

export default Filters

import styled, { keyframes } from 'styled-components'

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
    position: absolute;
    top: 63px;
    left: 0;
    background-color: var(--canvas-primary);
    height: calc(100% - 64px);
    width: 100%;
    & > p {
        margin: 0;
        color: #FFF;
        font-size: 18px;
        font-weight: 300;
        line-height: 24px;
    }
`
const indeterminateAnimation = keyframes`
0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
`

export const ProgressContainer = styled.div`
  width: 250px;
  
  & > .enroll-progress-bar {
    border-radius: 6px;
    height: 6px;
    background-color: var(--border-secondary);
    width: 100%;
    overflow: hidden;

    & > .enroll-progress-bar-value {
        width: 100%;
        height: 100%;
        background-color: var(--sub-text-secondary);
        animation: ${indeterminateAnimation} 1s infinite linear;
        transform-origin: 0 50%;
      }
  }

`

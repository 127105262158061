import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { BroadcastChannel } from 'broadcast-channel'
import InformationCard from '../InformationCard/InformationCard'
import EditStudentStatusModal from './EditStudentStatusModal'
import { defaultFormFields, DROPS } from '../../utilities/studentStatusUtils'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import api from '../../api'
import AlertModal from '../AlertModal/AlertModal'
import cuid from 'cuid'
import { USER_STATUS_UPDATE } from '../../Constants/broadcastChannels'

const broadCast = new BroadcastChannel(USER_STATUS_UPDATE)
export default function StudentStatus ({
  studentStatus,
  studentEmail,
  cohortId,
  preferredName,
  selectedStudentCohort,
  courseName
}) {
  const [showEditStudentStatus, setShowEditStudentStatus] = useState(false)
  const [tokens, setTokens] = useState([])
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showAlertModal, setShowAlertModal] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [formFields, setFormFields] = useState(defaultFormFields)
  const [isUpdating, setIsUpdating] = useState(false)
  const history = useHistory()
  const [
    currentStudentStatus,
    setCurrentStudentStatus
  ] = useState(studentStatus)

  const { updatedStatus: newStatus } = formFields
  const queueId = useRef(null)
  const queueItems = useRef([])
  const updateRequestMade = useRef(false)

  useEffect(() => {
    const fetchStudentTokens = async () => {
      try {
        const tokens = await api.getStudentTokens(studentEmail) || []
        if (!tokens.length) return

        setTokens(tokens)
      } catch (error) {
        console.error('Error: ', error)
      }
    }

    fetchStudentTokens()
  }, [studentEmail])

  const handleQueueUpdate = (event) => {
    queueItems.current = event.detail || []
    const currentProcess = queueItems.current[0]
    const activeProcessId = currentProcess?.id
    if (queueId.current === activeProcessId) {
      const currentFormData = currentProcess.data
      queueId.current = null
      handleSubmit(currentFormData)
    }
  }

  useEffect(() => {
    broadCast.addEventListener('message', handleQueueUpdate)

    return () => {
      broadCast.removeEventListener('message', handleQueueUpdate)
    }
    // eslint-disable-next-line
  }, [])

  const clearFields = () => {
    setFormFields(defaultFormFields)
  }

  const handleNextClick = formInputs => {
    setFormFields(formInputs)
    setShowEditStudentStatus(false)
    setShowConfirmationModal(true)
  }

  const handleGoBackClick = () => {
    setShowConfirmationModal(false)
    setShowEditStudentStatus(true)
  }

  const handleConfirmClick = () => {
    setIsUpdating(true)
    const { updatedStatus, statusNote, note, zendeskUrl } = formFields
    const formFieldsData = {
      from: currentStudentStatus,
      to: updatedStatus.value,
      statusNote: statusNote.value,
      note,
      zendeskUrl
    }
    const uuid = cuid()
    const queueItem = {
      id: uuid,
      data: formFieldsData
    }
    queueId.current = uuid
    if (Array.isArray(queueItems.current)) {
      queueItems.current.push(queueItem)
    } else {
      queueItems.current = [{ ...queueItem }]
    }
    handleQueueUpdate({ detail: queueItems.current })
    broadCast.postMessage({ detail: queueItems.current })

    setTimeout(() => {
      if (updateRequestMade.current) return
      showAlert('This is taking too long. Please close all the open tabs and try again.')
    }, 120000)
  }

  const showAlert = (message) => {
    setAlertMessage(message)
    setIsUpdating(false)
    setShowConfirmationModal(false)
    clearFields()
    setShowAlertModal(true)
  }

  // when the newly chosen status(newStatus.value) is drop related
  const isStudentDropped = DROPS.includes(newStatus.value)

  const handleRedirect = () => {
    // when the updated status(currentStudentStatus) is drop related
    const isStudentNowDropped = DROPS.includes(currentStudentStatus)
    if (isStudentNowDropped) {
      history.push({ pathname: '/student-grade-report' })
    }
    // reload page
    history.go(0)
  }

  const handleCloseAlertModal = () => {
    setShowAlertModal(false)
    handleRedirect()
  }

  const handleSubmit = async updateData => {
    try {
      updateRequestMade.current = true
      const { success, message } = await api.putStudentStatus(
        studentEmail, cohortId, updateData
      )

      if (success) {
        setCurrentStudentStatus(newStatus.value)
        showAlert('Student status successfully updated!')
      } else {
        showAlert(message)
      }
    } catch (error) {
      showAlert(error.message)
    } finally {
      queueItems.current.shift()
      broadCast.postMessage({ detail: queueItems.current })
    }
  }

  const handleConfirmationModalClose = value => {
    setShowConfirmationModal(value)
    clearFields()
  }

  const handleEditStudentStatusModalClose = () => {
    clearFields()
    setShowEditStudentStatus(false)
  }

  return (
    <>
      <InformationCard
        title='Student Status'
        subTitle={currentStudentStatus}
        buttonDetails={{
          text: 'edit',
          handler: () => setShowEditStudentStatus(true)
        }}
      />
      {showEditStudentStatus && (
        <EditStudentStatusModal
          show={showEditStudentStatus}
          handleClose={handleEditStudentStatusModalClose}
          studentStatus={currentStudentStatus}
          handleNextClick={handleNextClick}
          formFields={formFields}
          selectedStudentCohort={selectedStudentCohort}
          tokens={tokens}
          setFormFields={setFormFields}
        />
      )}
      {showAlertModal && (
        <AlertModal
          show={showAlertModal}
          children={alertMessage}
          showModalHandler={setShowAlertModal}
          confirm={{
            text: 'ok',
            handler: () => handleCloseAlertModal()
          }}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          show={showConfirmationModal}
          showModalHandler={handleConfirmationModalClose}
          confirm={{
            text: isUpdating
              ? <i className='fa fa-spinner fa-spin' />
              : 'confirm',
            handler: () => handleConfirmClick()
          }}
          cancel={{
            text: 'go back',
            handler: () => handleGoBackClick()
          }}
          children={
            <p>
              Are you sure you want to change {preferredName}’s {courseName} status from <b>{currentStudentStatus}</b> to <b>{newStatus.value}</b>? {isStudentDropped && ' This will remove the student from this cohort and cannot be undone.'}
            </p>
          }
        />
      )}
    </>
  )
}

StudentStatus.propTypes = {
  preferredName: PropTypes.string.isRequired,
  studentEmail: PropTypes.string.isRequired,
  cohortId: PropTypes.string.isRequired,
  courseName: PropTypes.string.isRequired,
  studentStatus: PropTypes.string.isRequired
}

import styled from 'styled-components'
import { Button } from 'reactstrap'

export const Table = styled.table`
  border-collapse: separate;
  flex: 1;
`

export const VoucherListContainer = styled.div`
  display: flex;
  padding: 1em 0;
`

export const Header = styled.th`
  padding-right: 15px;
  text-decoration: underline;
  ${({ label }) =>
    ['drop', 'action'].includes(label) && 'visibility: hidden;'}
`

export const Cell = styled.td`
  padding: 15px 0;
  padding-right 10px;
  padding-left: ${props => props.isLoading && '65px'};
  width: ${props => props.width || 250}px;
`

export const CustomButton = styled(Button)`
  ${(bottomMargin) => bottomMargin && 'margin-bottom: 10px;'}
  &.btn-custom.btn-primary {
    background-color: #5FC4B8 !important;
    font-size: ${({ fontSize }) => fontSize || '14px'} !important;
    line-height: 17px !important;
  }
  &:active {
    background-color: #161618 !important;
  }
`

export const LoaderContainer = styled.div`
  height: 60vh;
`

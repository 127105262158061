import styled from 'styled-components'

export const InformationCardWrapper = styled.div`
  width: 290px;
  height: 104px;
  background: var(--black-secondary);
  border-radius: 5px;
  margin-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
`
export const InformationCardTitleWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  align-items: center;
`
export const InformationCardTitle = styled.span`
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
  color: var(--text-primary);
`
export const InformationCardButton = styled.span`
  cursor: pointer;
  font-size: 14px;
  color: var(--brand-regular);
  margin-left: 12px;
  font-weight: bold;
  line-height: 17px;
  text-transform: uppercase;
  margin-top: 2px;
`
export const InformationCardSubTitle = styled(InformationCardTitle)`
  font-weight: 300;
`

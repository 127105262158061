export const PAGES = {
  main: 'main-page',
  ELTUpload: 'ELT-upload-page',
  ELTArchive: 'ELT-archive-page',
  PC_AdmissionsUpload: 'PC-admissions-upload-page',
  PC_ELTUpload: 'PC-ELT-upload-page'
}

export const ELTFileHeaders = [
  'guild_external_id', 'employer_name', 'partner_program_id', 'first_name',
  'last_name', 'date_of_birth', 'email', 'phone_number', 'address', 'city',
  'state', 'zip', 'academic_period_identifier', 'term_eligibility_status',
  'term_ineligibility_reason_code', 'term_eligibility_locked_date',
  'term_eligibility_change', 'financial_status', 'funding_max', 'funding_remaining'
]

export const ProfCertELTFileHeaders = [
  'guild_external_id',
  'partner_student_id',
  'first_name',
  'last_name',
  'term_code',
  'term_eligibility_status',
  'term_ineligibility_reason_code',
  'term_eligibility_locked_date',
  'term_eligibility_change',
  'financial_status',
  'funding_max',
  'funding_remaining'
]

export const ProfCertAdmissionsFileHeaders = [
  'guild_external_id',
  'partner_student_id',
  'expected_start_term',
  'admissions_status',
  'partner_program_name',
  'partner_program_id',
  'status',
  'employer_name',
  'first_name',
  'last_name',
  'date_of_birth',
  'email',
  'phone_number',
  'address',
  'city',
  'state',
  'zip',
  'admissions_flag_reason',
  'created_at',
  'updated_at'
]

export const GUILD_CSV_TYPES = {
  PATHWAYS_ELT: 'pathways-elt',
  PROF_CERTS_ADMISSIONS: 'prof-certs-admissions',
  PROF_CERTS_ELT: 'prof-certs-elt'
}

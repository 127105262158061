import {
  convertEasternTimeToLocalTime,
  getDateAfterWeeks, getDateString, getDateStringWithoutYear
} from './dateTimeUtil'
import moment from 'moment'

export const getPartnerOptions = () => {
  const options = {
    Yes: true,
    No: false
  }
  return getSelectorOptions(options)
}

const enrollmentOptionsObject = {
  UPittStandard: 'UPitt Standard',
  GGUDegreesPlusStandard: 'GGU Degrees+ Standard',
  UPittIntensive: 'UPitt Intensive',
  UPittExtended: 'UPitt Extended'
}

const createOption = (name, value, condition) => {
  return {
    name,
    value,
    disabled: condition
  }
}

export const getDurationOptions = (enrollmentOptions) => {
  const isEnrollmentOptions = enrollmentOptions?.length > 0
  return [
    createOption('14wk Standard', '14wk Standard', isEnrollmentOptions &&
      (!enrollmentOptions.includes(enrollmentOptionsObject.UPittStandard) && !enrollmentOptions.includes(enrollmentOptionsObject.GGUDegreesPlusStandard))
    ),
    createOption('7wk Intensive', '7wk Intensive', isEnrollmentOptions && (
      !enrollmentOptions.includes(enrollmentOptionsObject.UPittIntensive))),
    createOption('39wk Extended', '39wk Extended', isEnrollmentOptions && (
      !enrollmentOptions.includes(enrollmentOptionsObject.UPittExtended)))
  ]
}

export const getDurationOptionsByDate = (date, selectedCourse) => {
  const { cohorts } = selectedCourse
  const selectedDateWithoutTime = moment(date).startOf('day')

  const durationsArray = Array.isArray(cohorts) ? cohorts.reduce((acc, cohort) => {
    const cohortStartDate = moment(cohort.dateStart).startOf('day')
    if (selectedDateWithoutTime.isSame(cohortStartDate, 'day')) {
      acc.push(cohort.duration)
    }
    return acc
  }, []) : []

  const is14wkStandardDisabled = !durationsArray.includes(14) && !durationsArray.includes(15)
  const is7wkIntensiveDisabled = !durationsArray.includes(7) && !durationsArray.includes(8)
  const is39wkExtendedDisabled = !durationsArray.includes(39)
  return [
    createOption('14wk Standard', '14wk Standard', is14wkStandardDisabled),
    createOption('7wk Intensive', '7wk Intensive', is7wkIntensiveDisabled),
    createOption('39wk Extended', '39wk Extended', is39wkExtendedDisabled)
  ]
}

export const getIsAuditOptions = () => {
  const options = {
    'Yes, audit this course': true,
    'No, take for credit': false
  }
  return getSelectorOptions(options)
}
export const getSelectorOptions = options => {
  return Object.keys(options).map(name => {
    return {
      name,
      value: options[name]
    }
  })
}

export const getCohortDates = (selectedCourse, selectedStartDate, duration) => {
  if (!selectedCourse || !selectedStartDate || !duration) return ''
  const allCohorts = selectedCourse?.cohorts || []
  const cohort = allCohorts?.find(cohort => {
    const startDateLocal = convertEasternTimeToLocalTime(
      cohort?.dateStart,
      'T06:00:00'
    )
    // use 'day' because the datePicker sometimes modifies outputs
    return moment(startDateLocal).isSame(selectedStartDate, 'day')
  })
  const { dateStart } = cohort || {}

  const weeks = parseFloat(duration?.match(/\d+/)[0]) || 0
  const datEnd = getDateAfterWeeks(dateStart, weeks)

  const dateStartString = getDateString(dateStart, 'MMM D')
  const dateEndString = getDateStringWithoutYear(datEnd, 'MMM D')
  const dateStartYearString = getDateString(dateStart, 'YYYY')
  const dateEndYearString = getDateString(datEnd, 'YYYY')
  if (!dateStartString || !dateEndString) return ''

  const isSameYear = dateStartYearString === dateEndYearString
  if (isSameYear) {
    return `${dateStartString} - ${dateEndString}, ${dateEndYearString}`
  } else {
    return `${dateStartString}, ${dateStartYearString} - ${dateEndString}, ${dateEndYearString}`
  }
}

import React, { useEffect, useState } from 'react'
import {
  CloseOverlay,
  Container,
  NavBarItem,
  Overlay, Separator,
  Title, Tool, ToolName,
  ToolIcon,
  Tools
} from './styles'
import CloseIcon from '../../assets/icons/modal-close.svg'
import { NavLink } from 'reactstrap'
import { Link } from 'react-router-dom'
import chevronRight from '../../assets/icons/chevron-right.svg'
import api from '../../api'
import { hasAccessToLink } from '../../utilities/userUtils'
import { cards, PARTNERSHIPS_MANAGER_CARDS } from '../../utilities/DashboardCards'
import { PARTNERSHIPS_MANAGER } from '../../Constants/roles'

function JumpToTool () {
  const [isVisible, setIsVisible] = useState(false)
  const [permissions, setPermissions] = useState([])
  const [roles, setRoles] = useState([])

  useEffect(() => {
    let isMounted = true

    const checkPermissions = async () => {
      try {
        const { permissions, roles } = await api.getCurrentUserPermissions()
        if (!isMounted) return
        setPermissions(permissions)
        setRoles(roles)
      } catch (err) {
        console.error(err)
      }
    }

    checkPermissions()

    return () => {
      isMounted = false
    }
  }, [])

  const isPermissionsManagerRole = roles?.length > 0 &&
    roles?.every(item => item === PARTNERSHIPS_MANAGER)
  const cardsList = isPermissionsManagerRole
    ? PARTNERSHIPS_MANAGER_CARDS
    : cards
  const sortedCards = cardsList.sort((current, next) => {
    current = current.name.toLowerCase().split(' ').join('')
    next = next.name.toLowerCase().split(' ').join('')
    return current > next ? 1 : -1
  })

  return (
    <>
      <NavBarItem
        data-testid={'jumpToToolBtn'}
        onClick={() => setIsVisible(true)}>
                JUMP TO TOOL
      </NavBarItem>
      {isVisible && <Overlay>
        <Container>
          <div className='d-flex align-items-center'>
            <Title>Jump to tool</Title>
            <CloseOverlay
              className='ml-auto'
              onClick={() => setIsVisible(false)}
              src={CloseIcon} alt='close icon'
            />
          </div>
          <Separator />
          <Tools className='d-flex flex-wrap'>
            {sortedCards.map((card, index) => {
              return hasAccessToLink(card, permissions, roles) && (
                <div key={index}
                  className='col-12 col-sm-6 col-md-4'>
                  <Tool id='toolsList'>
                    <NavLink className='d-inline-block'
                      tag={Link}
                      to={card.to}
                      style={{ padding: 0 }}>
                      <ToolIcon src={card.icon} alt={card.name} />
                      <ToolName>
                        {card.name}
                        <img src={chevronRight} alt='chevron-right' />
                      </ToolName>
                    </NavLink>
                  </Tool>
                </div>
              )
            })}
          </Tools>
        </Container>
      </Overlay>}
    </>
  )
}

export default JumpToTool

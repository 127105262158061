import {
  ADMINISTRATIVE_DROP,
  AUDIT,
  AUDIT_TRANSCRIPT,
  COMPLETED,
  ENROLLED,
  PRE_DROP,
  UNOFFICIAL_WITHDRAW,
  WITHDRAW
} from '../../../Constants/studentStatus'
import { cloneDeep, uniqWith } from 'lodash'
import {
  AUDIT_STATUSES,
  CHART_AUDIT,
  CHART_COMPLETED, CHART_EXITED,
  CHART_IN_PROGRESS, CHART_MANUAL_DROPS, CHART_NEEDS_REVIEW,
  CHART_WITHDRAW,
  EXITED_STATUSES,
  IN_PROGRESS_STATUSES,
  MANUAL_DROPS_STATUSES,
  NEEDS_REVIEWING_STATUSES, PIE_DATA
} from '../../../Constants/attemptsChart'
import isEqual from 'lodash/isEqual'

export const getCertificateStatus = (certificate) => {
  const { courses } = certificate
  const requiredCourses = courses
    .filter((course) => course.courseType === 'required')

  const areAllRequiredCourseRefunded =
      requiredCourses.every((course) => course.cohort.pmtStatus === 'Refunded')
  if (areAllRequiredCourseRefunded) {
    return { ...certificate, status: 'Refunded' }
  }

  const isAnyRequiredCourseRefunded =
      requiredCourses.some((course) => course.cohort.pmtStatus === 'Refunded')
  if (isAnyRequiredCourseRefunded) {
    return { ...certificate, status: 'Partially Refunded' }
  }

  const areAllRequiredCoursesCompleted = requiredCourses
    .every((course) => course.cohort.studentStatus === COMPLETED)
  if (areAllRequiredCoursesCompleted) {
    return { ...certificate, status: COMPLETED }
  }
  return { ...certificate, status: 'In progress' }
}

export const getInProgressCohortsAndRelationships = (attempts) => {
  const inProgressStatuses = [
    ENROLLED,
    WITHDRAW,
    AUDIT,
    AUDIT_TRANSCRIPT,
    PRE_DROP
  ]
  const inProgressCohorts = []
  const relationships = []
  attempts.forEach(attempt => {
    const {
      fields: {
        cohort: {
          fields: { name: cohortName } = {}
        } = {},
        studentStatus: {
          fields: { Name: studentStatus } = {}
        } = {},
        relationshipName
      }
    } = attempt
    if (inProgressStatuses.includes(studentStatus)) {
      inProgressCohorts.push({ cohortName })
      if (relationshipName) {
        relationships.push({ relationshipName })
      }
    }
  })
  const uniqueRelationships = uniqWith(relationships, isEqual)

  return { inProgressCohorts, relationships: uniqueRelationships }
}

export const getStatusText = (status) => {
  switch (status) {
    case ADMINISTRATIVE_DROP:
      return 'Admin Drop'
    case UNOFFICIAL_WITHDRAW:
      return 'Unofficial W'
    default: return status
  }
}

export const updateCategoryCount = (pieData, categoryName, studentStatus) => {
  const categoryIndex = pieData.findIndex(
    category => category.name === categoryName
  )
  if (categoryIndex !== -1) {
    const category = pieData[categoryIndex]
    category.count++

    const statusIndex = category.statuses.findIndex(
      status => status.name === studentStatus
    )
    if (statusIndex !== -1) {
      category.statuses[statusIndex].count++
    } else {
      category.statuses.push({ name: studentStatus, count: 1 })
    }
  }
}

export const getPieData = (attempts) => {
  const pieData = cloneDeep(PIE_DATA)

  attempts.forEach((attempt) => {
    const {
      fields: {
        studentStatus: {
          fields: { Name: studentStatus } = {}
        }
      }
    } = attempt

    switch (studentStatus) {
      case IN_PROGRESS_STATUSES[0]:
      case IN_PROGRESS_STATUSES[1]:
      case IN_PROGRESS_STATUSES[2]:
        updateCategoryCount(pieData, CHART_IN_PROGRESS, studentStatus)
        break
      case AUDIT_STATUSES[0]:
      case AUDIT_STATUSES[1]:
        updateCategoryCount(pieData, CHART_AUDIT, studentStatus)
        break
      case COMPLETED:
        updateCategoryCount(pieData, CHART_COMPLETED, studentStatus)
        break
      case WITHDRAW:
        updateCategoryCount(pieData, CHART_WITHDRAW, studentStatus)
        break
      case MANUAL_DROPS_STATUSES[0]:
      case MANUAL_DROPS_STATUSES[1]:
      case MANUAL_DROPS_STATUSES[2]:
      case MANUAL_DROPS_STATUSES[3]:
      case MANUAL_DROPS_STATUSES[4]:
        updateCategoryCount(pieData, CHART_MANUAL_DROPS, studentStatus)
        break
      case EXITED_STATUSES[0]:
      case EXITED_STATUSES[1]:
        updateCategoryCount(pieData, CHART_EXITED, studentStatus)
        break
      case NEEDS_REVIEWING_STATUSES[0]:
      case NEEDS_REVIEWING_STATUSES[1]:
        updateCategoryCount(pieData, CHART_NEEDS_REVIEW, studentStatus)
        break
      // no default
    }
  })
  const attemptsCount = attempts.length
  const updatedPieData = pieData.map(category => {
    const value = parseFloat((category.count / attemptsCount * 100).toFixed(2))
    return { ...category, value }
  })

  return updatedPieData
}

export const getTokensCountByStatus = (tokensData) => {
  const statusCounts = [
    { statusDisplayName: 'Pending', status: 'pending', count: 0 },
    { statusDisplayName: 'Used', status: 'used', count: 0 },
    { statusDisplayName: 'Available', status: 'available', count: 0 },
    { statusDisplayName: 'Refunded', status: 'refunded', count: 0 },
    { statusDisplayName: 'Expired', status: 'expired', count: 0 },
    { statusDisplayName: 'Pending Refund', status: 'pendingRefund', count: 0 },
    { statusDisplayName: 'Void', status: 'Void', count: 0 }
  ]

  tokensData.forEach((token) => {
    const status = token?.usedStatus
    const index = statusCounts
      .findIndex((category) => category.status === status)
    if (index !== -1) {
      statusCounts[index].count++
    } else {
      const availableIndex = statusCounts
        .findIndex((category) => category.status === 'available')
      statusCounts[availableIndex].count++
    }
  })

  return statusCounts
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { EXPOSE_KEYS } from '../../../Constants/CSVDownloadToolV2'
import {
  areMainFieldsSelected,
  hasArrayValidItem
} from '../../../utilities/CSVDownloadToolV2'
import { CustomButton } from '../../CSVDownloadTool/style'

const NextButton = props => {
  const history = useHistory()
  const {
    criteria
  } = props

  const handleNextButton = () => {
    history.push({
      pathname: '/csv-download/exported-information',
      state: {
        allowView: true
      }
    })
  }

  const shouldEnableNext = () => {
    const studentStatus = criteria[EXPOSE_KEYS.STUDENT_STATUS]
    const mainFieldsSelected = areMainFieldsSelected(criteria)

    return mainFieldsSelected && hasArrayValidItem(studentStatus)
  }

  const disableNext = !shouldEnableNext()

  return (
    <CustomButton
      id='csv-next-button'
      className='btn-custom btn-primary'
      onClick={handleNextButton}
      disabled={disableNext}
    >
      Next Page
    </CustomButton>
  )
}

NextButton.propTypes = {
  criteria: PropTypes.object
}

NextButton.displayName = 'NextButton'

export default NextButton

import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import {
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import api from '../../api'
import './reset-student-progress.css'
import { getCoursesWithCourseId } from '../../utilities/courseUtils'
import { additionalCourseIdToName } from '../../config'

function ResetStudentProgress () {
  const [isResetSuccess, setIsResetSuccess] = useState(false)
  const [modal, setModal] = useState(false)
  const [studentIdentifier, setStudentIdentifier] = useState(null)
  const [error, setError] = useState(null)
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [courses, setCourses] = useState([])
  const mountedRef = useRef(false)

  useEffect(() => {
    mountedRef.current = true
    fetchCourses()
    return () => {
      mountedRef.current = false
    }
  }, [])

  const fetchCourses = async () => {
    const courses = await api.getCourses()
    if (mountedRef.current) {
      const filteredCourses = getCoursesWithCourseId(courses)
      setCourses(filteredCourses.map((course) =>
        ({ value: course.id, label: course.name })))
    }
  }

  const toggle = () => setModal(!modal)

  const submit = async () => {
    const additionalIds = additionalCourseIdToName(selectedCourse.value)
    let resetResponse = await Promise.all(additionalIds.map(id => api.resetStudentProgress(
      id,
      studentIdentifier
    )))
    resetResponse = resetResponse.flat()
    if (resetResponse[0].error) {
      setError(resetResponse.message)
      return
    }
    setIsResetSuccess(true)

    // Removes the success message after 4.5 seconds
    setTimeout(() => setIsResetSuccess(false), 4500)

    toggle()
  }

  return (
    <div className='reset-student-progress-wrapper'>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Reset Student Progress</ModalHeader>
        <ModalBody>Are you sure you want reset the student progress?</ModalBody>
        {error && <p className='error-text'>{error}</p>}
        <ModalFooter>
          <Button color='primary' onClick={submit}>
            Yes
          </Button>
          <Button color='secondary' onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div>
        <FormGroup>
          <Label for='student-identifier'>Reset Student Progress</Label>
          <Input
            onChange={e => setStudentIdentifier(e.target.value)}
            type='text'
            name='email'
            id='student-identifier'
            placeholder='Enter student ID or email'
          />
        </FormGroup>
        <FormGroup>
          <Select
            className='select-button'
            options={courses}
            value={selectedCourse}
            onChange={(currentCourse) => setSelectedCourse(currentCourse)}
            placeholder='Choose a course'
          />
        </FormGroup>
        <Button onClick={toggle} disabled={!studentIdentifier || !selectedCourse}
          className='btn-primary'>
          Reset Progress
        </Button>
        {isResetSuccess && <p className='text-success mt-2 font-weight-bold'>Progress has been reset successfully !</p>}
      </div>
    </div>
  )
}

export default ResetStudentProgress

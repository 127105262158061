import React from 'react'
import {
  GradingCheckTitle,
  GradingIssueRow,
  GradingIssueRowText,
  GradingIssuesHeaderWrapper,
  GradingIssuesWrapper
} from './style'
import ellipseIcon from '../../assets/icons/ellipse3.svg'
import chevronDownIcon from '../../assets/icons/chevron-down2.svg'

const GradingIssues = ({ coursesWithGradingIssues }) => {
  return (
    <GradingIssuesWrapper>
      <GradingIssuesHeaderWrapper>
        <GradingCheckTitle>Grading Issues</GradingCheckTitle>
        <img src={chevronDownIcon} alt='chevron-icon' />
      </GradingIssuesHeaderWrapper>
      {coursesWithGradingIssues.map((course, index) => {
        return (
          <GradingIssueRow key={index}>
            <img src={ellipseIcon} alt='ellipse-icon' />
            <GradingIssueRowText>{course.courseTabName}</GradingIssueRowText>
          </GradingIssueRow>
        )
      })
      }
    </GradingIssuesWrapper>
  )
}

export default GradingIssues
GradingIssues.displayName = 'GradingIssues'

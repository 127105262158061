import React, { useState } from 'react'
import {
  RejectedTableContainer,
  ShowRejectedTableButton,
  Table,
  TableContainer
} from './styles'
import { ReactComponent as ChevronDown } from '../../../assets/icons/chevron-down.svg'
import { tables } from './constants'

function RejectedViolationsTable ({ uniqueDenied }) {
  const [showRejectedTable, setShowRejectedTable] = useState(false)

  return (
    <RejectedTableContainer>
      <ShowRejectedTableButton
        onClick={() => setShowRejectedTable((state) => !state)}
        showRejectedTable={showRejectedTable}
      >
        {showRejectedTable ? 'Hide' : 'Show'} Rejected flags & reports
        <ChevronDown />
      </ShowRejectedTableButton>
      {showRejectedTable && (
        <TableContainer>
          <Table style={{ minWidth: '1200px' }}>
            <thead>
              <tr>
                {tables[1].headers.map((header) => (
                  <th>{header.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {uniqueDenied.map((record) => (
                <tr>
                  {tables[1].headers.map((header) => (
                    <td>{header.accessor(record)}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}
    </RejectedTableContainer>
  )
}

export default RejectedViolationsTable

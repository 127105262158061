import React from 'react'
import { generatePaginationNumbers } from './utils'
import {
  PaginationWrapper,
  PaginationButtonsWrapper,
  PaginationButton,
  PaginationText
} from './styled'

const TablePaginator = ({
  currentPage,
  setCurrentPage,
  rows,
  rowsPerPage
}) => {
  const onPaginationButtonClick = (page) => {
    if (page === 'Previous') return setCurrentPage((prev) => prev - 1)
    if (page === '...') return
    if (page === 'Next') return setCurrentPage((prev) => prev + 1)
    return setCurrentPage(parseInt(page))
  }

  const getPaginationText = () => {
    if (!rows?.length) return '0 items'
    const start = (currentPage - 1) * rowsPerPage + 1
    const end = currentPage * rowsPerPage > rows?.length
      ? rows?.length
      : currentPage * rowsPerPage

    return `${start} - ${end} of ${rows?.length} items`
  }

  return (
    <PaginationWrapper>
      <PaginationButtonsWrapper
        data-testid='pagination-buttons-wrapper'
      >
        {generatePaginationNumbers(rows?.length, currentPage, rowsPerPage)
          .map((page, i) => (
            <PaginationButton
              key={page + i}
              type={page}
              onClick={() => onPaginationButtonClick(page)}
              isSelected={currentPage === parseInt(page)}
            >
              {page === 'Next' || page === 'Previous' ? null : page}
            </PaginationButton>
          ))}
      </PaginationButtonsWrapper>
      <PaginationText
        data-testid='pagination-text'
      >
        {getPaginationText()}
      </PaginationText>
    </PaginationWrapper>
  )
}

TablePaginator.displayName = 'TablePaginator'
export default TablePaginator

import styled from 'styled-components'

export const FilterWrapper = styled.div`
  margin-right: 12px;
`
export const AddStudent = styled.div`
margin-top: 12px;
`
export const FiltersContainer = styled.div`
margin-top: ${props => props.isExamRetakeFilter && '12px'};
  display: flex;
  width: 100%;
`

export const FilterLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-transform: ${props => props.isFirstLabel ? 'none' : 'capitalize'};
  color: ${props => props.isFirstLabel ? '#ffffff' : '#b1bfc5'};
  margin-bottom:  ${props => props.isFirstLabel ? '20px' : '10px'};
  margin-top: ${props => props.isFirstLabel && '36px'};
`

export const MainWrapper = styled.div`
  padding:24px;
  margin-left: 12px;
`

export const TableWrapper = styled.div`
  margin-top: 24px;
`

export const DateRangeWrapper = styled.div`
  display: flex;
  margin-top: 12px;
`

export const DateWrapper = styled.div`
  color: #b1bfc5 !important;
`

export const ToSpan = styled.span`
  margin-right: 10px;
  margin-left: 10px;
  margin-top: 40px;
`

export const Button = styled.button`
  height: 49px;
  border-radius: 5px !important;
  width: 157px;
  margin-top: ${props => props.isEnableButton ? '16px' : '40px'};
  background-color: #5fc4b8;
  cursor: pointer;
  border: none;
  font-family: Lato;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 17px;
`

export const lowPriorityDataTables = [
  'drop',
  'newDrop',
  'withdraw',
  'newWithdraw',
  'audit',
  'exception'
]

export const highPriorityDataTables = [
  'aiViolations',
  'assignmentList',
  'assignmentType',
  'assignments',
  'attempts',
  'audienceConsent',
  'certificates',
  'cohortMilestones',
  'cohortSchedules',
  'cohortSchedulesExt',
  'cohortSchedulesIntensive',
  'cohorts',
  'courses',
  'degrees',
  'examExtensions',
  'examRetakes',
  'gguAdvisorApprovalRequired',
  'gguAppCompRec',
  'gguBusAdminRec',
  'gguLiberalStudiesRec',
  'gguSemesters',
  'instructors',
  'paymentStatus',
  'pretestAttempts',
  'pretestOverrides',
  'prospects',
  'purchases',
  'relationships',
  'specialDays',
  'statusNote',
  'studentGuardians',
  'studentStatus',
  'students',
  'tokens',
  'tuitionVoucher',
  'underageProspectsData',
  'voucher',
  'outlierCourseLicense'
]

const dataTables = [
  ...highPriorityDataTables,
  ...lowPriorityDataTables
]

export default dataTables

import React, { useEffect, useState } from 'react'
import Filters from './Filters'
import Select from 'react-select'
import api from '../../api'
import CreatableSelect from 'react-select/creatable'
import { CheckBox } from '../StudentGradeReport/styles'
import {
  RETAKE_WINDOW_START,
  PARTNER_REQUEST,
  RETAKE_WINDOW_END,
  RETAKE_REASON,
  COURSE_NAMES_WITH_RETAKES_AVAILABLE,
  EXAM_KEYS,
  EXAM_LOCK_STATUS
} from '../../Constants/retakes'
import { Modal, ModalBody, Label, Input } from 'reactstrap'
import {
  MainWrapper,
  TableWrapper,
  DateRangeWrapper,
  DateWrapper,
  ToSpan,
  FilterLabel,
  AddStudent,
  Button
} from './styled'
import {
  useStudentGradeActions,
  useStudentGradeContext
} from '../../contexts/StudentGradeContext'
import { fixTimezoneAndFormat } from '../../utilities/dateTimeUtil'
import { getDefaultCourse } from '../../utilities/courseUtils'
import {
  fetchCourseDatasPromise
} from '../../Components/ExamGradeBook/Filters/index'
import {
  getSortedCourses
} from '../../utilities/gradeReportUtils'
import Table from '../../Components/Table/Table'
import { dropDownStyles } from '../global.styled'
const headerData = [
  { Header: 'name',
    accessor: 'name',
    prop: 'name',
    sortType: 'alphanumeric' },
  { Header: 'email',
    accessor: 'email',
    prop: 'email',
    sortType: 'alphanumeric' },
  { Header: 'retake window start',
    accessor: 'retake window start',
    prop: 'retake window start',
    sortType: 'alphanumeric' },
  { Header: 'retake window end',
    accessor: 'retake window end',
    prop: 'retake window end',
    sortType: 'alphanumeric' },
  { Header: 'partner request',
    accessor: 'partner request',
    prop: 'partner request',
    sortType: 'alphanumeric' },
  { Header: 'retake reason',
    accessor: 'retake reason',
    prop: 'retake reason',
    sortType: 'alphanumeric' }
]

const reasons = [
  {
    label: 'Academic Integrity',
    value: 'Academic Integrity'
  },
  {
    label: 'Student Illness',
    value: 'Student Illness'
  },
  {
    label: 'Technical Issue',
    value: 'Technical Issue'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]

export const putStudentData = async ({
  data,
  setStudentData,
  setSuccessModal,
  setIsLoading
}, lockExamRequestData) => {
  try {
    const { email, courseId, examUUID } = lockExamRequestData
    const response = await api.putExamRetake(data)
    if (!response) return

    await lockRetakeExam(email, courseId, examUUID)

    const { cohort } = response?.fields ?? response
    if (!cohort) return

    const { cohortId } = data
    setSuccessModal(true)
    setTimeout(() => setSuccessModal(false), 4500)
    getExamRetakeStudents(cohortId, setStudentData, setIsLoading)
  } catch (error) {
    setIsLoading(false)
    console.error('Error:', error)
  }
}

export const lockRetakeExam = async (email, courseId, examUUID) => {
  const sectionData = { [examUUID]: true }
  await api.setStudentSectionProgress(EXAM_LOCK_STATUS, email, courseId, sectionData)
}

export const getStudentsWithCurrentExam = (studentData, currentExam) => {
  const { label } = currentExam
  let filteredData = filterByExamDeadline(studentData, label)

  filteredData = filteredData.map((student) => {
    const {
      student: cohortStudent,
      retakeRequestReason,
      partnerRequest
    } = student
    const { name, email } = cohortStudent
    const retakeWindow = getExamRetakeWindowData(label, student)
    return {
      name,
      email,
      [PARTNER_REQUEST]: partnerRequest && partnerRequest.toString(),
      [RETAKE_WINDOW_START]: retakeWindow.start,
      [RETAKE_WINDOW_END]: retakeWindow.end,
      [RETAKE_REASON]: retakeRequestReason
    }
  })

  return filteredData
}

export const getExamRetakeStudents = async (
  cohortId,
  setStudentData,
  setIsLoading
) => {
  try {
    const data = await api.getExamRetakes(cohortId)
    setStudentData(data)
    setIsLoading(false)
  } catch (error) {
    setIsLoading(false)
    console.error('Error:', error)
  }
}

// Returns students who have a retake deadline for the selected exam.
const filterByExamDeadline = (studentData, label) => {
  return studentData.filter(student => {
    const deadline = EXAM_KEYS[label]?.end
    return deadline ? student[deadline] : null
  })
}

const getExamRetakeWindowData = (label, student) => {
  const keys = EXAM_KEYS[label]
  const retakeWindow = { start: student[keys.start], end: student[keys.end] }
  return retakeWindow
}

const ExamRetakes = () => {
  const { fetchCourses } = useStudentGradeActions()
  const {
    courses
  } = useStudentGradeContext()
  const [currentCourses, setCurrentCourses] = useState([])
  const [coursesWithExams, setCoursesWithExams] = useState([])
  const [studentData, setStudentData] = useState([])
  const [studentsWithCurrentExam, setStudentsWithCurrentExam] = useState([])
  const [addStudent, setAddStudent] = useState(false)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [isCohortEndRetake, setIsCohortEndRetake] = useState(false)
  const [isPartnerRequest, setIsPartnerRequest] = useState(false)
  const [reason, setReason] = useState()
  const [selectedCourse, setSelectedCourse] = useState('')
  const [cohortData, setCohortData] = useState([])
  const [courseExams, setCourseExams] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [currentCohort, setCurrentCohort] = useState('')
  const [currentExam, setCurrentExam] = useState('')
  const [students, setStudents] = useState([])
  const [currentStudent, setCurrentStudent] = useState('')
  const [isRetakeDisabled, setIsRetakeDisabled] = useState(true)
  const [successModal, setSuccessModal] = useState(false)
  const isAllfields = currentStudent && currentCohort &&
   currentExam && reason && fromDate && toDate
  const { id: cohortId, finalExamEndTime } = currentCohort

  useEffect(() => {
    getExamRetakeStudents(cohortId, setStudentData, setIsLoading)
    // eslint-disable-next-line
  }, [currentCohort])

  useEffect(() => {
    fetchCourses()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getCoursesWithExams()
    // eslint-disable-next-line
  }, [courses.length])

  useEffect(() => {
    if (!coursesWithExams?.length) return
    const defaultCourseName = COURSE_NAMES_WITH_RETAKES_AVAILABLE
    const sortedCourses = getSortedCourses(coursesWithExams)
    const defaultCourses = defaultCourseName
      .map(courseName => getDefaultCourse(sortedCourses, courseName))
    setCurrentCourses(defaultCourses)
    // eslint-disable-next-line
  }, [coursesWithExams])

  useEffect(() => {
    setIsRetakeDisabled(!isAllfields)
    if (!currentExam || !studentData.length) {
      setStudentsWithCurrentExam([])
      return
    }

    const filteredData =
    getStudentsWithCurrentExam(studentData, currentExam)
    setStudentsWithCurrentExam(filteredData)
    // eslint-disable-next-line
  }, [
    selectedCourse,
    currentStudent,
    currentCohort,
    currentExam,
    isCohortEndRetake,
    fromDate,
    toDate,
    reason,
    studentData])

  const getCoursesWithExams = async () => {
    const coursesExamData = await fetchCourseDatasPromise(courses, false)
    const coursesWithExams = coursesExamData.filter(
      course => course.exams && course.exams.length
    )

    setCoursesWithExams(coursesWithExams.map(course => {
      return {
        ...course,
        exams: [
          ...course.exams
        ],
        label: course.name,
        value: course.id
      }
    }))
  }

  const handleReasonChange = (reason) => {
    setReason(reason)
  }

  const resetStates = () => {
    setAddStudent(!addStudent)
    setCurrentStudent('')
    setSelectedCourse('')
    setCurrentCohort('')
    setCurrentExam('')
    setCohortData([])
    setCourseExams([])
    setFromDate(null)
    setToDate(null)
  }

  const cohortEndCheckboxHandler = () => {
    const formattedEndTime = fixTimezoneAndFormat(finalExamEndTime)
    formattedEndTime && setIsCohortEndRetake(!isCohortEndRetake)
    const date = !isCohortEndRetake && formattedEndTime.substr(0, 16)
    setToDate(date)
  }

  const handleEnableRetake = async () => {
    const { id: studentId, email } = currentStudent
    const { id: courseId } = selectedCourse
    const { label, value: examUUID } = currentExam
    const midTermStartKey = EXAM_KEYS[label].start
    const midTermEndKey = EXAM_KEYS[label].end

    const data = {
      studentId,
      cohortId,
      [midTermStartKey]: fromDate,
      [midTermEndKey]: toDate,
      retakeRequestReason: reason.value,
      partnerRequest: isPartnerRequest
    }
    const lockExamRequestData = { email, courseId, examUUID }
    setIsLoading(true)
    putStudentData({ data, setStudentData, setSuccessModal, setIsLoading }, lockExamRequestData)
    resetStates()
  }

  return (
    <MainWrapper>
      <div>
        <Modal isOpen={successModal}>
          <ModalBody>
            Student added successfully
          </ModalBody>
        </Modal>
      </div>
      <Filters
        currentCourses={currentCourses}
        setStudents={setStudents}
        setCurrentCohort={setCurrentCohort}
        currentCohort={currentCohort}
        currentExam={currentExam}
        setCurrentExam={setCurrentExam}
        cohortData={cohortData}
        setCohortData={setCohortData}
        courseExams={courseExams}
        setCourseExams={setCourseExams}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        setCurrentStudent={setCurrentStudent}
      />
      {!addStudent
        ? <>
          {!isLoading && <TableWrapper>
            <Table
              columns={headerData}
              data={studentsWithCurrentExam}
              isExamRetakes />
          </TableWrapper>}
          <Button
            data-testid='add-student-button'
            onClick={() => setAddStudent(true)}>
           Add Student
          </Button>
        </>
        : <AddStudent>
          <FilterLabel >Student</FilterLabel>
          <Select
            data-testid='student-dropdown'
            placeholder='Select Student'
            options={students}
            value={currentStudent}
            onChange={(e) => setCurrentStudent(e)}
            styles={dropDownStyles}
          />
          <DateRangeWrapper data-testid='custom-date-range'>
            <DateWrapper>
              <Label>Start Date</Label>
              <Input
                type='datetime-local'
                data-testid='custom-date-from'
                value={fromDate}
                onChange={date => setFromDate(date.target.value)}
              />
            </DateWrapper>
            <ToSpan>to</ToSpan>
            <DateWrapper>
              <Label>End Date</Label>
              <Input
                type='datetime-local'
                data-testid='custom-date-to'
                value={toDate}
                disabled={isCohortEndRetake}
                onChange={date => setToDate(date.target.value)}
              />
            </DateWrapper>
          </DateRangeWrapper>
          <CheckBox isExamRetakes>
            Use cohort end date
            <input
              data-testid='cohort-end-date-checkbox'
              type='checkbox'
              disabled={!finalExamEndTime}
              checked={isCohortEndRetake}
              onChange={cohortEndCheckboxHandler}
            />
            <span />
          </CheckBox>
          <FilterLabel
            isFirstLabel
          >
            (Required) Reason for granting retake
          </FilterLabel>
          <CreatableSelect
            data-testid='reason-dropdown'
            options={reasons}
            placeholder='Select Options'
            onChange={handleReasonChange}
            value={reason}
            styles={dropDownStyles}
          />
          <CheckBox isExamRetakes>
            Partner request
            <input
              data-testid='partner-request-checkbox'
              type='checkbox'
              checked={isPartnerRequest}
              onChange={() => setIsPartnerRequest(!isPartnerRequest)}
            />
            <span />
          </CheckBox>
          <Button
            data-testid='enable-retake-button'
            isEnableButton
            disabled={isRetakeDisabled}
            onClick={handleEnableRetake}
          >
            Enable Retake
          </Button>
        </AddStudent>}
    </MainWrapper>
  )
}

export default ExamRetakes

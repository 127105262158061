import React from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import { ModalButtonsContainer, ModalContent } from './styled'
import { PrimaryButton, SecondaryButton } from '../global.styled'

function MergeConfirmationModal (props) {
  const {
    primaryEmail,
    secondaryEmail,
    handleMergeAccounts,
    setShowConfirmationModal
  } = props

  return (
    <ModalComponent
      handleClose={() => setShowConfirmationModal(false)}
      title='Are you sure you want to merge accounts?'
      show
      width='455px'
    >
      <ModalContent>
        <div>
          When merging accounts, you also delete a student record from our database.
        </div>
        <div>
          Are you sure <b>{primaryEmail}</b> is the primary record? <b>{secondaryEmail}</b> will be deleted.
        </div>
      </ModalContent>
      <ModalButtonsContainer>
        <PrimaryButton
          onClick={handleMergeAccounts}
        >
          Merge
        </PrimaryButton>
        <SecondaryButton
          onClick={() => setShowConfirmationModal(false)}
        >
          Go Back
        </SecondaryButton>
      </ModalButtonsContainer>
    </ModalComponent>
  )
}

export default MergeConfirmationModal

import React from 'react'
import GoToPreviousPageButton from '../../GoToPreviousPageButton/GoToPreviousPageButton'
import {
  HeadingText,
  NotificationSeparator
} from '../styled'
import {
  Wrapper,
  Content
} from './styled'

const NotificationCreate = ({ routerProps }) => {
  const {
    history
  } = routerProps
  const handleGoBackClick = () => history.push('/notifications')

  return (
    <>
      <GoToPreviousPageButton
        text='Notifications'
        handleClick={handleGoBackClick}
      />
      <Wrapper>
        <Content>
          <HeadingText>Create notification</HeadingText>
          <NotificationSeparator />
        </Content>
      </Wrapper>
    </>
  )
}

export default NotificationCreate

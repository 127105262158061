import React from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { EditorWrapper } from './styles'

const CustomToolbar = () => (
  <div id='toolbar' className='ql-toolbar'>
    <span className='ql-formats'>
      <select
        className='ql-header'
        defaultValue={''}
        onChange={e => e.persist()}
      >
        <option value='1' />
        <option value='2' />
        <option selected value='' >Paragraph</option>
      </select>
    </span>
    <span className='ql-formats'>
      <button className='ql-bold' />
      <button className='ql-italic' />
      <button className='ql-link' />
      <button className='ql-list' value='ordered' />
      <button className='ql-list' value='bullet' />
    </span>
    <span className='ql-formats'>
      <button className='ql-indent' value='-1' />
      <button className='ql-indent' value='+1' />
    </span>
    <span className='ql-formats'>
      <button className='ql-blockquote' />
    </span>
  </div>
)

export default function RichTextEditor (props) {
  const { value, setValue, isDisabled } = props

  const modules = {
    toolbar: !isDisabled && { container: '#toolbar' }
  }

  return (
    <EditorWrapper disabled={isDisabled}>
      { !isDisabled && <CustomToolbar /> }
      <ReactQuill
        value={value}
        onChange={val => setValue(val)}
        theme='snow'
        modules={modules}
        readOnly={isDisabled}
      />
    </EditorWrapper>
  )
}

import React from 'react'
import PropTypes from 'prop-types'
import {
  MANUAL_CRITERIA_SELECTION
} from '../../../Constants/CSVDownloadToolOptions'
import CheckboxSelector from '../../CheckboxSelector'
import { StyledWrapper } from './styled'

const ManualCriteria = props => {
  const {
    values,
    setValue
  } = props

  return (
    <StyledWrapper>
      {
        MANUAL_CRITERIA_SELECTION.map(selection => {
          const {
            label,
            exposeKey,
            getOptions,
            options,
            type
          } = selection

          return <CheckboxSelector
            key={exposeKey}
            exposeKey={exposeKey}
            label={label}
            options={options}
            getOptions={getOptions}
            values={values}
            setValue={setValue}
            selection={selection}
            type={type}
          />
        })
      }
    </StyledWrapper>
  )
}

ManualCriteria.propTypes = {
  values: PropTypes.object,
  setValue: PropTypes.func.isRequired
}

ManualCriteria.displayName = 'ManualCriteria'

export default ManualCriteria

import styled from 'styled-components'

export const PageWrapper = styled.div`
  margin: 48px 24px 0 24px;
  display: flex;
  flex-direction: column;

  & * {
    --sb-track-color: transparent;
    --sb-thumb-color: #5b6067;
    --sb-size: 18px;

    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);

    &::-webkit-scrollbar {
      width: var(--sb-size);
      height: var(--sb-size);
    }

    &::-webkit-scrollbar-track {
      background: var(--sb-track-color);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--sb-thumb-color);
      border-radius: 10px;
      border: 5px solid var(--sb-track-color);
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #98a4ae;
      border-radius: 10px;
      border: 5px solid var(--sb-track-color);
      background-clip: content-box;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
`
export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const Separator = styled.hr`
  border: 0.5px solid var(--border-secondary);
  margin: ${({ space }) => space} 0;
`

import {
  RowWrapper,
  TokenDetailHeaderCell,
  TokenDetailRowCell,
  TokenDetailsHeaderWrapper,
  TokenDetailsRow,
  TokenDetailsWrapper,
  TokenRow,
  TokenRowCell
} from './style'
import React, { useState } from 'react'
import {
  getFormattedDateAndTime
} from '../../AssessmentExtensions/util/ExtensionRecordsUtils'
import rectanglePlusIcon from '../../../assets/icons/rectangle-plus.svg'
import rectangleMinusIcon from '../../../assets/icons/rectangle-minus.svg'
import { BREAKPOINTS } from '../../../mediaQueries'
import useWindowSize from '../../../Hooks/useWindowSize'
import { convertNumberToTwoPercision } from '../../../utilities/numbers'

const StudentTokenRowDetails = ({ token }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const {
    tokenNumber,
    usedStatus,
    lastRefundDate,
    returnTo,
    refundability,
    tokenIssueDate = null,
    tokenExpirationDate = null,
    purchases: [
      { fields: { orderNumber, paidAmount, giftCardUsed } = {} } = {}
    ],
    relationship: [{ fields: { relationshipName } = {} } = {}],
    certificate: { name: certificateName } = {},
    attemptCohort: { fields: { name: cohortName } = {} } = {}
  } = token
  const [tokenIssueDatePart, tokenIssueTimePart] = getFormattedDateAndTime(tokenIssueDate)
  const formattedTokenIssueDate = `${tokenIssueDatePart}   ${tokenIssueTimePart}`
  const [tokenExpirationDatePart, tokenExpirationTimePart] = getFormattedDateAndTime(tokenExpirationDate)
  const formattedExpirationDate = `${tokenExpirationDatePart}   ${tokenExpirationTimePart}`
  const icon = isExpanded ? rectangleMinusIcon : rectanglePlusIcon

  const returnToText = returnTo
    ? returnTo.replace(new RegExp('Return', 'gi'), '')
    : '--'
  const [windowWidth] = useWindowSize()
  const isTablet = windowWidth < BREAKPOINTS.desktop
  const isMobile = windowWidth < BREAKPOINTS.mobile
  const tabletOrMobileRow = () => {
    return (
      <>
        <TokenDetailsHeaderWrapper>
          {isMobile && <TokenDetailHeaderCell>purchase date</TokenDetailHeaderCell>}
          <TokenDetailHeaderCell>expiration date</TokenDetailHeaderCell>
          <TokenDetailHeaderCell>gift card used</TokenDetailHeaderCell>
        </TokenDetailsHeaderWrapper>
        <TokenDetailsRow
          data-testid='token-details-row'
        >
          {isMobile &&
            <TokenDetailRowCell>
              {formattedTokenIssueDate || '--'}
            </TokenDetailRowCell>
          }
          <TokenDetailRowCell>
            {formattedExpirationDate || '--'}
          </TokenDetailRowCell>
          <TokenDetailRowCell>
            {giftCardUsed || '--'}
          </TokenDetailRowCell>
        </TokenDetailsRow>
      </>
    )
  }

  return (
    <RowWrapper isExpanded={isExpanded}>
      <TokenRow key={token?.tokenNumber} onClick={() => setIsExpanded(!isExpanded)}>
        <TokenRowCell>{tokenNumber || '--'}</TokenRowCell>
        <TokenRowCell>
          {usedStatus || '--'}
        </TokenRowCell>
        <TokenRowCell>{orderNumber || '--'}</TokenRowCell>
        <TokenRowCell>{relationshipName || '--'}</TokenRowCell>
        <TokenRowCell>{certificateName || '--'}</TokenRowCell>
        <TokenRowCell>{lastRefundDate || '--'}</TokenRowCell>
        <TokenRowCell onClick={() => setIsExpanded(!isExpanded)}>
          <img
            src={icon}
            alt='rectangle-plus-icon'
          />
        </TokenRowCell>
      </TokenRow>
      { isExpanded && (
        <TokenDetailsWrapper>
          <TokenDetailsHeaderWrapper>
            <TokenDetailHeaderCell>used cohort</TokenDetailHeaderCell>
            <TokenDetailHeaderCell>paid amount</TokenDetailHeaderCell>
            <TokenDetailHeaderCell>return to</TokenDetailHeaderCell>
            <TokenDetailHeaderCell>refundability</TokenDetailHeaderCell>
            {!isMobile && <TokenDetailHeaderCell>purchase date</TokenDetailHeaderCell>}
            {!isTablet && <TokenDetailHeaderCell>expiration date</TokenDetailHeaderCell>}
            {!isTablet && <TokenDetailHeaderCell>gift card used</TokenDetailHeaderCell> }
          </TokenDetailsHeaderWrapper>
          <TokenDetailsRow
            data-testid='token-details-row'
          >
            <TokenDetailRowCell>
              {cohortName || '--'}
            </TokenDetailRowCell>
            <TokenDetailRowCell>${convertNumberToTwoPercision(paidAmount)}</TokenDetailRowCell>
            <TokenDetailRowCell>{returnToText}</TokenDetailRowCell>
            <TokenDetailRowCell className='zendesk-cell'>
              {refundability || '--'}
            </TokenDetailRowCell>
            {!isMobile &&
              <TokenDetailRowCell>
                {formattedTokenIssueDate || '--'}
              </TokenDetailRowCell>
            }
            {!isTablet &&
              <>
                <TokenDetailRowCell>
                  {formattedExpirationDate || '--'}
                </TokenDetailRowCell>
                <TokenDetailRowCell>
                  {giftCardUsed || '--'}
                </TokenDetailRowCell>
              </>
            }
          </TokenDetailsRow>
          {isTablet && tabletOrMobileRow()}
        </TokenDetailsWrapper>
      )}
    </RowWrapper>
  )
}
StudentTokenRowDetails.displayName = 'StudentTokenRowDetails'
export default StudentTokenRowDetails

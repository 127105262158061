import React from 'react'
import {
  StudentGoalQuestion,
  StudentGoalResponse,
  StudentGoalsRow,
  StudentGoalsContainer,
  StudentGoalsWrapper,
  StudentGoalsHeader,
  Separator,
  ToolTipContainer,
  ToolTip
} from './style'
import iconTooltip from '../../../assets/icons/icon-tooltip.svg'

const StudentGoals = ({ academicGoal, primaryGoal }) => {
  return (
    <StudentGoalsWrapper>
      <StudentGoalsHeader>
        Journey at a glance
      </StudentGoalsHeader>
      <Separator />
      <StudentGoalsContainer>
        <StudentGoalsRow>
          <StudentGoalQuestion>
            What is your primary goal for taking an Outlier course?
            <ToolTipContainer>
              <img
                src={iconTooltip}
                alt='icon-tooltip'
              />
              <ToolTip>
                From Online Learning Readiness form.
              </ToolTip>
            </ToolTipContainer>
          </StudentGoalQuestion>
          <StudentGoalResponse>{primaryGoal || 'N/A'}</StudentGoalResponse>
        </StudentGoalsRow>
        <StudentGoalsRow>
          <StudentGoalQuestion>
            What is your academic end goal?
            <ToolTipContainer>
              <img
                src={iconTooltip}
                alt='icon-tooltip'
              />
              <ToolTip style={{ height: 48 }}>
                From Degrees+ Application.
              </ToolTip>
            </ToolTipContainer>
          </StudentGoalQuestion>
          <StudentGoalResponse>{academicGoal || 'N/A'}</StudentGoalResponse>
        </StudentGoalsRow>
      </StudentGoalsContainer>
    </StudentGoalsWrapper>
  )
}
StudentGoals.displayName = 'StudentGoals'
export default StudentGoals

import styled, { css } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const StudentRecordDetailsWrapper = styled.div`
  display: flex; 
  flex-direction: column;
  padding: 24px 26px 0;
`
export const StudentInformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StudentRecordDetailsHeader = styled.div`
  display: flex;
  padding: 24px 0 32px;
  justify-content: space-between;
  align-items: center;
`
export const StudentRecordDetailsHeaderLeft = styled.p`
  font-size: 32px;
  color: var(--text-primary);
  font-weight: 300;
  margin: 0;
`
export const StudentRecordDetailsHeaderRight = styled.p`
  font-size: 16px;
  color: var(--text-dark-off);
  margin: 0;
`
export const Separator = styled.hr`
  width: 100%;
  height: 1px;
  margin-bottom: 24px;
  margin-top: 0;
  border-color: rgba(255, 255, 255, 0.3);
`
export const StudentDetails = styled.div`
  display: flex;
  gap: 24px;
`
export const StudentDetailsLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 12px;
  width: 400px;
`
export const StudentDetailsRightContainer = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 12px;
  width: 100%;
  justify-content: space-between;
  ${mediaqueries.desktop`
    flex-direction: column;
    justify-content: flex-start;
  `}
`
export const StudentDetailsRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 400px;
`
export const StudentDetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const RowLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: var(--sub-text-secondary);
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`
export const RowText = styled.div`
  font-size: 18px;
  font-weight: 300;
  color: var(--text-primary);
  line-height: 24px;
  overflow-wrap: break-word;
`

export const ClearCacheButton = styled.div`
  cursor: pointer;
  height: 32px;
  width: fit-content;
  border-radius: 5px;
  padding: 8px;
  background-color: var(--brand-dark);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--brand-regular);
`

export const StudentGoalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 48px;
`
export const StudentGoalsHeader = styled.span`
  font-size: 24px;
  font-weight: 300;
  color: var(--text-primary);
  line-height: 32px;
  padding-bottom: 16px;
`

export const StudentGoalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`
export const StudentGoalsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const StudentGoalQuestion = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-primary);
  line-height: 24px;
`
export const StudentGoalResponse = styled.span`
  font-size: 18px;
  font-weight: 300;
  color: var(--text-primary);
  line-height: 24px;
`
export const ToolTipContainer = styled.div`
  margin-left: 8px;
  position: relative;
  display: flex;
  &:hover {
    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`
export const ToolTip = styled.span`
  visibility: hidden;
  width: 255px;
  height: 72px;
  padding: 12px;
  position: absolute;
  left: 28px;
  line-height: 24px;
  color: #FFFFFF;
  text-transform: none;
  font-weight: normal;
  background-color: #25272B;
  top: 50%; 
  transform: translateY(-50%);
  letter-spacing: normal;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
`
export const StudentCertDegreeWrapper = styled.div`
  display: flex;
  gap: 24px;
  padding: 32px 0;
  ${mediaqueries.desktop`
    flex-direction: column;
  `}
`
export const StudentCertDegreeContainer = styled.div`
  min-width: 564px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 5px;
  border: 1px solid #5B6067;
`
export const StudentCertHeader = styled.div`
  display: flex;
  gap: 24px;
  align-items: baseline;
  color: var(--text-primary);
  font-weight: 300;
`
export const StudentCertDegreeNumber = styled.span`
  font-size: 64px;
`
export const StudentCertDegreeText = styled.span`
  font-size: 40px;
`
export const StudentCertDegreeRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`
export const StudentCertDegreeName = styled.span`
  font-size: 18px;
  background-color:  ${props => props.isCompleted ? 'unset' : '#232327'};
  padding: 8px 12px;
  border-radius: 5px;
  ${props => props.isCompleted && css`
    border: 1px solid #5B6067;
  `}
`
export const StudentCertStatus = styled.span`
  font-size: 16px;
  color: ${props => props.isCompleted ? '#969BFF' : 'var(--sub-text-secondary)'};
`
export const StudentDegreeStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${props => props.isCompleted ? '#969BFF' : 'var(--sub-text-secondary)'};
`
export const Ellipse = styled.div`
  width: 2px;
  height: 2px;
  border-radius: 50%/100%;
  background-color: #98A4AE;
`
export const NoCertDegree = styled.span`
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: var(--text-primary);
`
export const StudentAttemptsWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  padding: 24px;
  border-radius: 5px;
  border: 1px solid var(--border-secondary);
`
export const StudentAttemptsRecordsContainer = styled.div`
  width: 792px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
export const StudentAttemptsRecordsHeader = styled.div`
  display: flex;
  gap: 24px;
  align-items: baseline;
  font-weight: 300;
  color: var(--text-primary);
`
export const StudentAttemptsRecordsHeaderNumber = styled.span`
  font-size: 64px;
`
export const StudentAttemptsRecordsHeaderText = styled.span`
  font-size: 40px;
`
export const StudentAttemptsRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
export const StudentAttemptsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
`
export const StudentAttemptsRowHeader = styled.span`
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  color: var(--text-primary);
`

export const StudentItemsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`
export const StudentCohortRelationshipItem = styled.span`
  padding: 6px 12px;
  align-self: stretch;
  color: var(--text-secondary);
  font-size: 18px;
  border-radius: 5px;
  background: var(--gray-primary);
`
export const JumpToLink = styled.a`
  color: var(--brand-regular) !important;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
`
export const LinkIcon = styled.img`
  margin-left: 8px;
`
export const StudentAttemptsChartContainer = styled.div`
  width: 470px;
  height: 380px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  padding-top: 16px;
`
export const PieContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px 24px;
`
export const StudentTokensSummaryWrapper = styled.div`
  padding: 32px 0 48px;
`
export const StudentTokensSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 5px;
  border: 1px solid var(--border-secondary);
`

export const StudentTokensSummaryHeader = styled.div`
  display: flex;
  gap: 24px;
  align-items: baseline;
  font-weight: 300;
  color: var(--text-primary);
`
export const StudentTokensSummaryHeaderNumber = styled.span`
  font-size: 64px;
`
export const StudentTokensSummaryHeaderText = styled.span`
  font-size: 40px;
`
export const StudentTokens = styled.div`
  display: flex;
  gap: 36px;
  align-items: center;
  padding-bottom: 16px;
`
export const StudentTokensColumn = styled.div`
  width: 126px;
  height: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`
export const StudentTokensColumnNumber = styled.span`
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  color: var(--text-primary);
`
export const StudentTokensColumnText = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--sub-text-secondary);
`
export const StudentAttemptRecordsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 48px;
`
export const StudentAttemptRecordsHeader = styled.span`
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  color: var(--text-primary);
`

export const AttemptsTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(255, 255, 255, 0.30);
  padding: 16px 0 8px;
`
export const HeaderCell = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #B1BFC5;

  &:first-child {
    width: 172px;
  }

  &:nth-child(2) {
    width: 169px;
  }

  &:nth-child(3) {
    width: 184px;
  }

  &:nth-child(4) {
    width: 230px;
  }

  &:nth-child(5) {
    width: 160px;
  }

  &:nth-child(6) {
    width: 254px;
  }

  &:nth-child(7) {
    width: 24px;
  }
`
export const TableHeaderWrapper = styled.div`
  padding: 0 17px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const AttemptRowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const HeaderRow = styled.div`
  display: flex;
  gap: 16px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
`
export const RowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  ::before{
    width: ${props =>
    props.isExpanded ? '2px' : 0
};
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    background-color: #5FC4B8;
    top: 0;
  }
`
export const StudentRow = styled.div`
  display: flex;
  gap: 16px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding:0 17px 0 17px;
`
export const StudentCell = styled(HeaderCell)`
  word-break: break-word;
  min-height: 70px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  color: #CCD4D8;
  text-transform: none;
  
  & img {
    cursor: pointer;
  }

  &:first-child {
    width: 172px;
  }

  &:nth-child(2) {
    width: 169px;
    gap: 8px;
    color: #fff;
  }

  &:nth-child(3) {
    width: 184px;
    color: #fff;
  }

  &:nth-child(4) {
    width: 230px;
  }

  &:nth-child(5) {
    width: 160px;
  }

  &:nth-child(6) {
    width: 254px;
  } 
  
  &:nth-child(7) {
    justify-content: end;
  }
`
export const ShowAllButton = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #5FC4B8;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
`
export const ChevronIcon = styled.img`
  transition: transform 0.3s ease;
  transform: ${props => props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'}
`

export const TokenRow = styled(StudentRow)`
  cursor: pointer;
  &:hover{
    background-color: #1D1D1F;
  }
`
export const TokenHeaderCell = styled(HeaderCell)`

  &:first-child {
    width: 294px;
  }

  &:nth-child(2) {
    width: 145px;
  }

  &:nth-child(3) {
    width: 99px;
  }

  &:nth-child(4) {
    width: 223px;
  }

  &:nth-child(5) {
    width: 175px;
  }

  &:nth-child(6) {
    width: 325px;
  }

  &:nth-child(7) {
    width: 24px;
  }
`
export const TokenRecordsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 16px;
`
export const TokensHeader = styled.span`
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  color: var(--text-primary);
`

export const TokensTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(255, 255, 255, 0.30);
  padding: 16px 0 8px;
`
export const TokenRowCell = styled(StudentCell)`
  min-height: 80px;
  text-transform: Capitalize;
  
  &:first-child {
    width: 294px;
  }

  &:nth-child(2) {
    width: 145px;
  }

  &:nth-child(3) {
    width: 99px;
  }

  &:nth-child(4) {
    width: 223px;
  }

  &:nth-child(5) {
    width: 175px;
  }

  &:nth-child(6) {
    width: 325px;
  }

  &:nth-child(7) {
    width: 24px;
  }
`

export const TokenDetailHeaderCell = styled(HeaderCell)`
  width: 132px;
  color: #89969F;
  
  &:first-child {
    width: 178px;
  }

  &:nth-child(2) {
    width: 140px;
  }

  &:nth-child(3) {
    width: 109px;
  }

  &:nth-child(4) {
    width: 152px;
  } 
  
  &:nth-child(5) {
    width: 161px;
  } 
  
  &:nth-child(6) {
    width: 161px;
  }  
  
  &:nth-child(7) {
    width: 161px;
  }
`

export const TokenDetailRowCell = styled(StudentCell)`
  min-height: 48px;
  width: 132px;
  color: #FFFFFF;
  text-transform: Capitalize;

  &:first-child {
    width: 178px;
  }

  &:nth-child(2) {
    width: 140px;
    color: #CCD4D8;
  }

  &:nth-child(3) {
    width: 109px;
    font-weight: 300;
    font-size: 16px;
  }

  &:nth-child(4) {
    width: 152px;
    font-weight: 300;
    font-size: 16px;
  }

  &:nth-child(5) {
    width: 161px;
    color: #CCD4D8;
    text-transform: none;
    white-space: pre;
  }

  &:nth-child(6) {
    width: 161px;
    text-transform: none;
    white-space: pre;
  }

  &:nth-child(7) {
    width: 161px;
    justify-content: initial;
    color: #CCD4D8;
  }
`
export const TokenDetailsWrapper = styled.div`
  padding:0 0 12px 24px;
`
export const TokenDetailsHeaderWrapper = styled.div`
  display: flex;
  gap: 24px;
  flex-flow: row nowrap;
  align-items: center;
  padding: 26px 0 13px 0;
  cursor: auto;
`

export const TokenDetailsRow = styled.div`
  display: flex;
  gap: 24px;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
`

export const NoRecords = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`

export const ToastTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

import React from 'react'
import { Editor, NoPreview, PreviewFrame } from './style'
import DownloadLink from './DownloadLink'
import { NO_ASSIGNMENT } from '../../Constants'

const AssignmentPreview = ({ content, title }) => {
  const { url, fileType, text, name: fileName } = content
  const fileTypeWithoutPreview = !text &&
    fileType !== 'application/pdf' && fileType !== 'text/plain'
  return text === NO_ASSIGNMENT
    ? <Editor value={text} disabled />
    : (
      <>
        {text && <Editor value={text} disabled /> }
        {fileType === 'application/pdf' &&
        <PreviewFrame
          src={`${url}#toolbar=0&navpanes=0`}
          title={title} />}
        {fileTypeWithoutPreview &&
          <NoPreview>
            <p>We currently can’t preview this file type, please download
              the file below to view the student’s submission.</p>
            <DownloadLink
              fileName={fileName || title.split(' ').join('')}
              label={`${fileName || 'Download File'}`}
              link={url} />
          </NoPreview>}
      </>
    )
}

AssignmentPreview.displayName = 'AssignmentPreview'

export default AssignmentPreview

import React, { useEffect, useRef, useState } from 'react'
import ModalComponent from '../../Components/ModalComponent/ModalComponent'
import api from '../../api'
import {
  ActionButton, DropdownMenuItem, ModalContent,
  UserAction,
  UserInfo,
  UserList,
  UserListItem,
  DropdownMenu, EmailText
} from './styles/index.styled'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash-icon.svg'
import { ReactComponent as PenLight } from '../../assets/icons/pen-light.svg'
import {
  ReactComponent as ControlsIcon
} from '../../assets/icons/controls-icon.svg'
import {
  ReactComponent as ChevronRight
} from '../../assets/icons/chevron-right.svg'
import { Separator } from '../../styles'
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner'

const RoleUsersModal = ({ selectedRole, setSelectedRole, setUserToRemove, setSelectedUser }) => {
  const [users, setUsers] = useState([])
  const [menuOpen, setMenuOpen] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const menuRef = useRef(null)

  useEffect(() => {
    const fetchUsersByRole = async (role) => {
      setIsLoading(true)
      const { data } = await api.getUsersByRole({ role })
      setUsers(data)
      setIsLoading(false)
    }

    fetchUsersByRole(selectedRole)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(null)
      }
    }

    if (menuOpen !== null) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuOpen])

  const toggleMenu = (email) => {
    setMenuOpen(menuOpen === email ? null : email)
  }

  const handleClickedRemoveUser = (user) => {
    setUserToRemove(user)
    setMenuOpen(null)
    setSelectedRole(null)
  }

  const handleClickEditPermissions = (user) => {
    setSelectedUser(user)
    setMenuOpen(null)
  }

  return <ModalComponent
    isResponsive
    width={'400px'}
    title={selectedRole.charAt(0).toUpperCase() + selectedRole.slice(1)}
    show={!!selectedRole}
    handleClose={() => setSelectedRole(null)}
  >
    <ModalContent isLoading>
      {isLoading
        ? <LoadingSpinner />
        : <UserList>
          {users.map((user, index) => (
            <UserListItem key={index}>
              <UserInfo>
                <div>{user.firstName} {user.lastName}</div>
                <EmailText>{user.email}</EmailText>
              </UserInfo>
              <UserAction>
                <ActionButton
                  className={menuOpen === user.email ? 'active' : ''}
                  onClick={() => toggleMenu(user.email)}>
                  <ControlsIcon />
                </ActionButton>
                {menuOpen === user.email && (
                  <DropdownMenu ref={menuRef}
                    onClick={() => handleClickEditPermissions(user)}
                  >
                    <DropdownMenuItem>

                      <PenLight />
                      Edit permissions
                      <ChevronRight style={{ marginLeft: 'auto' }} />
                    </DropdownMenuItem>
                    <Separator />
                    <DropdownMenuItem
                      onClick={() => handleClickedRemoveUser(user)}>
                      <TrashIcon />
                      Remove user
                    </DropdownMenuItem>
                  </DropdownMenu>
                )}
              </UserAction>
            </UserListItem>
          ))}
        </UserList>
      }
    </ModalContent>
  </ModalComponent>
}

export default RoleUsersModal

import React from 'react'
import { Page, Text, Image, Document, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#161618',
    color: '#FFF',
    marginBottom: 'auto',
    padding: '10px'
  },
  topic: {
    marginTop: '10px',
    fontSize: '20px'
  },
  text: {
    fontSize: '14px',
    paddingLeft: '10px'
  }
})

const PDFDocument = ({ activeLearning }) => {
  const { title: sectionTitle, Question } = activeLearning

  const renderSection = (question) => {
    const {
      Question_uuid: questionUUID,
      title: cardTitle,
      lesson_text: lessonText,
      question_text: questionText,
      illustration,
      options,
      answer,
      text_when_stuck: textWhenStuck,
      text_when_correct: textWhenCorrect,
      text_when_incorrect: textWhenIncorrect
    } = question
    return (
      <Page key={questionUUID} size='A4' style={styles.page} orientation='landscape'>
        <>
          <Text style={styles.topic}>Section</Text>
          <Text style={styles.text}>{sectionTitle}</Text>
        </>
        <>
          <Text style={styles.topic}>Card</Text>
          <Text style={styles.text}>{cardTitle}</Text>
        </>
        {lessonText &&
        <>
          <Text style={styles.topic}>Lesson</Text>
          <Text style={styles.text}>{lessonText.split('>').join('> ')}</Text>
        </>
        }
        {illustration &&
        (illustration.endsWith('jpg') || illustration.endsWith('jpeg') || illustration.endsWith('png')) &&
        <>
          <Text style={styles.topic}>Image</Text>
          <Image style={{ maxHeight: 400, maxWidth: 400 }} src={illustration} />
        </>
        }
        {questionText &&
        <>
          <Text style={styles.topic}>Question</Text>
          <Text style={styles.text}>{questionText.split('>').join('> ')}</Text>
        </>
        }
        {questionText && options && options.length > 0 &&
        <>
          <Text style={styles.topic}>Options</Text>
          {options.map((option, index) =>
            <Text key={option} style={styles.text}>{index + 1}. {option}</Text>
          )}
        </>
        }
        {questionText && answer && answer.length > 0 &&
        <>
          <Text style={styles.topic}>Answer</Text>
          {answer.map(ans => <Text key={ans} style={styles.text}>{ans}</Text>)}
        </>
        }
        {questionText && textWhenStuck &&
        <>
          <Text style={styles.topic}>Feedback When Stuck</Text>
          <Text style={styles.text}>{textWhenStuck}</Text>
        </>
        }
        {questionText && textWhenCorrect &&
        <>
          <Text style={styles.topic}>Feedback When Correct</Text>
          <Text style={styles.text}>{textWhenCorrect}</Text>
        </>
        }
        {questionText && textWhenIncorrect && textWhenIncorrect.length > 0 &&
        <>
          <Text style={styles.topic}>Feedback When Incorrect</Text>
          {textWhenIncorrect.map((option, index) =>
            <Text key={option} style={styles.text}>{index + 1}. {option}</Text>
          )}
        </>
        }
      </Page>
    )
  }
  return (
    <Document>
      {Question.map(question => renderSection(question))}
    </Document>
  )
}

export default PDFDocument

import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Field, ReorderButton, HideUnhideButton } from './styles'
import { ReactComponent as GripIcon } from '../../../assets/icons/grip.svg'

import { ReactComponent as CrossedEyeIcon } from '../../../assets/icons/crossed-eye.svg'
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye.svg'

function SortableItem ({
  children,
  id,
  isColumnHidden,
  isSearching,
  hideUnhideColumn
}) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id })
  const style = {
    opacity: isDragging ? 0.7 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
    border: isDragging ? '1px dashed var(--border-secondary)' : '',
    borderRadius: isDragging ? '5px' : ''
  }

  return (
    <Field
      data-testid={isColumnHidden ? 'hidden-field' : 'visible-field'}
      columnHidden={isColumnHidden}
      ref={setNodeRef}
      style={style}
    >
      {!isSearching && (
        <ReorderButton {...attributes} {...listeners} ref={setActivatorNodeRef}>
          <GripIcon />
        </ReorderButton>
      )}
      <span>{children}</span>
      <HideUnhideButton
        onClick={() => hideUnhideColumn(id, isColumnHidden)}
        data-testid={isColumnHidden ? 'unhide-field' : 'hide-field'}
      >
        {isColumnHidden ? <EyeIcon /> : <CrossedEyeIcon />}
      </HideUnhideButton>
    </Field>
  )
}

SortableItem.displayName = 'SortableItem'
export default SortableItem

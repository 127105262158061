import React, { useState, useEffect } from 'react'
import {
  EmailIDMultiTextLabel,
  ActiveDateRangeFromLabel,
  ResetButton,
  EmailIDMultiTextContainer,
  DateRangeFilterContainer,
  DatePickerSearchFromWrapper,
  FilterModalDatePickerWrapper,
  ControlLabelWrapper,
  DatePickerSearch
} from '../StudentGradeReport/styles'
import MultiTextInput from '../StudentGradeReport/MultiTextInput'
import { SubText } from './styles'

const StudentSearchForm = ({ handleChange, students, startDate, endDate }) => {
  const [email, setEmail] = useState([])

  useEffect(() => {
    handleChange('students', email)
    // eslint-disable-next-line
  }, [email])

  return (
    <>
      <EmailIDMultiTextContainer marginTop='22px' marginBottom='22px'>
        <EmailIDMultiTextLabel>Student ID or Email</EmailIDMultiTextLabel>
        <ResetButton onClick={() => handleChange('students', [])}>
          CLEAR
        </ResetButton>
        <MultiTextInput
          emailFilteredStudents={students}
          setEmailFilteredStudents={setEmail}
        />
      </EmailIDMultiTextContainer>
      <DateRangeFilterContainer margin='-1px'>
        <ActiveDateRangeFromLabel>
          Last Active Date Range
        </ActiveDateRangeFromLabel>
        <ResetButton
          onClick={() => {
            handleChange('startDate', '')
            handleChange('endDate', '')
          }}
        >
          CLEAR
        </ResetButton>
        <ControlLabelWrapper>
          <DatePickerSearchFromWrapper>
            <DatePickerSearch
              data-testid='date-field'
              className='date'
              autoComplete='off'
              placeholderText='Start Date'
              selected={startDate ? new Date(startDate) : startDate}
              onChange={date => handleChange('startDate', date)}
            />
          </DatePickerSearchFromWrapper>
        </ControlLabelWrapper>
        <SubText marginBottom='12px'>to</SubText>
        <FilterModalDatePickerWrapper disabled>
          <DatePickerSearch
            data-testid='date-field'
            className='date'
            autoComplete='off'
            minDate={startDate}
            placeholderText='End Date'
            selected={endDate ? new Date(endDate) : endDate}
            onChange={date => handleChange('endDate', date)}
            disabled={!startDate}
          />
        </FilterModalDatePickerWrapper>
      </DateRangeFilterContainer>
    </>
  )
}

export default StudentSearchForm

import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useHistory } from 'react-router-dom'
import Header from '../Header/Header'
import routes from '../../routes/routes'
import api from '../../api'
import { hasAccessToLink } from '../../utilities/userUtils'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { Content, LayoutHeader } from './style'
import useWindowSize from '../../Hooks/useWindowSize'
import { BREAKPOINTS } from '../../mediaQueries'
import SmallDeviceWarning from '../SmallDeviceWarning/SmallDeviceWarning'

function Layout () {
  const [loading, setLoading] = useState(true)
  const [permissions, setPermissions] = useState([])
  const [roles, setRoles] = useState([])
  const [windowWidth] = useWindowSize()
  const isTablet = windowWidth < BREAKPOINTS.tablet
  const history = useHistory()

  const isDBViewer = history.location.pathname.includes('database-viewer')
  const isPartnerLicense = history.location.pathname.includes('add-course-licenses')

  useEffect(() => {
    checkPermissions()
  }, [])

  const checkPermissions = async () => {
    try {
      const { permissions, roles } = await api.getCurrentUserPermissions()
      setPermissions(permissions)
      setRoles(roles)
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  const routing = (type) => {
    const availableRoutes = routes.filter(route => hasAccessToLink(route, permissions))
    const redirect = type === 'content' && <Redirect exact from='/' to={availableRoutes[0].path} />
    return <Switch>
      {redirect}
      {routes.map(route => {
        let child
        switch (type) {
          case 'header': {
            child = <Header />
            break
          }
          default: {
            if (!hasAccessToLink(route, permissions, roles)) {
              return <Redirect key={route.path} exact from={`${route.path}`} to={availableRoutes[0].path} />
            }
            child = props => <route.main routerProps={props} />
          }
        }
        return <Route key={route.id || route.path} exact path={route.path} children={child} />
      })}
    </Switch>
  }

  if (loading) {
    return (
      <div>
        <div className='layout-wrapper'>
          <div className='content'>
            <LoadingSpinner id='loading-spinner' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {isTablet && <SmallDeviceWarning />}
      <LayoutHeader>{routing('header')}</LayoutHeader>
      <div className='layout-wrapper'>
        <Content hasNoBottomPadding={isDBViewer || isPartnerLicense}>
          {routing('content')}
        </Content>
      </div>
    </>
  )
}

export default Layout

import React from 'react'
import Draggable from 'react-draggable'
import { DraggableWrapper } from './styled'

const DraggableModalComponent = ({
  children,
  width,
  height,
  bgColor,
  borderRadius,
  defaultPosition
}) => {
  return (
    <Draggable>
      <DraggableWrapper
        width={width}
        height={height}
        bgColor={bgColor}
        borderRadius={borderRadius}
        defaultPosition={defaultPosition}
      >
        {children}
      </DraggableWrapper>
    </Draggable>
  )
}

DraggableModalComponent.defaultProps = {
  defaultPosition: {}
}

DraggableModalComponent.displayName = 'DraggableModalComponent'
export default DraggableModalComponent

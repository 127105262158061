import styled, { css } from 'styled-components'

export const buttonEventsBehavior = css`
  &:hover {
    color: var(--brand-regular-hover);
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &:active {
    opacity: 0.3;
    color: var(--brand-regular-hover);
  }

  &:focus {
    outline: none;
  }
`

export const TransparentButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  width: fit-content;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-decoration: none;
  background: transparent;
  text-transform: uppercase;
  color: var(--brand-regular);

  ${buttonEventsBehavior}
`

import React from 'react'
import ModalComponent from '../../ModalComponent/ModalComponent'
import { DiscardModalBody } from './styles'
import { PrimaryButton, SecondaryButton } from '../../global.styled'

function DiscardModal ({ closeDiscardModal, closeFiltersModal }) {
  return (
    <ModalComponent show>
      <DiscardModalBody>
        <p>Discard your filter changes?</p>
        <PrimaryButton
          data-testid='discard-filters-button'
          marginRight='12px'
          onClick={closeFiltersModal}
        >
          Discard
        </PrimaryButton>
        <SecondaryButton
          data-testid='go-back-button'
          onClick={closeDiscardModal}
        >
          Go Back
        </SecondaryButton>
      </DiscardModalBody>
    </ModalComponent>
  )
}

DiscardModal.displayName = 'DiscardModal'
export default DiscardModal

import moment from 'moment'
import {
  getMidNightTimeStamp,
  getSecondBeforeMidNightTimeStamp
} from '../../utilities/dateTimeUtil'
import { COLLEGE_WRITING_I_GGU_V1_ID } from '../../Constants/courses'
import { CWI_DATE_OVERLAP_VALUE } from '../../Constants'
import { additionalCourseIdToName } from '../../config'

export const defaultStatuses = [
  {
    value: 'Audit',
    label: 'Audit'
  },
  {
    value: 'Audit Transcript',
    label: 'Audit Transcript'
  },
  {
    value: 'Administrative Drop',
    label: 'Administrative Drop'
  },
  {
    value: 'Withdraw',
    label: 'Withdraw'
  },
  {
    value: 'Enrolled',
    label: 'Enrolled'
  },
  {
    value: 'Signed Up',
    label: 'Signed Up'
  },
  {
    value: 'PreDrop',
    label: 'PreDrop'
  },
  {
    value: 'Completed',
    label: 'Completed'
  }
]

export const getReportByActiveDate = ({ gradeData, endDate, startDate }) => {
  if (!startDate) return gradeData

  const endDateRange = getSecondBeforeMidNightTimeStamp(endDate || new Date())
  const startDateRange = getMidNightTimeStamp(startDate)
  return gradeData.filter(({ lastActive }) => {
    const lastActiveTimeStamp = new Date(lastActive)
    return lastActiveTimeStamp >= startDateRange &&
      lastActiveTimeStamp <= endDateRange
  })
}

export const getAdditionalCourseId = (courseId, cohort) => {
  // this is specific to College Writing I GGU, we can change this to be more generic if needed
  if (!cohort) return courseId

  if (courseId !== COLLEGE_WRITING_I_GGU_V1_ID) return courseId

  const isAfterCWIOverlapTime = moment(cohort.dateStart, 'YYYY-MM-DD')
    .isSameOrAfter(moment(CWI_DATE_OVERLAP_VALUE, 'ddd MMM DD YYYY'))

  if (isAfterCWIOverlapTime) return additionalCourseIdToName(courseId)?.[1] || courseId

  return courseId
}

import React from 'react'
import {
  PartnerSection,
  PartnerDetailsTitle,
  PartnerDetailsSection,
  PartnerDetailsInputSection,
  PartnerDetailsInputItem,
  DropdownLabel,
  PartnerSelect,
  AvailableLicenseBalance,
  AvailableLicenseBalanceLabel,
  AvailableLicenseBalanceValue
} from './styled'

function PartnerDetails (props) {
  const {
    isRelationshipsLoading,
    relationshipDistrictOptions,
    coursesLicenses,
    selectedDistrict,
    setSelectedDistrict,
    selectedRelationship,
    setSelectedRelationship,
    setSelectedLicenseOption,
    filteredRelationships
  } = props

  const availableLicenses =
    coursesLicenses.filter(({ usedStatus }) => !usedStatus)
  const coursesLicensesForSelectedRelationship =
    availableLicenses.filter(({ relationshipName }) =>
      relationshipName === selectedRelationship?.label)
  const availableBalance =
    selectedRelationship ? coursesLicensesForSelectedRelationship.length : '--'

  return (
    <PartnerSection>
      <PartnerDetailsTitle>
        Partner details
      </PartnerDetailsTitle>
      <PartnerDetailsSection>
        <PartnerDetailsInputSection>
          <PartnerDetailsInputItem>
            <DropdownLabel>
              District
            </DropdownLabel>
            <PartnerSelect
              isClearable
              isLoading={isRelationshipsLoading}
              placeholder='Select a district'
              value={selectedDistrict}
              options={relationshipDistrictOptions}
              onChange={district => {
                setSelectedDistrict(district)
                setSelectedRelationship(null)
              }}
            />
          </PartnerDetailsInputItem>
          <PartnerDetailsInputItem>
            <DropdownLabel>
              School
            </DropdownLabel>
            <PartnerSelect
              isClearable
              isLoading={isRelationshipsLoading}
              placeholder='Select a school'
              value={selectedRelationship}
              options={filteredRelationships}
              onChange={relationship => {
                setSelectedRelationship(relationship)
                setSelectedLicenseOption(null)
              }}
            />
          </PartnerDetailsInputItem>
        </PartnerDetailsInputSection>
        <AvailableLicenseBalance>
          <AvailableLicenseBalanceLabel>
            Available license balance
          </AvailableLicenseBalanceLabel>
          <AvailableLicenseBalanceValue>
            {availableBalance}
          </AvailableLicenseBalanceValue>
        </AvailableLicenseBalance>
      </PartnerDetailsSection>
    </PartnerSection>
  )
}

export default PartnerDetails

import React, { useState, useEffect } from 'react'
import clearIcon from '../../assets/icons/clear-icon.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg'
import api from '../../api'
import {
  NEEDS_REVIEWING, NEEDS_REVIEWING_DEGREE
} from '../../Constants/studentStatus'
import {
  ClearIcon,
  CustomInput,
  InputError,
  InputLabel
} from './styled'

function AccountInput (props) {
  const {
    label,
    setStudentDetails,
    setStudentNeedsReview,
    setPrimaryAccount
  } = props

  const [studentEmail, setStudentEmail] = useState('')
  const [showError, setShowError] = useState(false)

  const hasNeedsReviewStatus = (attempts) => {
    return attempts.some(({ studentStatus }) =>
      [NEEDS_REVIEWING, NEEDS_REVIEWING_DEGREE].includes(studentStatus))
  }

  useEffect(() => {
    setPrimaryAccount(null)
    if (!studentEmail) return setShowError(false)
    const studentProgress = async () => {
      const { data, success } = await api.checkStudentProgress(studentEmail)
      if (!success) {
        setShowError(true)
        return
      }
      setStudentDetails(data)
      if (hasNeedsReviewStatus(data.attempts)) {
        setStudentNeedsReview(label)
      }
      setShowError(false)
    }
    // debouncing api calls
    const timeout = setTimeout(studentProgress, 1000)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line
  }, [studentEmail])

  return (
    <div>
      <InputLabel>
        {label}
      </InputLabel>
      {showError &&
        <InputError>
          <WarningIcon />
          <div>
            No results for this student. Please check for typos.
          </div>
        </InputError>}
      <CustomInput
        type='text'
        placeholder='Enter student email or ID'
        value={studentEmail}
        onChange={(e) => setStudentEmail(e.target.value)}
        showError={showError}
      />
      {studentEmail && !showError &&
        <ClearIcon
          src={clearIcon}
          alt='clear'
          onClick={() => {
            setStudentEmail('')
            setStudentDetails({})
            setShowError(false)
          }}
        />
      }
    </div>
  )
}

export default AccountInput

import React from 'react'
import {
  MergeAccountsProgressContainer,
  MergeAccountsProgressTitle,
  ProgressBar,
  ProgressBarContainer,
  ProgressBarValue
} from './styled'

function MergeInProgress () {
  return (
    <MergeAccountsProgressContainer>
      <MergeAccountsProgressTitle>
        Merging accounts, please wait...
      </MergeAccountsProgressTitle>
      <ProgressBarContainer>
        <ProgressBar>
          <ProgressBarValue />
        </ProgressBar>
      </ProgressBarContainer>
    </MergeAccountsProgressContainer>
  )
}

export default MergeInProgress

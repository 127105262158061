import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import './StudentGradeDetails.css'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import EditAssignmentModal from '../EditGrades/EditAssignmentModal'
import ParticipationDetail from './Participation'
import CodingGradeDetails from './CodingGradeDetails'
import { useStudentGradeContext, useStudentGradeActions } from '../../contexts/StudentGradeContext'
import api from '../../api'
import EditGradeOptionsModal from '../EditGrades/EditGradeOptionsModal'
import EditAssessmentGradeModal from '../EditGrades/EditAssessmentGradeModal'
import { getDateString } from '../../utilities/dateTimeUtil'
import ViewSubmissionIcon from '../../assets/icons/box-arrow-up-right.svg'
import { getExamGrade } from '../../utilities/examUtils'

function StudentGradeDetails ({
  cohortId,
  courseData,
  section,
  studentProgress,
  cohort
}) {
  const isParticipation = section.type === 'participation'
  const history = useHistory()
  const match = useParams()
  const [canUserEditGrades, setCanUserEditGrades] = useState(false)
  const [loading, setIsLoading] = useState(false)
  const [sectionData, setSectionData] = useState()
  const [specificHistory, setSpecificHistory] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [showOptionsModal, setShowOptionsModal] = useState(false)
  const [showEditAssessmentModal, setShowEditAssessmentModal] = useState(false)

  const { studentEmail, courseId, courseName, cohortName } = match

  const { globalSectionData, changesHistory, gradeData } = useStudentGradeContext()

  const { fetchSectionData } = useStudentGradeActions()

  useEffect(() => {
    checkPermissions()
    getSpecificChangesHistory()
    if (isParticipation) return
    if (section.type === 'exam' || section.type === 'WritingAssignmentChapterRecord') {
      setSectionData({})
    } else {
      fetchData()
    }
    // eslint-disable-next-line
  }, [])

  const handleViewSubmissionClick = () => {
    const { chapter_uuid: chapterUuid } = section
    window.open(
      `/#/student-submission/${courseId}/${cohortName}/${chapterUuid}/${studentEmail}`,
      '_blank'
    )
  }

  const checkPermissions = async () => {
    const EDIT_PERMISSION = 'gradeReport.view'
    try {
      const { permissions } = await api.getCurrentUserPermissions()
      const containEditPermission = permissions.some(permission => permission === EDIT_PERMISSION)
      setCanUserEditGrades(containEditPermission)
    } catch (err) {
      console.error(err)
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    let data = globalSectionData.find(data => {
      const { section_uuid: sectionUuid, course_uuid: courseUuid } = data
      return sectionUuid === section.section_uuid && courseUuid === courseData.course_uuid
    })
    if (!data) { data = await fetchSectionData(courseData.course_uuid, section.section_uuid) }
    setSectionData(data)
    setIsLoading(false)
  }

  const getSpecificChangesHistory = () => {
    const specificChanges = changesHistory.filter(changeHistory => {
      let compareWith
      if (section.type === 'WritingAssignmentChapterRecord') {
        compareWith = section.chapter_uuid
        return (changeHistory.assignmentUuid === compareWith)
      } else if (section.type === 'CodingAssignmentChapterRecord') {
        return section.chapter_uuid === changeHistory.assignmentUuid
      } else if (section.type === 'exam') {
        compareWith = section.chapter_uuid
      } else if (section.type === 'participation') {
        compareWith = 'participation'
      } else {
        compareWith = section.section_uuid
      }
      return (changeHistory.sectionUuid === compareWith)
    }).filter(changeHistory => {
      return gradeData.pastCohortData
        ? changeHistory.cohortId === cohortId
        : !changeHistory.cohortId
    })?.map(changeHistory => ({
      ...changeHistory,
      ...(changeHistory.questions ? {
        questions: changeHistory.questions.map(question => ({
          ...question,
          number: studentProgress?.studentAnswers?.[question?.questionUuid]
            ?.questionIndex + 1 ||
            question.number
        }))
      } : {})
    }))

    setSpecificHistory(specificChanges)
  }

  const getCompleted = (sectionData, studentData) => {
    const intersection = sectionData.filter(x => studentData.includes(x))
    if (intersection.length === 0) return `0 / ${sectionData.length} `
    if (intersection.length === sectionData.length) return ``
    else return `${intersection.length} / ${sectionData.length} `
  }

  const handleEditQuizClick = (quizUuid) => {
    const { course_uuid: courseUuid } = courseData
    const { name: studentName } = gradeData
    const { section_uuid: sectionUuid } = section

    history.push({
      pathname: `/student-edit-grades/${studentEmail}/${studentName}/${courseUuid}/${cohortName}`,
      search: `?quizUuid=${quizUuid}&sectionUuid=${sectionUuid}`
    })
  }

  const handleExamClick = (examData) => {
    const { chapter_uuid: chapterUuid } = examData
    const { course_uuid: courseUuid } = courseData
    const { name: studentName } = gradeData

    history.push({
      pathname: `/student-edit-grades/${studentEmail}/${studentName}/${courseUuid}/${cohortName}`,
      search: `?chapterUuid=${chapterUuid}&courseName=${courseName}`
    })
  }

  if (!isParticipation && (loading || !sectionData)) {
    return (
      <div className={'learning-wrapper'}>
        <LoadingSpinner />
      </div>
    )
  }

  const handleEditCodingAssignmentModal = async (editData) => {
    setIsUpdating(true)
    const { course_uuid: courseUuid } = courseData
    await api.putCodingAssignmentModifications(
      studentEmail,
      { courseId: courseUuid,
        cohortId: gradeData.pastCohortData ? cohortId : null },
      editData
    )
    setShowModal(false)
    window.location.reload()
  }

  const handleEditAssignmentModal = async (editData) => {
    setIsUpdating(true)
    const { course_uuid: courseUuid } = courseData
    await api.putAssignmentModifications(
      studentEmail,
      { courseId: courseUuid,
        cohortId: gradeData.pastCohortData ? cohortId : null },
      editData)
    setShowModal(false)
    window.location.reload()
  }

  const handleEditAssessmentGradeModal = async (editData) => {
    setIsUpdating(true)
    const { course_uuid: courseUuid } = courseData
    await api.putStudentSectionModification(
      studentEmail,
      { courseId: courseUuid,
        cohortId: gradeData.pastCohortData ? cohortId : null,
        progressKey: 'admin-exam-section' },
      editData)
    window.location.reload()
  }

  const handleExamEdit = examGrade => (typeof examGrade === 'number'
    ? () => setShowOptionsModal(true) : () => setShowEditAssessmentModal(true))

  if (isParticipation) {
    return <ParticipationDetail
      cohortId={cohortId}
      canUserEditGrades={canUserEditGrades}
      gradeData={gradeData}
      specificHistory={specificHistory}
      studentEmail={studentEmail}
      courseId={courseId}
      cohort={cohort}
    />
  }

  if (section.type === 'CodingAssignmentChapterRecord') {
    return <CodingGradeDetails
      canUserEditGrades={canUserEditGrades}
      cohort={cohort}
      handleEditCodingAssignmentModal={handleEditCodingAssignmentModal}
      isUpdating={isUpdating}
      section={section}
      studentEmail={studentEmail}
      specificHistory={specificHistory}
      studentProgress={studentProgress}
    />
  }

  if (section.type === 'WritingAssignmentChapterRecord') {
    const assignmentProgress = studentProgress['assignment-progress'][section.chapter_uuid]
    const assignmentGrade = assignmentProgress &&
      assignmentProgress.grade
    return (
      <div className='learning'>
        <div className='learning-section'>
          <span className='title'>Scores</span>
          <div>
            <div className='section-data-item'>
              <div className='section-data-quiz'>Assignment:</div>
              <div
                className='section-data-quiz'>{typeof assignmentGrade === 'number' ? `${assignmentGrade}%` : '--'}</div>
              {canUserEditGrades &&
              <>
                <div className='section-data-quiz__edit'
                  onClick={() => setShowModal(true)}>edit</div>
                <div
                  className='section-data-quiz__view-submission'
                  data-testid='view-submission-link'
                  onClick={() => handleViewSubmissionClick()}>
                    View submission
                  <img src={ViewSubmissionIcon} alt='View submission' />
                </div>
              </>
              }
            </div>
          </div>
        </div>
        {!!specificHistory.length && <div className='learning-section' style={{ gridArea: 'edit' }}>
          <span className='title'>Change notes</span>
          {
            specificHistory.map((change, index) => {
              return (
                <div className='change-item' key={index}>
                  <div className='change-item__date'>
                    <span>{change.dateTime}</span>
                  </div>
                  <div>
                    <div>{`${change.user} changed ${change.section} grade from ${change.from || 0}% to ${change.to}%`}</div>
                    {change.assignmentModificationDetails.map((c, id) => {
                      return (
                        <div className='change-item__data' key={id}>
                          {c.zendeskUrl && <span className='d-flex'>
                          Zendesk URL : &nbsp;&nbsp;
                            <div
                              className='section-data-quiz__view'
                              onClick={() => window.open(c.zendeskUrl, '_blank')}>
                                View
                            </div>
                          </span>}
                          <span>{`Change Reason: ${c.reason}`}</span>
                          {c.note && <span>{`Note: ${c.note}.`}</span>}
                        </div>
                      )
                    })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>}
        {showModal && <EditAssignmentModal
          showModalHandler={() => setShowModal(false)}
          show={showModal}
          section={section}
          studentProgress={studentProgress}
          handleEditAssignmentModal={handleEditAssignmentModal}
          isUpdating={isUpdating}
        />}
      </div>)
  }

  if (section.type !== 'exam') {
    const { section_exe: {
      quiz,
      guesswork,
      lecture,
      multi_lecture_videos: multiLectureVideos,
      active_learning: activeLearning,
      readings,
      practice_terms: practiceTerm,
      practice_exercises: practiceExercises } } = sectionData

    const hasLectures =
      lecture?.lecturevideos?.length > 0 ||
      multiLectureVideos?.videos?.length > 0

    return (
      <div className={'learning-wrapper'}>
        <div className={'learning'}>
          {quiz && <div className={'learning-section'}>
            <span className={'title'}>Scores</span>
            <div>
              {quiz.map((quiz, index) => {
                const quizGrade = studentProgress['quiz-section'][quiz.question_set_uuid]
                return (<div key={quiz.question_set_uuid} className={'section-data-item'}>
                  <div className={'section-data-quiz'}>Quiz {index + 1}:</div>
                  <div
                    className={'section-data-quiz'}>{typeof quizGrade === 'number' ? `${quizGrade}%` : '--'}</div>
                  {canUserEditGrades &&
                  <div className={'section-data-quiz__edit'}
                    onClick={() => handleEditQuizClick(quiz.question_set_uuid)}>
                      edit</div>
                  }
                </div>)
              }
              )}
            </div>
          </div>}

          <div className={'learning-section'}>
            <span className={'title'}>Progress</span>
            <div>
              {guesswork && <div className={'section-data-item'}>
                <div className={'section-data-progress'}>Guesswork:</div>
                <div className={'section-data-progress'}>
                  {
                    `${getCompleted(
                      guesswork.Question.map(question => question.Question_uuid),
                      Object.keys(studentProgress['studentAnswers']))} Completed`
                  }
                </div>
              </div>}

              {readings && readings.length > 0 &&
                <div className='section-data-item'>
                  <div className='section-data-progress'>Readings:</div>
                  <div className='section-data-progress'>
                    {
                      `${getCompleted(
                        readings.map(reading => reading.question_set_uuid),
                        Object.keys(studentProgress['reading-progress']))} Completed`
                    }
                  </div>
                </div>}

              {hasLectures &&
              <div className={'section-data-item'}>
                <div className={'section-data-progress'}>Lecture:</div>
                <div className={'section-data-progress'}>
                  {
                    `${Object.keys(studentProgress['lecture-complete']).includes(section.section_uuid) ? '' : '0 / 1'} Completed`
                  }
                </div>
              </div>}

              {activeLearning && <div className={'section-data-item'}>
                <div className={'section-data-progress'}>Active Learning:</div>
                <div className={'section-data-progress'}>
                  {
                    `${getCompleted(
                      activeLearning.Question.map(question => question.Question_uuid),
                      Object.keys(studentProgress['studentAnswers']))} Completed`
                  }
                </div>
              </div>}

              {practiceTerm && <div className={'section-data-item'}>
                <div className={'section-data-progress'}>Practice Term:</div>
                <div className={'section-data-progress'}>
                  {
                    Object.keys(studentProgress['practice-term-complete'])
                      .includes(section.section_uuid) ? 'Opened' : 'Unopened'
                  }
                </div>
              </div>}

              {practiceExercises && <div className={'section-data-item'}>
                <div className={'section-data-progress'}>Practice Exercises:</div>
                <div className={'section-data-progress'}>
                  {
                    `${getCompleted(Array.isArray(practiceExercises)
                      ? practiceExercises.map(question => question.question_set_uuid)
                      : practiceExercises.Question.map(question => question.Question_uuid),
                    Object.keys(studentProgress['studentAnswers']))} Completed`
                  }
                </div>
              </div>}

              {quiz &&
              <div className={'section-data-item'}>
                <div className={'section-data-progress'}>Quizzes:</div>
                <div className={'section-data-progress'}>
                  {
                    `${getCompleted(
                      quiz.map(quiz => quiz.question_set_uuid),
                      Object.keys(studentProgress['quiz-section']))} Completed`
                  }
                </div>
              </div>}
            </div>
          </div>

          {!!specificHistory.length && <div className={'learning-section'}>
            <span className={'title'}>Change notes</span>
            {
              specificHistory.map((change, index) => {
                return (
                  <div className={'change-item'} key={index}>
                    <div className={'change-item__date'}>
                      <span>{change.dateTime}</span>
                    </div>
                    <div>
                      <div className='changed__Section'>{`${change.user} changed Section ${change.section} Quiz ${change.quiz} grade from ${change.from || 0}% to ${change.to}%`}</div>
                      {change.questions.map((question, id) => {
                        const changesStatement = question.correct ? 'Incorrect to Correct' : 'Correct to Incorrect'
                        return (
                          <div className={'change-item__data'} key={id}>
                            <span>{`Question ${question.number} changed ${changesStatement}`}</span>
                            <span>
                          Zendesk URL : &nbsp;&nbsp;
                              <div
                                className={'section-data-quiz__view'}
                                onClick={() => window.open(question.zendeskUrl, '_blank')}>
                                  View
                              </div>
                            </span>
                            <span>{`Change Reason: ${question.reason}`}</span>
                            {question.note && <span className='note__span'>{`Note: ${question.note}.`}</span>}
                          </div>
                        )
                      })
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>}

        </div>
      </div>
    )
  } else {
    const examGrade = getExamGrade(studentProgress, section)
    return (
      <div className={'learning'}>
        <div className={'learning-section'}>
          <span className={'title'}>Scores</span>
          <div>
            <div className={'section-data-item'}>
              <div className={'section-data-quiz'}>Exam:</div>
              <div className={'section-data-quiz'}>
                {typeof examGrade === 'number' ? `${examGrade}%` : '--'}
              </div>
              {canUserEditGrades && (
                <>
                  <div
                    className={'section-data-quiz__edit'}
                    onClick={handleExamEdit(examGrade)}
                  >
                    edit
                  </div>
                  {showEditAssessmentModal && (
                    <EditAssessmentGradeModal
                      show={showEditAssessmentModal}
                      showModalHandler={() => setShowEditAssessmentModal(false)}
                      handleEditAssessmentModal={handleEditAssessmentGradeModal}
                      title={section?.title}
                      grade={examGrade}
                      section={section}
                      isUpdating={isUpdating}
                    />
                  )}
                  {showOptionsModal && (
                    <EditGradeOptionsModal
                      show={showOptionsModal}
                      showModalHandler={() => setShowOptionsModal(false)}
                      examGrade={examGrade}
                      section={section}
                      studentProgress={studentProgress}
                      isUpdating={isUpdating}
                      handleEditAssessmentModal={handleEditAssessmentGradeModal}
                      handleIndividualQuestionsClick={() =>
                        handleExamClick(section)
                      }
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {!!specificHistory.length && <div className={'learning-section'} style={{ gridArea: 'edit' }}>
          <span className={'title'}>Change notes</span>
          {
            specificHistory.map((change, index) =>
              change?.questions ? (
                <div key={index} className={'change-item'}>
                  <div className={'change-item__date'}>
                    <span>{change.dateTime}</span>
                  </div>
                  <div>
                    <div>{`${change.user} changed ${change.section} grade from ${change.from || 0}% to ${change.to}%`}</div>
                    {change.questions.map((question, index) => {
                      const changesStatement = question.correct ? 'Incorrect to Correct' : 'Correct to Incorrect'
                      return (
                        <div key={index} className={'change-item__data'}>
                          <span>{`Question ${question.number} changed ${changesStatement}`}</span>
                          <span>
                            Zendesk URL : &nbsp;&nbsp;
                            <div
                              className={'section-data-quiz__view'}
                              onClick={() => window.open(question.zendeskUrl, '_blank')}>
                                  View
                            </div>
                          </span>
                          <span>{`Change Reason: ${question.reason}`}</span>
                          {question.note && <span>{`Note: ${question.note}.`}</span>}
                        </div>
                      )
                    })
                    }
                  </div>
                </div>
              ) : (
                change?.registrar && <div key={index} className={'change-item'}>
                  <div className={'change-item__date'}>
                    <span>{getDateString(change.timestamp, 'MMM D, YYYY h:mmA')}</span>
                  </div>
                  <div>
                    <div>{`${change.registrar.email} changed ${section?.title
                    } grade from ${change.from || 0}% to ${change.to}%`}</div>
                    <div className={'change-item__data'}>
                      <span style={{ display: 'flex', marginBottom: '6px' }}>
                            Zendesk URL : &nbsp;&nbsp;
                        <div
                          className={'section-data-quiz__view'}
                          onClick={() => window.open(change.zendeskUrl, '_blank')}>
                                  View
                        </div>
                      </span>
                      <span>{`Change Reason: ${change.reason}`}</span>
                      {change.note && <span>{`Note: ${change.note}.`}</span>}
                    </div>
                  </div>
                </div>
              )
            )
          }
        </div>}
      </div>)
  }
}

export default StudentGradeDetails

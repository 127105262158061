import React from 'react'
import NotificationReviewAndUpdate from '../ReviewAndUpdateComponent'

const NotificationUpdate = ({ routerProps }) => {
  return (
    <>
      <NotificationReviewAndUpdate
        routerProps={routerProps}
        headerText='Update notification'
      />
    </>
  )
}

export default NotificationUpdate

export const prospectStatusOptions = [
  'Application submitted',
  'GGU decision received',
  'GGU decision sent out',
  'Offer accepted',
  'Offer not accepted',
  'Offer given',
  'Application started',
  'Application withdrawn'
]

export const admissionDecisionOptions = [
  'Conditional Admission (CA)',
  'Conditional Admission-Readmit (RCA)',
  'Deny (DN)',
  'Full Admission (FA)',
  'Full Admission-Readmit (RFA)',
  'Grad Professional Certificate (GRCT)',
  'Open Enrollment (OE)',
  'P1 Pending (Incomplete) (P1)',
  'P2 Pending (With Evaluator) (P2)',
  'P3 Pending (Dept Review) (P3)',
  'Subject to Documentation (SD)',
  'Withdrawn (WD)'
]
export const degreeStatusOptions = [
  'Active',
  'Drop Out',
  'Stop Out',
  'Complete'
]

import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import Button from '../../Button/Button'
import calendarSvg from '../../../assets/icons/calendar.svg'
import checkMark from '../../../assets/icons/check-mark.svg'

export const Container = styled.div`
  margin: 64px 63px 64px 36px;

  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }

  div.presets {
    margin-left: 35px;
  }

  div.presets p {
    margin-bottom : 14px;
  }
`
export const Header = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
`
export const Separator = styled.hr`
  margin: 36px 0 24px;
  height: 0;
  border: 1px solid var(--border-primary);
`
export const IndicatorsContainer = styled.div`
  display: flex;
  margin-bottom: 36px;
`

export const Indicator = styled.div`
  width: 240px;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 13px 16px;
  margin-right: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  background: var(--black-secondary);
  color: var(--brand-regular);
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;

  span {
    font-weight: 700;
    margin-right: 12px;
  }
`
export const ActiveIndicator = styled(Indicator)`
  color: var(--text-primary);
  border: 3px solid var(--border-primary);
`

export const GradientContainer = styled.div`
  padding: 12px 12px 0 12px;
  margin-bottom: 46px;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 5px;
  border-image: 
    linear-gradient(
      to bottom, 
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0)
    ) 1;

  label {
    font-weight: 400;
  }

  div div {
    margin-left: 12px;
  }

  p {
    margin-bottom: 0;
  }
`

export const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 6px;
  line-height: 22px;
  font-size: 18px;
  font-weight: normal;
  text-transform: capitalize;

  span {
    font-weight: bold;  
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--border-secondary);
    position: relative;
    top: 4px;
  }
`

export const SelectionButton = styled.div`
  color: ${props => props.disabled ? 'var(--brand-regular-disabled)' : 'var(--brand-regular)'};
  top: 4px;
  position: relative;
  height: fit-content;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
`

export const SelectAllButton = styled(SelectionButton)`
  margin: 0 4px 0 12px;
`

export const DeselectAllButton = styled(SelectionButton)`
  margin-left: 4px;
`

export const CheckBox = styled.label`
  margin-top: ${({ marginTop }) => marginTop};
  display: block;
  width: max-content;
  position: relative;
  padding-left: 36px;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* hide the default checkbox */
  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  /* create a custom radio button */
  span {
    position: absolute;
    top: -3px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: var(--canvas-primary);
    border: ${props => props.disabled
    ? '2px solid var(--brand-regular-disabled)'
    : '2px solid var(--brand-regular)'};
    box-sizing: border-box;
    border-radius: 5px;
  }

  /* When the checkbox is checked */
  input:checked ~ span {
    background-color: var(--brand-regular);
    border: 3px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 5px;
  }

  /* Create the check-mark/indicator */
  span::after {
    display: none;
    position: absolute;
    content: url(${checkMark});
  }

  /* Show the check-mark when checked */
  input:checked ~ span::after {
    left: 2px;
    display: block;
  }
`

export const RadioButton = styled.label`
  display: block;
  width: max-content;
  position: relative;
  padding-left: 36px;
  margin-top: ${props => props.marginTop || '0px'};
  margin-bottom: ${props => props.marginBottom || '24px'};
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.preset-item {
    font-size: 16px;
    line-height: 19px;
    margin-top: 0;
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  span {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 20px;
    width: 20px;
    background-color: var(--canvas-primary);
    border: 2px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 100%;
  }

  input:checked ~ span {
    background-color: var(--brand-regular);
    border: 3px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 100%;
  }
`

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker__input-container::after {
    content: url(${calendarSvg});
    position: relative;
    right: 25px;
  }

  span {
    position: relative;
    right: 7px;
  }
`

export const CustomDatePicker = styled(DatePicker)`
  width: 166px;
  height: 38px;
  background: var(--input-primary);
  border: none;
  padding: 10px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 19px;
  text-indent: 10px;
  color: var(--text-primary);
  position: relative;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--text-dark-off);
  }
`

export const SubHeader = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  margin-bottom: 12px;
`

export const CustomButton = styled(Button)`
  width: 150px;
  margin-top: 22px;
  color: var(--text-dark) !important;
  background-color: var(--brand-regular) !important;

  &:active {
    background-color: var(--brand-regular-active) !important;
  }

  &:hover {
    background-color: var(--brand-regular-hover) !important;
  }
`

export const ErrorMessage = styled.div`
  top: -2px;
  color: var(--error-color);
  line-height: 19px;
  position: relative;
  margin-bottom: 13px;
  font-size: 16px;
`

import React, { useState } from 'react'
import { Button } from './styles'
import { ReactComponent as FilterIcon } from '../../../assets/icons/filter.svg'
import { ReactComponent as FilterAppliedIcon } from '../../../assets/icons/filter-applied.svg'
import FiltersModal from './FiltersModal'

function Filters ({ metaData, currentView, setCurrentView, currentTable }) {
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const { filters } = currentView || {}

  const closeFiltersModal = () => {
    setShowFiltersModal(false)
  }

  return (
    <>
      <Button onClick={() => setShowFiltersModal(true)}>
        {Object.keys(filters ?? {}).length > 0 ? (
          <FilterAppliedIcon data-testid='filter-applied-icon' />
        ) : (
          <FilterIcon data-testid='no-filters-icon' />
        )}
        Filters
      </Button>
      {showFiltersModal && (
        <FiltersModal
          closeFiltersModal={closeFiltersModal}
          metaData={metaData}
          filters={filters}
          currentView={currentView}
          setFilters={(filters) => setCurrentView({ ...currentView, filters })}
          currentTable={currentTable}
        />
      )}
    </>
  )
}

Filters.displayName = 'Filters'
export default Filters

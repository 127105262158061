import styled, { createGlobalStyle } from 'styled-components'
import Button from '../../Button/Button'
import { ReactComponent as BoxArrowSVG } from '../../../assets/icons/box-arrow-up-right.svg'
import media from '../../../mediaQueries'

export const GlobalStyle = createGlobalStyle`
  .layout-wrapper > div:first-child {
    padding-bottom: 0;
  }

  ${media.tablet`
    .layout-wrapper > div:first-child {
      padding-bottom: 126px;
    }
  `}
`

export const IndividualSubmissionContainer = styled.div`
  border-top: 1px solid var(--border-secondary);
  margin: 16px 20px ${({ showCompletionModal }) => showCompletionModal ? '150px' : '24px'} 20px;
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  position: -webkit-sticky;
  position: sticky;
  top: 24px;
  z-index: 1;

  > h1 {
    font-family: Lato;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left; 
  }

  ${media.tablet`
    flex-direction: column;
    position: relative;
    top: unset;
    height: auto;
  `}
`

export const SubmissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 24px;
  max-height: 575px;
  overflow-y: ${({ overflowY }) => overflowY};

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #5B6067;
    height: 100px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #98A4AE;
  }

  ${media.tablet`
    margin-right: 0px;
    margin-bottom: 24px;
    max-height: unset;
  `}
`

export const SubmissionFrame = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #5B6067;
  border-radius: 5px;
  margin-bottom: 24px;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }
`

export const GradingContainer = styled.div`
    display: flex;
    margin-right: 20px;
    margin-left: 28px;
`

export const Assignment = styled.div`
  width: 100%;
  display: flex;
  height: 475px;
  align-items: center;

  & video {
    border: 0 none;
    border-radius: 0 0 5px 5px;
  }
`
export const GradingSidebar = styled.div`
  padding: 20px 24px 24px 24px;
  border: 1px solid #5B6067;
  width: 386px;
  border-radius: 5px;
  height: fit-content;

  & p {
    margin-bottom: 0;
    
    &.label {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: ${({ feedbackMarginBottom }) => feedbackMarginBottom || '8px'};
    }

    &.feedback-label {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--sub-text-secondary);
      margin-bottom: 8px;
    }

    &.annotation {
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--text-dark-off);
      margin-top: 8px;

      & button {
        display: contents;
        font-style: normal;
        color: var(--brand-regular);
        text-decoration: underline;
        background-color: transparent;
        border: 0 none;
        outline: 0 none;
      }
    }

    &.out-of {
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: var(--text-dark-off);
      margin-bottom: 36px;
    }
  }

  ${media.tablet`
    width: 100%;
  `}
`
export const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--gray-primary);
  z-index: 2;

  & p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;
    margin-right: 24px;
  }
`

export const ActionButton = styled(Button)`
  width: auto;
  padding: 11px 24px;
`

export const ScoreContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  & span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    &.grade {
      padding: 16px 13px;
      background: var(--black-secondary);
      margin-right: 8px;
    }

    &.equal {
      margin-right: 16px;
    }
  }
`

export const Score = styled.input`
    background: var(--black-tertiary);
    color: var(--text-primary);
    border: 1px solid var(--border-secondary);
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    width: 180px;
    margin-right: 16px;

    &:hover {
      border: 1px solid var(--brand-regular);
    }

    &:focus-visible {
      border: 1px solid var(--white);
      outline: 0 none;
    }
`

export const Link = styled.a`
  color: var(--brand-regular);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  
  :hover {
    text-decoration: none;
    color: var(--brand-regular-hover);
  }
`

export const AnnotateSubmissionButton = styled.div`
  height: 17px;
  width: fit-content;
  margin-top: 0px;
  margin-bottom: 0px;
  cursor: ${({ disabled }) => disabled ? 'wait' : 'pointer'};
  display: flex;
  align-content: center;

  p {
    color: ${({ disabled }) => disabled ? 'var(--border-primary)' : 'var(--brand-regular)'};
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: -1px;
  }

  ${props => props.disabled && `
    svg {
      fill: var(--border-primary);
    }
  `}
`

export const ErrorMessage = styled.p`
  height: 17px;
  font-size: 14px;
  letter-spacing: 1px;
  color: var(--error-color);
`

export const WarningMessage = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FF8064;
  margin-bottom: 8px;
  margin-top: 0px;
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};

  & img {
    margin-right: 8px;
  }

  & p {
    margin-bottom: 0;
  }
`

export const BoxArrowIcon = styled(BoxArrowSVG)`
  margin-right: 8px;
`

export const Editor = styled.textarea`
  width: 100%;
  height: 100%;
  border-radius: 0 0 5px 5px;
  border: 0 none;
  background: var(--black-secondary);
  color: var(--text-primary);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 1px;
  text-align: left;
  padding: 30px 5px 14px 21px;
  &:focus {
    outline: none;
  }
`

export const PreviewFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: 0 none;
  border-radius: 0 0 5px 5px;
`

export const NoPreview = styled.div`
  width: 100%;
  height: 475px;
  background: var(--black-secondary);
  text-align: center;
  border-radius: 0 0 5px 5px;

  p {
    margin-top: 150px;
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
`

export const SuccessModalWrapper = styled.div`
  padding: 24px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 80px;
  right: 24px;
  background: var(--gray-primary);
  box-shadow: 0px 6px 20px var(--shadow-primary);
  border-radius: 5px;
  overflow: overlay;

  img {
    &:first-child {
      margin-right: 12px;
      margin-top: 2px;
    }

    &:last-child {
      margin-left: 24px;
      margin-top: 2px;
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
  }
`

export const GoToPreviousPageButtonWrapper = styled.div`
  margin: 20px;
`

export const AssignmentTitle = styled.h2`
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  margin: 25.35px 0 37px 20px;
`

export const AssignmentInformation = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  margin-bottom: 0;

  & > div {
    margin-bottom: 8px;
  }
`

export const AssignmentInfoKey = styled.div`
  min-width: 100px;
  margin-bottom: ${props => (props.prompt ? '37px' : '0px')};
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
`

export const SubmissionHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  padding: 12px 12px 16px 17px;
`

export const SubmissionName = styled.p`
  flex: 1;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
`

export const LinksContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 0;
`

export const Prompt = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  height: ${({ height }) => height || 'auto'};
  overflow: hidden;
`

export const ReadMore = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 100px;
  margin-top: 4px;
  margin-bottom: 16px !important;

  & span {
    margin-left: 12px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
  }
`

export const AssignmentInfoValue = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;

  &.prompt {
    display: block;
  }

  .rubric {
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--brand-regular);
  }

  & span {
    text-transform: uppercase;
    padding: 4px 8px;
    background-color: #393D44;
    border-radius: 3px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    margin-left: 8px;
  }
`

export const PaginationEntries = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% / 4 + 60px);
`

export const PaginationFooter = styled.div`
  position: fixed;
  height: 144px;
  left: 2px;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(22, 22, 24, 0) 0%, var(--canvas-primary) 79.17%);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const PaginationWrapper = styled.div`
  margin-top: auto;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`

export const TextButton = styled.span`
  height: 17px;
  font-size: 14px;
  line-height: 17px;
  padding: 0 8px;
  cursor: pointer;
  border-right: ${props =>
    props.left && '0.5px solid rgba(255, 255, 255, 0.7)'};
  border-left: ${props =>
    props.right && '0.5px solid rgba(255, 255, 255, 0.7)'};
`

export const NumbersContainer = styled.span`
  margin: 0 4px;
`

export const NumberButton = styled.span`
  width: 12.67px;
  height: 17px;
  padding: 0 4px;
  cursor: pointer;
  text-align: center;
  text-decoration-line: ${props => (props.isActive ? 'underline' : 'none')};
`

export const NoSubmissionsText = styled.p`
  margin-top: 48px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  text-align: center;
`

export const QuickViewButton = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--brand-regular);
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;

  & img {
    margin-right: 12px;
  }
`

export const QuickViewHeader = styled.div`
  background: var(--gray-primary);
  border-radius: 5px 5px 0px 0px;
  height: 70px;
  padding: 14px 20px 0 20px;
  display: flex;
  justify-content: space-between;

  img {
    width: 12px;
    height: 12px;
    margin-top: 15px;
    margin-right: 29px;
    cursor: pointer;
  }
`

export const QuickViewBody = styled.div`
  padding: 32px 20px;
  height: 307px;
  overflow-y: scroll;
  border-bottom: 1px solid var(--border-primary);

  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 18px;

    &:last-of-type {
      margin-bottom: 10px;
    }
  }

  span {
    font-size: 16px;
    line-height: 19px;
    color: var(--text-primary);
  }
`

export const QuickViewTitle = styled.p`
  color: var(--text-secondary);
`

export const QuickViewFooter = styled.div`
  padding-top: 23px;
`

export const QuickViewSubmitButton = styled(Button)`
  font-weight: bold;
  border-radius: 5px !important;
  border: none;
  background: var(--brand-regular);
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--canvas-primary);
  padding: 12px 18px;
  margin-right: 24px;
  float: right;

  &:disabled {
    background: var(--brand-regular-disabled);
  }

  &:not(:disabled):hover {
    background: var(--brand-regular-hover);
  }
`

export const FeedbackFileUploadWrapper = styled.div`
  margin-top: ${({ marginTop }) => marginTop || '14px'};
`

export const FileUploadHeader = styled.div`
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: space-between;
`

export const FileUploadInput = styled.input`
  display: none;
`

export const FileUploadBox = styled.div`
  height: ${({ file }) => file ? '76px' : '39px'};
  width: 100%;
  position: relative;
  padding-top: ${({ file }) => file ? '42px' : '9px'};
  margin-top: ${({ marginTop }) => marginTop || '12px'};
  background: ${({ file }) => file ? 'var(--white)' : 'var(--black-secondary)'};
  border: 1px dashed ${({ file }) => file ? '#000000' : '#606060'};
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  color: ${({ file }) => file ? 'black' : 'white'};
`

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  > span {
    visibility: hidden;
    width: 183.59px;
    background: var(--gray-primary);
    color: var(--text-primary);
    text-align: center;
    padding: 8px;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: -50%;
    left: 400%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    > p {
      &:first-child {
        margin-bottom: 5px;
      }

      font-weight: 700;
      font-size: 14px;
      line-height: 16.8px;
      margin: 0;
    }
  }

  &:hover {

    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const FileUploadLabel = styled.label`
  cursor: pointer;
`

export const RemoveFileButton = styled.div`
  color: #860000;
  cursor: pointer;
  position: relative;
  
  img {
    left: -12px;
    position: absolute;
    top: 3px;
  }
`

export const FileName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 28ch;
  padding-left: 12px;
  position: relative;

  img {
    position: absolute;
    left: 0;
    top: 1px;
  }
`

export const ReplaceUploadText = styled.span`
  color: ${({ file }) => file ? 'var(--info-color)' : 'var(--brand-regular)'};
`

export const UploadedFileDetails = styled.div`
  position: absolute;
  right: 5px;
  left: 5px;
  top: 11px;
  background: var(--text-primary-off);
  border-radius: 5px;
  height: 22px;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: space-between;
  padding-left: 12px;
  padding-right: 10px;
  align-items: center;
`

export const customSelectStyles = {
  indicatorSeparator: styles => ({ display: 'none' }),
  control: (base, state) => ({
    ...base,
    width: '228px',
    minHeight: '40px',
    background: 'var(--input-primary)',
    color: 'white',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid var(--border-primary)'
    }
  }),
  menu: base => ({
    ...base,
    width: '228px',
    background: 'var(--canvas-dark)',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    marginTop: '-4px'
  }),
  menuList: base => ({
    ...base,
    width: '100%',
    height: '100%',
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    width: '100%',
    backgroundColor: !state.isSelected ? 'var(--canvas-dark)' : 'var(--canvas-dark-off)',
    borderBottom: '1px solid var(--border-primary)',
    cursor: 'pointer',
    fontSize: '18px',
    lineHeight: '22px',
    '&:last-child': {
      borderBottom: 'none',
      borderRadius: '0 0 5px 5px'
    },
    '&:first-of-type': {
      borderRadius: '5px 5px 0 0'
    },
    '&:hover': {
      backgroundColor: 'var(--canvas-dark-off)'
    }
  }),
  placeholder: base => ({
    ...base,
    color: 'var(--white)',
    marginLeft: '4px',
    fontSize: '16px',
    lineHeight: '19px'
  }),
  singleValue: base => ({
    ...base,
    color: 'var(--white)',
    paddingLeft: '6px',
    margin: 0,
    fontSize: '16px',
    lineHeight: '19px'
  }),
  dropdownIndicator: base => ({
    ...base,
    color: 'var(--white)',
    padding: '0 8px 0 0',
    '&:hover': {
      color: 'var(--white)'
    }
  }),
  indicatorContainer: base => ({
    ...base,
    paddingLeft: 0
  })
}

export const AssignmentNameContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  margin-left: 28px;
  margin-bottom: 6px;
`

export const AssignmentName = styled.div`
  width: 262px;
  height: 24.74px;
  font-style: normal;
  background: #D9D9D9;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  padding-left: 8.86px;
  padding-top: 3px;
`

export const DownloadLink = styled.button`
  color: #5FC4B8;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  background-color: transparent;
  border: 0 none;
  outline: 0 none;
  margin-left: 16px;
  & img {
    margin-right: 8px;
    margin-top: -2px;
  }
  :focus {
    outline: 0 none;
  }
`

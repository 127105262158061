import React, { useState, useEffect } from 'react'
import { saveAs } from 'file-saver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import api from '../../api'
import EditCodingIndividualGradeModal from './EditCodingIndividualGradeModal'
import EditConfirmationModal from './EditConfirmationModal'
import {
  CodingGradeWrapper,
  NameContainer,
  CohortContainer,
  AssignmentContainer,
  DownloadContainer,
  DownloadIcon,
  GradesContainer,
  GradeSuite,
  GradeHeader,
  ModifyButton,
  GradeStep,
  OriginalScoreContainer,
  RevisedScoreContainer,
  ButtonWrapper,
  PrimaryButton,
  SecondaryButton
} from './styled/EditCodingGradeStyles'
import { calculateRevisedScore } from '../../utilities/gradeUtils'

function EditCodingGrades (props) {
  const {
    name,
    courseId,
    cohortID,
    cohortName,
    codingAssignment,
    studentEmail,
    studentProgress,
    setCodingAssignment
  } = props
  const { title, chapter_uuid: chapterUuid } = codingAssignment
  const { studentAnswers } = studentProgress
  const studentAnswer = studentAnswers[chapterUuid]
  const {
    totalScore,
    step_results: stepResults
  } = studentAnswer?.meta || {}

  const handleDownload = async () => {
    const { data } = await api.getCodingAssignment(
      { courseId, cohortID, assignmentUUID: chapterUuid },
      { studentEmail }
    )

    const fileName = `${name}_${title}.java`
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' })
    saveAs(blob, fileName)
  }

  const [codingGrades, setCodingGrades] = useState(stepResults)
  const [showModal, setShowModal] = useState(false)
  const [editIndex, setEditIndex] = useState(null)
  const [editData, setEditData] = useState(null)
  const [preferredName, setPreferredName] = useState('')
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  useEffect(() => {
    const fetchStudentData = async () => {
      const { preferredName: pName = '' } =
        await api.getStudentData(studentEmail)
      setPreferredName(pName)
    }
    fetchStudentData()
  }, [studentEmail])

  const revisedScore = calculateRevisedScore(codingGrades)

  const handleEditCodingAssignmentModal = async () => {
    if (!editData) return

    setIsUpdating(true)
    await api.putCodingAssignmentModifications(
      studentEmail,
      {
        courseId
      },
      editData
    )
    window.location.reload()
  }

  return (
    <div>
      <h3 className='admin-home' onClick={() => setCodingAssignment(null)} >
        <FontAwesomeIcon icon={faAngleLeft} />{' '}
        <p>{name}</p>
      </h3>
      <CodingGradeWrapper>
        <NameContainer>
          {name}
        </NameContainer>
        <CohortContainer>
          {cohortName}
        </CohortContainer>
        <AssignmentContainer>
          {title}
        </AssignmentContainer>
        <DownloadContainer
          onClick={handleDownload}
        >
          <DownloadIcon />
          Download Student Submission
        </DownloadContainer>
        <GradesContainer>
        {codingGrades?.map((result, index) => {
          const { header, steps } = result || {}
          return (
            <GradeSuite key={`${header.replace(/\s/g, '')}_${index}`}>
              <GradeHeader>
                <span>{header}</span>
                <ModifyButton
                  onClick={() => {
                    if (!preferredName) return
                    setShowModal(true)
                    setEditIndex(index)
                  }}
                >
                  modify
                </ModifyButton>
              </GradeHeader>
              {steps?.map((step, stepIndex) => {
                const { name, weight, achievedPoints } = step || {}
                return (
                  <GradeStep key={`${name.replace(/\s/g, '')}_${stepIndex}`}>
                    <span>{name}</span>
                    <span>{achievedPoints}/{weight}</span>
                  </GradeStep>
                )
              })}
            </GradeSuite>
          )
        })}
        </GradesContainer>
        <OriginalScoreContainer>
          Original Score: {totalScore}%
        </OriginalScoreContainer>
        <RevisedScoreContainer>
          Revised Score: {revisedScore}%
        </RevisedScoreContainer>
        <ButtonWrapper>
          <PrimaryButton
            onClick={() => setShowConfirmationModal(true)}
          >
            Commit
          </PrimaryButton>
          <SecondaryButton
            onClick={() => setCodingAssignment(null)}
          >
            Cancel
          </SecondaryButton>
        </ButtonWrapper>
      </CodingGradeWrapper>
      {showModal && <EditCodingIndividualGradeModal
        assignmentUuid={chapterUuid}
        preferredName={preferredName}
        show={showModal}
        showModalHandler={() => setShowModal(false)}
        stepResults={codingGrades}
        setStepResults={setCodingGrades}
        setEditData={setEditData}
        totalScore={totalScore}
        editIndex={editIndex}
      />}
      {showConfirmationModal && <EditConfirmationModal
        show={showConfirmationModal}
        title={title}
        name={preferredName}
        isUpdating={isUpdating}
        showParentModalHandler={() => {}}
        handleEditAssessmentModal={handleEditCodingAssignmentModal}
        showModalHandler={() => setShowConfirmationModal(false)}
        editData={editData}
      />}
    </div>
  )
}

export default EditCodingGrades

import React, { useState } from 'react'
import exclamationCircleError from '../../../assets/icons/exclamation-circle-error.svg'
import { getTotalCourses, getTotalLicenses } from '../utils/licenseUtils'
import {
  ButtonSeparator,
  EmptyCoursesList,
  SelectedCourse,
  SelectedCourseName,
  SelectedCoursesContainer,
  SelectedCoursesCount,
  SelectedCoursesCountContainer,
  SelectedCoursesCountTitle,
  SelectedCoursesList,
  SelectedCoursesTitle,
  SubmitError,
  SubmitButton
} from './styled'

function SelectedCourses (props) {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    courseLicenses,
    tokens,
    showError,
    handleSubmit
  } = props

  const totalCourses = getTotalCourses(tokens)
  const totalLicenses = getTotalLicenses(tokens)

  return (
    <SelectedCoursesContainer>
      <SelectedCoursesTitle>
        Your selections
      </SelectedCoursesTitle>
      { !totalCourses &&
        <EmptyCoursesList>
          Courses with at least 1 license will appear here.
        </EmptyCoursesList>
      }
      { totalCourses > 0 &&
        <SelectedCoursesList>
          {courseLicenses.map((license) => {
            const {
              courseLicense,
              courseName,
              duration
            } = license
            if (!tokens[courseLicense]) return null

            return (
              <SelectedCourse key={courseLicense}>
                <SelectedCourseName>
                  {courseName} <span>({duration} Weeks)</span>
                </SelectedCourseName>
                <div>
                  {tokens[courseLicense]}
                </div>
              </SelectedCourse>
            )
          })}
        </SelectedCoursesList>
      }
      <SelectedCoursesCountContainer>
        <SelectedCoursesCountTitle>
          Total
        </SelectedCoursesCountTitle>
        <SelectedCoursesCount>
          <div>
            {totalCourses} course(s)
          </div>
          <div>
            {totalLicenses} license(s)
          </div>
        </SelectedCoursesCount>
      </SelectedCoursesCountContainer>
      <ButtonSeparator />
      {showError &&
        <SubmitError>
          <img src={exclamationCircleError} alt='exclamation-circle' />
          <div>
            Looks like you're one step ahead! Select a school to add licenses.
          </div>
        </SubmitError>
      }
      <SubmitButton
        disabled={showError || !totalLicenses || isSubmitting}
        onClick={async () => {
          setIsSubmitting(true)
          await handleSubmit()
          setIsSubmitting(false)
        }}
      >
        Submit
      </SubmitButton>
    </SelectedCoursesContainer>
  )
}

export default SelectedCourses

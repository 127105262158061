export function determineFlagTypes (flags) {
  flags.sort((a, b) => new Date(a.date) - new Date(b.date))

  let nonProctorReportIndex = 0

  return flags.map((flag) => {
    if (flag.source === 'proctor report') {
      return { ...flag, type: 'Report' }
    } else {
      let type
      switch (nonProctorReportIndex) {
        case 0:
          type = 'Warning'
          break
        case 1:
          type = 'Removal'
          break
        default:
          type = null
      }
      nonProctorReportIndex++
      return { ...flag, type }
    }
  })
}

export const groupRecords = (records) => {
  if (!records?.length) {
    return {}
  }
  return records.reduce((acc, record) => {
    const { fields } = record
    const key = `${fields.student[0]?.fields.id}-${fields.cohort[0]?.id}-${fields.assessment}`
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(fields)
    return acc
  }, {})
}

export const getTableData = (groupedRecords) => {
  return Object.keys(groupedRecords).map((key) => {
    const group = groupedRecords[key]
    const flags = group.map((record) => ({
      date: record.created_time,
      source: record.source,
      sanction: record.sanction
    }))

    const {
      email,
      cohort,
      assessment,
      description,
      partnerNotes,
      student,
      relationship
    } = group[0]
    const determinedFlags = determineFlagTypes(flags)

    return {
      key,
      email,
      cohort,
      assessment,
      description,
      partnerNotes,
      student,
      relationship,
      flagsReceived: determinedFlags,
      flaggedOn: determinedFlags[0].date
    }
  })
}

import React, { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import {
  NoExtensionsMessage,
  ExtensionsTableWrapper,
  HeaderCell,
  Row,
  TableHeaderWrapper
} from './style'
import ExtensionsTableRow from './ExtensionsTableRow'
import cornerRectanglePlus from '../../assets/icons/corner-rectangle-plus.svg'
import cornerRectangleMinus from '../../assets/icons/corner-rectangle-minus.svg'
import PaginationBar from './PaginationBar'
import api from '../../api'
import { groupBy } from 'lodash'
import { useDebouncedEffect } from '../../Hooks/useDebounce'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { useCoursesContext } from '../../contexts/Courses'
import { getExtensionRecordsWithExams } from './util/ExtensionRecordsUtils'
import {
  COLLEGE_WRITING_I_GGU_V1_ID,
  COLLEGE_WRITING_I_GGU_V2_AT_ID
} from '../../Constants/courses'
import { CWI_DATE_OVERLAP_VALUE } from '../../Constants'

const ExtensionsTable = ({
  cohortFilter,
  studentFilter,
  courseFilter,
  goToEditExtension
}) => {
  const { allCourses } = useCoursesContext()
  const [extensionsData, setExtensionsData] = useState(null)
  const [extensionsCount, setExtensionsCount] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [expandedRowsList, setExpandedRowsList] = useState([])
  const [extendedRecordsListByStudentId, setExtendedRecordsListByStudentId] = useState([])
  const [isLoadingRecords, setIsLoadingRecords] = useState(true)

  useDebouncedEffect(
    () => getExamExtensionsData(0),
    [cohortFilter, studentFilter, courseFilter],
    studentFilter ? 1000 : 0
  )

  const currentPagesStudentIds = useMemo(() => {
    if (!extensionsData) return []
    return extensionsData.map(extensionData => extensionData.studentId)
  }, [extensionsData])

  const getExamExtensionsData = async (offset) => {
    setIsLoadingRecords(true)

    const isAfterCWIOverlapDate = courseFilter?.id === COLLEGE_WRITING_I_GGU_V1_ID &&
     new Date(cohortFilter.dateStart) >= new Date(CWI_DATE_OVERLAP_VALUE)

    const data = await api.getExamExtensionsTable({
      offset,
      cohortFilter: cohortFilter?.id,
      courseFilter: isAfterCWIOverlapDate ? COLLEGE_WRITING_I_GGU_V2_AT_ID : courseFilter?.at_id,
      studentFilter
    })
    const { count, rows } = data
    const noOfPages = Math.ceil(count / 10)
    setNumberOfPages(noOfPages)
    setExtensionsData(rows)
    setExtensionsCount(count)
    setIsLoadingRecords(false)
  }

  const pageChangeHandler = (selectedPage = 1) => {
    const offset = 10 * selectedPage
    getExamExtensionsData(offset)
    setExpandedRowsList([])
    setExtendedRecordsListByStudentId([])
  }

  const getStudentExtensionsRecords = async (studentIdList) => {
    if (extendedRecordsListByStudentId.length === 10) { return }
    const studentRecords =
      await api.getStudentExtensionRecords(studentIdList)
    if (!studentRecords?.length) return
    const studentRecordsWithExams = await getExtensionRecordsWithExams(studentRecords, allCourses)
    if (!studentRecordsWithExams?.length) return
    return studentRecordsWithExams
  }

  const addExpandedRow = async (studentId) => {
    if (!expandedRowsList.includes(studentId)) {
      setExpandedRowsList([
        ...expandedRowsList,
        studentId
      ])
      if (!extendedRecordsListByStudentId[studentId]) {
        const studentRecords =
          await getStudentExtensionsRecords([studentId])
        setExtendedRecordsListByStudentId({
          ...extendedRecordsListByStudentId,
          [studentId]: studentRecords
        })
      }
    } else {
      setExpandedRowsList(
        expandedRowsList
          .filter(expandedRowStudentId => expandedRowStudentId !== studentId)
      )
    }
  }

  const expandCollapseRows = async () => {
    if (expandedRowsList.length > 0) {
      setExpandedRowsList([])
    } else {
      setExpandedRowsList(currentPagesStudentIds)
      const studentsRecords = await getStudentExtensionsRecords(currentPagesStudentIds)
      const studentRecordsGroupedByStudentId =
        groupBy(studentsRecords, 'studentId')
      setExtendedRecordsListByStudentId(studentRecordsGroupedByStudentId)
    }
  }

  const icon = expandedRowsList.length > 0
    ? cornerRectangleMinus
    : cornerRectanglePlus

  if (!isLoadingRecords && extensionsData?.length === 0) {
    return (
      <NoExtensionsMessage
        data-testid='noExtensionsMessage'
      >
        No extensions found.
      </NoExtensionsMessage>
    )
  }

  return (
    <>
      <ExtensionsTableWrapper data-testid='extensionsTable'>
        {isLoadingRecords && createPortal(<LoadingSpinner />, document.body)}
        {extensionsData?.length > 0 && (
          <TableHeaderWrapper>
            <Row style={{ paddingBottom: 15 }} data-testid='headerRow'>
              <HeaderCell style={{ width: 319 }}>Student Id</HeaderCell>
              <HeaderCell style={{ width: 403 }}>Email</HeaderCell>
              <HeaderCell>Total Extensions</HeaderCell>
              <HeaderCell style={{ display: 'flex', justifyContent: 'end' }}>
                <img
                  data-testid='expandAll'
                  src={icon}
                  alt='corner-rectangle-plus'
                  onClick={() => expandCollapseRows()}
                />
              </HeaderCell>
            </Row>
          </TableHeaderWrapper>
        )}
        {extensionsData?.map((extensionData, index) => {
          const { studentId } = extensionData
          const expandRow = expandedRowsList.includes(studentId)
          const extensionRecords =
            extendedRecordsListByStudentId?.[studentId] || []
          return (
            <ExtensionsTableRow
              key={index}
              extensionData={extensionData}
              addExpandedRow={addExpandedRow}
              expandRow={expandRow}
              extensionRecords={extensionRecords}
              cohortFilter={cohortFilter}
              courseFilter={courseFilter}
              goToEditExtension={goToEditExtension}
            />
          )
        })}
      </ExtensionsTableWrapper>
      {numberOfPages > 0 &&
        <PaginationBar
          extensionsCount={extensionsCount}
          numberOfPages={numberOfPages}
          pageChangeHandler={pageChangeHandler}
        />
      }
    </>
  )
}

ExtensionsTable.displyName = 'ExtensionsTable'
export default ExtensionsTable

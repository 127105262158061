import React, { useEffect } from 'react'
import {
  VoidAllLicensesContainer,
  VoidAllLicensesSection,
  VoidAllLicensesTitle,
  VoidAllLicensesTableHeader,
  VoidAllLicensesTableHeaderItem,
  VoidAllLicensesTableRow,
  VoidAllLicensesTableRowItem,
  VoidAllCourseName,
  VoidAllDuration,
  VoidAllLicensesTableFooter,
  VoidAllLicensesTableFooterItem,
  VoidAllLicensesMessage
} from './styled'

function VoidAllLicenses (props) {
  const {
    setTokensToVoid,
    selectedRelationship,
    coursesLicenses
  } = props

  const availableLicenses =
    coursesLicenses.filter(({ usedStatus }) => !usedStatus)
  const coursesLicensesForSelectedRelationship =
    availableLicenses.filter(({ relationshipName }) =>
      relationshipName === selectedRelationship?.label)
  const groupedCourseLicenses =
    coursesLicensesForSelectedRelationship.reduce((acc, value) => {
      const { courseLicense } = value
      if (!acc[courseLicense]) {
        acc[courseLicense] = []
      }
      acc[courseLicense].push(value)
      return acc
    }, {})

  useEffect(() => {
    const tokenIds = coursesLicensesForSelectedRelationship.map(({ id }) => id)
    setTokensToVoid(tokenIds)
    // eslint-disable-next-line
  }, [])

  return (
    <VoidAllLicensesContainer>
      <VoidAllLicensesSection>
        <VoidAllLicensesTitle>
          Licenses
        </VoidAllLicensesTitle>
        <VoidAllLicensesTableHeader>
          <VoidAllLicensesTableHeaderItem>
            course
          </VoidAllLicensesTableHeaderItem>
          <VoidAllLicensesTableHeaderItem>
            available
          </VoidAllLicensesTableHeaderItem>
        </VoidAllLicensesTableHeader>
        <div>
          {Object.entries(groupedCourseLicenses).map(([key, data]) => (
            <VoidAllLicensesTableRow key={key}>
              <VoidAllLicensesTableRowItem>
                <VoidAllCourseName>
                  {data[0].course.displayname}
                </VoidAllCourseName>
                <VoidAllDuration>
                  {data[0].licenseDuration} Weeks
                </VoidAllDuration>
              </VoidAllLicensesTableRowItem>
              <VoidAllLicensesTableRowItem>
                {data.length}
              </VoidAllLicensesTableRowItem>
            </VoidAllLicensesTableRow>
          ))}
        </div>
        <VoidAllLicensesTableFooter>
          <VoidAllLicensesTableFooterItem>
            Total licenses to void
          </VoidAllLicensesTableFooterItem>
          <VoidAllLicensesTableFooterItem>
            {coursesLicensesForSelectedRelationship.length}
          </VoidAllLicensesTableFooterItem>
        </VoidAllLicensesTableFooter>
      </VoidAllLicensesSection>
      <VoidAllLicensesMessage>
        By clicking “submit”, you agree to void {coursesLicensesForSelectedRelationship.length} licenses for {selectedRelationship.label}.
      </VoidAllLicensesMessage>
    </VoidAllLicensesContainer>
  )
}

export default VoidAllLicenses

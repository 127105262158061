import React, { useState, useEffect, useRef } from 'react'
import { FormGroup, Label, Input, Button } from 'reactstrap'
import Select from 'react-select'
import { VipCheckBox as CheckBox } from '../VipUser/styled'
import './clone-student-progress.css'

import api from '../../api'
import { additionalCourseIdToName } from '../../config'
import { getAdditionalCourseDetails, getCoursesWithCourseId } from '../../utilities/courseUtils'

const initialState = {
  course: '',
  cohort: '',
  fromId: '',
  toId: ''
}

function CloneStudentProgress () {
  const [formState, setFormState] = useState(initialState)
  const [cloneByCohort, setCloneByCohort] = useState(false)
  const [filteredCohorts, setFilteredCohorts] = useState([])
  const [allCohorts, setCohorts] = useState([])
  const [loading, setLoading] = useState()
  const [disabled, setDisabled] = useState()
  const [allCourses, setCourses] = useState([])
  const [filteredCourses, setFilteredCourses] = useState([])
  const mountedRef = useRef(false)

  useEffect(() => {
    setDisabled(true)
    mountedRef.current = true
    fetchDropdownData()
    // eslint-disable-next-line
    return () => {
      mountedRef.current = false
    }
  }, [])

  const fetchDropdownData = async () => {
    const { getCourses, getAllCohorts } = api
    const promises = [getCourses(), getAllCohorts()]
    const [courses, cohorts] = await Promise.all(promises)
    const allCourses = courses.map(course => ({ value: course.id, label: course.name, ...course }))
    const filteredCourses = getCoursesWithCourseId(allCourses)
    setFilteredCourses(filteredCourses)
    mountedRef.current && setCourses(allCourses)
    mountedRef.current && setCohorts(
      cohorts.map(cohort => ({ value: cohort.at_id, label: cohort.name, ...cohort }))
    )
  }

  useEffect(() => {
    onChange('cohort', '')
    const filtered = filterCohorts(course, allCohorts)
    setFilteredCohorts(filtered || [])
    // eslint-disable-next-line
  }, [formState.course])

  const filterCohorts = (course, cohorts) => {
    if (!course) return []
    let newCourse = [course]

    const courseToAdd = getAdditionalCourseDetails(allCourses, newCourse)
    newCourse = courseToAdd ? [course, { ...courseToAdd }] : newCourse

    return cohorts.filter((cohort) =>
      newCourse.some((course) =>
        cohort.course.includes(course.at_id)
      )
    )
  }

  const callCloneStudentProgress = async (studentData) => {
    let response
    if (cloneByCohort || !course.value) {
      response = await api.cloneStudentProgress(
        studentData,
        cloneByCohort ? cohort.course[0] : course.value,
        cloneByCohort ? cohort.value : false
      )
      return response
    }
    const additionalIds = additionalCourseIdToName(course.value)
    response = additionalIds.length ? await Promise.all(additionalIds.map(id => api.cloneStudentProgress(
      studentData,
      id,
      false
    ))) : false
    response = response?.flat()[0]
    return response
  }

  const submit = async () => {
    try {
      const studentData = {
        clonedToStudentId: formState.toId,
        clonedFromStudentId: formState.fromId
      }
      setLoading(true)
      const response = await callCloneStudentProgress(studentData)
      if (response.success) {
        setFormState(initialState)
        alert('Student Progress is cloned successfully')
      } else {
        alert(response.message)
      }
    } catch ({ message }) {
      alert(message)
    } finally {
      setLoading(false)
    }
  }

  const onChange = (name, value) => {
    (name === 'cohort' && !cloneByCohort) || value
      ? setDisabled(false)
      : setDisabled(true)
    setFormState((prevState) => ({ ...prevState, [name]: value }))
  }

  const { course, cohort, fromId, toId } = formState
  return (
    <div className='clone-student-page__container'>
      <h3>Clone Student Progress</h3>
      <div className='form-wrapper'>
        <FormGroup>
          <Label>Course</Label>
          <div className='text-field'>
            <Select
              options={filteredCourses}
              value={course}
              onChange={course =>
                onChange('course', course)
              }
              placeholder='Select Student Courses'
            />
          </div>
          <CheckBox margin='15px'>
            Clone progress from cohort?
            <input
              type='checkbox'
              id='cloneByCohortCheck'
              value={cloneByCohort}
              onChange={e => setCloneByCohort(e.target.checked)}
              checked={cloneByCohort}
            />
            <span />
          </CheckBox>
          {cloneByCohort && (
            <>
              <Label>Cohort</Label>
              <div className='text-field'>
                <Select
                  options={filteredCohorts}
                  value={cohort}
                  isDisabled={!course}
                  id='cohortSelect'
                  onChange={(cohort) => onChange('cohort', cohort)}
                  placeholder='Select Student Cohort'
                />
              </div>
            </>
          )}
          <Label>From</Label>
          <div className='text-field'>
            <Input
              type='text'
              value={fromId}
              placeholder='From (Student ID)'
              onChange={e => onChange('fromId', e.target.value)}
            />
          </div>
          <Label>To</Label>
          <div className='text-field'>
            <Input
              type='text'
              value={toId}
              placeholder='To (Student ID)'
              onChange={e => onChange('toId', e.target.value)}
            />
          </div>
          {loading ? <i className='fa fa-spinner fa-spin' />
            : <Button className='submit-button' color='primary' onClick={submit} disabled={disabled}>
            Submit
            </Button>}
        </FormGroup>
      </div>
      <hr />
    </div>
  )
}

export default CloneStudentProgress

import React, { useState } from 'react'
import FileUploader from '../FileUploader/FileUploader'
import { ComponentContainer, CustomButton, InputContainer } from './styled'
import api from '../../api'
import { arrayToCSV } from '../../utilities/CSVDownloadTool'

const AmazonCSVUpload = ({
  setUploadedCSV,
  setFileName
}) => {
  const [file, setFile] = React.useState(undefined)
  const [loading, setLoading] = useState(false)

  const processUploadedFile = async () => {
    try {
      const formData = new FormData()
      formData.append('students', file)
      setFileName(file.name)

      setLoading(true)
      const { data } = await api.getAmazonStudentsProgress(formData)
      const result = arrayToCSV(data)
      setUploadedCSV(result)
      setLoading(false)
    } catch (e) {
      console.log('processUploadedFile Error', e.message)
    }
  }

  return (
    <ComponentContainer>
      <h3>Upload CSV</h3>
      <p>
        Please upload the CSV you downloaded from the Amazon Career Choice Portal.
        Processing the CSV will fill the remaining fields with the student’s progress.
      </p>
      <InputContainer>
        <FileUploader
          isSmallInput
          onFileSelect={(fileObj) => setFile(fileObj)}
          uploadedContent={file}
          onEmpty={() => setFile(undefined)}
        />
        <p className='italic-text'>
        Note: This tool only allows one CSV per upload.
        Attaching another CSV file will replace the existing file.
        </p>
      </InputContainer>
      <CustomButton
        data-testid='next-button'
        className='btn-custom btn-primary'
        disabled={!file || loading}
        onClick={processUploadedFile}
      >
        {loading && <i className='fa fa-spinner fa-spin' />}
        {loading ? ' loading' : 'process csv'}
      </CustomButton>
    </ComponentContainer>
  )
}

export default AmazonCSVUpload

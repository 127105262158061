import React, { useEffect, useRef, useState } from 'react'
import api from '../../api'
import {
  AddButton,
  ButtonsContainer,
  CancelButton,
  ContentWrapper,
  FormContainer,
  FormLabel,
  InputContainer,
  ModalContainer,
  ModalContentsUpdate,
  ModalContentsWrapper,
  ModalHeaderContainer,
  ModalWrapper,
  WarningMessage
} from './styles'
import RecordInput from './RecordInput'
import { ModalTitle } from '../ModalComponent/styles/modalComponent'
import ActivityPanel from './ActivityPanel/ActivityPanel'
import { getTableColumns } from './utils'
import HiddenFields from './HiddenFields'

const RecordModal = (props) => {
  const {
    tableName,
    metaData,
    rowToEdit,
    handleClose,
    handleUpdateTable,
    action,
    canUserEditDatabase,
    view
  } = props

  const { id } = rowToEdit || {}
  const isCreate = action === 'create'
  const isEdit = action === 'edit'

  const [row, setRow] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [rowEditHistory, setRowEditHistory] = useState([])
  const [rowCommentsHistory, setRowCommentsHistory] = useState([])
  const originalRow = useRef({})
  const { visibleColumns, hiddenColumns } = getTableColumns(metaData, view)

  useEffect(() => {
    if (!rowToEdit) return
    const row = {}
    for (const key in rowToEdit) {
      if (key === 'id') continue

      const field = metaData.find(item => [item.camelCase, item.columnName].includes(key))
      if (!field) continue

      const { camelCase, isAutoGenerated } = field
      if (isAutoGenerated) continue

      row[camelCase] = rowToEdit[key]
    }
    originalRow.current = row
    setRow(row)
  }, [rowToEdit, metaData])

  useEffect(() => {
    setErrorMessage('')
  }, [row])

  const fetchData = async () => {
    const editHistoryData = {
      tableName: 'history_log',
      filters: {
        rules: [
          {
            field: 'id',
            operator: 'is',
            value: id
          },
          {
            field: 'table_name',
            operator: 'is',
            value: tableName.toLowerCase()
          }
        ],
        logicalOperator: 'AND'
      }
    }

    const [{ rows: editHistory = [] }, { rows: commentHistory } = []] = await Promise.all([
      api.getTableFilter(editHistoryData),
      api.getTableRecordComments(tableName, id)
    ])

    setRowEditHistory(editHistory)
    setRowCommentsHistory(commentHistory)
  }
  useEffect(() => {
    if (isEdit) {
      fetchData()
    }
    // eslint-disable-next-line
  }, [])

  const handleSave = async () => {
    setIsSaving(true)
    const {
      applicationInfo,
      cohortUpdate,
      createdAt,
      createdOn,
      dateDOBChanged,
      updatedAt,
      lastUpdatedAt,
      lastRefundDate,
      /* TODO remove the 3 fields bellow after BE adds isComputed to the fields
       and we implement it in this file similar to isAutoGenerated */
      latestRefundDate,
      cohortCount,
      paidAmountPerCourse,
      ...rest
    } = row

    const recordUpdatedFields = id
      ? Object.keys(rest).reduce((acc, key) => {
        if (originalRow.current[key] !== rest[key]) {
          acc[key] = rest[key]
        }
        return acc
      }, {})
      : rest

    if (Object.keys(recordUpdatedFields).length === 0) {
      handleClose()
      return
    }

    const result = id
      ? await api.updateRecord(recordUpdatedFields, tableName, id)
      : await api.addNewRecord(rest, tableName)
    if (result?.error) {
      setErrorMessage(result.error)
      setIsSaving(false)
    }
    if (isCreate) handleClose && handleClose()
    if (isEdit) handleUpdateTable && handleUpdateTable()
  }

  return (
    <ModalContainer show width={929}>
      <ModalWrapper width={929} className='modal-wrapper'>
        {tableName && <ModalHeaderContainer>
          <ModalTitle>{tableName}</ModalTitle>
          <ButtonsContainer>
            <CancelButton
              data-testid='cancel-button'
              onClick={handleClose}
              disabled={isSaving}
            >
              cancel
            </CancelButton>
            {canUserEditDatabase &&
              <AddButton
                data-testid='update-record-button'
                onClick={handleSave}
                disabled={isSaving}
              >
                {isCreate ? 'add record' : 'update'}
              </AddButton>
            }
          </ButtonsContainer>
        </ModalHeaderContainer>}
        <ContentWrapper>
          <ModalContentsWrapper>
            <ModalContentsUpdate>
              {
                visibleColumns.map((field) => {
                  const { columnName, isAutoGenerated } = field
                  if (columnName === 'id' || isAutoGenerated) return null
                  return (
                    <FormContainer key={columnName}>
                      <FormLabel>{columnName}</FormLabel>
                      <InputContainer >
                        <RecordInput
                          field={field}
                          row={row}
                          setRow={setRow}
                          canUserEditDatabase={canUserEditDatabase}
                        />
                      </InputContainer>
                    </FormContainer>
                  )
                })
              }
              <HiddenFields
                hiddenColumns={hiddenColumns}
                row={row}
                setRow={setRow}
                canUserEditDatabase={canUserEditDatabase}
              />
            </ModalContentsUpdate>
            { errorMessage && <WarningMessage>{errorMessage}</WarningMessage>}
          </ModalContentsWrapper>
          <ActivityPanel
            rowEditHistory={rowEditHistory}
            rowCommentsHistory={rowCommentsHistory}
            tableName={tableName}
            recordId={id}
            fetchData={fetchData}
            row={rowToEdit}
          />
        </ContentWrapper>
      </ModalWrapper>
    </ModalContainer>
  )
}

export default RecordModal

import styled from 'styled-components'

export const StudentStatusEditModalWrapper = styled.div`
  background-color: var(--student-status-edit-modal-background-color);
  width: 514px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 36px;
  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 24px;
  }
  p {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    span {
      font-weight: bold;
    }
  }
  .form-group {
    margin-top: 10px;
    .section-title {
      margin-bottom: 0;
    }
    .input-error-border {
      border: 1px solid var(--form-error-color);
    }
    textarea {
      height: 72px;
    }
    .zendesk {
      height: 42px;
    }
    textarea,
    input {
      margin-top: 12px;
      background-color: var(--information-card-background-color);
      border: 1px solid var(--form-input-border-color);
      box-shadow: none;
      color: white;
      &:focus,
      &:active {
        border: 1px solid var(--information-card-button-color);
      }
    }
  }
  .error {
    font-size: 18px;
    line-height: 22px;
    color: var(--form-error-color);
    margin: 6px 0 0 0;
    padding: 0;
  }
  .buttons-container {
    display: flex;
    justify-content: flex-end;
    .next-btn {
      margin-left: 12px;
      color: var(--information-card-background-color);
      background-color: var(--information-card-button-color);
    }
    button {
      width: 120px;
    }
  }
`

export const customSelectStyles = {
  container: base => ({ ...base, marginTop: '12px' }),
  option: (base, state) => ({
    ...base,
    color: state.isSelected
      ? 'white'
      : 'var(--information-card-background-color)'
  }),
  control: (base, state) => {
    const { error } = state.selectProps
    const borderColor = () => {
      if (error) {
        return 'var(--form-error-color)'
      } else if (state.isFocused) {
        return 'var(--information-card-button-color)'
      }

      return 'var(--form-input-border-color)'
    }

    return {
      ...base,
      backgroundColor: 'var(--information-card-background-color)',
      border: `1px solid ${borderColor()}`,
      color: 'white',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid var(--form-input-border-color)'
      }
    }
  },
  placeholder: base => ({ ...base, color: 'white', lineHeight: '19px' }),
  singleValue: base => ({ ...base, color: 'white', lineHeight: '19px' }),
  valueContainer: base => ({ ...base, padding: '0 8px' }),
  input: base => ({ ...base, padding: 0, margin: 0 })
}

export const TokenMessageText = styled.div`
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #98A4AE;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 12px 0px;
`

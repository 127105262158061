import styled, { keyframes, css } from 'styled-components'

export const Spinner = styled.div`
  position: ${props => props.position || 'absolute'};
  ${props => props.height && `height: ${props.height}px;`}
  top: 50%;
  left: calc(50% + ${props => props.left || 0}px);
  width: 100px;
  text-align: center;
  transform: ${props => props.position !== 'static' && 'translate(-50%, -50%)'};

  ${props => props.position === 'static' && css`
    margin: 50px auto 0px auto;
    ${props => props.marginTop && `margin-top: ${props.marginTop}px;`}
  `}
`

export const LoadingKeyFrame = keyframes`
  0%, 80%, 100% { 
    transform: scale(0);
  } 40% { 
    transform: scale(1.0);
  }
`

export const Bounce = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 10px;
  background-color: var(--white);
  border-radius: 100%;
  display: inline-block;
  animation: ${LoadingKeyFrame} 1.4s infinite ease-in-out both;
`

export const Bounce1 = styled(Bounce)`
  animation-delay: -0.32s;
`

export const Bounce2 = styled(Bounce)`
  animation-delay: -0.16s;
`

export const SpinnerWrap = styled.div`
  background-color: #0f0f10;
  height: 100vh
`

import styled from 'styled-components'

export const TableContainer = styled.table`
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: auto;
`

export const TableHeader = styled.thead`
  border: 2px solid #fff;
`

export const HeaderColumn = styled.th`
  border: 2px solid #fff;
  padding: 8px 80px 8px 5px;
  font-size: 14px;
  position: sticky;
  background-color: var(--content-background-color);
  top: 0;
  vertical-align: inherit;
  text-align: left;

  &:first-child,
  &:nth-child(2),
  &:nth-child(3){
    z-index: 2;
  }
`

export const TableBody = styled.tbody`
  border: 2px solid #fff;
`

export const BodyCell = styled.td`
  border: 2px solid #fff;
  padding: 4px 10px 4px 5px;
  font-weight: normal;
  font-size: 17px;
`

export const PaginationContainer = styled.div`
  width: 100%;
  bottom: 0px;
  padding-top: 8px;
  margin-bottom: 0;

  .pagination {
    padding-top: 2px;
  }

  .pagination .previous {
    border-right: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  }

  .pagination .next {
    border-left: 0.5px solid rgba(255, 255, 255, 0.3) !important;
  }

  .pagination .next,
  .pagination .previous {
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    margin-right: 7px;
    margin-left: 7px;
  }

  .pagination .previous.disabled,
  .pagination .next.disabled {
    display: block !important;
  }

  .disabled a {
    opacity: 0.3 !important;
  }
`

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  > span {
    visibility: hidden;
    width: 300px;
    height: auto;
    padding: 12px;
    background: var(--gray-primary);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: var(--text-primary);
    margin-bottom: -8px;
    

    /* Position the tooltip text */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 200%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
      
    > p {
      margin-bottom: 5px;
    }
   }

  &:hover {
    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`
export const Link = styled.a`
  color: var( --brand-regular);
  text-decoration: underline;
  &:hover {
    color: var(--brand-regular-hover);
  }
`

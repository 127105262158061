import moment from 'moment'

export function transformRecords (records) {
  const cohorts = {}

  records.forEach((record) => {
    const cohortId = record.fields.cohort[0].id
    const cohortFields = record.fields.cohort[0].fields

    if (!cohorts[cohortId]) {
      cohorts[cohortId] = initializeCohort(cohortFields)
    }

    if (record.fields.source === 'proctor report') {
      cohorts[cohortId].reports += 1
    }

    if (record.fields.approvalStatus === 'Approved') {
      cohorts[cohortId].approved.push(record)
    } else if (record.fields.approvalStatus === 'Denied') {
      cohorts[cohortId].denied.push(record)
    }
  })

  return Object.values(cohorts).map(processCohort)
}

function initializeCohort (cohortFields) {
  return {
    cohortName: cohortFields.name,
    cohortStartDate: cohortFields.dateStart,
    cohortEndDate: cohortFields.finalExamEndTime,
    warnings: 0,
    removals: 0,
    reports: 0,
    approved: [],
    denied: [],
    uniqueApproved: [],
    uniqueDenied: []
  }
}

function processCohort (cohort) {
  const approvedAssessments = processApprovedRecords(cohort.approved)
  cohort.uniqueApproved = Object.values(approvedAssessments)

  cohort.warnings = cohort.uniqueApproved.reduce(
    (sum, details) => sum + details.warnings,
    0
  )
  cohort.removals = cohort.uniqueApproved.reduce(
    (sum, details) => sum + details.removals,
    0
  )
  cohort.reports = cohort.uniqueApproved.reduce(
    (sum, details) => sum + details.reports,
    0
  )

  const deniedAssessments = processDeniedRecords(cohort.denied)
  cohort.uniqueDenied = Object.values(deniedAssessments)

  cohort.uniqueApproved.sort((a, b) => a.assessment.localeCompare(b.assessment))
  cohort.uniqueDenied.sort((a, b) => a.assessment.localeCompare(b.assessment))

  return cohort
}

function processApprovedRecords (approvedRecords) {
  const approvedAssessments = {}

  approvedRecords.forEach((record) => {
    const assessment = record.fields.assessment
    if (!approvedAssessments[assessment]) {
      approvedAssessments[assessment] = initializeApprovedAssessment(assessment)
    }

    updateApprovedAssessment(approvedAssessments[assessment], record)
  })

  return approvedAssessments
}

function initializeApprovedAssessment (assessment) {
  return {
    assessment,
    warnings: 0,
    warningTime: null,
    removals: 0,
    removalTime: null,
    reports: 0,
    reportTime: null,
    sanction: null,
    partnerNotes: null,
    outlierNotes: null
  }
}

function updateApprovedAssessment (assessmentDetails, record) {
  if (assessmentDetails.warnings === 0) {
    assessmentDetails.warnings += 1
    assessmentDetails.warningTime = record.fields.created_time
  } else if (assessmentDetails.removals === 0) {
    assessmentDetails.removals += 1
    assessmentDetails.removalTime = record.fields.created_time
  }

  if (record.fields.source === 'proctor report') {
    assessmentDetails.reports += 1
  }

  assessmentDetails.sanction = record.fields.sanction
  assessmentDetails.partnerNotes = record.fields.partnerNotes
  assessmentDetails.outlierNotes = record.fields.outlierNotes
}

function processDeniedRecords (deniedRecords) {
  const deniedAssessments = {}

  deniedRecords.forEach((record) => {
    const assessment = record.fields.assessment
    if (!deniedAssessments[assessment]) {
      deniedAssessments[assessment] = initializeDeniedAssessment(record)
    } else {
      updateDeniedAssessment(deniedAssessments[assessment], record)
    }
  })

  return deniedAssessments
}

function initializeDeniedAssessment (record) {
  return {
    assessment: record.fields.assessment,
    incident: 'Warning',
    flaggedOn: record.fields.created_time,
    rejectedOn: record.fields.rejected_on,
    partnerNotes: record.fields.partnerNotes,
    denialReason: record.fields.denialReason
  }
}

function updateDeniedAssessment (assessmentDetails, record) {
  const currentFlaggedOn = new Date(record.fields.created_time)
  const existingFlaggedOn = new Date(assessmentDetails.flaggedOn)

  if (currentFlaggedOn > existingFlaggedOn) {
    assessmentDetails.flaggedOn = record.fields.created_time
    assessmentDetails.rejectedOn = record.fields.rejected_on
    assessmentDetails.partnerNotes = record.fields.partnerNotes
    assessmentDetails.denialReason = record.fields.denialReason
  }
}

export const formatDate = (date) => {
  return date ? moment(date).format('MM/DD/YYYY  h:mma') : ''
}

export const formatAttempt = (attempt) => {
  return {
    ...attempt?.fields,
    label: attempt?.fields.cohort.fields.name
      .split(' - ')
      .splice(0, 2)
      .join(' - '),
    value: attempt?.fields.cohort.id,
    studentStatus: attempt?.fields?.studentStatus.fields.Name
  }
}

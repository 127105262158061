import React, { useEffect, useState } from 'react'
import { TableWrapper,
  HeaderCell, Row,
  TableHeaderWrapper
} from './styles/index.styled'
import TableRow from './TableRow'
import PaginationBar from './PaginationBar'

const LicenseDashboardTable = ({ relationships }) => {
  const [displayedRelationships, setDisplayedRelationships] = useState([])

  useEffect(() => {
    if (!relationships.length) setDisplayedRelationships([])
    setDisplayedRelationships(relationships.slice(0, 20))
  }, [relationships])

  const partnersCount = relationships?.length
  const numberOfPages = Math.ceil(partnersCount / 20)

  const pageChangeHandler = (page) => {
    const startIndex = page * 20
    const endIndex = startIndex + 20
    setDisplayedRelationships(relationships.slice(startIndex, endIndex))
  }

  return (
    <>
      <TableWrapper data-testid='license-dashboard-table'>
        {displayedRelationships?.length > 0 && (
          <TableHeaderWrapper>
            <Row style={{ paddingBottom: 15 }} data-testid='headerRow'>
              <HeaderCell style={{ width: 319 }}>Relationship</HeaderCell>
              <HeaderCell style={{ width: 403 }}>District</HeaderCell>
              <HeaderCell>Total Licenses</HeaderCell>
              <HeaderCell style={{ justifyContent: 'end' }} />
            </Row>
          </TableHeaderWrapper>
        )}
        {displayedRelationships?.map((relationship, index) => {
          return (
            <TableRow
              key={index}
              relationship={relationship}
            />
          )
        })}
      </TableWrapper>
      {numberOfPages > 1 && (
        <PaginationBar
          partnersCount={partnersCount}
          numberOfPages={numberOfPages}
          pageChangeHandler={pageChangeHandler}
        />
      )}
    </>
  )
}

LicenseDashboardTable.defaultProps = {
  relationships: []
}

LicenseDashboardTable.displayName = 'LicenseDashboardTable'
export default LicenseDashboardTable

import React from 'react'
import { Link } from './style'

const DownloadLink = ({ label, link, fileName }) => {
  return (
    <>
      <Link href={link}
        target='_blank'
        download={fileName}>{label}</Link>
    </>
  )
}

export default DownloadLink

import React, { useEffect, useRef, useState } from 'react'
import {
  ToastTitle,
  ClearCacheButton,
  RowLabel,
  RowText,
  Separator,
  StudentDetails,
  StudentDetailsLeft,
  StudentDetailsRightContainer,
  StudentDetailsRight,
  StudentDetailsRow,
  StudentInformationWrapper,
  StudentRecordDetailsHeader,
  StudentRecordDetailsHeaderLeft,
  StudentRecordDetailsHeaderRight
} from './style'
import api from '../../../api'
import { getDateString } from '../../../utilities/dateTimeUtil'
import StudentGoals from './StudentGoals'
import Toast from '../../ToastComponent/Toast'
import { ReactComponent as CheckMark } from '../../../assets/icons/icon-checkmark.svg'
import { ReactComponent as Error } from '../../../assets/icons/warning-diamond.svg'

const StudentInformation = ({ studentId: studentIdParam, prospectData }) => {
  const [studentDetails, setStudentDetails] = useState({})
  const toast = useRef()

  useEffect(() => {
    const fetchStudentRecord = async () => {
      const data = await api.getStudentDataByEmailOrId(studentIdParam)
      const {
        id: studentId,
        email,
        preferredName,
        name,
        firstName,
        lastName,
        phoneNumber,
        notes,
        lastActive,
        primaryGoal
      } = data
      const lastActiveDate = new Date(lastActive)
      const formattedLastActive = lastActive && lastActive !== 0
        ? getDateString(lastActiveDate, 'MMM d, yyyy h:mmA')
        : 'N/A'
      setStudentDetails({
        studentId,
        email,
        preferredName: preferredName || '',
        name,
        firstName,
        lastName: lastName || name?.split(' ').pop(),
        phoneNumber,
        notes,
        lastActive: formattedLastActive,
        primaryGoal
      })
    }
    fetchStudentRecord()
    // eslint-disable-next-line
    },  [])

  const handleClearCache = async () => {
    const response = await api.removeStudentCache(studentDetails.email)
    const { current: { display } = {} } = toast
    if (response?.success) {
      return display(
        <ToastTitle>
          <CheckMark />
          <>Cache successfully cleared</>
        </ToastTitle>
      )
    }
    return display(
      <ToastTitle>
        <Error />
        <>Error clearing cache</>
      </ToastTitle>,
      "There was a problem clearing this student's cache. Please try again or report this issue."
    )
  }

  const {
    academicEndGoal,
    gguEmail,
    gguStudentId
  } = prospectData || {}

  return (
    <StudentInformationWrapper>
      <Toast toast={toast} />
      <StudentRecordDetailsHeader>
        <StudentRecordDetailsHeaderLeft>
          {`${studentDetails?.preferredName || ''} ${studentDetails?.lastName || ''}`}
        </StudentRecordDetailsHeaderLeft>
        <StudentRecordDetailsHeaderRight>
          {`Last Active ${studentDetails?.lastActive}`}
        </StudentRecordDetailsHeaderRight>
      </StudentRecordDetailsHeader>
      <Separator />
      <StudentDetails>
        <StudentDetailsLeft>
          <StudentDetailsRow>
            <RowLabel>Full Name</RowLabel>
            <RowText>
              {studentDetails.name || `${studentDetails.firstName || ''} ${studentDetails.lastName || ''}`}
            </RowText>
          </StudentDetailsRow>
          <StudentDetailsRow>
            <RowLabel>Email</RowLabel>
            <RowText>{studentDetails.email}</RowText>
          </StudentDetailsRow>
          {gguEmail && (
            <StudentDetailsRow>
              <RowLabel>GGU Email</RowLabel>
              <RowText>{gguEmail}</RowText>
            </StudentDetailsRow>
          )}
          <StudentDetailsRow>
            <RowLabel>Phone</RowLabel>
            <RowText>{studentDetails.phoneNumber}</RowText>
          </StudentDetailsRow>
        </StudentDetailsLeft>
        <StudentDetailsRightContainer>
          <StudentDetailsRight>
            <StudentDetailsRow>
              <RowLabel>Student ID</RowLabel>
              <RowText>{studentDetails.studentId}</RowText>
            </StudentDetailsRow>
            {gguStudentId && (
              <StudentDetailsRow>
                <RowLabel>GGU ID</RowLabel>
                <RowText>{gguStudentId}</RowText>
              </StudentDetailsRow>
            )}
            <StudentDetailsRow>
              <RowLabel>Notes</RowLabel>
              <RowText>{studentDetails.notes}</RowText>
            </StudentDetailsRow>
          </StudentDetailsRight>
          <ClearCacheButton onClick={handleClearCache}>
            Clear Cache
          </ClearCacheButton>
        </StudentDetailsRightContainer>
      </StudentDetails>
      <StudentGoals
        academicGoal={academicEndGoal}
        primaryGoal={studentDetails.primaryGoal}
      />
    </StudentInformationWrapper>
  )
}
StudentInformation.displayName = 'StudentInformation'
export default StudentInformation

import {
  ButtonAsLink,
  ButtonsContainer, CardHeader,
  CardTitle,
  DetailLabel,
  DetailRow, DetailsCard,
  DetailsContainer, DetailValue,
  RowGroup, ToolTip, ToolTipContainer
} from './styles'
import iconTooltip from '../../../assets/icons/icon-tooltip.svg'
import React, { useState } from 'react'
import { PrimaryButton } from '../../../Components/global.styled'
import { getFieldInput, getUpdatedFields } from './utils'
import api from '../../../api'

const editableFields = [
  {
    label: 'Clear to register',
    key: 'goodFinancialStanding',
    options: ['Yes', 'No']
  }
]

const StudentDetails = ({ prospect, setProspect }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [fieldsToEdit, setFieldsToEdit] = useState({
    goodFinancialStanding: prospect.goodFinancialStanding
  })
  const {
    firstName,
    middleName,
    lastName,
    email,
    gguEmail,
    gguStudentId,
    outlierId,
    goodFinancialStanding
  } = prospect
  const fullName =
    `${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''}`

  const handleSave = async () => {
    const { prospectId } = prospect
    const updatedFields = getUpdatedFields(prospect, fieldsToEdit)
    await api.updateProspectDetails(prospectId, updatedFields)

    // TODO: show error message if update fails

    setProspect({
      ...prospect,
      ...fieldsToEdit
    })
    setIsEditing(false)
  }

  return (
    <DetailsCard>
      <CardHeader>
        <CardTitle>Student details</CardTitle>
        {isEditing ? (
          <ButtonsContainer>
            <ButtonAsLink
              type='button'
              onClick={() => {
                setFieldsToEdit({
                  goodFinancialStanding
                })
                setIsEditing(false)
              }}
              data-testid='cancel-button'
            >
            Cancel
            </ButtonAsLink>
            <PrimaryButton
              small
              type='button'
              onClick={() => { handleSave() }}
              data-testid='save-button'
            >
            Save
            </PrimaryButton>
          </ButtonsContainer>
        ) : (
          <ButtonAsLink
            type='button'
            onClick={() => setIsEditing(true)}
            data-testid='edit-button'
          >
          Edit
          </ButtonAsLink>
        )}
      </CardHeader>
      <DetailsContainer>
        <RowGroup width='335px' justifyContent='space-between'>
          <DetailRow>
            <DetailLabel>Full name</DetailLabel>
            <DetailValue>{fullName}</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailLabel>Email</DetailLabel>
            <DetailValue>{email}</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailLabel>OutlierID</DetailLabel>
            <DetailValue>{outlierId}</DetailValue>
          </DetailRow>
        </RowGroup>
        <RowGroup width='335px' justifyContent='space-between'>
          <DetailRow>
            <DetailLabel>GGU email</DetailLabel>
            <DetailValue>{gguEmail}</DetailValue>
          </DetailRow>
          <DetailRow>
            <DetailLabel>GGU ID</DetailLabel>
            <DetailValue>{gguStudentId}</DetailValue>
          </DetailRow>
          <DetailRow style={{ marginTop: '12px' }}>
            <DetailLabel>
              Clear to register?
              <ToolTipContainer>
                <img src={iconTooltip} alt='tooltip-icon' />
                <ToolTip>
                  Clear to register = good financial standing
                </ToolTip>
              </ToolTipContainer>
            </DetailLabel>
            {isEditing ? (
              getFieldInput({
                field: editableFields[0],
                value: fieldsToEdit,
                setValue: setFieldsToEdit
              })
            ) : (
              <DetailValue>{goodFinancialStanding || '--'}</DetailValue>
            )}
          </DetailRow>
        </RowGroup>
      </DetailsContainer>
    </DetailsCard>
  )
}

StudentDetails.displayName = 'StudentDetails'
export default StudentDetails

import React from 'react'
import PropTypes from 'prop-types'
import {
  useStudentGradeContext
} from '../../contexts/StudentGradeContext'
import './grade-report.css'
import GradeTable from './GradeTable'
import {
  filterGoogleDataAnalyticsIChapters,
  jsonToTableData
} from '../../utilities/gradeReportUtils'

const GradeReport = props => {
  const {
    currentCourse,
    currentCohort,
    startingGradeRange,
    searchedEmails,
    endingGradeRange,
    maxParticipationGrade
  } = useStudentGradeContext()
  const onUserChange = user => user && props.onUserSelect(user)

  const {
    chapters,
    grade,
    isCohortEnd
  } = props
  const { id: courseId } = currentCourse
  const { dateStart } = currentCohort

  const courseGradeInfo = {
    grade,
    isCohortEnd
  }

  const filteredChapters = filterGoogleDataAnalyticsIChapters(
    chapters, courseId, dateStart
  )

  const { headerData, rowData } = jsonToTableData({
    courseGradeInfo,
    chapters: filteredChapters,
    maxParticipationGrade
  })

  if (!courseId || !headerData || !rowData) {
    return <p>Oops. No Data Available for the table</p>
  }

  const headerColumn = headerData.map(item => {
    return {
      prop: item,
      Header: item,
      accessor: item,
      sortType: 'alphanumeric'
    }
  })

  const rows = rowData.map(row => {
    const objRowData = {}
    headerColumn.forEach((item, index) => {
      objRowData[item.prop] = row[index]
    })
    return objRowData
  })
  return (
    <>
      <div className='table-wrapper'>
        <GradeTable
          onUserChange={onUserChange}
          columns={headerColumn}
          data={rows}
          startingGradeRange={startingGradeRange}
          searchedEmails={searchedEmails}
          endingGradeRange={endingGradeRange}
          currentCohort={currentCohort}
          chapters={filteredChapters}
        />
      </div>
    </>
  )
}

GradeReport.propTypes = {
  chapters: PropTypes.array.isRequired,
  grade: PropTypes.array,
  onUserSelect: PropTypes.func.isRequired
}

export default GradeReport

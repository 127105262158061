import React, { useEffect, useRef, useState } from 'react'
import {
  AddUserButton,
  HeaderSection,
  PageWrapper,
  PageDescription
} from './styles/index.styled'
import { ReactComponent as AddIcon } from '../../assets/icons/plus-sign.svg'
import { PageTitle } from '../../styles/text'
import { Separator } from '../../styles'
import TabComponent from './TabComponent'
import UsersTable from './UsersTable'
import api from '../../api'
import RolesTable from './RolesTable'
import AddUserModal from './AddUserModal'
import EditUserPermissions from './EditUserPermissions'
import Toast from '../../Components/ToastComponent/Toast'
import { SuccessIcon } from '../../Components/AdminStudentDrop/styled'

const PermissionsManagerV2 = () => {
  const toast = useRef()
  const [activeTab, setActiveTab] = useState('users')
  const [users, setUsers] = useState([])
  const [roles, setRoles] = useState([])
  const [showAddUserModal, setShowAddUserModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [updateUserStatus, setUpdateUserStatus] = useState(null)

  useEffect(() => {
    const fetchUsers = async () => {
      const { data } = await api.getAllAdmins()
      setUsers(data)
    }
    fetchUsers()
  }, [])

  useEffect(() => {
    const fetchRoles = async () => {
      const { data } = await api.getRolesAndDescriptions()
      setRoles(data)
    }
    fetchRoles()
  }, [])

  useEffect(() => {
    if (!updateUserStatus) return
    const refetchUsers = async () => {
      const { data } = await api.getAllAdmins()
      setUsers(data)
      const { display } = toast.current || {}
      display && display(<><SuccessIcon /> Changes saved!</>)
      setUpdateUserStatus(null)
    }

    if (updateUserStatus === 'saved') {
      refetchUsers()
    }
  }, [updateUserStatus])

  const handleAddUser = () => {
    setShowAddUserModal(true)
  }

  const handleDeleteUser = async () => {
    const response = await api.deleteAdmin(selectedUser.email)
    setSelectedUser(null)
    if (response.success) {
      const { data } = await api.getAllAdmins()
      setUsers(data)
    }
    const { display } = toast.current || {}
    display && display(
      response?.success
        ? <><SuccessIcon /> Changes saved!</>
        : <>Error adding user</>
    )
  }

  if (selectedUser) {
    return <EditUserPermissions
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
      handleDeleteUser={handleDeleteUser}
      handleUpdateUser={handleDeleteUser}
      setUpdateUserStatus={setUpdateUserStatus}
      roles={roles}
      setUsers={setUsers}
    />
  }

  return (
    <PageWrapper>
      <Toast toast={toast} />
      <AddUserModal
        roles={roles}
        showAddUserModal={showAddUserModal}
        setShowAddUserModal={setShowAddUserModal}
      />
      <HeaderSection>
        <PageTitle>Permissions manager</PageTitle>
        <AddUserButton onClick={handleAddUser}>
          <AddIcon /> Add user
        </AddUserButton>
      </HeaderSection>
      <Separator />
      <PageDescription>
    Roles and permissions that allow you to specify which tools every team
    member can access. Not seeing a tool you need? Contact your manager for
    access.
      </PageDescription>
      <TabComponent activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 'users'
        ? <UsersTable users={users} setSelectedUser={setSelectedUser} />
        : <RolesTable
          setSelectedUser={setSelectedUser}
          setUsers={setUsers}
          data-testid='roles-tab' roles={roles}
        />}
    </PageWrapper>)
}

export default PermissionsManagerV2

import pick from 'lodash/pick'
import { getDateString } from '../../utilities/dateTimeUtil'

export const STUDENT_KEYS = ['studentId', 'studentName', 'studentEmail']
export const VOUCHER_KEYS = [
  'voucherStatus',
  'dropDeadline',
  'courseName',
  'certificateName',
  'voucherId'
]

export const getStudentDetails = (data) => pick(data, STUDENT_KEYS)

export const getVoucherDetails = ({
  dropDeadline,
  dropNotificationDate,
  ...data
}) => ({
  ...pick(data, VOUCHER_KEYS),
  dropNotificationDate: dropNotificationDate
    ? getDateString(dropNotificationDate)
    : 'No',
  dropDeadline: getDateString(dropDeadline)
})

export const getAmazonVoucherEvent = (event, studentID) => {
  return {
    event,
    properties: {
      studentID,
      time_stamp: Date.now()
    }
  }
}

import React, { useState, useEffect } from 'react'
import {
  ReferenceLine, LineChart, Line, XAxis, YAxis, Tooltip, Text, ResponsiveContainer
} from 'recharts'
import PropTypes from 'prop-types'
import { useStudentGradeContext } from '../../contexts/StudentGradeContext'
import {
  sectionNameToShortCode, getMedianGradeForSection
} from '../../utilities/gradeUtils'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import Button from '../Button/Button'
import api from '../../api'
import './grade-report-chart.css'
import StudentStatus from '../StudentStatus/StudentStatus'
import FinalGradeWidget from '../FinalGradeWidget'
import StudentAttendance from '../StudentAttendance/StudentAttendance'
import { getDaysDifference } from '../../../src/utilities/dateTimeUtil'
import { isCohortCompleted } from '../../utilities/courseUtils'
import {
  getGradeReport,
  getGradeKeys,
  filterGoogleDataAnalyticsIChapters
} from '../../utilities/gradeReportUtils'
import { getAdditionalCourseId } from '../StudentGradeReport/util'

export const getCohortTotalDays = (duration, remainingCohortDays) => {
  return (duration) - remainingCohortDays
}

const GradeReportChart = props => {
  const {
    cohort,
    hasHistory,
    onNotesModalShow,
    selectedCourse,
    selectedCohort,
    selectedUser
  } = props
  const { id: courseId, name: courseName } = selectedCourse
  const { at_id: cohortId } = cohort || {}
  const {
    sectionOverviewData,
    gradeData: { id: studentId, studentStatus },
    selectedStudentCohort
  } = useStudentGradeContext()
  const [graphData, setGraphData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [cohortDuration, setCohortDuration] = useState(0)
  const [remainingCohortDays, setRemainingCohortDays] = useState(0)
  const [attendedDays, setAttendedDays] = useState(null)
  const preferredName = selectedUser.name && selectedUser.name.split(' ')[0]

  useEffect(() => {
    if (!sectionOverviewData?.length) return
    const courseID = getAdditionalCourseId(courseId, selectedStudentCohort)
    fetchGrade(courseID, selectedCohort)
    fetchStudentActivity(studentId, courseID, cohortId)
    getCohortDetails()
    // eslint-disable-next-line
  }, [sectionOverviewData])

  const fetchStudentActivity = async (studentId, courseId, cohortId) => {
    const data = await api.getStudentActivity(studentId, courseId, cohortId)
    setAttendedDays(data['student-attendance-days'])
  }

  const getCohortDetails = () => {
    const { dateStart, finalExamEndTime } = selectedStudentCohort
    const totalDays = getDaysDifference(dateStart, finalExamEndTime)
    const isCohortEnded = isCohortCompleted(selectedStudentCohort, new Date())
    setCohortDuration(totalDays)

    if (isCohortEnded) return

    const remainingCohortDays = getDaysDifference(Date.now(), finalExamEndTime)
    setRemainingCohortDays(remainingCohortDays)
  }

  const fetchGrade = async (courseId, cohortName) => {
    setIsLoading(true)
    const [gradeReport, courseData] = await Promise.all([
      getGradeReport({
        courseId,
        cohortId,
        studentEmail: selectedUser.email,
        queryParams: `cohortName=${encodeURIComponent(cohortName)}`
      }),
      api.getCourseData(courseId)
    ])
    const useGradeData = sectionOverviewData.find(data => data.email === selectedUser.email)
    useGradeData && setFormattedChartData(useGradeData, gradeReport, courseData)
    setIsLoading(false)
  }

  const setFormattedChartData = (userGradeData, gradeReport, courseData) => {
    const { chapters } = courseData
    const { dateStart } = cohort
    const filteredChapters = filterGoogleDataAnalyticsIChapters(chapters, courseId, dateStart)
    const gradeKeys = getGradeKeys(userGradeData, filteredChapters)

    const userGrades = gradeReport.find(data => data.email === selectedUser.email) || {}
    const graphData = gradeKeys.map(key => {
      return ({
        name: sectionNameToShortCode(key, filteredChapters),
        grade: userGrades[key] || 0,
        medianGrade: getMedianGradeForSection(gradeReport, key)
      })
    }).filter(({ grade }) => !isNaN(grade))

    setGraphData(graphData)
  }

  return (
    <div className='grade-report-chart'>
      {
        isLoading ? <LoadingSpinner /> : (
          <>
            <div className='grade-report-chart-header'>
              <p>
                {selectedCohort}
                {hasHistory && <Button
                  className={'btn-custom btn-tertiary'}
                  onClick={onNotesModalShow}
                >
                  VIEW CHANGE NOTES
                </Button>}
              </p>
            </div>
            <div className='information-card-container'>
              {studentStatus && (
                <StudentStatus
                  preferredName={preferredName}
                  studentStatus={studentStatus}
                  courseName={courseName}
                  studentEmail={selectedUser.email}
                  selectedStudentCohort={selectedStudentCohort}
                  cohortId={cohortId}
                />
              )}
              <StudentAttendance
                attendedDays={attendedDays || 0}
                totalDays={
                  getCohortTotalDays(
                    cohortDuration,
                    remainingCohortDays
                  )
                }
              />
              <FinalGradeWidget cohort={selectedCohort} />
            </div>
            <div className='grade-report-chart-content'>
              <ResponsiveContainer key={`${selectedUser.email}`} width='100%' height={300}>
                <LineChart key={`${selectedUser.email}`} data={graphData}
                  margin={{ top: 10, right: 30, left: 20, bottom: 40 }}>
                  <XAxis
                    dataKey='name'
                    tick={{ fill: '#d3d3d3', fontSize: 15 }}
                  />
                  <YAxis
                    type='number'
                    ticks={[0, 25, 50, 75, 100]}
                    domain={[0, 100]}
                    tick={{ fill: '#d3d3d3', fontSize: 15 }}
                    label={
                      <Text
                        style={{ fill: '#d3d3d3', fontSize: '130%' }}
                        x={0}
                        y={0}
                        dx={25}
                        dy={150}
                        offset={0}
                        angle={-90}
                      >Grade</Text>
                    }
                  />
                  <Tooltip cursor={false} content={<CustomTooltip />} />
                  <Line
                    dataKey='grade'
                    strokeWidth={3}
                    fill='#77BBB5'
                    stroke='#77BBB5'
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    dataKey='medianGrade'
                    strokeWidth={1}
                    fill='#808080'
                    stroke='#808080'
                    activeDot={{ r: 8 }}
                  />
                  <ReferenceLine
                    strokeDasharray='5 5'
                    x='Mid 1'
                    stroke='#d3d3d3'
                  />
                  <ReferenceLine
                    strokeDasharray='5 5'
                    x='Mid 2'
                    stroke='#d3d3d3'
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <div className='grade-report-bottom-bar'>
              <div className='grade-report-color-code'>
                <div className='grade-report-color-code-student'>
                  <p className='grade-report-color-code-title'> Student</p>
                  <div className='grade-report-color-code-box brand-teal' />
                </div>
                <div className='grade-report-color-code-cohort-median'>
                  <p className='grade-report-color-code-title'>Cohort Median</p>
                  <div className='grade-report-color-code-box brand-grey' />
                </div>
              </div>
              <p className='quiz-exam-text'>Quiz/Exam</p>
            </div>
          </>
        )
      }
    </div>
  )
}

const CustomTooltip = ({ active, payload, label }) => {
  if (!active || !payload) return null
  const exams = ['Mid 1', 'Mid 2', 'Final']
  const newLabel = exams.includes(label) ? label : `Section: ${label}`
  return (
    <div className='grade-report-chart-tooltip'>
      <p className='grade-report-chart-tooltip-label'>{newLabel}</p>
      <p className='grade-report-chart-tooltip-value'>
        Grade: {payload[0].value}
      </p>
      <p className='grade-report-chart-tooltip-value'>
        Median: {payload[1] ? payload[1].value : 0}
      </p>
    </div>
  )
}

GradeReportChart.propTypes = {
  selectedCourse: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  selectedCohort: PropTypes.string.isRequired,
  cohort: PropTypes.object.isRequired,
  onNotesModalShow: PropTypes.func.isRequired
}

export default GradeReportChart

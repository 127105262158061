import React from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import PropTypes from 'prop-types'
import { ConfirmationModalWrapper, ConfirmationModalText } from './styled'
import {
  ButtonWrapper,
  PrimaryButton,
  SecondaryButton
} from './styled/AssessmentModalStyles'

function EditConfirmationModal (
  { showModalHandler,
    show,
    showParentModalHandler,
    showAncestorModalHandler,
    handleEditAssessmentModal,
    editData,
    title,
    isUpdating,
    name }
) {
  const { to, from } = editData

  const handleModalClose = () => {
    showModalHandler()
    showParentModalHandler()
    showAncestorModalHandler && showAncestorModalHandler()
  }

  return (
    <ModalComponent show={show} handleClose={showModalHandler}>
      <ConfirmationModalWrapper>
        <ConfirmationModalText>
          Are you sure you want to change {name}’s {title} grade
          from <span>{!isNaN(from) ? `${from}%` : 'N/A'}</span> to <span>{`${to}`}</span>%?
        </ConfirmationModalText>
        <ButtonWrapper top={'0px'} bottom={'42px'}>
          <PrimaryButton
            width={'120px'}
            className='btn-custom btn-primary button'
            disabled={isUpdating}
            onClick={handleEditAssessmentModal}
          >
            {isUpdating ? <i className='fa fa-spinner fa-spin' /> : 'Confirm'}
          </PrimaryButton>
          <SecondaryButton
            data-testId='close-confirmation-modal'
            className='btn-custom btn-secondary button'
            onClick={handleModalClose}
          >
            Cancel
          </SecondaryButton>
        </ButtonWrapper>
      </ConfirmationModalWrapper>
    </ModalComponent>
  )
}

EditConfirmationModal.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

export default EditConfirmationModal

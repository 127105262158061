import React, { useRef, useState } from 'react'
import api from '../../api'
import { GUILD_CSV_TYPES, PAGES } from './constants'
import { checkIfELTFile } from './utils'
import { ToolTip } from '../AdminStudentDrop/styled'
import FileUploader from '../FileUploader/FileUploader'
import {
  BackLink, BackArrowIcon, PageContainer, PageHeader, PageIndicator, Separator,
  PageText, InfoIcon, SubmitButton, UploaderContainer, ErrorMessage, WarningIcon,
  Modal, ModalHeader, SuccessIcon, CloseIcon
} from './style'

function ELTUpload ({ setCurrentPage, refetchELTFiles }) {
  const [file, setFile] = useState(undefined)
  const [fileName, setFileName] = useState(undefined)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const infoRef = useRef()

  const onBackClick = () => {
    setCurrentPage(PAGES.main)
  }

  const onFileSelect = (fileObj) => {
    setShowModal(false)
    setIsError(false)
    setFile(fileObj)
    setFileName(fileObj?.name)
  }

  const onUnsupportedFormat = () => {
    setIsError(true)
    setIsLoading(false)
    setErrorMessage(`Please ensure that you're uploading an ELT file and that it's in .csv format.`)
    setFile(undefined)
    setFileName(undefined)
  }

  const onFileUploadFailure = (message, statusCode) => {
    const failureMessage = <div>
      {statusCode && (<div>Error {statusCode}: {message}</div>)}
      <div>The records might have been created despite the error. Do not retry the upload without checking the records.</div>
    </div>

    setIsError(true)
    setIsLoading(false)
    setErrorMessage(failureMessage)
    setFile(undefined)
    setFileName(undefined)
  }

  const uploadELTFile = async (file) => {
    const formData = new FormData()
    formData.append('guild-students', file)
    const { message, statusCode } = await api.uploadELTCSV(
      formData,
      GUILD_CSV_TYPES.PATHWAYS_ELT
    )
    return { message, statusCode }
  }

  const onSubmitClick = async () => {
    setIsLoading(true)

    try {
      const isELTFile = await checkIfELTFile(file)
      if (!isELTFile) return onUnsupportedFormat()

      const { message, statusCode } = await uploadELTFile(file)
      if (message) return onFileUploadFailure(message, statusCode)

      setIsLoading(false)
      setShowModal(true)
      setFile(undefined)
      refetchELTFiles()
    } catch (e) {
      console.error('Error uploading the ELT file', e.message)
      onFileUploadFailure(e.message, e.status)
    }
  }

  return (
    <PageContainer>
      <BackLink
        onClick={onBackClick}
      >
        <BackArrowIcon />
        go back
      </BackLink>

      <PageHeader>Guild ELT Upload</PageHeader>
      <Separator margin='24px 0' />

      <PageIndicator>
        <span>1</span> upload ELT
      </PageIndicator>

      <PageText>
        Upload the Guild Eligibility / Lead Transfer file
        <InfoIcon ref={infoRef} />
        <ToolTip
          target={infoRef}
          placement='right'
          arrowClassName='tooltip-arrow'
          innerClassName='tooltip-content'
        >
          Please upload one ELT file at a time. Ensure that the file format is .csv.
        </ToolTip>
      </PageText>

      <UploaderContainer>
        <FileUploader
          isSmallInput
          uploadedContent={file}
          onFileSelect={onFileSelect}
          onEmpty={() => setFile(undefined)}
          onUnsupportedFormat={onUnsupportedFormat}
        />

        {isError && !isLoading && (
          <ErrorMessage>
            <WarningIcon />
            {errorMessage}
          </ErrorMessage>
        )}
      </UploaderContainer>

      <SubmitButton
        disabled={!file || isLoading}
        onClick={onSubmitClick}
      >
        {isLoading
          ? <i className='fa fa-spinner fa-spin' />
          : 'submit'
        }
      </SubmitButton>

      {showModal && (
        <Modal>
          <ModalHeader>
            <SuccessIcon />
            <p>{fileName} uploaded successfully!</p>
          </ModalHeader>
          <CloseIcon
            onClick={() => setShowModal(false)}
          />
        </Modal>
      )}
    </PageContainer>
  )
}

ELTUpload.displayName = 'ELTUpload'
export default ELTUpload

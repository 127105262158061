import {
  getCourseOptions,
  getPartnerPresetOptions,
  getStudentGradePresetOptions,
  getUsePresetOptions,
  getEnrollmentStatusOptions,
  getStudentGradesOptions,
  getOtherInformationOptions,
  getTermLenthOptions,
  getStudentIndentificationOptions,
  getStudentContactOptions,
  getOtherStudentInfoOptions,
  getCohortInfoOptions,
  getGradeDetailOptions,
  getCreateCSVForCoursesOptions,
  getRelationshipsOptions,
  getLoginActivityOptions,
  getCreditGrantingInstitutionsOptions
} from '../utilities/CSVDownloadToolV2'

import { EXPOSE_KEYS } from './CSVDownloadToolV2'

export const COURSE_SELECTION = {
  label: 'Courses',
  exposeKey: EXPOSE_KEYS.COURSES,
  options: [],
  getOptions: getCourseOptions,
  type: 'multiselect'
}

const TERM_LENGTH_SELECTION = {
  label: 'Term Lengths',
  exposeKey: EXPOSE_KEYS.TERM_LENGTHS,
  options: getTermLenthOptions()
}

const START_DATE_RANGE_SELECTION = {
  label: 'cohort start date range',
  description: 'Cohorts starting within this range will be included.',
  exposeKey: EXPOSE_KEYS.COHORT_START_RANGE
}

const END_DATE_RANGE_SELECTION = {
  label: 'cohort end date range',
  description: 'Cohorts ending within this range will be included.',
  exposeKey: EXPOSE_KEYS.COHORT_END_RANGE
}

export const USE_PRESET_SELECTION = {
  headerText: 'Would you like to use a preset?',
  exposeKey: EXPOSE_KEYS.USE_PRESET,
  options: getUsePresetOptions()
}

export const PARTNER_PRESET_SELECTION = {
  headerText: 'Partner Presets',
  exposeKey: EXPOSE_KEYS.SELECTED_PRESET,
  options: getPartnerPresetOptions()
}

export const STUDENT_GRADES_PRESET_SELECTION = {
  headerText: 'Student Grade Presets',
  exposeKey: EXPOSE_KEYS.SELECTED_PRESET,
  options: getStudentGradePresetOptions()
}

export const ENROLLMENT_STATUS_SELECTION = {
  label: 'Enrollment Statuses',
  exposeKey: EXPOSE_KEYS.STUDENT_STATUS,
  options: [],
  getOptions: getEnrollmentStatusOptions,
  type: 'multiselect'
}

export const RELATIONSHIPS_SELECTION = {
  label: 'Relationships',
  exposeKey: EXPOSE_KEYS.RELATIONSHIPS,
  options: [],
  getOptions: getRelationshipsOptions,
  type: 'multiselect'
}

export const CREDIT_GRANTING_INSTITUTIONS_SELECTION = {
  label: 'Credit Granting Institution',
  exposeKey: EXPOSE_KEYS.CREDIT_GRANTING_INSTITUTIONS,
  options: [],
  getOptions: getCreditGrantingInstitutionsOptions
}

export const STUDENT_GRADES_SELECTION = {
  label: 'Student Grades - Optional',
  exposeKey: EXPOSE_KEYS.STUDENT_GRADES,
  options: getStudentGradesOptions()
}

export const OTHER_INFORMATION_SELECTION = {
  label: 'Other information - Optional',
  exposeKey: EXPOSE_KEYS.OTHER_INFORMATION,
  options: getOtherInformationOptions()
}

export const STUDENT_IDENTIFICATION_DETAILS_SELECTION = {
  label: 'Student Identification Details',
  exposeKey: EXPOSE_KEYS.STUDENT_IDENTIFICATION,
  options: getStudentIndentificationOptions()
}

export const STUDENT_CONTACT_INFO_SELECTION = {
  label: 'Student Contact Information',
  exposeKey: EXPOSE_KEYS.STUDENT_CONTACT_INFO,
  options: getStudentContactOptions()
}

export const OTHER_STUDENT_INFORMATION_SELECTION = {
  label: 'Other Student Information',
  exposeKey: EXPOSE_KEYS.OTHER_STUDENT_INFO,
  options: getOtherStudentInfoOptions()
}

export const COHORT_INFORMATION_SELECTION = {
  label: 'Cohort Information',
  exposeKey: EXPOSE_KEYS.COHORT_INFO,
  options: getCohortInfoOptions()
}

export const STUDENT_LOGIN_ACTIVITY_SELECTION = {
  label: 'Student Login Activity',
  exposeKey: EXPOSE_KEYS.LOGIN_ACTIVITY,
  options: getLoginActivityOptions()
}

export const GRADE_DETAILS_SELECTION = {
  label: 'Grade Details',
  exposeKey: EXPOSE_KEYS.GRADE_DETAILS,
  options: getGradeDetailOptions()
}

export const CREATE_CSV_FOR_EACH_COURSE_SELECTION = {
  label: 'Create ZIP?',
  exposeKey: EXPOSE_KEYS.CREATE_ZIP,
  options: getCreateCSVForCoursesOptions(),
  showButtons: false
}

export const PRESET_SELECTION = [
  PARTNER_PRESET_SELECTION,
  STUDENT_GRADES_PRESET_SELECTION
]

export const PRIMARY_CRITERIA_SELECTION = [
  COURSE_SELECTION,
  TERM_LENGTH_SELECTION,
  RELATIONSHIPS_SELECTION,
  CREDIT_GRANTING_INSTITUTIONS_SELECTION
]

export const DATE_RANGE_SELECTION = [
  START_DATE_RANGE_SELECTION,
  END_DATE_RANGE_SELECTION
]

export const MANUAL_CRITERIA_SELECTION = [
  ENROLLMENT_STATUS_SELECTION,
  OTHER_INFORMATION_SELECTION
]

export const EXPORTED_INFORMATION_SELECTION = [
  STUDENT_IDENTIFICATION_DETAILS_SELECTION,
  STUDENT_CONTACT_INFO_SELECTION,
  OTHER_STUDENT_INFORMATION_SELECTION,
  COHORT_INFORMATION_SELECTION,
  STUDENT_LOGIN_ACTIVITY_SELECTION,
  GRADE_DETAILS_SELECTION,
  CREATE_CSV_FOR_EACH_COURSE_SELECTION
]

import React from 'react'
import Header from '../Header/Header'
import './no-access.css'

const NoAccess = () => {
  return (
    <div className='no-access'>
      <Header />
      <div className='no-access-content'>
        <span>
          FORBIDDEN
          <br /><br />
          You don't have permission to access this site.
        </span>
      </div>
    </div>
  )
}

export default NoAccess

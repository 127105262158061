import React from 'react'
import iconTooltip from '../../../assets/icons/icon-tooltip.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg'
import { NO_DISTRICT_ERROR } from '../constants'
import {
  PartnerDetailsContainer,
  PartnerDetailsTitle,
  PartnerDetailsContent,
  PartnerSelectContainer,
  PartnerDetailsSection,
  PartnerDetailsSectionTitle,
  PartnerSelectError,
  PartnerSelect,
  PartnerAboutSection,
  AboutTitle,
  AboutContent,
  ToolTipContainer,
  ToolTip,
  AboutContentListItem,
  Dot
} from './styled'

function PartnerDetails (props) {
  const {
    isLoading,
    relationships,
    selectedDistrict,
    setSelectedDistrict,
    selectedRelationship,
    setSelectedRelationship,
    showError
  } = props

  const relationshipsWithDistrict =
    relationships.filter(relationship => relationship.districtName)
  const relationshipDistricts =
    [...new Set(relationshipsWithDistrict.map(relationship => relationship.districtName))]
  const relationshipDistrictOptions = relationshipDistricts
    .sort((a, b) => a.localeCompare(b))
    .map(district => ({ value: district, label: district }))

  const filteredRelationships = relationships.filter(relationship =>
    (!selectedDistrict?.value || relationship.districtName === selectedDistrict?.value))

  const {
    displayName,
    districtName,
    enrollmentOptions
  } = selectedRelationship || {}

  return (
    <PartnerDetailsContainer>
      <PartnerDetailsTitle>
        Partner details
      </PartnerDetailsTitle>
      <PartnerDetailsContent>
        <PartnerSelectContainer>
          <PartnerDetailsSection>
            <PartnerDetailsSectionTitle>
              District
            </PartnerDetailsSectionTitle>
            {
              showError === NO_DISTRICT_ERROR &&
              <PartnerSelectError>
                <WarningIcon />
                <div>
                  Please select a district
                </div>
              </PartnerSelectError>
            }
            <PartnerSelect
              isClearable
              isLoading={isLoading}
              showError={showError === NO_DISTRICT_ERROR}
              placeholder='Select a district'
              value={selectedDistrict}
              options={relationshipDistrictOptions}
              onChange={district => {
                setSelectedDistrict(district)
                setSelectedRelationship(null)
              }}
            />
          </PartnerDetailsSection>
          <PartnerDetailsSection>
            <PartnerDetailsSectionTitle>
              School
            </PartnerDetailsSectionTitle>
            {
              showError &&
              <PartnerSelectError>
                <WarningIcon />
                <div>
                  Please select a school
                </div>
              </PartnerSelectError>
            }
            <PartnerSelect
              isClearable
              isLoading={isLoading}
              showError={showError}
              placeholder='Select a school'
              value={selectedRelationship}
              options={filteredRelationships}
              onChange={relationship => setSelectedRelationship(relationship)}
            />
          </PartnerDetailsSection>
        </PartnerSelectContainer>
        <PartnerDetailsSection>
          <PartnerDetailsSectionTitle>
            About
          </PartnerDetailsSectionTitle>
          <PartnerAboutSection>
            <div>
              <AboutTitle>
                Name
              </AboutTitle>
              <AboutContent>
                {displayName || '--'}
              </AboutContent>
            </div>
            <div>
              <AboutTitle>
                District Name
              </AboutTitle>
              <AboutContent>
                {districtName || '--'}
              </AboutContent>
            </div>
            <div>
              <AboutTitle>
                Enrollment options
                <ToolTipContainer>
                  <img
                    src={iconTooltip}
                    alt='icon-tooltip'
                  />
                  <ToolTip>
                    These are the enrollment options available to the partner.
                  </ToolTip>
                </ToolTipContainer>
              </AboutTitle>
              <AboutContent>
                {
                  enrollmentOptions && enrollmentOptions.length > 0
                    ? enrollmentOptions.map((option, index) => (
                      <AboutContentListItem key={index}>
                        <div>
                          {option}
                        </div>
                        {index !== enrollmentOptions.length - 1 && <Dot />}
                      </AboutContentListItem>
                    ))
                    : '--'
                }
              </AboutContent>
            </div>
          </PartnerAboutSection>
        </PartnerDetailsSection>
      </PartnerDetailsContent>
    </PartnerDetailsContainer>
  )
}

export default PartnerDetails

import React from 'react'
import UnselectedBtn from '../../../assets/icons/radio-button-unselected.svg'
import SelectedBtn from '../../../assets/icons/radio-button-selected.svg'
import { VOID_LICENSE_OPTIONS } from '../constants'
import {
  LicenseVoidOptionsSection,
  LicenseVoidOptionsTitle,
  LicenseVoidOption
} from './styled'

function SelectLicense (props) {
  const { selectedOption, setSelectedOption, setTokensToVoid } = props

  return (
    <LicenseVoidOptionsSection>
      <LicenseVoidOptionsTitle>
        How many licenses would you like to void?
      </LicenseVoidOptionsTitle>
      <LicenseVoidOption
        onClick={() => {
          setSelectedOption(VOID_LICENSE_OPTIONS.ALL)
          setTokensToVoid([])
        }}
        selected={selectedOption === VOID_LICENSE_OPTIONS.ALL}
      >
        <img
          src={selectedOption === VOID_LICENSE_OPTIONS.ALL
            ? SelectedBtn : UnselectedBtn}
          alt='ALL'
        />
        All available licenses
      </LicenseVoidOption>
      <LicenseVoidOption
        onClick={() => {
          setSelectedOption(VOID_LICENSE_OPTIONS.BY_COURSE)
          setTokensToVoid([])
        }}
        selected={selectedOption === VOID_LICENSE_OPTIONS.BY_COURSE}
      >
        <img
          src={selectedOption === VOID_LICENSE_OPTIONS.BY_COURSE
            ? SelectedBtn : UnselectedBtn}
          alt='BY_COURSE'
        />
        Enter number by course
      </LicenseVoidOption>
      <LicenseVoidOption
        onClick={() => {
          setSelectedOption(VOID_LICENSE_OPTIONS.BY_DATE)
          setTokensToVoid([])
        }}
        selected={selectedOption === VOID_LICENSE_OPTIONS.BY_DATE}
      >
        <img
          src={selectedOption === VOID_LICENSE_OPTIONS.BY_DATE
            ? SelectedBtn : UnselectedBtn}
          alt='BY_DATE'
        />
        Select licenses expiration date
      </LicenseVoidOption>
    </LicenseVoidOptionsSection>
  )
}

export default SelectLicense

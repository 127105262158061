import styled, { css } from 'styled-components'
import { buttonEventsBehavior } from '../../../../styles/buttons'
import { ReactComponent as SearchButtonSVG } from '../../../../assets/icons/search-icon.svg'
import { ReactComponent as ClearIconSVG } from '../../../../assets/icons/clear-icon.svg'
import { ReactComponent as magnifyIcon } from '../../../../assets/icons/icon-magnify.svg'

export const FiltersSection = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`
export const FiltersHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: baseline;
`
export const FiltersWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 16px;
`
export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const SearchButton = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background: transparent;
  ${buttonEventsBehavior}

  :active {
    opacity: 0.3;
  }

  :focus {
    outline: none;
  }
  :focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
`
export const SearchButtonIcon = styled(SearchButtonSVG)`
  width: 48px;
  height: 48px;
`
export const DarkSearchField = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 24px;
  align-self: flex-end;
  border-radius: 5px;
  border: 1px solid var(--border-secondary, #5b6067);
  width: 250px;
  height: 48px;
  background: var(--black-tertiary, #0d0d0f);
`
export const SearchTextField = styled.input`
  width: 100%;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: inherit;
  border: none;

  &::placeholder {
    color: #5b6067;
  }
  &:focus {
    outline: none;
  }
`
export const SearchIcon = styled(magnifyIcon)``
export const ClearIcon = styled(ClearIconSVG)`
  cursor: pointer;

  path {
    fill: var(--text-dark-off, #98a4ae);
  }

  &:hover {
    path {
      fill: #fff;
    }
  }

  &:active {
    opacity: 0.3;
  }
`
export const TableDescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--border-secondary);
  margin-bottom: 16px;
`
export const TableTitle = styled.h2`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  display: flex;
  margin: 0;
  align-items: center;
  gap: 8px;
  align-self: stretch;
`
export const TableDescription = styled.p`
  color: var(--text-dark-off);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
`
export const ToolDescription = styled.p`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin: 24px 0;
`
export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 32px;
`
export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: ${({ isDefault }) =>
    isDefault ? '1fr 1.5fr 2.5fr' : '1fr 1.5fr 2.5fr 16px'};
  grid-gap: 16px;
  overflow-wrap: anywhere;
  padding: 8px 12px 12px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const HeaderItem = styled.span`
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`
export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr 2.5fr;
  grid-gap: 16px;
  padding: 12px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  ${({ isDefault }) =>
    !isDefault &&
    css`
      grid-template-columns: 1fr 1.5fr 2.5fr 16px;
      cursor: pointer;
      &:hover {
        background-color: var(--black-secondary);
      }
    `}
`
export const Cell = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-primary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
`
export const PlainCell = styled.span`
  height: 24px;
  border-radius: 5px;
  background: var(--black-secondary);
`

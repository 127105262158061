import styled from 'styled-components'

export const PaginationBarContainer = styled.div`
  display: flex;
  width: 100%;
  background: #1D1D1F;
  padding: 16px;
`
export const PaginationGroup = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
`

export const PaginationIcon = styled.img`
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin: 4px 0;
  ${props => props.disabled && `display: none`}
`

export const PaginationNumber = styled.div`
  color: var(--brand-regular);
  cursor: pointer;
  padding: 0 8px;
  ${props => props.hidden && `display: none;`}
  ${props => props.disabled && `
  color: var(--white);
  cursor: default;
  `}
`

export const PaginationInfo = styled.div`
  margin-left: auto;
`

export const PaginationInfoText = styled.div`
  color: var(--text-secondary);
  display: inline-block;
  vertical-align: middle;
`

import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../ModalComponent/ModalComponent'
import {
  ModalContent,
  RequestDetail,
  ButtonsContainer,
  PrimaryButton,
  SecondaryButton
} from './styled'
import {
  DECISION_APPROVED,
  DECISION_DENIED
} from '../../Constants'

function DecisionConfirmationModal ({
  show,
  setShowConfirmationModal,
  recommendedActionHandler,
  denyRequest
}) {
  const confirmHandler = async () => {
    setShowConfirmationModal(false)
    await recommendedActionHandler(
      denyRequest ? DECISION_DENIED : DECISION_APPROVED
    )
  }

  const cancelHandler = () => {
    setShowConfirmationModal(false)
  }

  return (
    <Modal show={show}>
      <ModalContent>
        <div>
          <RequestDetail {...(denyRequest && { decisionColor: 'var(--error-color)' })}>
            Are you sure you want to
            <span>
              <strong>{denyRequest ? ' Deny' : ' Approve'}</strong>
            </span> this request?
          </RequestDetail>
        </div>
        <ButtonsContainer>
          <PrimaryButton
            data-testid='btn-recommended'
            onClick={confirmHandler}
          >
            confirm
          </PrimaryButton>
          <SecondaryButton
            data-testid='btn-not-recommended'
            onClick={cancelHandler}
          >
            cancel
          </SecondaryButton>
        </ButtonsContainer>
      </ModalContent>
    </Modal>
  )
}

DecisionConfirmationModal.displayName = 'DecisionConfirmationModal'

DecisionConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowConfirmationModal: PropTypes.func,
  recommendedActionHandler: PropTypes.func,
  denyRequest: PropTypes.bool
}

export default DecisionConfirmationModal

import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import CustomSelect from '../../../Components/CustomSelect/UpdatedSelect'
import { Input } from 'reactstrap'
import { ReactComponent as Clear } from '../../../assets/icons/clear-icon.svg'
import {
  PrimaryButton,
  SecondaryButton
} from '../../../Components/global.styled'

export const Container = styled.div`
  padding: 0 24px;
`
export const GoToPreviousPageButtonWrapper = styled.div`
  padding: 16px 0;
  width: fit-content;
`
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 12px;
`
export const StudentName = styled.h2`
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
  margin: 0;
`
export const ProspectId = styled.span`
  color: var(--text-dark-off, #98a4ae);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
export const Separator = styled.hr`
  margin: 24px 0;
  height: 0;
  width: 100%;
  border: 0.5px solid var(--border-secondary);
`
export const CardsContainer = styled.div`
  --groups-gap: 12px;
  display: flex;
  flex-direction: column;
  gap: var(--groups-gap);
`
export const DetailsCard = styled.div`
  display: flex;
  flex: 1;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--border-secondary, #5b6067);
`
export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
`
export const CardTitle = styled.h3`
  color: #fff;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  margin: 0;
`
export const DetailRow = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => columns || '1fr 3fr'};
  gap: 16px;
  align-items: baseline;

  ${mediaqueries.mobile`
    width: 100%;
    max-width: 100%;
    grid-template-columns: 145px 3fr;
  `}

  ${mediaqueries.smallMobile`
    grid-template-columns: 1fr;
    gap: 6px;
  `}
`
export const DetailLabel = styled.span`
  display: flex;
  align-items: center;
  color: var(--sub-text-secondary, #98a4ae);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: max-content;
`
export const DetailValue = styled.span`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;
`
export const RowGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap || '8px'};
  flex: ${({ width }) => (width ? `1 1 ${width}` : '1')};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  width: ${({ width }) => width || '100%'};
  max-width: ${({ width }) => width || '100%'};

  ${mediaqueries.mobile`
    flex: 1 1 100%;
    max-width: 100%;
  `}

  ${mediaqueries.smallMobile`
    gap: 12px
  `}
`
export const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-content: flex-start;
  row-gap: 12px;
  column-gap: 25px;
  width: ${({ width }) => width || '100%'};
  align-items: baseline;
  max-width: fit-content;
  flex: ${({ flex }) => flex || '1'};

  ${mediaqueries.mobile`
    width: 100%;
    max-width: 100%;
    grid-template-columns: 145px 3fr;
  `}

  ${({ wrapChildren }) =>
    wrapChildren &&
    mediaqueries.smallMobile`
    grid-template-columns: 1fr;
    row-gap: 6px;
    & > :not(:first-child):nth-child(odd) {
     margin-top: 6px;
    }
  `}
`
export const DetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 64px;
  row-gap: 32px;
  width: 100%;
`
export const ToolTipContainer = styled.div`
  margin-left: 8px;
  position: relative;
  display: flex;
  &:hover {
    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`
export const ToolTip = styled.span`
  visibility: hidden;
  width: 255px;
  height: 72px;
  padding: 12px;
  position: absolute;
  left: 70px;
  line-height: 24px;
  color: #ffffff;
  text-transform: none;
  font-size: 16px;
  font-weight: normal;
  background-color: #25272b;
  top: 50%;
  transform: translateY(-50%);
  letter-spacing: normal;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);

  ${mediaqueries.smallMobile`
    left: 25px;
  `}
`
export const CardGroup = styled.div`
  display: flex;
  gap: var(--groups-gap);

  ${mediaqueries.mobile`
    flex-direction: column;
  `}
`
export const ButtonAsLink = styled.button`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  padding: 0;
  color: var(--brand-regular);
  &:hover {
    color: var(--brand-regular-hover);
  }
  &:active {
    opacity: 0.3;
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:disabled:hover {
    color: var(--brand-regular);
  }
`
export const DateString = styled.span`
  display: flex;
  align-items: center;
  gap: 16px;
  color: #98a4ae;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
export const IconContainer = styled.span`
  display: flex;
  height: 16px;
  width: 16px;
  color: #969bff;
`
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;
`
export const Select = styled(CustomSelect)`
  min-height: 43px;
  width: 100%;
  max-width: 300px;
  & .select__control {
    min-height: 43px;
    height: 100%;
  }
`
export const asyncSelectStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  control: (base, state) => ({
    ...base,
    width: state.selectProps.width || 'auto',
    maxWidth: state.selectProps.width || '300px',
    height: state.selectProps.height || '43px',
    background: 'var(--black-tertiary)',
    color: 'var(--text-primary)',
    border: '1px solid var(--border-secondary)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    padding: '0 12px 0 16px',
    '&:hover': {
      border: '1px solid var(--white)'
    },
    '&:focus-within': {
      borderColor: 'var(--white)'
    }
  }),
  menu: (base, state) => ({
    ...base,
    width: state.selectProps.width || '100%',
    maxWidth: state.selectProps.width || '300px',
    height: 'auto',
    zIndex: 1000,
    background: '#25272b',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    marginTop: '12px',
    isolation: 'isolate'
  }),
  menuList: (base) => ({
    ...base,
    width: '100%',
    height: '100%',
    padding: '8px 0',
    borderRadius: '5px'
  }),
  option: (base, state) => ({
    ...base,
    padding: '8px 15px',
    width: '100%',
    backgroundColor: '#25272B',
    color: state.isSelected ? 'var(--white)' : 'var(--sub-text-secondary)',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: 'normal',
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid var(--brand-regular)',
      borderRadius: '5px'
    },
    '&:active': {
      background: '#25272b'
    }
  }),
  placeholder: (base) => ({
    ...base,
    color: 'var(--border-secondary)',
    fontSize: '16px',
    lineHeight: '22px'
  }),
  valueContainer: (base) => ({
    ...base,
    paddingLeft: 0
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--white)',
    margin: 0,
    fontSize: '16px',
    lineHeight: '22px'
  }),
  multiValue: () => ({
    display: 'none'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: 'var(--white)',
    padding: 0,
    '&:hover': {
      color: 'var(--white)'
    }
  }),
  indicatorContainer: (base) => ({
    ...base,
    paddingLeft: 0
  }),
  input: (base) => ({
    ...base,
    color: 'var(--text-primary)',
    height: 'auto'
  })
}
export const FormInput = styled(Input)`
  background: var(--black-tertiary);
  border: 1px solid var(--border-primary);
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
  padding-right: 40px;
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  height: 43px;
  width: 100%;
  max-width: 300px;

  &:hover {
    outline: none;
    background: var(--black-tertiary);
    color: #fff;
    border: 1px solid var(--text-primary);
    box-shadow: none;
  }

  &:focus {
    outline: none;
    color: #fff;
    background: var(--black-tertiary);
    border: 1px solid var(--text-primary);
    box-shadow: none;
  }
`
export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 300px;
`
export const ClearIcon = styled(Clear)`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`

export const CommentsCard = styled.div`
  display: flex;
  flex: 1;
  padding: 24px 5px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 5px 5px 0 0;
  border: 1px solid var(--border-secondary, #5b6067);
`

export const CommentsNote = styled.span`
  color: #CCD4D8;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
`

export const CommentsContainerWrapper = styled.div`
  width: 100%;
  overflow: auto;
  
  & {
    &::-webkit-scrollbar {
      width: 8px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--border-secondary);
      border-radius: 10px;
      visibility: visible;
    }
  }
`

export const CommentsContainer = styled.div`
  height: 220px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const CommentsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CommentItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const CommentItemInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const CommentItemAuthor = styled.span`
  color: #CCD4D8;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
`

export const CommentItemDate = styled.span`
  color: #98A4AE;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px; /* 145.455% */
`

export const CommentItemText = styled.span`
  width: fit-content;
  max-width: 600px;
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #232327;
  border-radius: 5px;
  color: #FFF;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 153.846% */
`

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--border-secondary, #5b6067);
  border-top: none;
  border-radius: 0 0 5px 5px;
`

export const CommentInput = styled(Input)`
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #5B6067;
  background-color: #000000;
  border-radius: 5px;
  border: 1px solid #5B6067;

  &:focus {
    border: 1px solid #FFFFFF;
    background-color: var(--input-primary);
    color: var(--text-primary);
    box-shadow: none;
  }
`

export const CommentButtonContainer = styled.div`
  display: ${props => props.isTextInputFocused ? 'flex' : 'none'};
  gap: 12px;
  padding-top: 16px;
`

export const CommentButton = styled(PrimaryButton)`
  padding: 8px;
`

export const CommentCancelButton = styled(SecondaryButton)`
  border: none;
  background-color: #161618;
  
  &:hover { 
    border: none;
  }
`

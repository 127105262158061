import {
  NoCertDegree,
  StudentCertDegreeContainer,
  StudentCertDegreeNumber,
  StudentCertDegreeText,
  StudentCertHeader
} from './style'
import React from 'react'
import StudentDegreeInfo from './StudentDegreeInfo'

const StudentDegrees = ({ prospectList }) => {
  const validProspects = prospectList.filter(
    ({ applicationSubmitted, admissionDecision }) =>
      applicationSubmitted && !admissionDecision?.includes('Deny')
  )

  const activeDegreesList = validProspects.filter(
    (prospect) => prospect.degreeStatus === 'Active')
  const numberOfActiveDegrees = activeDegreesList.length
  const degreeText = numberOfActiveDegrees === 1 ? 'Degree' : 'Degrees'

  return (
    <StudentCertDegreeContainer>
      <StudentCertHeader>
        <StudentCertDegreeNumber>
          {numberOfActiveDegrees}
        </StudentCertDegreeNumber>
        <StudentCertDegreeText>{degreeText}</StudentCertDegreeText>
      </StudentCertHeader>
      {
        validProspects.length > 0 &&
        validProspects.map((degree, index) => {
          return <StudentDegreeInfo prospect={degree} key={index} />
        })
      }
      {
        validProspects.length === 0
          ? <NoCertDegree>
          This student has no degrees.
          </NoCertDegree>
          : activeDegreesList.length === 0 &&
          <NoCertDegree>
            This student submitted their application, but they do not have an active Degrees+ record.
          </NoCertDegree>
      }
    </StudentCertDegreeContainer>
  )
}
StudentDegrees.displayName = 'StudentDegrees'
export default StudentDegrees

import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import {
  Option,
  OptionCheckBox,
  OptionLabel,
  OptionDescription,
  SingleValue,
  customStyles
} from './styled'

const customOption = ({
  innerRef,
  innerProps,
  children,
  data: { description },
  selectProps: { isTermLength, selectedValue },
  ...rest }) => {
  return (
    <Option
      ref={innerRef}
      {...innerProps}
      isTermLength={isTermLength}
      isSelected={selectedValue?.[rest.value]}
    >
      <OptionCheckBox isChecked={selectedValue?.[rest.value]} />
      <div>
        <OptionLabel>{children }</OptionLabel>
        {description && <OptionDescription>{description}</OptionDescription>}
      </div>
    </Option>
  )
}

const customSingleValue = ({
  innerRef,
  innerProps,
  selectProps: { selectedValue, isTermLength },
  options
}) => {
  const getSingleValueText = () => {
    const values = Object.values(selectedValue)

    if (values.every(val => val)) {
      return '2 Selected'
    }

    if (values.some(val => val)) {
      const indexOfSelected = values.findIndex(val => {
        // JS sorts object keys numerically, so we need to switch the order of the keys to get the correct index
        return isTermLength ? !val : val
      })
      return options[indexOfSelected].label
    }

    return 'Select...'
  }

  return (
    <SingleValue ref={innerRef} {...innerProps}>
      {getSingleValueText()}
    </SingleValue>
  )
}

const CustomSelect = ({
  options,
  isTermLength,
  onChange,
  ...rest
}) => (
  <Select
    options={options}
    width='250px'
    height='56px'
    styles={customStyles}
    components={{
      SingleValue: customSingleValue,
      Option: customOption
    }}
    onChange={onChange}
    isSearchable={false}
    isTermLength={isTermLength}
    closeMenuOnSelect={false}
    {...rest}
  />
)

CustomSelect.displayName = 'CustomSelect'
CustomSelect.propTypes = {
  options: PropTypes.array.isRequired,
  isTermLength: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

export default CustomSelect

import api from './api'
import {
  courseIdToName,
  getCourseIds,
  participationPhilosophyChangeDate
} from './config'
import { getCohortStartSecondsSinceEpoch } from './utilities/courseUtils'

export const getMaxCourseParticipationGrade = async (courseId, startDate) => {
  const courseIds = getCourseIds()

  const isProfessionalCommunication =
    courseId === courseIds.professionalcommunication
  if (isProfessionalCommunication) return 15

  const isGGUCourse = (courseIdToName(courseId) || '').endsWith('.plus')
  if (isGGUCourse) {
    const maxParticipationGrade = await api.getMaxParticipationGrade(courseId)
    return maxParticipationGrade
  }

  const isPhilosophy = courseId === courseIds.philosophy
  const cohortStartDate = getCohortStartSecondsSinceEpoch({ dateStart: startDate })

  if (isPhilosophy && (cohortStartDate >= participationPhilosophyChangeDate)) return 6
  return 5
}

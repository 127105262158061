import React, { useEffect, useState } from 'react'
import {
  Cell,
  CellContainer,
  HeaderItem,
  NoPendingApprovals,
  TableBody,
  TableContainer,
  TableHeader,
  TableRow
} from './styles'
import api from '../../../api'
import moment from 'moment'
import { ButtonAsLink } from '../../../Components/shared/buttons'
import { getTableData, groupRecords } from './utils'
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner'
import ReportModal from './ReportModal'

const pendingApprovalTableHeaders = [
  { title: 'flagged on', value: 'flaggedOn' },
  { title: 'student', value: 'student' },
  { title: 'assessment', value: 'assessment' },
  { title: 'flags received', value: 'flagsReceived' }
]

function PendingApproval ({ setTotalPendingApprovals }) {
  const [aiViolations, setAiViolations] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [currentViolation, setCurrentViolation] = useState({})

  useEffect(() => {
    const getAiViolations = async () => {
      setIsLoading(true)
      const violations = await api.searchAIViolations({
        filters: { approvalStatus: ['Pending'] }
      })
      const groupedRecords = groupRecords(violations)
      const tableData = getTableData(groupedRecords)
      setAiViolations(tableData)
      setTotalPendingApprovals(tableData.length)
      setIsLoading(false)
    }
    getAiViolations()
  }, [setTotalPendingApprovals])

  return (
    <>
      {Object.keys(currentViolation).length > 0 && (
        <ReportModal
          violation={currentViolation}
          handleClose={() => setCurrentViolation({})}
          updateViolations={(violation) => {
            const updatedViolations = aiViolations.filter(
              (currentViolation) => currentViolation.key !== violation.key
            )
            setAiViolations(updatedViolations)
            setTotalPendingApprovals(updatedViolations.length)
          }}
        />
      )}
      <TableContainer>
        <TableHeader>
          {pendingApprovalTableHeaders.map((header, index) => (
            <HeaderItem key={index}>{header.title}</HeaderItem>
          ))}
        </TableHeader>

        <TableBody>
          {!!aiViolations.length &&
            !isLoading &&
            aiViolations.map((violation) => {
              const { key, email, assessment, flagsReceived, flaggedOn } =
                violation

              return (
                <TableRow key={key}>
                  <CellContainer>
                    <Cell>{moment(flaggedOn).format('MMM D, YYYY')}</Cell>
                    <Cell>{email}</Cell>
                    <Cell>{assessment}</Cell>
                    <Cell>
                      {flagsReceived
                        .filter((flag) => flag.type !== null)
                        .map((flag) => flag.type)
                        .join(', ')}
                    </Cell>
                  </CellContainer>
                  <ButtonAsLink
                    onClick={() => setCurrentViolation(violation)}
                    data-testid='review-button'
                  >
                    Review
                  </ButtonAsLink>
                </TableRow>
              )
            })}
          {!aiViolations.length && isLoading && <LoadingSpinner />}
          {!aiViolations.length && !isLoading && (
            <NoPendingApprovals>
              All academic integrity incidents that require approval will appear
              here.
            </NoPendingApprovals>
          )}
        </TableBody>
      </TableContainer>
    </>
  )
}

export default PendingApproval

import styled, { css } from 'styled-components'

export const DraggableWrapper = styled.div`
  width: ${props => props.width || 300}px;
  height: ${props => props.height || 500}px;
  background-color: ${props => props.bgColor || '#1D1D1F'};
  border-radius: ${props => props.bgColor || 5}px;
  position: fixed;
  ${props =>
    props.defaultPosition &&
    props.defaultPosition.right &&
    css`
      right: ${props.defaultPosition.right}px;
    `}
  ${props =>
    props.defaultPosition &&
    props.defaultPosition.left &&
    css`
      left: ${props.defaultPosition.left}px;
    `}
  ${props =>
    props.defaultPosition &&
    props.defaultPosition.top &&
    css`
      top: ${props.defaultPosition.top}px;
    `}
  ${props =>
    props.defaultPosition &&
    props.defaultPosition.bottom &&
    css`
      bottom: ${props.defaultPosition.bottom}px;
    `}
  z-index: 2;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.3);
`

import React, { useState, useEffect } from 'react'
import api from '../../../api'
import {
  CardTitle,
  CommentButton,
  CommentButtonContainer, CommentCancelButton, CommentInput,
  CommentsCard, CommentsContainer, CommentsContainerWrapper,
  CommentsNote, CommentsSectionWrapper, CommentWrapper
} from './styles'
import CommentItem from './CommentItem'

const CommentsSection = ({ prospectATId }) => {
  const [commentsList, setCommentsList] = useState([])
  const [commentText, setCommentText] = useState('')
  const [isTextInputFocused, setIsTextInputFocused] = useState(false)

  useEffect(() => {
    fetchComments()
    // eslint-disable-next-line
  }, [prospectATId])

  const fetchComments = async () => {
    const { rows: comments } =
      await api.getTableRecordComments('prospects', prospectATId) || {}
    const sortedComments = comments.sort((a, b) => {
      const dateA = new Date(a?.fields?.updated_time)
      const dateB = new Date(b?.fields?.updated_time)
      return dateA - dateB
    })
    setCommentsList(sortedComments)
  }

  const saveComment = async () => {
    if (commentText === '') return
    await api.createTableRecordComment('prospects', prospectATId, commentText)
    setCommentText('')
    fetchComments()
  }

  const noComments = commentsList?.length === 0

  return (
    <CommentsSectionWrapper>
      <CommentsCard>
        <CardTitle>Comments</CardTitle>
        {noComments
          ? <CommentsNote>Any notes about this student will appear here.</CommentsNote>
          : <CommentsContainerWrapper>
            <CommentsContainer>
              {commentsList.map((comment, index) => (
                <CommentItem key={index} comment={comment} />
              ))}
            </CommentsContainer>
          </CommentsContainerWrapper>
        }
      </CommentsCard>
      <CommentWrapper>
        <CommentInput
          onFocus={() => setIsTextInputFocused(true)}
          onBlur={() => setIsTextInputFocused(false)}
          placeholder='Leave a comment'
          autoComplete='off'
          type='text'
          value={commentText}
          onChange={e => setCommentText(e.target.value)}
        />
        <CommentButtonContainer isTextInputFocused={isTextInputFocused}>
          <CommentButton onMouseDown={(e) => {
            e.preventDefault()
            saveComment(commentText)
          }}
          >
            comment
          </CommentButton>
          <CommentCancelButton>cancel</CommentCancelButton>
        </CommentButtonContainer>
      </CommentWrapper>
    </CommentsSectionWrapper>
  )
}
CommentsSection.displayName = 'CommentsSection'
export default CommentsSection

import styled, { css } from 'styled-components'
import { UncontrolledTooltip } from 'reactstrap'
import { CustomDatePicker } from '../../VipUser/styled'
import calendar from '../../../assets/icons/calendar_2.svg'
import clear from '../../../assets/icons/clear-icon.svg'
import { ReactComponent as Sort } from '../../../assets/icons/sort-icon.svg'
import { ReactComponent as info } from '../../../assets/icons/info.svg'
import { ReactComponent as Bin } from '../../../assets/icons/trash-bin.svg'
import { ReactComponent as Close } from '../../../assets/icons/modal-close.svg'
import { ReactComponent as Success } from '../../../assets/icons/icon-success.svg'
import { ReactComponent as Fail } from '../../../assets/icons/icon-failure.svg'

export const PageWrapper = styled.div`
  min-width: 1100px;
  max-width: 1920px;
  padding: 64px 64px 0 64px;
  margin: 0 auto;
`
export const Title = styled.h1`
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--border-primary);
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  color: var(--text-primary);
`
export const Instructions = styled.div`
  margin-bottom: 32px;

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-primary);
    opacity: 0.7;
  } 

  b {
    color: var(--text-primary);
  }

  p:nth-child(2) {
    margin-bottom: 0px;
  }
`
export const FiltersWrapper = styled.div`
  display: flex;
  flex-flow: nowrap row;
  align-items: left;
  margin-bottom: 56px;
`
export const Label = styled.div`
  margin-right: 16px;
  span {
    display: block;
    margin-bottom: 8px;
    color: var(--text-secondary);
  }
`
export const SingleValue = styled.div`
  background-color: transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`
export const Option = styled.div`
  display: flex;
  flex-flow: nowrap row;
  align-items: center;
  justify-content: left;
  padding: 8px 16px;
  height: ${props => props.isTermLength ? '40px' : '64px'};
  color: ${props => props.isSelected ? 'var(--text-primary)' : 'var(--sub-text-secondary)'};

  :hover {
    background: rgba(255, 255, 255, 0.1);
    color: var(--text-primary);
  }
`
export const OptionCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: ${props => props.isChecked && 'var(--brand-regular)'};
  margin-right: ${({ marginRight }) => marginRight || 16}px;
  border: 1px solid var(--brand-regular);
  border-radius: 5px;
  cursor: pointer;

  ::after {
    position: relative;
    bottom: 2px;
    display: ${props => props.isChecked ? 'block' : 'none'};
    content: '';
    height: 35%;
    width: 60%;
    border-left: solid 2px var(--text-dark);
    border-bottom: solid 2px var(--text-dark);
    transform: rotate(-45deg);
  }
`
export const OptionLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`
export const OptionDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`
export const DatePicker = styled(CustomDatePicker)`
  width: 160px;
  height: 56px;
  cursor: pointer;

  :focus {
    border: 1px solid var(--border-primary);
  }

  :hover {
    border: 1px solid var(--brand-regular);
  }
`
export const DatePickerWrapper = styled.div`
  position: relative;

  .react-datepicker-ignore-onclickoutside {
    border: 1px solid var(--border-primary)!important;
  }

  #clear-icon {
    display: ${props => props.selected ? 'block' : 'none'};
    width: 16px;
    height: 16px;
    background: url(${clear});
    background-size: contain;
    background-position: center;
    position: absolute;
    right: 16px;
    top: 19.5px;
    cursor: pointer;
    z-index: 1;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container::after {
      ${props => props.selected && 'display: none'};
      content: url(${calendar});
      position: absolute;
      right: 16px;
      top: 19.5px;
      pointer-events: none;
    }
  }

  .react-datepicker {
    width: 279px;
    padding-top: 10px;
    font-family: "Lato";
    background: var(--gray-primary);
    border: none;
    box-shadow: 0px 0px 20px var(--shadow-primary);
    border-radius: 5px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 16px;
    color: var(--text-primary);
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 14px;
    color: var(--text-primary);
  }
  .react-datepicker__day {
    border: 1px solid var(--brand-regular);
    border-radius: 5px;
    color: var(--brand-regular);
  }
  .react-datepicker__day--disabled {
    color: var(--text-primary-off)!important;
    border: none!important;
  }
  .react-datepicker__month-container {
    width: 100%;
    background: var(--gray-primary);
    border-radius: 5px;
  }
  .react-datepicker__month {
    margin: 0px;
    padding: 6px 0px 12px 0px;
  }
  .react-datepicker__day-names {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 9px 20px 6px 20px;
  }
  .react-datepicker__week {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 20px;
  }
  .react-datepicker__header {
    background: var(--gray-primary);
    border-bottom: 1px solid var(--border-primary);
  }

  .react-datepicker__time-list {
    background: var(--gray-primary);
  }

  .react-datepicker__time-list-item {
    font-size: 14px;
    color: var(--text-primary);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__time-list-item--selected {
    background: var(--brand-regular) !important;
    color: var(--text-dark) !important;
  }

  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__time-container {
    border-left: 1px solid var(--border-primary);
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__time-list-item:hover {
    background-color: var(--brand-regular-hover);
    color: var(--text-dark);
  }

  .react-datepicker__day--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__time-box {
    width: 84px !important;
  }

  .react-datepicker__time-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--shadow-primary);
    border-radius: 10px;
    background-color: transparent;
  }

  .react-datepicker__time-list::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  .react-datepicker__time-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--shadow-primary);
    background: rgba(255, 255, 255, 0.1);
  }

  .react-datepicker__navigation {
    top: 22px;
  }

  .react-datepicker__navigation--previous {
    left: 21px;
  }

  .react-datepicker__navigation--next {
    right: 21px;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 91px;
  }
`
export const PrimaryButton = styled.button`
  display: block;
  padding: 16px 24px;
  height: 48px;
  margin-left: ${props => props.ml ? props.ml : '0'}px;
  background: ${props => props.disabled ? 'var(--brand-regular-disabled)' : 'var(--brand-regular)'};
  color: var(--text-dark);
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  border-radius: 5px!important;
  outline: none!important;

  :active {
    ${props => !props.disabled && css`
      background: var(--white);
    `}
  }

  :hover:not(:disabled) {
    background: var(--brand-regular-hover);
  }
`
export const SecondaryButton = styled(PrimaryButton)`
  background: transparent;
  color: ${props => props.disabled
    ? 'var(--brand-regular-disabled)'
    : 'var(--brand-regular)'};
  border: none;
  border: solid 1px ${props => props.disabled
    ? 'var(--brand-regular-disabled)'
    : 'var(--brand-regular)'};
  border-radius: 5px!important;
  outline: none!important;

  :active {
    ${props => !props.disabled && css`
      background: var(--white);
      color: black;
    `}
  }

  :hover:not(:disabled) {
    color: var(--brand-regular-hover);
    border-color: var(--brand-regular-hover);
    background: transparent;
  }
`
export const DropStudentsButton = styled(PrimaryButton)`
  margin-left: auto;
  margin-top: 40px;
`
export const StudentRowWrapper = styled.div`
  display: flex;
  flex-flow: column;
  border-bottom: ${({ isStudentCell }) => (
    `1px solid rgba(255, 255, 255, ${isStudentCell ? 0.1 : 0.3})`
  )};
`
export const NoStudentsMessage = styled.p`
  margin-top: 32px;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`
export const Row = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: ${props => props.isStudentRow ? '5px 0px' : '24px 0px'};
  padding-right: 8.2%;

  ${props => props.isStudentRow && css`
    cursor: pointer;
  `}
`
export const HeaderCell = styled.div`
  position: relative;
  width: 160px;
  height: fit-content;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--text-secondary);
  overflow-wrap: anywhere;
`
export const StudentCell = styled(HeaderCell)`
  min-height: 70px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  color: var(--white);
  text-transform: none;
`
export const SortBtnsWrapper = styled.div`
  display: inline-block;
  position: absolute;
  left: 75px;
  top: -2px;
`
export const SortAscending = styled(Sort)`
  display: block;
  cursor: pointer;
  margin-bottom: 1px;
  transform: rotate(180deg);

  ${props => !props.isSelected && css`
    path {
      fill: var(--border-tertiary)!important;
    }
  `}
`
export const SortDescending = styled(Sort)`
  display: block;
  cursor: pointer;
  ${props => !props.isSelected && css`
    path {
      fill: var(--border-tertiary)!important;
    }
  `}
`
export const InfoIcon = styled(info)`
  margin-left: 8px;
  margin-top: -3px;
`
export const ToolTip = styled(UncontrolledTooltip)`
  .show {
    opacity: 1;
  }

  .tooltip-content {
    max-width: 255px;
    width: 255px;
    height: 96px;
    padding: 12px;
    background: var(--gray-primary);
    box-shadow: 0px 0px 20px var(--shadow-primary);
    border-radius: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-primary);
    text-align: left;
  }

  .tooltip-arrow {
    display: none;
  }
`
export const StudentNameWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`
export const ExpandCollapseButton = styled.div`
  width: 15.88px;
  height: 16px;
  background-size: contain;
  background-position: center;
  position: absolute;
  right: 0px;
  top: calc(50% - 14.5px);
  cursor: pointer;
  z-index: 1;
`
export const StudentStatusWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding-top: 11px;
  padding-left: 64px;
`
export const StudentStatus = styled.div`
  display: flex;
  width: 250px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 16px;
`
export const TokenStatus = styled(StudentStatus)`
  padding-left: 36px;
  width: 284px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }
`
export const StatusTitle = styled.h2`
  margin: 0px;
  padding: 0px 0px 12px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--text-dark-off);
`
export const StatusInfo = styled.h3`
  font-style: normal;
  padding: 0px 0px 12px 0px;
  margin: 0px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: var(--text-primary);

  span {
    color: ${props => !props.isComplete && 'var(--error-color)!important'};
    padding-left: 4px;
  }
`
export const RemoveStudent = styled.h3`
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--brand-regular);
  cursor: pointer;
`
export const TrashBin = styled(Bin)`
  display: inline-block;
  margin-right: 8px; 
  margin-top: -1px;
  cursor: pointer;
`
export const ModalHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 455px;
  padding: 24px 29px 24px 24px;
  border-bottom: 1px solid var(--border-primary);
`
export const ModalHeaderTitle = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
`
export const ModalCloseIcon = styled(Close)`
  display: block;
  width: 14px;
  height: 14px;
  margin-top: 6px;
  cursor: pointer;
`
export const ModalBody = styled.div`
  width: 455px;
  padding: 24px;
  padding-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-feature-settings: 'tnum' on, 'lnum' on;
  color: var(--white);
  text-shadow: 0px 4px 4px var(--shadow-primary);

  p {
    margin: 0px 0px 12px 0px;
  }
`
export const ModalActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 0px 24px 24px 24px;
`
export const SuccessIcon = styled(Success)`
  display: inline-block;
  margin-top: -4px;
  margin-right: 12px;
`
export const FailureIcon = styled(Fail)`
  display: inline-block;
  margin-top: -4px;
  margin-right: 12px;
`

export const customStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  control: (base, state) => ({
    ...base,
    width: state.selectProps.width ? state.selectProps.width : '285px',
    height: state.selectProps.height ? state.selectProps.height : '40px',
    background: 'var(--input-primary)',
    color: 'var(--text-primary)',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    marginTop: '7px',
    opacity: state.isDisabled ? 0.3 : 1,
    '&:hover': {
      border: '1px solid var(--brand-regular)'
    },
    '&:focus-within': {
      borderColor: 'var(--white)'
    }
  }),
  input: base => ({
    ...base,
    color: 'var(--text-primary)'
  }),
  menu: base => ({
    ...base,
    width: '250px',
    zIndex: 1000,
    background: 'var(--gray-primary)',
    border: 'none',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 20px var(--shadow-primary)',
    borderRadius: '5px',
    marginTop: '15px',
    paddingTop: '8px',
    paddingBottom: '8px'
  }),
  menuList: base => ({
    ...base,
    width: '100%',
    height: '100%',
    padding: 0
  }),
  placeholder: base => ({
    ...base,
    color: 'var(--text-primary)',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px'
  }),
  singleValue: base => ({
    ...base,
    color: 'var(--text-primary)',
    paddingLeft: '6px',
    margin: 0,
    fontSize: '16px',
    lineHeight: '19px'
  }),
  dropdownIndicator: base => ({
    ...base,
    color: 'var(--text-primary)',
    padding: '0 12.5px 0 0',
    '&:hover': {
      color: 'var(--text-primary)'
    }
  }),
  indicatorContainer: base => ({
    ...base,
    paddingLeft: 0
  }),
  valueContainer: base => ({
    ...base,
    fontSize: '16px',
    paddingLeft: '16px',
    backgroundColor: 'transparent',
    color: 'var(--text-primary)',
    lineHeight: '19px',
    border: 'none'
  })
}

import cloneDeep from 'lodash/cloneDeep'

export {
  setChapterNumber
}

function setChapterNumber (courseData) {
  const _courseData = cloneDeep(courseData)
  let chapterNumber = 0
  _courseData.chapters = _courseData.chapters.map((chapter) => {
    if (chapter.type === 'chapter') {
      chapterNumber = chapterNumber + 1
      chapter.chapterNumber = chapterNumber
      return chapter
    }
    return chapter
  })
  return _courseData
}

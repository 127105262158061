import React, { useEffect, useState } from 'react'
import api from '../../../api'
import {
  ExpandedRowCell,
  ExpandedRowHeaderCell,
  ExpandedRowHeaderWrapper,
  ExpandedRowWrapper, Row
} from './styles/index.styled'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'

const ExpandedRow = ({ relationship }) => {
  const [courseLicenses, setCourseLicenses] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!relationship?.id) return

    const fetchCourseLicenses = async () => {
      const data = await api.getCoursesLicenses(
        { relationshipId: relationship.id }
      )
      const tokens = data?.reduce((acc, { course: { name }, usedStatus }) => {
        if (!acc[name]) {
          acc[name] = {
            courseName: name,
            available: 0,
            pending: 0,
            used: 0,
            total: 0
          }
        }
        acc[name].total += 1
        if (!usedStatus) acc[name].available += 1
        else if (usedStatus === 'pending') acc[name].pending += 1
        else if (usedStatus === 'used') acc[name].used += 1
        return acc
      }, [])

      tokens && setCourseLicenses(Object.values(tokens))
      setIsLoading(false)
    }

    fetchCourseLicenses()
  }, [relationship])

  if (isLoading) return <LoadingSpinner />

  return (
    <ExpandedRowWrapper>
      <ExpandedRowHeaderWrapper>
        {['Course', 'Available', 'Pending', 'Used', 'Total']
          .map((header, index) => (
            <ExpandedRowHeaderCell key={index}>{header}</ExpandedRowHeaderCell>
          ))}
      </ExpandedRowHeaderWrapper>
      {courseLicenses.map(
        (
          { courseName, available, pending, used, total }
          , index
        ) => (
          <Row key={index}>
            <ExpandedRowCell>{courseName}</ExpandedRowCell>
            <ExpandedRowCell>{available}</ExpandedRowCell>
            <ExpandedRowCell>{pending}</ExpandedRowCell>
            <ExpandedRowCell>{used}</ExpandedRowCell>
            <ExpandedRowCell>{total}</ExpandedRowCell>
          </Row>
        ))}
    </ExpandedRowWrapper>
  )
}

export default ExpandedRow

import papa from 'papaparse'
import { csvHeaders } from '../../Constants/vipTool'

function getFileString (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export async function validateCSV (file) {
  const csvString = await getFileString(file)
  const results = papa.parse(csvString, { header: true, skipEmptyLines: true })

  // Check if the actual headers match the expected headers
  const actualHeaders = results.meta.fields
  if (JSON.stringify(actualHeaders) !== JSON.stringify(csvHeaders)) {
    return { error: true, data: results.data }
  }

  // Check if all rows have the correct number of values and none of the values are empty
  for (const row of results.data) {
    if (Object.keys(row).length !== csvHeaders.length) {
      return { error: true, data: results.data }
    }

    for (const header of csvHeaders) {
      if (!row[header] || row[header].trim() === '') {
        return { error: true, data: results.data }
      }
    }
  }

  return { error: false, data: results.data }
}

import React, { useEffect, useState } from 'react'
import api from '../../api'
import {
  SectionTitle,
  NormalText,
  SectionWrapper,
  PaleLine,
  FiltersWrapper,
  Label,
  LoadingWrapper
} from './index.style'
import StudentsTable from './StudentsTable'
import CustomSelect from '../../Components/CustomSelect/UpdatedSelect'
import { ResetButton } from '../../Components/shared/buttons'
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner'
import { initialSelectedData } from './utils/constants'
import {
  getFilterCohorts,
  getFilteredStudents,
  getOrderedStudents
} from './utils'
import { getRelationshipCohorts } from '../../utilities/cohortUtils'

function SelectionPage ({
  filtersData,
  setFiltersData,
  selectedData,
  setSelectedData,
  setCurrentPage
}) {
  const [isLoadingStudents, setIsLoadingStudents] = useState(false)

  useEffect(() => {
    if (!selectedData?.cohort) {
      return setSelectedData(prev => ({
        ...prev,
        cohortStudents: [],
        filteredStudents: [],
        selectedStudents: []
      }))
    }

    const fetchStudents = async () => {
      setIsLoadingStudents(true)
      const cohortId = selectedData?.cohort?.id
      const cohortStudents = await api.getStudentsByCohort(cohortId)
      getOrderedStudents(cohortStudents)
      setSelectedData(prev => ({ ...prev, cohortStudents }))
      setIsLoadingStudents(false)
    }

    fetchStudents()
  }, [selectedData.cohort, setSelectedData])

  useEffect(() => {
    if (!selectedData.cohortStudents?.length) return

    const filteredStudents = getFilteredStudents(
      selectedData.cohortStudents, selectedData.statuses, selectedData.relationship)
    const selectedStudents = selectedData?.selectedStudents
      ?.filter(student => filteredStudents?.includes(student))
    setSelectedData(prev => ({ ...prev, filteredStudents, selectedStudents }))
  // eslint-disable-next-line
  }, [
    setSelectedData,
    selectedData.statuses,
    selectedData.relationship,
    selectedData.cohortStudents
  ])

  const handleInstitutionChange = (institution) => {
    setSelectedData(prev => ({ ...prev, institution, course: null, cohort: null }))
    if (!institution) return
    const filteredCourses = filtersData.allCourses
      .filter(course => course.institutions?.includes(institution?.value))
    setFiltersData(prev => ({ ...prev, courses: filteredCourses }))
  }

  const handleCourseChange = (course) => {
    const isSameCourse = course?.value === selectedData?.course?.value
    if (isSameCourse) return
    setSelectedData(prev => ({ ...prev, course, cohort: null }))
    if (!course) return
    const filterCohorts = getFilterCohorts(course?.courses, selectedData?.institution?.value)
    setFiltersData(prev => ({ ...prev, cohorts: filterCohorts }))
  }

  const handleCohortChange = async (cohort) => {
    const isSameCohort = cohort?.id === selectedData?.cohort?.id
    if (isSameCohort) return
    setSelectedData(prev => ({ ...prev, cohort }))
  }

  const handleStatusesChange = (status) => {
    const { value } = status || {}

    const isStatusSelected = selectedData?.statuses?.find(s => s?.value === value)
    if (isStatusSelected) {
      return setSelectedData(prev => ({
        ...prev,
        statuses: prev.statuses.filter(s => s?.value !== value)
      }))
    }

    setSelectedData(prev => ({
      ...prev,
      statuses: [...prev.statuses, status]
    }))
  }

  const isResetDisabled = () => {
    const { institution, course, cohort, statuses } = selectedData
    return !(institution && course && cohort && statuses?.length)
  }

  const handleResetClick = () => {
    setSelectedData({ ...initialSelectedData, statuses: filtersData.statuses })
  }

  const relationshipCohorts = getRelationshipCohorts(selectedData?.relationship)
  const isCohortInRelationship = !selectedData?.relationship ? true
    : relationshipCohorts?.includes(selectedData?.cohort?.id)

  const showStudentsTable = isCohortInRelationship &&
    !!selectedData?.filteredStudents?.length && !isLoadingStudents

  const getRosterText = () => {
    if (!selectedData?.cohort) return 'Select a cohort to view the roster.'

    const noStatusSelected = !selectedData?.statuses?.length
    if (noStatusSelected) return 'Select a status to view the roster.'

    if (!isCohortInRelationship) {
      return 'This cohort is not in the selected relationship. Please adjust the filters.'
    }

    if (!selectedData?.filteredStudents?.length) {
      return 'No students match this selection. Please adjust the filters.'
    }
  }

  return (
    <>
      <NormalText>
        Use this tool to update the enrollment status for one or a group of students.
      </NormalText>

      <SectionWrapper>
        <SectionTitle>
          Filters
        </SectionTitle>

        <FiltersWrapper>
          <Label>
            <span>Credit-granting institution</span>
            <CustomSelect
              width='300px'
              isClearable
              placeholder='Select an institution'
              value={selectedData.institution}
              options={filtersData.institutions}
              onChange={institution => handleInstitutionChange(institution)}
            />
          </Label>

          <Label>
            <span>Course</span>
            <CustomSelect
              width='300px'
              isClearable
              value={selectedData.course}
              options={filtersData.courses}
              isDisabled={!selectedData.institution}
              onChange={course => handleCourseChange(course)}
              placeholder={!selectedData.institution ? ' ' : 'Select a course'}
            />
          </Label>

          <Label>
            <span>Cohort</span>
            <CustomSelect
              isClearable
              value={selectedData.cohort}
              options={filtersData.cohorts}
              isDisabled={!selectedData.course}
              onChange={cohort => handleCohortChange(cohort)}
              placeholder={!selectedData.course ? ' ' : 'Select a cohort'}
            />
          </Label>

          <Label>
            <span>Relationship (optional)</span>
            <CustomSelect
              width='300px'
              isClearable
              value={selectedData.relationship}
              options={filtersData.relationships}
              isDisabled={!selectedData.institution}
              placeholder={!selectedData.institution ? ' ' : 'Select a relationship'}
              onChange={relationship => setSelectedData(prev => ({ ...prev, relationship }))}
            />
          </Label>

          <Label>
            <span>Student status</span>
            <CustomSelect
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              value={selectedData.statuses}
              options={filtersData.statuses}
              isDisabled={!selectedData.institution}
              onChange={event => handleStatusesChange(event)}
              placeholder={!selectedData.institution ? ' ' : 'Select a relationship'}
            />
          </Label>

          <ResetButton
            disabled={isResetDisabled()}
            onClick={handleResetClick}
          >
            reset
          </ResetButton>
        </FiltersWrapper>
      </SectionWrapper>

      {isLoadingStudents ? (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      ) : !showStudentsTable ? (
        <>
          <PaleLine />
          <NormalText>{getRosterText()}</NormalText>
        </>
      ) : (
        <StudentsTable
          filteredStudents={selectedData.filteredStudents}
          selectedStudents={selectedData.selectedStudents}
          setSelectedData={setSelectedData}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  )
}

SelectionPage.displayName = 'SelectionPage'
export default SelectionPage

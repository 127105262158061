import React from 'react'
import {
  TableContainer,
  TableHeader,
  HeaderItem,
  TableRow,
  RowItem,
  TableBody,
  TablesSection,
  TableNamesContainer,
  TableName
} from './style'
import { BodyText } from '../../styles/text'
import chevronRight from '../../assets/icons/chevron-right-teal.svg'
import { PAGES, TABLES, prospectsTableHeaders } from './utils/constants'
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner'
import { getFilteredProspects } from './utils'

const ProspectsTable = ({
  activeTable,
  setActiveTable,
  setActivePage,
  selectedData,
  setActiveProspect,
  prospectsData
}) => {
  const isReviewTable = activeTable === TABLES.review
  const isApprovedTable = activeTable === TABLES.approved

  const activeProspectsData = prospectsData?.[activeTable]
  const filteredProspects = getFilteredProspects(activeProspectsData, selectedData)
  const showProspectsData = !!filteredProspects.length && !prospectsData.isLoading

  const handleTableNameClick = (table) => {
    if (activeTable === table) return
    setActiveTable(table)
  }

  const handleRowClick = (prospect) => {
    setActiveProspect(prospect)
    setActivePage(PAGES.prospect)
  }

  return (
    <TablesSection>
      <TableNamesContainer>
        <TableName
          isActive={isReviewTable}
          data-testid='needs-reviewing'
          onClick={() => handleTableNameClick(TABLES.review)}
        >
          Needs reviewing
        </TableName>

        <TableName
          isActive={isApprovedTable}
          data-testid='approved-students'
          onClick={() => handleTableNameClick(TABLES.approved)}
        >
          Approved students
        </TableName>
      </TableNamesContainer>

      <TableContainer>
        <TableHeader>
          {prospectsTableHeaders.map((header, index) => (
            <HeaderItem key={index}>
              {header.title}
            </HeaderItem>
          ))}
        </TableHeader>

        {prospectsData.isLoading && (
          <TableBody isWrapper>
            <LoadingSpinner />
          </TableBody>
        )}

        {!showProspectsData && !prospectsData.isLoading && (
          <TableBody>
            <BodyText>
              {isReviewTable
                ? 'Students who have registered for courses will appear here.'
                : 'Approved students will appear here.'
              }
            </BodyText>
          </TableBody>
        )}

        <TableBody isTable>
          {showProspectsData && (
            filteredProspects.map((prospect, index) => {
              return (
                <TableRow
                  key={index}
                  onClick={() => handleRowClick(prospect)}
                >
                  {prospectsTableHeaders.map((header, index) => (
                    <RowItem key={index}>
                      {prospect?.[header.value] || '--'}
                    </RowItem>
                  ))}

                  <img src={chevronRight} alt='chevron-icon' />
                </TableRow>
              )
            })
          )}
        </TableBody>
      </TableContainer>
    </TablesSection>
  )
}

ProspectsTable.displayName = 'ProspectsTable'
export default ProspectsTable

import React, { useEffect, useRef, useState } from 'react'
import GoToPreviousPageButton
from '../../GoToPreviousPageButton/GoToPreviousPageButton'
import {
  StudentAttemptsWrapper,
  StudentCertDegreeWrapper,
  StudentRecordDetailsWrapper
} from './style'
import StudentInformation from './StudentInformation'
import api from '../../../api'
import StudentCertificates from './StudentCertificates'
import StudentDegrees from './StudentDegrees'
import StudentAttemptsSummary from './StudentAttemptsSummary'
import StudentAttemptsChart from './StudentAttemptsChart'
import StudentTokensSummary from './StudentTokensSummary'
import StudentAttemptRecords from './StudentAttemptRecords'
import StudentTokenRecords from './StudentTokenRecords'

const StudentRecordDetails = ({ routerProps: { history, match } }) => {
  const [prospectData, setProspectData] = useState({})
  const [prospectList, setProspectList] = useState([])
  const [attemptsData, setAttemptsData] = useState([])
  const [tokensData, setTokensData] = useState([])
  const attemptsScrollRef = useRef(null)
  const tokensScrollRef = useRef(null)

  useEffect(() => {
    fetchProspectData(studentEmail)
    fetchStudentAttempts(studentId)
    fetchTokensData(studentEmail)
    // eslint-disable-next-line
  }, [])

  const { studentId, studentEmail } = match.params
  const fetchProspectData = async (studentEmail) => {
    const prospectData = await api.getProspectsData(studentEmail)
    setProspectList(prospectData?.data)
    setProspectData(prospectData?.data?.[0])
  }
  const fetchStudentAttempts = async (studentId) => {
    const attemptsData = await api.getStudentAttempts({
      studentId
    })
    setAttemptsData(attemptsData)
  }
  const fetchTokensData = async (studentEmail) => {
    const tokensData = await api.getStudentTokens(studentEmail)
    if (!tokensData?.length) return
    setTokensData(tokensData)
  }

  const scrollToAttempts = () => {
    attemptsScrollRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToTokens = () => {
    tokensScrollRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <StudentRecordDetailsWrapper>
      <GoToPreviousPageButton
        handleClick={() => history.push('/student-records')}
        text='student search'
      />
      <StudentInformation studentId={studentId} prospectData={prospectData} />
      <StudentCertDegreeWrapper>
        <StudentCertificates studentEmail={studentEmail} />
        <StudentDegrees prospectList={prospectList} />
      </StudentCertDegreeWrapper>
      <StudentAttemptsWrapper>
        <StudentAttemptsSummary attempts={attemptsData} scrollToAttempts={scrollToAttempts} />
        <StudentAttemptsChart attempts={attemptsData} />
      </StudentAttemptsWrapper>
      <StudentTokensSummary tokensData={tokensData} scrollToTokens={scrollToTokens} />
      <StudentAttemptRecords attempts={attemptsData} studentEmail={studentEmail} attemptsScrollRef={attemptsScrollRef} />
      <StudentTokenRecords tokens={tokensData} tokensScrollRef={tokensScrollRef} />
    </StudentRecordDetailsWrapper>
  )
}
StudentRecordDetails.displayName = 'StudentRecordDetails'
export default StudentRecordDetails

import React, { useEffect, useRef, useState } from 'react'
import api from '../../../api'
import Toast from '../../ToastComponent/Toast'
import GoToPreviousPageButton from '../../GoToPreviousPageButton/GoToPreviousPageButton'
import { getTotalLicenses } from '../utils/licenseUtils'
import { NO_DISTRICT_ERROR, NO_RELATIONSHIP_ERROR } from '../constants'
import PartnerDetails from './PartnerDetails'
import AvailableCourses from './AvailableCourses'
import {
  Container,
  GoToPreviousPageButtonWrapper,
  Header,
  HeaderDescription
} from './styled'

function AddCourseLicenses ({ routerProps }) {
  const {
    history
  } = routerProps

  const toast = useRef()
  const [isRelationshipsLoading, setRelationshipsLoading] = useState(true)
  const [relationships, setRelationships] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [selectedRelationship, setSelectedRelationship] = useState(null)
  const [tokens, setTokens] = useState({})

  const licenses = getTotalLicenses(tokens)
  const showError = licenses > 0 && !selectedRelationship &&
    (!selectedDistrict ? NO_DISTRICT_ERROR : NO_RELATIONSHIP_ERROR)

  useEffect(() => {
    const getRelationships = async () => {
      setRelationshipsLoading(true)
      const relationships = await api.getStudentRelationships({ useStream: false })
      const relationShipOptions = relationships
        .filter(relationship => relationship.partnerType === 'high school')
        .sort((a, b) => a.relationshipName.localeCompare(b.relationshipName))
        .map(
          relationship => {
            return {
              ...relationship,
              value: relationship.id,
              label: relationship.relationshipName
            }
          }
        )
      setRelationships(relationShipOptions)
      setRelationshipsLoading(false)
    }
    getRelationships()
  }, [])

  return (
    <Container>
      <Toast toast={toast} />
      <GoToPreviousPageButtonWrapper>
        <GoToPreviousPageButton
          text='Go back'
          handleClick={() => { history.push('/relationships') }}
        />
      </GoToPreviousPageButtonWrapper>
      <Header>Add course licenses</Header>
      <HeaderDescription>
        Add course licenses on behalf of a partner.
      </HeaderDescription>
      <PartnerDetails
        isLoading={isRelationshipsLoading}
        relationships={relationships}
        selectedDistrict={selectedDistrict}
        setSelectedDistrict={setSelectedDistrict}
        selectedRelationship={selectedRelationship}
        setSelectedRelationship={setSelectedRelationship}
        showError={showError}
      />
      <AvailableCourses
        relationship={selectedRelationship}
        setRelationship={setSelectedRelationship}
        showError={showError}
        toast={toast}
        tokens={tokens}
        setTokens={setTokens}
      />
    </Container>
  )
}

export default AddCourseLicenses

import React, { useRef } from 'react'
import FileUploader from '../FileUploader/FileUploader'
import ErrorTable from './ErrorTable'
import { steps } from './CSVImportConstants'
import {
  InstructionsParagraph,
  CustomContainer,
  CustomButton,
  ReviewTitle,
  ErrorTitle,
  HeaderContainer
} from './style'

function CSVReviewError (props) {
  const errorTableRef = useRef(null)
  const {
    file,
    csvColumns,
    errorsTable,
    handleUploadedFile,
    setFile,
    setStep,
    setErrorsTable
  } = props

  const { COLUMN_MAPPING } = steps

  const renderErrorsTable = () => {
    const columns = csvColumns.map(c => c.csvColumn).filter(Boolean)
    const headerData = ['Row', 'Error Type'].concat(columns)

    const headerColumn = headerData.map(item => {
      return {
        prop: item,
        Header: item,
        accessor: item.replace('.', ''),
        sortType: 'alphanumeric'
      }
    })

    const rows = []
    errorsTable.forEach(errors => {
      const rowData = errors.rowData
      rowData.Row = errors.rowNumber
      rowData['Error Type'] = ''
      rowData['Error Columns'] = []
      errors.errors.forEach(errorRow => {
        rowData['Error Type'] += `${errorRow.error}; `
        rowData['Error Columns'].push(errorRow.errorColumn)
      })
      rowData['Error Type'] = rowData['Error Type'].slice(0, -2)
      const objRowData = {}
      headerColumn.forEach((item) => {
        objRowData[item.prop.replace('.', '')] = rowData[item.prop] || 'Null'
      })
      objRowData['Error Columns'] = rowData['Error Columns']
      rows.push(objRowData)
    })

    return (
      <ErrorTable
        columns={headerColumn}
        data={rows}
        refProp={errorTableRef}
      />
    )
  }

  return (
    <>
      <CustomContainer margin='32px 0 0 0'>
        <HeaderContainer
          style={{
            alignItems: 'center',
            lineHeight: '22px'
          }}
        >
          <ErrorTitle>
            Errors contained in CSV file
          </ErrorTitle>
        </HeaderContainer>
        {renderErrorsTable()}
      </CustomContainer>
      <CustomContainer margin='34px 0 0 0'>
        <ReviewTitle>
          Updated CSV file
        </ReviewTitle>
        <CustomContainer margin='12px 0 0 0'>
          <InstructionsParagraph>
            Note: When updating the file, please do not add or
            remove columns from the original file.
          </InstructionsParagraph>
        </CustomContainer>
        <CustomContainer margin='12px 0 0 0'>
          <FileUploader
            uploadedContent={file}
            onFileSelect={handleUploadedFile}
            isDisabled={false}
            onEmpty={() => {
              setFile(undefined)
            }} />
        </CustomContainer>
        <CustomButton
          className='btn btn-custom btn-primary'
          disabled={!file}
          onClick={() => {
            setStep(COLUMN_MAPPING)
            setErrorsTable([])
          }}
        >
          Fix
        </CustomButton>

      </CustomContainer>
    </>
  )
}

CSVReviewError.displayName = 'CSVReviewError'
export default CSVReviewError

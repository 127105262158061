import React, { useEffect } from 'react'
import {
  CourseDetailsLink,
  CourseMismatchText,
  CourseMatchText,
  CourseDetailsWrapper,
  Divider,
  GradingCheckHeaderTitle,
  Row,
  RowContent,
  RowHeader,
  StudentRowHeader,
  RowContainer,
  RowGrade,
  TableContainer
} from './style'
import { formatHeader } from './gradingCheckUtils'

const CourseDetails = ({ routerProps }) => {
  const history = routerProps?.history
  const course = history?.location?.state?.course
  useEffect(() => {
    if (!course) {
      history.push(`/grading-check`)
    }
    // eslint-disable-next-line
  }, [])
  if (!course) { return null }

  const students = course?.students?.sort((a, b) => a.atGradeData.student.localeCompare(b.atGradeData.student))
  const hasMismatchedGrades = course?.hasMismatchedGrades

  return (
    <CourseDetailsWrapper>
      <GradingCheckHeaderTitle>
        {course?.courseTabName}
      </GradingCheckHeaderTitle>
      <Divider />
      {hasMismatchedGrades
        ? <CourseMismatchText>
          Here are the fields that don’t match.
        </CourseMismatchText>
        : <CourseMatchText>
          All fields currently match!
        </CourseMatchText>
      }
      <CourseDetailsLink
        href='https://airtable.com/appwT9oHfFBuMDfsx/tblwkpumHV30lV063/viwkoUczDcaH8028T?blocks=hide'
        target='_blank'
        rel='noopener noreferrer'
      >
        See the full Airtable sheet here
      </CourseDetailsLink>
      <TableContainer>
        {students.map((student, index) => {
          const gradesLength = Math.max(
            Object.values(student.atGradeData).length,
            Object.values(student.projectedGrade).length
          )
          const atGradeDataKeys = Object.keys(student.atGradeData)

          return (
            <RowContainer key={index}>
              <Row>
                <StudentRowHeader>Student</StudentRowHeader>
                <RowContent>{student.atGradeData['student']}</RowContent>
                <RowContent />
              </Row>
              <Row>
                <RowHeader>System</RowHeader>
                <RowContent>LMS</RowContent>
                <RowContent>Airtable</RowContent>
              </Row>
              {Array.from({ length: gradesLength }).map((_, i) => {
                if (!atGradeDataKeys?.[i]) return null
                const isStudentField = atGradeDataKeys?.[i] === 'student'
                if (isStudentField) return null
                const isTotalGrade = atGradeDataKeys?.[i] === 'totalGrade'
                const isTotalGradeAndMatched = isTotalGrade &&
                (student.atGradeData?.totalGrade ===
                student.projectedGrade?.totalGrade)
                const isPercentGrade = atGradeDataKeys?.[i]
                  ?.toLowerCase()?.includes('percent')
                const lmsGrade = isPercentGrade
                  ? `${student.projectedGrade[atGradeDataKeys?.[i]]}%`
                  : student.projectedGrade[atGradeDataKeys?.[i]]
                const atGrade = isPercentGrade
                  ? `${student.atGradeData[atGradeDataKeys?.[i]]}%`
                  : student.atGradeData[atGradeDataKeys?.[i]]

                return (
                  <Row key={i}>
                    <RowHeader>{formatHeader(atGradeDataKeys?.[i])}</RowHeader>
                    <RowGrade isTotalGradeAndMatched={isTotalGradeAndMatched}>
                      {lmsGrade}
                    </RowGrade>
                    <RowGrade isTotalGradeAndMatched={isTotalGradeAndMatched}>
                      {atGrade}
                    </RowGrade>
                  </Row>
                )
              }
              )}
            </RowContainer>
          )
        }) }
      </TableContainer>
    </CourseDetailsWrapper>
  )
}
export default CourseDetails
CourseDetails.displayName = 'CourseDetails'

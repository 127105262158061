import moment from 'moment'
export const generatePaginationNumbers = (rows, currentPage, rowsPerPage) => {
  const totalPages = Math.ceil(rows / rowsPerPage)
  if (totalPages <= 1) return []
  if (totalPages <= 4) {
    return Array.from({ length: totalPages }, (_, i) => (i + 1).toString())
  }
  if (currentPage <= 3) {
    return ['1', '2', '3', '...', totalPages.toString(), 'Next']
  }
  if (currentPage >= totalPages - 3) {
    return [
      'Previous',
      '...',
      (totalPages - 3).toString(),
      (totalPages - 2).toString(),
      (totalPages - 1).toString(),
      totalPages.toString()
    ]
  }
  return [
    'Previous',
    '...',
    (currentPage - 1)?.toString(),
    currentPage?.toString(),
    (currentPage + 1)?.toString(),
    '...',
    totalPages.toString(),
    'Next'
  ]
}

export const createTemplates = (durationInfo) => {
  if (!durationInfo) return []
  const { createAuditCohorts, ...rest } = durationInfo
  return [
    rest,
    ...(createAuditCohorts ? [{ ...rest, audit: true }] : [])
  ]
}

export const generateTableData = ({
  selectedCourses,
  dates,
  durationInfo
}) => {
  if (!selectedCourses?.length || !dates?.length || !durationInfo) return []

  const templates = createTemplates(durationInfo)

  return templates.flatMap(starter => {
    const { cohortType, duration, linkCohortSchedules, audit = false } = starter

    return selectedCourses.flatMap(course => {
      const { label } = course

      return dates.map(date => ({
        name: `${label} - ${date}${cohortType === 'Extended' ? ' - 39 Weeks' : ''}${audit ? ' - Audit' : ''}`,
        duration,
        startDate: date,
        endDate: moment(date)
          .add(Number(duration) - 1, 'weeks')
          .format('M/D/YY'),
        schedule: linkCohortSchedules ? label?.replace(' ', '').toLowerCase() + cohortType + '1' : 'N/A'
      }))
    })
  })
}

import React, { useState } from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import { StyledImage, StyledImageTitle } from './styled'

const AttributedImage = (props) => {
  const [modalShow, setModalShow] = useState(false)

  const { alt = '', src, title } = props

  return (
    <>
      <div>
        <img
          onClick={() => setModalShow(true)}
          src={src}
          alt={alt}
          width='100%'
        />
        {title && (
          <StyledImageTitle dangerouslySetInnerHTML={{ __html: title }} />
        )}
      </div>
      <ModalComponent show={modalShow} handleClose={() => setModalShow(false)}>
        <StyledImage src={src} alt={alt} />
      </ModalComponent>
    </>
  )
}

export default AttributedImage

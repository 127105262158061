import { jsPDF as JSPDF } from 'jspdf'

/**
 * @param {String} string the submission text to converted into lines
 * @returns {Array} an array of strings, every one represent a line that can
 *   fit in the pdf to be created from the provided text
 */
export function convertTextToLines (string = '') {
  const pdfLines = []
  const lines = string.split('\n')
  if (!lines.length) return

  lines.forEach(line => {
    const isEmptyLine = line.trim().length === 0
    if (isEmptyLine) return pdfLines.push('\n')

    const lineChars = line.split('')
    let currentIndex = 0
    const pdfLineChars = 80 // the chars that can fit in one line in the pdf

    while (currentIndex < lineChars.length - 1) {
      const actualLine = lineChars.slice(currentIndex, currentIndex + pdfLineChars).join('')
      pdfLines.push(actualLine)
      currentIndex += pdfLineChars
    }

    const residuals = lineChars.slice(currentIndex).join('')
    if (residuals) pdfLines.push(residuals)
    pdfLines.push('\n')
  })

  return pdfLines
}

/**
 * @param {Array} lines An array of strings, everyone represent a line in the pdf to be created
 * @returns {Array} An array of strings everyone represent a page in the pdf to be created
 * Giving that the library does not create multiple pages by default
 */
export function getPdfPages (lines = []) {
  let currentIndex = 0
  const pages = []
  const pageLines = 31 // the lines that can fit in one page in the pdf

  while (currentIndex < lines.length - 1) {
    const page = lines.slice(currentIndex, currentIndex + pageLines).join('')
    pages.push(page)
    currentIndex += pageLines
  }

  const residuals = lines.slice(currentIndex).join('')
  if (residuals) pages.push(residuals)
  return pages
}

/**
 * @param {ArrayBuffer} data The array buffer from which would get the submission text
 * @param {String} fileType The file type of the ArrayBuffer included
 * @returns A doc pdf generated from the text in the ArrayBuffer provided from which we can get the blob
 * These util functions are needed because the jspdf does not calculate how much
 * text is going to fit in one page
 */
export function convertTextToPdf (data, fileType) {
  if (!data || !typeof data === 'object') return

  const doc = new JSPDF()
  const decoder = new TextDecoder()
  const submissionData = decoder.decode(data)
  const submissionString = fileType === 'text/plain'
    ? submissionData
    : JSON.parse(submissionData)?.assignmentHTML

  const lines = convertTextToLines(submissionString)
  const pages = getPdfPages(lines)
  const pagesLength = pages.length
  const firstPage = pages[0]

  // Check jspdf docs for more details
  const docOptions = { maxWidth: 190, lineHeightFactor: 1.6 }
  doc.text(firstPage, 10, 15, docOptions)

  if (pagesLength > 1) {
    let currentPageIndex = 1
    while (currentPageIndex < pagesLength) {
      doc.addPage()
      doc.text(pages[currentPageIndex], 10, 15, docOptions)
      currentPageIndex += 1
    }
  }

  return doc
}

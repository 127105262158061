import React, { useMemo } from 'react'
import {
  HeaderCell,
  OptionCheckBox,
  Row,
  RowContent,
  SecondaryBorder,
  SelectedText,
  StudentCell,
  StudentsTableWrapper
} from './index.style'
import { PAGES, studentsTableHeaders } from './utils/constants'
import { PrimaryButton } from '../../Components/global.styled'
import { EXIT_STATUSES } from '../../Constants/studentStatus'

function StudentsTable ({
  filteredStudents,
  selectedStudents,
  setSelectedData,
  setCurrentPage
}) {
  const selectableStudents = useMemo(() => {
    return filteredStudents?.filter(s => !EXIT_STATUSES.includes(s?.studentStatus))
  }, [filteredStudents])

  const handleSelectAll = () => {
    if (!filteredStudents?.length) return

    const isAllSelected = selectedStudents?.length === selectableStudents?.length
    if (isAllSelected) {
      return setSelectedData(prev => ({ ...prev, selectedStudents: [] }))
    }

    setSelectedData(prev => ({ ...prev, selectedStudents: selectableStudents }))
  }

  const handleSelectStudent = (student) => {
    const isSelected = selectedStudents?.some(s => s.id === student?.id)
    if (isSelected) {
      return setSelectedData(prev => ({
        ...prev,
        selectedStudents: selectedStudents?.filter(s => s.id !== student?.id)
      }))
    }

    setSelectedData(prev => ({
      ...prev, selectedStudents: [...selectedStudents, student]
    }))
  }

  const isAllChecked = !!selectableStudents?.length &&
    selectedStudents?.length === selectableStudents?.length

  const isStudentSelected = (student) => {
    return selectedStudents?.some(s => s.id === student?.id)
  }

  const handleContinueClick = () => {
    setCurrentPage(PAGES.review)
  }

  return (
    <>
      <StudentsTableWrapper>
        <Row>
          <OptionCheckBox
            disabled={!selectableStudents?.length}
            isChecked={isAllChecked}
            onClick={handleSelectAll}
          />

          <RowContent>
            {studentsTableHeaders.map(header => {
              const isEmail = header === 'email'

              return (
                <HeaderCell key={header} isEmail={isEmail}>
                  {header}
                </HeaderCell>
              )
            })}
          </RowContent>
        </Row>

        {filteredStudents?.map(student => {
          const isDisabled = EXIT_STATUSES.includes(student?.studentStatus)
          const isLastItem =
            filteredStudents?.length - 1 === filteredStudents?.indexOf(student)

          return (
            <Row
              key={student.email} isStudentRow
              isLastItem={isLastItem} isDisabled={isDisabled}
            >
              <OptionCheckBox
                isChecked={isStudentSelected(student)}
                onClick={() => handleSelectStudent(student)}
              />

              <RowContent>
                <StudentCell>{student.name}</StudentCell>
                <StudentCell isEmail>{student.email}</StudentCell>
                <StudentCell>{student.studentStatus}</StudentCell>
              </RowContent>
            </Row>
          )
        })}
      </StudentsTableWrapper>

      <SecondaryBorder />
      <SelectedText>
        {selectedStudents?.length}<span>Students selected</span>
      </SelectedText>

      <PrimaryButton
        disabled={!selectedStudents?.length}
        style={{ width: 'fit-content' }}
        onClick={handleContinueClick}
      >
        continue
      </PrimaryButton>
    </>
  )
}

StudentsTable.displayName = 'StudentsTable'
export default StudentsTable

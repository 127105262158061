import React from 'react'
import './StudentGradeDetails.css'

function CourseraActivityGradeDetails ({ score, label }) {
  return (
    <div className='learning'>
      <div className='learning-section'>
        <span className='title'>Scores</span>
        <div>
          <div className='section-data-item'>
            <div className='section-data-quiz'>{label}:</div>
            <div
              className='section-data-quiz'>{isNaN(score) ? '--' : `${score}%`}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CourseraActivityGradeDetails.displayName = 'CourseraActivityGradeDetails'

export default CourseraActivityGradeDetails

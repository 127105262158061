import { timeStampToFormattedDate } from './dateTimeUtil'
import { CHAPTER, CODING_ASSIGNMENT, WRITING_ASSIGNMENT } from '../Constants/chapter'

export {
  calculateRevisedScore,
  sectionNameToShortCode,
  getMedianGradeForSection,
  calculateMedian,
  getSectionGrade,
  areAllGradesNotAttempted,
  filterAttemptedGrades,
  isValidUrl,
  changeParticipationPercentToGrade,
  formatGradesLastActiveTimeStamp
}

export const MAX_PARTICIPATION_GRADE = 5
export const MIN_PARTICIPATION_GRADE = 0

function getMedianGradeForSection (gradeReport, section) {
  if (gradeReport.length === 0) return null
  const sectionGrade = getSectionGrade(gradeReport, section)
  const median = calculateMedian(sectionGrade)
  return median
}

function calculateMedian (sectionGrade) {
  if (
    sectionGrade.length === 0 || areAllGradesNotAttempted(sectionGrade)
  ) return 0

  // only select attempted grades(by removing grades with '')
  const filteredGrades = filterAttemptedGrades(sectionGrade)

  filteredGrades.sort(function (a, b) {
    return a - b
  })

  var half = Math.floor(filteredGrades.length / 2)

  if (filteredGrades.length % 2) return filteredGrades[half]
  return parseFloat(((filteredGrades[half - 1] + filteredGrades[half]) / 2.0).toFixed(2))
}

// checks if all quiz grades are not started
function areAllGradesNotAttempted (sectionGrade) {
  if (!sectionGrade) return
  return sectionGrade.every(grade => grade === '')
}

function filterAttemptedGrades (sectionGrade) {
  if (!sectionGrade) return
  return sectionGrade.filter(grade => grade !== '')
}

// returns grade of all students for a specfic section/exam
function getSectionGrade (gradeReport, sectionName) {
  return gradeReport.map(studentGrade => studentGrade[sectionName])
}

function sectionNameToShortCode (title, chapters) {
  if (!chapters?.length) return

  const chapterWithTitle = chapters.find(chapter => chapter.title === title)
  if (chapterWithTitle) {
    if (chapterWithTitle.type === WRITING_ASSIGNMENT) return 'Essay'
    if (chapterWithTitle.type === CODING_ASSIGNMENT) return 'Assignment'
    return chapterWithTitle.title
  }

  const sectionChapters = chapters.filter(chapter => chapter.type === CHAPTER)
  let sectionNumber = ''
  sectionChapters.forEach((chapter, index) => {
    if (sectionNumber) return
    if (!chapter?.sections?.length) return

    chapter.sections.forEach((section, sectionIndex) => {
      if (sectionNumber) return
      if (section.title !== title) return
      sectionNumber = `${index + 1}.${sectionIndex + 1}`
    })
  })

  return sectionNumber
}

function isValidUrl (string) {
  try {
    return new URL(string)
  } catch (_) {
    return false
  }
}

function changeParticipationPercentToGrade ({ percent, maxParticipationGrade }) {
  return parseFloat(parseFloat(percent * (maxParticipationGrade / 100)).toFixed(2))
}

function formatGradesLastActiveTimeStamp (grade) {
  if (!grade) return []
  return grade.map((studentData) => ({
    ...studentData,
    lastActive: timeStampToFormattedDate(studentData.lastActive)
  }))
}

function calculateRevisedScore (stepResults) {
  if (!Array.isArray(stepResults) || !stepResults.length) return 0
  const steps = []
  stepResults.forEach(result => {
    result.steps.forEach(step => {
      steps.push(step)
    })
  })
  const totalWeight = steps.reduce((x, y) => x + y.weight, 0)
  const totalAchievedPoints = steps.reduce((x, y) => x + y.achievedPoints, 0)
  return 100 * totalAchievedPoints / totalWeight
}

import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.position === 'top' ? 'flex-start' : 'center'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: ${props => props.show ? 1 : 0};
  z-index: ${props => props.show ? 1000 : -1};
  transition: opacity 0.5s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
`

export const ModalWrapper = styled.div`
  background: var(--gray-primary);
  box-shadow: 0px 6px 20px var(--shadow-primary);
  border-radius: 5px;
  ${props => props.width && `width: ${props.width};`}

  ${mediaqueries.smallMobile`
    width: calc(100% - 48px);
  `}

  ${props => props.isResponsive && `
    max-width: calc(100% - 48px);
    max-height: calc(100% - 48px);
    margin: auto;

    background: var(--black-secondary);
  `}
`

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`

export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 27px;
  background: var(--gray-primary);
`

export const ModalTitle = styled.h2`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: var(--white);
  margin: 0;
`

export const ModalCloseButton = styled.div`
  cursor: pointer;
`

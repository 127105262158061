import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Cell,
  HeaderItem,
  TableBody,
  TableContainer,
  TableDescription,
  TableDescriptionContainer,
  TableHeader,
  TableRow,
  TableTitle,
  ToolDescription
} from './styles'
import Filters from './Filters'
import api from '../../../../api'
import { tables } from './constants'
import DefaultState from './DefaultState'
import chevronRight from '../../../../assets/icons/chevron-right-teal.svg'
import { getTableData, groupRecords } from './utils'

function FlagsAndReports () {
  const [aiViolations, setAiViolations] = useState([])
  const [filtersData, setFiltersData] = useState({
    course: null,
    cohort: null,
    assessment: null,
    searchText: ''
  })

  const history = useHistory()
  const { cohort, assessment, searchText } = filtersData
  const cohortId = cohort?.value

  const filteredAiViolations = useMemo(() => {
    if (!aiViolations.length) return {}

    const filterViolations = searchText
      ? aiViolations.filter((violation) => {
        return violation.fields.student[0].fields.email
          .toLowerCase()
          .includes(searchText.toLowerCase())
      })
      : aiViolations
    const groupedRecords = groupRecords(filterViolations)
    const tableData = getTableData(groupedRecords)
    return tableData
  }, [aiViolations, searchText])

  useEffect(() => {
    if (!cohortId) return

    const getAiViolations = async () => {
      const violations = await api.searchAIViolations({
        filters: {
          approvalStatus: ['Approved', 'Denied'],
          cohortIds: [cohortId],
          ...(assessment && { assessments: [assessment.label] })
        }
      })

      setAiViolations(violations)
    }
    getAiViolations()
  }, [cohortId, assessment])

  const handleReset = () => {
    setFiltersData({
      course: null,
      cohort: null,
      assessment: null,
      searchText: ''
    })

    setAiViolations({})
  }

  return (
    <>
      <ToolDescription>
        Select a course and start date to view list of students.
      </ToolDescription>
      <Filters
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        handleReset={handleReset}
      />

      {Object.keys(filteredAiViolations).length === 0 && <DefaultState />}
      {Object.keys(filteredAiViolations).length > 0 &&
        tables.map((table) => {
          const { title, description, headers, key } = table
          return (
            <TableContainer>
              <TableDescriptionContainer>
                <TableTitle>
                  {title} <span>&#183;</span>{' '}
                  <span>{filteredAiViolations[key].length}</span>
                </TableTitle>
                {description && (
                  <TableDescription>{description}</TableDescription>
                )}
              </TableDescriptionContainer>
              <TableHeader>
                {headers.map((header, index) => (
                  <HeaderItem key={index}>{header.title}</HeaderItem>
                ))}
              </TableHeader>
              <TableBody>
                {filteredAiViolations[key].map((record, index) => {
                  return (
                    <TableRow
                      key={index}
                      onClick={() =>
                        history.push({
                          pathname: `/academic-integrity/student/${record.studentId}`,
                          state: { cohortId }
                        })
                      }
                      data-testid='table-row'
                    >
                      {headers.map((header, index) => (
                        <Cell key={index}>{header.accessor(record)}</Cell>
                      ))}
                      <img src={chevronRight} alt='chevron-icon' />
                    </TableRow>
                  )
                })}
              </TableBody>
            </TableContainer>
          )
        })}
    </>
  )
}

export default FlagsAndReports

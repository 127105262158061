import React from 'react'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TableBody } from './styled'

function CohortTable (props) {
  const {
    courseName,
    cohortName,
    startDate,
    duration,
    cohortId,
    disable,
    showCohortDetail
  } = props

  const ShowCohortDeatil = () => (
    <FontAwesomeIcon
      icon={faListAlt}
      className='detail-btn'
      onClick={() => showCohortDetail(cohortId)}
    />
  )

  return (
    <TableBody>
      <span>{cohortName}</span>
      <span className='placeholder' />
      <span className={'header'}>Course</span>
      <span className='course-text'>{courseName}</span>
      <span className={'header'}>Start Date</span>
      <span className='cohort-text'>{startDate}</span>
      <span className={'header'}>Duration</span>
      <span className='cohort-text'>{duration}</span>
      <span className='header'>Status</span>
      <span className='cohort-text'>{disable ? 'Disable' : 'Enable'}</span>
      <ShowCohortDeatil />
    </TableBody>
  )
}

export default CohortTable

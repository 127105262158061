import React, { useEffect, useState } from 'react'
import {
  CohortContainer,
  CohortName,
  FilterContainer,
  PageDescription,
  PageHeader,
  PageWrapper,
  SelectLabel,
  SelectOption,
  Table,
  TableContainer,
  ViolationsOverview,
  ViolationsOverviewContainer
} from './styles'
import GoToPreviousPageButton from '../../../Components/GoToPreviousPageButton/GoToPreviousPageButton'
import { PageTitle } from '../../../styles/text'
import CustomSelect from '../../../Components/CustomSelect/UpdatedSelect'
import api from '../../../api'
import { formatAttempt, transformRecords } from './utils'
import moment from 'moment'
import { tables } from './constants'
import RejectedViolationsTable from './RejectedViolationsTable'

function StudentOverview ({ routerProps: { history, match } }) {
  const [attempts, setAttempts] = useState([])
  const [selectedCohorts, setSelectedCohorts] = useState([])
  const [aiViolations, setAIViolations] = useState([])

  const studentId = match.params.studentId

  const studentFirstName =
    aiViolations?.[0]?.fields?.student[0]?.fields?.firstName || ''
  const studentLastName =
    aiViolations?.[0]?.fields?.student[0]?.fields?.lastName || ''
  const studentFullName =
    `${studentFirstName} ${studentLastName}`.trim() || '--'

  const transformedRecords = transformRecords(aiViolations)

  useEffect(() => {
    const getStudentAttempts = async () => {
      const cohortId = history.location.state?.cohortId
      const attempts = await api.getStudentAttempts({ studentId })
      const filteredAttempts = attempts.filter((attempt) =>
        ['PreDrop', 'Enrolled', 'Drop', 'Withdrawn', 'Completed'].includes(
          attempt.fields.studentStatus.fields.Name
        )
      )

      const formattedAttempts = filteredAttempts.map((attempt) =>
        formatAttempt(attempt)
      )
      setAttempts(formattedAttempts)

      if (cohortId) {
        const selectedCohort = filteredAttempts.find(
          (attempt) => attempt.fields.cohort.id === cohortId
        )
        setSelectedCohorts([formatAttempt(selectedCohort)])
        return
      }
      setSelectedCohorts(formattedAttempts)
    }
    getStudentAttempts()
  }, [studentId, history])

  useEffect(() => {
    if (!selectedCohorts.length) return
    const getAIViolations = async () => {
      const violations = await api.searchAIViolations({
        filters: {
          approvalStatus: ['Approved', 'Denied'],
          cohortIds: selectedCohorts.map((cohort) => cohort.value),
          studentId
        }
      })
      setAIViolations(violations)
    }
    getAIViolations()
  }, [selectedCohorts, studentId])

  const handleCohortChange = (selectedCohort) => {
    const { value } = selectedCohort || {}
    const isSelected = selectedCohorts?.find(
      (cohort) => cohort?.value === value
    )
    if (isSelected) {
      return setSelectedCohorts((prev) =>
        prev.filter((cohort) => cohort?.value !== value)
      )
    }
    setSelectedCohorts((prev) => [...prev, selectedCohort])
  }

  return (
    <PageWrapper>
      <GoToPreviousPageButton
        text='academic integrity'
        handleClick={() => history.push('/academic-integrity')}
      />
      <PageHeader>
        <PageTitle>{studentFullName}</PageTitle>
        <PageDescription>Academic integrity flags and reports</PageDescription>
      </PageHeader>
      <FilterContainer>
        <SelectLabel>Courses</SelectLabel>
        <CustomSelect
          height='48px'
          width='300px'
          hideSelectedOptions={false}
          value={selectedCohorts}
          options={attempts}
          placeholder='Select courses'
          onChange={(attempt) => handleCohortChange(attempt)}
          closeMenuOnSelect={false}
          isMulti
          formatOptionLabel={({ label, studentStatus }) => (
            <SelectOption>
              <p>{label}</p>
              <p>{studentStatus}</p>
            </SelectOption>
          )}
        />
      </FilterContainer>
      {transformedRecords?.length > 0 &&
        transformedRecords.map((record, index) => {
          const {
            cohortName,
            cohortStartDate,
            cohortEndDate,
            warnings,
            removals,
            reports,
            uniqueApproved,
            uniqueDenied
          } = record
          return (
            <CohortContainer key={index}>
              <CohortName>
                {cohortName} <span>&#183;</span>{' '}
                {moment(cohortStartDate).format('MMM DD, YYYY')} -{' '}
                {moment(cohortEndDate).format('MMM DD, YYYY')}
              </CohortName>
              <ViolationsOverviewContainer>
                <ViolationsOverview>
                  <span>{warnings}</span>Warnings
                </ViolationsOverview>
                <ViolationsOverview>
                  <span>{removals}</span>Removals
                </ViolationsOverview>
                <ViolationsOverview>
                  <span>{reports}</span>Reports
                </ViolationsOverview>
              </ViolationsOverviewContainer>
              <TableContainer>
                <Table style={{ minWidth: '2000px' }}>
                  <thead>
                    <tr>
                      {tables[0].headers.map((header, index) => (
                        <th key={index}>{header.title}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {uniqueApproved.map((record, index) => (
                      <tr key={index}>
                        {tables[0].headers.map((header, index) => (
                          <td key={index}>{header.accessor(record)}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
              <RejectedViolationsTable uniqueDenied={uniqueDenied} />
            </CohortContainer>
          )
        })}
    </PageWrapper>
  )
}

export default StudentOverview

import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { BroadcastChannel } from 'broadcast-channel'
import { useCoursesContext, useCoursesActions } from '../../contexts/Courses'
import Select, { components } from 'react-select'
import { toast } from 'react-toastify'
import api from '../../api'
import CohortFilter from './CohortFilter'
import {
  filterSelectStyles,
  paginationSelectStyles,
  SubHeadingMarginBottom,
  PaginationEntries,
  FiltersContainer,
  ResetFilterButton,
  CohortFilterWrapper,
  FilterWrapper,
  CheckedBox,
  CustomCheckBox,
  CustomOptionContainer,
  FilterLabel,
  CustomSelect,
  FilterHeader,
  SelectFiltersContainer
} from './styled'
import { ReactComponent as CaretDownIcon } from '../../assets/icons/chevron-down.svg'
import {
  useWritingGradeCenterContext,
  useWritingGradeCenterActions
} from '../../contexts/WritingGradeCenterContext'
import { GRADING_STATUS, SUBMISSION_STATUS } from '../../Constants'
import {
  getFormattedMilestones,
  getCohortIdsOfUngradedAssignments
} from '../../utilities/writingGradeCenterUtils'
import { courseSelectStyles } from '../global.styled'
import { getCoursesWithCourseId } from '../../utilities/courseUtils'
import { WGC_CHANNEL } from '../../Constants/broadcastChannels'
import { mapLimit } from '../../utilities'
import { additionalCourseIdToName } from '../../config'
import {
  COLLEGE_WRITING_I_GGU_V2_NAME
} from '../../Constants/courses'

function Filters ({
  isLoadingGrid,
  setIsLoadingGrid,
  setIsFetchingMilestones,
  setIsFetchingSubmissions,
  paginationOptions,
  entriesPerPage,
  setEntriesPerPage
}) {
  const {
    coursesWithAssignments,
    selectedCourse,
    selectedGradingStatuses,
    selectedSubmissionStatuses,
    selectedAssignments,
    defaultCohorts,
    selectedCohorts,
    allAssignments,
    isAllActiveCohortsSelected,
    isAllPastCohortsSelected
  } = useWritingGradeCenterContext()
  const {
    setCoursesWithAssignments,
    setSelectedCourse,
    setSelectedGradingStatuses,
    setSelectedSubmissionStatuses,
    setSelectedAssignments,
    setDefaultCohorts,
    setSelectedCohorts,
    setAllActiveCohortsSelected,
    setAllPastCohortsSelected,
    setAllAssignments,
    fetchSubmissions
  } = useWritingGradeCenterActions()

  const currentDate = moment()
  const activeCohorts = defaultCohorts.filter(cohort => {
    const {
      dateStart,
      finalExamEndTime,
      isPastCohortAndHasUngradedAssignments
    } = cohort

    if (!dateStart || !finalExamEndTime) return false
    const extendedEndTime = moment(finalExamEndTime).add(7, 'days')

    return (currentDate.isAfter(dateStart) &&
    currentDate.isBefore(extendedEndTime)) ||
    isPastCohortAndHasUngradedAssignments
  })

  const pastCohorts = defaultCohorts.filter(cohort => {
    const {
      finalExamEndTime,
      isPastCohortAndHasUngradedAssignments
    } = cohort

    if (!finalExamEndTime) return false

    const extendedEndTime = moment(finalExamEndTime).add(7, 'days')

    return currentDate.isAfter(extendedEndTime) &&
    !isPastCohortAndHasUngradedAssignments
  })

  const [isLoading, setIsLoading] = useState(false)
  const [cohortMilestones, setCohortMilestones] = useState([])

  const { allCourses } = useCoursesContext()
  const { fetchCourses } = useCoursesActions()

  useEffect(() => {
    if (!allCourses || !allCourses.length) {
      fetchCourses()
      return
    }

    if (coursesWithAssignments && coursesWithAssignments.length) return
    getCoursesWithAssignments()
    // eslint-disable-next-line
  }, [allCourses])

  useEffect(() => {
    if (!selectedCourse) return
    setIsLoadingGrid(true)
    setAllAssignments([])
    setSelectedGradingStatuses([GRADING_STATUS[0]])
    getCohorts(selectedCourse.id)
    // eslint-disable-next-line
  }, [selectedCourse])

  useEffect(() => {
    handleResetFilter()
    // eslint-disable-next-line
  }, [defaultCohorts])

  useEffect(() => {
    getMilestones()
    // eslint-disable-next-line
  }, [selectedCohorts])

  useEffect(() => {
    // filter assignments anytime the value of
    // selectedAssignments, and selectedCohorts change
    const assignmentNames = selectedAssignments.map(
      assignment => assignment.title
    )
    const cohortNames = selectedCohorts && selectedCohorts.length
      ? selectedCohorts.map(cohort => cohort.value) : []
    const newFilteredAssignments = [...allAssignments]
      .filter(assignment => assignmentNames.includes(assignment.title))
      .filter(assignment =>
        cohortNames.includes(assignment.cohortMilestone.cohortName)
      )
      .sort()

    if (!selectedCourse) return

    getCurrentSubmissions(newFilteredAssignments, cohortNames)

    // eslint-disable-next-line
  }, [selectedAssignments, allAssignments])

  useEffect(() => {
    const newAssignments = [...cohortMilestones].map(milestone => ({
      cohortMilestone: { ...milestone },
      ...selectedAssignments.find(
        assignment => assignment.assignmentUUID === milestone.datoAssignmentUUID
      )
    }))
      // sort from closest to deadline -- furthest from deadline
      .sort(
        (a, b) =>
          new Date(a.cohortMilestone.lockTime) -
          new Date(b.cohortMilestone.lockTime)
      )
    if (!newAssignments) return

    setAllAssignments(newAssignments)
    // eslint-disable-next-line
  }, [cohortMilestones])

  useEffect(() => {
    const broadcast = new BroadcastChannel(WGC_CHANNEL)
    const handleBroadcastEvent = async (e) => {
      if (!e.refetchGrades) return
      const assignmentNames = selectedAssignments.map(
        (assignment) => assignment.title
      )
      const cohortNames =
        selectedCohorts && selectedCohorts.length
          ? selectedCohorts.map((cohort) => cohort.value)
          : []
      const newFilteredAssignments = [...allAssignments]
        .filter((assignment) => assignmentNames.includes(assignment.title))
        .filter((assignment) =>
          cohortNames.includes(assignment.cohortMilestone.cohortName)
        )
        .sort()

      await fetchSubmissions({
        courseId: selectedCourse.id,
        cohortNamesList: cohortNames,
        assignmentList: newFilteredAssignments,
        fromCache: false
      })
    }

    // check if there are selected assignments and all assignments before adding event listener
    if (selectedAssignments?.length && allAssignments?.length) {
      broadcast.addEventListener('message', handleBroadcastEvent)
    }
    return () => {
      broadcast.removeEventListener('message', handleBroadcastEvent)
      broadcast.close()
    }
    // eslint-disable-next-line
  }, [selectedAssignments, allAssignments])

  const getCurrentSubmissions = async (newFilteredAssignments, cohortNames) => {
    setIsFetchingSubmissions(true)
    await fetchSubmissions({
      courseId: selectedCourse.id,
      cohortNamesList: cohortNames,
      assignmentList: newFilteredAssignments,
      fromCache: true
    })
    setIsFetchingSubmissions(false)
  }

  const getCoursesWithAssignments = async () => {
    setIsLoading(true)
    const filteredCourses = getCoursesWithCourseId(allCourses)

    try {
      const coursesAssignmentData = await mapLimit(filteredCourses, 4, async (course) => {
        const courseData = await api.getCourseData(course.id)
        if (!courseData || !courseData.chapters || !courseData.chapters.length) {
          course.hasWritingAssignment = false
          return course
        }
        course.hasWritingAssignment = courseData.chapters.some(
          chapter => chapter.type === 'WritingAssignmentChapterRecord'
        )
        course.writingAssignments = courseData.chapters
          .filter(chapter => chapter.type === 'WritingAssignmentChapterRecord')
          .map(writingAssignment => ({
            ...writingAssignment,
            assignmentUUID: writingAssignment.chapter_uuid,
            label: writingAssignment.title,
            value: writingAssignment.chapter_uuid
          }))
        return course
      })
      const coursesWithAssignments = coursesAssignmentData.filter(
        course => course.hasWritingAssignment
      )
      if (!coursesWithAssignments) setCoursesWithAssignments([])
      setCoursesWithAssignments(
        coursesWithAssignments.map(course => {
          return { ...course, label: course.name, value: course.id }
        }).filter(course => course.name !== COLLEGE_WRITING_I_GGU_V2_NAME)
      )
    } catch (e) {
      toast.error('Error while fetching courses, please reload the page')
    }
    setIsLoading(false)
  }

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    )
  }

  // custom option for react-select
  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <CustomOptionContainer>
          <span>{children}</span>
          <CheckedBox type='checkbox' defaultChecked={props.isSelected} />
          <CustomCheckBox className='check-mark' />
        </CustomOptionContainer>
      </components.Option>
    )
  }

  // custom value container for react-select
  const ValueContainer = props => {
    const { getValue, hasValue, children, options } = props
    const newChildren = [...children]
    const selectedValuesCount = getValue().length
    const singleSelectedValue = getValue()[0]
    const allSelected = selectedValuesCount === options.length

    const getChildren = () => {
      if (!hasValue) {
        return newChildren
      } else if (selectedValuesCount === 1) {
        newChildren[0] = singleSelectedValue.label
      } else {
        newChildren[0] = `${allSelected ? 'All' : selectedValuesCount} Selected`
      }
      return newChildren
    }

    return (
      <components.ValueContainer {...props}>
        {getChildren()}
      </components.ValueContainer>
    )
  }

  const getCohorts = async courseId => {
    let selectedCourseCohorts = await api.getAllCohorts({ courseId })

    // check for additional course id
    const additionalIdExists = additionalCourseIdToName(courseId)?.length > 1
    const additionalCourseId = additionalIdExists && additionalCourseIdToName(courseId)?.[1]
    const additionalCourseCohorts = additionalCourseId && await api.getAllCohorts({ courseId: additionalCourseId })

    const ids = await getCohortIdsOfUngradedAssignments(selectedCourseCohorts, courseId)
    if (additionalCourseCohorts.length) {
      selectedCourseCohorts = [...selectedCourseCohorts, ...additionalCourseCohorts]
      const additionalCohortIds = await getCohortIdsOfUngradedAssignments(additionalCourseCohorts, additionalCourseId)
      ids.push(...additionalCohortIds)
    }

    const cohorts = selectedCourseCohorts
      .filter(cohort => new Date(cohort.dateStart) < new Date())
      .sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart))

    setDefaultCohorts(
      cohorts.map(cohort => ({
        ...cohort,
        label: cohort.name,
        value: cohort.name,
        isPastCohortAndHasUngradedAssignments: ids.includes(cohort.at_id)
      }))
    )
    setIsLoadingGrid(false)
  }

  const getMilestones = async () => {
    if (!defaultCohorts.length) return

    setIsFetchingMilestones(true)
    const milestones = await Promise.all(selectedCohorts.map(async (cohort) => {
      const milestoneForEach = await api.getCohortMilestones(cohort.at_id)
      return milestoneForEach
    }))
    setIsFetchingMilestones(false)

    const formattedMilestones = getFormattedMilestones(selectedCohorts, milestones)

    setCohortMilestones(formattedMilestones)
  }

  const handleResetFilter = () => {
    setSelectedGradingStatuses([GRADING_STATUS[0]])
    setSelectedSubmissionStatuses(SUBMISSION_STATUS)
    setSelectedAssignments(writingAssignments || [])
    setSelectedCohorts(defaultCohorts)
    setAllActiveCohortsSelected(true)
    setAllPastCohortsSelected(true)
  }

  const writingAssignments = useMemo(() => {
    if (!selectedCourse) return []
    const { writingAssignments: currentWritingAssignments } = selectedCourse || {}
    const hasAdditionalCourseId =
      additionalCourseIdToName(selectedCourse?.id)?.length > 1
    const additionalCourseId =
      hasAdditionalCourseId && additionalCourseIdToName(selectedCourse?.id)?.[1]
    const additionalCourse = additionalCourseId &&
      allCourses.find(course => course.id === additionalCourseId)
    const additionalWritingAssignments = additionalCourse?.writingAssignments || []
    return [
      ...currentWritingAssignments || [],
      ...additionalWritingAssignments] || []
    // eslint-disable-next-line
  }, [selectedCourse])

  const isDefaultState =
    selectedGradingStatuses[0]?.label === GRADING_STATUS[0].label &&
    selectedGradingStatuses.length === 1 &&
    selectedSubmissionStatuses?.length === SUBMISSION_STATUS.length &&
    selectedAssignments?.length === writingAssignments?.length &&
    selectedCohorts?.length === defaultCohorts?.length &&
    isAllActiveCohortsSelected &&
    isAllPastCohortsSelected

  return (
    <>
      <SubHeadingMarginBottom hasCourse={selectedCourse}>
        {selectedCourse
          ? 'Course'
          : 'Select a course to view written assignments available for grading.'}
      </SubHeadingMarginBottom>
      <CustomSelect
        options={coursesWithAssignments}
        value={selectedCourse}
        isLoading={isLoading}
        onChange={selectedCourse => {
          setSelectedCourse(selectedCourse)
        }}
        id='courseSelectbox'
        components={{ DropdownIndicator }}
        placeholder='Select Course'
        styles={courseSelectStyles}
        classNamePrefix='select'
      />
      {selectedCourse && (
        <>
          <FiltersContainer>
            <FilterHeader>
              Filters
            </FilterHeader>
            <ResetFilterButton
              onClick={handleResetFilter}
              disabled={isLoadingGrid || isLoading || isDefaultState}
            >
              reset
            </ResetFilterButton>
          </FiltersContainer>
          <SelectFiltersContainer>
            <CohortFilterWrapper>
              <FilterLabel>Cohort</FilterLabel>
              <CohortFilter
                isLoadingGrid={isLoadingGrid}
                activeCohorts={activeCohorts}
                pastCohorts={pastCohorts}
              />
            </CohortFilterWrapper>
            <FilterWrapper>
              <FilterLabel>Assignment</FilterLabel>
              <CustomSelect
                options={writingAssignments}
                value={selectedAssignments}
                isLoading={isLoading}
                onChange={selectedAssignments =>
                  setSelectedAssignments(selectedAssignments)
                }
                id='assignment'
                components={{ Option, ValueContainer, DropdownIndicator }}
                placeholder='Filter By Assignment'
                styles={filterSelectStyles}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isSearchable={false}
                isClearable={false}
                isMulti
                classNamePrefix='select'
              />
            </FilterWrapper>
            <FilterWrapper>
              <FilterLabel>Submission Status</FilterLabel>
              <CustomSelect
                options={SUBMISSION_STATUS}
                value={selectedSubmissionStatuses}
                onChange={selectedSubmissionStatus => {
                  setSelectedSubmissionStatuses(selectedSubmissionStatus)
                }}
                components={{ Option, ValueContainer, DropdownIndicator }}
                placeholder='Filter By Submission Status'
                styles={filterSelectStyles}
                id='submissionStatus'
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isSearchable={false}
                isClearable={false}
                isMulti
                classNamePrefix='select'
              />
            </FilterWrapper>
            <FilterWrapper>
              <FilterLabel>Grading Status</FilterLabel>
              <CustomSelect
                options={GRADING_STATUS}
                value={selectedGradingStatuses}
                onChange={selectedGradingStatus => {
                  setSelectedGradingStatuses(selectedGradingStatus)
                }}
                components={{ Option, ValueContainer, DropdownIndicator }}
                placeholder='Filter By Grading Status'
                styles={filterSelectStyles}
                id='gradeStatus'
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isSearchable={false}
                isClearable={false}
                isMulti
                classNamePrefix='select'
              />
            </FilterWrapper>
          </SelectFiltersContainer>
          <FilterWrapper>
            <PaginationEntries>
              <Select
                options={paginationOptions}
                defaultValue={entriesPerPage}
                styles={paginationSelectStyles}
                components={{ DropdownIndicator }}
                isSearchable={false}
                onChange={entry => setEntriesPerPage(entry)}
                classNamePrefix='select'
              />
              <span>Entries shown</span>
            </PaginationEntries>
          </FilterWrapper>
        </>
      )}
    </>
  )
}

export default Filters

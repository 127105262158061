import styled from 'styled-components'
import { ReactComponent as InfoSVG } from '../../../assets/icons/info.svg'
import { ReactComponent as WarningSVG } from '../../../assets/icons/warning.svg'
import { ReactComponent as BackArrowSVG } from '../../../assets/icons/modal-back-arrow.svg'
import { ReactComponent as SuccessSVG } from '../../../assets/icons/icon-success.svg'
import { ReactComponent as CloseSVG } from '../../../assets/icons/modal-close.svg'

// MAIN PAGE

export const PageContainer = styled.div`
  margin: ${({ margin }) => margin || '15px 64px'};
`

export const PageHeader = styled.h1`
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  font-style: normal;
`

export const Separator = styled.hr`
  margin: ${({ margin }) => margin || '24px 0 48px'};
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`

export const OptionsContainer = styled.div`
  display: flex;
  gap: 32px;
  &:not(:last-child){
    margin-bottom: 48px;
  }
`

export const OptionContainer = styled.div`
  width: 346px;
  height: 152px;
  padding: 24px;

  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  cursor: pointer;
  border-radius: 5px;
  background-color: #25272B;

  &:hover {
    background-color: #2f3137;
  }
`

export const OptionTitle = styled.h2`
  height: 38px;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 16px;
`

export const TitleContainer = styled.div`
  display: flex;
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 12px 0 16px;
`

export const Description = styled.div`
  color: #B1BFC5;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`

// SECONDARY PAGE

export const BackLink = styled.a`
  display: flex;
  width: fit-content;
  margin-bottom: 32px;
  align-items: center;
  cursor: pointer;
  color: #5FC4B8 !important;

  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  
  &:hover {
    text-decoration: none;
    color: #B8FFF7 !important;
  }
`

export const BackArrowIcon = styled(BackArrowSVG)`
  width: 6px;
  height: 12px;
  margin-right: 12px;
`

// ELT Upload

export const PageIndicator = styled.div`
  height: 48px;
  width: fit-content;
  margin-bottom: 48px;
  padding: 12px 18px 12px 16px;

  display: flex;
  gap: 12px;
  align-items: center;

  border: 1px solid #FFFFFF;
  border-radius: 5px;
  box-sizing: border-box;
  background: #1D1D1F;

  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-transform: capitalize;
`

export const PageText = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  display: flex;
  align-items: center;
  margin-bottom: 16px;
`

export const InfoIcon = styled(InfoSVG)`
  width: 16px;
  height: 16px;
  margin-left: 12px;
`

export const UploaderContainer = styled.div`
  max-width: 600px;
  margin-bottom: ${({ hasError }) => hasError ? '36px' : '48px'};
`

export const ErrorMessage = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  font-style: normal;
  color: #FF8064;

  display: flex;
  align-items: center;
  margin-top: 18px;
`

export const WarningIcon = styled(WarningSVG)`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

export const SubmitButton = styled.button`
  height: 48px;
  padding: 16px 24px;
  border: none;
  border-radius: 5px !important;
  background-color: #5FC4B8;
  outline: none !important;

  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;

  :disabled {
    opacity: 0.3;
    color: #161618;
  }

  :hover:enabled {
    background-color: #B8FFF7;
  }
`

export const Modal = styled.div`
  width: 395px;
  padding: 24px;
  position: absolute;
  top: 80px;
  right: 64px;
  height: 72px;
  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #25272B;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
`

export const SuccessIcon = styled(SuccessSVG)`
  width: 16px;
  height: 16px;
  margin-right: 12px;
  path {
    fill: #98A4AE;
  }
`

export const CloseIcon = styled(CloseSVG)`
  width: 14px;
  height: 14px;
  cursor: pointer;
`

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;

  > p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    font-style: normal;
    margin-bottom: 0;
  }
`

// ELT Archive

export const TableContainer = styled.div`
  min-height: 576px;
  margin-top: 25px;
`

export const TableHeader = styled.div`
  height: 56px;
  align-items: center;
  display: grid;
  grid-template-columns: 200px 300px auto;
  gap: 16px;
  border-top: 1px solid #5B6067;
  border-bottom: 1px solid #5B6067;
`

export const HeaderItem = styled.span`
  margin: 0 16px;
  color: #98a4ae;
  line-height: 24px;
  display: flex;
  align-items: center;
`

export const TableRow = styled.div`
  height: 52px;
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-columns: 200px 300px auto;

  border-bottom: ${({ hideBorder }) => hideBorder ? 'none' : '1px solid #25272b'};
`

export const TableItem = styled.span`
  color: #ffffff;
  margin: 0 16px;

  &:last-child {
    color: #5fc4b8;
    cursor: pointer;
    justify-self: end;
    width: fit-content;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    font-style: normal;
    text-transform: uppercase;
  }

  ${({ hover }) => hover && `
    &:hover {
      color: #B8FFF7 !important;
    }
  `}
`

import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-top: 32px;
`

export const Content = styled.div`
  max-width: 600px;
  margin: 0px auto;
`

import React from 'react'
import {
  ExtensionRowWrapper,
  StudentCell,
  StudentRow
} from './style'
import rectanglePlus from '../../assets/icons/rectangle-plus.svg'
import rectangleMinus from '../../assets/icons/rectangle-minus.svg'
import ExtensionRecords from './ExtensionRecords'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

const ExtensionsTableRow = (props) => {
  const {
    extensionData,
    addExpandedRow,
    expandRow,
    extensionRecords,
    cohortFilter,
    courseFilter,
    goToEditExtension
  } = props
  const {
    studentId,
    email,
    studentExtensions: studentExtensionsCount
  } = extensionData
  const handleClick = async () => {
    addExpandedRow(studentId)
  }
  const isLoading = expandRow && extensionRecords.length < 1
  const icon = expandRow ? rectangleMinus : rectanglePlus
  return (
    <ExtensionRowWrapper data-testid='extensionsData' expandRow={expandRow}>
      <StudentRow onClick={() => { handleClick() }}>
        <StudentCell data-label='Student ID' style={{ width: 319 }}>{studentId}</StudentCell>
        <StudentCell data-label='Email' style={{ width: 403 }}>{email}</StudentCell>
        <StudentCell data-label='Total Extensions'>{studentExtensionsCount}</StudentCell>
        <StudentCell style={{ justifyContent: 'end' }}>
          <img src={icon} alt='rectangle-plus' />
        </StudentCell>
      </StudentRow>
      {expandRow &&
        (isLoading
          ? <LoadingSpinner />
          : <ExtensionRecords
            studentExtensionsRecords={extensionRecords}
            cohortFilter={cohortFilter}
            courseFilter={courseFilter}
            goToEditExtension={goToEditExtension}
          />
        )}
    </ExtensionRowWrapper>
  )
}

ExtensionsTableRow.displayName = 'ExtensionsTableRow'
export default ExtensionsTableRow

import { getCourseIds } from '../config'
import {
  PRESET_EXPORT_INFO_ARRAY,
  COURSES, TERM_LENGTHS, UNIVERSITY_PARTNERS, STUDENT_IDENTIFICATIONS,
  STUDENT_CONTACT_INFO, OTHER_STUDENT_INFO, COHORT_INFO, PITT_COURSE_NAMES
} from '../Constants/CSVDownloadTool'
import {
  secondsSinceEpoch,
  dateToSecondsSinceEpoch,
  getUSDateStringFormat } from './dateTimeUtil'
import {
  COMPLETED,
  ENROLLED } from '../Constants/studentStatus'

export function mapCSVCoursesToCoursesName (courses = [], uniPartners = []) {
  const [
    calculus, astronomy, psychology, statistics, philosophy, microeconomics
  ] = COURSES
  const [, otherPartners] = UNIVERSITY_PARTNERS

  const includeCalculusFP = (
    courses.includes(calculus) && uniPartners.includes(otherPartners)
  )

  const mappedCourses = courses.map(course => {
    if (course === calculus) return 'calculus'
    if (course === astronomy) return 'astronomy'
    if (course === psychology) return 'psychology'
    if (course === statistics) return 'statistics'
    if (course === philosophy) return 'philosophy'
    if (course === microeconomics) return 'microeconomics'
    return undefined
  })

  // Insert y.calculus (Calculus FP) after calculus if otherPartners determined
  if (includeCalculusFP) {
    mappedCourses.splice(1, 0, 'y.calculus')
  }

  return mappedCourses
}

export function mapTermLengthsToNumbers (termLengths = []) {
  const [termLength14, termLength7] = TERM_LENGTHS

  return termLengths.map(termLength => {
    if (termLength === termLength14) return 14
    if (termLength === termLength7) return 7
    return undefined
  })
}

/**
 * @param {Array} uniPartners Array of university partners
 * @returns An array of booleans, that includes false for pitt university and/or
 *   true for non outlier cohorts
 */
export function mapUniPartnersToBooleans (uniPartners = []) {
  const [pittsburghUniversity, otherPartners] = UNIVERSITY_PARTNERS

  return uniPartners.map(partner => {
    if (partner === pittsburghUniversity) return false
    if (partner === otherPartners) return true
    return undefined
  })
}

/**
 * @returns The params from string form to criteria form requested for the api
 */
export function getStudentCriteria (courses, termLengths, uniPartners) {
  const coursesNames = mapCSVCoursesToCoursesName(courses, uniPartners)
  const courseIds = getCourseIds()
  const coursesIds = coursesNames.map(name => courseIds[name])

  const termLengthsNumbers = mapTermLengthsToNumbers(termLengths)
  const uniPartnersBooleans = mapUniPartnersToBooleans(uniPartners)

  return {
    courses: coursesIds,
    termLengths: termLengthsNumbers,
    partners: uniPartnersBooleans
  }
}

/**
 * @param {Object} exportedInfo Containing the exported info Arrays
 * @returns {Object} The exportedInfo Array and the mapped keys to extract
 *   the students info from student objects
 */
export function mapExportedStudentInfo (exportedInfo) {
  const {
    studentIdentifications, studentContactInfo, otherStudentInfo, cohortInfo
  } = exportedInfo || {}

  const [
    fullLegalName, preferredName, studentId, attemptID, dateOfBirth, gender, citizenship
  ] = STUDENT_IDENTIFICATIONS
  const [
    emailAddress, phoneNumber, mailingAddress
  ] = STUDENT_CONTACT_INFO
  const [
    numericalGrade, letterGrade, enrollmentStatus, statusChangeDate
  ] = OTHER_STUDENT_INFO
  const [
    cohortName, startDate, duration, partnerUniversity
  ] = COHORT_INFO

  let exportedInfoArray = [
    ...studentIdentifications, ...studentContactInfo, ...otherStudentInfo,
    ...cohortInfo
  ]

  // Replace Full Legal Name with Legal First & Last Name with order
  if (exportedInfoArray.includes(fullLegalName)) {
    exportedInfoArray.shift()
    exportedInfoArray.unshift('Legal Last Name')
    exportedInfoArray.unshift('Legal First Name')
  }

  // Replace mailing address with the detailed mailing address information
  if (exportedInfoArray.includes(mailingAddress)) {
    const mailingAddressIndex = exportedInfoArray.indexOf(mailingAddress)
    const firstHalf = exportedInfoArray.slice(0, mailingAddressIndex)
    const secondHalf = exportedInfoArray.slice(mailingAddressIndex + 1)

    const mailingAddressInfo = [
      'Mailing Address 1', 'Mailing Address 2', 'City', 'Zip', 'State/Province',
      'Country'
    ]

    exportedInfoArray = [...firstHalf, ...mailingAddressInfo, ...secondHalf]
  }
  // eslint-disable-next-line array-callback-return
  const mappedExportedInfo = exportedInfoArray.map(info => {
    switch (info) {
      case 'Legal First Name': return 'firstName'
      case 'Legal Last Name': return 'lastName'
      case preferredName: return 'preferredName'
      case studentId : return 'id'
      case attemptID : return 'attemptID'
      case dateOfBirth : return 'dateOfBirth'
      case gender : return 'gender'
      case citizenship : return 'citizenship'
      case emailAddress : return 'email'
      case phoneNumber : return 'phoneNumber'
      case 'Mailing Address 1': return 'address1'
      case 'Mailing Address 2': return 'address2'
      case 'City': return 'city'
      case 'Zip': return 'postalCode'
      case 'State/Province': return 'state'
      case 'Country': return 'country'
      case numericalGrade: return 'numericalGrade'
      case letterGrade: return 'letterGrade'
      case enrollmentStatus: return 'studentsStatus'
      case statusChangeDate: return 'statusChangeDate'
      case cohortName: return 'cohortName'
      case startDate: return 'dateStart'
      case duration: return 'duration'
      case partnerUniversity: return 'isPartnerCohort'
      default: break
    }
  })

  return { exportedInfoArray, mappedExportedInfo }
}

/**
 * @param {Object} exportedInfo An Object of Arrays for the selected info
 * @param {Array} students An array of student object
 * @returns {Array} An Array of Arrays of Header and student info for CSV file
 */
export function getCSVExportedStudentInfo (exportedInfo, students) {
  const {
    exportedInfoArray, mappedExportedInfo
  } = mapExportedStudentInfo(exportedInfo)

  const studentsInfo = students.map(student => {
    const studentInfo = mappedExportedInfo.map(infoKey => {
      if (['dateOfBirth', 'dateStart'].includes(infoKey)) {
        return getUSDateStringFormat(student[infoKey])
      }

      if (infoKey === 'isPartnerCohort') {
        return student[infoKey] ? 'Partner' : 'Outlier'
      }

      if (!student[infoKey]) return ''

      return student[infoKey]
    })

    return studentInfo
  })

  return [exportedInfoArray, ...studentsInfo]
}

/**
 * @param {string} selectedPreset
 * @param {Array} students
 * @param {Array} allCohorts to fetch cohort info not
 * available in students array
 * @returns {Array} formatted csv data with headers and student info
 * @description students array has multiple entries for same student
 * with different cohort. Same students are merged into one,
 * with their cohort info in a studentCohorts field, and
 * passed to formatPresetStudentInfo function
 */
export function getPresetStudentInfo (
  selectedPreset,
  students,
  allCohorts) {
  if (!students || !students.length) return []

  const availableCourses = []
  const studentsWithCohortInfo = []

  students.forEach(student => {
    const studentExists = studentsWithCohortInfo.filter(studentWithCohorts =>
      studentWithCohorts.id === student.id)
    if (studentExists && studentExists.length) return null

    const studentCohorts = students
      .filter(s => s.id === student.id)
      .map(s => {
        const {
          cohortId,
          cohortName,
          studentsStatus,
          letterGrade } = s
        const currentCohort = allCohorts.find(cohort =>
          cohort.at_id === cohortId)
        const {
          course: [courseId] = [],
          dateStart,
          finalExamEndTime } = currentCohort || {}

        // store unique course Ids in availableCourses
        if (!availableCourses.includes(courseId)) {
          availableCourses.push(courseId)
        }

        return {
          courseId,
          cohortId,
          cohortName,
          cohortStartDate: dateToSecondsSinceEpoch(new Date(dateStart)),
          cohortEndDate: dateToSecondsSinceEpoch(new Date(finalExamEndTime)),
          studentsStatus,
          letterGrade
        }
      })

    studentsWithCohortInfo.push({
      ...student,
      studentCohorts
    })
  })

  return formatPresetStudentInfo(
    selectedPreset,
    availableCourses,
    studentsWithCohortInfo
  )
}

/**
 * @param {string} selectedPreset
 * @param {Array} availableCourses unique courseIds collected from the students array
 * @param {Array} studentsWithCohortInfo
 * @returns {Array} formatted csv data with headers and student info
 * @description Each course becomes a column in the generated csv.
 * If student took multiple cohorts in the same course, the
 * statuses are comma separated in the corresponding course column.
 */
function formatPresetStudentInfo (
  selectedPreset,
  availableCourses,
  studentsWithCohortInfo
) {
  const exportInfoArray = PRESET_EXPORT_INFO_ARRAY[selectedPreset]
  const mappedExportInfoArray = getMappedExportInfoArray(exportInfoArray)

  const courseNames = availableCourses.map(courseId =>
    PITT_COURSE_NAMES[courseId])
  const insertPosition = exportInfoArray.indexOf('Date of Birth') + 1
  const courseHeaders = [
    ...exportInfoArray.slice(0, insertPosition),
    ...courseNames,
    ...exportInfoArray.slice(insertPosition)
  ]

  const studentsInfo = studentsWithCohortInfo.map(student => {
    const info = mappedExportInfoArray.map(infoKey => {
      return student[infoKey]
    })

    const { studentCohorts } = student
    const courseInfo = availableCourses.map(courseId => {
      const cohorts = studentCohorts.filter(cohort =>
        courseId === cohort.courseId)

      const currentDate = secondsSinceEpoch()

      const enrollmentStatus = cohorts
        .sort((a, b) => a.cohortStartDate - b.cohortStartDate)
        .map(cohort => {
          const {
            cohortStartDate,
            cohortEndDate,
            studentsStatus,
            letterGrade } = cohort
          const isCompleteCohort = cohortEndDate < currentDate
          const isInProgressCohort = cohortStartDate < currentDate &&
            cohortEndDate > currentDate
          const isNotStartedCohort = currentDate < cohortStartDate

          let status = ''
          if (isCompleteCohort) {
            status = studentsStatus === COMPLETED
              ? letterGrade : studentsStatus
          } else if (isInProgressCohort || isNotStartedCohort) {
            status = studentsStatus === ENROLLED
              ? 'Yes' : studentsStatus
          }

          return status
        })

      return enrollmentStatus.join(', ')
    })

    info.splice(insertPosition, 0, ...courseInfo)
    return info
  })

  return [courseHeaders, ...studentsInfo]
}

function getMappedExportInfoArray (exportInfoArray) {
  // eslint-disable-next-line
  return exportInfoArray.map(info => {
    switch (info) {
      case 'First Name': return 'firstName'
      case 'Last Name': return 'lastName'
      case 'Email' : return 'email'
      case 'Country of Citizenship' : return 'citizenship'
      case 'Phone Number' : return 'phoneNumber'
      case 'Sex' : return 'gender'
      case 'Date of Birth' : return 'dateOfBirth'
      case 'Mailing Address 1': return 'address1'
      case 'Mailing Address 2': return 'address2'
      case 'City': return 'city'
      case 'Zip': return 'postalCode'
      case 'State/Province': return 'state'
      case 'Country': return 'country'
      default: break
    }
  })
}

export function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export function arrayToCSV (dataArray, delimiter = ',') {
  if (!Array.isArray(dataArray) || !dataArray.length) return

  const rows = []
  const headers = Object.keys(dataArray[0])
  rows.push(headers.join(delimiter))
  dataArray.forEach(item => {
    const rowValues = headers.map(header => item[header])
    rows.push(rowValues.join(delimiter))
  })

  return rows.join('\n')
}

import React, { useEffect, useRef, useState } from 'react'
import { DropdownItem } from 'reactstrap'
import PersonIcon from '../../../../assets/icons/person.svg'
import useWindowSize from '../../../../Hooks/useWindowSize'
import { isOverflownX } from '../../../../utilities/domUtils'
import HorizontalEllipsis from '../../../HorizontalEllipsis'
import VerticalEllipsis from '../../../VerticalEllipsis'
import ActionLink from './ActionLink'
import AdditionalDetail from './AdditionalDetail'
import {
  Container,
  Icon,
  ParticipantNumber,
  Left,
  Right,
  Time,
  Message,
  StyledDropdownToggle,
  StyledDropdown,
  StyledDropdownMenu
} from './styled'

const NotificationItem = ({ notification }) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [isLeftOverflown, setIsLeftOverflown] = useState(false)
  const [width] = useWindowSize()
  const leftContentRef = useRef()

  useEffect(() => {
    if (!leftContentRef.current) return
    setIsLeftOverflown(isOverflownX(leftContentRef.current))
  }, [width])

  const {
    time,
    headline,
    numberOfRecipients,
    additionalDetail,
    actionLinks
  } = notification

  return (
    <Container>
      <Left ref={leftContentRef}>
        <Time>{time}</Time>
        <Icon src={PersonIcon} mr={6} alt='Person Icon' />
        <ParticipantNumber>{numberOfRecipients}</ParticipantNumber>
        <Message>
          {headline}
        </Message>
        <AdditionalDetail msg={additionalDetail} />
        <ActionLink links={actionLinks} />
      </Left>
      {isLeftOverflown && <HorizontalEllipsis />}
      <Right>
        <StyledDropdown
          isOpen={showDropdown}
          direction='left'
          toggle={() => setShowDropdown(!showDropdown)}
        >
          <StyledDropdownToggle>
            <VerticalEllipsis />
          </StyledDropdownToggle>
          <StyledDropdownMenu>
            <DropdownItem>View</DropdownItem>
            <DropdownItem>Edit</DropdownItem>
            <DropdownItem>Delete</DropdownItem>
          </StyledDropdownMenu>
        </StyledDropdown>
      </Right>
    </Container>
  )
}

export default NotificationItem

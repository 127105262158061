import React, { useState, useEffect, useRef } from 'react'
import exclamationCircleError from '../../assets/icons/exclamation-circle-error.svg'
import { ReactComponent as CheckMark } from '../../assets/icons/icon-checkmark.svg'
import api from '../../api'
import {
  ButtonsContainer,
  Container,
  CourseProgressErrorContainer,
  Description,
  StudentAccounts,
  StudentAccountsContainer,
  StudentAccountsDetails,
  StudentAccountsDetailsContainer,
  StudentAccountsDetailsDescription,
  StudentAccountsDetailsTitle,
  StudentAccountsInputs,
  StudentAccountsTitle,
  Title,
  ToastDescription,
  ToastTitle
} from './styled'
import { PrimaryButton, SecondaryButton } from '../global.styled'
import Toast from '../ToastComponent/Toast'
import AccountDetails from './AccountDetails'
import AccountInput from './AccountInput'
import ReviewMerge from './ReviewMerge'
import MergeConfirmationModal from './MergeConfirmationModal'
import MergeInProgress from './MergeInProgress'
import ErrorScreen from './ErrorScreen'

function MergeAccounts () {
  const [student1Details, setStudent1Details] = useState({})
  const [student2Details, setStudent2Details] = useState({})
  const [studentNeedsReview, setStudentNeedsReview] = useState(false)
  const [primaryAccount, setPrimaryAccount] = useState(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [mergeStatus, setMergeStatus] = useState(null)
  const [finalAccount, setFinalAccount] = useState(null)

  const bothAccountsArePopulated =
    student1Details?.email && student2Details?.email
  const bothAccountsHaveProgress =
    student1Details?.haveProgress && student2Details?.haveProgress
  const showReview = !studentNeedsReview &&
    bothAccountsArePopulated && !bothAccountsHaveProgress && primaryAccount
  const isAccount1Primary = primaryAccount === 'Account 1'
  const primaryAccountDetails = isAccount1Primary ? student1Details : student2Details
  const secondaryAccountDetails = isAccount1Primary ? student2Details : student1Details

  const toast = useRef()

  useEffect(() => {
    if (mergeStatus === 'success') {
      toast.current.display(
        <ToastTitle>
          <CheckMark /><div>Accounts successfully merged!</div>
        </ToastTitle>,
        <ToastDescription>
          <b>{finalAccount?.email}</b> can now access their courses with this account.
        </ToastDescription>
      )
    }
  }, [mergeStatus, finalAccount])

  const handleMergeAccounts = async () => {
    setMergeStatus('in progress')
    setShowConfirmationModal(false)
    const { success } = await api.mergeAccounts({
      primaryEmail: primaryAccountDetails?.email,
      secondaryEmail: secondaryAccountDetails?.email
    })
    if (!success) {
      return setMergeStatus('error')
    }
    setFinalAccount(primaryAccountDetails)
    setStudent1Details({})
    setStudent2Details({})
    setMergeStatus('success')
  }

  if (mergeStatus === 'in progress') {
    return <MergeInProgress />
  }

  if (mergeStatus === 'error') {
    return <ErrorScreen
      setMergeStatus={setMergeStatus}
    />
  }

  return <>
    <Toast toast={toast} />
    <Container>
      {showConfirmationModal &&
        <MergeConfirmationModal
          primaryEmail={primaryAccountDetails?.email}
          secondaryEmail={secondaryAccountDetails?.email}
          setShowConfirmationModal={setShowConfirmationModal}
          handleMergeAccounts={handleMergeAccounts}
        />
      }
      <Title>
        Merge Accounts
      </Title>
      <Description>
        Merge accounts for users that have more than one student record. <b>Note:</b> The tool is unable to transfer student progress if both accounts have progress.
      </Description>
      <StudentAccountsContainer>
        <StudentAccounts>
          <StudentAccountsTitle>
            Student Accounts
          </StudentAccountsTitle>
          <StudentAccountsInputs>
            <AccountInput
              label='Account 1'
              setStudentDetails={setStudent1Details}
              setStudentNeedsReview={setStudentNeedsReview}
              setPrimaryAccount={setPrimaryAccount}
            />
            <AccountInput
              label='Account 2'
              setStudentDetails={setStudent2Details}
              setStudentNeedsReview={setStudentNeedsReview}
              setPrimaryAccount={setPrimaryAccount}
            />
          </StudentAccountsInputs>
        </StudentAccounts>
        <StudentAccountsDetailsContainer>
          <StudentAccountsDetailsTitle>
            Which account is the primary record?
          </StudentAccountsDetailsTitle>
          <StudentAccountsDetailsDescription>
            Once merged, the unselected account will be deleted.
          </StudentAccountsDetailsDescription>
          {studentNeedsReview && (
            <CourseProgressErrorContainer>
              <img src={exclamationCircleError} alt='exclamation-circle' />
              <div>
                {studentNeedsReview} has a Needs Reviewing attempt record
                that needs to be reviewed by Student Success
                and changed to a different status.
              </div>
            </CourseProgressErrorContainer>
          )}
          {bothAccountsHaveProgress && (
            <CourseProgressErrorContainer>
              <img src={exclamationCircleError} alt='exclamation-circle' />
              <div>
                Both accounts have course progress. Please merge these accounts manually.
              </div>
            </CourseProgressErrorContainer>
          )}
          <StudentAccountsDetails>
            <AccountDetails
              accountTitle='Account 1'
              email={student1Details?.email}
              studentId={student1Details?.studentId}
              numberOfAttemptRecords={student1Details?.attempts?.length}
              doesAccountContainProgress={student1Details?.haveProgress}
              primaryAccount={primaryAccount}
              setPrimaryAccount={setPrimaryAccount}
            />
            <AccountDetails
              accountTitle='Account 2'
              email={student2Details?.email}
              studentId={student2Details?.studentId}
              numberOfAttemptRecords={student2Details?.attempts?.length}
              doesAccountContainProgress={student2Details?.haveProgress}
              primaryAccount={primaryAccount}
              setPrimaryAccount={setPrimaryAccount}
            />
          </StudentAccountsDetails>
        </StudentAccountsDetailsContainer>
      </StudentAccountsContainer>
      { showReview && <ReviewMerge
        primaryAccount={primaryAccount}
        student1Details={student1Details}
        student2Details={student2Details}
      />}
      <ButtonsContainer>
        <PrimaryButton
          disabled={!showReview}
          onClick={() => setShowConfirmationModal(true)}
        >
          Merge Accounts
        </PrimaryButton>
        <SecondaryButton>
          Cancel
        </SecondaryButton>
      </ButtonsContainer>
    </Container>
  </>
}

export default MergeAccounts

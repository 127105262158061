import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  PageTitle,
  PrimaryButton,
  Separator
} from '../../global.styled'
import {
  ActionButtonContainer, ActionButtonStyle,
  AddStudentButton, DotSeparator,
  EnrollStudentForm, FormDescription,
  FormInput,
  FormLabel, FormRow,
  FormTitle,
  PageWrapper, StudentName,
  Badge, StudentTokens,
  WarningMessage
} from '../style'
import Button from '../../Button/Button'
import { validateEmail } from '../../../utilities/userUtils'
import api from '../../../api'
import {
  getTokensRelationships
} from '../../../utilities/tokenUtils'
import WarningIcon from '../../../assets/icons/icon-red-warning.svg'
import isEmpty from 'lodash/isEmpty'
import PartnerEnrollment from './PartnerEnrollment'
import EnrollmentDetails from './EnrollmentDetails'
import PaymentDetails from './PaymentDetails'
import ReviewEnrollmentDetails from './ReviewEnrollmentDetails'
import ProgressBar from '../ProgressBar/ProgressBar'
import ErrorMessage from '../ErrorMessage/ErrorMessage'
import { SuccessIcon } from '../../AdminStudentDrop/styled'
import Toast from '../../ToastComponent/Toast'

const EnrollStudent = () => {
  const studentDetailsRef = useRef(null)
  const enrollmentDetailsRef = useRef(null)
  const paymentDetailsRef = useRef(null)
  const relationshipDetailsRef = useRef(null)
  const toast = useRef()
  const [studentData, setStudentData] = React.useState(null)
  const [studentTokens, setStudentsTokens] = useState(null)
  const [availableTokens, setAvailableTokens] = useState([])
  const [isVIPStudent, setIsVIPStudent] = useState(false)
  const [isTestStudent, setIsTestStudent] = useState(false)
  const [studentNotFound, setStudentNotFound] = useState(false)
  const [onBehalfOfPartner, setOnBehalfOfPartner] = useState(
    { partner: null }
  )
  const [degreePlusEnrollment, setDegreePlusEnrollment] = useState({
    degreePlus: null
  })
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedDuration, setSelectedDuration] = useState({})
  const [selectedIsAudit, setSelectedIsAudit] = useState({})
  const [showNextStep, setShowNextStep] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)
  const [shopifyOrderNumber, setShopifyOrderNumber] = useState('')
  const [tokenOption, setTokenOption] = useState({})
  const [tokenOptionType, setTokenOptionType] = useState({})
  const [selectedCohort, setSelectedCohort] = useState(null)

  const [selectedRelationship, setSelectedRelationship] = useState(null)
  const [partnerAvailableTokens, setPartnerAvailableTokens] = useState(null)

  const [prospectData, setProspectData] = useState({})
  const [zendeskTicket, setZendeskTicket] = useState('')
  const [isEnrollStudentLoading, setIsEnrollStudentLoading] = useState(false)
  const [errorMessageText, setErrorMessageText] = useState(null)

  const resetState = () => {
    setStudentData(null)
    setStudentsTokens(null)
    setAvailableTokens([])
    setIsVIPStudent(false)
    setIsTestStudent(false)
    setProspectData({})
    setStudentNotFound(false)
  }

  const fetchProspectData = async (studentEmail) => {
    const { data: prospect } = await api.getProspectsData(studentEmail)
    if (prospect?.length) {
      setProspectData({
        isDegreeStudent: prospect[0]?.degreeStatus === 'Active',
        degree: prospect[0]?.degree,
        gguSemester: prospect[0]?.gguSemesters[0]?.term
      })
    }
  }

  const fetchData = async (studentEmail) => {
    const [data, tokens] = await Promise.all([
      api.getStudentData(studentEmail),
      api.getStudentTokens(studentEmail)
    ])

    if (!data || isEmpty(data)) {
      resetState()
      setStudentNotFound(true)
      return
    }
    setStudentNotFound(false)
    if (!data || !tokens) return
    if (data.message) return

    const { vip, contractorEmployee } = data || {}
    fetchProspectData(studentEmail)

    setIsVIPStudent(vip)
    setIsTestStudent(contractorEmployee)
    setStudentData({ ...data, email: studentEmail })

    const unusedTokens = tokens?.length
      ? tokens.filter((token) => token.usedStatus === null)
      : []
    setAvailableTokens(unusedTokens)

    setStudentsTokens(getTokensRelationships(unusedTokens))
  }

  const onEmailChange = (e) => {
    const studentEmail = e.target.value
    if (!validateEmail(studentEmail) || studentEmail === '') {
      return resetState()
    }
    fetchData(studentEmail)
    setZendeskTicket('')
    setOnBehalfOfPartner({ partner: null })
  }

  const tokensDisplayByType = () => {
    let iterator = 0
    return Object.keys(studentTokens).map((key) => {
      const tokenCount = studentTokens[key]
      let tokensDisplayByType = (<>{tokenCount} {key}</>)
      if (iterator < Object.keys(studentTokens).length - 1) {
        iterator++
        tokensDisplayByType = (
          <>{tokensDisplayByType}<DotSeparator>.</DotSeparator></>
        )
      }
      return tokensDisplayByType
    })
  }

  const resetEnrollmentDetails = () => {
    setSelectedCourse(null)
    setSelectedCohort(null)
    setSelectedDuration({})
    setSelectedIsAudit({})
    setSelectedDate(null)
  }

  const resetPaymentDetails = () => {
    setShopifyOrderNumber('')
    setTokenOption({})
    setTokenOptionType({})
  }

  const handleDegreePlusChange = (e) => {
    setDegreePlusEnrollment(e)
  }

  const handlePartnerEnrollmentChange = (e) => {
    setOnBehalfOfPartner(e)
    setSelectedRelationship(null)
    setPartnerAvailableTokens(null)
    setShowNextStep(false)
    resetEnrollmentDetails()
    resetPaymentDetails()
  }

  const showZendeskTicket = studentData && !isTestStudent && !isVIPStudent
  const showPartnerEnrollment = (showZendeskTicket && zendeskTicket) ||
    (isTestStudent || isVIPStudent)

  const showEnrollmentDetails = studentData && (onBehalfOfPartner.partner === false ||
    degreePlusEnrollment.degreePlus === true ||
    (onBehalfOfPartner.partner === true && selectedRelationship))

  const showPaymentDetails = !isEmpty(onBehalfOfPartner) &&
    showEnrollmentDetails && selectedCourse && !isEmpty(selectedDuration) &&
    selectedDate && (!isEmpty(selectedIsAudit) || degreePlusEnrollment.degreePlus === true)

  const scrollToStudentDetails = () => {
    studentDetailsRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToEnrollmentDetails = () => {
    enrollmentDetailsRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToPaymentDetails = () => {
    paymentDetailsRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToRelationshipDetails = () => {
    relationshipDetailsRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const getCourseID = () => {
    const { id, value, courses } = selectedCourse || {}
    if (id === value) return value
    // get course ID from courses based on selected cohort
    return courses?.find(course => {
      const { cohorts } = course || {}
      return cohorts?.find(cohort => cohort.id === selectedCohort.id)
    })?.id
  }

  async function submitEnrollment () {
    const isPartner = onBehalfOfPartner?.partner === true
    const isDegreePlus = degreePlusEnrollment?.degreePlus
    const tokenOptionValue = isPartner || isDegreePlus
      ? 'tokenBalance'
      : tokenOption?.option

    const enrollmentData = {
      student: studentData.id,
      course: getCourseID(),
      cohort: selectedCohort?.id,
      duration: selectedDuration?.duration,
      isAudit: selectedIsAudit?.isAudit,
      startDate: selectedDate,
      relationship: selectedRelationship?.value,
      onBehalfOfPartner: onBehalfOfPartner?.partner,
      degreePlus: isDegreePlus,
      tokenOption: tokenOptionValue,
      tokenOptionType: tokenOptionType,
      shopifyOrderNumber,
      zendeskTicket
    }

    setIsEnrollStudentLoading(true)

    const enrollmentResult = await api.submitEnrollment(enrollmentData)
    const {
      success,
      message,
      invoiceRequest
    } = enrollmentResult || {}

    if (success) {
      const { current: { display } = {} } = toast
      setIsEnrollStudentLoading(false)
      const { firstName, lastName } = studentData || {}
      const { label: CourseName } = selectedCourse || {}
      resetState()
      return display(
        <><SuccessIcon /> Student successfully enrolled!</>,
        <>
          <div>{firstName} {lastName} is now enrolled in {CourseName}</div>
          {invoiceRequest && (
            <div className={'mt-3'}>ar@outlier.org received an invoice request.</div>
          )}
        </>
      )
    }

    message && setErrorMessageText(message)
    setIsEnrollStudentLoading(false)
  }

  return (
    <Container>
      <Toast toast={toast} />
      <PageTitle ref={studentDetailsRef}>Enroll a Student</PageTitle>
      <Separator />
      <PageWrapper>
        <FormTitle>Student details</FormTitle>
        <EnrollStudentForm>
          <FormRow>
            <FormLabel>Student email</FormLabel>
            {studentNotFound && (
              <WarningMessage>
                <img src={WarningIcon} alt='Warning' />
                <p>This student is not in our database. Please enroll them as a new student.</p>
              </WarningMessage>
            )}
            <FormInput
              type='text'
              placeholder='Enter the student’s email'
              onChange={onEmailChange}
              {...(studentNotFound && { error: 'true' })}
            />
            { !studentData && (
              <AddStudentButton as={Link} to='/students/student-information'>
              Enroll a new student
              </AddStudentButton>
            )}
          </FormRow>
          <FormRow>
            <FormLabel>Student information</FormLabel>
            <FormDescription>
              {studentData ? (
                <>
                  <StudentName>
                    {studentData.firstName} {studentData.lastName}
                    {isVIPStudent && (<Badge>VIP user</Badge>)}
                    {isTestStudent && (<Badge>Test account</Badge>)}
                    {prospectData.isDegreeStudent && (<Badge>Degrees+</Badge>)}
                  </StudentName>
                  {(prospectData.degree || prospectData.gguSemester) && (
                    <p>
                      {prospectData.gguSemester}
                      {prospectData.gguSemester && prospectData.degree &&
                        <DotSeparator>.</DotSeparator>
                      }
                      {prospectData.degree}
                    </p>
                  )}
                  <p>{studentData.id}</p>
                </>
              ) : (
                studentNotFound
                  ? '--'
                  : 'Enter the student’s email to start enrollment.'
              )}
            </FormDescription>
          </FormRow>
          <FormRow>
            <FormLabel>Token balance</FormLabel>
            <FormDescription>
              {studentTokens ? (
                <>
                  <StudentTokens>{availableTokens.length} Available</StudentTokens>
                  <p>{tokensDisplayByType()}</p>
                </>
              ) : (
                '--'
              )}
            </FormDescription>
          </FormRow>
        </EnrollStudentForm>
        {showZendeskTicket && (
          <EnrollStudentForm>
            <FormRow>
              <FormLabel>Zendesk ticket</FormLabel>
              <FormInput
                type='text'
                placeholder='Add Zendesk ticket link'
                onChange={(e) => setZendeskTicket(e.target.value)}
              />
            </FormRow>
          </EnrollStudentForm>
        )}
        <Separator />
        {showPartnerEnrollment && (
          <>
            <PartnerEnrollment
              relationshipDetailsRef={relationshipDetailsRef}
              isDegreePlusStudent={prospectData.isDegreeStudent}
              onBehalfOfPartner={onBehalfOfPartner}
              handlePartnerEnrollmentChange={handlePartnerEnrollmentChange}
              degreePlusEnrollment={degreePlusEnrollment}
              handleDegreePlusChange={handleDegreePlusChange}
              setSelectedRelationship={setSelectedRelationship}
              setPartnerAvailableTokens={setPartnerAvailableTokens}
            />
            <Separator />
          </>
        )}
        {showEnrollmentDetails && (
          <>
            <EnrollmentDetails
              enrollmentDetailsRef={enrollmentDetailsRef}
              isDegreePlusStudent={prospectData.isDegreeStudent && degreePlusEnrollment.degreePlus}
              studentData={studentData}
              selectedRelationship={selectedRelationship}
              onBehalfOfPartner={onBehalfOfPartner}
              selectedCourse={selectedCourse}
              setSelectedCourse={setSelectedCourse}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedDuration={selectedDuration}
              setSelectedDuration={setSelectedDuration}
              selectedIsAudit={selectedIsAudit}
              setSelectedIsAudit={setSelectedIsAudit}
              selectedCohort={selectedCohort}
              setSelectedCohort={setSelectedCohort}
            />
            <Separator />
            {showPaymentDetails && (
              <>
                <PaymentDetails
                  paymentDetailsRef={paymentDetailsRef}
                  onBehalfOfPartner={onBehalfOfPartner}
                  isDegreePlusStudent={prospectData.isDegreeStudent && degreePlusEnrollment.degreePlus}
                  selectedRelationship={selectedRelationship}
                  unusedStudentTokens={availableTokens}
                  studentTokens={studentTokens}
                  setShowNextStep={setShowNextStep}
                  shopifyOrderNumber={shopifyOrderNumber}
                  setShopifyOrderNumber={setShopifyOrderNumber}
                  tokenOption={tokenOption}
                  setTokenOption={setTokenOption}
                  tokenOptionType={tokenOptionType}
                  setTokenOptionType={setTokenOptionType}
                  partnerAvailableTokens={partnerAvailableTokens}
                />
                <Separator />
              </>
            )}
            {showNextStep && (
              <>
                <ReviewEnrollmentDetails
                  studentData={studentData}
                  isDegreePlusStudent={prospectData.isDegreeStudent && degreePlusEnrollment.degreePlus}
                  prospectData={prospectData}
                  onBehalfOfPartner={onBehalfOfPartner}
                  selectedRelationship={selectedRelationship}
                  unusedStudentTokens={availableTokens}
                  studentTokens={studentTokens}
                  zendeskTicket={zendeskTicket}
                  selectedCourse={selectedCourse}
                  selectedDuration={selectedDuration}
                  selectedIsAudit={selectedIsAudit}
                  selectedStartDate={selectedDate}
                  shopifyOrderNumber={shopifyOrderNumber}
                  tokenOption={tokenOption}
                  tokenOptionType={tokenOptionType}
                  scrollToStudentDetails={scrollToStudentDetails}
                  scrollToEnrollmentDetails={scrollToEnrollmentDetails}
                  scrollToPaymentDetails={scrollToPaymentDetails}
                  scrollToRelationshipDetails={scrollToRelationshipDetails}
                  partnerAvailableTokens={partnerAvailableTokens}
                />
                <Separator />
              </>
            )}
          </>
        )}
        <ActionButtonContainer>
          <PrimaryButton
            disabled={!showNextStep}
            style={ActionButtonStyle}
            onClick={submitEnrollment}
          >
            Submit
          </PrimaryButton>
          <Button
            onClick={() => { window.history.back() }}
            className={{
              'btn-custom': true,
              'btn-secondary': true
            }}
            style={ActionButtonStyle}
          >
            Cancel
          </Button>
        </ActionButtonContainer>
      </PageWrapper>
      {isEnrollStudentLoading && <ProgressBar />}
      {errorMessageText && (
        <ErrorMessage
          handleGoBack={() => setErrorMessageText(null)}
          errorMessageText={errorMessageText}
        />
      )}
    </Container>
  )
}

export default EnrollStudent

import React from 'react'
import { InfoWrapper, StudentInfoItem } from './styled'

const StudentDetails = ({ studentId, studentName, studentEmail }) => {
  return (
    <InfoWrapper>
      <StudentInfoItem className='student-info__item'>
        <span>Name:</span>
        <span className='preferred_name fs-exclude'>{studentName}</span>
      </StudentInfoItem>
      <StudentInfoItem className='student-info__item'>
        <span>Student ID:</span>
        <span>{studentId}</span>
      </StudentInfoItem>
      <StudentInfoItem className='student-info__item email'>
        <span>Email Address:</span>
        <span className='email_address fs-exclude'>{studentEmail}</span>
      </StudentInfoItem>
    </InfoWrapper>
  )
}

export default StudentDetails

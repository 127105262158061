import React from 'react'
import { ClearIcon, FormInput, InputContainer } from './styles'

function ClearableInput ({ value, onChange, onClear }) {
  return (
    <InputContainer data-testid='clearable-input'>
      <FormInput type='text' value={value} onChange={onChange} />
      <ClearIcon onClick={onClear} />
    </InputContainer>
  )
}

ClearableInput.displayName = 'ClearableInput'
export default ClearableInput

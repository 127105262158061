import React from 'react'
import {
  DetailItem,
  SectionBody,
  SectionContainer,
  SectionElement
} from '../style/ProspectPage'
import { SectionTitle } from '../../../styles/text'
import { studentDetails } from '../utils/constants'

function StudentDetails ({ prospect }) {
  return (
    <SectionContainer>
      <SectionTitle>Student details</SectionTitle>

      <SectionBody>
        {studentDetails.map(({ title, value }) => (
          <SectionElement key={title}>
            <DetailItem isTitle minWidth='85'>{title}</DetailItem>
            <DetailItem>{prospect?.[value] || '--'}</DetailItem>
          </SectionElement>
        ))}
      </SectionBody>
    </SectionContainer>
  )
}

StudentDetails.displayName = 'StudentDetails'
export default StudentDetails

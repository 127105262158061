import React, { useState, useEffect } from 'react'
import {
  useTable, usePagination, useGlobalFilter, useSortBy, useRowSelect
} from 'react-table'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { allowATWriteFlag } from '../../config'
import './table.css'
import PaginationBar from './PaginationBar'
import { isMatchCaseInsensitive } from '../../utilities'
import { CustomCheckBox } from '../global.styled'
import DropdownImgURL from '../../assets/icons/caret-sort-desc.png'
import DisabledDropdownImgURL from '../../assets/icons/caret-sort-disable.png'
import { DropDownImg } from '../WritingGradeCenter/styled'
import ExpandIcon from '../../assets/icons/arrows-angle-expand.svg'
import _ from 'lodash'

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <CustomCheckBox>
        <input type='checkbox' ref={resolvedRef} {...rest} />
        <span data-testid='checkbox' />
      </CustomCheckBox>
    )
  }
)

export default function Table ({
  columns,
  data,
  serverSidePagination,
  handleSort,
  sort,
  onUserChange,
  isExamRetakes,
  useContextMenu,
  onRightClickHandler,
  onMultiSelectHandler,
  isRowEditable,
  noCheckbox,
  onEditClickHandler
}) {
  const [text, setText] = useState('')
  const [grade, setGrade] = useState('')
  const [records, setRecords] = useState(data)

  const columnNames = columns.map(col => col.Header)
  useEffect(() => {
    setRecords(data)
    // eslint-disable-next-line
  }, columnNames)
  useEffect(() => {
    setRecords(data)
  }, [data])

  const handleSearch = e => {
    setText(e)
    e ? searchData(e, data) : setRecords(data)
  }

  const searchData = (val, records) => {
    const filteredRecord = records.filter(({ name = '', email = '', id = '' }) => {
      return (
        isMatchCaseInsensitive(name, val) ||
        id.includes(val) ||
        isMatchCaseInsensitive(email, val)
      )
    })
    setRecords(filteredRecord)
  }

  const handleGrade = gradeValue => {
    setGrade(gradeValue)
  }

  const rowOnClickHandler = (row) => {
    const { values: { email, name }, idValue } = row
    onUserChange({ email, name, idValue })
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    rows,
    ...rest
  } = useTable(
    {
      columns,
      data: records,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        sortBy: serverSidePagination ? sort : []
      },
      manualPagination: serverSidePagination,
      manualSortBy: serverSidePagination
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      serverSidePagination && hooks.visibleColumns.push(columns => [
        {
          id: 'selection',
          Header: () => (null),
          Cell: ({ row }) => {
            if (
              rows.filter((row) => row.isSelected).length < 10 ||
              row.isSelected
            ) {
              return (
                <div>
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                </div>
              )
            } else {
              return (
                <div>
                  <IndeterminateCheckbox
                    checked={false}
                    readOnly
                    style={row.getToggleRowSelectedProps().style}
                  />
                </div>
              )
            }
          }
        },
        ...columns
      ])
    }
  )
  const { state: { sortBy } } = rest

  useEffect(() => {
    serverSidePagination && handleSort(sortBy)
    // eslint-disable-next-line
  }, [sortBy])

  const hasGraphData = headerGroups[0].headers.some((header) => (
    header.prop === 'graph'
  ))

  useEffect(() => {
    onMultiSelectHandler && onMultiSelectHandler(selectedFlatRows)
    // eslint-disable-next-line
  }, [selectedFlatRows.length])

  const tableHeader = () => {
    return (
      <thead className='tableHeader'>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} className='headerRow'>
            {headerGroup.headers.map((column, index) => {
              const isGraphCell = hasGraphData && index === 0
              const isActionColumn = column.prop === 'actions'
              const isActive = column.prop === sortBy[0]?.id
              if (isGraphCell) return <th key={index} />
              if (isActionColumn) {
                return (
                  <th key={index} className='action-wrapper'>
                    {column.render('Header').toUpperCase()}
                  </th>
                )
              }
              return (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ left: index === 2 && (records.length ? 345 : 292) }}
                  className='headerColumn'>
                  {serverSidePagination
                    ? column.render('Header')
                    : column.render('Header').toUpperCase()}
                  {(serverSidePagination && column.id !== 'selection') &&
                    <DropDownImg
                      src={
                        isActive
                          ? DropdownImgURL
                          : DisabledDropdownImgURL
                      }
                      isSortedAscending={isActive && sortBy[0]?.desc === false}
                    />}
                </th>
              )
            })}
          </tr>
        ))}
      </thead>
    )
  }

  const tableBody = () => {
    return (
      <tbody {...getTableBodyProps()} className='tableBody'>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr
              key={i}
              className='tableBodyRow'
              {...row.getRowProps()}
              {...(useContextMenu && {
                onContextMenu: (e) => {
                  e.preventDefault()
                  onRightClickHandler(e, row)
                }
              })
              }
            >
              {row.cells.map((cell, j) => {
                let { getCellProps, value, column: { id, Header } } = cell
                if (Array.isArray(value)) {
                  value = value
                    .filter(Boolean)
                    .map((data) => data?.value || data)
                    .join(', ')
                }
                const isGraphCell = hasGraphData && j === 0
                const isActionColumn = value === 'Action Column'
                const isBoolean = typeof value === 'boolean'
                const isRowSelection = id === 'selection'
                const isPersonalInfo = [
                  'email', 'name', 'id', 'preferredName'
                ].includes(Header)

                return (
                  <td key={j} {...getCellProps()}
                    className={classNames(
                      isActionColumn ? 'action-wrapper-body-cell' : 'bodyCell',
                      { 'fs-exclude': isPersonalInfo },
                      { 'edit-cell': isRowEditable && j === 1 }
                    )}
                    {...((isGraphCell) && {
                      onClick: () => rowOnClickHandler(row)
                    })}
                    style={{ backgroundColor: highlightGrades() }}
                  >
                    {isRowSelection && <span {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </span>}
                    { (isRowEditable && j === 1) && allowATWriteFlag &&
                      <img
                        data-testid='edit-record'
                        alt='Edit record'
                        src={ExpandIcon}
                        onClick={() => onEditClickHandler(row.original)}
                      />
                    }
                    {isGraphCell && <img alt='Id Card'
                      src={require('../../assets/icons/id-card.png')} />
                    }
                    {isActionColumn && (
                      <div className='action-column'>
                        <i
                          className='fa fa-pencil-square-o edit'
                          onClick={(e) => {
                            rowOnClickHandler({ ...row, idValue: e.target.id })
                          }}
                          id='edit'
                        />
                        <i
                          className='fa fa-trash delete'
                          onClick={(e) => {
                            rowOnClickHandler({ ...row, idValue: e.target.id })
                          }}
                          id='delete'
                        />
                      </div>
                    )}
                    {!isGraphCell && !isActionColumn && isBoolean
                      ? noCheckbox
                        ? value.toString() : <input disabled type='checkbox' checked={value} />
                      : _.isObject(value)
                        ? JSON.stringify(value) : value
                    }
                  </td>
                )
              })}
            </tr>
          )
        })}

        {!records.length && (<tr className='tableBodyRow'>
          { hasGraphData && <td className='bodyCell' /> }
          <td className='bodyCell' colSpan={columns.length}>
              No results to be shown.
          </td>
        </tr>)}
      </tbody>
    )
  }

  const highlightGrades = () => {
    if (serverSidePagination) return
    if (isExamRetakes) return '#161618'
    return 'black'
  }

  return (
    <>
      {!isExamRetakes && !serverSidePagination &&
        <PaginationBar
          {...rest}
          pagesData={data}
          text={text}
          searchByGrade={hasGraphData}
          handleSearch={handleSearch}
          handleGrade={handleGrade}
          grade={grade}
        />
      }
      <table
        {...getTableProps()}
        className={`${!hasGraphData
          ? 'edit-and-delete-table'
          : 'table'} table-responsive`}>
        {tableHeader()}
        {tableBody()}
      </table>
    </>
  )
}

Table.propTypes = {
  onUserChange: PropTypes.func
}

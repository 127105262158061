import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { pdf } from '@react-pdf/renderer'
import { saveAs } from 'file-saver'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button'
import PDFDocument from './PDFDocument'
import {
  ActiveLearningExportContainer,
  Label,
  WarningText,
  courseSelectStyles
} from './styled'
import api from '../../api'

const ActiveLearningExport = () => {
  const [courses, setCourses] = useState([])
  const [selectedCourseId, setSelectedCourseId] = useState('')
  const [isCourseLoaded, setCourseLoaded] = useState(false)
  const [sections, setSections] = useState([])
  const [selectedSectionId, setSelectedSectionId] = useState('')
  const [isSectionLoaded, setSectionLoaded] = useState(false)
  const [activeLearning, setActiveLearning] = useState(null)
  const [isGeneratingPDF, setGeneratingPDF] = useState(false)

  const selectedCourse = selectedCourseId
    ? courses.find(course => course.value === selectedCourseId)
    : {}

  const selectedSection = selectedSectionId
    ? sections.find(section => section.value === selectedSectionId)
    : {}

  const generatePDF = async () => {
    setGeneratingPDF(true)
    const doc = <PDFDocument activeLearning={activeLearning} />
    const asPdf = pdf([])
    asPdf.updateContainer(doc)
    const blob = await asPdf.toBlob()
    saveAs(blob, `${selectedCourse.label}_${selectedSection.label}.pdf`)
    setGeneratingPDF(false)
  }

  const updateActiveLearning = (activeLearning) => {
    if (!activeLearning) return setActiveLearning(null)
    setActiveLearning(activeLearning)
  }

  useEffect(() => {
    const fetchCourses = async () => {
      const courses = await api.getCourses()
      const courseOptions = courses.map(course => {
        return { value: course.id, label: course.name }
      })
      setCourses(courseOptions)
    }

    fetchCourses()
  }, [])

  useEffect(() => {
    const fetchCourseData = async (courseId) => {
      setCourseLoaded(false)
      const courseData = await api.getCourseData(courseId)
      const chapters = courseData.chapters.filter(chapter => chapter.type === 'chapter')
      const sectionsList = chapters.map(chapter => chapter.sections).flat()
      const sectionInfo = sectionsList.map(section => {
        return { label: section.title, value: section.section_uuid }
      })
      setSections(sectionInfo)
      setCourseLoaded(true)
    }

    if (!selectedCourseId) return
    fetchCourseData(selectedCourseId)
  }, [selectedCourseId])

  useEffect(() => {
    const fetchSectionData = async (courseId, sectionId) => {
      setSectionLoaded(false)
      const sectionData = await api.loadSectionData(courseId, sectionId)
      const { section_exe: sectionExe } = sectionData || {}
      const { active_learning: activeLearning } = sectionExe || {}
      updateActiveLearning(activeLearning)
      setSectionLoaded(true)
    }

    if (!selectedCourseId || !selectedSectionId) return
    fetchSectionData(selectedCourseId, selectedSectionId)
  }, [selectedCourseId, selectedSectionId])

  const handleCourseSelectChange = e => {
    setSelectedCourseId(e.value)
    setCourseLoaded(false)
    setSelectedSectionId(null)
  }

  const handleSectionSelectChange = e => {
    setSelectedSectionId(e.value)
  }

  return (
    <ActiveLearningExportContainer>
      <h3>
        Active Learning Export
      </h3>
      <Label>
        Course
      </Label>
      <Select
        options={courses}
        isLoading={!courses.length}
        onChange={selectedCourse => {
          handleCourseSelectChange(selectedCourse)
        }}
        id='courseSelectbox'
        placeholder='Select Course'
        styles={courseSelectStyles}
      />
      {selectedCourseId &&
      <>
        <Label>
          Section
        </Label>
        <Select
          key={selectedCourseId}
          options={sections}
          isLoading={!isCourseLoaded}
          onChange={selectedSection => {
            handleSectionSelectChange(selectedSection)
          }}
          id='sectionSelectbox'
          placeholder='Select Section'
          styles={courseSelectStyles}
        />
        {selectedSectionId &&
        <Button
          className={{
            'btn-custom': true,
            'btn-primary': true
          }}
          onClick={generatePDF}
          disabled={!isSectionLoaded || !activeLearning}
        >
          {
            !isSectionLoaded
              ? <><FontAwesomeIcon spin icon={faSpinner} /> Loading Data</>
              : isGeneratingPDF
                ? <><FontAwesomeIcon spin icon={faSpinner} /> Generating PDF</>
                : 'Download PDF'
          }
        </Button>}
      </>}
      {selectedSectionId && isSectionLoaded && !activeLearning &&
      <WarningText>Active Learning Section not Present</WarningText>}
    </ActiveLearningExportContainer>
  )
}

export default ActiveLearningExport

import React, { useEffect, useState } from 'react'
import ModalComponent from '../ModalComponent/Modal'
import {
  EmailIDMultiTextLabel,
  ActiveDateRangeFromLabel,
  ActiveDateRangeToLabel,
  ResetButton,
  EmailIDMultiTextContainer,
  DateRangeFilterContainer,
  DatePickerSearchFromWrapper,
  FilterModalDatePickerWrapper,
  ControlLabelWrapper,
  DatePickerSearch
} from './styles'
import 'react-datepicker/dist/react-datepicker.css'
import MultiTextInput from './MultiTextInput'

function SearchModal (props) {
  const {
    setIsSearchModalShow,
    emailFilteredStudents,
    setEmailFilteredStudents,
    handleFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate
  } = props
  const [isStateReset, setIsStateReset] = useState(false)

  useEffect(() => {
    if (!isStateReset) return

    setIsStateReset(false)
    handleFilter()
    // eslint-disable-next-line
  }, [isStateReset])

  useEffect(() => {
    handleFilter()
    // eslint-disable-next-line
  }, [emailFilteredStudents, startDate, endDate])

  const handleClose = () => {
    setIsSearchModalShow(false)
  }

  const handleEmailClear = () => {
    setEmailFilteredStudents([])
    setIsStateReset(true)
  }

  const handleDateRangeClear = () => {
    setStartDate('')
    setEndDate('')
    setIsStateReset(true)
  }

  return (
    <ModalComponent
      handleClose={handleClose}
      title='Search by'
      marginLeft={82}
      width={360}
    >
      <EmailIDMultiTextContainer>
        <EmailIDMultiTextLabel>
            Student ID or Email
        </EmailIDMultiTextLabel>
        <ResetButton onClick={handleEmailClear}>
            CLEAR
        </ResetButton>
        <MultiTextInput
          emailFilteredStudents={emailFilteredStudents}
          setEmailFilteredStudents={setEmailFilteredStudents}
        />
      </EmailIDMultiTextContainer>
      <DateRangeFilterContainer>
        <ActiveDateRangeFromLabel>
            Last Active Date Range
        </ActiveDateRangeFromLabel>
        <ResetButton onClick={handleDateRangeClear}>
            CLEAR
        </ResetButton>
        <ControlLabelWrapper>
          <DatePickerSearchFromWrapper>
            <DatePickerSearch
              data-testid='date-field'
              className='date'
              autoComplete='off'
              placeholderText='Start Date'
              selected={startDate ? new Date(startDate) : startDate}
              onChange={date => setStartDate(date)}
            />
          </DatePickerSearchFromWrapper>
          <ActiveDateRangeToLabel>
            to
          </ActiveDateRangeToLabel>
        </ControlLabelWrapper>
        <FilterModalDatePickerWrapper disabled={!startDate}>
          <DatePickerSearch
            data-testid='date-field'
            className='date'
            autoComplete='off'
            placeholderText='End Date'
            selected={endDate ? new Date(endDate) : endDate}
            onChange={date => setEndDate(date)}
            disabled={!startDate}
          />
        </FilterModalDatePickerWrapper>
      </DateRangeFilterContainer>
    </ModalComponent>
  )
}

export default SearchModal

import styled from 'styled-components'
import { courseSelectStyles } from '../../global.styled'
import DatePicker from 'react-datepicker'
import calendarSvg from '../../../assets/icons/calendar.svg'
import { Button } from 'reactstrap'
import { ReactComponent as Close } from '../../../assets/icons/modal-close.svg'

export const IndicatorsContainer = styled.div`
  display: flex;
  margin-bottom: 36px;
`

export const Indicator = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 13px 14px;
  margin-right: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  background: #1D1D1F;
  color: #5FC4B8;
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;

  span {
    font-weight: 700;
    margin-right: 12px;
  }
`

export const StudentExtensionIndicator = styled(Indicator)`
  cursor: pointer !important;
  color: ${(props) => props.active ? '#FFFFFF' : '#5FC4B8'};
  border: ${(props) => props.active ? '1px solid #FFFFFF' : 'none'};
`
export const ReviewExtensionIndicator = styled(Indicator)`
  color: ${(props) => props.active ? '#FFFFFF' : '#98A4AE'};
  border: ${(props) => props.active ? '1px solid #FFFFFF' : 'none'};
`
export const HeaderContainer = styled.div`
  display: flex; 
  flex-direction: column;
  gap: 20px;
`
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
export const Error = styled.p`
  margin-bottom: 0;
  color: #FF8064;
`
export const RowContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 8px;
  h5 {
    font-size: 18px;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  max-width: fit-content;
  position: relative;
`
export const InputLabel = styled.label`
  font-size: 16px;
  color: #B1BFC5;
  margin: 0;
`

export const CustomInput = styled.input`
  height: 56px;
  width: 400px;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 16px;
  &::placeholder{
    color: #5B6067;
  }

  &:disabled, &:focus {
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    box-shadow: 0 0 0 0;
  }
  &:focus {
    border-color: #5FC4B8;
  }
  &:hover{
    border: 1px solid #5FC4B8;`

export const DropDownStyles = {
  ...courseSelectStyles,
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#5B6067'
    }
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#FFFFFF',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    padding: state.selectProps.menuIsOpen ? '0 0 0 17.5px' : '0 17.5px 0 0',
    '&:hover': {
      color: 'white'
    }
  }),
  clearIndicator: base => ({
    ...base,
    paddingRight: '17.5px'
  }),
  input: provided => ({
    ...provided,
    color: 'white'
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: 'black',
    width: '400px',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px'
  }),
  control: (base, state) => ({
    ...base,
    width: '400px',
    height: '56px',
    background: ' rgba(0, 0, 0, 0.3)',
    color: 'white',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    opacity: state.isDisabled ? 0.3 : 1,
    '&:hover': {
      border: '1px solid var(--brand-regular)'
    },
    '&:focus-within': {
      borderColor: 'white'
    }
  })
}

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${props => props.isDisabled ? 0.4 : 1};
`

export const StyledRadioButton = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  span {
    font-size: 16px;
  }
  
  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }
`
export const AssessmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
export const OneAssessment = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
export const DateContainer = styled.div`
  position: relative;

  .react-datepicker {
    background: var(--gray-primary);
    box-shadow: 0px 0px 20px var(--shadow-primary);
    border: 2px solid var(--border-primary);
    border-radius: 5px;
    color: var(--text-primary);
    font-family: Lato;
    font-size: 14px;
    line-height: 17px;
  }

  .react-datepicker__header {
    background: var(--gray-primary);
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid var(--border-primary);
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day {
    color: var(--text-primary);
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    border-radius: 5px;
    width: 24px;
    height: 24px;
    font-weight: normal;
    background-color: var(--brand-regular);
    color: var(--text-dark);
  }

  .react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker__day {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .react-datepicker__day:hover {
    background-color: var(--brand-regular-hover);
    color: var(--text-dark);
  }

  .react-datepicker__input-container::after {
    position: absolute;
    pointer-events: none;
    filter: brightness(0) invert(1);
    ${({ disabled }) => disabled && 'opacity: 0.3;'}
    content: url(${calendarSvg});
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
  }
`
export const CustomDatePicker = styled(DatePicker)`
  background: var(--input-primary);
  outline: none;
  border: 1px solid var(--border-primary);
  padding-left: 12px;
  border-radius: 5px;
  color: var(--text-primary);
  width: 400px;
  height: 56px;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--text-dark-off);
    font-size: 16px;
    line-height: 19px;
  }

  &:hover:not(:disabled) {
    border: 1px solid var(--brand-regular);
  }

  &:focus {
    border: 1px solid var(--brand-regular);
  }

  &:disabled {
    color: var(--gray-tertiary);
    background: var(--input-primary);
    border: 1px solid var(--border-primary);

    &::placeholder,
    &::-webkit-input-placeholder {
      color: var(--gray-tertiary);
    }
  }
`
export const CustomTextArea = styled.textarea`
  min-height: 86px;
  resize: none;
  width: 400px;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 16px;
  &::placeholder{
    color: #5B6067;
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 16px;
`
export const CustomButton = styled(Button)`
  border: none !important;
  &.btn-custom.btn-primary {
    border-radius: 5px;
    width: 108px;
    height: 48px;
    color: var(--text-dark);
    background-color: var(--brand-regular) !important;
    font-size: 14px !important;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 17px !important;
    &:hover:not(:disabled) {
      background-color: var(--brand-regular-hover)!important;
    }
    &:active {
      background-color: var(--brand-regular-active)!important;
    }
  }
`
export const CustomButtonSecondary = styled(CustomButton)`
  border: 1px solid var(--brand-regular) !important;
  width: 108px;
  height: 48px;
  border-radius: 5px!important;
  box-sizing: border-box;
  background: ${({ background }) => background || 'unset'} !important;
  color: var(--brand-regular);
  text-transform: uppercase;
  &:hover {
    border-color: var(--brand-regular-hover)!important;
    color: var(--brand-regular-hover);
  }
`
export const AssessmentsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const AssessmentsHeader = styled.span`
  font-size: 18px;
`
export const AssessmentsDescription = styled.span`
  font-size: 16px;
  color: #CCD4D8;
  white-space: pre-line;
`
export const AssignmentRow = styled.div`
  display: flex;
  flex-direction: column;
`
export const AssignmentTitle = styled.span`
  font-size: 16px;
`
export const AssignmentDeadline = styled.span`
  font-size: 14px;
  color: #CCD4D8;
`
export const ExtensionLength = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 20px;
`
export const ExtensionLengthSelectors = styled.div`
  display: flex;
  gap:8px;
`
export const NumberDropDownStyles = {
  ...DropDownStyles,
  control: (base, state) => ({
    ...base,
    ...DropDownStyles.control(base, state),
    width: '150px'
  }),
  menu: (base, state) => ({
    ...base,
    ...DropDownStyles.menu(base, state),
    width: '150px'
  })
}
export const TimeUnitDropDownStyles = {
  ...DropDownStyles,
  control: (base, state) => ({
    ...base,
    ...DropDownStyles.control(base, state),
    width: '242px',
    fontsize: '16px'
  }),
  menu: (base, state) => ({
    ...base,
    ...DropDownStyles.menu(base, state),
    width: '242px',
    fontsize: '16px'
  })
}
export const CurrentDeadline = styled.span`
white-space: pre
`
export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  font-size: 14px;
`
export const ReviewMessage = styled.div`
  font-size: 18px;
  line-height: 27px;
`
export const InfoRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const Header = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  & span{
    color: #CCD4D8;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 21px;
    letter-spacing: 1px;
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 16px;
`
export const DataRow = styled.div`
  display: flex;
  gap: 12px;
`
export const Label = styled.span`
  width: 50px;
  color: #CCD4D8;
`
export const Data = styled.span`
  color: #FFFFFF;
`
export const AssessmentMessage = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: #CCD4D8;
`
export const OldDeadline = styled.span`
  text-decoration: line-through;
  color: #CCD4D8;
`
export const ZendeskLabel = styled.span`
  color: #FFFFFF;
`
export const ZendeskTicket = styled.a`
  color: #5FC4B8;
  text-decoration: underline;
  &:hover {
    color: var(--brand-regular-hover);
  }
`
export const ExtensionReason = styled.span`
  font-size: 16px;
  line-height: 24px;
`

export const ToastWrapper = styled.div`
  position: fixed;
  top: 105px;
  right: ${props => props.showToast ? '62px' : '-500px'};
  transition: all 0.3s ease-in-out;
  width: 268px;
  height: 72px;
  padding: 24px 29px 24px 28px;
  background: #25272B;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 10;
`
export const ToastHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ToastMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
`
export const ToastClose = styled(Close)`
  display: block;
  width: 14px;
  height: 14px;
  cursor: pointer;
`

import React, { useState } from 'react'
import {
  Prompt,
  AssignmentInfoKey,
  AssignmentInformation,
  AssignmentInfoValue,
  QuickViewButton,
  AssignmentTitle,
  ReadMore
} from './style'
import QuickViewFeedbackModal from './QuickViewFeedbackModal'
import {
  getGradedAssignments,
  getSubmissionTime
} from '../../utilities/writingGradeCenterUtils'
import ShowMoreIcon from '../../assets/icons/icon-show-more.svg'
import ShowLessIcon from '../../assets/icons/icon-show-less.svg'
import QuickViewFeedbackIcon from '../../assets/icons/icon-quick-view.svg'

const AssignmentInformationSection = ({
  title,
  formattedDeadline,
  cohortName,
  assignmentDetails,
  params,
  history,
  assignmentProgress,
  rubric
}) => {
  const [showMore, setShowMore] = useState(false)
  const [showQuickView, setShowQuickView] = useState(false)
  const gradedAssignments = getGradedAssignments(assignmentProgress)
  const currentAssignment = assignmentProgress?.[params?.assignmentUUID]
  const { submissionTime, deadlineTime } = currentAssignment || {}
  const formattedSubmissionTime = getSubmissionTime(submissionTime)
  const statusText = submissionTime > deadlineTime ? 'Late' : 'On Time'

  return (
    <>
      <AssignmentTitle>{title}</AssignmentTitle>
      <AssignmentInformation>
        <div className='d-flex'>
          <AssignmentInfoKey>Cohort</AssignmentInfoKey>
          <AssignmentInfoValue>{cohortName}</AssignmentInfoValue>
        </div>
        <div className='d-flex'>
          <AssignmentInfoKey>Due date</AssignmentInfoKey>
          <AssignmentInfoValue>{formattedDeadline}</AssignmentInfoValue>
        </div>
        <div className='d-flex'>
          <AssignmentInfoKey>Submitted on</AssignmentInfoKey>
          <AssignmentInfoValue>
            {formattedSubmissionTime}
            <span>{statusText}</span>
          </AssignmentInfoValue>
        </div>
        {assignmentDetails && (
          <div className='d-flex'>
            <AssignmentInfoKey prompt>Prompt</AssignmentInfoKey>
            <Prompt
              data-testid='prompt'
              height={showMore ? 'auto' : '70px'}
              dangerouslySetInnerHTML={{ __html: assignmentDetails }}
            />
          </div>
        )}
        {assignmentDetails && (
          <ReadMore data-testid='readMore' onClick={() => {
            setShowMore(value => !value)
          }}>
            <img
              src={showMore ? ShowLessIcon : ShowMoreIcon}
              alt={showMore ? 'View Less' : 'Read More'}
            />
            <span>{showMore ? 'View Less' : 'Read More'}</span>
          </ReadMore>
        )}
        <div className='d-flex'>
          <AssignmentInfoKey>Rubric</AssignmentInfoKey>
          <AssignmentInfoValue>
            <a
              href={rubric}
              className='rubric'
              target='_blank'
              rel='noopener noreferrer'
            >
              View
            </a>
          </AssignmentInfoValue>
          <QuickViewButton onClick={() => setShowQuickView(true)}>
            <img src={QuickViewFeedbackIcon} alt='Quick View Feedback' />
            quickView past feedback
          </QuickViewButton>
          {showQuickView && (
            <QuickViewFeedbackModal
              setShowQuickView={setShowQuickView}
              gradedAssignments={gradedAssignments}
              params={params}
              history={history}
            />
          )}
        </div>
      </AssignmentInformation>
    </>
  )
}

AssignmentInformationSection.displayName = 'AssignmentInformationSection'
export default AssignmentInformationSection

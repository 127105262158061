import React, { useState } from 'react'
import './StudentGradeDetails.css'
import EditCodingAssignmentModal from '../EditGrades/EditCodingAssignmentModal'
import { useStudentGradeActions } from '../../contexts/StudentGradeContext'
import { dateToSecondsSinceEpoch, secondsSinceEpoch } from '../../utilities/dateTimeUtil'
import EditCodingOverallGradeModal from '../EditGrades/EditCodingOverallGradeModal'

function CodingGradeDetails (props) {
  const {
    canUserEditGrades,
    cohort,
    handleEditCodingAssignmentModal,
    isUpdating,
    section,
    studentEmail,
    specificHistory,
    studentProgress
  } = props
  const {
    chapter_uuid: chapterUUID,
    title
  } = section
  const {
    milestones: cohortMilestones = []
  } = cohort

  const [showModal, setShowModal] = useState(false)
  const [showOverallGradeModal, setShowOverallGradeModal] = useState(false)

  const { setCodingAssignment } = useStudentGradeActions()

  const assignmentMilestone = cohortMilestones.find(
    milestone => milestone.datoAssignmentUUID === chapterUUID)

  const lockTime = dateToSecondsSinceEpoch(new Date(assignmentMilestone.lockTime))
  const currentTime = secondsSinceEpoch()
  const hasDeadlinePassed = currentTime > lockTime

  const codingAssignmentAnswers = studentProgress['studentAnswers'][chapterUUID]

  const { 'assignment-progress': assignmentProgress } = studentProgress || {}
  const assignmentScore = assignmentProgress[chapterUUID]?.grade || 0

  const handleCodingAssignment = () => {
    setCodingAssignment(section)
  }

  const handleEdit = () => {
    if (hasDeadlinePassed && codingAssignmentAnswers) return setShowModal(true)
    return setShowOverallGradeModal(true)
  }

  return (
    <div className='learning'>
      <div className='learning-section'>
        <span className='title'>Scores</span>
        <div>
          <div className='section-data-item'>
            <div className='section-data-quiz'>Assignment:</div>
            <div className='section-data-quiz'>
                {(codingAssignmentAnswers || assignmentScore)
                  ? `${assignmentScore}%`
                  : 'N/A'}
            </div>
            {canUserEditGrades && (codingAssignmentAnswers || hasDeadlinePassed) &&
              <div className='section-data-quiz__edit'
                onClick={handleEdit}
              >
                edit
              </div>
            }
          </div>
        </div>
      </div>
      {!!specificHistory?.length && <div className='learning-section' style={{ gridArea: 'edit' }}>
          <span className='title'>Change notes</span>
          {
            specificHistory.map((change, index) => {
              return (
                <div className='change-item' key={index}>
                  <div className='change-item__date'>
                    <span>{change.dateTime}</span>
                  </div>
                  <div>
                    <div>{`${change.user} changed ${change.section} grade from ${change.from || 0}% to ${change.to}%`}</div>
                        <div className='change-item__data'>
                          {change.zendeskUrl && <span className='d-flex'>
                          Zendesk URL : &nbsp;&nbsp;
                            <div
                              className='section-data-quiz__view'
                              onClick={() => window.open(change.zendeskUrl, '_blank')}>
                                View
                            </div>
                          </span>}
                          <span>{`Change Reason: ${change.reason}`}</span>
                          {change.note && <span>{`Note: ${change.note}.`}</span>}
                        </div>
                  </div>
                </div>
              )
            })
          }
        </div>}
      {showModal && <EditCodingAssignmentModal
        title={title}
        score={assignmentScore}
        handleCodingAssignment={handleCodingAssignment}
        handleOverallGrades={() => setShowOverallGradeModal(true)}
        show={showModal}
        showModalHandler={() => setShowModal(false)}
      />}
      {showOverallGradeModal && <EditCodingOverallGradeModal
        showModalHandler={() => setShowOverallGradeModal(false)}
        show={showOverallGradeModal}
        grade={assignmentScore}
        isUpdating={isUpdating}
        title={title}
        section={section}
        showParentModalHandler={() => setShowModal(false)}
        studentEmail={studentEmail}
        handleEditAssessmentModal={handleEditCodingAssignmentModal}
      />}
    </div>
  )
}

export default CodingGradeDetails

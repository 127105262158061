import {
  ClearIcon,
  CustomInput,
  DropDownContainer,
  DropDownWrapper,
  extensionDropDownStyles,
  InputWrapper
} from './style'
import { dropDownStyles } from '../global.styled'
import clearIcon from '../../assets/icons/clear-icon.svg'
import magnifyIcon from '../../assets/icons/icon-magnify.svg'
import Select from 'react-select'
import React, { useEffect, useRef, useState } from 'react'
import { useCoursesActions, useCoursesContext } from '../../contexts/Courses'
import { getSortedCourses } from '../../utilities/gradeReportUtils'
import {
  DropdownIndicator,
  ClearIndicator
} from '../CustomSelect/CustomSelectComponents'
import { additionalCourseIdToName } from '../../config'
import {
  COLLEGE_WRITING_I_GGU_V2_NAME
} from '../../Constants/courses'

const AssessmentExtensionFilter = ({
  handleCohortFilter,
  handleStudentFilter,
  handleCourseFilter
}) => {
  const [allCourses, setAllCourses] = useState([])
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedCohort, setSelectedCohort] = useState(null)
  const [filterText, setFilterText] = useState('')
  const inputRef = useRef(null)
  const [cohortData, setCohortData] = useState([])

  const { fetchCourses } = useCoursesActions()
  const { allCourses: courses } = useCoursesContext()

  useEffect(() => {
    if (!courses?.length) {
      fetchCourses()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!courses || !courses.length) return
    const sortedCourses = getSortedCourses(courses)
      .filter(course => course.name !== COLLEGE_WRITING_I_GGU_V2_NAME)
    setAllCourses(sortedCourses)
    // eslint-disable-next-line
  }, [courses])

  function filterAndSortCohorts (cohorts) {
    const filteredCohorts = cohorts.filter(cohort => !cohort.testCohort)
    const sortedCohortList = filteredCohorts.sort((a, b) =>
      new Date(b.dateStart) - new Date(a.dateStart)).map(cohort =>
      ({ ...cohort, value: cohort.name, label: cohort.name }))
    return sortedCohortList
  }

  const onCourseChange = (course) => {
    if (!course) {
      setSelectedCourse(null)
      setCohortData([])
      setSelectedCohort(null)
      handleCohortFilter('')
      handleCourseFilter('')
      return
    }

    const additionalIdExists = additionalCourseIdToName(course.id)?.length > 1
    const additionalCourseId = additionalIdExists && additionalCourseIdToName(course.id)?.[1]

    const additionalCourse = additionalCourseId && courses.find(course => course.id === additionalCourseId)
    const additionalCohorts = filterAndSortCohorts(additionalCourse?.cohorts || [])

    const filteredCohorts = [...filterAndSortCohorts(course.cohorts), ...additionalCohorts]
    setSelectedCourse(course)
    handleCourseFilter(course)
    setCohortData(filteredCohorts)
    setSelectedCohort(null)
  }

  const onCohortChange = cohort => {
    if (!cohort) {
      setSelectedCohort(null)
      handleCohortFilter('')
      return
    }
    setSelectedCohort(cohort)
    handleCohortFilter(cohort)
  }

  const onFilterTextChange = (value) => {
    setFilterText(value)
    handleStudentFilter(value)
  }

  const inputIcon = filterText === '' ? magnifyIcon : clearIcon

  return (
    <DropDownContainer>
      <InputWrapper data-testid='input-wrapper'>
        <CustomInput
          ref={inputRef}
          placeholder='Search email or ID'
          value={filterText}
          onChange={(e) =>
            onFilterTextChange(e.target.value)}
          styles={dropDownStyles}
        />
        <ClearIcon src={inputIcon} alt='' onClick={() => {
          inputRef.current.value = ''
          onFilterTextChange('')
        }}
        />
      </InputWrapper>
      <DropDownWrapper>
        <Select
          options={allCourses}
          components={{ DropdownIndicator, ClearIndicator }}
          isClearable
          value={selectedCourse}
          onChange={(course) => onCourseChange(course)}
          placeholder='Select course'
          styles={{ ...extensionDropDownStyles, height: '56px' }}
        />
      </DropDownWrapper>
      <DropDownWrapper>
        <Select
          options={cohortData}
          components={{ DropdownIndicator, ClearIndicator }}
          isClearable
          value={selectedCohort}
          onChange={(cohort) => onCohortChange(cohort)}
          placeholder='Select cohort'
          styles={extensionDropDownStyles}
        />
      </DropDownWrapper>
    </DropDownContainer>
  )
}
AssessmentExtensionFilter.displayName = 'AssessmentExtensionFilter'
export default AssessmentExtensionFilter

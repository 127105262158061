import React, { useEffect, useState } from 'react'
import { PAGES } from '../utils/constants'
import api from '../../../api'
import {
  ButtonsContainer,
  PageHeader,
  PageWrapper,
  ProspectId,
  ReviewDescription,
  ReviewList,
  ReviewSection,
  SectionsContainer,
  SectionsGroup
} from '../style/ProspectPage'
import { getActiveAttemptsCohorts, processProspect } from '../utils'
import StudentDetails from './StudentDetails'
import AdmissionDetails from './AdmissionDetails'
import SelectedCourses from './SelectedCourses'
import AcademicHistory from './AcademicHistory'
import CommentsSection from './CommentsSection'
import { Separator } from '../../../styles'
import { PageTitle, SectionTitle } from '../../../styles/text'
import { PrimaryButton, SecondaryButton } from '../../../Components/global.styled'
import GoToPreviousPageButton from '../../../Components/GoToPreviousPageButton/GoToPreviousPageButton'
import ErrorState from '../../ExamKeyOverride/ErrorState'
import ProgressBar from '../../../Components/EnrollStudentsTool/ProgressBar/ProgressBar'
import { getUpdatedFields } from '../../GGUAppManagement/ProspectPage/utils'

function ProspectPage ({
  setActivePage,
  filtersData,
  prospectId,
  showToast,
  enrollmentStatus,
  setEnrollmentStatus,
  setRefetchProspects
}) {
  const [prospectData, setProspectData] = useState({})
  const [editedProspect, setEditedProspect] = useState({})
  const [studentAttempts, setStudentAttempts] = useState([])
  const [gguCohorts, setGGUCohorts] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const {
    name,
    preferredName
  } = prospectData || {}

  useEffect(() => {
    const getProspectData = async () => {
      setIsLoading(true)
      const prospect = await api.getProspectDetails(prospectId)
      setIsLoading(false)
      const processedProspect = processProspect(prospect)
      setProspectData(processedProspect)
      setEditedProspect(processedProspect)
    }
    getProspectData()
  }, [prospectId])

  useEffect(() => {
    const fetchStudentAttempts = async () => {
      setIsLoading(true)
      const attempts = await api.getStudentAttempts({
        studentEmail: prospectData?.email
      })
      setIsLoading(false)
      setStudentAttempts(attempts)

      const activeGGUCohorts = getActiveAttemptsCohorts(attempts, 'ggu')
      const updateProspect = (prev) => ({
        ...prev,
        activeCohorts: activeGGUCohorts,
        selectedCohorts: prev?.selectedCohorts?.concat(activeGGUCohorts)
      })
      setProspectData(updateProspect)
      setEditedProspect(updateProspect)
    }

    fetchStudentAttempts()
  }, [prospectData.email])

  const isConfirmDisabled = isLoading || !editedProspect?.selectedCohorts?.length

  const handleDegreeUpdate = async () => {
    const updatedFields = getUpdatedFields(prospectData, {
      degreeName: editedProspect?.degreeName,
      degreeStatus: editedProspect?.degreeStatus
    })
    const noChanges = !Object.keys(updatedFields).length
    if (noChanges) return

    const { error } = await api.updateProspectDetails(prospectId, {
      degreeStatus: editedProspect?.degreeStatus,
      degree: [filtersData?.degrees?.find(degree => degree?.name === editedProspect?.degreeName)?.id]
    })
    if (error) {
      return setEnrollmentStatus(prev => ({ ...prev, isLoading: false, isError: true }))
    }
  }

  const handleEnrollmentConfirmation = async () => {
    setEnrollmentStatus(prev => ({ ...prev, isLoading: true }))

    await handleDegreeUpdate()

    const newCohortIds = editedProspect?.selectedCohorts?.map(cohort => cohort?.id) || []
    const removedCohortIds = editedProspect?.removedCohorts?.map(cohort => cohort?.id) || []

    const { error } = await api.approveProspect(prospectId, {
      newCohorts: newCohortIds,
      removedCohorts: removedCohortIds
    })

    setEnrollmentStatus(prev => ({ ...prev, isLoading: false }))

    if (error) {
      return setEnrollmentStatus(prev => ({ ...prev, isError: true }))
    }

    setRefetchProspects(true)
    handleBackClick()
    setTimeout(() => {
      showToast(name)
    }, 1000)
  }

  const handleBackClick = () => {
    setActivePage(PAGES.main)
  }

  if (enrollmentStatus?.isLoading) {
    return <ProgressBar />
  }

  if (enrollmentStatus?.isError) {
    return (
      <ErrorState
        title='GGU Enrollment'
        handleGoBack={() => setEnrollmentStatus({ ...enrollmentStatus, isError: false })}
      />
    )
  }

  return (
    <PageWrapper>
      <GoToPreviousPageButton
        text='go back'
        handleClick={handleBackClick}
      />

      <PageHeader>
        <PageTitle>{preferredName || name || '--'}</PageTitle>
        <ProspectId>Prospect ID: {prospectId || '--'}</ProspectId>
      </PageHeader>

      <Separator />

      <SectionsContainer>
        <SectionsGroup>
          <StudentDetails prospect={prospectData} />
          <AdmissionDetails
            prospect={prospectData}
            editedProspect={editedProspect}
            degreesData={filtersData?.degrees}
            setEditedProspect={setEditedProspect}
          />
        </SectionsGroup>

        <SectionsGroup>
          <SectionsGroup isColumn>
            <SelectedCourses
              originalProspect={prospectData}
              editedProspect={editedProspect}
              setEditedProspect={setEditedProspect}
              gguCohorts={gguCohorts}
              setGGUCohorts={setGGUCohorts}
            />
            <CommentsSection
              prospectATId={prospectData?.prospectATId}
            />
          </SectionsGroup>

          <SectionsGroup>
            <AcademicHistory
              prospect={prospectData}
              studentAttempts={studentAttempts}
            />
          </SectionsGroup>
        </SectionsGroup>
      </SectionsContainer>

      <Separator />

      <ReviewSection>
        <SectionTitle>Review enrollment</SectionTitle>
        <ReviewDescription>
          Click "Confirm courses" to finalize the student's enrollment.
        </ReviewDescription>

        {!!editedProspect?.selectedCohorts?.length && (
          <ReviewList>
            {editedProspect?.selectedCohorts?.map((cohort, index) => (
              <li key={index}>{cohort?.name}</li>
            ))}
          </ReviewList>
        )}
      </ReviewSection>

      <ButtonsContainer>
        <PrimaryButton
          disabled={isConfirmDisabled}
          onClick={handleEnrollmentConfirmation}
        >
          confirm enrollment
        </PrimaryButton>

        <SecondaryButton
          onClick={handleBackClick}
        >
          cancel
        </SecondaryButton>
      </ButtonsContainer>
    </PageWrapper>
  )
}

ProspectPage.displayName = 'ProspectPage'
export default ProspectPage

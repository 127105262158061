import React from 'react'

export const updatedStatusOptions = [
  { value: 'Drop', label: 'Drop' },
  { value: 'Administrative Drop', label: 'Administrative Drop' },
  { value: 'Melt', label: 'Melt' },
  { value: 'Withdraw', label: 'Withdraw' },
  { value: 'Signed Up', label: 'Signed Up' },
  { value: 'Enrolled', label: 'Enrolled' },
  { value: 'Completed', label: 'Completed' },
  { value: 'PreDrop', label: 'PreDrop' },
  { value: 'Audit Transcript', label: 'Audit Transcript' },
  { value: 'DropNR', label: 'DropNR' },
  { value: 'Audit', label: 'Audit' }
]

export const DROPS = ['Administrative Drop', 'Drop']

export const EXIT_STATUSES = [
  'Drop',
  'Melt',
  'Administrative Drop',
  'DropNR',
  'DropNV',
  'Needs Reviewing',
  'Incomplete',
  'Withdraw'
]

export const statusNoteOptions = [
  {
    value: 'No refund or transfer will be issued',
    label: 'No refund or voucher will be issued'
  },
  { value: 'Refund will be issued', label: 'Refund will be issued' },
  { value: 'Transfer will be issued', label: 'Voucher will be issued' }
]

export function IndicatorsContainer (props) {
  return (
    <svg
      className='mr-2'
      width='14'
      height='9'
      viewBox='0 0 14 9'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M7 9L13.9282 0.118685H0.0717969L7 9Z' fill='#77B1AF' />
    </svg>
  )
}

export const defaultFormFields = {
  updatedStatus: '',
  statusNote: '',
  note: '',
  zendeskUrl: ''
}

export function isValidUrl (string) {
  try {
    if (new URL(string)) return true
  } catch (_) {
    return false
  }
}

export const getUniqueCreditGrantingInstitutions = relationships => {
  if (!relationships || !Array.isArray(relationships)) return []

  const creditGrantingInstitutions = []

  // Since we don't have an endpoint to explicitly return
  // creditGrantingInstitutions, we use /admin/relationships
  // endpoint to get unique creditGrantingInstitutions.
  relationships.forEach(relationship => {
    const { creditGrantingInstitution } = relationship

    if (
      creditGrantingInstitutions.includes(creditGrantingInstitution)
    ) return

    creditGrantingInstitutions.push(creditGrantingInstitution)
  })

  return creditGrantingInstitutions.filter(Boolean)
}

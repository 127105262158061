import styled from 'styled-components'
import { LinkStyles } from '../../global.styled'
import { ReactComponent as BreadCrumbIcon } from '../../../assets/icons/back-arrow-caret.svg'
import { ReactComponent as CorrectIcon } from '../../../assets/icons/icon-correct-answer.svg'
import { ReactComponent as IncorrectIcon } from '../../../assets/icons/icon-incorrect-answer.svg'
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg'

export const BreadCrumb = styled.div`
  ${LinkStyles};
  cursor: pointer;
  user-select: none;
  height: 16px;
  display: flex;
`
export const StyledBreadCrumbIcon = styled(BreadCrumbIcon)`
  margin-right: 12px;
`

export const AssessmentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  border-bottom: 1px solid #25272B;
  padding-top: 24px;
  padding-bottom: 24px;
  flex-wrap: wrap;
`

export const ContentHeader = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
`

export const AssessmentSubmissionTime = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #98A4AE;
`

export const AssessmentContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 24px 32px;
`

export const QuestionsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column;
  padding-top: 32px;
`
export const AnswersText = styled.div`
  color: #CCD4D8;

  & div {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 12px;
    text-transform: uppercase;
  }

  & p {
    font-weight: 400;
    line-height: 19px;
    font-size: 16px;
    margin-bottom: 12px;
  }
`

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--canvas-primary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 26px 0;
  :last-child {
    border-bottom: none;
  }
`

export const ViewQuestionIcon = styled(ArrowRight)`
  width: 16px;
  height: 16px;
  margin-left: auto;
  margin-right: 12px;
`

export const AnswerWrapper = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #25272B;
`

export const AnswerContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 32px 0;
  ${props => props.showQuestionsList && `
    padding: 32px;
    border-left: 1px solid #25272B;
    margin-left: 32px;
  `};
`

export const CustomQuestionContainer = styled(QuestionContainer)`
  height: fit-content;  
  cursor: pointer;
  background-color: ${props => props.selected ? '#1D1D1F' : 'transparent'};
  &:hover {
    background-color: #1D1D1F;
  }
`

export const CorrectAnswerIcon = styled(CorrectIcon)`
  margin-right: 12px;
`

export const IncorrectAnswerIcon = styled(IncorrectIcon)`
  margin-right: 12px;
`

export const QuestionTitle = styled.div`
  font-weight:400;
  font-size:16px;
  line-height:19px;
`

export const ModifyResultButton = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background: #0A2A2D;
  color: #5FC4B8;
  cursor: pointer;
  text-transform: uppercase;
  width: fit-content;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  &:hover {
    background-color: #082123;
  }
`

import styled from 'styled-components'
import mediaqueries from '../../../../mediaQueries'
import CustomSelect from '../../../CustomSelect/UpdatedSelect'
import { CustomDatePicker, DatePickerContainer } from '../../../VipUser/styled'

export const Container = styled.div`
  padding: 0 24px 64px;

  ${mediaqueries.desktop`
    padding-bottom: 48px;
  `}
`

export const GoToPreviousPageButtonWrapper = styled.div`
  padding: 24px 0;
`

export const Header = styled.h2`
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--border-secondary);
  margin-bottom: 24px;
`

export const HeaderDescription = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 32px;
  max-width: 500px;

  a {
    color: var(--brand-regular);
    border-bottom: 1px solid var(--brand-regular);
    text-decoration: none;
  }
`

export const PartnerSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--border-secondary);
  padding-bottom: 32px;
`

export const PartnerDetailsTitle = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
`

export const PartnerDetailsSection = styled.div`
  display: flex;
  gap: 48px;

  ${mediaqueries.mobile`
    flex-direction: column;
  `}
`

export const PartnerDetailsInputSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const PartnerDetailsInputItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const DropdownLabel = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
`

export const PartnerSelect = styled(CustomSelect)`
  width: 400px;
  min-height: 48px;

  & > .select__control {
    min-height: 48px;
    ${props => props.showError && `
      border: 1px solid #FF8064;
    `}
  }

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`

export const AvailableLicenseBalance = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const AvailableLicenseBalanceLabel = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`

export const AvailableLicenseBalanceValue = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
`

export const LicenseVoidOptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--border-secondary);
  padding-bottom: 32px;
`

export const LicenseVoidOptionsTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`

export const LicenseVoidOption = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  cursor: pointer;

  img {
    margin-right: 8px;
  }
`

export const VoidAllLicensesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`

export const VoidAllLicensesSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  border-bottom: 1px solid var(--border-secondary);
  padding-bottom: 32px;
`

export const VoidAllLicensesTitle = styled.div`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
`

export const VoidCoursesLicensesTitleDescription = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
`

export const VoidLicensesByDateFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 32px;
`

export const VoidLicensesByDateFilterHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const VoidLicensesByDateFilterHeaderDescription = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21.6px;
`

export const VoidLicensesByDateFilterReset = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 1px;
  text-transform: uppercase;

  color: var(--brand-regular);
  cursor: pointer;
  ${props => props.disabled && 'opacity: 30%;'}
`

export const VoidLicensesByDateFilter = styled.div`
  display: flex;
  gap: 16px;

  ${mediaqueries.smallMobile`
    flex-direction: column;
  `}
`

export const VoidLicensesByDateFilterItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const VoidLicensesByDateFilterLabel = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 21.6px;

  color: var(--sub-text-secondary);
`

export const OperatorSelect = styled(CustomSelect)`
  width: 200px;

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`

export const LicenseDatePickerContainer = styled(DatePickerContainer)`
  .react-datepicker {
    background: var(--gray-primary);
    border: none;
  }

  .react-datepicker__header {
    background: var(--gray-primary);
    border-bottom: 1px solid #FFFFFF1A;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
`

export const LicenseDatePicker = styled(CustomDatePicker)`
  width: 200px;
  height: 48px;

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`

export const VoidAllLicensesTableHeader = styled.div`
  display: flex;
  gap: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #FFFFFF1A;
`

export const VoidAllLicensesTableHeaderItem = styled.div`
  display: flex;
  align-items: center;

  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #B1BFC5;

  :first-child {
    width: 250px;
  }
`

export const VoidCoursesLicensesTableHeaderItem = styled(VoidAllLicensesTableHeaderItem)`
  :nth-child(2) {
    width: 90px;
  }

  ${mediaqueries.smallMobile`
    :nth-child(3) {
      opacity: 0%
    }
  `}
`

export const VoidLicensesByDateTableHeaderItem = styled(VoidAllLicensesTableHeaderItem)`
  :nth-child(2) {
    width: 120px;
  }

  :nth-child(3) {
    width: 90px;
  }
`

export const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  margin-left: 10px;
`

export const VoidAllLicensesTableRow = styled(VoidAllLicensesTableHeader)`
  align-items: center;
  padding: 8px 0;
`

export const VoidLicensesByDateTableRowItem = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  :first-child {
    flex-shrink: 0;
  }

  :nth-child(2) {
    width: 120px;
    flex-shrink: 0;
  }

  :nth-child(3) {
    width: 90px;
    flex-shrink: 0;
  }
`

export const VoidAllLicensesTableRowItem = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;

  display: flex;
  flex-direction: column;
  gap: 4px;

  :first-child {
    width: 250px;
  }
`

export const VoidCoursesLicensesTableRowItem = styled(VoidAllLicensesTableRowItem)`
  :nth-child(2) {
    width: 90px;
  }
`

export const VoidAllCourseName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 19.2px;
  color: #CCD4D8;
`

export const VoidAllDuration = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: #98A4AE;
`

export const VoidCourseNumberToVoidContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const VoidCourseNumberToVoidError = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FF8064;
`

export const VoidCourseNumberToVoid = styled.input`
  height: 40px;
  width: 80px;
  background-color: var(--black-tertiary);
  border: 1px solid var(--border-secondary);
  border-radius: 5px;
  color: var(--text-primary);
  padding: 8px 16px;

  ::placeholder {
    color: var(--border-secondary);
  }

  :hover {
    border: 1px solid var(--brand-regular);
  }

  :focus {
    border: 1px solid white;
    outline: none;

    &::placeholder {
      color: transparent;
    }
  }

  ${props => props.isError && `
    border: 1px solid #FF8064;
  `}
`

export const VoidAllLicensesTableFooter = styled.div`
  display: flex;
  gap: 24px;
  margin-top: 14px;
`

export const VoidLicensesByDateTableFooterItem = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
`

export const VoidAllLicensesTableFooterItem = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  :first-child {
    width: 250px;
  }
`

export const VoidCoursesLicensesTableFooterItem = styled(VoidAllLicensesTableFooterItem)`
  :first-child {
    width: 364px;
  }

  :last-child {
    width: 80px;
    flex-shrink: 0;
  }
`

export const VoidAllLicensesMessage = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  max-width: 500px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;

  ${mediaqueries.smallMobile`
    flex-direction: column;
  `}
`

export const Button = styled.button`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  padding: 16px 24px;
  border-radius: 5px;
  cursor: pointer;

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`

export const SubmitButton = styled(Button)`
  background-color: var(--brand-regular);
  border: none;
  color: var(--text-dark);

  &:disabled {
    opacity: 30%;
  }
`

export const CancelButton = styled(Button)`
  background-color: var(--text-dark);
  color: var(--brand-regular);
  border: 1px solid var(--brand-regular);
`

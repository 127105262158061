import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from '../../api'
import AddViewModalRedesign from './DBViewerRedesign/AddViewModal'

const AddViewModal = (props) => {
  const {
    currentView,
    tableName,
    getViews,
    handleClose
  } = props
  const history = useHistory()
  const [isAddViewLoading, setIsAddViewLoading] = useState(false)
  const [newView, setNewView] = useState({})
  const [errorMessage, setErrorMessage] = useState('')
  const [inputError, setInputError] = useState('')

  const handleAddView = async () => {
    if (!newView?.name) return
    setIsAddViewLoading(true)

    if (newView.name.includes('/')) {
      setInputError('View name cannot contain /')
      setIsAddViewLoading(false)
      return
    }

    const { id, label, value, ...rest } = currentView
    const newViewData = {
      ...rest,
      ...newView
    }

    const result = await api.addNewView(tableName, newViewData)

    if (
      newView.name.toLowerCase() === 'default' ||
      result?.error === 'view name already exists'
    ) {
      setInputError('Please use a unique view name')
      setIsAddViewLoading(false)
      return
    }

    if (result?.error) {
      setErrorMessage(result.error)
      setIsAddViewLoading(false)
      return
    }
    await getViews()
    handleClose()

    history.push(`/database-viewer/${tableName}/${newView.name}`)
  }

  return (
    <AddViewModalRedesign
      handleClose={handleClose}
      handleAddView={handleAddView}
      newView={newView}
      setNewView={setNewView}
      errorMessage={errorMessage}
      inputError={inputError}
      setInputError={setInputError}
      isAddViewLoading={isAddViewLoading}
    />
  )
}

export default AddViewModal

import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner } from '@fortawesome/free-solid-svg-icons'
import {
  ExamButton
} from '../ExamGradeBook/styled'
import api from '../../api'

const YellowdigButton = ({
  courseId,
  cohortId,
  type,
  yellowdigIncluded
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const launchYellowdig = async () => {
    setIsLoading(true)
    const { launchUrl, message } = await api.getYellowdigUrl(courseId, cohortId, type)
    setIsLoading(false)
    if (!launchUrl) return console.error('Error in getting yellowdig URL: ', message)

    window.open(launchUrl)
  }

  return (
    <ExamButton
      className='btn-custom btn-primary'
      style={{ width: '200px', marginRight: '24px', right: '0px' }}
      onClick={launchYellowdig}
      disabled={!yellowdigIncluded || isLoading}
    >
      {isLoading
        ? <FontAwesomeIcon icon={faSpinner} />
        : <span>{type === 'community' ? `Launch Community` : 'Launch Group'}</span>
      }
    </ExamButton>
  )
}

export default YellowdigButton

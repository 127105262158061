import React, { Fragment, useState } from 'react'
import {
  ButtonAsLink,
  ButtonsContainer,
  CardHeader,
  CardTitle,
  DetailLabel,
  DetailValue,
  DetailsCard,
  DetailsGrid
} from './styles'
import { PrimaryButton } from '../../../Components/global.styled'
import { degreeStatusOptions } from './selectOptions'
import { getFieldInput, getUpdatedFields } from './utils'
import api from '../../../api'

const editableFields = [
  {
    label: 'Degree',
    key: 'degree',
    joinedTo: 'degrees'
  },
  {
    label: 'Degree status',
    key: 'degreeStatus',
    options: degreeStatusOptions
  },
  {
    label: 'Current Semester',
    key: 'gguSemesters',
    joinedTo: 'gguSemesters'
  },
  {
    label: 'Application Term',
    key: 'term'
  }
]

function DegreeDetails ({ prospect, setProspect }) {
  const { degree, degreeStatus, gguSemesters, term } = prospect
  const [isEditing, setIsEditing] = useState(false)
  const [fieldsToEdit, setFieldsToEdit] = useState({
    degree,
    degreeStatus,
    gguSemesters,
    term
  })

  const handleSave = async () => {
    const { prospectId } = prospect

    const updatedFields = getUpdatedFields(prospect, fieldsToEdit)

    await api.updateProspectDetails(prospectId, updatedFields)

    // TODO: show error message if update fails

    setProspect({
      ...prospect,
      ...fieldsToEdit
    })
    setIsEditing(false)
  }

  return (
    <DetailsCard>
      <CardHeader>
        <CardTitle>Degree</CardTitle>
        {isEditing ? (
          <ButtonsContainer>
            <ButtonAsLink
              type='button'
              onClick={() => {
                setFieldsToEdit({
                  degree,
                  degreeStatus,
                  gguSemesters,
                  term
                })
                setIsEditing(false)
              }}
              data-testid='cancel-button'
            >
              Cancel
            </ButtonAsLink>
            <PrimaryButton
              small
              type='button'
              onClick={handleSave}
              data-testid='save-button'
            >
              Save
            </PrimaryButton>
          </ButtonsContainer>
        ) : (
          <ButtonAsLink
            type='button'
            onClick={() => setIsEditing(true)}
            data-testid='edit-button'
          >
            Edit
          </ButtonAsLink>
        )}
      </CardHeader>
      <DetailsGrid>
        {editableFields.map((field) => {
          const { key, label } = field
          return (
            <Fragment key={key}>
              <DetailLabel>{label}</DetailLabel>
              {isEditing ? (
                getFieldInput({
                  field,
                  value: fieldsToEdit,
                  setValue: setFieldsToEdit
                })
              ) : (
                <DetailValue>
                  {prospect[key]?.value ?? prospect[key]}
                </DetailValue>
              )}
            </Fragment>
          )
        })}
      </DetailsGrid>
    </DetailsCard>
  )
}

DegreeDetails.displayName = 'DegreeDetails'
export default DegreeDetails

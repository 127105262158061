import styled, { keyframes } from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const Container = styled.div`
  padding: 48px 32px 0;
`

export const Title = styled.div`
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
  margin-bottom: 32px;
`

export const Description = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(255,255,255,0.1);
`

export const StudentAccountsContainer = styled.div`
  display: flex;
  gap: 48px;
  ${mediaqueries.desktop`
    flex-direction: column;
    gap: 0;
  `}
`

export const StudentAccounts = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 48px;
  border-right: 1px solid rgba(255,255,255,0.1);
  ${mediaqueries.desktop`
    border-bottom: 1px solid rgba(255,255,255,0.1);
    border-right: none;
    padding-right: 0;
  `}
`

export const StudentAccountsTitle = styled.div`
  font-size: 18px;
  line-height: normal;
  font-weight: 400;
  padding-bottom: 8px;
`

export const StudentAccountsInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${mediaqueries.desktop`
    flex-direction: row;
  `}

  ${mediaqueries.tablet`
    flex-direction: column;
  `}
`

export const InputLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: var(--sub-text-secondary);
  padding-bottom: 8px;
`

export const CustomInput = styled.input`
  height: 56px;
  width: 400px;
  padding: 16px 56px 16px 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.showError ? '#FF8064' : 'rgba(255, 255, 255, 0.3)'};
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 16px;
  &::placeholder{
    color: #5B6067;
  }

  &:disabled, &:focus {
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    box-shadow: 0 0 0 0;
  }
  &:focus {
    border-color: #5FC4B8;
  }
  &:hover{
    border: 1px solid #5FC4B8;
  }

  ${mediaqueries.smallMobile`
    width: 90%;
  `}
`

export const ClearIcon = styled.img`
  position: relative;
  right: 34px;
  cursor: pointer;
`

export const InputError = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;

  div {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #FF8064;
  }
`

export const StudentAccountsDetailsContainer = styled.div`
  padding: 32px 0;
  width: fit-content;
`

export const StudentAccountsDetailsTitle = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  padding-bottom: 8px;
`

export const StudentAccountsDetailsDescription = styled.div`
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  color: var(--sub-text-secondary);
  padding-bottom: 24px;
`

export const CourseProgressErrorContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 19px 16px;
  border-radius: 5px;
  background-color: #351915;
  margin-bottom: 24px;

  div {
    font-size: 14px;
    line-height: normal;
    font-weight: 400;
    color: #FFFFFF;
  }
`

export const StudentAccountsDetails = styled.div`
  display: flex;
  gap: 24px;

  ${mediaqueries.mobile`
    flex-direction: column;
  `}
`

export const AccountDetailsItemContainer = styled.div`
  border-radius: 5px;
  background-color: var(--black-secondary);
  padding: 16px 16px 24px;
  width: 308px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${props => props.isPrimaryAccount && `
    border: 1px solid #FFFFFF;
  `}

  ${mediaqueries.mobile`
    width: 100%;
  `}
`

export const DetailsTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const DetailsTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--sub-text-secondary);
`

export const DetailsTitleIcon = styled.img`
  padding: 8px;
`

export const DetailsItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const DetailsItemContentTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: var(--text-dark-off);
`

export const DetailsItemContentValue = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  overflow-wrap: break-word;
`

export const DetailsItemContentSeparator = styled.div`
  border-top: 1px solid rgba(255,255,255,0.1);
  margin: 4px 0;
`

export const ReviewContainer = styled.div`
  padding: 32px 0;
  border-top: 1px solid rgba(255,255,255,0.1);
`

export const ReviewTitle = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 24px;
`

export const ReviewItemContainer = styled.div`
  display: flex;
  gap: 24px 32px;
  ${mediaqueries.tablet`
    flex-direction: column;
  `}
`

export const ReviewItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const PrimaryAccountTitle = styled.div`
  display: flex;
  gap: 8px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
`

export const DeletionAccountTitle = styled(PrimaryAccountTitle)`
  color: var(--warning-color);
`

export const AccountInfo = styled.div`
  display: flex;
  gap: 8px;
  color: var(--sub-text-secondary);
  font-size: 16px;
  line-height: normal;
  font-weight: 400;

  ${mediaqueries.mobile`
    flex-direction: column;
    img {
      display: none;
    }
  `}
`

export const ReviewDescription = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
`

export const ButtonsContainer = styled.div`
  border-top: 1px solid rgba(255,255,255,0.1);
  padding-top: 32px;
  display: flex;
  gap: 12px;

  ${mediaqueries.mobile`
    flex-direction: column;
  `}
`

export const ModalContent = styled.div`
  border-top: 1px solid rgba(255,255,255,0.1);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const ModalButtonsContainer = styled.div`
  padding: 0 24px 24px;
  display: flex;
  gap: 16px;

  ${mediaqueries.smallMobile`
    flex-direction: column;
  `}
`

export const MergeAccountsProgressContainer = styled.div`
  padding-top: 241px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

export const MergeAccountsProgressTitle = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
`

export const ProgressBarContainer = styled.div`
  width: 250px;
  background-color: var(--border-secondary);
  border-radius: 3px;
`

export const progressAnimation = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`

export const ProgressBar = styled.div`
  border-radius: 3px;
  background-color: var(--sub-text-secondary);
  animation: ${progressAnimation} 6s linear infinite;
`

export const ProgressBarValue = styled.div`
  height: 6px;
  border-radius: 3px;
`

export const MergeAccountsErrorMessage = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
  padding-bottom: 16px;
`

export const MergeAccountsErrorDescription = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
  padding-bottom: 32px;
`

export const ToastTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`

export const ToastDescription = styled.div`
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  padding-top: 4px;
`

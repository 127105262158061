export const steps = {
  UPLOAD_CSV: 'UPLOAD_CSV',
  COLUMN_MAPPING: 'COLUMN_MAPPING',
  REVIEW: 'REVIEW'
}

export const ALERT = {
  SUCCESS: 'success',
  ERROR: 'error',
  HIDE: ''
}

export const requiredStudentCSVColumns = [
  'students.email',
  'students.firstName',
  'students.lastName',
  'students.courses',
  'students.cohorts',
  'attempts.studentStatus',
  'purchases.purchaseEmail',
  'tokens.relationship'
]

export const requiredGGUCSVColumns = [
  'prospects.gguStudentId',
  'prospects.email',
  'prospects.admissionDecision'
]

export const SUCCESS_MESSAGE_STUDENTS = `The students have been added successfully.
Click OK to return to the Upload CSV page.`
export const SUCCESS_MESSAGE_GGU = `The admission decisions have been added to the prospects tab. 
Click OK to return to the GGU Admission Decision Import page.`

export const CSV_SAMPLES = {
  ADMISSION_DECISION: `gguStudentId,email,admissionDecision
123456,example.ggu@ggu.edu,Admitted`,
  STUDENT: `students.preferredName,students.firstName,students.lastName,students.courses,students.cohorts,students.email,students.citizenship,students.phoneNumber,students.gender,students.dateOfBirth,students.address1,students.address2,students.city,students.postalCode,students.state,students.country,students.isRegistered,attempts.studentStatus,attempts.scholarship,attempts.comments,purchases.purchaseEmail,purchases.paidAmount,purchases.pmtStatus,purchases.pmtMethod,purchases.noteAttributes,purchases.isPartnerStudent,purchases.relationship,tokens.relationship
Sample1,Sample1,Test,Calculus I; Introduction to Philosophy,Calculus - 9/22/21; Philosophy - 9/22/21 - Audit,sample1test@outlier.org,Afghanistan,,Female,1994-11-03,123 Adams Blvd,,Los Angeles,90007,California,United States of America,1,PreDrop,0,second attempt in this course,sample1test@outlier.org,400,Paid,partnership,partner paid for student,1,Be Proud Summer 2021,Amazon`
}

export const gguDecisionCSVColumns = [
  'GGUStudentID',
  'EmailAddress',
  'AdmitType'
]

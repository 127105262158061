import { tokensLaunchDate } from '../config'
import { convertEasternTimeToLocalTime } from './dateTimeUtil'

export {
  isAfterTokensLaunch,
  getTokensRelationships
}

function isAfterTokensLaunch (attempt) {
  const { createdAt: date, attemptID } = attempt || {}

  /* We've an edge case where one student was granted a token a little early and
  Product wants to handle this attempt as post token, so we hardcode that attempt id */
  if (!date || attemptID === 'cl1tz1ra500031ts64p0calvc') return true

  const launchDateInET = convertEasternTimeToLocalTime(
    tokensLaunchDate, 'T12:00:00'
  )
  return new Date(date) >= launchDateInET
}

function getTokensRelationships (tokens) {
  if (!tokens?.length) return {}

  const PURCHASED = 'Purchased'

  return tokens.reduce((accumulator, { relationship }) => {
    if (!relationship.length) {
      accumulator[PURCHASED] = (accumulator[PURCHASED] || 0) + 1
    }
    const { fields: { relationshipName } = {} } = relationship[0] || {}
    if (relationshipName) {
      accumulator[relationshipName] = (accumulator[relationshipName] || 0) + 1
    }
    return accumulator
  }, {})
}

import styled from 'styled-components'

export const ResetButton = styled.button`
  padding: 16px 24px;
  color: var(--brand-regular);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;

  cursor: pointer;
  border: none;
  border-radius: 5px;
  background: var(--brand-dark);
  user-select: none;

  ${({ disabled }) => disabled && `
    opacity: 0.3;
    pointer-events: none;
  `}
  &:hover {
    color: var(--brand-regular-hover);
  }
  &:active {
    color: var(--brand-regular-active);
    outline: none;
  }
  &:focus {
    outline: none;
  }
`
export const ButtonAsLink = styled.button`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  padding: 0;
  color: var(--brand-regular);
  &:hover {
    color: var(--brand-regular-hover);
  }
  &:active {
    opacity: 0.3;
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:disabled:hover {
    color: var(--brand-regular);
  }
`

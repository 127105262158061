import React from 'react'
import Button from '../../Components/Button/Button'
import {
  ActionButtonStyle,
  ErrorDescription,
  ErrorTitile,
  StyledWrapper
} from './index.styled'
import { PageTitle, Separator } from '../../Components/global.styled'

const ErrorState = ({ title, handleGoBack }) => {
  return (
    <StyledWrapper>
      <PageTitle>{title || 'Exam Key Override'}</PageTitle>
      <Separator />
      <ErrorTitile>
        Oh no! There was an error while processing the changes.
      </ErrorTitile>
      <ErrorDescription>
        Go back to resubmit or contact the Product team for further assistance.
      </ErrorDescription>
      <Button
        data-testid='go-back-button'
        onClick={handleGoBack}
        className={{
          'btn-custom': true,
          'btn-primary': true
        }}
        style={ActionButtonStyle}
      >
        Go Back
      </Button>
    </StyledWrapper>
  )
}

ErrorState.displayName = 'ErrorState'
export default ErrorState

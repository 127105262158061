import React from 'react'
import {
  getDateTimeWithComma,
  getTimeAgo
} from '../../../utilities/dateTimeUtil'
import {
  CommentItemAuthor,
  CommentItemDate,
  CommentItemInfo,
  CommentItemText,
  CommentItemWrapper
} from './styles'
import { useAuth0 } from '../../../Components/Auth0Provider/Auth0Provider'

const CommentItem = ({ comment }) => {
  const {
    fields: {
      author_email: author,
      updated_time: date,
      comment: commentText
    }
  } = comment

  const { user: { email: currentUserEmail } = {} } = useAuth0() || {}

  const getTimeAgoFromDate = getTimeAgo(date)
  const dateText = getTimeAgoFromDate || getDateTimeWithComma(date)
  const authorText = author === currentUserEmail
    ? 'You commented'
    : `${author} commented`

  return (
    <CommentItemWrapper>
      <CommentItemInfo>
        <CommentItemAuthor>{authorText}</CommentItemAuthor>
        <CommentItemDate>{dateText}</CommentItemDate>
      </CommentItemInfo>
      <CommentItemText>{commentText}</CommentItemText>
    </CommentItemWrapper>
  )
}
CommentItem.displayName = 'CommentItem'
export default CommentItem

import React from 'react'
import personIcon from '../../assets/icons/person-up.svg'
import trashIcon from '../../assets/icons/trash3.svg'
import dotIcon from '../../assets/icons/eclipse2.svg'
import {
  AccountInfo,
  DeletionAccountTitle,
  PrimaryAccountTitle,
  ReviewContainer,
  ReviewDescription,
  ReviewItem,
  ReviewItemContainer,
  ReviewTitle
} from './styled'

function ReviewMerge (props) {
  const {
    primaryAccount,
    student1Details,
    student2Details
  } = props

  const isAccount1Primary = primaryAccount === 'Account 1'

  return (
    <ReviewContainer>
      <ReviewTitle>
        Review
      </ReviewTitle>
      <ReviewItemContainer>
        <ReviewItem>
          <PrimaryAccountTitle>
            <img src={personIcon} alt='person icon' />
            <div>
              Primary account
            </div>
          </PrimaryAccountTitle>
          <AccountInfo>
            <div>
              {isAccount1Primary ? student1Details?.email : student2Details?.email}
            </div>
            <img src={dotIcon} alt='dot icon' />
            <div>
              {isAccount1Primary ? student1Details?.studentId : student2Details?.studentId}
            </div>
          </AccountInfo>
          <ReviewDescription>
            Course enrollments and tokens from the other account will be relinked to the primary record.
          </ReviewDescription>
        </ReviewItem>
        <ReviewItem>
          <DeletionAccountTitle>
            <img src={trashIcon} alt='trash icon' />
            <div>
            Account deletion
            </div>
          </DeletionAccountTitle>
          <AccountInfo>
            <div>
              {isAccount1Primary ? student2Details?.email : student1Details?.email}
            </div>
            <img src={dotIcon} alt='dot icon' />
            <div>
              {isAccount1Primary ? student2Details?.studentId : student1Details?.studentId}
            </div>
          </AccountInfo>
          <ReviewDescription>
            This account will be deleted from our database.
          </ReviewDescription>
        </ReviewItem>
      </ReviewItemContainer>
    </ReviewContainer>
  )
}

export default ReviewMerge

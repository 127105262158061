import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AlertModal from '../AlertModal/AlertModal'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import GradeReportChart from '../GradeReportChart/GradeReportChart'
import ChangeNotesModal from './ChangeNotesModal'
import './GradeDetail.css'
import StudentGradeTable from '../StudentGradeTable/StudentGradeTable'
import { useStudentGradeContext, useStudentGradeActions } from '../../contexts/StudentGradeContext'
import api from '../../api'
import EditStudentEmailModal from '../EditStudentEmailModal/EditStudentEmailModal'
import EditCodingGrades from '../EditGrades/EditCodingGrades'

import { setChapterNumber } from './Utils/courseUtils'
import Toast from '../ToastComponent/Toast'
import successIcon from '../../assets/icons/icon-success.svg'
import warningIcon from '../../assets/icons/warning-diamond.svg'

function GradeDetail ({ routerProps: { history, match } }) {
  const { params: {
    courseName, courseId, cohortName, studentEmail
  } } = match

  const decodedCohortName = decodeURIComponent(cohortName)

  const {
    codingAssignment,
    gradeData,
    courseData,
    syllabus,
    historyData,
    selectedStudentCohort,
    studentProgress,
    changesHistory
  } = useStudentGradeContext()
  const {
    fetchTableData,
    fetchSectionData,
    setChangesHistory,
    setIsCohortEnd,
    setCodingAssignment,
    getMaxParticipationGrade
  } = useStudentGradeActions()
  const [loading, setIsLoading] = useState(true)
  const [modalShow, setModalShow] = useState(false)
  const [isAlertModal, setAlertModal] = useState(false)
  const [cohort, setCohort] = useState(null)
  const [preferredName, setPreferredName] = useState('')
  const [statusModifications, setStatusModifications] = useState([])
  const [isEmailEdit, setIsEmailEdit] = useState(false)
  const [emailEditStatus, setEmailEditStatus] = useState(null)
  const toast = useRef()

  const { at_id: cohortId } = cohort || {}

  useEffect(() => {
    const getCohort = async () => {
      const cohorts = await api.getAllCohorts()
      if (!cohorts) return

      const currentCohort = cohorts.find(
        cohort => cohort.name === decodedCohortName
      )
      if (!currentCohort) return

      setCohort(currentCohort)
    }

    getCohort()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchStatusModifications = async () => {
      const statusModificationsData = await api.getStatusModifications(
        cohortId,
        studentEmail
      )

      const preparedModificationsData = prepareStatusModifications(
        statusModificationsData
      )

      setStatusModifications(preparedModificationsData)
    }
    fetchStatusModifications()

    if (!cohortId) return
    fetchData()
    // eslint-disable-next-line
  }, [cohortId, studentEmail])

  useEffect(() => {
    setIsCohortEnd({ email: studentEmail }, courseName, decodedCohortName)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchStudentData = async () => {
      const { preferredName: pName = '' } = await api.getStudentData(studentEmail)
      setPreferredName(pName)
    }
    fetchStudentData()
  }, [studentEmail])

  useEffect(() => {
    const { chapters } = courseData
    chapters && prepareHistory(historyData, chapters)
    // eslint-disable-next-line
  }, [courseData])

  useEffect(() => {
    const getEmailEditStatus = () => {
      return emailEditStatus?.airtable &&
        emailEditStatus?.auth0 &&
        emailEditStatus?.activeCampaign
    }

    const getFailedToolName = () => {
      const airtable = emailEditStatus?.airtable
      const activeCampaign = emailEditStatus?.activeCampaign
      const auth0 = emailEditStatus?.auth0

      switch (true) {
        case !airtable && activeCampaign && auth0:
          return 'Airtable'
        case airtable && !activeCampaign && auth0:
          return 'ActiveCampaign'
        case airtable && activeCampaign && !auth0:
          return 'Auth0'
        case !airtable && !activeCampaign && auth0:
          return 'Airtable and ActiveCampaign'
        case !airtable && activeCampaign && !auth0:
          return 'Airtable and Auth0'
        case airtable && !activeCampaign && !auth0:
          return 'ActiveCampaign and Auth0'
        case !airtable && !activeCampaign && !auth0:
          return 'Airtable, ActiveCampaign and Auth0'
        default: return ''
      }
    }

    const showToster = () => {
      const { current: { display } = {} } = toast
      const isEmailEditSuccess = getEmailEditStatus()
      if (isEmailEditSuccess) {
        return display(
          <div className='d-flex align-items-center'>
            <img src={successIcon} alt='check' />
            <span className='ml-2'>Email successfully updated!</span>
          </div>
        )
      }

      return display(
        <div className='d-flex align-items-center'>
          <img src={warningIcon} alt='exclaim' />
          <span className='ml-2'>Error updating email</span>
        </div>,
        `The email failed to update in ${getFailedToolName()}. Please contact the product team.`
      )
    }

    if (emailEditStatus) showToster()
  }, [emailEditStatus])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      await fetchTableData({
        courseId,
        studentEmail,
        cohortId,
        cohortName
      })
      await getMaxParticipationGrade(courseId, cohort?.dateStart)
    } catch (e) {
      setAlertModal(true)
    } finally {
      setIsLoading(false)
    }
  }

  const prepareParticipationModifications = async () => {
    const participationModifications = await api.getParticipationModifications(
      studentEmail,
      courseId
    )

    return participationModifications.map(modification => {
      const { timestamp, registrar: { name, email } } = modification
      const dateTime = moment(timestamp).format('MMM D, YYYY h:mmA')

      // "correct: null" is to hide question details in ChangeNotesCollapse
      modification.correct = null

      return {
        ...modification,
        user: name || email,
        section: 'Participation',
        isParticipation: true,
        dateTime,
        participationModificationDetails: [
          { ...modification, isParticipation: true }
        ]
      }
    })
  }

  const prepareAssignmentModifications = async (chapters) => {
    const assignments = chapters.filter(chapter => [
      'WritingAssignmentChapterRecord',
      'CodingAssignmentChapterRecord'
    ].includes(chapter.type))
    if (!assignments.length) return []

    const assignmentModifications = await api.getAssignmentModifications(
      studentEmail,
      courseId
    )

    return assignmentModifications.map((modification) => {
      const { assignmentUuid, timestamp, registrar: { name, email } } = modification
      const sectionObj = assignments.find(assignment => assignment.chapter_uuid === assignmentUuid)
      const { title } = sectionObj
      const dateTime = moment(timestamp).format('MMM D, YYYY h:mmA')

      // "correct: null" is to hide question details in ChangeNotesCollapse
      modification.correct = null

      return {
        ...modification,
        user: name || email,
        dateTime,
        section: title,
        isAssignment: true,
        assignmentModificationDetails: [modification]
      }
    })
  }

  const prepareModifiedChangesHistory = async () => {
    const { course_uuid: courseUuid } = courseData
    const modifiedChangesHistory =
    await api.getStudentSectionModification(courseUuid, studentEmail)
    return modifiedChangesHistory
  }

  const formatDate = (lastActive) => {
    if (!lastActive) return 'N/A'

    const date = new Date(lastActive)
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }
    return date.toLocaleDateString('en-US', options)
  }

  const getAllSections = (chapters) => {
    const sections = []
    chapters.forEach(chapter => {
      const {
        chapter_uuid: chapterUuid,
        sections: chapterSections,
        title,
        type
      } = chapter
      const isExam = type === 'exam'
      if (isExam) return sections.push({ sectionUuid: chapterUuid, title, isExam })
      const writingAssignmentChapterRecord = type === 'WritingAssignmentChapterRecord'
      const codingAssignmentChapterRecord = type === 'CodingAssignmentChapterRecord'
      if (writingAssignmentChapterRecord || codingAssignmentChapterRecord) return
      return chapterSections.forEach(({ section_uuid: sectionUuid, title }) => {
        sections.push({ sectionUuid, title })
      })
    })
    return sections
  }

  const getAllQuizzes = async sectionUuid => {
    const sectionData = await fetchSectionData(courseId, sectionUuid)
    return sectionData.section_exe.quiz
  }

  const getExamQuestions = (chapters, sectionUuid) => {
    const exam = chapters.find(chapter => chapter.chapter_uuid === sectionUuid)
    return exam
  }

  const prepareStatusModifications = (modifications) => {
    const updatedModifications = []
    if (!modifications || !Array.isArray(modifications)) {
      return updatedModifications
    }

    modifications.forEach((modification) => {
      const { timestamp, registrar: { name, email } } = modification
      const dateTime = moment(timestamp).format('MMM D, YYYY h:mmA')

      // "correct: null" is to hide question details in ChangeNotesCollapse
      modification.correct = null

      updatedModifications.push({
        ...modification,
        user: name || email,
        dateTime,
        statusModificationDetails: [modification]
      })
    })

    return updatedModifications
  }

  const prepareHistory = async (history, chapters) => {
    setChangesHistory([])
    const groupedHistory = []
    const sections = getAllSections(chapters)
    for (const sectionUuid in history) {
      const sectionObject = sections.find(section => section.sectionUuid === sectionUuid)
      const { isExam, title: section } = sectionObject
      const quizzes = isExam ? getExamQuestions(chapters, sectionUuid) : await getAllQuizzes(sectionUuid)
      for (const quizUuid in history[sectionUuid]) {
        const quiz = !isExam && quizzes.findIndex(quiz => quiz.question_set_uuid === quizUuid)
        const questions = history[sectionUuid][quizUuid]
        if (typeof questions !== 'object') continue
        const quizObject = isExam ? quizzes.exams : quizzes[quiz]
        const { Question: quizQuestions } = quizObject
        const { questionsModifications, quizStudentGrade } = questions

        questionsModifications.forEach((item, index) => {
          const {
            cohortId,
            timestamp, questionUuid,
            registrar: { name, email } = {}, quizGrade
          } = item
          const found = groupedHistory.find(grouped => grouped.timestamp === timestamp)
          const question = quizQuestions.findIndex(question => question.Question_uuid === questionUuid)

          item.number = question + 1
          if (!found) {
            const previousHistory = groupedHistory[groupedHistory.length - 1]
            const isExistingQuiz = previousHistory ? previousHistory.quizUuid === quizUuid : false
            const dateTime = moment(timestamp).format('MMM D, YYYY h:mmA')
            groupedHistory.push({
              cohortId,
              timestamp: timestamp,
              user: name || email,
              from: isExistingQuiz ? previousHistory.to : quizStudentGrade,
              to: quizGrade,
              questions: [item],
              quiz: quiz + 1,
              dateTime,
              sectionUuid,
              section,
              quizUuid,
              isExam
            })
          } else {
            found.questions.push(item)
          }
        })
      }
    }

    const [assignmentModifications, participationModifications,
      modifiedChangesHistory] = await Promise.all([
      prepareAssignmentModifications(chapters),
      prepareParticipationModifications(),
      prepareModifiedChangesHistory()
    ])

    const combinedHistory = [
      ...groupedHistory,
      ...statusModifications,
      ...assignmentModifications,
      ...participationModifications,
      ...modifiedChangesHistory
    ]

    const sortedHistory = combinedHistory.sort(
      (a, b) => b.timestamp - a.timestamp
    )

    const selectedCohortHistory = sortedHistory.filter(isChangeHistoryInSelectedCohort)
    setChangesHistory(selectedCohortHistory)
  }

  const isChangeHistoryInSelectedCohort = (history) => {
    if (!selectedStudentCohort?.dateStart || !selectedStudentCohort?.cohortEndTime || !history.timestamp) return false
    const changeHistoryDate = moment(history.timestamp)
    const cohortDateStart = moment(selectedStudentCohort?.dateStart, 'YYYY-MM-DD')
    const cohortDateEnd = moment(selectedStudentCohort?.cohortEndTime)
    const weekAfterCohortDateEnd = cohortDateEnd.add(1, 'weeks')
    const isBetween = changeHistoryDate.isBetween(cohortDateStart, weekAfterCohortDateEnd, null, '[]')
    return isBetween
  }

  const { name, notes, lastActive, id } = gradeData

  if (codingAssignment) {
    return <EditCodingGrades
      name={name}
      courseId={courseId}
      cohortID={cohortId}
      cohortName={decodedCohortName}
      codingAssignment={codingAssignment}
      studentEmail={studentEmail}
      studentProgress={studentProgress}
      setCodingAssignment={setCodingAssignment}
    />
  }
  const courseDataExists = Object.keys(courseData).length
  return (
    <div className='grade-detail'>
      <h3 className='admin-home' onClick={() => history.push('/')} >
        <FontAwesomeIcon icon={faAngleLeft} />{' '}
        <p>Admin Home</p>
      </h3>
      <Toast toast={toast} />
      <EditStudentEmailModal
        history={history}
        match={match}
        show={isEmailEdit}
        currentEmail={studentEmail}
        setEmailEditStatus={setEmailEditStatus}
        handleClose={() => setIsEmailEdit(false)}
      />
      {loading ? <LoadingSpinner /> : !isAlertModal &&
        <>
          <div className={'heading-info'}>
            <h1 className='student-name fs-exclude'>{name}</h1>
            <h1 className='student-active'>Last Active {formatDate(lastActive)}</h1>
          </div>
          <div className='student-container'>
            <div className='student-info-constainer'>
              <div className='student-info'>
                <div className='student-info__item'>
                  <span>Preferred Name</span>
                  <span className='preferred_name fs-exclude'>{preferredName}</span>
                </div>
                <div className='student-info__item'>
                  <span>Student ID</span>
                  <span className='student_id fs-exclude'>{id}</span>
                </div>
                <div className='student-info__item email'>
                  <span>Email Address</span>
                  <span className='email_address fs-exclude'>{studentEmail}</span>
                  <span className='edit-email-button' onClick={() => setIsEmailEdit(true)}>Edit</span>
                </div>
              </div>
              <div className='note'>
                <p>Notes</p>
                <p>
                  {notes}
                </p>
              </div>
            </div>
          </div>
          <div className='chart-container'>
            <GradeReportChart
              key={studentEmail}
              hasHistory={!!changesHistory.length}
              selectedUser={{ email: studentEmail, name }}
              selectedCourse={{ id: courseId, name: courseName }}
              selectedCohort={decodedCohortName}
              onNotesModalShow={() => setModalShow(true)}
              cohort={cohort}
            />
          </div>
          <ChangeNotesModal
            courseData={courseData}
            show={modalShow}
            setModalShowHide={setModalShow}
            history={changesHistory}
            studentProgress={studentProgress}
          />
          {courseDataExists &&
            <StudentGradeTable
              cohort={cohort}
              selectedStudentCohort={selectedStudentCohort}
              courseData={setChapterNumber(courseData)}
              syllabus={syllabus}
            />}
        </>}
      <AlertModal
        data-testid='error-modal'
        showModalHandler={() => {}}
        confirm={{ text: 'Back', handler: () => history.push('/') }}
        show={isAlertModal}
      >
        {`Oops! There seems to be an error loading this page. 
        Please report this to the product team.`}
      </AlertModal>
    </div>
  )
}

export default GradeDetail

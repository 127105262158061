import React, { useEffect, useState } from 'react'
import successIcon from '../../../assets/icons/icon-success.svg'
import api from '../../../api'
import { COURSE_FILTER_OPTIONS } from '../constants'
import {
  cleanupAndSortCourseLicenses,
  filterCourseLicenses
} from '../utils/licenseUtils'
import CourseDurationSelect from './CourseDurationSelect'
import CourseList from './CourseList'
import SelectedCourses from './SelectedCourses'
import {
  AvailableCoursesContainer,
  AvailableCoursesTitle,
  CourseFilterContainer,
  CourseListContainer,
  ToastHeader,
  ToastMessage
} from './styled'

const TIMEOUT = 8 * 1000 // 8 seconds

function AvailableCourses (props) {
  const {
    relationship,
    setRelationship,
    toast,
    tokens,
    setTokens,
    showError
  } = props

  const [courseLicenses, setCourseLicenses] = useState([])
  const [selectedFilter, setSelectedFilter] = useState(COURSE_FILTER_OPTIONS[0])

  useEffect(() => {
    const getCourseLicenses = async () => {
      const courseLicenses = await api.getOutlierCourseLicenses()

      const cleanupAndSortedCourseLicenses =
        cleanupAndSortCourseLicenses(courseLicenses)
      setCourseLicenses(cleanupAndSortedCourseLicenses)
    }
    getCourseLicenses()
  }, [])

  const {
    relationshipName,
    enrollmentOptions
  } = relationship || {}

  const filteredCourseLicenses = filterCourseLicenses(
    courseLicenses,
    selectedFilter,
    enrollmentOptions
  )

  const tokenLicenses = courseLicenses.filter(license => tokens[license.courseLicense])
  const tokensData = tokenLicenses.map(license => {
    const { courseLicense, courseId } = license
    return {
      courseLicense,
      courseId,
      count: tokens[courseLicense]
    }
  })

  const request = {
    relationshipName,
    tokensData
  }

  const handleSubmit = async () => {
    setTokens({})
    setRelationship(null)

    const { current: { display } = {} } = toast || {}

    display(
      <ToastHeader>
        <img src={successIcon} alt='Success' />
        <div>
          Course licenses request received
        </div>
      </ToastHeader>
      ,
      <ToastMessage>
        We have received your request and are working on it.
      </ToastMessage>,
      { delay: 0, period: TIMEOUT }
    )

    await api.createTokens(request)
  }

  return (
    <AvailableCoursesContainer>
      <AvailableCoursesTitle>
        Available courses
      </AvailableCoursesTitle>
      <CourseFilterContainer>
        <CourseDurationSelect
          filterOptions={COURSE_FILTER_OPTIONS}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      </CourseFilterContainer>
      <CourseListContainer>
        <CourseList
          filteredCourseLicenses={filteredCourseLicenses}
          tokens={tokens}
          setTokens={setTokens}
          relationship={relationship}
        />
        <SelectedCourses
          courseLicenses={courseLicenses}
          tokens={tokens}
          showError={showError}
          handleSubmit={handleSubmit}
        />
      </CourseListContainer>
    </AvailableCoursesContainer>
  )
}

export default AvailableCourses

import React from 'react'
import isEqual from 'lodash/isEqual'
import AsyncSelect from 'react-select/async'
import ClearableInput from './ClearableInput'
import { Select, asyncSelectStyles } from './styles'
import api from '../../../api'

export const getFieldInput = ({ field, value, setValue }) => {
  const { key, options, joinedTo } = field

  if (joinedTo) {
    return (
      <AsyncSelect
        classNamePrefix='select'
        styles={asyncSelectStyles}
        placeholder=''
        value={{
          label: value[key]?.label ?? value[key],
          value: value[key]?.value ?? value[key]
        }}
        defaultOptions
        cacheOptions
        onChange={(option) => setValue({ ...value, [key]: option })}
        loadOptions={async () => {
          const result = await api.getTableIdValues({ tableName: joinedTo })

          return result.map((row) => {
            return {
              ...row,
              label: row.value
            }
          })
        }}
        data-testid='async-select'
      />
    )
  }

  if (options) {
    return (
      <Select
        options={options.map((option) => ({
          label: option,
          value: option
        }))}
        value={{ label: value[key], value: value[key] }}
        onChange={(option) => setValue({ ...value, [key]: option.value })}
        data-testid='regular-select'
      />
    )
  }

  return (
    <ClearableInput
      value={value[key] ?? ''}
      onChange={(e) => setValue({ ...value, [key]: e.target.value })}
      onClear={() => setValue({ ...value, [key]: '' })}
    />
  )
}

export const getUpdatedFields = (originalFields, updatedFields) => {
  return Object.keys(updatedFields).reduce((acc, key) => {
    if (!isEqual(originalFields[key], updatedFields[key])) {
      acc[key] = updatedFields[key]?.id
        ? [updatedFields[key].id]
        : updatedFields[key]
    }
    return acc
  }, {})
}

import styled from 'styled-components'

export const PageTitle = styled.h1`
  margin: 0;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
`

export const SectionTitle = styled.h2`
  margin: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
`

export const ContentTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const Label = styled.h4`
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const BodyText = styled.p`
  margin: 0;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`

export const Description = styled.p`
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const Detail = styled.p`
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

import React, { useState, useEffect } from 'react'
import {
  useWritingGradeCenterContext,
  useWritingGradeCenterActions
} from '../../contexts/WritingGradeCenterContext'
import { ReactComponent as CaretDownIcon } from '../../assets/icons/chevron-down.svg'
import { ReactComponent as OptionUnselected } from '../../assets/icons/option-unselected.svg'
import { ReactComponent as OptionSelected } from '../../assets/icons/option-selected.svg'
import {
  CohortSelectContainer,
  CohortSelect,
  CohortSelectDropdown,
  CohortSearchBar,
  CohortHeaderText,
  CohortSelectionList,
  CohortOption
} from './styled'
import { isMatchCaseInsensitive } from '../../utilities'
const ALL_ACTIVE_COHORTS = 'All active cohorts'
const ALL_PAST_COHORTS = 'All past cohorts'

function CohortFilter ({
  isLoadingGrid,
  activeCohorts,
  pastCohorts
}) {
  const {
    defaultCohorts,
    selectedCohorts,
    isAllActiveCohortsSelected,
    isAllPastCohortsSelected
  } = useWritingGradeCenterContext()
  const {
    setSelectedCohorts,
    setAllActiveCohortsSelected,
    setAllPastCohortsSelected
  } = useWritingGradeCenterActions()
  const [isCohortDropdownOpen, setCohortDropdownOpen] = useState(false)
  const [filteredCohorts, setFilteredCohorts] = useState(defaultCohorts)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (searchText !== '') {
      const results = defaultCohorts.filter((cohort) => {
        return isMatchCaseInsensitive(cohort.name, searchText)
      })
      setFilteredCohorts(results)
    } else {
      setFilteredCohorts(defaultCohorts)
    }
  }, [defaultCohorts, searchText])

  const handleChange = (event) => {
    const { target: { checked, id } } = event
    const clickedCohort = defaultCohorts.find(cohort => cohort.at_id === id)
    if (checked) {
      setSelectedCohorts([...selectedCohorts, clickedCohort])
    } else {
      setSelectedCohorts(selectedCohorts.filter(cohort => cohort !== clickedCohort))
      activeCohorts.includes(clickedCohort) &&
        setAllActiveCohortsSelected(false)
      pastCohorts.includes(clickedCohort) &&
        setAllPastCohortsSelected(false)
    }
  }

  const handleActiveChange = (event) => {
    const { target: { checked } } = event
    setAllActiveCohortsSelected(!!checked)
    if (checked) {
      setSelectedCohorts(defaultCohorts.filter(cohort =>
        activeCohorts.includes(cohort) || selectedCohorts.includes(cohort)))
    } else {
      setSelectedCohorts(selectedCohorts.filter(cohort =>
        !activeCohorts.includes(cohort)))
    }
  }

  const handlePastChange = (event) => {
    const { target: { checked } } = event
    setAllPastCohortsSelected(!!checked)
    if (checked) {
      setSelectedCohorts(defaultCohorts.filter(cohort =>
        pastCohorts.includes(cohort) || selectedCohorts.includes(cohort)))
    } else {
      setSelectedCohorts(selectedCohorts.filter(cohort =>
        !pastCohorts.includes(cohort)))
    }
  }

  const getFilterLabel = () => {
    if (isLoadingGrid) return 'Loading ...'
    if (!selectedCohorts.length) return 'Filter By Cohort'
    if (selectedCohorts.length === defaultCohorts.length) return 'All Selected'
    if (
      isAllActiveCohortsSelected &&
      selectedCohorts.length === activeCohorts.length
    ) return ALL_ACTIVE_COHORTS
    if (
      isAllPastCohortsSelected &&
      selectedCohorts.length === pastCohorts.length
    ) return ALL_PAST_COHORTS
    return `${selectedCohorts.length} Selected`
  }

  return (
    <CohortSelectContainer>
      <CohortSelect onClick={() => !isLoadingGrid && setCohortDropdownOpen(!isCohortDropdownOpen)}>
        {getFilterLabel()}
        <span />
        <CaretDownIcon />
      </CohortSelect>
      {
        isCohortDropdownOpen && <CohortSelectDropdown>
          <CohortSearchBar
            type='search'
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            className='input'
            placeholder='Search'
          />
          <CohortOption key='active-cohorts' htmlFor='active-cohorts'>
            {
              isAllActiveCohortsSelected
                ? <OptionSelected />
                : <OptionUnselected />
            }
            <div>
              {ALL_ACTIVE_COHORTS}
            </div>
            <input
              type='checkbox'
              id='active-cohorts'
              onChange={handleActiveChange}
              checked={isAllActiveCohortsSelected}
            />
          </CohortOption>
          <CohortOption key='past-cohorts' htmlFor='past-cohorts'>
            {
              isAllPastCohortsSelected
                ? <OptionSelected />
                : <OptionUnselected />
            }
            <div>
              {ALL_PAST_COHORTS}
            </div>
            <input
              type='checkbox'
              id='past-cohorts'
              onChange={handlePastChange}
              checked={isAllPastCohortsSelected}
            />
          </CohortOption>
          <CohortHeaderText>
            Cohorts
          </CohortHeaderText>
          <CohortSelectionList>
            {
              filteredCohorts.map(cohort =>
                <CohortOption key={cohort.at_id} htmlFor={cohort.at_id}>
                  {
                    selectedCohorts.includes(cohort)
                      ? <OptionSelected />
                      : <OptionUnselected />
                  }
                  <div>
                    {cohort.name}
                  </div>
                  <input
                    type='checkbox'
                    id={cohort.at_id}
                    onChange={handleChange}
                    checked={selectedCohorts.includes(cohort)}
                  />
                </CohortOption>
              )}
          </CohortSelectionList>
        </CohortSelectDropdown>
      }
    </CohortSelectContainer>
  )
}

export default CohortFilter

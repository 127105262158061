import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledWrapper,
  LabelContainer,
  SubHeader,
  DatePickerContainer,
  CustomDatePicker
} from './styled'

const CustomDateRange = props => {
  const {
    label,
    description,
    exposeKey,
    values,
    isDisabled,
    isExamRetakes,
    setValue
  } = props

  const selectedDates = values[exposeKey] || []

  const handleDateChange = (date, isFrom) => {
    const newDates = [...selectedDates]
    const index = isFrom ? 0 : 1

    newDates[index] = date

    setValue({
      [exposeKey]: newDates
    })
  }

  const [fromDate, toDate] = selectedDates

  return (
    <StyledWrapper isExamRetakes={isExamRetakes}>
      {
        label && (
          <LabelContainer style={{ marginBottom: '4px' }}>
            <label>{label}</label>
          </LabelContainer>
        )
      }
      {
        description && (
          <SubHeader isExamRetakes={isExamRetakes}>
            {description}
          </SubHeader>
        )
      }

      <DatePickerContainer>
        <CustomDatePicker
          id={'from' + exposeKey}
          autoComplete='off'
          maxDate={toDate}
          placeholderText='Start date'
          selected={fromDate}
          onChange={date => handleDateChange(date, true)}
        />
        <span>to</span>
        <CustomDatePicker
          id={'to' + exposeKey}
          autoComplete='off'
          minDate={fromDate}
          placeholderText='End date'
          selected={toDate}
          disabled={isDisabled}
          onChange={date => handleDateChange(date)}
        />
      </DatePickerContainer>
    </StyledWrapper>
  )
}

CustomDateRange.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  exposeKey: PropTypes.string.isRequired,
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  values: PropTypes.object,
  setValue: PropTypes.func.isRequired
}

export default CustomDateRange

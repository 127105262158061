export function filterAttemptsByStatus (studentAttempts) {
  if (!studentAttempts?.length) return

  const filteredAttempts = []
  const FILTERED_STATUS = ['enrolled', 'signed up', 'predrop', 'audit']
  studentAttempts.forEach((attempt) => {
    const {
      fields: {
        course: { fields: { id, name = '' } = {} } = {},
        studentStatus: { fields: { Name = '' } = {} } = {},
        cohort: { fields = {} } = {}
      }
    } = attempt
    const studentStatusName = Name.toLowerCase()

    if (FILTERED_STATUS.includes(studentStatusName)) {
      const filteredAttemptCourse =
        filteredAttempts.find(course => course.value === id)

      if (filteredAttemptCourse) {
        filteredAttemptCourse.cohorts.push(fields)
      } else {
        filteredAttempts.push({
          cohorts: [fields], value: id, label: name
        })
      }
    }
  })

  return filteredAttempts
}

import { saveAs } from 'file-saver'
import {
  BoxArrowIcon,
  ComponentContainer,
  CSVDownloadLink,
  CustomButton,
  DownloadIcon
} from './styled'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { UPLOAD_CSV } from '../../Constants'

const AmazonDownloadCSV = ({
  uploadedCSV,
  fileName
}) => {
  const history = useHistory()

  useEffect(() => {
    if (!uploadedCSV || !fileName) { history.push(`/amazon-student-progress/${UPLOAD_CSV}`) }
  }, [fileName, uploadedCSV, history])

  const downloadCSV = () => {
    const blob = new Blob([uploadedCSV], { type: 'text/csv' })
    saveAs(blob, fileName)
  }

  return (
    <ComponentContainer>
      <h3>Download updated CSV</h3>
      <p className='download-csv-text'>
        The CSV now contains the details requested by Amazon for each student listed.
      </p>
      <CSVDownloadLink
        data-testid='download-link'
        onClick={downloadCSV}>
        <DownloadIcon />
        Download student progress
      </CSVDownloadLink>
      <h3>Upload to Career Choice Portal</h3>
      <p className='download-csv-text'>
        Upload the downloaded CSV to Amazon’s Career Choice portal.
      </p>
      <CustomButton
        data-testid='next-button'
        className='btn-custom btn-primary download-csv-btn'
        onClick={() => window.open('https://www.amazoncareerchoice.com/')}
      >
        <BoxArrowIcon />
        Career choice portal
      </CustomButton>
      <CustomButton
        data-testid='go-back'
        className='btn-custom btn-secondary download-csv-btn'
        onClick={() => history.push(`/amazon-student-progress/${UPLOAD_CSV}`)}
      >
        go back
      </CustomButton>
    </ComponentContainer>
  )
}

export default AmazonDownloadCSV

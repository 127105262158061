import React, { memo } from 'react'
import {
  Badge, DotSeparator,
  EditButton,
  FormDescription,
  FormSubtitle,
  FormTitle,
  PageWrapper,
  ReviewStudentName,
  ReviewWrapper,
  SubText
} from '../style'

import { getCohortDates } from '../../../utilities/EnrollStudentsTool'
import isEmpty from 'lodash/isEmpty'

const ReviewEnrollmentDetails = (props) => {
  const {
    studentData,
    isDegreePlusStudent,
    prospectData,
    isVIPStudent,
    isTestStudent,
    zendeskTicket,
    selectedCourse,
    selectedDuration,
    selectedIsAudit,
    selectedStartDate,
    selectedRelationship,
    onBehalfOfPartner,
    unusedStudentTokens,
    tokenOption,
    tokenOptionType,
    shopifyOrderNumber,
    scrollToStudentDetails,
    scrollToEnrollmentDetails,
    scrollToRelationshipDetails,
    scrollToPaymentDetails,
    partnerAvailableTokens
  } = props

  if (isEmpty(studentData)) return null

  const { tokenType } = tokenOptionType || {}
  const { creditGrantingInstitution, displayName } = selectedCourse || {}
  const { isAudit } = selectedIsAudit || {}
  const { duration } = selectedDuration || {}
  const { partner: isPartner } = onBehalfOfPartner || {}
  const {
    label: relationshipName
  } = selectedRelationship || {}

  const partnerRemainingTokens = partnerAvailableTokens - 1

  const isTokenGranted = (!isPartner && unusedStudentTokens.length === 0 &&
    tokenOption?.option === 'tokenBalance')
  const isTokenGenerated = (isPartner && partnerAvailableTokens === 0)
  const isTokenPaid = !isTokenGranted && !isTokenGenerated

  return (
    <PageWrapper>
      <ReviewWrapper>
        <FormTitle>Review enrollment</FormTitle>
        <FormSubtitle>
          Student
          <EditButton onClick={scrollToStudentDetails}>Edit</EditButton>
        </FormSubtitle>
        <FormDescription>
          <ReviewStudentName>
            {studentData.firstName} {studentData.lastName}
            {isVIPStudent && (<Badge>VIP user</Badge>)}
            {isTestStudent && (<Badge>Test account</Badge>)}
            {prospectData?.isDegreeStudent && (<Badge>Degrees+</Badge>)}
          </ReviewStudentName>
          {(prospectData?.degree || prospectData?.gguSemester) && (
            <p>
              {prospectData.gguSemester}
              {prospectData.gguSemester && prospectData.degree &&
                <DotSeparator>.</DotSeparator>
              }
              {prospectData.degree}
            </p>
          )}
          <p>{studentData.email}</p>
          <p>{studentData.id}</p>
          {zendeskTicket && (
            <a href={zendeskTicket} target='_blank' rel='noopener noreferrer'>
              Zendesk ticket
            </a>
          )}
        </FormDescription>
      </ReviewWrapper>
      <ReviewWrapper>
        <FormSubtitle>
          Enrollment
          <EditButton onClick={scrollToEnrollmentDetails}>Edit</EditButton>
        </FormSubtitle>
        <FormDescription>
          {selectedCourse && (
            <p>
              {creditGrantingInstitution}
              <DotSeparator>.</DotSeparator>
              {displayName}
            </p>
          )}
          {(selectedIsAudit && duration) && (
            <p>Enroll (For {isAudit ? 'Audit' : 'Credit'})
              <DotSeparator>.</DotSeparator>
              {duration}
            </p>
          )}
          <p>{getCohortDates(selectedCourse, selectedStartDate, duration)}</p>
        </FormDescription>
      </ReviewWrapper>
      {isPartner && (
        <ReviewWrapper>
          <FormSubtitle>
            Relationship
            {relationshipName &&
              (<EditButton
                onClick={scrollToRelationshipDetails}>Edit</EditButton>)
            }
          </FormSubtitle>
          <FormDescription>
            <p>{relationshipName || creditGrantingInstitution}</p>
          </FormDescription>
        </ReviewWrapper>
      )}
      <ReviewWrapper>
        <FormSubtitle>
          payment {tokenOption.option === 'recentOrder' && (
            <EditButton onClick={scrollToPaymentDetails}>Edit</EditButton>
          )}
        </FormSubtitle>
        <FormDescription>
          {isDegreePlusStudent && (
            <p>1 Token</p>
          )}
          {tokenOption.option === 'recentOrder' ? (
            <p>Order {shopifyOrderNumber} (Paid)</p>
          ) : (!isDegreePlusStudent && (
            <>
              <p>1 Token {' '}
                {isTokenGranted && (<span>(Granted)</span>)}
                {isTokenGenerated && (<span>(Generated)</span>)}
                {isTokenPaid && (<span>(Paid)</span>)}
                {(tokenType && tokenType !== 'Purchased') && (
                  <><DotSeparator>.</DotSeparator>{tokenType}</>
                )}
              </p>
              {isTokenGenerated && (
                <SubText>ar@outlier.org will invoice the partner for 1 token.</SubText>)}
            </>
          ))}
          {(partnerAvailableTokens > 0 && isPartner) && (
            <SubText>Balance after enrollment: {partnerRemainingTokens} Tokens</SubText>
          )}
        </FormDescription>
      </ReviewWrapper>
    </PageWrapper>

  )
}

export default memo(ReviewEnrollmentDetails)

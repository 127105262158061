import React, { useEffect, useState } from 'react'
import {
  Container,
  PageHeading,
  Separator
} from '../WritingGradeCenter/styled'
import Filters from './Filters'
import ExamCenterTable from './Table'
import {
  addDotBtwDateAndTime,
  formatWithoutYear,
  substractHoursFromDate,
  getTimezoneShort
} from '../../utilities/dateTimeUtil'
import {
  getCohortStartSecondsSinceEpoch
} from '../../utilities/courseUtils'
import api from '../../api'
import {
  shouldUseExamUUIDInExamTag
} from '../../utilities/proctorio'
import { getExamEndTime } from './utils'

const isCompletedExam = examEndTime => {
  return new Date() >= new Date(examEndTime)
}

const ExamGradeBook = () => {
  const [currentCohorts, setCurrentCohorts] = useState([])
  const [currentExams, setCurrentExams] = useState([])
  const [currentCourse, setCurrentCourse] = useState(null)
  const [completedExams, setCompletedExams] = useState([])
  const [upcomingExams, setUpcomingExams] = useState([])
  const [retakeExams, setRetakeExams] = useState(null)

  const getExamRetakeStudents = async ({
    cohortId,
    cohortName,
    examName,
    proctorioExamTitle
  }) => {
    try {
      const retakes = await api.getExamRetakes(cohortId)
      setRetakeExams({ retakes, cohortName, examName, proctorioExamTitle })
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const getVersionExamEndTime = () => {
    const completed = []
    const upcoming = []

    const {
      retakes,
      cohortName,
      examName,
      proctorioExamTitle
    } = retakeExams

    retakes.forEach(retake => {
      const { finalDeadline, midterm1Deadline, midterm2Deadline } = retake
      const examEndTime = finalDeadline || midterm1Deadline || midterm2Deadline
      const substractedExamEndTime = substractHoursFromDate(
        examEndTime, 2)

      const timezone = getTimezoneShort(new Date() / 1000)
      const deadline = examEndTime
        ? addDotBtwDateAndTime(
          formatWithoutYear(new Date(substractedExamEndTime * 1000))) +
      ` ${timezone}`
        : ''

      const exam = {
        examName,
        deadline,
        cohortName,
        examEndTime,
        proctorioExamTitle
      }
      if (isCompletedExam(examEndTime)) return completed.push(exam)

      upcoming.push(exam)
    })
    setCompletedExams([...completedExams, ...completed])
    setUpcomingExams([...upcomingExams, ...upcoming])
  }

  useEffect(() => {
    const completed = []
    const upcoming = []

    currentCohorts.forEach((cohort, index) => {
      const { name: cohortName } = cohort
      const cohortStartDate = getCohortStartSecondsSinceEpoch(cohort)
      const shouldUseExamUUID = shouldUseExamUUIDInExamTag(cohortStartDate)

      return currentExams.forEach(exam => {
        const { label: examName, value: examUUID } = exam
        const proctorioExamTitle = shouldUseExamUUID ? examUUID : examName

        const examEndTime = getExamEndTime(examName, cohort)

        if (!examEndTime) {
          const { at_id: cohortId } = cohort
          getExamRetakeStudents({
            cohortId,
            cohortName,
            examName,
            proctorioExamTitle
          })
          return
        }
        const substractedExamEndTime = substractHoursFromDate(
          examEndTime, 2)

        const timezone = getTimezoneShort(new Date() / 1000)
        const deadline = examEndTime
          ? addDotBtwDateAndTime(
            formatWithoutYear(new Date(substractedExamEndTime * 1000))) +
          ` ${timezone}`
          : ''

        if (
          isCompletedExam(examEndTime)
        ) return completed.push({ examName, deadline, cohortName, examEndTime, proctorioExamTitle })
        upcoming.push({ examName, deadline, cohortName, examEndTime, proctorioExamTitle })
      })
    })

    setCompletedExams(completed)
    setUpcomingExams(upcoming)
  }, [currentCohorts, currentExams])

  useEffect(() => {
    if (!retakeExams) return

    getVersionExamEndTime()
    // eslint-disable-next-line
  }, [retakeExams])

  return (
    <Container padding>
      <PageHeading>Proctorio Exam Gradebook</PageHeading>
      <Separator />
      <Filters
        currentCourse={currentCourse}
        setCurrentCourse={setCurrentCourse}
        currentCohorts={currentCohorts}
        setCurrentCohorts={setCurrentCohorts}
        currentExams={currentExams}
        setCurrentExams={setCurrentExams}
      />
      {currentCourse && <ExamCenterTable
        exams={completedExams}
        heading='Completed Exams'
        isCompletedTable
      />}
      {currentCourse && <ExamCenterTable
        exams={upcomingExams}
        heading='Upcoming Exams'
      />}
    </Container>
  )
}

export default ExamGradeBook

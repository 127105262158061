import { saveAs } from 'file-saver'
import api from '../api'
import {
  GRADE_DETAILS_MAP,
  QUIZ_GRADES,
  TERM_LENGTHS
} from '../Constants/CSVDownloadToolV2'
import {
  dateToISOString,
  getShortDateString
} from './dateTimeUtil'
import { COLLEGE_WRITING_I_GGU_V2_NAME } from '../Constants/courses'

export const areMainFieldsSelected = criteria => {
  const {
    courses,
    termLengths,
    cohortStartRange,
    relationships,
    creditGrantingInstitutions,
    cohortEndRange
  } = criteria || {}

  const hasSelectedCourse = hasArrayValidItem(courses)
  const hasSelectedTermLength = hasArrayValidItem(termLengths)
  const hasSelectedDateRange = hasArrayValidItem(cohortStartRange, 2) ||
    hasArrayValidItem(cohortEndRange, 2)
  const partnerFieldsSelected = hasArrayValidItem(relationships) ||
    hasArrayValidItem(creditGrantingInstitutions)

  const firstCondition = hasSelectedCourse && hasSelectedTermLength
  const secondCondition = hasSelectedDateRange && partnerFieldsSelected

  return firstCondition && secondCondition
}

export const hasArrayValidItem = (value, min = 1) => {
  const isNotNull = (item) => item !== null

  return !!(value && value.filter(isNotNull).length >= min)
}

export const getSimilarKeyValueObject = keys => {
  return keys.reduce((acc, item) => {
    return { ...acc, [item]: item }
  }, {})
}

export const getPresetType = (usePreset, selectedPreset) => {
  const isManual = usePreset === false

  if (isManual) return 'manual'
  return selectedPreset || ''
}

export const getCourseOptions = async (isPittCourses = false) => {
  let courses = await api.getCourses()
  if (isPittCourses) {
    courses = courses?.filter(course => course.pittCourseCode) || []
  }

  // filter out CW V2 courses
  courses = courses?.filter(course => course?.name !== COLLEGE_WRITING_I_GGU_V2_NAME) || []

  const options = courses.map(course => {
    const { name, id } = course

    return {
      name,
      label: name,
      value: id
    }
  })

  return options
}

export const getTermLenthOptions = () => {
  const options = {
    'Extended (39 weeks)': TERM_LENGTHS.EXTENDED,
    'Standard (14 weeks)': TERM_LENGTHS.STANDARD,
    'Intensive (7 weeks)': TERM_LENGTHS.INTENSIVE,
    'Studio/Other': TERM_LENGTHS.STUDIOOTHER
  }

  return getSelectorOptions(options)
}

export const getUsePresetOptions = () => {
  const options = {
    Yes: true,
    No: false
  }

  return getSelectorOptions(options)
}

export const getPartnerPresetOptions = () => {
  const options = {
    'University of Pittsburgh - Enrollment Preset': 'pittEnrollment',
    'University of Pittsburgh - Repeat Preset': 'pittRepeat',
    'University of Pittsburgh - Withdraw/Audit': 'pittWithdrawOrAudit',
    'University of Pittsburgh - Final Grades': 'pittFinalGrades'
  }

  return getSelectorOptions(options)
}

export const getStudentGradePresetOptions = () => {
  const options = {
    'Course progress and grades': 'gradesAndCourseProgress',
    'Partner progress report': 'partnerProgressReport',
    'Studio Cohort': 'studioCohort'
  }

  return getSelectorOptions(options)
}

export const getRelationshipsOptions = async () => {
  const relationships = await api.getStudentRelationships()

  const options = relationships.map(({ relationshipName }) => {
    return {
      name: relationshipName,
      label: relationshipName,
      value: relationshipName
    }
  })

  return options
}

export const getCreditGrantingInstitutionsOptions = async () => {
  const creditGrantingInstitutions = await api.getCreditGrantingInstitutions()

  const options = creditGrantingInstitutions.map(institution => {
    return {
      name: institution,
      label: institution,
      value: institution
    }
  })

  options.push({
    name: 'None',
    label: 'None',
    value: ''
  })

  return options
}

export const getEnrollmentStatusOptions = async () => {
  const items = await api.getStudentStatuses()

  const options = items.map(({ name }) => {
    return {
      name,
      label: name,
      value: name
    }
  })

  return options
}

export const getStudentGradesOptions = () => {
  const options = {
    'Fetch final grades': 'fetchGrades',
    'Fetch grade details': 'fetchGradeDetails'
  }

  return getSelectorOptions(options)
}

export const getOtherInformationOptions = () => {
  const options = {
    'Only scholarship students': 'scholarship',
    'Only partnership students': 'onlyPartnerStudents',
    'Include contractor employees': 'contractorEmployee',
    'Include test attempts': 'testAttempts'
  }

  return getSelectorOptions(options)
}

export const STUDENT_IDENTIFICATIONS = [
  'First name',
  'Last name',
  'Full legal name',
  'Preferred name',
  'Student ID',
  'Attempt ID',
  'Date of birth',
  'Gender',
  'Country of citizenship'
]

export const STUDENT_CONTACT_INFO = [
  'Email address',
  'Phone number',
  'Mailing address'
]

export const OTHER_STUDENT_INFO = [
  'Current Grade (numerical)',
  'Course final grade (numerical)',
  'Course final grade (letter)',
  'Enrollment status',
  'Status change date'
]

export const COHORT_INFO = [
  'Cohort name',
  'Start date',
  'Duration',
  'Partner university'
]

export const LOGIN_ACTIVITY = [
  'First login',
  'Last active'
]

export const GRADE_DETAILS = [
  'Section final grade',
  'Section progress',
  'Guesswork progress',
  'Lecture progress',
  'Active Learning progress',
  'Practice exercises progress',
  'Practice Terms progress',
  'Quiz Progress',
  'Quiz grades - all',
  'Quiz grades - highest score only',
  'Coursera Activities',
  'Writing assignment grades',
  'Assessment timestamps',
  'Midterm grades',
  'Final exam grade',
  'Participation grade'
]

export const getStudentIndentificationOptions = () => {
  const options = getSimilarKeyValueObject(STUDENT_IDENTIFICATIONS)

  return getSelectorOptions(options)
}

export const getStudentContactOptions = () => {
  const options = getSimilarKeyValueObject(STUDENT_CONTACT_INFO)

  return getSelectorOptions(options)
}

export const getOtherStudentInfoOptions = () => {
  const options = getSimilarKeyValueObject(OTHER_STUDENT_INFO)

  return getSelectorOptions(options)
}

export const getCohortInfoOptions = () => {
  const options = getSimilarKeyValueObject(COHORT_INFO)

  return getSelectorOptions(options)
}

export const getLoginActivityOptions = () => {
  const options = getSimilarKeyValueObject(LOGIN_ACTIVITY)

  return getSelectorOptions(options)
}

export const getGradeDetailOptions = () => {
  const options = getSimilarKeyValueObject(GRADE_DETAILS)

  options['Quiz grades - all'] = QUIZ_GRADES.ALL
  options['Quiz grades - highest score only'] = QUIZ_GRADES.HIGHEST
  options['Practice Terms progress'] = GRADE_DETAILS_MAP.PRACTICE_TERMS

  return getSelectorOptions(options)
}

export const getCreateCSVForCoursesOptions = () => {
  const options = {
    'Create CSV for each selected course': true
  }

  return getSelectorOptions(options)
}

export const getSelectorOptions = options => {
  return Object.keys(options).map(name => {
    return {
      name,
      value: options[name]
    }
  })
}

export const getAllOptionsValue = options => {
  return options.map(({ value }) => value)
}

export const getAllNoneOptionsValue = options => {
  return options.filter(({ value }) => value !== '').map(({ value }) => value)
}

export const changeDatesToISOString = dates => {
  if (!hasArrayValidItem(dates, 2)) return []

  return dates.filter(Boolean).map(date => dateToISOString(date))
}

export const getDownloadFileName = startDateRange => {
  if (!hasArrayValidItem(startDateRange, 2)) return 'Rosters'

  const [from, to] = startDateRange

  return `${getShortDateString(from)}_to_${getShortDateString(to)}_Roster`
}

export const getCSVFileName = startDateRange => {
  return `${getDownloadFileName(startDateRange)}.csv`
}

export const getZIPFileName = startDateRange => {
  return `${getDownloadFileName(startDateRange)}.zip`
}

export const saveTextFile = (content, fileName) => {
  const blob = new Blob([content], { type: 'text/plain;charset=utf-8' })
  saveAs(blob, fileName)
}

export const getFilteredCriteria = criteria => {
  if (!criteria) return criteria

  const { termLengths = [], ...rest } = criteria
  const isStudioOther = termLengths.includes(TERM_LENGTHS.STUDIOOTHER)

  if (isStudioOther) return rest
  return criteria
}

export const formatMultiSelectValue = (multiSelectValue) => {
  if (!multiSelectValue) return multiSelectValue

  const isNone = multiSelectValue.length === 1 && multiSelectValue[0] === ''
  if (isNone) return []

  return multiSelectValue
}

import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const Container = styled.div`
  margin: 64px auto;
  max-width: 1136px;
  ${mediaqueries.desktop`
    padding: 0 16px;
  `}
  ${mediaqueries.mobile`
    padding: 0 8px;
  `}
`
export const Header = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 0;
`
export const Separator = styled.hr`
  margin: 30px 0 31px;
  height: 1px;
  border-color: var(--border-primary);
`
export const CardContainer = styled.div`
  padding-right: 8px;
  padding-left: 8px;
`
export const Card = styled.div`
  background-color: var(--gray-primary);
  padding: 32px 24px;
  border-radius: 5px;
  margin-bottom: 16px;
  height: 152px;
  &:hover{
    text-decoration: none !important;
  }
  ${mediaqueries.tablet`
    padding: 14px;
  `}
`

export const CardIcon = styled.img`
  width: 24px;
`

export const CardTitle = styled.span`
  font-size: 18px;
  vertical-align: middle;
  color: var(--text-primary);
  margin-left: 16px;
  img {
    margin-left: 4px;
  }
  &:hover{
    text-decoration: none !important;
  }
  ${mediaqueries.mobile`
    font-size: 16px;
  `}
`
export const CardDescription = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: var(--text-secondary);
  margin-top: 16px;
  &:hover{
    text-decoration: none !important;
  }
  ${mediaqueries.mobile`
    font-size: 14px;
  `}
`

import React, { useState, useEffect } from 'react'
import {
  ToastWrapper,
  ToastHeader,
  ToastTitle,
  ToastClose,
  ToastMessage
} from './styles'

const Toast = ({ toast = {} }) => {
  const [showToast, setShowToast] = useState(false)
  const [toastTitle, setToastTitle] = useState('')
  const [toastMessage, setToastBody] = useState('')
  const [time, setTime] = useState({ delay: null, period: null })
  const [id, setId] = useState()

  toast.current = {
    display: (title, message, time) => {
      clearTimeout(id)

      setToastTitle(title)
      setToastBody(message)
      setTime(time)

      setTimeout(() => {
        setShowToast(true)
      }, time?.delay || 500)
    }
  }

  useEffect(() => {
    const id = setTimeout(() => {
      setShowToast(false)
    }, time?.period || 5000)

    setId(id)

    return () => clearTimeout(id)
  }, [time, showToast])

  return (
    <ToastWrapper showToast={showToast}>
      <ToastHeader>
        <ToastTitle>{toastTitle}</ToastTitle>
        <ToastClose onClick={() => setShowToast(false)} />
      </ToastHeader>
      { toastMessage &&
        <ToastMessage>
          <p>{toastMessage}</p>
        </ToastMessage>
      }
    </ToastWrapper>
  )
}

Toast.displayName = 'Toast'
export default Toast

import React from 'react'
import { Label, Input } from 'reactstrap'
import { RadioContainer, Wrapper, CustomButton } from './styles'
import '../VipUser/vip-user.css'

const FormTypeSelection = ({ setSelection, selection, setStep, step }) => {
  return (
    <>
      <p>
        Do you wish to set up content gating for a cohort or for individual
        students?
      </p>
      <Wrapper className='vip-user-page__container'>
        <div className='form-wrapper'>
          <RadioContainer className='text-field radio'>
            <Label className='container'>
              Cohort
              <Input
                className='checkbox'
                type='radio'
                checked={selection === 'cohort'}
                onChange={() => setSelection('cohort')}
              />
              <span className='check-mark' />
            </Label>
            <Label className='container'>
              Students
              <Input
                className='checkbox'
                type='radio'
                data-testid='studentsCheckBox'
                checked={selection === 'students'}
                onChange={() => setSelection('students')}
              />
              <span className='check-mark' />
            </Label>
          </RadioContainer>
          {selection && (
            <CustomButton
              selection={selection}
              data-testid='submitButton'
              onClick={() => setStep(step + 1)}
              className='btn-custom btn-primary'
            >
              {selection === 'cohort' ? 'Search' : 'Continue'}
            </CustomButton>
          )}
        </div>
      </Wrapper>
    </>
  )
}

export default FormTypeSelection

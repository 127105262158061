import React, { useEffect, useState } from 'react'
import { uniq } from 'lodash'
import CaretUpSelected from '../../../assets/icons/caret-up-selected.svg'
import CaretUpUnselected from '../../../assets/icons/caret-up-unselected.svg'
import { getDateString, getDateStringWithComma } from '../../../utilities/dateTimeUtil'
import { OptionCheckBox } from '../../AdminStudentDrop/styled'
import {
  VoidAllLicensesContainer,
  VoidAllLicensesSection,
  VoidAllLicensesTitle,
  VoidCoursesLicensesTitleDescription,
  VoidLicensesByDateFilterContainer,
  VoidLicensesByDateFilterHeader,
  VoidLicensesByDateFilterHeaderDescription,
  VoidLicensesByDateFilterReset,
  VoidLicensesByDateFilter,
  VoidLicensesByDateFilterItem,
  VoidLicensesByDateFilterLabel,
  OperatorSelect,
  LicenseDatePickerContainer,
  LicenseDatePicker,
  VoidAllLicensesTableHeader,
  VoidLicensesByDateTableHeaderItem,
  SortContainer,
  VoidAllLicensesTableRow,
  VoidLicensesByDateTableRowItem,
  VoidAllLicensesTableFooter,
  VoidLicensesByDateTableFooterItem,
  VoidAllLicensesMessage
} from './styled'

const operatorOptions = [
  { value: 'onOrAfter', label: 'Is on or after' },
  { value: 'after', label: 'Is after' },
  { value: 'between', label: 'Is between' },
  { value: 'is', label: 'Is' }
]

function VoidLicensesByDate (props) {
  const {
    setTokensToVoid,
    selectedRelationship,
    coursesLicenses
  } = props

  const [sortAscending, setSortAscending] = useState(true)
  const [selectedOperator, setSelectedOperator] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedDate2, setSelectedDate2] = useState(null)
  const [licensesToVoid, setLicensesToVoid] = useState({})

  const availableLicenses =
    coursesLicenses.filter(({ usedStatus }) => !usedStatus)
  const coursesLicensesForSelectedRelationship =
    availableLicenses.filter(({ relationshipName }) =>
      relationshipName === selectedRelationship?.label)
  const filteredCoursesLicenses =
    coursesLicensesForSelectedRelationship.filter(({ tokenExpirationDate }) => {
      if (!selectedDate) {
        return true
      }
      const selectedDateValue = new Date(selectedDate).valueOf()

      const tokenExpirationDateValue = new Date(tokenExpirationDate).valueOf()
      switch (selectedOperator?.value) {
        case 'onOrAfter':
          return tokenExpirationDateValue >= selectedDateValue
        case 'after':
          return tokenExpirationDateValue > selectedDateValue
        case 'between':
          if (!selectedDate2) {
            return true
          }
          const selectedDate2Value = new Date(selectedDate2).valueOf()
          return tokenExpirationDateValue > selectedDateValue &&
            tokenExpirationDateValue < selectedDate2Value
        case 'is':
          return getDateString(tokenExpirationDate) === getDateString(selectedDate)
        default:
          return true
      }
    })
  const groupedCourseLicenses =
    filteredCoursesLicenses.reduce((acc, value) => {
      const { tokenExpirationDate } = value
      const date = getDateStringWithComma(tokenExpirationDate)
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(value)
      return acc
    }, {})
  const sortedCoursesLicenses =
    Object.entries(groupedCourseLicenses).sort(([a], [b]) => {
      return sortAscending
        ? new Date(a) - new Date(b)
        : new Date(b) - new Date(a)
    })
  const totalLicenses = sortedCoursesLicenses.reduce((acc, [key]) => {
    return licensesToVoid[key] ? acc + groupedCourseLicenses[key].length : acc
  }, 0)

  useEffect(() => {
    const tokensToVoid = []
    Object.entries(licensesToVoid).forEach(([key, value]) => {
      if (!value) return

      const keyTokens = groupedCourseLicenses[key]
      tokensToVoid.push(...keyTokens)
    })

    const tokenIds = tokensToVoid.map(({ id }) => id)
    setTokensToVoid(tokenIds)
    // eslint-disable-next-line
  }, [licensesToVoid])

  return (
    <VoidAllLicensesContainer>
      <VoidAllLicensesSection>
        <VoidAllLicensesTitle>
          Licenses
        </VoidAllLicensesTitle>
        <VoidCoursesLicensesTitleDescription>
          Select the expiration date or date range you'd like to void.
        </VoidCoursesLicensesTitleDescription>
        <VoidLicensesByDateFilterContainer>
          <VoidLicensesByDateFilterHeader>
            <VoidLicensesByDateFilterHeaderDescription>
              Expiration date range (optional)
            </VoidLicensesByDateFilterHeaderDescription>
            <VoidLicensesByDateFilterReset
              disabled={!selectedDate}
              onClick={() => {
                setSelectedOperator(null)
                setSelectedDate(null)
                setSelectedDate2(null)
              }}
            >
              reset
            </VoidLicensesByDateFilterReset>
          </VoidLicensesByDateFilterHeader>
          <VoidLicensesByDateFilter>
            <VoidLicensesByDateFilterItem>
              <VoidLicensesByDateFilterLabel>
                Operator
              </VoidLicensesByDateFilterLabel>
              <OperatorSelect
                isClearable
                placeholder='Select operator'
                value={selectedOperator}
                options={operatorOptions}
                height='48px'
                onChange={operator => {
                  setSelectedOperator(operator)
                }}
              />
            </VoidLicensesByDateFilterItem>
            <VoidLicensesByDateFilterItem>
              <VoidLicensesByDateFilterLabel>
                {
                  selectedOperator?.value === 'between'
                    ? 'Date From'
                    : 'Date'
                }
              </VoidLicensesByDateFilterLabel>
              <LicenseDatePickerContainer>
                <LicenseDatePicker
                  data-testid='date-field'
                  className='date'
                  autoComplete='off'
                  placeholderText='mm/dd/yyyy'
                  selected={selectedDate}
                  onChange={date => setSelectedDate(date)}
                />
              </LicenseDatePickerContainer>
            </VoidLicensesByDateFilterItem>
            {selectedOperator?.value === 'between' && (
              <VoidLicensesByDateFilterItem>
                <VoidLicensesByDateFilterLabel>
                  Date To
                </VoidLicensesByDateFilterLabel>
                <LicenseDatePickerContainer>
                  <LicenseDatePicker
                    data-testid='date-field'
                    className='date'
                    autoComplete='off'
                    placeholderText='mm/dd/yyyy'
                    selected={selectedDate2}
                    onChange={date => setSelectedDate2(date)}
                  />
                </LicenseDatePickerContainer>
              </VoidLicensesByDateFilterItem>
            )}
          </VoidLicensesByDateFilter>
        </VoidLicensesByDateFilterContainer>
        <VoidAllLicensesTableHeader>
          <OptionCheckBox
            isChecked={sortedCoursesLicenses.every(([key]) => !!licensesToVoid[key])}
            onClick={() => {
              const allChecked = sortedCoursesLicenses.every(([key]) => !!licensesToVoid[key])
              const newLicensesToVoid = {}
              sortedCoursesLicenses.forEach(([key]) => {
                newLicensesToVoid[key] = !allChecked
              })
              setLicensesToVoid(newLicensesToVoid)
            }}
            marginRight='0'
          />
          <VoidLicensesByDateTableHeaderItem>
            <div>
              expires on
            </div>
            <SortContainer
              onClick={() => setSortAscending(!sortAscending)}
            >
              <img
                src={!sortAscending
                  ? CaretUpSelected
                  : CaretUpUnselected}
                alt='disabled dropdown'
              />
              <img
                style={{ transform: 'rotate(180deg)' }}
                src={sortAscending
                  ? CaretUpSelected
                  : CaretUpUnselected}
                alt='disabled dropdown'
              />
            </SortContainer>
          </VoidLicensesByDateTableHeaderItem>
          <VoidLicensesByDateTableHeaderItem>
            available
          </VoidLicensesByDateTableHeaderItem>
          <VoidLicensesByDateTableHeaderItem>
            courses
          </VoidLicensesByDateTableHeaderItem>
        </VoidAllLicensesTableHeader>
        <div>
          {sortedCoursesLicenses.map(([key, data]) => (
            <VoidAllLicensesTableRow key={key}>
              <VoidLicensesByDateTableRowItem>
                <OptionCheckBox
                  isChecked={!!licensesToVoid[key]}
                  marginRight='0'
                  onClick={() => {
                    const isChecked = !!licensesToVoid[key]
                    setLicensesToVoid({
                      ...licensesToVoid,
                      [key]: !isChecked
                    })
                  }}
                />
              </VoidLicensesByDateTableRowItem>
              <VoidLicensesByDateTableRowItem>
                {key}
              </VoidLicensesByDateTableRowItem>
              <VoidLicensesByDateTableRowItem>
                {data.length}
              </VoidLicensesByDateTableRowItem>
              <VoidLicensesByDateTableRowItem>
                {uniq(data.map(({ course }) => course.displayname)).join(', ')}
              </VoidLicensesByDateTableRowItem>
            </VoidAllLicensesTableRow>
          ))}
        </div>
        <VoidAllLicensesTableFooter>
          <VoidLicensesByDateTableFooterItem>
            Total licenses to void
          </VoidLicensesByDateTableFooterItem>
          <VoidLicensesByDateTableFooterItem>
            {totalLicenses}
          </VoidLicensesByDateTableFooterItem>
        </VoidAllLicensesTableFooter>
      </VoidAllLicensesSection>
      <VoidAllLicensesMessage>
        By clicking “submit”, you agree to void {totalLicenses} licenses for {selectedRelationship.label}.
      </VoidAllLicensesMessage>
    </VoidAllLicensesContainer>
  )
}

export default VoidLicensesByDate

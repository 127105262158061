import React, { useState, useEffect } from 'react'
import {
  PaginationBarContainer,
  PaginationGroup,
  PaginationIcon, PaginationInfo, PaginationInfoText,
  PaginationNumber
} from './ServerSidePagination.styled'
import previousIcon from '../../assets/icons/back-arrow-caret.svg'
import nextIcon from '../../assets/icons/front-arrow-caret.svg'

export default function ServerSidePagination (props) {
  const [visiblePages, handleVisiblePages] = useState([])
  const {
    pageChangeHandler,
    selectedPage,
    handlePage,
    pageCount,
    totalRows,
    pageSize
  } = props

  const offset = selectedPage * pageSize
  const lastPageIndex = offset > totalRows ? totalRows : offset

  useEffect(() => {
    setPageVisibility(1, pageCount)
    // eslint-disable-next-line
  }, [pageCount])

  const setPageVisibility = (page, total) => {
    handlePage(page)
    const data = getVisiblePages(page, total)
    handleVisiblePages(data)
  }

  const getVisiblePages = (page, total) => {
    const isCurrentPagesVisible = page % 3 >= 0 && page > 2 && page + 1 < total
    const isLastPagesVisible = page % 3 >= 0 && page > 2 && page + 1 >= total

    if (isCurrentPagesVisible) {
      return [
        { pageNumber: page - 2, text: '...' },
        page - 1,
        page,
        page + 1,
        { pageNumber: page + 2, text: '...' }
      ]
    } else if (isLastPagesVisible) {
      if (total === 3) {
        return [
          2,
          3
        ]
      }
      return [{ pageNumber: page - 3, text: '...' }, total - 2, total - 1, total]
    } else {
      return total > 3
        ? [1, 2, 3, { pageNumber: 4, text: '...' }]
        : total > 2 ? [1, 2, 3] : total > 1 ? [1, 2] : [1]
    }
  }

  const changePage = (page) => {
    pageChangeHandler(page - 1)
    setPageVisibility(page, pageCount)
    handlePage(page)
  }

  const moveToNextPage = () => {
    pageChangeHandler(selectedPage + 1)
    setPageVisibility(selectedPage + 1, pageCount)
  }

  const moveToPreviousPage = () => {
    pageChangeHandler(selectedPage - 1)
    setPageVisibility(selectedPage - 1, pageCount)
  }

  const moveToFirstPage = () => {
    pageChangeHandler(0)
    setPageVisibility(1, pageCount)
  }

  const moveToLastPage = () => {
    pageChangeHandler(pageCount - 1)
    setPageVisibility(pageCount, pageCount)
  }

  if (!totalRows) return null

  return (
    <PaginationBarContainer>
      <PaginationGroup>
        <PaginationIcon
          data-testid='previous-page-button'
          src={previousIcon}
          disabled={selectedPage === 1}
          onClick={moveToPreviousPage}
        />
        <PaginationNumber
          hidden={selectedPage < 3}
          onClick={() => moveToFirstPage()}
        >
          1
        </PaginationNumber>
        {
          visiblePages.map((page, index, array) => {
            if (page.text) {
              return <PaginationNumber key={index}
                data-testid='page-button'
                onClick={() => changePage(page.pageNumber)}
              >
                {page.text}
              </PaginationNumber>
            }
            return <PaginationNumber key={index}
              data-testid='page-button'
              disabled={selectedPage === page}
              onClick={() => changePage(page)}
            >
              {array[index - 1] + 2 < page ? `${page}` : page}
            </PaginationNumber>
          })
        }
        <PaginationNumber
          data-testid='last-page-button'
          disabled={selectedPage === pageCount}
          hidden={selectedPage >= pageCount - 1 || pageCount <= 3 || !pageCount}
          onClick={() => moveToLastPage()}
        >
          {pageCount}
        </PaginationNumber>
        <PaginationIcon
          data-testid='next-page-button'
          src={nextIcon}
          disabled={selectedPage === pageCount || !pageCount}
          onClick={moveToNextPage}
        />
      </PaginationGroup>
      <PaginationInfo>
        <PaginationInfoText
          data-testid='page-info'
        >
          {`${offset - pageSize + 1} - ${lastPageIndex} of ${totalRows} items`}
        </PaginationInfoText>
      </PaginationInfo>
    </PaginationBarContainer>
  )
}

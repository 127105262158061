import React, { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import {
  StepContainer,
  StepName,
  ScoreContainer,
  ScoreInput
} from './styled/AssessmentModalStyles'

function EditCodingIndividualGradeStep (props) {
  const { categorySteps, originalSteps, index, setCategorySteps } = props
  const {
    name,
    weight,
    achievedPoints
  } = categorySteps[index]

  const [grade, setGrade] = useState(achievedPoints)

  useEffect(() => {
    if (grade === '') return setCategorySteps(originalSteps)

    const steps = cloneDeep(categorySteps)
    steps[index].achievedPoints = grade
    setCategorySteps(steps)
    //eslint-disable-next-line
  }, [grade])

  const handleInput = (e) => {
    if (e.target.value === '') return setGrade('')

    const grade = +e.target.value
    if (grade < 0) return setGrade(0)
    if (grade > weight) return setGrade(weight)
    setGrade(grade)
  }

  return (
    <StepContainer
      key={name}
    >
      <StepName>
        {name}
      </StepName>
      <ScoreContainer>
        <ScoreInput
          type='number'
          className={
            'text-area'
          }
          value={grade}
          onChange={handleInput}
        />
        <div>
          /{weight}
        </div>
      </ScoreContainer>
    </StepContainer>
  )
}

EditCodingIndividualGradeStep.displayName = 'EditCodingIndividualGradeStep'
export default EditCodingIndividualGradeStep

import React, { useState, useMemo, useEffect, useRef } from 'react'
import moment from 'moment'
import { Collapse } from 'reactstrap'
import ReviewTable from './ReviewTable'
import { generateTableData } from './utils'
import { CREDIT_GRANTING_INSTITUTIONS } from '../../Constants/creaditGrantingInstitutions'
import {
  ReviewPageWrapper,
  ReviewTitle,
  SectionWrapper,
  SectionTitle,
  SectionInfo,
  Dot,
  CoursePool,
  CoursePoolItem,
  Selection
} from './styled'

const CoursePoolComponent = ({ items }) => (
  <CoursePool
    mt='12px'
    mb='-12px'
  >
    {items?.map(item => (
      <CoursePoolItem
        data-testid='course-pool-item'
        key={item.label || item}
        mr='0'
      >
        <span>{item.label || item}</span>
      </CoursePoolItem>
    ))}
  </CoursePool>
)

export const ReviewBulkCohort = ({
  selectedInstitution,
  selectedCourses,
  selectedDates = {},
  durationInfo = {},
  price,
  quantity
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const titleRef = useRef()
  const { cohortType, duration } = durationInfo
  const [, GGU] = CREDIT_GRANTING_INSTITUTIONS

  useEffect(() => {
    if (titleRef.current) {
      return titleRef.current.scrollIntoView?.({ top: 0, behavior: 'smooth' })
    }
  }, [])

  const dates = useMemo(() => {
    const dates = Object.values(selectedDates)
      .flat()
      .map(date => moment(date).format('M/D/YY'))
    return dates
  }, [selectedDates])

  const shouldLinkCohortSchedules = durationInfo.linkCohortSchedules

  const tableData = useMemo(() => {
    return generateTableData({
      selectedCourses,
      dates,
      durationInfo
    })
  }, [selectedCourses, dates, durationInfo])

  return (
    <ReviewPageWrapper>
      <ReviewTitle
        isOpen={isOpen}
        onClick={() => setIsOpen(prev => !prev)}
        showBorder
        ref={titleRef}
      >
        Summary Overview
      </ReviewTitle>
      <Collapse isOpen={isOpen}>
        <SectionWrapper>
          <SectionTitle>Credit-granting Institution</SectionTitle>
          <SectionInfo
            data-testid='selected-institution'
          >
            {selectedInstitution}
          </SectionInfo>
          <SectionTitle>
            Courses
            <Dot />
            <span>{selectedCourses?.length}&nbsp;&nbsp;total</span>
          </SectionTitle>
          <CoursePoolComponent items={selectedCourses} />
          <SectionTitle>
            Start Date
            <Dot />
            <span>{dates.length}&nbsp;&nbsp;total</span>
          </SectionTitle>
          <CoursePoolComponent items={dates} />
          <SectionTitle>Duration</SectionTitle>
          <SectionInfo data-testid='cohort-durations'>
            {`${duration} wk ${cohortType}`}
          </SectionInfo>
          <SectionTitle data-testid='create-audit-cohort'>
            Create audit cohort
            {durationInfo?.createAuditCohorts && <Selection>Yes</Selection>}
          </SectionTitle>
          <SectionInfo>
            Shopify and Airtable will create an audit variant of the standard
            cohort.
          </SectionInfo>
          <SectionTitle
            data-testid='link-cohort-schedules'
          >
            Link cohort schedules
            {shouldLinkCohortSchedules && <Selection>Yes</Selection>}
          </SectionTitle>
          <SectionInfo>
            The system will automatically link the default cohort schedule.
          </SectionInfo>
          <SectionTitle data-testid='cohort-deadlines'>Cohort deadlines</SectionTitle>
          <SectionInfo
            data-testid='warning-text'
            showWarning
          >
            All exit and assignment deadlines must be added after cohorts are
            created.
          </SectionInfo>
          <SectionTitle data-testid='shopify-varient-details'>Shopify variant details</SectionTitle>
          {cohortType !== 'Extended' && selectedInstitution !== GGU
            ? (
              <>
                <SectionInfo data-testid='price'>Price: ${price}</SectionInfo>
                <SectionInfo data-testid='quantity'>Quantity: {quantity}</SectionInfo>
              </>
            )
            : (
              <SectionInfo
                isSectionInfo
                data-testid='shopify-info'
              >
                {`${cohortType === 'Extended' ? 'Extended' : 'GGU'} cohorts won't be added to Shopify.`}
              </SectionInfo>
            )}
        </SectionWrapper>
      </Collapse>
      <ReviewTable tableData={tableData} />
    </ReviewPageWrapper>
  )
}

ReviewBulkCohort.displayName = 'ReviewBulkCohort'
export default ReviewBulkCohort

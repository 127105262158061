import React from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import Layout from './Components/Layout/Layout'
import StudentGradeProvider from './contexts/StudentGradeContext'
import CoursesProvider from './contexts/Courses'
import WritingGradeCenterProvider from './contexts/WritingGradeCenterContext'

function App () {
  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <StudentGradeProvider>
        <CoursesProvider>
          <WritingGradeCenterProvider>
            <Layout />
          </WritingGradeCenterProvider>
        </CoursesProvider>
      </StudentGradeProvider>
    </>
  )
}

export default App

import React, { Fragment, useState } from 'react'
import moment from 'moment'
import {
  ButtonAsLink,
  ButtonsContainer,
  CardHeader,
  CardTitle,
  DateString,
  DetailLabel,
  DetailValue,
  DetailsCard,
  DetailsGrid,
  IconContainer
} from './styles'
import { ReactComponent as CheckMark } from '../../../assets/icons/check-mark-dynamic.svg'
import { PrimaryButton } from '../../../Components/global.styled'
import {
  admissionDecisionOptions,
  prospectStatusOptions
} from './selectOptions'
import { getFieldInput, getUpdatedFields } from './utils'
import api from '../../../api'

const editableFields = [
  {
    label: 'Prospect status',
    key: 'prospectStatus',
    options: prospectStatusOptions
  },
  {
    label: 'Admission decision',
    key: 'admissionDecision',
    options: admissionDecisionOptions
  },
  {
    label: 'Advisor',
    key: 'advisorName'
  },
  {
    label: 'Advisor URL',
    key: 'advisorUrl'
  }
]

function AdmissionDetails ({ prospect, setProspect }) {
  const {
    degreeApplicationSubmission,
    appSentToGGU_update: appSentToGGUUpdate,
    prospectStatus,
    admissionDecision,
    advisorName,
    advisorUrl
  } = prospect
  const [isEditing, setIsEditing] = useState(false)
  const [fieldsToEdit, setFieldsToEdit] = useState({
    prospectStatus,
    admissionDecision,
    advisorName,
    advisorUrl
  })

  const handleSave = async () => {
    const { prospectId } = prospect
    const updatedFields = getUpdatedFields(prospect, fieldsToEdit)
    await api.updateProspectDetails(prospectId, updatedFields)

    // TODO: show error message if update fails

    setProspect({
      ...prospect,
      ...fieldsToEdit
    })
    setIsEditing(false)
  }

  return (
    <DetailsCard>
      <CardHeader>
        <CardTitle>Admission details</CardTitle>
        {isEditing ? (
          <ButtonsContainer>
            <ButtonAsLink
              type='button'
              onClick={() => {
                setFieldsToEdit({
                  prospectStatus,
                  admissionDecision,
                  advisorName,
                  advisorUrl
                })
                setIsEditing(false)
              }}
              data-testid='cancel-button'
            >
              Cancel
            </ButtonAsLink>
            <PrimaryButton
              small
              type='button'
              onClick={handleSave}
              data-testid='save-button'
            >
              Save
            </PrimaryButton>
          </ButtonsContainer>
        ) : (
          <ButtonAsLink
            type='button'
            onClick={() => setIsEditing(true)}
            data-testid='edit-button'
          >
            Edit
          </ButtonAsLink>
        )}
      </CardHeader>
      <DetailsGrid wrapChildren>
        <DetailLabel>App submitted</DetailLabel>
        <DateString>
          <IconContainer>
            <CheckMark />
          </IconContainer>
          {degreeApplicationSubmission
            ? moment(degreeApplicationSubmission).format('L hh:mm a')
            : '--'}
        </DateString>
        <DetailLabel>App sent to GGU</DetailLabel>
        <DateString>
          <IconContainer>
            <CheckMark />
          </IconContainer>
          {appSentToGGUUpdate
            ? moment(appSentToGGUUpdate).format('L hh:mm a')
            : '--'}
        </DateString>
        {editableFields.map((field) => {
          const { key, label } = field
          return (
            <Fragment key={key}>
              <DetailLabel>{label}</DetailLabel>
              {isEditing ? (
                getFieldInput({
                  field,
                  value: fieldsToEdit,
                  setValue: setFieldsToEdit
                })
              ) : (
                <DetailValue>{prospect[key]}</DetailValue>
              )}
            </Fragment>
          )
        })}
      </DetailsGrid>
    </DetailsCard>
  )
}

AdmissionDetails.displayName = 'AdmissionDetails'
export default AdmissionDetails

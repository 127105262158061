import React, { useMemo, useState } from 'react'
import Select, { components } from 'react-select'
import {
  ActivitiesWrapper,
  ActivityPanelWrapper,
  ActivitySelectStyles,
  CommentButton,
  CommentButtonContainer,
  CommentCancelButton,
  CommentInput,
  CommentWrapper,
  Separator
} from './index'
import filterIcon from '../../../assets/icons/filter.svg'
import filterAppliedIcon from '../../../assets/icons/filter-applied.svg'
import ActivityItem from './ActivityItem'
import api from '../../../api'

const options = [
  { value: 'all', label: 'All activity' },
  { value: 'revision', label: 'Revision history' },
  { value: 'comment', label: 'Comments' }
]

const lowerCaseKeys = (obj = {}) => {
  return Object.entries(obj).reduce((newObj, [key, value]) => {
    newObj[key.toLowerCase()] = value
    return newObj
  }, {})
}

export const ActivityPanel = ({
  rowEditHistory,
  rowCommentsHistory,
  tableName,
  recordId,
  fetchData,
  row
}) => {
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [isTextAreaFocused, setIsTextAreaFocused] = useState(false)
  const [commentText, setCommentText] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  const includeNextActivity = (activitiesList) => {
    return activitiesList.map((activity, index) => {
      const nextActivity = () => {
        if (activitiesList.length === 1) {
          return null
        }
        if (index === 0) {
          return { fields: { old_val: lowerCaseKeys(row) } }
        }
        if (index < activitiesList.length - 1) {
          return activitiesList[index + 1]
        }

        return null
      }

      return { activity, nextActivity: nextActivity() }
    })
  }

  const ActivitiesArea = useMemo(() => {
    const filteredActivitiesList = () => {
      if (selectedOption.value === 'all') {
        return rowEditHistory.concat(rowCommentsHistory)
      }
      if (selectedOption.value === 'revision') {
        return rowEditHistory
      }
      return rowCommentsHistory
    }
    const sortedActivitiesList = filteredActivitiesList().sort(
      (a, b) => new Date(b.fields.updated_time) - new Date(a.fields.updated_time)
    )
    const activitiesWithNext = includeNextActivity(sortedActivitiesList)

    return activitiesWithNext.map(({ activity, nextActivity }, index) => (
      <ActivityItem
        activity={activity}
        key={index}
        nextActivity={nextActivity}
        activitiesCount={activitiesWithNext.length}
        activityIndex={index}
      />
    ))

    // eslint-disable-next-line
  }, [selectedOption, rowEditHistory, rowCommentsHistory])

  const saveComment = async () => {
    setIsSaving(true)
    await api.createTableRecordComment(tableName, recordId, commentText)
    setCommentText('')
    fetchData()
    setIsSaving(false)
  }

  const DropdownIndicator = (props) => {
    const isFilterSelected = ['revision', 'comment']
      .includes(selectedOption.value)
    const icon = isFilterSelected ? filterAppliedIcon : filterIcon

    return (
      <components.DropdownIndicator {...props}>
        <img src={icon} alt='filter-icon' />
      </components.DropdownIndicator>
    )
  }

  return (
    <ActivityPanelWrapper>
      <Select
        components={{ DropdownIndicator }}
        options={options}
        value={selectedOption}
        onChange={option => {
          setSelectedOption(option)
        }}
        styles={{ ...ActivitySelectStyles }}
        isSearchable={false}
      />
      <Separator />
      <ActivitiesWrapper isTextAreaFocused={isTextAreaFocused}>
        {ActivitiesArea}
      </ActivitiesWrapper>
      <CommentWrapper>
        <CommentInput
          onFocus={() => setIsTextAreaFocused(true)}
          onBlur={() => setIsTextAreaFocused(false)}
          placeholder='leave a comment'
          autoComplete='off'
          type='textarea'
          value={commentText}
          onChange={e => setCommentText(e.target.value)}
        />
        <CommentButtonContainer isTextAreaFocused={isTextAreaFocused}>
          <CommentButton
            disabled={!commentText || isSaving}
            onMouseDown={(e) => {
              e.preventDefault()
              saveComment(commentText)
            }}
          >
            {isSaving ? (
              <i
                className='fa fa-spinner fa-spin'
                style={{ color: 'var(--brand-regular)' }}
              />
            ) : (
              'comment'
            )}
          </CommentButton>
          <CommentCancelButton onClick={() => setIsTextAreaFocused(false)}>
            cancel
          </CommentCancelButton>
        </CommentButtonContainer>
      </CommentWrapper>
    </ActivityPanelWrapper>
  )
}
ActivityPanel.displayName = 'ActivityPanel'
export default ActivityPanel

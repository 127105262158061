import React, { useEffect, useState } from 'react'
import {
  AddExtensionButton,
  Container,
  ExtensionTitle,
  Header,
  HeaderContainer,
  Separator
} from './style'

import ExtensionsTable from './ExtensionsTable'
import AssessmentExtensionFilter from './AssessmentExtensionFilter'
import ExtensionToast from './AddExtension/ExtensionToast'

const AssessmentExtensions = ({ routerProps }) => {
  const [cohortFilter, setCohortFilter] = useState('')
  const [studentFilter, setStudentFilter] = useState('')
  const [courseFilter, setCourseFilter] = useState('')
  const [extensionToastMessage, setExtensionToastMessage] = useState(null)

  const { history } = routerProps

  useEffect(() => {
    const toastMessage = history?.location?.state?.extensionToastMessage
    if (toastMessage) {
      setExtensionToastMessage(toastMessage)
    }
  }, [history])

  const handleCohortFilter = (cohort) => {
    if (cohort === cohortFilter) return
    setCohortFilter(cohort)
  }

  const handleStudentFilter = (studentIdOrEmail) => {
    setStudentFilter(studentIdOrEmail)
  }

  const handleCourseFilter = (courseId) => {
    if (courseId === courseFilter) return
    setCourseFilter(courseId)
  }

  const goToEditExtension = (recordRow) => {
    history.push({
      pathname: '/assessment-extensions/create',
      state: { extensionToEdit: recordRow }
    })
  }

  return (
    <Container>
      <HeaderContainer>
        {extensionToastMessage &&
          <ExtensionToast extensionToastMessage={extensionToastMessage} />
        }
        <Header>
          Assessment Extensions
        </Header>
        <AddExtensionButton
          data-testid='add-extension-button'
          href='#/assessment-extensions/create'
          className='btn btn-custom'>
          <span>Add Extension</span>
        </AddExtensionButton>
      </HeaderContainer>
      <Separator />
      <ExtensionTitle>
        Extension history
      </ExtensionTitle>
      <AssessmentExtensionFilter
        handleCohortFilter={handleCohortFilter}
        handleStudentFilter={handleStudentFilter}
        handleCourseFilter={handleCourseFilter}
      />
      <ExtensionsTable
        cohortFilter={cohortFilter}
        studentFilter={studentFilter}
        courseFilter={courseFilter}
        goToEditExtension={goToEditExtension}
      />
    </Container>
  )
}

AssessmentExtensions.displayName = 'AssessmentExtensions'
export default AssessmentExtensions

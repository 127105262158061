import React, { useEffect, useState } from 'react'
import {
  getOutstandingRequests,
  getPassedRequirements,
  sortRequests
} from '../../utilities/amazonRefunds'
import {
  SectionContainer,
  RequestsContainer,
  SectionHeader,
  ViewAll
} from './styled'
import { getDateString } from '../../utilities/dateTimeUtil'
import approveIcon from '../../assets/icons/icon-correct.svg'
import denyIcon from '../../assets/icons/icon-incorrect.svg'
import DecisionModal from './DecisionModal'

const RequestItem = ({ request, reviewHandler }) => {
  if (!request) return null

  const {
    requestReceived,
    certificateName,
    studentName,
    studentEmail,
    latestRefundDate,
    earliestDropDate
  } = request

  const requestReceivedDate = new Date(requestReceived)
  const requestReceivedDateString = getDateString(requestReceivedDate, 'MM/DD/YY')
  const passedRequirements = getPassedRequirements({
    requestReceived,
    earliestDropDate,
    latestRefundDate
  })
  const totalRequirements = 2
  const canApprove = passedRequirements === totalRequirements

  return (
    <div>
      <span>{requestReceivedDateString}</span>
      <span className='flex'>{certificateName}</span>
      <span className='flex'>{studentName}</span>
      <span className='flex'>{studentEmail}</span>
      <span>
        <img
          src={canApprove ? approveIcon : denyIcon}
          alt={canApprove ? 'Passed Requirements' : 'Failed Requirements'}
        />
        {`${passedRequirements}/${totalRequirements} requirements`}
      </span>
      <span
        className='link'
        onClick={() => {
          reviewHandler({
            ...request,
            passedRequirements,
            totalRequirements,
            approveIcon,
            denyIcon
          })
        }}
      >
        review
      </span>
    </div>
  )
}

const Requests = ({ refundRequests, getRefundRequests }) => {
  const [viewAll, setViewAll] = useState(false)
  const [requests, setRequests] = useState(
    getOutstandingRequests(refundRequests)
  )
  const [showDecisionModal, setShowDecisionModal] = useState(false)
  const [currentRequest, setCurrentRequest] = useState(null)

  useEffect(() => {
    if (!refundRequests?.length) return setRequests([])

    const outstandingRequests = getOutstandingRequests(refundRequests)
    setRequests(sortRequests(outstandingRequests, 'requestReceived'))

    // eslint-disable-next-line
  }, [requests])

  const reviewHandler = request => {
    setCurrentRequest(request)
    setShowDecisionModal(true)
  }

  if (!requests?.length) return null

  return (
    <SectionContainer data-testid='pending-requests'>
      <SectionHeader>
        Requests ({requests?.length || 0})
      </SectionHeader>
      <RequestsContainer>
        {requests
          .slice(0, viewAll ? requests.length : 5)
          .map((request, index) => {
            return (
              <RequestItem
                id='requests'
                key={`request-${index}-${request.voucherNumber}`}
                request={request}
                reviewHandler={reviewHandler}
              />
            )
          })}
      </RequestsContainer>
      {requests.length > 5 && (
        <ViewAll id='viewAll' onClick={() => setViewAll(viewAll => !viewAll)}>
          {`View ${viewAll ? 'Less' : 'All'}`}
        </ViewAll>
      )}
      {showDecisionModal && (
        <DecisionModal
          show={showDecisionModal}
          setShowDecisionModal={setShowDecisionModal}
          getRefundRequests={getRefundRequests}
          request={currentRequest}
        />
      )}
    </SectionContainer>
  )
}

Requests.displayName = 'Requests'

export default Requests

import React, { useState } from 'react'
import {
  DetailItem,
  SectionBody,
  SectionContainer,
  SectionElement
} from '../style/ProspectPage'
import { Separator } from '../../../styles'
import { SectionTitle } from '../../../styles/text'
import { TransparentButton } from '../../../styles/buttons'
import CustomSelect from '../../../Components/CustomSelect/UpdatedSelect'
import { admissionDetails, degreeStatusOptions } from '../utils/constants'

function AdmissionDetails ({
  prospect,
  degreesData,
  editedProspect,
  setEditedProspect
}) {
  const [editingStatus, setEditingStatus] = useState({ degreeName: false, degreeStatus: false })

  const handleDegreeEdit = (field) => {
    setEditingStatus(prev => ({ ...prev, [field]: !prev[field] }))
  }

  const handleDegreeChange = (field, value) => {
    setEditedProspect(prev => ({ ...prev, [field]: value.name }))
    setEditingStatus(prev => ({ ...prev, [field]: false }))
  }

  return (
    <SectionContainer>
      <SectionTitle>Admission details</SectionTitle>

      <SectionBody gap='8px'>
        {admissionDetails.map(({ title, value }) => (
          <SectionElement key={title}>
            <DetailItem isTitle minWidth='140'>{title}</DetailItem>
            <DetailItem>{prospect?.[value] || '--'}</DetailItem>
          </SectionElement>
        ))}

        <Separator style={{ margin: '8px 0' }} />

        <SectionElement>
          <DetailItem isTitle minWidth='140'>Degree</DetailItem>

          <DetailItem>
            {!editingStatus.degreeName ? (
              editedProspect?.degreeName || '--'
            ) : (
              <CustomSelect
                width='250px'
                height='48px'
                name='degree'
                closeMenuOnSelect
                hideSelectedOptions={false}
                options={degreesData}
                value={degreesData?.find(d => d?.name === editedProspect?.degreeName)}
                onChange={(value) => handleDegreeChange('degreeName', value)}
              />
            )}
          </DetailItem>

          {!!prospect?.degreeName && (
            <TransparentButton
              onClick={() => handleDegreeEdit('degreeName')}
            >
              {editingStatus.degreeName ? 'cancel' : 'edit'}
            </TransparentButton>
          )}
        </SectionElement>

        <SectionElement>
          <DetailItem isTitle minWidth='140'>Degree status</DetailItem>

          <DetailItem>
            {!editingStatus.degreeStatus ? (
              editedProspect?.degreeStatus || '--'
            ) : (
              <CustomSelect
                width='150px'
                height='48px'
                name='degreeStatus'
                closeMenuOnSelect
                hideSelectedOptions={false}
                options={degreeStatusOptions}
                value={degreeStatusOptions.find(s => s.value === editedProspect?.degreeStatus)}
                onChange={(value) => handleDegreeChange('degreeStatus', value)}
              />
            )}
          </DetailItem>

          {!!prospect?.degreeStatus && (
            <TransparentButton
              onClick={() => handleDegreeEdit('degreeStatus')}
            >
              {editingStatus.degreeStatus ? 'cancel' : 'edit'}
            </TransparentButton>
          )}
        </SectionElement>
      </SectionBody>
    </SectionContainer>
  )
}

AdmissionDetails.displayName = 'AdmissionDetails'
export default AdmissionDetails

import React, { useEffect, useRef } from 'react'
import {
  ModalContainer,
  ModalBody,
  ModalHeader,
  CloseIcon
} from './styles/modal'
import CrossIcon from '../../assets/icons/cross.svg'

const ModalComponent = (props) => {
  const {
    handleClose,
    children,
    title,
    width,
    marginLeft
  } = props
  const wrapperRef = useRef()

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeClick, false)
    document.addEventListener('mousedown', handleClickOutside, false)

    return () => {
      document.removeEventListener('keydown', handleEscapeClick, false)
      document.removeEventListener('mousedown', handleClickOutside, false)
    }
    // eslint-disable-next-line
  }, [])

  const handleEscapeClick = (event) => {
    if (event.keyCode === 27 && handleClose) {
      handleClose()
    }
  }

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      handleClose()
    }
  }

  return (
    <ModalContainer
      marginLeft={marginLeft}
      width={width}
      ref={wrapperRef}
    >
      <div>
        <ModalHeader>
          <span>
            {title}
          </span>
          <CloseIcon src={CrossIcon} alt='Cross Icon' onClick={handleClose} />
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
      </div>
    </ModalContainer>
  )
}

export default ModalComponent

import { Content, Data, DataRow, OldDeadline } from './style'
import React from 'react'
import {
  getDateString
} from '../../../utilities/dateTimeUtil'
import { getAssessmentNewDeadline } from './AddExtensionUtils'
import orderBy from 'lodash/orderBy'

const MultipleAssessmentsReview = ({
  assessmentsList,
  selectedNumber,
  selectedUnitLength
}) => {
  const { value: numberOfUnit } = selectedNumber
  const { value: unit } = selectedUnitLength
  const sortedAssessmentsList = orderBy(assessmentsList, 'unlockTime', 'asc')
  return (
    <>
      <Content>
        <span>
          {`${numberOfUnit} ${unit} added to each deadline.`}
        </span>
      </Content>
      {sortedAssessmentsList.map((assessment, index) => {
        const { lockTime: deadline } = assessment
        const assessmentNewDeadlineDate = getAssessmentNewDeadline(
          deadline,
          numberOfUnit,
          unit
        )
        const formattedDeadlineDate =
          getDateString(deadline, 'MM/DD/YY')
        const formattedNewDeadlineDate =
          getDateString(assessmentNewDeadlineDate, 'MM/DD/YY')
        return (
          <Content key={index}>
            <DataRow>
              <span>{assessment.examTitle}</span>
            </DataRow>
            <DataRow style={{ paddingLeft: '24px' }}>
              <OldDeadline>{formattedDeadlineDate}</OldDeadline>
              <Data>{formattedNewDeadlineDate}</Data>
            </DataRow>
          </Content>
        )
      })}
    </>
  )
}
MultipleAssessmentsReview.displayName = 'MultipleAssessmentsReview'
export default MultipleAssessmentsReview

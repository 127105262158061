import styled from 'styled-components'
import Button from '../Button/Button'
import {
  Container,
  PageHeading,
  Separator
} from '../WritingGradeCenter/styled'

export const NotificationContainer = styled(Container)`
  margin-right: 24px;
  margin-left: 24px;
`

export const NotificationSeparator = styled(Separator)`
  margin-top: 49px;
  margin-bottom: 48px;
`

export const NotificationPageHeading = styled(PageHeading)`
  margin-bottom: 0px;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const NotificationButton = styled(Button)`
  background-color: #5FC4B8 !important;

  &&& {
    line-height: 17px !important;
    font-size: 14px !important;
  }
`

export const History = styled.span`
  display: block;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 24px;
`

export const HeadingText = styled(NotificationPageHeading)`
  text-transform: none;
`

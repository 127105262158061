import styled from 'styled-components'
import Select from 'react-select'

export const CourseDropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .btn-custom {
    font-size: 14px !important;
    line-height: 17px !important;
  }
`

export const CustomSelect = styled(Select)`
  width: 350px;
  min-height: 40px;
  margin-bottom: ${({ reduced }) => reduced ? '15px' : '24px'};
  background-color: var(--input-primary);
  & > .select__control {
    width: 100%;
    height: auto;
    min-height: 40px;
    &.select__control--is-focused {
      border: 1px solid var(--brand-regular) !important;
    }
    .select__indicators {
      border-left: 1px solid var(--border-primary);
      padding-left: 7px;
      margin: 3px auto;
      .select__clear-indicator {
        padding-left: 1px;
      }
    }
  }
`

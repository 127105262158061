import React from 'react'
import addRelationshipBtn from '../../assets/icons/add-relationship.svg'
import editRelationshipBtn from '../../assets/icons/edit-relationship.svg'
import addCourseLicensesBtn from '../../assets/icons/add-course-licenses.svg'
import chevronRight from '../../assets/icons/chevron-right.svg'
import viewLicenseDashboard from '../../assets/icons/file-earmark-ruled.svg'
import voidLicenses from '../../assets/icons/void-licenses.svg'
import {
  Container,
  PageHeading,
  Separator,
  OptionsContainer,
  OptionContainer,
  TitleContainer,
  Title,
  Description
} from './styles'

function ManageRelationships () {
  return (
    <Container>
      <PageHeading>Manage Relationships</PageHeading>
      <Separator />
      <OptionsContainer>
        <OptionContainer href='#/relationships/add-course-licenses'>
          <TitleContainer>
            <img src={addCourseLicensesBtn} alt='add-course-licenses' />
            <Title>
              Add course licenses
            </Title>
            <img src={chevronRight} alt='chevron-right' />
          </TitleContainer>
          <Description>
            Add course licenses to relationships
          </Description>
        </OptionContainer>
        <OptionContainer href='#/relationships/new'>
          <TitleContainer>
            <img src={addRelationshipBtn} alt='add-relationship' />
            <Title>
              Add Relationship
            </Title>
            <img src={chevronRight} alt='chevron-right' />
          </TitleContainer>
          <Description>
            Create a new Relationship
          </Description>
        </OptionContainer>
        <OptionContainer href='#/relationships/edit'>
          <TitleContainer>
            <img src={editRelationshipBtn} alt='edit-relationship' />
            <Title>
              Edit Relationship
            </Title>
            <img src={chevronRight} alt='chevron-right' />
          </TitleContainer>
          <Description>
            Edit an existing Relationship
          </Description>
        </OptionContainer>
        <>
          <OptionContainer href='#/relationships/view-license-dashboard'>
            <TitleContainer>
              <img src={viewLicenseDashboard} alt='view-license-dashboard' />
              <Title>
                View license balance
              </Title>
              <img src={chevronRight} alt='chevron-right' />
            </TitleContainer>
            <Description>
              Review the License balance for every relationship
            </Description>
          </OptionContainer>
          <OptionContainer href='#/relationships/void-licenses'>
            <TitleContainer>
              <img src={voidLicenses} alt='void-licenses' />
              <Title>
                Void licenses
              </Title>
              <img src={chevronRight} alt='chevron-right' />
            </TitleContainer>
            <Description>
              Void licenses by district, school, course, or expiration date
            </Description>
          </OptionContainer>
        </>
      </OptionsContainer>
    </Container>
  )
}

export default ManageRelationships

import React from 'react'
import LicenseSubtractDisabled from '../../../assets/icons/license-subtract-disabled.svg'
import LicenseSubtract from '../../../assets/icons/license-subtract.svg'
import LicenseAdd from '../../../assets/icons/license-add.svg'
import {
  CourseListTable,
  CourseListTableHeader,
  HeaderCourse,
  HeaderDuration,
  HeaderLicenses,
  CourseListTableRow,
  RowCourse,
  RowDuration,
  RowLicenses,
  RowLicenseCount,
  RowLicenseImg
} from './styled'

function CourseList (props) {
  const {
    filteredCourseLicenses,
    tokens,
    setTokens,
    relationship
  } = props

  function handleAddLicense (courseLicense) {
    const newTokens = { ...tokens }

    if (newTokens[courseLicense]) {
      newTokens[courseLicense]++
    } else {
      newTokens[courseLicense] = 1
    }
    setTokens(newTokens)
  }

  function handleRemoveLicense (courseLicense) {
    if (!tokens[courseLicense]) return

    const newTokens = { ...tokens }
    newTokens[courseLicense]--

    setTokens(newTokens)
  }

  function keyPressHandler (event) {
    if (['-', 'e', 'E'].includes(event.key)) {
      event.preventDefault()
    }
  }

  function handleChangeLicenseCount (event, courseLicense) {
    const newTokens = { ...tokens }

    let value = parseInt(event.target.value)
    if (value < 0 || isNaN(value)) value = 0

    newTokens[courseLicense] = value
    setTokens(newTokens)
  }

  const disableEditingLicenseCount = !relationship

  return (
    <CourseListTable>
      <CourseListTableHeader>
        <HeaderCourse>Course</HeaderCourse>
        <HeaderDuration>Duration</HeaderDuration>
        <HeaderLicenses>Licenses</HeaderLicenses>
      </CourseListTableHeader>
      {filteredCourseLicenses.map(license => {
        const {
          courseLicense,
          courseName,
          duration
        } = license
        return (
          <CourseListTableRow key={courseLicense}>
            <RowCourse>{courseName}</RowCourse>
            <RowDuration>{duration} Weeks</RowDuration>
            <RowLicenses>
              {tokens[courseLicense] ? (
                <RowLicenseImg
                  src={LicenseSubtract}
                  alt='subtract-license'
                  onClick={() => handleRemoveLicense(courseLicense)}
                />
              ) : (
                <img
                  src={LicenseSubtractDisabled}
                  alt='subtract-license-disabled'
                />
              )}
              <RowLicenseCount
                id={`license-count-${courseLicense}`}
                value={tokens[courseLicense] || 0}
                type='number'
                onKeyPress={(event) => keyPressHandler(event)}
                onChange={(event) => handleChangeLicenseCount(event, courseLicense)}
                disabled={disableEditingLicenseCount}
              />
              <RowLicenseImg
                src={LicenseAdd}
                alt='add-license'
                onClick={() => handleAddLicense(courseLicense)}
                isDisabled={disableEditingLicenseCount}
              />
            </RowLicenses>
          </CourseListTableRow>
        )
      })}
    </CourseListTable>
  )
}

export default CourseList

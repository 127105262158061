import React from 'react'
import { WarningWrapper } from './styled'
import { ReactComponent as WarningIcon } from '../../assets/icons/exclamation-circle.svg'

function WarningAlert ({ message }) {
  return (
    <WarningWrapper>
      <WarningIcon />
      <p>{message}</p>
    </WarningWrapper>
  )
}

WarningAlert.displayName = 'WarningAlert'
export default WarningAlert

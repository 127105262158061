import React, { useMemo } from 'react'
import StudentGradeSection from './StudentGradeSection'
import './StudentGradeTable.css'
import { isStudentProgressFlag } from '../../config'
import { getUpdatedDatoAssignmentDetails } from '../../utilities/writingGradeCenterUtils'
import { addCourseraActivitiesToCourseData } from '../../utilities/courseUtils'
import { COURSERA_ACTIVITY } from '../../Constants/chapter'
import CourseraActivityGradeSection from './CourseraActivityGradeSection'
import { filterGoogleDataAnalyticsIChapters } from '../../utilities/gradeReportUtils'

function StudentGradeTable ({
  cohort,
  selectedStudentCohort,
  courseData: propsCourseData,
  syllabus
}) {
  const courseData = addCourseraActivitiesToCourseData(propsCourseData, syllabus)
  const courseId = courseData?.course_uuid
  const { at_id: cohortId, dateStart } = cohort || {}

  const filteredChapters = filterGoogleDataAnalyticsIChapters(courseData?.chapters, courseId, dateStart)

  const selectedCohort = useMemo(() => selectedStudentCohort || {}, [selectedStudentCohort])
  let sectionNumber

  return (
    <div className={'table-wrapper'}>
      <div className={'table-grid'}>
        <span />
        <span>Section</span>
        <span>Final Score</span>
        <span>Attempts</span>
        {isStudentProgressFlag ? <span>Completion</span>
          : <span>Progress</span>}
      </div>
      {filteredChapters && filteredChapters.map((chapter, index) => {
        if (chapter.type === 'exam') {
          return <StudentGradeSection
            key={chapter.chapter_uuid}
            section={chapter}
            courseData={courseData}
            cohort={selectedCohort}
          />
        }
        if (['WritingAssignmentChapterRecord',
          'CodingAssignmentChapterRecord'].includes(chapter.type)) {
          const hasMilestone = selectedStudentCohort?.milestones?.some(
            assignment => assignment.datoAssignmentUUID === chapter.chapter_uuid
          )
          if (!hasMilestone) return null

          return <StudentGradeSection
            key={chapter.chapter_uuid}
            section={getUpdatedDatoAssignmentDetails({
              courseId: courseData.course_uuid,
              currentAssignment: selectedStudentCohort || {},
              datoAssignmentDetails: chapter
            })}
            courseData={courseData}
            cohort={selectedCohort}
          />
        }

        if (chapter.assignmentType === COURSERA_ACTIVITY) {
          return <CourseraActivityGradeSection
            key={`courseraActivity_${index}`}
            section={chapter}
          />
        }

        if (chapter.type === 'chapter') {
          sectionNumber = 0
          const { chapterNumber, sections } = chapter
          return sections.map((section, id) => {
            if (section.assignmentType === COURSERA_ACTIVITY) {
              return <CourseraActivityGradeSection
                key={`courseraSectionActivity_${chapterNumber}_${id}`}
                section={section}
              />
            }

            sectionNumber++
            return <StudentGradeSection
              key={section.section_uuid}
              section={section}
              courseData={courseData}
              chapterNumber={chapterNumber}
              sectionNumber={sectionNumber}
              courseId={courseData?.course_uuid}
              cohort={selectedCohort}
            />
          })
        }
        return null
      })
      }
      <StudentGradeSection
        cohortId={cohortId}
        key='participation'
        section={{ type: 'participation',
          title: 'Participation' }}
        courseData={null}
        courseId={courseId}
        cohort={selectedCohort}
      />
    </div>
  )
}

export default StudentGradeTable

import styled from 'styled-components'
import Button from '../Button/Button'
import mediaQueries from '../../mediaQueries'

export const CustomButton = styled(Button)`
  font-size: 14px !important;
  line-height: 17px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px !important;
  font-weight: 700;
  height: 49px !important;
`

export const PrimaryButton = styled(CustomButton)`
  width: ${props => props.width || '113px'} !important;
  background: #5fc4b8;
  margin-right: 12px;

  &:hover {
    background: #b8fff7 !important;
  }

  &:disabled {
    opacity: 0.3;
  }

  &.btn-custom.btn-primary {
    font-size: 14px !important;
    line-height: 17px !important;
  }
`

export const SecondaryButton = styled(CustomButton)`
  border: 1px solid #5fc4b8;
  box-sizing: border-box;
  border-radius: 5px;
  width: 108px !important;

  &.btn-custom.btn-secondary {
    border: 1px solid #5FC4B8;
    color: #5FC4B8;
  }
`

export const TextInput = styled.input`
  display: block;
  width: ${props => props.width || '181px'};
  height: 56px;
  border-radius: 5px;
  background: var(--black-tertiary);
  border: 1px solid ${props => props.isValid ? 'var(--border-secondary)' : 'var(--error-color)'} ;
  padding: 16px;
  color: var(--text-primary);
  ${props => props.margin && `margin: ${props.margin};`}

  ::placeholder {
    color: ${props => props.disabled ? 'transparent' : 'var(--border-secondary)'};
  }

  :focus {
    border: 1px solid #ffffff;
    outline: 1px solid ${props => props.isValid ? 'var(--border-secondary)' : 'var(--error-color)'};
  }

  :hover {
    border: 1px solid var(--brand-regular);
  }

  &:disabled {
    border: none;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`

export const InputLabel = styled.label`
  color:#CCD4D8;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const SectionTitle = styled.div`
  color: #CCD4D8;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
`

export const EditGRadeWrapper = styled.div`
  padding: 36px;
  width: 514px;
  background: #1d1d1f;
`

export const QuestionStatus = styled.div`
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 400;
  line-height: normal;
& span {
  color: ${props => props.correct ? '#77B1AF' : '#E1774F'};
}
`

export const TextError = styled.div`
  color: #E1774F;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  margin-bottom: 8px;
`

export const AdjustmentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: start;
  margin-top: 32px;
  & > p {
      color: var(--sub-text-secondary);
      margin-bottom: 4px;
  }
`

export const AdjustmentsTitle = styled.div`
  font-size: 16px;
  line-height: 14px;
  font-weight: 700;
  color: var(--sub-text-secondary);
  margin-bottom: 12px;
  text-transform: uppercase;
    & > p {
      line-height: 14px;
      font-weight: 400;
      margin-bottom: 4px;
      color: var(--sub-text-secondary);
    }
`

export const AdjustmentsGrade = styled.span`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: var(--sub-text-secondary);
    & > span.last {
        color: var(--white);
    }
`

export const AdjustmentButtonsWrapper = styled.div`
  display: flex;
  ${mediaQueries.mobile`
    flex-direction: column;
    width: 100%;
  `}
  margin: 32px 0;
  & > button {
    width: fit-content;
    ${mediaQueries.mobile`
      width: 100%;
      margin-bottom: 12px;
      margin-left: 0;
    `}
    padding: 16px 24px;
  }
`

export const Dot = styled.span`
  display: inline-block;
  width: 2px;
  height: 2px;
  background-color: var(--text-dark-off);
  margin-left: 8px;
  margin-right: 8px;
`

import {
  CurrentDeadline,
  CustomDatePicker,
  DateContainer,
  DropDownStyles,
  InputLabel,
  OneAssessment,
  RowContainer
} from './style'
import Select from 'react-select'
import React, { useEffect } from 'react'
import { getFormattedShortDateAndTime } from './AddExtensionUtils'
import { DropdownIndicator } from '../../CustomSelect/CustomSelectComponents'

const SingleAssessment = ({
  assessmentsList,
  selectedAssessment,
  handleAssessmentChange,
  newDeadline,
  setNewDeadline,
  isEditMode
}) => {
  useEffect(() => {
    if (!selectedAssessment) {
      if (assessmentsList.length > 0) {
        const firstAssessment = assessmentsList[0]
        handleAssessmentChange(firstAssessment)
        return
      }
      handleAssessmentChange(null)
    }
    // eslint-disable-next-line
  }, [assessmentsList])

  const [lockDate, lockTime] = getFormattedShortDateAndTime(selectedAssessment?.lockTime) || []
  const lockDateTime = !isEditMode
    ? `${lockDate}   ${lockTime}`
    : lockDate
  return (
    <OneAssessment>
      <RowContainer>
        <InputLabel data-testid='assessment-label'>Assessment</InputLabel>
        <Select
          data-testid='assessments-selector'
          components={{ DropdownIndicator }}
          options={assessmentsList}
          value={selectedAssessment}
          placeholder='Select assessment'
          styles={{ ...DropDownStyles }}
          onChange={assessment => {
            handleAssessmentChange(assessment)
          }}
          isDisabled={false}
        />
      </RowContainer>
      {selectedAssessment &&
        <CurrentDeadline data-testid='assessment-deadline'>
          Current deadline: {lockDateTime}
        </CurrentDeadline>}
      <RowContainer>
        <InputLabel data-testid='new-deadline'>New deadline</InputLabel>
        <DateContainer>
          <CustomDatePicker
            data-testid='date-picker'
            selected={newDeadline}
            dateFormat='MM/dd/yy'
            onChange={(date) => {
              setNewDeadline(date)
            }}
          />
        </DateContainer>
      </RowContainer>
    </OneAssessment>
  )
}
SingleAssessment.displayName = 'SingleAssessment'
export default SingleAssessment

export const getTotalCourses = (tokens) => {
  let totalCourses = 0
  for (const key in tokens) {
    if (tokens[key] > 0) totalCourses++
  }
  return totalCourses
}

export const getTotalLicenses = (tokens) => {
  let totalLicenses = 0
  for (const key in tokens) {
    totalLicenses += tokens[key]
  }
  return totalLicenses
}

export const cleanupAndSortCourseLicenses = (courseLicenses) => {
  const nonGGUCourses =
    courseLicenses.filter(course => !course.courseName.includes('GGU'))
  const cleanupCourseNames = nonGGUCourses.map(course => {
    return {
      ...course,
      courseName: course.courseName.replace(' - EXT', '')
    }
  })
  const sortedCourses =
    cleanupCourseNames.sort((a, b) => a.courseName.localeCompare(b.courseName))
  return sortedCourses
}

const ENROLLMENT_OPTIONS_FILTERS = {
  'UPitt Intensive': (course) =>
    course.creditGrantingInstitution === 'University of Pittsburgh' &&
    course.duration === 7,
  'UPitt Standard': (course) =>
    course.creditGrantingInstitution === 'University of Pittsburgh' &&
    course.duration === 14,
  'UPitt Extended': (course) =>
    course.creditGrantingInstitution === 'University of Pittsburgh' &&
    course.duration === 39,
  'GGU Professional Certificates': () => false // we filter GGU courses in cleanupAndSortCourseLicenses
}

export const filterCourseLicenses = (courseLicenses, selectedFilter, enrollmentOptions) => {
  if (!courseLicenses?.length) return []

  return courseLicenses.filter((course) => {
    const matchesFilter = selectedFilter?.value
      ? course.duration === selectedFilter.value
      : true
    const matchesEnrollmentOptions = enrollmentOptions?.length
      ? enrollmentOptions.some((option) =>
          ENROLLMENT_OPTIONS_FILTERS[option]?.(course)
      )
      : true

    return matchesFilter && matchesEnrollmentOptions
  })
}

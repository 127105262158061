import { ELTFileHeaders } from './constants'
import { readCSVFile } from '../../utilities/file'

export function getDateTimeFormatted (dateString = '') {
  if (!dateString || String(new Date(dateString)) === 'Invalid Date') return

  const date = new Date(dateString)
  const timeZone = 'America/New_York'

  const resultDate = date.toLocaleDateString('en-US', { timeZone }) || ''
  const resultTime = date.toLocaleTimeString('en-US', { timeZone, timeStyle: 'short' }) || ''
  const timeString = resultTime.replace(' ', '').toLocaleLowerCase()

  return `${resultDate} ${timeString} ET`
}

export async function checkIfELTFile (file) {
  const fileData = await readCSVFile(file)
  const fileHeaders = fileData[0] || []

  const isELTFile = ELTFileHeaders.every(header => fileHeaders?.includes(header))

  return isELTFile
}

export async function checkFileHeaders (file, headers) {
  const fileData = await readCSVFile(file)
  const fileHeaders = fileData[0] || []

  const hasCorrectHeaders = headers.every((header) =>
    fileHeaders?.includes(header)
  )

  return hasCorrectHeaders
}

import React, { useMemo, useState } from 'react'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  defaultDropAnimationSideEffects,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'
import { FieldsContainer } from './styles'
import SortableItem from './SortableItem'
import api from '../../../api'

const dropAnimationConfig = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.7'
      }
    }
  })
}

function SortableOverlay ({ children }) {
  return (
    <DragOverlay
      style={{ boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' }}
      dropAnimation={dropAnimationConfig}
    >
      {children}
    </DragOverlay>
  )
}

function SortableList ({
  items,
  onChange,
  currentView,
  isSearching,
  hideUnhideColumn,
  currentTable
}) {
  const [active, setActive] = useState(null)
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  )
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const isActiveItemHidden = currentView?.hiddenFields?.some(
    (hiddenField) => hiddenField.toLowerCase() === activeItem?.id.toLowerCase()
  )

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active)
      }}
      onDragEnd={async ({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id)
          const overIndex = items.findIndex(({ id }) => id === over.id)

          const newColumnsOrder = arrayMove(items, activeIndex, overIndex)
          onChange(newColumnsOrder)

          if (currentView.label === 'Default') return

          const { id, label, value, ...rest } = currentView

          await api.updateTableView({
            tableName: currentTable,
            id,
            view: { ...rest, columnOrder: newColumnsOrder }
          })
        }
        setActive(null)
      }}
      onDragCancel={() => {
        setActive(null)
      }}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <FieldsContainer data-testid='fields-list'>
          {items.map((item) => {
            const isColumnHidden = currentView?.hiddenFields?.some(
              (hiddenField) =>
                hiddenField.toLowerCase() === item.id.toLowerCase()
            )
            return (
              <SortableItem
                key={item.id}
                id={item.id}
                isColumnHidden={isColumnHidden}
                isSearching={isSearching}
                hideUnhideColumn={hideUnhideColumn}
              >
                {item.id}
              </SortableItem>
            )
          })}
        </FieldsContainer>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? (
          <SortableItem id={activeItem.id} isColumnHidden={isActiveItemHidden}>
            {activeItem.id}
          </SortableItem>
        ) : null}
      </SortableOverlay>
    </DndContext>
  )
}

SortableList.displayName = 'SortableList'
export default SortableList

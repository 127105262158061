import React, { useEffect, useReducer, useMemo, useRef, useState } from 'react'
import './EditGrades.css'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import { useStudentGradeContext, useStudentGradeActions } from '../../contexts/StudentGradeContext'
import api from '../../api'
import {
  SET_CHANGES,
  SET_DISCARD_MODAL,
  SET_LOADING,
  SET_QUIZ_EXAM_DATA,
  SET_SHOW_MODAL
} from '../../Constants/actionTypes'
import EditGradesV2 from './EditGradesV2'

const initialState = {
  showModal: false,
  showDiscardModal: false,
  studentProgress: {},
  quizExamData: { Question: [] },
  editQuestion: {},
  changes: [],
  loading: true
}

function EditGrades ({ routerProps: { history, match, location } }) {
  const {
    gradeData,
    globalSectionData,
    studentProgress,
    courseData } = useStudentGradeContext()

  const [cohortId, setCohortId] = useState(null)
  const { fetchEditGradesRelevantData, fetchSectionData } = useStudentGradeActions()
  const allCohorts = useRef(null)
  const reducer = (state, action) => {
    switch (action.type) {
      case SET_QUIZ_EXAM_DATA: return {
        ...state,
        quizExamData: action.quizExamData
      }
      case SET_SHOW_MODAL: return {
        ...state,
        showModal: action.showModal
      }
      case SET_DISCARD_MODAL: return {
        ...state,
        showDiscardModal: action.showDiscardModal
      }
      case SET_CHANGES: return {
        ...state,
        changes: action.changes,
        showModal: false
      }
      case SET_LOADING: return { ...state, loading: action.loading }

      default: throw new Error('Unexpected action')
    }
  }
  const [{
    showDiscardModal,
    quizExamData,
    changes,
    loading }, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    fetchData()
    // eslint-disable-next-line
  }, [courseData, globalSectionData])

  const sortedQuestions = useMemo(() => {
    const lastExam = quizExamData?.examVersions?.length &&
      quizExamData.examVersions[quizExamData.examVersions.length - 1]
    const hasRetake = lastExam && studentProgress['exam-percentage'][lastExam.uuid]
    const { Question } = hasRetake ? lastExam : quizExamData || {}
    if (quizExamData?.type !== 'exam') return Question

    const { studentAnswers = {} } = studentProgress || {}

    return Question?.map(question => ({
      ...question,
      questionIndex: studentAnswers[question.Question_uuid]?.questionIndex,
      correct: studentAnswers[question.Question_uuid]?.correct
    })).sort((a, b) => a.questionIndex - b.questionIndex)
  }, [quizExamData, studentProgress])

  const fetchData = async () => {
    const { courseUuid } = match.params
    const { chapters } = courseData
    const query = new URLSearchParams(location.search)
    if (!chapters) {
      const {
        cohortName,
        studentEmail } = match.params
      if (!allCohorts.current) {
        allCohorts.current = await api.getAllCohorts()
      }
      const { current } = allCohorts
      const cohort = current && current.find(cohort =>
        cohort.name === decodeURIComponent(cohortName))
      setCohortId(cohort && cohort?.at_id)
      return fetchEditGradesRelevantData({
        courseId: courseUuid,
        cohortId: gradeData.pastCohortData
          ? cohort && cohort.at_id : null,
        studentEmail })
    }

    if (query.has('sectionUuid')) {
      const quizUuid = query.get('quizUuid')
      const sectionUuid = query.get('sectionUuid')
      if (!globalSectionData.length) {
        return fetchSectionData(courseUuid, sectionUuid)
      }
      const sectionData = globalSectionData.find(data => {
        return sectionUuid === data.section_uuid && data.course_uuid === courseUuid
      })

      const quiz = sectionData.section_exe.quiz.find(quiz => quiz.question_set_uuid === quizUuid)
      quiz.Question = quiz.Question.map(question => {
        question['correct'] = studentProgress.studentAnswers[question.Question_uuid]
          ? studentProgress.studentAnswers[question.Question_uuid].correct : Boolean(false)
        return question
      })
      const quizGrades = sectionData.section_exe.quiz.map(quiz => {
        if (Object.keys(studentProgress['quiz-section'])
          .includes(quiz.question_set_uuid) && quiz.question_set_uuid !== quizUuid) {
          return studentProgress['quiz-section'][quiz.question_set_uuid]
        } else { return 0 }
      })
      quiz.sectionGrade = Math.max(...quizGrades)
      quiz.sectionTitle = sectionData.title
      dispatch({ type: SET_QUIZ_EXAM_DATA, quizExamData: quiz })
    } else {
      const chapterUuid = query.get('chapterUuid')
      const exam = courseData.chapters.find(chapter => chapter.chapter_uuid === chapterUuid)
      exam.Question = exam.exams.Question.map(question => {
        question['correct'] = studentProgress.studentAnswers[question.Question_uuid]
          ? studentProgress.studentAnswers[question.Question_uuid].correct : Boolean(false)
        return question
      })
      dispatch({ type: SET_QUIZ_EXAM_DATA, quizExamData: exam })
    }
    dispatch({ type: SET_LOADING, loading: false })
  }

  const setChanges = (changedQuestion) => {
    if (changedQuestion?.length === 0) { return dispatch({ type: SET_CHANGES, changes: [] }) }
    const index = changes.findIndex(change => change.questionUuid === changedQuestion.questionUuid)
    if (index !== -1) {
      changes[index] = changedQuestion
      dispatch({ type: SET_CHANGES, changes })
    } else {
      dispatch({ type: SET_CHANGES, changes: [...changes, changedQuestion] })
    }
  }

  return (
    <div className='editable-container'>
      {loading ? <LoadingSpinner /> : <>
        <EditGradesV2
          studentProgress={studentProgress}
          quizExamData={quizExamData}
          sortedQuestions={sortedQuestions}
          changes={changes}
          setChanges={setChanges}
          dispatch={dispatch}
          history={history}
          match={match}
          location={location}
          cohortId={cohortId}
        />
        {showDiscardModal && <ConfirmationModal
          show={showDiscardModal}
          showModalHandler={() => dispatch({
            type: SET_DISCARD_MODAL,
            showDiscardModal: false
          })}
          confirm={{ text: 'yes', handler: () => history.goBack() }}
          cancel={{
            text: 'no',
            handler: () => dispatch({
              type: SET_DISCARD_MODAL,
              showDiscardModal: false
            }) }}
        >
            Are you sure that you want to discard the changes you made on this page?
        </ConfirmationModal>}
      </>}
    </div>
  )
}

export default EditGrades

import React from 'react'
import PropTypes from 'prop-types'
import RadioButtonSelector from '../../Components/RadioButtonSelector'

const options = [
  {
    name: 'Unlock',
    label: 'Unlock',
    value: false
  },
  {
    label: 'Lock',
    name: 'Lock',
    value: true
  }
]
const ExamLockUnlock = ({
  isSelectedRelationshipEmpty,
  locked,
  setLocked
}) => {
  return (
    <RadioButtonSelector
      headerText='Would you like to unlock or lock an exam?'
      exposeKey='locked'
      values={locked}
      setValue={setLocked}
      isDisabled={isSelectedRelationshipEmpty}
      options={options}
      noMargin
    />
  )
}

ExamLockUnlock.propTypes = {
  isSelectedRelationshipEmpty: PropTypes.bool.isRequired,
  locked: PropTypes.object.isRequired,
  setLocked: PropTypes.func.isRequired
}
export default ExamLockUnlock

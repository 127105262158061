import React, { useEffect, useState } from 'react'
import ModalComponent from '../ModalComponent/Modal'
import {
  ResetButton,
  highlightDropdownStyles,
  DropDownLabel,
  ActiveDateRangeFromLabel,
  GradeRangeContainer,
  GradeContainerToLabel,
  LetterGradeRangeContainer,
  GradeRangeStartSelect,
  GradeRangeEndSelect,
  NumericRangeStartInput,
  NumericRangeEndInput,
  ControlLabelWrapper
} from './styles'

function HighlightModal (props) {
  const {
    setIsHighlightModalShow,
    gradingScale,
    startRangeLetterGrade,
    handleHighlight,
    endRangeLetterGrade,
    endRangeNumericGrade,
    startRangeNumericGrade,
    setStartRangeLetterGrade,
    setEndRangeLetterGrade,
    setEndRangeNumericGrade,
    setStartRangeNumericGrade
  } = props
  const [isStateReset, setIsStateReset] = useState(false)

  useEffect(() => {
    if (!isStateReset) return

    setIsStateReset(false)
    handleHighlight()
    // eslint-disable-next-line
  }, [isStateReset])

  useEffect(() => {
    handleHighlight()
    // eslint-disable-next-line
  }, [
    startRangeLetterGrade,
    endRangeLetterGrade,
    startRangeNumericGrade,
    endRangeNumericGrade
  ])

  const handleClose = () => {
    setIsHighlightModalShow(false)
  }

  const handleReset = () => {
    setStartRangeLetterGrade('')
    setEndRangeLetterGrade('')
    setIsStateReset(true)
  }

  const handleClear = () => {
    setStartRangeNumericGrade('')
    setEndRangeNumericGrade('')
    setIsStateReset(true)
  }

  return (
    <ModalComponent
      handleClose={handleClose}
      title='Highlight Grades'
      marginLeft={185}
    >
      <GradeRangeContainer>
        <LetterGradeRangeContainer>
          <DropDownLabel>
            Letter Grade Range
          </DropDownLabel>
          <ResetButton onClick={handleReset}>
            RESET
          </ResetButton>
          <ControlLabelWrapper>
            <GradeRangeStartSelect
              options={gradingScale}
              styles={highlightDropdownStyles}
              value={startRangeLetterGrade}
              isDisabled={startRangeNumericGrade}
              placeholder='Select grade'
              width='200px'
              onChange={(startGrade) => setStartRangeLetterGrade(startGrade)}
            />
            <GradeContainerToLabel>
            to
            </GradeContainerToLabel>
          </ControlLabelWrapper>
          <GradeRangeEndSelect
            styles={highlightDropdownStyles}
            options={gradingScale}
            value={endRangeLetterGrade}
            placeholder='Select grade'
            isDisabled={!startRangeLetterGrade}
            width='200px'
            onChange={(endGrade) => setEndRangeLetterGrade(endGrade)}
          />
        </LetterGradeRangeContainer>
        <div>
          <ActiveDateRangeFromLabel>
            Numeric Grade Range
          </ActiveDateRangeFromLabel>
          <ResetButton onClick={handleClear} >
            CLEAR
          </ResetButton>
          <ControlLabelWrapper>
            <NumericRangeStartInput
              type='number'
              placeholder='Enter numeric value'
              value={startRangeNumericGrade}
              onChange={e => setStartRangeNumericGrade(e.target.value)}
              disabled={startRangeLetterGrade}
            />
            <GradeContainerToLabel>
            to
            </GradeContainerToLabel>
          </ControlLabelWrapper>
          <NumericRangeEndInput
            type='number'
            placeholder='Enter numeric value'
            value={endRangeNumericGrade}
            onChange={e => setEndRangeNumericGrade(e.target.value)}
            disabled={!startRangeNumericGrade}
          />
        </div>
      </GradeRangeContainer>
    </ModalComponent>
  )
}

export default HighlightModal

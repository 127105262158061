import React from 'react'
import { useParams } from 'react-router-dom'
import StudentSubmission from './StudentSubmission'

const StudentSubmissionWrapper = (props) => {
  const { studentEmail, assignmentUUID } = useParams()
  const key = studentEmail + assignmentUUID

  return <StudentSubmission key={key} {...props} />
}

StudentSubmissionWrapper.displayName = 'StudentSubmissionWrapper'
export default StudentSubmissionWrapper

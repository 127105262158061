import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import calendarSvg from '../../assets/icons/calendar.svg'

export const StyledWrapper = styled.div`
  margin-top: ${props => props.isExamRetakes ? '24px' : '39px'};
`

export const LabelContainer = styled.div`
  display: flex;
  margin-bottom: 6px;
  line-height: 22px;
  font-size: 18px;
  font-weight: normal;
  text-transform: capitalize;

  span {
    font-weight: bold;  
    font-size: 14px;
    line-height: 17px;
    align-items: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #5E636B;
    position: relative;
    top: 4px;
  }
`

export const SubHeader = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 300;
  line-height: ${props => props.isExamRetakes ? '24px' : '19px'};
  margin-bottom: 12px;
`

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;

  .react-datepicker__input-container::after {
    content: url(${calendarSvg});
    position: relative;
    right: 25px;
  }

  span {
    position: relative;
    right: 7px;
  }
`

export const CustomDatePicker = styled(DatePicker)`
  width: 166px;
  height: 38px;
  background: #FFFFFF;
  border: none;
  padding: 10px;
  border-radius: 3px;
  font-size: 16px;
  line-height: 19px;
  text-indent: 10px;
  color: #6e757c;
  position: relative;

  &::placeholder,
  &::-webkit-input-placeholder {
    color: #6e757c;
  }
`

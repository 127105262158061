import React, { useState, useEffect, useRef } from 'react'
import {
  useTable, useGlobalFilter, useSortBy
} from 'react-table'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isMatchCaseInsensitive } from '../../utilities'
import {
  TableContainer,
  TableHeader,
  HeaderColumn,
  TableBody,
  BodyCell,
  Tooltip,
  Link
} from './styled'
import Toast from '../ToastComponent/Toast'
import { FailureIcon } from '../AdminStudentDrop/styled'
import { isCohortCompleted } from '../../utilities/courseUtils'
import { get, chain } from 'lodash'
import TooltipIcon from '../../assets/icons/icon-tooltip.svg'

export default function GradeTable ({
  startingGradeRange,
  searchedEmails,
  endingGradeRange,
  columns,
  data,
  onUserChange,
  currentCohort,
  chapters
}) {
  const [records, setRecords] = useState(data)
  const [columnsFullEmpty, setColumnsFullEmpty] = useState([])
  const toast = useRef()

  useEffect(() => {
    const isCohortEnded = isCohortCompleted(currentCohort, new Date())
    if (!isCohortEnded) return
    showToaster()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleSearch(searchedEmails)
    // eslint-disable-next-line
  }, [data, searchedEmails])

  // Show a toaster when at least one column is fully empty
  const showToaster = () => {
    // exclude non grade keys
    const keysToExclude = [
      'email', 'Attempt ID', 'Last Active', 'graph', 'id', 'name', 'notes',
      'Student Status', 'Preferred Name', 'Relationship'
    ]
    const fullEmptyColumns = getFullEmptyColumns() || []
    const filteredColumns = fullEmptyColumns
      .filter(column => !keysToExclude.includes(column))

    if (!filteredColumns.length) return

    setColumnsFullEmpty(filteredColumns)
    const { current: { display } = {} } = toast

    return display(
      <><FailureIcon /> Missing Grades</>,
      'At least one column is missing grades. Please notify the Product team.'
    )
  }

  const getQuizzesSection = () => {
    return chain(chapters)
      .map(chapter => chapter.sections).flatten().compact()
      .filter(section => !!get(section, 'quizUUIDs', []).length)
      .map(section => get(section, 'title', ''))
      .value()
  }

  const getFullEmptyColumns = () => {
    if (!records) return
    const emptyColumns = []
    const quizzesSection = getQuizzesSection() || []
    Object.entries(records[0]).forEach(entry => {
      const [sectionName] = entry
      if (!quizzesSection.includes(sectionName)) return
      // check if a full column is N/A or ''
      const hasEmptyColumn = records.every(record => (
        record[sectionName] === 'N/A' || record[sectionName] === ''
      ))
      if (!hasEmptyColumn) return
      emptyColumns.push(sectionName)
    })
    return emptyColumns
  }

  const handleSearch = e => {
    e ? searchData(e, data) : setRecords(data)
  }

  const searchData = (val, records) => {
    const filteredRecord = records.filter(
      ({ name = '', email = '', id = '', emailAddress = '' }) => {
        if (!val.length) return true
        return val.some((value) => {
          return (
            isMatchCaseInsensitive(emailAddress, value) ||
            isMatchCaseInsensitive(name, value) ||
            id.includes(value) ||
            isMatchCaseInsensitive(email, value)
          )
        })
      }
    )
    setRecords(filteredRecord)
  }

  const rowOnClickHandler = (row) => {
    const {
      values: { email, name, emailAddress },
      idValue
    } = row
    onUserChange({ emailAddress, email, name, idValue })
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows
  } = useTable(
    {
      columns,
      data: records
    },
    useGlobalFilter,
    useSortBy
  )

  const tableHeader = () => {
    return (
      <TableHeader>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} className='headerRow'>
            {headerGroup.headers.map((column, index) => {
              const isGraphCell = index === 0
              const isCurrentGrade = column.Header === 'Current Grade'
              if (isGraphCell) return <th key={index} />

              return (
                ['email', 'name', 'Last Active'].includes(column.Header)) ? (
                  <HeaderColumn
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{ left: index === 2 && (records.length ? 345 : 292),
                      paddingLeft: 5,
                      paddingRight: records.length ? 100 : 195,
                      whiteSpace: 'nowrap' }}
                  >
                    {column.render('Header').toUpperCase()}
                  </HeaderColumn>)
                : <HeaderColumn
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{
                    left: index === 2 && (records.length ? 345 : 292),
                    backgroundColor: columnsFullEmpty.includes(column.render('Header')) ? '#3a3838' : '#161618',
                    ...(isCurrentGrade && { zIndex: 2 })
                  }}
                >
                  {column.render('Header').toUpperCase()}
                  {isCurrentGrade && (
                    <Tooltip>
                      <img
                        src={TooltipIcon}
                        className='ml-2 mb-1'
                        width='16'
                        height='16'
                        alt='tool-tip'
                      />
                      <span>
                        <p>
                          This grade is calculated based on graded activities
                          that the student has completed and/or should have
                          completed by this point in their cohort.
                        </p>
                        <p>
                          For more about this calculation, see the {' '}
                          <Link
                            href='https://help.outlier.org/hc/en-us/articles/360046689752'
                            target='_blank' rel='noopener noreferrer'
                          >
                            Course Credits & Grades Help Center
                          </Link>
                          {' '}article.
                        </p>
                      </span>
                    </Tooltip>
                  )}
                </HeaderColumn>
            })}
          </tr>
        ))}
      </TableHeader>
    )
  }

  const tableBody = () => {
    return (
      <TableBody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr
              key={i}
              className='tableBodyRow'
              {...row.getRowProps()}
            >
              {row.cells.map((cell, j) => {
                const { getCellProps, value, column: { Header } } = cell
                const isGraphCell = j === 0
                const isPersonalInfo = [
                  'email', 'name', 'id', 'preferredName'
                ].includes(Header)

                return (
                  <BodyCell key={j} {...getCellProps()}
                    className={classNames(
                      { 'fs-exclude': isPersonalInfo }
                    )}
                    {...((isGraphCell) && {
                      onClick: () => rowOnClickHandler(row)
                    })}
                    style={{ backgroundColor: highlightGrades(cell) }}
                  >
                    {isGraphCell && <img alt='Id Card'
                      src={require('../../assets/icons/id-card.png')} />
                    }
                    {!isGraphCell && value}
                  </BodyCell>
                )
              })}
            </tr>
          )
        })}

        {!records.length && (<tr className='tableBodyRow'>
          <BodyCell />
          <BodyCell colSpan={columns.length}>
              No results to be shown.
          </BodyCell>
        </tr>)}
      </TableBody>
    )
  }

  const highlightGrades = cell => {
    const { value, column: { Header } } = cell
    return (Header !== 'email' && Header !== 'name' &&
        !!value && !isNaN(value) && (value <= startingGradeRange &&
        value >= endingGradeRange)) ? 'rgba(47, 128, 237, 0.3)' : 'black'
  }

  return (
    <>
      <Toast toast={toast} />
      <TableContainer
        {...getTableProps()}
        className='table'>
        {tableHeader()}
        {tableBody()}
      </TableContainer>
    </>
  )
}

GradeTable.propTypes = {
  onUserChange: PropTypes.func
}

import React, { useEffect, useState } from 'react'
import CohortsTable from './CohortsTable'
import Button from '../Button/Button'
import api from '../../api'
import { hasCreateBulkCohortFlag } from '../../config'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { useCoursesContext, useCoursesActions } from '../../contexts/Courses'
import { getCourseNameById } from '../../utilities/courseUtils'
import { sortByDateDescOrder } from '../../utilities/cohortUtils'
import { Container, TableGrid, Title, TitleWrapper } from './styled'

export default function Cohorts ({ routerProps: { history } }) {
  const [allCohorts, setAllCohorts] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [courses, setCourses] = useState([])
  const [canCreate, setCanCreate] = useState(false)

  const { fetchCourses } = useCoursesActions()
  const { allCourses } = useCoursesContext()

  useEffect(() => {
    if (!allCourses.length) {
      fetchCourses()
      return
    }
    setCourses(
      allCourses.map(course => ({ at_id: course.at_id, label: course.name }))
    )
    // eslint-disable-next-line
  }, [allCourses])

  useEffect(() => {
    fetchCohorts()
    checkPermissions()
    // eslint-disable-next-line
  }, [])

  const checkPermissions = async () => {
    const CREATE_PERMISSION = 'cohorts.view'
    try {
      const { permissions } = await api.getCurrentUserPermissions()
      const containCreatePermission = permissions.some(permission => permission === CREATE_PERMISSION)
      setCanCreate(containCreatePermission)
    } catch (err) {
      console.error(err)
    }
  }

  const fetchCohorts = async () => {
    const getAllCohorts = await api.getAllCohorts({ disabled: true })
    if (!getAllCohorts.length) return
    sortByDateDescOrder(getAllCohorts)
    setAllCohorts(getAllCohorts)
    setIsLoading(false)
  }

  const showCohortDetail = (cohortId) => {
    history.push({
      pathname: `/cohorts/${cohortId}`
    })
  }

  const createCohortLink = hasCreateBulkCohortFlag
    ? '/cohorts/bulk-create'
    : '/cohorts/create'

  return (
    <Container>
      <TitleWrapper>
        <Title>Cohorts</Title>
        {canCreate && (
          <Button
            className='btn-custom btn-primary'
            onClick={() => history.push(createCohortLink)}
          >Create Cohort
          </Button>)}
      </TitleWrapper>
      <TableGrid>
        <span className='cohort-text'>Cohort Name</span>
        <span className='cohort-text'>Course</span>
        <span className='cohort-text'>Start Date</span>
        <span className='cohort-text'>Duration</span>
        <span className='cohort-text'>Status</span>
        <span />
      </TableGrid>
      <>
        { !isLoading ? allCohorts.map((cohort) => {
          return (
            <div key={cohort.at_id}>
              <CohortsTable
                cohortId={cohort.at_id}
                showCohortDetail={showCohortDetail}
                cohortName={cohort.name}
                courseName={getCourseNameById(courses, cohort.course && cohort.course[0])}
                startDate={cohort.dateStart}
                duration={cohort.duration ? cohort.duration : 'N/A'}
                disable={cohort.disabled}
              />
            </div>
          )
        }) : <LoadingSpinner />}
      </>
    </Container>
  )
}

import styled from 'styled-components'

export const CustomCheckBox = styled.input`
  // hide default checkbox
  position: relative;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  height: 16px;
  width: 16px;

  // create custom checkbox
  ::after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 3px;
    border: 1px solid #5FC4B8;
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 0;
  }
  // when checked, show checkmark
  :checked::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    top: 6px;
    left: 2px;
    width: 12px;
    height: 8px;
    border-left: 3px solid black;
    border-bottom: 3px solid black;
    transform: rotate(-45deg);
  }

  // when checked, change background color
  :checked::after {
    background-color: #5FC4B8;
  }
`
export const Label = styled.label`
  display: flex;
  align-items: flex-start;

  ${props => props.disabled && `
    opacity: 0.5;
  `}

  input[type='checkbox'] {
    display: inline-block;
  }

  span {
    display: inline-block;
    margin-left: 8px;
    font-size: 16px;
  }
`

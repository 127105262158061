import styled, { css } from 'styled-components'
import { Separator, PageHeading } from '../../WritingGradeCenter/styled'

import { DropdownItem, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

const ScrollBar = css`
&::-webkit-scrollbar {
  width: 3px;
 }
 
 &::-webkit-scrollbar-track {
   margin: 3px;
 }
 
 &::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  opacity: 0.3;
  border-radius: 5px;
 }
`

export const Heading = styled.h1`
font-family: Lato;
font-size: 24px;
font-style: normal;
font-weight: 300;
line-height: 29px;
letter-spacing: 0em;
text-align: left;
display:inline-block;
margin-right: 12px;
margin-bottom: 0px;
text-transform: capitalize;
`

export const SeparatorExtended = styled(Separator)`
 margin-top: 49px;
 margin-bottom: 48px;
`

export const Link = styled.a`
font-family: Lato;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 17px;
letter-spacing: 1px;
text-align: right;
color: var(--brand-regular);
display: inline-block;
text-transform: uppercase;

:hover{
  color: var(--brand-regular);
  text-decoration: none;  
}
`

export const UsersListItem = styled.div`
border-top: 1px solid var(--border-primary);
border-bottom: 1px solid var(--border-primary);
padding: 24px 0 25px 0;

> div {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: left;
    width: 240px;
    margin-right: 12px;
    display: inline-block;
    > span {
      display: inline-block;
      width: 21ch;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  > a {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 1px;
  }
}
`
export const ModalHeader = styled.div`
width: 500px;
height: 70px;
background: var(--gray-primary);
padding: 24px;
position: relative;
 > h1 {
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
 }
`

export const ModalContent = styled.div`
${ScrollBar}

padding: 0 24px 24px 24px;
background: var(--canvas-secondary);
max-width: 500px;
min-height: 430px;
font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
overflow-y: scroll;
position: relative;

  > h2 {
    color: var(--text-secondary);
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 1px;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 0px;
  }

 > p {
     margin: 6px 0 0 0;
  }
`

export const ModalCloseIcon = styled.img`
 float: right;
 position: absolute;
 right: 29px;
 top: 29px;
 cursor: pointer;
`
export const PageHeadingText = styled(PageHeading)`
 display: inline;
`

export const HeadingContainer = styled.div`
  > button {
    float: right;
  } 
`

export const InputContainer = styled.div`
 margin-top: 12px;
 font-size: 16px;
 font-weight: 400;
 line-height: 19px;
 letter-spacing: 0px;
 text-align: left;
 position: relative;

 > * { 
   display: block;
   width: 300px;
 }

 > label {
  color: var(--text-secondary);
 }

 > span { 
  display: flex;
  align-items: flex-start;
  ${({ withoutLabel }) => withoutLabel
    ? `margin-top: 19px;
      margin-bottom: 21px;`
    : ''}
  .checkbox-label {
    display: flex;
    flex-direction: column;
    justify-items: center;

    small {
      font-size: 14px;
      line-height: 17px;
      margin-top: 4px;
    }
  }
 }
`
export const Input = styled.input`
  margin-top: 6px;
  height: 40px;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  color: var(--text-primary);
  padding: 9px 12px 12px 12px;
  &:focus {
   outline: none;
  }

  &[type='checkbox']{
    appearance: none;
    margin: 0px 12px 0px 0px;
    padding: 0;
    width:20px;
    height: 20px;
    background: transparent;
    border-radius: 3px;
    border: 1px solid var(--brand-regular);
    position: relative;

    &:checked { 
      background: var(--brand-regular);

      &:after {
        content: url("data:image/svg+xml,%3Csvg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.1212 0.319527C10.327 0.115583 10.6047 0.000803464 10.8944 4.20219e-06C11.1841 -0.00079506 11.4624 0.11245 11.6693 0.315256C11.8762 0.518062 11.9949 0.794134 11.9998 1.08379C12.0048 1.37344 11.8955 1.65339 11.6957 1.86313L5.82705 9.19893C5.72619 9.30757 5.60445 9.39475 5.46913 9.45527C5.3338 9.51579 5.18766 9.54839 5.03944 9.55114C4.89123 9.55388 4.74398 9.52671 4.60651 9.47124C4.46903 9.41578 4.34416 9.33316 4.23934 9.22834L0.350925 5.33845C0.242598 5.23751 0.155712 5.11579 0.0954499 4.98054C0.0351876 4.84529 0.00278364 4.69929 0.00017159 4.55124C-0.00244046 4.4032 0.024793 4.25615 0.0802466 4.11886C0.1357 3.98157 0.218238 3.85685 0.322937 3.75216C0.427636 3.64746 0.55235 3.56492 0.68964 3.50946C0.82693 3.45401 0.973983 3.42678 1.12203 3.42939C1.27007 3.432 1.41607 3.46441 1.55132 3.52467C1.68657 3.58493 1.80829 3.67182 1.90923 3.78014L4.98762 6.85706L10.0933 0.35187C10.1024 0.340482 10.1122 0.329679 10.1227 0.319527H10.1212Z' fill='%23161618'/%3E%3C/svg%3E%0A");
        position: absolute;
        top: -2px;
        left: 3px;
      }
    }
  }
${props => props.error && `border: 1px solid var(--error-color);`}
`
export const ErrorMessage = styled.span`
  color: var(--error-color);
  display: block;
  margin: 12px 0px;
`

export const DropdownExtended = styled(Dropdown)`
 > button {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  line-height: 19px;
  margin-bottom: 12px;
  position: relative;
  text-transform: capitalize;
  
 ${props => props.isOpen && `border-color: var(--brand-regular);`}
 ${props => props.disabled ? `
   border: none;
   background: rgba(0, 0, 0, 0);

   &:hover {
    color: white;
    background: rgba(0, 0, 0, 0);
    outline: none;
    border: none;
  }

   &:active {
    background-color: rgba(0, 0, 0, 0) !important;
    border: none !important;
    box-shadow: none !important;
  }
 ` : `
    &:hover {
    color: white;
    background: rgba(0, 0, 0, 0.3);
    outline: none;
    border-color: var(--brand-regular);
  }

   &:active {
    background-color: rgba(0, 0, 0, 0.3) !important;
    border-color: var(--brand-regular) !important;
    box-shadow: none !important;
  }
 `}

  &:focus {
    outline: none;
    border-color: var(--brand-regular);
    box-shadow: none;
  }
 }
`
export const DropdownToggleExtended = styled(DropdownToggle)`
 position: relative; 
 display: flex;
 align-items: center;
 justify-content: space-between;
`

export const DropdownItemExtended = styled(DropdownItem)`
 background: transparent;
 margin: 6px 12px;
 width: 264px;
 padding: 10px 6px;
 text-transform: capitalize;

 &:hover {
   background: rgba(255, 255, 255, 0.05);
   border-radius: 5px;
 }
`
export const DropdownMenuExtended = styled(DropdownMenu)`
${ScrollBar}
  width: 100%;
  max-height: 145px;
  background-color: var(--gray-tertiary) !important;
  border: 2px solid var(--border-primary);
  border-radius: 5px;
  overflow-y: scroll;
  margin-top: 10px;
`
export const ModalFooterContent = styled.div`
 width: 100%;
 border-top: 1px solid var(--border-primary);
 position: absolute;
 bottom: 0;
 left: 0;
 display: flex;

 > p { 
   margin: 25px 24px;
   color: var(--text-secondary);
   width: 308px;
 }

 > button {
   margin: 24px;
   float: right;
   width: 120px;
   height: 40px;
   position: relative;
 }
`
export const ConfirmationModalContent = styled.div`
  ${ScrollBar}
  margin: 24px 1px;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left; 
  overflow-y: scroll;
  max-height: 313px;

  > div > span {
    display: block;
    margin: 0px 0px 16px 0px;
  }

  div  p {
    margin: 0px 0px 25px 0px;
  }

  > p {
    margin: 0px 0px 30px 0px;
  }

  > p:first-child {
    color: var(--text-secondary);
  }

  label {
    color: var(--text-secondary);
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  }
`
export const BackLink = styled.a`
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1px;
  text-align: left;
  color: var(--brand-regular)!important;
  display: flex;
  align-items: center;
  cursor: pointer;

  > img {
    width: 6px;
    height: 12px;
  }

  > span {
    margin-left: 12px;
  }

  &:hover {
    text-decoration: none;
    color: var(--brand-regular-hover)!important;
  }
`

export const ContentCreatorDescription = styled.p`
  margin-top: ${({ marginTop }) => marginTop ? '-17px !important' : 0};
`

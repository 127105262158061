import { useState, useEffect } from 'react'
import api from '../../api'
import { isValidUrl } from '../../utilities/validationUtils'

const videoCache = new Map()

const useFetchVideo = (videoUrl) => {
  const [url, setUrl] = useState(null)
  const [isUrlLoading, setIsUrlLoading] = useState(false)
  const [isFileNotFound, setIsFileNotFound] = useState(false)

  useEffect(() => {
    if (!videoUrl) {
      setUrl(null)
      setIsUrlLoading(false)
      setIsFileNotFound(false)
      return
    }

    // Check if the videoUrl is already in the cache
    if (videoCache.has(videoUrl)) {
      const cachedData = videoCache.get(videoUrl)
      setUrl(cachedData.url)
      setIsUrlLoading(false)
      setIsFileNotFound(cachedData.isFileNotFound)
      return
    }

    const fetchVideo = async () => {
      setIsUrlLoading(true)
      setIsFileNotFound(false)
      try {
        const token = await api.getToken()
        const options = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
        const response = await fetch(videoUrl, options)
        const contentType = response.headers?.get('content-type')
        // handle case for gcs videos
        if (contentType?.includes('application/json')) {
          const { url } = await response.json()
          if (isValidUrl(url)) {
            setUrl(url)
            videoCache.set(videoUrl, { isFileNotFound: false, url })
            return
          }
          setIsFileNotFound(true)
          videoCache.set(videoUrl, { isFileNotFound: true, url: null })
          throw new Error(`Something went wrong while fetching the video!`)
        }

        if (!response.ok) {
          if (response.status === 404) {
            setIsFileNotFound(true)
            videoCache.set(videoUrl, { isFileNotFound: true, url: null })
          }
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const blob = await response.blob()
        const videoObjectUrl = URL.createObjectURL(blob)
        setUrl(videoObjectUrl)
        videoCache.set(videoUrl, { isFileNotFound: false, url: videoObjectUrl })
      } catch (e) {
        console.error(e)
      } finally {
        setIsUrlLoading(false)
      }
    }

    fetchVideo()
  }, [videoUrl])

  return { url, isUrlLoading, isFileNotFound }
}

export default useFetchVideo

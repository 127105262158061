import React, { useEffect, useState } from 'react'
import {
  AttemptRowWrapper,
  ChevronIcon,
  HeaderCell,
  HeaderRow,
  NoRecords,
  ShowAllButton,
  TableHeaderWrapper,
  TokenHeaderCell,
  TokenRecordsWrapper,
  TokensHeader,
  TokensTableWrapper
} from './style'
import StudentTokenRowDetails from './StudentTokenRowDetails'
import chevronDownIcon from '../../../assets/icons/green-chevron-down.svg'

const StudentTokenRecords = ({ tokens, tokensScrollRef }) => {
  const [sortedTokens, setSortedTokens] = useState([])
  const [visibleTokens, setVisibleTokens] = useState([])
  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    const sortedTokens = tokens.sort(
      (a, b) => new Date(b.tokenIssueDate) - new Date(a.tokenIssueDate)
    )
    setSortedTokens(sortedTokens)
    setVisibleTokens(sortedTokens.slice(0, 4))
  }, [tokens])

  const toggleShowAll = () => {
    setVisibleTokens(isExpanded ? sortedTokens.slice(0, 4) : sortedTokens)
    setIsExpanded(!isExpanded)
  }

  return (
    <TokenRecordsWrapper ref={tokensScrollRef}>
      <TokensHeader>Tokens & purchases</TokensHeader>
      <TokensTableWrapper>
        <TableHeaderWrapper>
          <HeaderRow>
            <TokenHeaderCell>token</TokenHeaderCell>
            <TokenHeaderCell>status</TokenHeaderCell>
            <TokenHeaderCell>order</TokenHeaderCell>
            <TokenHeaderCell>relationship</TokenHeaderCell>
            <TokenHeaderCell>certificate</TokenHeaderCell>
            <TokenHeaderCell>refundable until</TokenHeaderCell>
            <HeaderCell />
          </HeaderRow>
        </TableHeaderWrapper>
        { !tokens?.length &&
          <NoRecords>
            No purchase records.
          </NoRecords>
        }
        { tokens?.length > 0 &&
        <AttemptRowWrapper data-testid='extensionsData'>
          {visibleTokens.map((token, index) => {
            return (
              <StudentTokenRowDetails token={token} key={index} />
            )
          })}
        </AttemptRowWrapper>}
      </TokensTableWrapper>
      {tokens?.length >= 4 &&
      <ShowAllButton onClick={() => {
        toggleShowAll()
      }}
      >
        <ChevronIcon
          src={chevronDownIcon}
          alt='chevron-down-icon'
          isExpanded={isExpanded}
        />
        show {isExpanded ? 'less' : 'all'}
      </ShowAllButton>}
    </TokenRecordsWrapper>
  )
}
StudentTokenRecords.displayName = 'StudentTokenRecords'
export default StudentTokenRecords

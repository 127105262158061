import React, { Component } from 'react'
import MultipleValueTextInputItem from './MultipleValueTextInputItem'
import { EmailContainer, EmailInput } from './styles'

const defaultProps = {
  charCodes: [13, 44],
  deleteButton: (<span>&times;</span>),
  values: [],
  shouldAddOnBlur: false,
  className: ''
}

class MultipleValueTextInput extends Component {
  constructor (props) {
    super(props)
    this.state = {
      values: props.values,
      value: ''
    }
    this.handleKeypress = this.handleKeypress.bind(this)
    this.handleValueChange = this.handleValueChange.bind(this)
    this.handleItemAdd = this.handleItemAdd.bind(this)
    this.handleItemRemove = this.handleItemRemove.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.values !== this.props.values) {
      this.setState({ values: this.props.values })
    }
  }

  handleKeypress (e) {
    const { onItemAdded, charCodes } = this.props
    // 13: Enter, 44: Comma
    if (charCodes.includes(e.charCode)) {
      e.preventDefault()
      this.handleItemAdd(e.target.value, onItemAdded)
    }
  }

  handleValueChange (e) {
    this.setState({ value: e.target.value })
  }

  handleItemAdd (value, onItemAdded) {
    if (this.state.values.includes(value) || !value) {
      this.setState({ value: '' })
      return
    }
    const newValues = this.state.values.concat(value)
    this.setState({
      values: newValues,
      value: ''
    })
    onItemAdded(value, newValues)
  }

  handleItemRemove (value) {
    const currentValues = this.state.values
    const newValues = currentValues.filter(v => v !== value)
    this.props.onItemDeleted(value, newValues)
    this.setState({ values: newValues })
  }

  handleBlur (e) {
    const { onItemAdded, shouldAddOnBlur } = this.props
    // 13: Enter, 44: Comma
    if (shouldAddOnBlur) {
      e.preventDefault()
      this.handleItemAdd(e.target.value, onItemAdded)
    }
  }

  render () {
    const {
      name,
      deleteButton,
      onItemAdded,
      onItemDeleted,
      className,
      charCodes,
      values: InputValues,
      shouldAddOnBlur,
      ...forwardedProps
    } = this.props
    const values = this.state.values && this.state.values.length
      ? this.state.values
      : this.props.values
    const valueDisplays = values.map(v => (
      <MultipleValueTextInputItem
        value={v}
        key={v}
        deleteButton={deleteButton}
        handleItemRemove={this.handleItemRemove}
      />))
    return (
      <EmailContainer>
        {values.length > 0 && valueDisplays}
        <EmailInput
          name={name}
          value={this.state.value}
          type='text'
          onKeyPress={this.handleKeypress}
          onChange={this.handleValueChange}
          onBlur={this.handleBlur}
          className={className}
          {...forwardedProps}
        />
      </EmailContainer>
    )
  }
}

MultipleValueTextInput.defaultProps = defaultProps
export default MultipleValueTextInput

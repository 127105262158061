import styled from 'styled-components'

export const ModalContainer = styled.div`
  background: var(--canvas-secondary);
  width: ${props => props.width ? `${props.width}px` : '325px'};
  position: absolute;
  margin-top: 15px;
  margin-left: ${props => props.marginLeft && `${props.marginLeft}px`};
  box-shadow: 0 5px 10px var(--shadow-primary);
  border-radius: 5px;
  z-index: 1100;
`
export const ModalBody = styled.div`
  margin: 24px 0px 36px 0px;
  padding: 0px 0px 0px 24px;
`
export const ModalHeader = styled.div`
  border-bottom: 0px;
  border-radius: 5px 5px 0px 0px;
  height: 70px;
  background: var(--gray-primary);
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  span {
    font-size: 18px;
    line-height: 21.6px;
  }
`
export const CloseIcon = styled.img`
  height: 12px;
  width: 12px;
  cursor: pointer;
`

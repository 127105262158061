import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const TooltipContainer = styled.div`
  display: inline-block;
  position: relative;
`

export const Icon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
`

export const TooltipBox = styled.div`
  position: absolute;
  background: #25272B;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.50);
  color: #fff;
  padding: 12px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-transform: none;
  white-space: normal;
  width: 231px;
  z-index: 1000;
  ${({ position }) => position === 'top' && `
    bottom: 100%;
    ${mediaqueries.tablet`
      left: 50%;
    `}
    ${mediaqueries.mobile`
      left: -50%;
    `}
    transform: translateX(-50%);
    margin-bottom: 16px;
  `}
  ${({ position }) => position === 'right' && `
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    margin-left: 16px;
  `}
  ${({ position }) => position === 'bottom' && `
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 16px;
  `}
  ${({ position }) => position === 'left' && `
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    margin-right: 16px;
  `}
`

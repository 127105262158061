import styled, { css } from 'styled-components'
import { ReactComponent as SearchButtonSVG } from '../../../assets/icons/search-icon.svg'
import { buttonEventsBehavior } from '../../../styles/buttons'
import mediaqueries from '../../../mediaQueries'
import { ContentTitle } from '../../../styles/text'
import { ReactComponent as ClearIconSVG } from '../../../assets/icons/clear-icon.svg'
import { ReactComponent as magnifyIcon } from '../../../assets/icons/icon-magnify.svg'

export const PageWrapper = styled.div`
  margin: 48px 24px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FiltersSection = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`

export const FiltersHeader = styled.div`
  display: flex;
  gap: 12px;
  align-items: baseline;
`

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 16px;
`

export const SearchButtonIcon = styled(SearchButtonSVG)`
  width: 48px;
  height: 48px;

  cursor: pointer;
  ${buttonEventsBehavior}
`

// TABLE

export const TablesSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const TableNamesContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`

export const TableName = styled(ContentTitle)`
  color: var(--sub-text-secondary);
  cursor: pointer;

  ${props => props.isActive && css`
    color: var(--text-primary);
    padding-bottom: 6px;
    border-bottom: 1px solid #FFF;
  `}

  &:hover {
    color: var(--text-primary);
  }
`

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  ${mediaqueries.tablet`
    width: fit-content;
  `}
`

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 15fr 22fr 22fr 9.8fr 16.9fr 11.9fr 2.4fr;
  grid-gap: 24px;
  overflow-wrap: anywhere;

  padding: 0 16px 12px 12px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--border-secondary);

  position: sticky;
  top: 0;
  z-index: 10;
  background-color: var(--content-background-color);
`

export const HeaderItem = styled.span`
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 15fr 22fr 22fr 9.8fr 16.9fr 11.9fr 2.4fr;
  grid-gap: 24px;
  overflow-wrap: anywhere;

  padding: 12px 16px 12px 12px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  cursor: pointer;

  &:hover {
    background-color: var(--black-secondary);
  }
`

export const RowItem = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 12px;
  position: relative;

  & {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--border-secondary);
      border-radius: 10px;
      visibility: visible;
    }
  }

  ${props => props.isWrapper && css`
    min-height: 200px;
  `}

  ${props => props.isTable && css`
    padding: 0;
    max-height: 484px;
  `}
`

// SEARCH INPUT

export const SearchField = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  border-radius: 5px;
  border: 1px solid var(--border-secondary, #5B6067);
  background: var(--text-dark, #161618);
`

export const DarkSearchField = styled(SearchField)`
  width: 300px;
  height: 48px;
  background: var(--black-tertiary, #0D0D0F);
`

export const SearchTextField = styled.input`
  width: 100%;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: inherit;
  border: none;

  &::placeholder{
    color: #5B6067;
  }
  &:focus {
    outline: none;
  }
`

export const SearchIcon = styled(magnifyIcon)`
`

export const ClearIcon = styled(ClearIconSVG)`
  cursor: pointer;

  path {
    fill: var(--text-dark-off, #98A4AE);
  }

  &:hover {
    path {
      fill: #fff;
    }
  }

  &:active {
    opacity: 0.3;
  }
`

import React, { useState } from 'react'
import './vip-user.css'
import { ChooseToolMode, Separator, ToolDescription } from './styled'
import CustomRadioButton from '../CustomRadioButton/CustomRadioButton'
import VipUser from './VipUser'
import MultipleVipUsers from './MultipleVipUsers'

const VIP_TOOL_MODES = ['One user', 'Multiple users']

function VipUserTool () {
  const [toolMode, setToolMode] = useState(VIP_TOOL_MODES[0])

  return (
    <div className='vip-user-page__container'>
      <h3>Add VIP Users</h3>
      <Separator margin={24} />
      <ToolDescription>
        Provide users VIP access to Outlier courses. All chapters and
        assignments will be unlocked.
      </ToolDescription>
      <ChooseToolMode>
        <p>How many VIP users would you like to enroll?</p>
        {VIP_TOOL_MODES.map((mode) => (
          <div key={mode}>
            <CustomRadioButton
              label={mode}
              name='tool-mode'
              checked={mode === toolMode}
              value={mode}
              onChange={(toolMode) => setToolMode(toolMode)}
            />
          </div>
        ))}
      </ChooseToolMode>
      <Separator margin={32} />
      {toolMode === 'One user' ? <VipUser /> : <MultipleVipUsers />}
    </div>
  )
}

VipUserTool.displayName = 'VipUserTool'
export default VipUserTool

import styled from 'styled-components'
import Select from 'react-select'
import Button from '../../Button/Button'
import {
  InformationCardTitle
} from '../../InformationCard/style'
import {
  FilterWrapper,
  PageHeading,
  Separator,
  ViewAllWrapper,
  SelectFiltersContainer
} from '../../WritingGradeCenter/styled'

export const SubHeadingMarginBottom = styled(InformationCardTitle)`
  font-weight: ${props => (props.hasCourse ? 400 : 300)};
  margin-bottom: ${props => props.hasCourse ? 20 : 24}px;
  display: block;
`

export const ExamSelectFiltersContainer = styled(SelectFiltersContainer)`
  flex-wrap: wrap;
  justify-content: flex-start;
`

export const ExamFilterWrapper = styled(FilterWrapper)`
  width: 100%;
  max-width: 350px;
  margin-right: ${props => props.mr ? props.mr : 0}px;
`

export const TableWrapper = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  position: relative;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */

  &::-webkit-scrollbar { /* WebKit */
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`

export const TableMainWrapper = styled.div``

export const SubPageHeading = styled(PageHeading)`
  font-size: 24px;
  line-height: 29px;
  margin-top: ${props => props.mt ? props.mt : 0}px;
`

export const ExamSeparator = styled(Separator)`
  margin-top: 0px;
  margin-bottom: 17px;
`

export const ExamViewAllWrapper = styled(ViewAllWrapper)`
  display: flex;
  align-items: center;
  position: sticky;
  height: 60px;
  padding-top: 0px;
`

export const ExamButton = styled(Button)`
  background-color: var(--brand-regular) !important;

  :hover {
    background-color: var(--brand-regular-hover) !important;
  }

  &:active {
    background: var(--brand-regular-active) !important;
    opacity: 0.3;

    span {
      color: black !important;
    }
  }

  :disabled {
    opacity: 0.7;
  }
`

export const TableHeaderText = styled.span`
  line-height: 17px;
  font-size: 14px;
`

export const TextDisabled = styled.div`
  color: var(--text-dark-off);
  font-size: 18px;
`

export const CustomSelect = styled(Select)`
  max-width: 350px;
  width: 100%;
  min-height: 40px;
  margin-bottom: ${({ reduced }) => reduced ? '15px' : '24px'};
  & > .select__control {
    height: auto;
    color: var(--text-primary);
    background-color: var(--input-primary);
    border: 1px solid ${props => props.error
    ? 'var(--error-color)' : 'var(--border-primary)'};
    &:hover {
      border: 1px solid var(--brand-regular);
    }
    .select__indicators {
      border-left: 1px solid var(--border-primary);
      padding-left: 7px;
      margin: 3px auto;
      .select__clear-indicator {
        padding-left: 1px;
      }
    }
    .select__single-value {
      font-size: 16px;
    }
    .select__value-container {
      font-size: 16px;
      .select__input {
        color: var(--text-primary);
      }
      .select__multi-value {
        background: var(--canvas-secondary);
        padding: 6px 3px 6px 3px;
        border-radius: 5px;
        .select__multi-value__label {
          color: var(--text-primary);
          font-size: 16px;
          font-weight: 400;
          line-height: 19.2px;
        }
        .select__multi-value__remove:hover {
          background-color: transparent;
          color: var(--text-primary);
        }
      }
    }
  }
  & > .select__menu {
    width: 100%;
    max-height: 200px;
    color: var(--white)!important;
  }
`

import React, { useState } from 'react'
import {
  ActiveIndicator,
  Container,
  Header,
  IndicatorsContainer,
  HeaderContainer,
  CSVLink,
  Separator
} from './style'
import StudentInformation from './StudentInformation'
import AttemptsAndPurchases from './AttemptsAndPurchases'

const STUDENT_INFORMATION = 'student-information'
const ATTEMPTS_AND_PURCHASES = 'attempts-and-purchases'

const initialState = {
  email: '',
  name: '',
  notes: '',
  emailExistError: false,
  id: '',
  relationship: '',
  courses: [],
  cohorts: [],
  contractorEmployee: false,
  disableOnboarding: false,
  dateOfBirth: '',
  orders: []
}

function AddStudent ({ routerProps: { match, history } }) {
  const { step } = match.params
  const formState = useState(initialState)
  const coursesState = useState([])
  const cohortState = useState([])
  const studentRelationshipState = useState([])
  const studentStatusesState = useState([])
  const studentNotesState = useState([])
  const paymentStatusesState = useState([])

  const renderStep = () =>
    ({
      [STUDENT_INFORMATION]: (
        <StudentInformation
          studentRelationshipState={studentRelationshipState}
          coursesState={coursesState}
          cohortState={cohortState}
          formState={formState}
        />
      ),
      [ATTEMPTS_AND_PURCHASES]: (
        <AttemptsAndPurchases
          formState={formState}
          initialState={initialState}
          studentNotesState={studentNotesState}
          studentRelationshipState={studentRelationshipState}
          studentStatusesState={studentStatusesState}
          paymentStatusesState={paymentStatusesState}
        />
      )
    }[step])

  return (
    <Container>
      <HeaderContainer>
        <Header>add new student</Header>
        <CSVLink
          data-testid='btn-import-csv'
          href='#/students-csv-import'
          className='btn btn-custom btn-primary'>
          <span> Import student csv</span>
        </CSVLink>
      </HeaderContainer>

      <Separator />

      <IndicatorsContainer>
        <ActiveIndicator active={step === STUDENT_INFORMATION}>
          <span>1</span> student information
        </ActiveIndicator>
        <ActiveIndicator active={step === ATTEMPTS_AND_PURCHASES}>
          <span>2</span> attempts and purchases
        </ActiveIndicator>
      </IndicatorsContainer>
      {renderStep() || history.push(`/students/${STUDENT_INFORMATION}`)}
    </Container>
  )
}

AddStudent.displayName = 'AddStudent'
export default AddStudent

import React, { useState, useEffect, useContext } from 'react'
import { FullStoryAPI } from 'react-fullstory'
import { createBrowserHistory } from 'history'
import createClient from './client'
import api from '../../api'

export const history = createBrowserHistory()

const DEFAULT_REDIRECT_CALLBACK = appState => {
  history.push(appState && appState.targetUrl
    ? appState.targetUrl
    : window.location.pathname, {})
}

export const Auth0Context = React.createContext()
export const useAuth0 = () => useContext(Auth0Context)
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState()
  const [auth0Client, setAuth0] = useState()
  const [loading, setLoading] = useState(true)
  const [redirect, setRedirect] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createClient()
      setAuth0(auth0FromHook)

      if (window.location.search.includes('code=')) {
        const { appState } = await auth0FromHook.handleRedirectCallback()
        onRedirectCallback(appState)
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated()

      setIsAuthenticated(isAuthenticated)

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser()
        const currentUserPermissions = await api.getCurrentUserPermissions()
        const studentId = await api.getStudentId()
        setUserWithStudentId({ ...user, ...currentUserPermissions, studentId })
      }

      setLoading(false)
      setRedirect(true)
    }
    initAuth0()
    // eslint-disable-next-line
  }, [])

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true)
    try {
      await auth0Client.loginWithPopup(params)
    } catch (error) {
      console.error(error)
    } finally {
      setPopupOpen(false)
    }
    const user = await auth0Client.getUser()
    const currentUserPermissions = await api.getCurrentUserPermissions()
    const studentId = await api.getStudentId()
    setUserWithStudentId({ ...user, ...currentUserPermissions, studentId })
    setIsAuthenticated(true)
  }

  const handleRedirectCallback = async () => {
    setLoading(true)
    await auth0Client.handleRedirectCallback()
    const user = await auth0Client.getUser()
    setLoading(false)
    setIsAuthenticated(true)
    const currentUserPermissions = await api.getCurrentUserPermissions()
    const studentId = await api.getStudentId()
    setUserWithStudentId({ ...user, ...currentUserPermissions, studentId })
  }
  const setUserWithStudentId = async (user) => {
    const { studentId } = user
    FullStoryAPI('identify', studentId, {
      // Real displayName is not passed to FS in order to limit exposure of PII.
      // As FS needs either displayName or email, first one is passed with the student id
      displayName: studentId,
      studentId_str: studentId
    })
    setUser(user)
  }
  if (!redirect) return null
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  )
}

import styled from 'styled-components'
import Button from '../Button/Button'
import ModalComponent from '../ModalComponent/ModalComponent'

export const StyledContainer = styled.div`
    margin: 64px 63px 64px 36px;
`

export const Header = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
`

export const Separator = styled.hr`
  margin: 36px 0 24px;
  height: 0;
  border: 1px solid rgba(255, 255, 255, 0.3);
`

export const StyledCustomButton = styled(Button)`
  width: 150px;
  margin-top: 22px;
  background-color: #5FC4B8 !important;

  &:active {
    background-color: #161618 !important;
  }
`

export const BreadCrumb = styled.h3`
  padding-left: 0;
`

export const StudentInfoItem = styled.div`
  margin-bottom: 15px;
  & span {
    margin-left: 0px;
  }
  & span:nth-child(1) {
    width: 180px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
`

export const InfoWrapper = styled.div`
  margin-right: 20px;
  flex: 1;
`

export const CustomModal = styled(ModalComponent)``

export const ChildWrapper = styled.div`
    margin: 20px;
    width: 400px;
`

export const ButtonWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    justify-content: space-evenly;
`

import React from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import { MODAL_TEXT } from './constants/constants'
import {
  ModalHeader,
  ModalHeaderTitle,
  ModalCloseIcon,
  ModalBody,
  ModalActions,
  PrimaryButton,
  SecondaryButton
} from './styled'

const Modal = ({
  showModal,
  handleClose,
  handleSubmit,
  isDropModal,
  email
}) => {
  const { DROP_MODAL, REMOVE_MODAL } = MODAL_TEXT

  return (
    <ModalComponent show={showModal} handleClose={handleClose}>
      {isDropModal && (
        <ModalHeader>
          <ModalHeaderTitle>Confirm drop</ModalHeaderTitle>
          <ModalCloseIcon
            data-testid='close-modal-btns'
            onClick={handleClose}
          />
        </ModalHeader>
      )}
      <ModalBody>
        {isDropModal
          ? <p>{DROP_MODAL.p1}</p>
          : <p dangerouslySetInnerHTML={{ __html: REMOVE_MODAL.p1(email) }} />
        }
        {isDropModal && <p>{DROP_MODAL.p2}</p>}
      </ModalBody>
      <ModalActions>
        <PrimaryButton
          data-testid='primary-btn'
          onClick={handleSubmit}
        >
          {isDropModal ? 'Complete Drop' : 'Remove Student'}
        </PrimaryButton>
        <SecondaryButton ml='12' onClick={handleClose}>
          {isDropModal ? 'Review' : 'Cancel'}
        </SecondaryButton>
      </ModalActions>
    </ModalComponent>
  )
}

Modal.displayName = 'Modal'
export default Modal

import React from 'react'
import useWindowSize from '../../../Hooks/useWindowSize'
import { BREAKPOINTS } from '../../../mediaQueries'
import {
  SmallFilterContainer,
  SmallFilterLabel,
  CourseSelect,
  LargeFilterContainer,
  LargeFilterItem
} from './styled'

function CourseDurationSelect (props) {
  const {
    filterOptions,
    selectedFilter,
    setSelectedFilter
  } = props

  const [windowWidth] = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.mobile

  if (isMobile) {
    return (
      <SmallFilterContainer>
        <SmallFilterLabel>Course Filter</SmallFilterLabel>
        <CourseSelect
          options={filterOptions}
          value={selectedFilter}
          onChange={filter => setSelectedFilter(filter)}
        />
      </SmallFilterContainer>
    )
  }

  return (
    <LargeFilterContainer>
      {filterOptions.map(filter => (
        <LargeFilterItem
          key={filter.label}
          selected={selectedFilter.value === filter.value}
          onClick={() => setSelectedFilter(filter)}
        >
          {filter.label}
        </LargeFilterItem>
      ))}
    </LargeFilterContainer>
  )
}

export default CourseDurationSelect

/**
 * @param {Array} array An array of Strings
 * @returns {String} Formatted string with commas & and at the end as needed
 */
export function getFormattedSentenceFromArray (array) {
  if (!array || typeof array !== 'object' || !array.length) return

  const arrayCopy = [...array]

  if (arrayCopy.length === 1) return `${arrayCopy[0]}`

  if (arrayCopy.length === 2) return `${arrayCopy[0]} and ${arrayCopy[1]}`

  const lastItem = arrayCopy.pop()
  return `${arrayCopy.join(', ')}, and ${lastItem}`
}

export function parseBooleanObject (obj) {
  if (!obj) return false
  const booleanObj = {}
  Object.entries(obj).forEach(([name, value]) => {
    booleanObj[name] = value.toLowerCase() === 'true'
  })

  return booleanObj
}

export const capitalizeFirstLetter = str => {
  if (!str) return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

// Eg. 'firstName' to 'First Name'
export const convertCamelCaseToHumanReadable = str => {
  if (!str) return ''
  return capitalizeFirstLetter(str.replace(/([A-Z])/g, ' $1').trim())
}

export const addSpacesBeforeText = (text, numberOfSpace) => {
  return '&nbsp;'.repeat(numberOfSpace) + text
}

export const capitalizeFirstLetterOfEveryWord = sentence => {
  if (!sentence || typeof sentence !== 'string') return ''

  return sentence.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
    letter.toUpperCase()
  )
}

export const isMatchCaseInsensitive = (firstString, secondString) => {
  if (!(firstString && secondString)) return false
  const firstStringLowered = firstString.toLowerCase()
  const secondStringLowered = secondString.toLowerCase()
  return firstStringLowered.includes(secondStringLowered)
}

/**
 *
 * @param {Array} options list of options used for dropdown
 * @returns {Array} an array of object with label and value
 */
export const getLabelValueOptions = options => {
  if (!options) return null

  return options.map(item => {
    return {
      label: item,
      value: item
    }
  })
}

export const wait = (time = 0) =>
  new Promise(resolve => {
    setTimeout(resolve, time)
  })

export const mapLimit = (iterable, limit, iteratee) => {
  if (!isFinite(limit) || limit < 1 || limit % 1 !== 0) {
    throw new RangeError('Limit must be a finite integer >= 1')
  }

  if (!iterable) {
    return Promise.resolve([])
  }

  return new Promise((resolve, reject) => {
    const iterator = getIterator(iterable)
    const results = []
    let counter = 0
    let running = 0
    let done = false

    function takeNext () {
      const iteratorResult = iterator.next()

      if (iteratorResult.done) {
        done = true
        if (running <= 0) {
          resolve(results)
        }
        return
      }

      const index = counter
      counter += 1
      running += 1

      Promise.resolve(iteratorResult.value)
        .then(iteratee || defaultIteratee)
        .then(
          value => {
            running -= 1
            results[index] = value
            if (done && running <= 0) {
              resolve(results)
              return
            }
            replenish()
          },
          err => {
            running -= 1
            done = true
            reject(err)
          }
        )
    }

    function replenish () {
      while (running < limit && !done) {
        takeNext()
      }
    }

    replenish()
  })
}

function getIterator (iterable) {
  if (iterable[Symbol.iterator]) {
    return iterable[Symbol.iterator]()
  }
  return Object.keys(iterable)
    .map(key => iterable[key])[Symbol.iterator]()
}

function defaultIteratee (fn) {
  if (typeof fn !== 'function') {
    throw new TypeError(
      'The default iterator in mapLimit expects an array of functions'
    )
  }
  return fn()
}

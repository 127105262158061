import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  DATE_RANGE_SELECTION,
  PRIMARY_CRITERIA_SELECTION,
  USE_PRESET_SELECTION
} from '../../Constants/CSVDownloadToolOptions'
import {
  EXPOSE_KEYS,
  TERM_LENGTHS,
  PAGE_TYPE
} from '../../Constants/CSVDownloadToolV2'
import { Container } from '../CSVDownloadTool/style'
import CheckboxSelector from '../CheckboxSelector'
import CustomDateRange from '../CustomDateRange'
import RadioButtonSelector from '../RadioButtonSelector'
import { StyledUsePresetWrapper } from './styled'
import PresetDownloadButton from './PresetDownloadButton'
import NextButton from './NextButton'
import CSVHeader from './Header'
import PresetSelector from './PresetSelector'
import useForceUpdate from '../../Hooks/useForceUpdate'
import ManualCriteria from './ManualCriteria'
import {
  getAllNoneOptionsValue,
  getAllOptionsValue,
  getPartnerPresetOptions,
  hasArrayValidItem
} from '../../utilities/CSVDownloadToolV2'

const StudentCriteria = props => {
  const forceUpdate = useForceUpdate()

  const {
    criteria,
    criteria: {
      selectedPreset,
      relationships,
      creditGrantingInstitutions
    },
    handleCriteriaChange
  } = props

  useEffect(() => {
    addHandlersForPresetOptions()
    forceUpdate()
    // eslint-disable-next-line
  }, [])

  const { usePreset } = criteria

  const addHandlersForPresetOptions = () => {
    const { options } = USE_PRESET_SELECTION

    const handlers = [
      (values, setValue) => <PresetSelector values={values} setValue={setValue} />,
      (values, setValue) => <ManualCriteria values={values} setValue={setValue} />
    ]

    const newOptions = options.map((option, i) => {
      return {
        ...option,
        handler: handlers[i]
      }
    })

    USE_PRESET_SELECTION.options = newOptions
  }

  const getDisabledOptions = selection => {
    const { exposeKey, options } = selection

    const termLengths = criteria[EXPOSE_KEYS.TERM_LENGTHS] || []
    const { STANDARD, INTENSIVE, EXTENDED, STUDIOOTHER } = TERM_LENGTHS

    if (exposeKey === EXPOSE_KEYS.TERM_LENGTHS) {
      const isStandardOrIntensive = [STANDARD, INTENSIVE, EXTENDED].some(
        item => termLengths.includes(item)
      )
      if (isStandardOrIntensive) return [STUDIOOTHER]

      const isStudioOther = termLengths.includes(STUDIOOTHER)
      if (isStudioOther) return [STANDARD, INTENSIVE, EXTENDED]
    }

    if (exposeKey === EXPOSE_KEYS.RELATIONSHIPS && hasArrayValidItem(creditGrantingInstitutions)) {
      return getAllOptionsValue(options)
    }

    if (exposeKey === EXPOSE_KEYS.CREDIT_GRANTING_INSTITUTIONS && hasArrayValidItem(relationships)) {
      return getAllOptionsValue(options)
    }

    if (exposeKey === EXPOSE_KEYS.CREDIT_GRANTING_INSTITUTIONS && hasArrayValidItem(creditGrantingInstitutions)) {
      const isNone = creditGrantingInstitutions[0] === ''
      if (isNone) return getAllNoneOptionsValue(options)

      return ['']
    }
  }

  const showDownloadButton = !!usePreset
  const showNextButton = usePreset === false

  const presetSelection = () => {
    const {
      headerText,
      options,
      exposeKey
    } = USE_PRESET_SELECTION

    return (
      <StyledUsePresetWrapper>
        <RadioButtonSelector
          headerText={headerText}
          exposeKey={exposeKey}
          options={options}
          values={criteria}
          setValue={handleCriteriaChange}
        />
      </StyledUsePresetWrapper>
    )
  }

  return (
    <Container>
      <CSVHeader pageType={PAGE_TYPE.STUDENT_CRITERIA} />

      <p>
        Select the criteria for each student you want included in the CSV.
        You must make at least one selection in each category below.
      </p>
      {presetSelection()}
      {
        PRIMARY_CRITERIA_SELECTION.map(selection => {
          const {
            exposeKey,
            label,
            getOptions,
            options,
            type
          } = selection

          const disabledOptions = getDisabledOptions(selection)
          const partnerPresetsValues = getPartnerPresetOptions()
            .map(preset => preset.value)
          const isPittPresets =
            usePreset && selectedPreset && exposeKey === EXPOSE_KEYS.COURSES
          const isPittCourses = isPittPresets &&
            partnerPresetsValues.includes(selectedPreset)

          return <CheckboxSelector
            key={exposeKey}
            label={label}
            exposeKey={exposeKey}
            options={options}
            getOptions={getOptions}
            values={criteria}
            selection={selection}
            disabledOptions={disabledOptions}
            setValue={handleCriteriaChange}
            type={type}
            {...(isPittCourses
              ? { isPittCourses: true }
              : {})
            }
            {...(exposeKey === EXPOSE_KEYS.CREDIT_GRANTING_INSTITUTIONS
              ? { initialValue: null }
              : {})
            }
          />
        })
      }
      {
        DATE_RANGE_SELECTION.map((date, index) => {
          const {
            label,
            description,
            exposeKey
          } = date
          return <CustomDateRange
            key={index}
            label={label}
            description={description}
            exposeKey={exposeKey}
            values={criteria}
            setValue={handleCriteriaChange}
          />
        })
      }
      {
        showDownloadButton && (
          <PresetDownloadButton criteria={criteria} />
        )
      }
      {
        showNextButton && <NextButton criteria={criteria} />
      }
    </Container>
  )
}

StudentCriteria.propTypes = {
  criteria: PropTypes.object,
  handleCriteriaChange: PropTypes.func
}

StudentCriteria.displayName = 'StudentCriteria'

export default StudentCriteria

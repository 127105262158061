import React, { useEffect, useState } from 'react'
import api from '../../api'
import MainPage from './MainPage'
import ELTUpload from './ELTUpload'
import { PAGES } from './constants'
import ELTArchive from './ELTArchive'
import orderBy from 'lodash/orderBy'
import AdmissionsUpload from './ProfessionalCertificates/AdmissionsUpload'
import PCELTUpload from './ProfessionalCertificates/ELTUpload'

function Guild () {
  const [currentPage, setCurrentPage] = useState(PAGES.main)
  const [fetchedELTFiles, setFetchedELTFiles] = useState([])
  const [isRefetch, setIsRefetch] = useState(false)

  useEffect(() => {
    const fetchELTFiles = async () => {
      const { data } = await api.getUploadedELTFiles()
      const sortedELTFiles = orderBy(data, 'createdAt', 'desc')
      setFetchedELTFiles(sortedELTFiles)
    }

    fetchELTFiles()
  }, [isRefetch])

  if (currentPage === PAGES.main) {
    return (
      <MainPage setCurrentPage={setCurrentPage} />
    )
  }

  if (currentPage === PAGES.ELTUpload) {
    return (
      <ELTUpload
        setCurrentPage={setCurrentPage}
        refetchELTFiles={() => setIsRefetch(!isRefetch)}
      />
    )
  }

  if (currentPage === PAGES.ELTArchive) {
    return (
      <ELTArchive
        setActivePage={setCurrentPage}
        fetchedELTFiles={fetchedELTFiles}
      />
    )
  }

  if (currentPage === PAGES.PC_AdmissionsUpload) {
    return <AdmissionsUpload setCurrentPage={setCurrentPage} />
  }

  if (currentPage === PAGES.PC_ELTUpload) {
    return <PCELTUpload setCurrentPage={setCurrentPage} />
  }

  return null
}

Guild.displayName = 'Guild'
export default Guild

import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import orderBy from 'lodash/orderBy'
import Button from '../Button/Button'
import {
  useWritingGradeCenterContext
} from '../../contexts/WritingGradeCenterContext'
import {
  timeStampToFormattedDate,
  secondsToHHMMSS
} from '../../utilities/dateTimeUtil'
import {
  getSubmissionStatusFilteredSubmissions,
  getGradingStatusFilteredSubmissions
} from '../../utilities/writingGradeCenterUtils'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import SubmissionsTableHeader from './SubmissionTableHeader'
import { HEADERS } from './constants/constants'
import {
  AssignmentTableContainer,
  PaginationContainer
} from './styled'

const SubmissionsTable = ({
  entriesPerPage,
  isLoading
}) => {
  const {
    selectedSubmissionStatuses,
    selectedGradingStatuses,
    isSubmissionsAscending,
    filteredSubmissions
  } = useWritingGradeCenterContext()

  const location = window.location
  const [sortColumn, setSortColumn] = useState('submissionTime')
  const [currentPage, setCurrentPage] = useState(0)
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000)
    return () => {
      clearInterval(timerID)
    }
  })

  function tick () {
    setDate(new Date())
  }

  const currentTime = Math.floor(date / 1000)
  const eightyOneHours = 81 * 60 * 60

  const submissionStatusFilteredSubmission =
    getSubmissionStatusFilteredSubmissions(
      filteredSubmissions, selectedSubmissionStatuses)

  const gradingStatusFilteredSubmission = getGradingStatusFilteredSubmissions(
    submissionStatusFilteredSubmission, selectedGradingStatuses)

  const filteredSubmissionsWithTimeLeft = gradingStatusFilteredSubmission.map(
    submission => {
      const { deadline } = submission
      const timeLeftInSeconds = deadline + eightyOneHours - currentTime
      const timeLeftWithinLimits = Math.min(
        eightyOneHours,
        Math.max(0, timeLeftInSeconds)
      )
      return { timeLeft: timeLeftWithinLimits, ...submission }
    }
  )

  const numberOfSubmissions = filteredSubmissionsWithTimeLeft.length
  const numberOfPages = Math.ceil(numberOfSubmissions / entriesPerPage.value)

  const sortedSubmissions = orderBy(
    filteredSubmissionsWithTimeLeft,
    sortColumn,
    isSubmissionsAscending ? 'asc' : 'desc'
  )

  const currentSubmissions = (numberOfSubmissions <= entriesPerPage.value)
    ? sortedSubmissions
    : sortedSubmissions.slice(
      currentPage * entriesPerPage.value, (currentPage + 1) * entriesPerPage.value
    )

  const handlePaginationBtnClick = selectedNumber => {
    // to prevent redundant state update and re-render
    const number = selectedNumber.selected
    if (currentPage === number) return

    setCurrentPage(number)
  }

  if (isLoading) return <LoadingSpinner position='relative' />

  return (
    <>
      <AssignmentTableContainer>
        <div className='table-header'>
          {HEADERS.map((header, index) => (
            <SubmissionsTableHeader
              key={index}
              title={header.title}
              sortItem={header.sortItem}
              sortColumn={sortColumn}
              setSortColumn={setSortColumn}
            />)
          )}
          <span className='submission-header right' />
        </div>
        {currentSubmissions.map((submission, index) => {
          const { studentId, submissionTime, title, isGraded, timeLeft,
            cohortMilestone: { cohortName } = {}, deadline, cohortDetails,
            email, assignmentUUID, courseId } = submission

          const timeLeftString = secondsToHHMMSS(timeLeft)

          return (
            <div className='table-row' key={`${studentId}${index}`}>
              <span className='submission-row'>{cohortDetails}</span>
              <span className='submission-row'>{title}</span>
              <span className='submission-row'>{studentId}</span>
              <span className='submission-row'>{email}</span>
              <span className='submission-row time'>
                {timeStampToFormattedDate(deadline * 1000, true, 'short')}
              </span>
              <span className='submission-row time'>
                {timeStampToFormattedDate(submissionTime * 1000, true, 'short')}
              </span>
              <span className='submission-row'>{timeLeftString}</span>
              <span className='submission-btn-wrapper'>
                <Button
                  onClick={() => {
                    const launchURL = `${location.origin}/#/student-submission/${courseId}/${encodeURIComponent(cohortName)}/${assignmentUUID}/${encodeURIComponent(email)}`
                    window.open(launchURL, '_blank')
                  }}
                  className={`btn-custom submission-btn ${
                    isGraded ? 'btn-secondary' : 'btn-primary'
                  }`}
                >
                  {isGraded ? 'Review' : 'Grade'}
                </Button>
              </span>
            </div>
          )
        })}
      </AssignmentTableContainer>
      { numberOfPages > 1 &&
      <PaginationContainer>
        <ReactPaginate
          previousLabel='Prev'
          nextLabel='Next'
          breakLabel='...'
          breakClassName='break-me'
          pageCount={numberOfPages}
          marginPagesDisplayed={1}
          pageRangeDisplayed={3}
          onPageChange={handlePaginationBtnClick}
          containerClassName='pagination'
          subContainerClassName='pages pagination'
          activeClassName='active'
        />
      </PaginationContainer>
      }
    </>
  )
}

export default SubmissionsTable

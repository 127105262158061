import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  ButtonAsLink,
  DropdownHeader,
  HeaderWrapper,
  SelectWrapper,
  Separator,
  dropDownStyles
} from './styles'
import { Label } from 'reactstrap'
import Select, { components } from 'react-select'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import HideFields from './HideFields'
import Filters from './Filters'
import { getDBViewerPreferences } from '../../../utilities/dataBaseUtils'
import SubHeader from './SubHeader'

const ViewsDropdown = (props) => {
  const { openAddViewModal } = props.selectProps
  return (
    <components.Menu {...props}>
      <DropdownHeader>
        <span>All views</span>
        <ButtonAsLink onClick={openAddViewModal}>
          <PlusIcon />
          Add a view
        </ButtonAsLink>
      </DropdownHeader>
      {props.children}
    </components.Menu>
  )
}

function DBViewerHeader ({
  allTables,
  currentTable,
  views,
  getViews,
  currentView,
  setCurrentView,
  setShowAddViewModal,
  setShowAddRecordModal,
  tableHeaders,
  metaData,
  canUserEditDatabase
}) {
  const history = useHistory()

  const changeTable = (table) => {
    const lastVisitedView =
      getDBViewerPreferences()?.[table.value]?.lastVisitedView || 'Default'

    history.push(`/database-viewer/${table.value}/${lastVisitedView}`)
  }

  const changeTableView = (view) => {
    history.push(`/database-viewer/${currentTable}/${view.label}`)
    setCurrentView(view)
  }

  const openAddRecordModal = () => {
    setShowAddRecordModal(true)
  }

  return (
    <>
      <HeaderWrapper>
        <SelectWrapper>
          <Label className='input-label'>Database table</Label>
          <Select
            options={allTables}
            value={{ label: currentTable, value: currentTable }}
            onChange={changeTable}
            placeholder='select a table'
            styles={dropDownStyles}
            width='300px'
          />
        </SelectWrapper>
        <SelectWrapper>
          <Label className='input-label'>Views</Label>
          <Select
            options={views}
            value={currentView}
            onChange={changeTableView}
            placeholder='select a view'
            styles={dropDownStyles}
            width='200px'
            height='56px'
            openAddViewModal={() => setShowAddViewModal(true)}
            components={{ Menu: ViewsDropdown }}
          />
        </SelectWrapper>
        <HideFields
          currentView={currentView}
          setCurrentView={setCurrentView}
          tableHeaders={tableHeaders}
          currentTable={currentTable}
        />
        <Filters
          metaData={metaData}
          currentView={currentView}
          setCurrentView={setCurrentView}
          currentTable={currentTable}
        />
      </HeaderWrapper>
      <Separator />
      <SubHeader
        views={views}
        getViews={getViews}
        currentView={currentView}
        currentTable={currentTable}
        openAddRecordModal={openAddRecordModal}
        canUserEditDatabase={canUserEditDatabase}
      />
    </>
  )
}

DBViewerHeader.displayName = 'DBViewerHeader'
export default DBViewerHeader

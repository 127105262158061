import styled, { css } from 'styled-components'
import Select from 'react-select'
import {
  DropdownItemExtended,
  DropdownMenuExtended,
  DropdownExtended
} from '../../PermissionManager/styles'
import {
  FormGroup,
  Button,
  Label,
  Input
} from 'reactstrap'
import calendar from '../../../assets/icons/calendar_2.svg'
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg'
import CurrencyInput from 'react-currency-input-field'
import ReactTooltip from 'react-tooltip'
import { VipCheckBox as CheckBox, DatePickerContainer } from '../../VipUser/styled'

export const Container = styled.div`
  margin: 64px 63px 64px 36px;

  p {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }

  div.presets {
    margin-left: 35px;
  }

  div.presets p {
    margin-bottom : 14px;
  }
`
export const Header = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  margin-bottom: 0;
`
export const Separator = styled.hr`
  margin: 30px 0 31px;
  height: 1px;
  border-color: var(--border-primary);
`
export const IndicatorsContainer = styled.div`
  display: flex;
  margin-bottom: 45px;
`

export const Indicator = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 13px 14px;
  margin-right: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  background: var(--black-secondary);
  color: var(--brand-regular);
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;

  span {
    font-weight: 700;
    margin-right: 12px;
  }
`
export const ActiveIndicator = styled(Indicator)`
  color: ${(props) => props.active ? 'var(--text-primary)' : 'var(--brand-regular)'};
  border: ${(props) => props.active ? '2px solid var(--white)' : 'none'};
  min-width: 186px;
`

export const CustomFormGroup = styled(FormGroup)`
  width: 400px;
`

export const CustomButton = styled(Button)`
  ${({ noMargin }) => !noMargin && `margin-top: 24px;`}
  border: none !important; 
  &.btn-custom.btn-primary {
    border-radius: 5px;
    padding: 10px;
    width: 150px;
    color: var(--text-dark);
    background-color: var(--brand-regular) !important;
    font-size: 14px !important;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 17px !important;
    &:hover:not(:disabled) {
      background-color: var(--brand-regular-hover)!important;
    }
    &:active {
      background-color: var(--brand-regular-active)!important;
    }
  }
`

export const CustomLabel = styled(Label)`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
`

export const inputStyles = css`
  min-height: 40px;
  height: 40px;
  margin-bottom: ${({ reduced }) => reduced ? '15px' : '24px'};
  background-color: var(--input-primary);
  border: 1px solid ${props => props.error
    ? 'var(--error-color)' : 'var(--border-primary)'};
  color: var(--text-primary);
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  &:disabled {
    background-color: var(--input-primary);
    border: 1px solid var(--border-primary);
    color: var(--text-primary);
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    box-shadow: var(--shadow-primary);
  }
  &:focus, &:hover:not(:disabled) {
    border: 1px solid var(--brand-regular);
    background-color: var(--input-primary);
    color: var(--text-primary);
    box-shadow: none;
  }
`

export const CustomInput = styled(Input)`
  ${inputStyles}
`

export const CustomSelect = styled(Select)`
  min-height: 40px;
  margin-bottom: ${({ reduced }) => reduced ? '15px' : '24px'};
  background-color: var(--input-primary);
  & > .select__control {
    width: 100%;
    height: auto;
    min-height: 40px;

    &.select__control--is-focused {
      border: 1px solid var(--brand-regular) !important;
    }

    .select__indicators {
      border-left: 1px solid var(--border-primary);
      padding-left: 7px;
      margin: 3px auto;

      .select__clear-indicator {
        padding-left: 1px;
      }
    }

    .select__single-value {
      font-size: 16px;
    }

    .select__value-container {
      font-size: 16px;

      .select__input {
        color: var(--text-primary);
      }

      .select__multi-value {
        background: var(--canvas-secondary);
        padding: 6px 3px 6px 3px;
        border-radius: 5px;

        .select__multi-value__label {
          color: var(--text-primary);
          font-size: 16px;
          font-weight: 400;
          line-height: 19.2px;
        }

        .select__multi-value__remove:hover {
          background-color: transparent;
          color: var(--text-primary);
        }
      }
    }
  }
  & > .select__menu {
    width: 100%;
  }
`

export const SubHeader = styled.h3`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
`

export const InfoText = styled.span`
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  margin-bottom: 10px;
  display: block;
`

export const CourseInfo = styled.h5`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 48px;
`

export const RecordHeading = styled.h6`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--text-secondary);
  margin-top: 36px;
  margin-bottom: 15px;
`

export const RequiredText = styled.div`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 34px;
  margin-bottom: 23px;
  letter-spacing: 1px;
`

export const DateContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container::after {
      content: url(${calendar});
      position: absolute;
      right: 3%;
      top: 50%;
    }
  }

  .react-datepicker {
    font-family: "Lato";
    background: var(--black-quaternary);
    border: none;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 16px;
    color: var(--text-primary);
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 14px;
    color: var(--text-primary);
  }
  .react-datepicker__month-container {
    background: var(--black-quaternary);
  }
  .react-datepicker__header {
    background: var(--black-quaternary);
    border-bottom: 1px solid var(--border-primary);
  }

  .react-datepicker__time-list {
    background: var(--black-quaternary);
  }

  .react-datepicker__time-list-item {
    font-size: 14px;
    color: var(--text-primary);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__time-list-item--selected {
    background: var(--brand-regular) !important;
    color: var(--text-dark) !important;
  }

  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__time-container {
    border-left: 1px solid var(--border-primary);
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__time-list-item:hover {
    color: var(--text-dark);
    background: var(--brand-regular-hover)!important;
  }

  .react-datepicker__time-box {
    width: 84px !important;
  }

  .react-datepicker__time-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--shadow-primary);
    border-radius: 10px;
    background-color: transparent;
  }

  .react-datepicker__time-list::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  .react-datepicker__time-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--shadow-primary);
    background: rgba(255, 255, 255, 0.1);
  }

  .react-datepicker__navigation {
    top: 14px;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 91px;
  }
`

export const CustomButtonSecondary = styled(CustomButton)`
  border: 1px solid var(--brand-regular) !important;
  ${({ noMargin }) => noMargin && `margin: 0;`}
  width: 150px;
  border-radius: 5px!important;
  box-sizing: border-box;
  background: ${({ background }) => background || 'unset'} !important;
  color: var(--brand-regular);
  margin-left: 12px;
  text-transform: uppercase;
  &:hover {
    border-color: var(--brand-regular-hover)!important;
    color: var(--brand-regular-hover);
  }
`
export const Info = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`

export const ErrorText = styled.span`
  float: left;
  margin-top: -19px;
  margin-bottom: -2px;
  font-size: 14px;
  color: var(--error-color);
`

export const ErrorTextExtended = styled.span`
  color: var(--error-color);
  font-size: 14px;
`

export const CustomCurrencyInput = styled(CurrencyInput)`
  ${inputStyles}
`

export const CustomToolTip = styled(ReactTooltip)`
  background-color: var(--error-color);
  box-shadow: 0px 0px 20px var(--shadow-primary);
  border-radius: 5px;
  width: 255px;
  opacity: 1 !important;

  &.tooltip {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--text-primary);
  }
  
  &.tooltip:before, :after{
    content: none !important;
  }
`
export const HeaderContainer = styled.div`
  display: flex; 
  justify-content: space-between;
`

export const CSVLink = styled(CustomButton)`
  width: 190px !important;
  padding: 11px 7px 12px 8px;
  border: none !important;

  > span {
    width: 175px;
    height: 17px;
  }
`

export const InstructionsArea = styled.div`
`

export const InstructionsHeading = styled.p`
  font-size: 18px !important;
  font-weight: 400 !important;
  color: var(--text-primary);
  margin: 0;
`

export const EmailError = styled.p`
  font-weight: normal !important;
  font-size: 16px !important;
  margin-bottom: 9px;
  line-height: 19.2px !important;
  color: var(--error-color);
  height: 38px;
`

export const CustomContainer = styled.div`
  margin: ${({ margin }) => margin};
`

export const InstructionsParagraph = styled.p`
  margin:0;
  font-family: Lato;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 300;
  line-height: 19px !important;
  letter-spacing: 0em;
  text-align: left;
`

export const ReviewInstructions = styled.p`
  margin:0;
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
`

export const DownloadExampleCSVLink = styled.a`
  font-size: 14px;
  font-weight: 700;
  color: var(--brand-regular);
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
   text-decoration: none;
   color: var(--brand-regular);
  }
`
export const ColumnsContainer = styled.div`
  margin-top: 32px;
  max-width: 716px;
  display: flex;
  justify-content: space-between;
`

export const ColumnHeader = styled.h1`
  text-transform: uppercase;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1px;
  text-align: left;
`

export const CustomColumnInput = styled(Input)`
  ${inputStyles}
  width: 334px;
  margin: 24px 0 0 0;
  padding: 11px 12px 14px;
`

export const DropdownContainer = styled(DropdownExtended)`
 > button {
   border: 1px solid var(--border-primary);
   width: 334px;
   height: 40px;
   padding: 10px 14px;
   text-transform: none;
 }

 .divider {
  height: 32px;
  padding-left: 13px;
  border-left: 1px solid var(--border-primary);
}

 input {
   background-color: transparent;
   border: none;
   width: 100%;
   outline: none;
   color: var(--text-primary);
 }
`

export const DropdownMenu = styled(DropdownMenuExtended)`
  background: var(--black-quaternary) !important;
  min-height: 230px !important;
  width: 334px !important;
`

export const DropdownItem = styled(DropdownItemExtended)`
  font-size: 14px;
  font-weight: 400;
  margin: 4px 17px 0 23px; 
  padding: 4px 12px;
  text-transform: none;
`

export const DropdownItemHeader = styled(DropdownItemExtended)`
  color: var(--brand-regular) !important;
  font-size: 14px;
  font-weight: 400;
  margin-left: 17px;
  padding: 0;
  text-transform: none;

  &:hover {
    background-color: transparent !important; 
  }
`

export const ReviewTitle = styled.h4`
  font-size: 18px;
  color: var(--text-primary);
  font-weight: 400;
  margin-bottom: 0px;
`

export const ErrorTitle = styled.span`
  font-size: 18px;
  color: var(--text-primary);
  font-weight: 400;
  margin-bottom: 0px;
  line-height: 22px;
`

export const Link = styled.a`
  color: var(--brand-regular);
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  margin-left: 16px;
  letter-spacing: 1px;

  &:hover {
    color: var(--brand-regular);
    text-decoration: none;
  }
`

export const EditLink = styled.div`
  color: var(--brand-regular);
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  margin-left: 16px;
  letter-spacing: 1px;
  cursor: pointer;
  display: inline;

  &:hover {
    color: var(--brand-regular);
    text-decoration: none;
  }
`

export const ReviewColumnsContainer = styled.div`
  margin-top: 12px;
  display: flex;
  width: 398px;
  justify-content: space-between;

  span {
   margin-top: 12px;
 }
`

export const ReviewColumnsHeader = styled.h5`
 color: var(--text-dark-off);
 font-size: 14px;
 font-weight: 700;
 text-transform: uppercase;
`

export const ReviewColumnsCell = styled.div`
  min-height: 17px;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 14px;
`

export const ErrorTableStyle = styled.div`
  overflow-x: scroll;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  -webkit-mask-image: linear-gradient( 90deg, #000 90%, transparent);

  margin-top: 10px;
  table {
    border-spacing: 0;
    border: 1px solid var(--gray-tertiary);
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 12px;
      border-bottom: 1px solid var(--gray-tertiary);
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      :last-child {
        border-right: 1px solid var(--gray-tertiary);
      }
    }
    th {
      color: var(--text-dark-off);
      letter-spacing: 1px;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      height: 70px;
    }
    .cell-error{
      color: var(--error-color);
    }
    .null-cell {
      font-style: italic;
    }
  }
`
export const CustomCheckbox = styled(CheckBox)`
  ${props => props.disabled && ` opacity: 0.5;`}
  margin-bottom: ${({ mb }) => mb || '24px'};
`

export const DatePickerWrapper = styled(DatePickerContainer)`
  margin-bottom: 20px;
`

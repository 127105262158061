import styled from 'styled-components'
import { Button } from 'reactstrap'
import mediaqueries from '../../../mediaQueries'
import {
  ReactComponent as BoxArrowSVG
} from '../../../assets/icons/box-arrow-up-right.svg'

import {
  ReactComponent as Download
} from '../../../assets/icons/download.svg'

export const Container = styled.div`
  margin: 64px 63px 64px 36px;
  ${mediaqueries.mobile`
    margin: 64px 33px 64px 23px;
  `}
`

export const Indicator = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  padding: 13px 14px;
  margin-right: 14px;
  border-radius: 5px;
  box-sizing: border-box;
  background: var(--black-secondary);
  color: var(--brand-regular);
  font-size: 18px;
  line-height: 22px;
  text-transform: capitalize;
  ${mediaqueries.mobile`
    width: max-content;
  `}
  span {
    font-weight: 400;
    margin-right: 12px;
  }
`

export const ComponentContainer = styled.div`
  max-width: 650px;
  
  h3 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  
  p {
    color: var(--sub-text-secondary);
    margin: 24px 0;
    &.italic-text{
      margin: 8px 0;
      color: var(--text-dark-off);
      font-style: italic;
    }
    &.download-csv-text{
      margin: 18px 0;
    }
  }
  
`
export const Header = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 0;

`

export const Separator = styled.hr`
  margin: 34px 0;
  height: 1px;
  border-color: var(--border-primary);
`
export const IndicatorsContainer = styled.div`
  display: flex;
  margin-bottom: 32px;
  ${mediaqueries.mobile`
    flex-direction: column;
  `}
  
  span{
    font-weight: 400;  
  }
`

export const ActiveIndicator = styled(Indicator)`
  margin-bottom: 16px;
  color: ${(props) => props.active ? 'var(--text-primary)' : 'var(--text-dark-off)'};
  border: ${(props) => props.active ? '1px solid var(--white)' : 'none'};
  ${(props) => props.clickable && `
    color: var(--brand-regular);
    cursor: pointer;
  `}
`
export const HeaderContainer = styled.div`
  display: flex; 
  justify-content: space-between;
`
export const CustomButton = styled(Button)`
  margin-top: 40px;
  height: 48px;
  padding: 0 24px;
  border: none !important;
  font-weight: bold;
  text-transform: uppercase;
  ${mediaqueries.mobile`
    width: 100% !important;
  `}
  
  &.btn-custom.btn-primary {
    width: auto;
    color: var(--text-dark) !important;
    background-color: var(--brand-regular) !important;
    font-size: 14px !important;
    line-height: 16px !important;
    margin-right: 16px;
    &:disabled {
      border: none !important;
      background-color: var(--brand-regular-disabled) !important;
    }
    &:hover:not(:disabled) {
      background-color: var(--brand-regular-hover) !important;
    }
  }
  &.btn-custom.btn-secondary {
    border: 1px solid var(--brand-regular) !important;
    color: var(--brand-regular);
    background-color: transparent;
    &:hover:not(:disabled) {
      border: 1px solid var(--brand-regular-hover) !important;
      color: var(--brand-regular-hover);
    }
  }
  &:active {
    background-color: var(--brand-regular-active) !important;
  }
  &.download-csv-btn{
    margin-top: 14px;
  }
`

export const InputContainer = styled.div`
  max-width: 600px;
`

export const DownloadIcon = styled(Download)`
  margin-right: 8px;
  margin-top: -8px;
  fill: var(--brand-regular);
  width: 20px;
  height: 20px;
`

export const BoxArrowIcon = styled(BoxArrowSVG)`
  margin-right: 8px;
  margin-top: -8px;
  fill: var(--text-dark);
`

export const CSVDownloadLink = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--brand-regular) !important;
  margin-bottom: 32px;
  line-height: 17px !important;
  cursor: pointer;
  letter-spacing: 1px;
`

import React, { useState } from 'react'
import { Dropdown, DropdownToggle } from 'reactstrap'
import {
  Button,
  ButtonAsLink,
  ButtonsContainer,
  DropdownHeader,
  HideFieldsDropdown,
  SearchInput,
  SearchInputContainer
} from './styles'
import { ReactComponent as CrossedEyeIcon } from '../../../assets/icons/crossed-eye.svg'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search-white.svg'
import SortableList from './SortableList'
import { getHideFieldsText } from '../utils'
import api from '../../../api'
function HideFields ({
  currentView,
  setCurrentView,
  tableHeaders,
  currentTable
}) {
  const [showHideFieldsDropdown, setShowHideFieldsDropdown] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const hiddenFieldsCount = currentView?.hiddenFields?.length

  const toggleHideFieldsDropdown = () =>
    setShowHideFieldsDropdown(!showHideFieldsDropdown)

  const filteredTableHeaders = tableHeaders.filter((header) =>
    header.id.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const updateHiddenFields = async (newHiddenFields) => {
    const { id, label, value, ...rest } = currentView

    setCurrentView({
      ...currentView,
      hiddenFields: newHiddenFields
    })

    if (currentView.label === 'Default') return

    await api.updateTableView({
      tableName: currentTable,
      id,
      view: { ...rest, hiddenFields: newHiddenFields }
    })
  }

  const hideAllFields = async () => {
    await updateHiddenFields(tableHeaders.map((item) => item.id.toLowerCase()))
  }

  const showAllFields = async () => {
    await updateHiddenFields([])
  }

  const hideUnhideColumn = async (columnName, isColumnHidden) => {
    if (isColumnHidden) {
      const updatedHiddenFields = currentView.hiddenFields.filter(
        (item) => item.toLowerCase() !== columnName.toLowerCase()
      )
      await updateHiddenFields(updatedHiddenFields)
    } else {
      const currentHiddenFields = currentView.hiddenFields || []
      await updateHiddenFields([
        ...currentHiddenFields,
        columnName.toLowerCase()
      ])
    }
  }

  return (
    <Dropdown isOpen={showHideFieldsDropdown} toggle={toggleHideFieldsDropdown}>
      <DropdownToggle data-toggle='dropdown' tag='div'>
        <Button data-testid='hide-fields-button'>
          <CrossedEyeIcon />
          {getHideFieldsText(hiddenFieldsCount)}
        </Button>
      </DropdownToggle>
      <HideFieldsDropdown
        style={{ maxHeight: '304px' }}
        data-testid='dropdown-menu'
        onClick={(e) => e.stopPropagation()}
      >
        <DropdownHeader>
          <span>Fields</span>
          <ButtonsContainer>
            <ButtonAsLink
              disabled={hiddenFieldsCount === tableHeaders.length}
              onClick={hideAllFields}
              data-testid='hide-all-fields'
            >
              Hide all
            </ButtonAsLink>
            <span className='dot'>&#183;</span>
            <ButtonAsLink
              disabled={!hiddenFieldsCount}
              onClick={showAllFields}
              data-testid='show-all-fields'
            >
              Show all
            </ButtonAsLink>
          </ButtonsContainer>
        </DropdownHeader>
        <SearchInputContainer>
          <SearchInput
            type='text'
            placeholder='Lookup field'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            data-testid='column-search-input'
          />
          <SearchIcon />
        </SearchInputContainer>
        {filteredTableHeaders.length > 0 && (
          <SortableList
            items={filteredTableHeaders}
            onChange={(newColumnsOrder) => {
              setCurrentView({
                ...currentView,
                columnOrder: newColumnsOrder
              })
            }}
            currentView={currentView}
            setCurrentView={setCurrentView}
            hideUnhideColumn={hideUnhideColumn}
            isSearching={searchTerm}
            currentTable={currentTable}
          />
        )}
      </HideFieldsDropdown>
    </Dropdown>
  )
}

HideFields.displayName = 'HideFields'
export default HideFields

import styled from 'styled-components'
import { inputStyles } from '../../../Components/global.styled'

export const PageWrapper = styled.div`
  margin: 32px 24px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 768px ) and (min-width: 576px) {
    align-items: flex-start;
  }
`

export const AddUserButton = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background: var(--brand-dark);
  cursor: pointer;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const PageDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: var(--text-primary);
  max-width: 700px;
  align-self: stretch;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 0;
`

export const TabWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;

`

export const Tab = styled.div`
  padding: 6px;
  font-size: 18px;
  cursor: pointer;
  color: ${(props) => (props.active ? 'white' : '#CCD4D8')};
  background-color: ${(props) => (props.active ? 'var(--outlier-teal)' : 'transparent')};
  border-bottom: ${(props) => (props.active ? '2px solid white' : '2px solid transparent')};

  &:hover {
    color: var(--text-secondary);
  }
`

export const TableWrapper = styled.div`
  overflow-x: auto;
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: white;
`

export const TableHeader = styled.th`
  padding: 0 16px 16px;
  text-align: left;
  font-weight: 700;
  font-size: 14px;
  color: var(--text-secondary);
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 16px;
  width: ${(props) => (props.width ? props.width : 'auto')};
    ${props => props.inline && `
    @media (max-width: 576px) {
      width: 240px;
    }
  `}
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  border-bottom: 1px solid var(--gray-primary);

`

export const TableRow = styled.tr`
  border-bottom: 1px solid var(--gray-primary);
  cursor: pointer;

  &:hover {
    background-color: var(--black-secondary);
  }
  &:nth-last-child(2) {
    border: none;
  }
  &:last-child {
    border: none;
    &:hover {
      background-color: transparent;
    }
  }
`

export const TableCell = styled.td`
  padding: 20px 16px;
  ${(props) => props.noPadding && 'padding: 0 16px;'}
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  align-content: flex-start;
  ${(props) => props.width && `max-width: ${props.width};`}
  ${(props) => props.inline && `
    @media (max-width: 576px) {
      display: inline-block;
      width: 180px;
    }
  `}
  > span {
    color: var(--text-dark-off);
    font-size: 14px;
    margin-top: 4px;
    ${(props) => props.width && `
      max-width: ${props.width};
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
    `}
  }
  &.view-users {
    cursor: pointer;
    color: #98A4AE;
    text-align: right;
    align-content: flex-start;
    white-space: nowrap;
    text-transform: none;
  }
`

export const RoleDescription = styled.div`
  @media (max-width: 576px) {
    width: 100vw;
    padding-right: 48px;
  }
`

export const DropDownImg = styled.img`
  margin-left: 10px;
  transform: ${(props) => (props.isSortedAscending ? 'rotate(180deg)' : 'rotate(0deg)')};
`

export const ViewTools = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
`
export const HideTools = styled(ViewTools)`
  margin-top: 16px;
  img {
    transform: rotate(180deg);
  }
`

export const ToolsList = styled.div`
  font-size: 16px;
`

export const ToolItem = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #CCD4D8;
`

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  background-color: #1a1a1a;
  color: white;
  border-radius: 8px;
`

export const Input = styled.input`
  ${inputStyles};
  margin-bottom: 15px;
  height: 48px;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid var(--border-secondary);
  background-color: var(--canvas-primary);
  color: white;
  font-size: 14px;
  ${(props) => props.isWarningVisible && 'border: 1px solid #FF8064;'}
  &:focus {
    border: 1px solid #42c9b3;
    background-color: var(--canvas-primary) !important;
  }
`

export const SubTitle = styled.h2`
    color: #98A4AE;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`

export const Label = styled.label`
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 400;
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const InfoText = styled.p`
  margin-top: 8px;
  color: var(--text-dark-off);
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`
export const ButtonContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: flex-start;
  @media (max-width: 768px) {
    flex-direction: row-reverse;
  }
`

export const WarningMessage = styled.div`
  display: flex;
  align-items: center;
  color: var(--error-color);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 8px;
`

export const InfoContainer = styled.div`
  min-height: 429px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  color: #CCD4D8;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const InfoTitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 8px;
`

export const GoBackButton = styled.div`
  color: var(--primary-color);
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  & > img {
    width: 16px;
    height: 16px;
  }
`

export const UserList = styled.ul`
  list-style: none;
  padding: 0;
`

export const UserListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

export const UserAction = styled.div`
  position: relative;
`

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0A2A2D;
  border: none;
  cursor: pointer;
  color: var(--primary-color);
  padding: 8px;
  border-radius: 5px;

  &.active {
    opacity: 0.3;
    background: #214B52;
    outline: none;
  }
`

export const DropdownMenu = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% + 14px);;
  background: #333;
  border-radius: 4px;
  overflow: hidden;
  width: 250px;
  z-index: 1;
  @media (max-width: 768px) {
    right: calc(100% + 14px);
    left: unset;
  }
  
`

export const DropdownMenuItem = styled.div`
  display: flex;
  justify-content: stretch;
  align-items: center;
  background: none;
  border: none;
  color: #fff;
  width: 100%;
  padding: 8px 16px;
  cursor: pointer;
  gap: 16px;
  margin: 8px 0;

  &:hover {
    border-radius: 5px;
    border: 1px solid var(--primary-color);
  }
`

export const ModalContent = styled.div`
  background: #1D1D1F;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  ${(props) => props.isLoading && 'position: relative; min-height: 200px;'}
`

export const EmailText = styled.div`
  color: #98A4AE;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
`

export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`

export const PermissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`

export const PermissionsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (max-width: 768px) {
    gap: 16px;
  }
`

export const PermissionsGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  margin-top: 12px;
  flex-wrap: wrap;
`

export const PermissionCategory = styled.div`
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 205px;
`
export const SubCheckBox = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

export const CheckboxDescription = styled.div`
  color: #98A4AE;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  margin-left: 32px;
`

export const DeleteUser = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
`

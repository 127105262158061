import React from 'react'
import NotificationReviewAndUpdate from '../ReviewAndUpdateComponent'

const NotificationReview = ({ routerProps }) => {
  return (
    <>
      <NotificationReviewAndUpdate
        routerProps={routerProps}
        headerText='Review notification'
      />
    </>
  )
}

export default NotificationReview

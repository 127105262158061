export const tooltipTexts = {
  expiryDate: {
    text1: "Set the date at which these relationship's tokens will expire.",
    text2:
        ' If this is not filled, tokens will use our non-relationship logic (expire 2 years from issue).'
  },
  returnTo: {
    text1:
        'This sets who will receive any token returns in the case of Drops, etc.'
  },
  refund: {
    text1:
        'Sets whether or not the token can be returned for a cash refund if not in use.',
    text2: `If not set, this will default to "nonRefundable", 
        which differs from our non-relationship tokens (which are set to "studentRefundable" by default).`
  }
}

export const getTooltipText = (text1, text2) =>
  `<div style=" font-family: 'Lato'; width: 250px; height: auto; padding: 4px">
      <p style="margin: 0px 0px ${text2 ? '10px' : '0px'} 0px; font-size: 16px;">${text1}</p>
      ${text2 ? `<p style="margin-bottom: 0px; font-size: 16px;">${text2}</p>` : ''}
   </div>`

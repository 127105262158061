import React from 'react'
import Button from '../../Button/Button'
import { ActionButtonStyle } from '../style'
import { ErrorDescription, ErrorTitile, StyledWrapper } from './style'
import { PageTitle, Separator } from '../../global.styled'

const ErrorMessage = ({ handleGoBack, errorMessageText }) => {
  const message = errorMessageText ||
    'Oh no! There was a network error while processing the enrollment.'
  return (
    <StyledWrapper>
      <PageTitle>Enroll a Student</PageTitle>
      <Separator />
      <ErrorTitile>
        {message}
      </ErrorTitile>
      <ErrorDescription>
        Go back to resubmit or contact Product for further assistance.
      </ErrorDescription>
      <Button
        data-testid='go-back-button'
        onClick={handleGoBack}
        className={{
          'btn-custom': true,
          'btn-primary': true
        }}
        style={ActionButtonStyle}
      >
        Go Back
      </Button>
    </StyledWrapper>
  )
}

ErrorMessage.displayName = 'ErrorMessage'
export default ErrorMessage

import React, { useState } from 'react'
import { Container, Heading, FormContainer, Header } from './styles'
import FormTypeSelection from './FormTypeSelection'
import SearchCriteria from './SearchCriteria'
import Confirmation from './Confirmation'
import { SelectionButton } from '../CSVDownloadTool/style'
import api from '../../api'
import { getDateString } from '../../utilities/dateTimeUtil'

const Description = () => {
  return (
    <>
      <p data-testid='contentGatingDescription'>
        Once you turn on content gating, the selected students or cohorts will
        be required to:
      </p>
      <ul>
        <li>
          Complete Guesswork to unlock Active Learning, Lectures, and Practice
          Terms.
        </li>
        <li>
          Show mastery on Practice Terms and engage with Lectures and Active
          Learning will unlock Quizzes.
        </li>
        <li>Take at least 1 Quiz to unlock the next chapter.</li>
      </ul>
    </>
  )
}

const INITIAL_STATE = {
  course: '',
  cohort: '',
  students: [],
  error: false,
  startDate: '',
  endDate: ''
}

const ContentGating = () => {
  const [selection, setSelection] = useState(null)
  const [state, setState] = useState({ ...INITIAL_STATE })
  const [step, setStep] = useState(0)

  const {
    cohort,
    error,
    students
  } = state

  const renderStep = () =>
    ({
      0: (
        <FormTypeSelection
          setStep={setStep}
          step={step}
          selection={selection}
          setSelection={setSelection}
        />
      ),
      1: (
        <SearchCriteria
          state={state}
          onSubmit={onSubmit}
          handleChange={handleChange}
          setStep={setStep}
          step={step}
          selection={selection}
        />
      ),
      2: <Confirmation
        setStep={setStep}
        students={students}
        cohort={cohort}
        isCohort={selection === 'cohort'}
        error={error}
        resetState={resetState}
        selection={selection}
      />
    }[step])

  const handleChange = (name, value) =>
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }))

  const resetState = () => {
    setState({ ...INITIAL_STATE })
    setSelection(null)
  }

  const getValues = (args) => args.map(({ value }) => value)

  const onSubmit = async () => {
    const {
      cohort: { value = '' },
      students,
      startDate,
      endDate
    } = state
    const dateFormat = 'MM-DD-YYYY'
    const res =
      selection === 'cohort'
        ? await api.enableContentGatingForCohort(value)
        : await api.enableContentGatingForStudents(
          value,
          getValues(students),
          {
            start: getDateString(startDate, dateFormat),
            end: getDateString(endDate, dateFormat)
          }
        )
    if (!res) handleChange('error', true)
    const {
      updatedStudents = []
    } = res || {}
    handleChange('students', updatedStudents)
    setStep(step + 1)
  }

  const isEnabled = selection === 'students' ? !!students.length : !error
  return (
    <Container>
      <Heading>Content Gating</Heading>
      <FormContainer>
        {selection === 'cohort' && step === 1 && (
          <Header>
            <span>Selected Search Criteria</span>
            <SelectionButton onClick={() => setStep(0)}>
              EDIT CRITERIA
            </SelectionButton>
          </Header>
        )}
        {step === 2 && isEnabled && (
          <Header selection={selection} step={step} marginBottom='11px'>
            <span className='last-step-heading'>Content Gating Enabled</span>
          </Header>
        )}
        {((selection === 'students' && step === 0) || step === 1) && (
          <Description />
        )}
        {renderStep()}
      </FormContainer>
    </Container>
  )
}

export default ContentGating

import {
  ENROLLED, PRE_DROP, SIGNED_UP
} from '../../../Constants/studentStatus'

export const PAGES = {
  selection: 'selection',
  review: 'review'
}

export const initialFiltersData = {
  institutions: [],
  allCourses: [],
  courses: [],
  cohorts: [],
  relationships: [],
  statuses: []
}

export const initialSelectedData = {
  institution: null,
  course: null,
  cohort: null,
  relationship: null,
  statuses: {},
  cohortStudents: [],
  filteredStudents: [],
  selectedStudents: []
}

export const studentsTableHeaders = [
  'name',
  'email',
  'current status'
]

export const COURSE_DETAILS = [
  { label: 'Credit-granting institution', value: 'institution' },
  { label: 'Course', value: 'course' },
  { label: 'Cohort', value: 'cohort' },
  { label: 'Relationship', value: 'relationship' }
]

export const EXCLUDED_UPDATE_STATUSES = [
  ENROLLED,
  PRE_DROP,
  SIGNED_UP
]

import React from 'react'
import { ProgressContainer, StyledWrapper } from './style'

const ProgressBar = () => {
  return (
    <StyledWrapper>
      <p>Enrolling the student, please wait...</p>
      <ProgressContainer>
        <div className='enroll-progress-bar'>
          <div className='enroll-progress-bar-value' />
        </div>
      </ProgressContainer>
    </StyledWrapper>
  )
}

ProgressBar.displayName = 'ProgressBar'

export default ProgressBar

import { mapKeys, omit } from 'lodash'
import { DB_VIEWER_PREFERENCES } from '../Constants/databaseViewer'

/**
 * @param key
 * @param value
 * @description This is a temporary solution using localStorage to store the
 * data that will be sent to the backend. This is added to avoid timing sync
 * issue between AT and PostgreSQL. This will be removed once we migrate all tables to PostgreSQL.
 */
export const manageLocalStorage = (key, value) => {
  if (key === 'updatedRows') {
    const updatedRows = localStorage.getItem('updatedRows')
    if (updatedRows) {
      const updatedRowsArray = JSON.parse(updatedRows)
      if (updatedRowsArray.find(row => row.id === value.id)) {
        const updatedRowsArrayWithoutOldRow = updatedRowsArray.filter(row => row.id !== value.id)
        const updatedRowsArrayWithNewRow = [...updatedRowsArrayWithoutOldRow, value]
        localStorage.setItem('updatedRows', JSON.stringify(updatedRowsArrayWithNewRow))
      } else {
        const updatedRowsArrayWithNewRow = [...updatedRowsArray, value]
        localStorage.setItem('updatedRows', JSON.stringify(updatedRowsArrayWithNewRow))
      }
    } else {
      localStorage.setItem('updatedRows', JSON.stringify([value]))
    }
  }
  if (key === 'newRows') {
    const newRows = localStorage.getItem('newRows')
    if (newRows) {
      const newRowsArray = JSON.parse(newRows)
      const newRowsArrayWithNewRow = [...newRowsArray, value]
      localStorage.setItem('newRows', JSON.stringify(newRowsArrayWithNewRow))
    } else {
      localStorage.setItem('newRows', JSON.stringify([value]))
    }
  }

  if (key === 'deletedRows') {
    const deletedRowsIds = localStorage.getItem('deletedRows')
    if (deletedRowsIds) {
      const deletedRowsIdsArray = JSON.parse(deletedRowsIds)
      if (!deletedRowsIdsArray.find(row => row === value)) {
        const deletedRowsIdsArrayWithNewRow = [...deletedRowsIdsArray, ...value]
        localStorage.setItem('deletedRows', JSON.stringify(deletedRowsIdsArrayWithNewRow))
      }
    } else {
      localStorage.setItem('deletedRows', JSON.stringify(value))
    }
  }

  clearLocalStorageInOneMinutes()
}

const clearLocalStorageInOneMinutes = () => {
  console.info('clearing local storage in one minute')
  setTimeout(() => {
    localStorage.removeItem('updatedRows')
    localStorage.removeItem('newRows')
    localStorage.removeItem('deletedRows')
  }, 60000)
}

export const isEmailValid = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regex.test(String(email).toLowerCase())
}

export function getRowsFromDataTable (dataTable) {
  if (!dataTable?.length) return []
  const rowData = []
  dataTable.forEach(row => {
    const { fields, id } = row
    const filteredData = omit(fields,
      [
        '_sync_inserted_at',
        '_sync_updated_at',
        'created_time',
        'createdOn',
        'createdAt'
      ])
    const data = mapKeys(filteredData, (value, key) => {
      if (key === 'id') return 'id_'
      return key
    })
    data.id = id
    rowData.push(data)
  })
  return rowData
}

export function getHeadersFromDataRows (dataRows) {
  if (!dataRows?.length) return []
  const firstRow = { ...dataRows[0] }
  delete firstRow['id']
  return Object.keys(firstRow).map((key) => ({
    id: key
  }))
}

export function getDBViewerPreferences () {
  return JSON.parse(localStorage.getItem(DB_VIEWER_PREFERENCES)) || {}
}

export function saveDBViewerPreferences (value) {
  const preferences = getDBViewerPreferences()
  localStorage.setItem(DB_VIEWER_PREFERENCES, JSON.stringify({ ...preferences, ...value }))
}

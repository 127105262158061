import React from 'react'
import PropTypes from 'prop-types'
import { GoToPreviousPageWrapper } from './style'
import CaretImg from '../../assets/icons/back-arrow-caret.svg'

const GoToPreviousPageButton = ({ text, handleClick }) => {
  return (
    <GoToPreviousPageWrapper onClick={handleClick}>
      <img src={CaretImg} alt='go back' />
      <p>{text}</p>
    </GoToPreviousPageWrapper>
  )
}

GoToPreviousPageButton.propTypes = {
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
}

export default GoToPreviousPageButton

import React, { useEffect, useState } from 'react'
import { ToastClose, ToastHeader, ToastMessage, ToastWrapper } from './style'
import circleChecked from '../../../assets/icons/circle-checked.svg'

const ExtensionToast = ({ extensionToastMessage }) => {
  const [showToast, setShowToast] = useState(true)
  useEffect(() => {
    const id = setTimeout(() => {
      setShowToast(false)
    }, 5000)

    return () => clearTimeout(id)
  }, [extensionToastMessage])

  const extensionMessage = extensionToastMessage === 'created'
    ? 'Extension created!'
    : 'Extension updated'

  return (
    <ToastWrapper showToast={showToast}>
      <ToastHeader>
        <ToastMessage>
          <img src={circleChecked} alt='check' />
          <span>{extensionMessage}</span>
        </ToastMessage>
        <ToastClose onClick={() => setShowToast(false)} />
      </ToastHeader>
    </ToastWrapper>
  )
}
ExtensionToast.displayName = 'ExtensionToast'
export default ExtensionToast

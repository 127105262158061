import styled from 'styled-components'

export const StyledImageTitle = styled.div`
  background-color: #000000;
  color: rgba(255, 255, 255, 0.6);
  display: block;
  padding: 5px;
  text-align: right;
  font-size: 14px;
  cursor: text;
  word-break: break-word;

  a {
    text-decoration: none;
  }
`

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
`

import React from 'react'
import { useTable } from 'react-table'

import { ErrorTableStyle } from './style'

const ErrorTable = props => {
  const {
    columns,
    data,
    refProp
  } = props

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns,
    data
  })

  return (
    <ErrorTableStyle ref={refProp}>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  const { original } = row
                  const { prop } = cell.column
                  const errorClass = original['Error Columns'].includes(prop)
                    ? 'cell-error' : ''
                  const nullClass = cell.value === 'Null' ? 'null-cell' : ''
                  const cellClassName = `${errorClass} ${nullClass}`
                  return <td {...cell.getCellProps([
                    { className: cellClassName }
                  ])}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </ErrorTableStyle>
  )
}

ErrorTable.displayName = 'ErrorTable'
export default ErrorTable

import React, { useState, useMemo } from 'react'
import Select, { components } from 'react-select'
import { useHistory } from 'react-router-dom'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import {
  StyledContainer,
  DropDownWrapper,
  DropDownLabel,
  ResetButton,
  StyledButtonWrapper,
  StyledFiltersWrapper
} from './styled'

import {
  VoucherListContainer,
  Header,
  Cell,
  Table
} from '../StudentLevel/styled'

import {
  CheckedBox,
  CustomCheckBox,
  CustomOptionContainer
} from '../../WritingGradeCenter/styled'

import { StyledCustomButton } from '../styled'
import { getVoucherStatuses } from '../../../utilities/voucher'
import { dropDownStyles } from '../../global.styled'

const HEADERS = [
  {
    label: 'Name',
    key: 'studentName'
  },
  {
    label: 'Student ID',
    key: 'studentID'
  },
  {
    label: 'Email',
    key: 'studentEmail'
  },
  {
    label: 'Voucher Status',
    key: 'status'
  },
  {
    label: 'action',
    key: 'button'
  }
]

const ListLevel = props => {
  const { students: { isLoading, records: studentRecords } } = props
  const history = useHistory()
  const [selectedStatus, setSelectedStatus] = useState(getVoucherStatuses())

  const getButtonProps = student => {
    return (
      <StyledCustomButton
        className='btn-custom btn-primary'
        onClick={() => navigateToStudentPage(student.studentID)}
      >
        View
      </StyledCustomButton>
    )
  }

  const voucherStatuses = useMemo(() => {
    return getVoucherStatuses()
  }, [])

  const formatData = () => {
    return studentRecords.map((node) => ({
      ...node,
      button: getButtonProps(node)
    }))
  }

  const formattedStudents = useMemo(() => {
    return formatData()
    // eslint-disable-next-line
  }, [studentRecords])

  const filteredStudents = useMemo(() => {
    const selectedStatuses = selectedStatus.map(status => status.value)

    return formattedStudents.filter(
      student => selectedStatuses.includes(student.status)
    )
  }, [selectedStatus, formattedStudents])

  const handleReset = () => {
    setSelectedStatus(voucherStatuses)
  }

  const navigateToStudentPage = studentID => history.push(`/voucher/${studentID}`)

  // custom option for react-select
  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <CustomOptionContainer>
          <span>{children}</span>
          <CheckedBox type='checkbox' defaultChecked={props.isSelected} />
          <CustomCheckBox className='check-mark' />
        </CustomOptionContainer>
      </components.Option>
    )
  }

  // custom value container for react-select
  const ValueContainer = props => {
    const { getValue, hasValue, children, options } = props
    const newChildren = [...children]
    const selectedValuesCount = getValue().length
    const singleSelectedValue = getValue()[0]
    const allSelected = selectedValuesCount === options.length

    const getChildren = () => {
      if (!hasValue) {
        return newChildren
      } else if (selectedValuesCount === 1) {
        newChildren[0] = singleSelectedValue.label
      } else {
        newChildren[0] = `${allSelected ? 'All' : selectedValuesCount} Selected`
      }
      return newChildren
    }

    return (
      <components.ValueContainer {...props}>
        {getChildren()}
      </components.ValueContainer>
    )
  }

  return (
    <StyledContainer id='voucher-list-level'>
      <StyledFiltersWrapper>
        <DropDownWrapper>
          <DropDownLabel>
            Filters
          </DropDownLabel>
          <ResetButton onClick={handleReset}>
            Reset
          </ResetButton>
          <Select
            options={voucherStatuses}
            value={selectedStatus}
            onChange={status => setSelectedStatus(status)}
            placeholder='Select Voucher Status'
            styles={dropDownStyles}
            components={{ Option, ValueContainer }}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isClearable={false}
            isMulti
          />
        </DropDownWrapper>
        <StyledButtonWrapper>
          <StyledCustomButton
            className='btn-custom btn-primary'
          >
            Submit
          </StyledCustomButton>
        </StyledButtonWrapper>
      </StyledFiltersWrapper>
      <VoucherListContainer>
        {
          isLoading
            ? <LoadingSpinner />
            : (
              <Table>
                <thead>
                  <tr>
                    {HEADERS.map(({ label, key }) => (
                      <Header key={key} label={label}>
                        {label}
                      </Header>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {filteredStudents.map((node) => {
                    return (
                      <tr key={node.studentID}>
                        {HEADERS.map(({ key }) => {
                          const isButton = key === 'button'

                          return (
                            <Cell
                              {...(isButton && { width: '100' })}
                              className='cell'
                              keyName={key}
                              key={node.studentID + key}
                            >
                              {node[key]}
                            </Cell>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            )
        }
      </VoucherListContainer>
    </StyledContainer>
  )
}

export default ListLevel

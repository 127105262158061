import React, { useState, useEffect } from 'react'
import isEqual from 'lodash/isEqual'
import { PAGE_TYPE } from '../../Constants/CSVDownloadToolV2'
import { getPresetType } from '../../utilities/CSVDownloadToolV2'
import StudentCriteria from './StudentCriteria'
import ExportedInformation from './ExportedInformation'

const CSVDownloadV2 = ({ routerProps: { match, history } }) => {
  const { pageType } = match.params
  const [criteria, setCriteria] = useState({})

  const {
    usePreset,
    studentIdentification,
    studentContactInfo,
    otherStudentInfo,
    cohortInfo,
    gradeDetails,
    loginActivity,
    selectedPreset
  } = criteria

  useEffect(() => {
    handleCriteriaChange({
      type: getPresetType(usePreset, selectedPreset)
    })
    // eslint-disable-next-line
  }, [usePreset, selectedPreset])

  useEffect(() => {
    const selectedCSVInfo = [
      ...(studentIdentification || []),
      ...(studentContactInfo || []),
      ...(otherStudentInfo || []),
      ...(cohortInfo || []),
      ...(loginActivity || []),
      ...(gradeDetails || [])
    ]

    handleCriteriaChange({
      selectedCSVInfo
    })
    // eslint-disable-next-line
  }, [
    studentIdentification,
    studentContactInfo,
    otherStudentInfo,
    cohortInfo,
    loginActivity,
    gradeDetails
  ])

  const handleCriteriaChange = values => {
    const [exposeKey] = Object.keys(values)
    const prevValues = criteria[exposeKey]
    const newValues = values[exposeKey]

    if (isEqual(prevValues, newValues)) return

    setCriteria(prevCriteria => ({
      ...prevCriteria,
      ...values
    }))
  }

  if (pageType === PAGE_TYPE.STUDENT_CRITERIA) {
    return <StudentCriteria
      criteria={criteria}
      handleCriteriaChange={handleCriteriaChange}
    />
  }

  if (pageType === PAGE_TYPE.EXPORTED_INFORMATION) {
    return <ExportedInformation
      criteria={criteria}
      handleCriteriaChange={handleCriteriaChange}
    />
  }
}

CSVDownloadV2.displayName = 'CSVDownloadV2'

export default CSVDownloadV2

import styled from 'styled-components'

export const GoToPreviousPageWrapper = styled.h3`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  width: fit-content;
  cursor: pointer;
  color: var(--brand-regular);

  &:hover {
    text-decoration: none;
    color: var(--brand-regular-hover)!important;
  }

  p {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    margin: 0 0 0 12px;
  }
`

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useHistory, useLocation } from 'react-router-dom'
import {
  EXPORTED_INFORMATION_SELECTION,
  COURSE_SELECTION
} from '../../Constants/CSVDownloadToolOptions'
import {
  PAGE_TYPE,
  EXPOSE_KEYS,
  FETCH_GRADES,
  QUIZ_GRADES,
  FETCH_GRADE_DETAILS
} from '../../Constants/CSVDownloadToolV2'
import {
  Container,
  GradientContainer,
  LabelContainer,
  SelectionButton
} from '../CSVDownloadTool/style'
import CheckboxSelector from '../CheckboxSelector'
import { getDateStringWithComma } from '../../utilities/dateTimeUtil'
import { getFormattedSentenceFromArray } from '../../utilities'
import {
  changeDatesToISOString,
  formatMultiSelectValue,
  getCSVFileName,
  getZIPFileName
} from '../../utilities/CSVDownloadToolV2'
import api from '../../api'
import { getCourseIds } from '../../config'
import DownloadButton from './DownloadButton'
import CSVHeader from './Header'
import { getCohortRangeCourse } from '../../utilities/courseUtils'

const ExportedInformation = props => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    criteria,
    criteria: {
      cohortStartRange,
      cohortEndRange,
      termLengths,
      courses: selectedCourses,
      type,
      selectedCSVInfo,
      studentGrades = [FETCH_GRADES, FETCH_GRADE_DETAILS],
      other,
      creditGrantingInstitutions,
      relationships,
      gradeDetails,
      studentStatus
    },
    handleCriteriaChange
  } = props

  const courses = getCohortRangeCourse(selectedCourses, cohortStartRange)
  const history = useHistory()
  const location = useLocation()

  const studentCriteriaPath = `/csv-download/${PAGE_TYPE.STUDENT_CRITERIA}`

  const { allowView } = location.state || {}
  if (!allowView) {
    history.push(studentCriteriaPath)
    return null
  }

  const handleEditCriteriaClick = () => {
    history.push(studentCriteriaPath)
  }

  const getSelectedStudentCriteriaText = () => {
    const [startDate, endDate] = cohortStartRange || []

    return `
      Your CSV will include students in cohorts starting between
      ${(getDateStringWithComma(startDate))} - ${getDateStringWithComma(endDate)}
      for ${(getFormattedSentenceFromArray(courses.map(getCourseNameFromId)))}
      in ${getFormattedSentenceFromArray(termLengths)} term lengths 
      with the statuses of ${getFormattedSentenceFromArray(studentStatus)}.
    `
  }

  const getCourseNameFromId = courseId => {
    const { options } = COURSE_SELECTION

    const course = options.find(({ value }) => value === courseId) || {}

    return course.name
  }

  const handleDownloadClick = async () => {
    const { createZIP = [] } = criteria
    const shouldEnforceZIP = (courses.length > 1) && gradeDetails && gradeDetails.length
    const shouldGenerateZIP = createZIP.includes(true) || shouldEnforceZIP

    setIsLoading(true)
    if (shouldGenerateZIP) {
      await downloadZIP()
      setIsLoading(false)
      return
    }

    await downloadCSV()
    setIsLoading(false)
  }

  const getCSVData = async courses => {
    const startRangeISOString = changeDatesToISOString(cohortStartRange) || []
    const endRangeISOString = changeDatesToISOString(cohortEndRange) || []

    const body = {
      courses,
      termLengths,
      ...(startRangeISOString.length && { cohortStartRange: startRangeISOString }),
      ...(endRangeISOString.length && { cohortEndRange: endRangeISOString }),
      type,
      format: 'csv',
      studentStatus,
      studentGrades,
      other,
      creditGrantingInstitutions: formatMultiSelectValue(creditGrantingInstitutions),
      relationships,
      selectedCSVInfo
    }

    const csvData = await api.getFilteredStudentsV2(body)
    return csvData
  }

  const downloadCSV = async () => {
    const csvData = await getCSVData(courses)
    const fileName = getCSVFileName(cohortStartRange)

    const blob = new Blob([csvData], { type: 'text/plain;charset=utf-8' })
    saveAs(blob, fileName)
  }

  const downloadZIP = async () => {
    const courseData = {}

    await Promise.all(courses.map(async (course) => {
      const csvData = await getCSVData([course])
      const fileName = `${course}_${getCSVFileName(cohortStartRange)}`

      courseData[fileName] = csvData
    }))

    const zip = new JSZip()
    for (const [fileName, csvData] of Object.entries(courseData)) {
      zip.file(fileName, csvData)
    }

    const zipContent = await zip.generateAsync({ type: 'blob' })
    saveAs(zipContent, getZIPFileName(cohortStartRange))
  }

  const getDisabledOptions = selection => {
    const {
      exposeKey
    } = selection

    const isQuizGradesAllSelected = (gradeDetails || []).includes(QUIZ_GRADES.ALL)
    const isQuizGradesHighestSelected = (gradeDetails || []).includes(QUIZ_GRADES.HIGHEST)

    const isPhilosophySelected = (courses || []).includes(getCourseIds().philosophy)

    if (exposeKey === EXPOSE_KEYS.GRADE_DETAILS) {
      const disabledFieldValues = []
      if (!isPhilosophySelected) {
        disabledFieldValues.push('Writing assignment grades')
      }

      if (isQuizGradesAllSelected) {
        disabledFieldValues.push(QUIZ_GRADES.HIGHEST)
      }

      if (isQuizGradesHighestSelected) {
        disabledFieldValues.push(QUIZ_GRADES.ALL)
      }

      return disabledFieldValues
    }

    return []
  }

  const disableDownload = !selectedCSVInfo || !selectedCSVInfo.length || isLoading

  return (
    <Container>
      <CSVHeader pageType={PAGE_TYPE.EXPORTED_INFORMATION} />

      <GradientContainer>
        <LabelContainer>
          <label>Selected Student Criteria</label>

          <SelectionButton
            onClick={handleEditCriteriaClick}
          >
            Edit Criteria
          </SelectionButton>
        </LabelContainer>

        <p>
          {getSelectedStudentCriteriaText()}
        </p>
      </GradientContainer>
      <p>
        Only items selected in this list will be included in the exported document.
      </p>
      {
        EXPORTED_INFORMATION_SELECTION.map((selection, i) => {
          const {
            label,
            exposeKey,
            getOptions,
            showButtons,
            options
          } = selection

          const disabledOptions = getDisabledOptions(selection)

          return <CheckboxSelector
            key={exposeKey + i}
            exposeKey={exposeKey}
            label={label}
            options={options}
            getOptions={getOptions}
            values={criteria}
            disabledOptions={disabledOptions}
            showButtons={showButtons}
            setValue={handleCriteriaChange}
          />
        })
      }
      <DownloadButton
        isLoading={isLoading}
        disableButton={disableDownload}
        handleClick={handleDownloadClick}
      />
    </Container>
  )
}

ExportedInformation.propTypes = {
  criteria: PropTypes.object,
  handleCriteriaChange: PropTypes.func
}

ExportedInformation.displayName = 'ExportedInformation'

export default ExportedInformation

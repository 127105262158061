import React, { useEffect, useState } from 'react'
import { components } from 'react-select'
import {
  useCoursesActions,
  useCoursesContext } from '../../contexts/Courses'
import {
  SubHeading,
  FiltersContainer,
  ResetFilterButton,
  FilterLabel,
  CustomOptionContainer,
  CheckedBox,
  CustomCheckBox,
  cohortSelectStyles
} from '../WritingGradeCenter/styled'
import {
  ExamFilterWrapper,
  ExamSelectFiltersContainer,
  SubHeadingMarginBottom
} from '../ExamGradeBook/styled'
import {
  yellowdigCohorts,
  yellowdigReleaseDate } from '../../config'
import {
  isAuditCohort,
  getCohortStartSecondsSinceEpoch,
  mergeCohortsOfDuplicateCourses
} from '../../utilities/courseUtils'
import YellowdigButton from './YellowdigButton'
import { CourseDropdownContainer, CustomSelect } from './styled'
import { courseSelectStyles } from '../global.styled'

const Filters = props => {
  const {
    currentCohorts,
    setCurrentCohorts,
    currentCourse,
    setCurrentCourse
  } = props

  const [isLoading, setIsLoading] = useState(true)
  const [courses, setCourses] = useState([])
  const [defaultCohorts, setDefaultCohorts] = useState([])

  const { allCourses } = useCoursesContext()
  const { fetchCourses } = useCoursesActions()

  useEffect(() => {
    if (!allCourses || !allCourses.length) {
      fetchCourses()
      return
    }
    const filteredCourses = mergeCohortsOfDuplicateCourses(allCourses)

    setCourses(
      filteredCourses.filter(course => course.cohorts && course.cohorts.length)
        .map(course => {
          return {
            ...course,
            label: course.name,
            value: course.id
          }
        })
    )
    setIsLoading(false)
    // eslint-disable-next-line
  }, [allCourses])

  useEffect(() => {
    handleResetFilter()
    // eslint-disable-next-line
  }, [defaultCohorts])

  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <CustomOptionContainer>
          <span>{children}</span>
          <CheckedBox type='checkbox' defaultChecked={props.isSelected} />
          <CustomCheckBox className='check-mark' />
        </CustomOptionContainer>
      </components.Option>
    )
  }

  const ValueContainer = props => {
    const { getValue, hasValue, children, options } = props

    const newChildren = [...children]
    const selectedValuesCount = getValue().length
    const singleSelectedValue = getValue()[0]
    const allSelected = selectedValuesCount === options.length

    const getChildren = () => {
      if (!hasValue) {
        return newChildren
      } else if (selectedValuesCount === 1) {
        newChildren[0] = singleSelectedValue.label
      } else {
        newChildren[0] = `${allSelected ? 'All' : selectedValuesCount} Selected`
      }
      return newChildren
    }

    return (
      <components.ValueContainer {...props}>
        {getChildren()}
      </components.ValueContainer>
    )
  }

  const setCohortsByCourse = course => {
    const { cohorts } = course

    if (!cohorts || !cohorts.length) return

    const cohortsForSelect = cohorts
      .filter(cohort => {
        const { dateStart, id: cohortId } = cohort
        if (!dateStart || isAuditCohort(cohort)) return false

        const cohortStartDate = getCohortStartSecondsSinceEpoch(cohort)
        return yellowdigReleaseDate <= cohortStartDate ||
          yellowdigCohorts.includes(cohortId)
      }).map(cohort => {
        const { name } = cohort
        return {
          ...cohort,
          label: name,
          value: name
        }
      }).sort((a, b) => new Date(a.dateStart) - new Date(b.dateStart))

    setDefaultCohorts(cohortsForSelect)
  }

  const handleCourseChange = course => {
    setCurrentCourse(course)
    setCohortsByCourse(course)
  }

  const handleResetFilter = () => {
    setCurrentCohorts(defaultCohorts)
  }

  const { name: courseName } = currentCourse || {}

  const isGGUorEXTCourse = courseName?.toLowerCase()?.includes('ggu') ||
    courseName?.toLowerCase()?.includes('ext')

  return (
    <>
      <SubHeadingMarginBottom hasCourse={currentCourse}>
        {currentCourse
          ? 'Course'
          : 'Select a course to view its cohorts.'
        }
      </SubHeadingMarginBottom>
      <CourseDropdownContainer>
        <CustomSelect
          options={courses}
          value={currentCourse}
          isLoading={isLoading}
          onChange={selectedCourse => {
            handleCourseChange(selectedCourse)
          }}
          id='courseSelectBox'
          placeholder='Select Course'
          styles={courseSelectStyles}
          classNamePrefix='select'
        />
        {currentCourse && !isGGUorEXTCourse &&
          <YellowdigButton
            courseId={currentCourse.id}
            yellowdigIncluded={currentCourse.yellowdigIncluded}
            type='community'
          />}
      </CourseDropdownContainer>
      {currentCourse && (
        <>
          <FiltersContainer>
            <SubHeading hasCourse={currentCourse}>Filters</SubHeading>
            <ResetFilterButton onClick={handleResetFilter}>
              reset filters
            </ResetFilterButton>
          </FiltersContainer>
          <ExamSelectFiltersContainer>
            <ExamFilterWrapper>
              <FilterLabel>Cohort</FilterLabel>
              <CustomSelect
                options={defaultCohorts}
                value={currentCohorts}
                isSearchable
                onChange={cohorts => {
                  setCurrentCohorts(cohorts)
                }}
                id='cohortSelectBox'
                components={{ Option, ValueContainer }}
                placeholder='Filter By Cohort'
                styles={cohortSelectStyles}
                hideSelectedOptions={false}
                closeMenuOnSelect={false}
                isClearable={false}
                isMulti
                classNamePrefix='select'
              />
            </ExamFilterWrapper>
          </ExamSelectFiltersContainer>
        </>
      )}
    </>
  )
}

export default Filters

import styled from 'styled-components'

export const ActiveLearningExportContainer = styled.div`
  padding: 15px;
`

export const Label = styled.div`
  margin: 15px 0 8px;
`

export const WarningText = styled.p`
  color: #E1774F;
  margin: 10px 0;
`

export const courseSelectStyles = {
  indicatorSeparator: styles => ({ display: 'none' }),
  control: (base, state) => ({
    ...base,
    width: '400px',
    height: '40px',
    background: ' rgba(0, 0, 0, 0.3)',
    color: 'white',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    marginBottom: '20px',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid rgba(255, 255, 255, 0.3)'
    }
  }),
  menu: base => ({
    ...base,
    width: '400px',
    background: ' #060606',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
    borderRadius: '5px',
    marginTop: '15px'
  }),
  menuList: base => ({
    ...base,
    width: '100%',
    height: '100%',
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    width: '100%',
    backgroundColor: !state.isSelected ? '#060606' : 'rgba(255, 255, 255, 0.1)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    cursor: 'pointer',
    fontSize: '18px',
    lineHeight: '22px',
    '&:last-child': {
      borderBottom: 'none',
      borderRadius: '0 0 5px 5px'
    },
    '&:first-of-type': {
      borderRadius: '5px 5px 0 0'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  }),
  placeholder: base => ({
    ...base,
    color: 'white',
    marginLeft: '5px',
    fontSize: '18px',
    lineHeight: '22px'
  }),
  singleValue: base => ({
    ...base,
    color: 'white',
    paddingLeft: '6px',
    margin: 0,
    fontSize: '18px',
    lineHeight: '22px'
  }),
  dropdownIndicator: base => ({
    ...base,
    color: 'white',
    padding: '0 8px 0 0',
    '&:hover': {
      color: 'white'
    }
  }),
  indicatorContainer: base => ({
    ...base,
    paddingLeft: 0
  })
}

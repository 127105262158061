import React from 'react'
import classNames from 'classnames'
import { CustomButton } from './styled'

export default function Button (props) {
  const {
    style,
    className,
    onClick,
    children,
    disabled
  } = props

  return (
    <CustomButton
      style={style}
      className={typeof className === 'string' ? className : classNames({
        ...className
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </CustomButton>
  )
}

import React from 'react'
import { InfoWrapper, StudentInfoItem } from './styled'

const VoucherDetails = ({
  voucherStatus,
  dropDeadline,
  courseName,
  certificateName,
  amazonTransfers
}) => {
  return (
    <InfoWrapper>
      <StudentInfoItem className='student-info__item'>
        <span>Voucher needed for:</span>
        <span>{certificateName || courseName}</span>
      </StudentInfoItem>
      <StudentInfoItem className='student-info__item'>
        <span>Drop Deadline:</span>
        <span>{dropDeadline}</span>
      </StudentInfoItem>
      <StudentInfoItem className='student-info__item'>
        <span>Voucher Status:</span>
        <span>{voucherStatus}</span>
      </StudentInfoItem>
      <StudentInfoItem className='student-info__item'>
        <span>Number of transfers:</span>
        <span>{amazonTransfers && amazonTransfers?.length}</span>
      </StudentInfoItem>
    </InfoWrapper>
  )
}

export default VoucherDetails

import React, { useEffect } from 'react'
import {
  Divider,
  GradingCheckHeaderTitle,
  GradingCheckHeaderWrapper,
  GradingCheckWrapper
} from './style'
import warningIcon from '../../assets/icons/warning-poly.svg'
import GradingIssues from './GradingIssues'
import CoursesView from './CoursesView'
import {
  removeMatchingGrades,
  setMismatchedGradesForCourses
} from './gradingCheckUtils'
import api from '../../api'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'

const GradingCheck = ({ routerProps }) => {
  const history = routerProps?.history
  const [coursesList, setCoursesList] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    getCoursesList()
    // eslint-disable-next-line
  }, [])

  const getCoursesWithGradingIssues = () => {
    return coursesList.filter(course => course?.hasMismatchedGrades)
  }
  const getCoursesList = async () => {
    setIsLoading(true)
    const courses = await api.getGradingCompData()
    const coursesWithoutMatchingGrades = removeMatchingGrades(courses)
    const updatedCourses =
      setMismatchedGradesForCourses(coursesWithoutMatchingGrades)
    setCoursesList(updatedCourses)
    setIsLoading(false)
  }

  if (isLoading) {
    return (
      <div>
        <div className='layout-wrapper'>
          <div className='content'>
            <LoadingSpinner id='loading-spinner' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <GradingCheckWrapper>
      <GradingCheckHeaderWrapper>
        <GradingCheckHeaderTitle>
          Grading System Check
        </GradingCheckHeaderTitle>
        <img src={warningIcon} width={46} alt='' />
      </GradingCheckHeaderWrapper>
      <Divider />
      <GradingIssues coursesWithGradingIssues={getCoursesWithGradingIssues()} />
      <CoursesView coursesList={coursesList} history={history} />
    </GradingCheckWrapper>
  )
}

export default GradingCheck
GradingCheck.displayName = 'GradingCheck'

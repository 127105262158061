export const removeMatchingGrades = (courses) => {
  const newCourses = [...courses]
  newCourses.forEach(course => {
    if (!course?.students) return
    course.students.forEach(student => {
      const atGradeDataKeys = Object.keys(student.atGradeData)

      atGradeDataKeys.forEach(key => {
        if (key !== 'totalGrade' && key !== 'student') {
          const atGrade = student?.atGradeData?.[key]
          const lmsGrade = student?.projectedGrade?.[key]

          if (atGrade === lmsGrade) {
            delete student?.atGradeData?.[key]
            delete student?.projectedGrade?.[key]
          }
        }
      })
    })
  })

  return newCourses
}

export const setMismatchedGradesForCourses = (courses) => {
  const newCourses = [...courses]
  newCourses.forEach(course => {
    if (!course?.students) return
    const students = course.students
    let hasMismatchedGrades = false

    for (const student of students) {
      const atGradeDataKeys = Object.keys(student.atGradeData)

      for (const key of atGradeDataKeys) {
        if (key !== 'totalGrade' && student?.atGradeData?.[key] !== student?.projectedGrade?.[key]) {
          hasMismatchedGrades = true
          break // Exit inner loop if a mismatch is found
        }
      }

      if (hasMismatchedGrades) {
        break // Exit outer loop if a mismatch is found for any student
      }
    }

    // Set the hasMismatchedGrades property for the course
    course.hasMismatchedGrades = hasMismatchedGrades
  })
  return newCourses
}

export const formatHeader = (key) => {
  if (!key?.includes('Grade') && !key?.includes('Percent')) return key

  return key?.includes('Grade')
    ? `${key?.split('Grade')[0]} Grade`
    : `${key?.split('Percent')[0]} Percent`
}

import React from 'react'
import PropTypes from 'prop-types'
import {
  InformationCardWrapper,
  InformationCardButton,
  InformationCardSubTitle,
  InformationCardTitle,
  InformationCardTitleWrapper
} from './style'

// This component is used in student details page to display information
// about student status, final grade, and attendance in card format

export default function InformationCard ({ title, subTitle, buttonDetails }) {
  return (
    <InformationCardWrapper>
      <InformationCardTitleWrapper>
        <InformationCardTitle>{title}</InformationCardTitle>
        {buttonDetails && (
          <InformationCardButton onClick={buttonDetails.handler}>
            {buttonDetails.text}
          </InformationCardButton>
        )}
      </InformationCardTitleWrapper>
      <InformationCardSubTitle>{subTitle}</InformationCardSubTitle>
    </InformationCardWrapper>
  )
}

InformationCard.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  buttonDetails: PropTypes.shape({
    text: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired
  })
}

import React, { useState, useEffect } from 'react'
import GoToPreviousPageButton from '../GoToPreviousPageButton/GoToPreviousPageButton'
import ReviewBulkCohort from './ReviewBulkCohort'
import CustomRadioButton from '../CustomRadioButton/CustomRadioButton'
import { CREDIT_GRANTING_INSTITUTIONS } from '../../Constants/creaditGrantingInstitutions'
import DateCarousel from './DateCarousel'
import cross from '../../assets/icons/cross.svg'
import CourseSelect from './CourseSelect'
import CheckBox from '../CheckBox'
import { DURATION_OPTIONS } from './constants'
import {
  CohortContainer,
  GoBackContainer,
  TitleWrapper,
  Title,
  TabsWrapper,
  Tab,
  Institutions,
  CoursePoolWrapper,
  CoursePoolLabel,
  CoursePool,
  CoursePoolItem,
  Cross,
  InputLabel,
  DurationsWrapper,
  Durations,
  ActionsWrapper,
  ActionText,
  ActionTitle,
  ActionDescription,
  DurationOptionsWrapper,
  TextFieldsWrapper,
  TextField,
  TextFieldContainer,
  ReviewButton
} from './styled'

function CreateBulkCohort ({ routerProps }) {
  const {
    history
  } = routerProps

  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedInstitution, setSelectedInstitution] = useState()
  const [selectedCourses, setSelectedCourses] = useState([])
  const [selectedDates, setSelectedDates] = useState({})
  const [durationInfo, setDurationInfo] = useState({})
  const [price, setPrice] = useState('579')
  const [quantity, setQuantity] = useState('200')

  const [, GGU] = CREDIT_GRANTING_INSTITUTIONS
  const [STANDARD, , EXTENDED] = DURATION_OPTIONS

  useEffect(() => {
    setSelectedCourses([])
    setSelectedDates({})

    if (selectedInstitution === GGU) {
      return setDurationInfo({
        cohortType: STANDARD.checkBoxLabel,
        createAuditCohorts: true,
        linkCohortSchedules: true
      })
    }

    const defaultCohortType = DURATION_OPTIONS.find(duration =>
      duration.default?.includes(selectedInstitution)
    )?.checkBoxLabel

    setDurationInfo({ cohortType: defaultCohortType })
    // eslint-disable-next-line
  }, [selectedInstitution])

  const onInstitutionChange = (value) => setSelectedInstitution(value)

  const onDurationChange = (durationOption) => {
    setDurationInfo({
      cohortType: durationOption.checkBoxLabel,
      ...(durationOption.radioOptions.some(option => option.default) && {
        duration: durationOption.radioOptions.find(option => option.default).value
      }),
      createAuditCohorts: durationOption.actions[0]?.default,
      linkCohortSchedules: durationOption.actions[1]?.default
    })
    setSelectedCourses([])
  }

  const shouldReviewEnable = () => {
    const cohortStartDates = Object.values(selectedDates).reduce((acc, curVal) => {
      acc = [...acc, ...curVal]
      return acc
    }, [])

    const isDurationSelected = durationInfo.duration

    return selectedInstitution &&
    selectedCourses.length &&
    cohortStartDates.length &&
    isDurationSelected
  }

  const shouldYesOrNoCheckboxDisable = (selectedDuration, value) => {
    if (value !== 'linkCohortSchedules') return false
    if (selectedDuration === '15' || selectedDuration === '8') return true
  }

  return <CohortContainer>
    <GoBackContainer>
      <GoToPreviousPageButton
        text='Go Back'
        handleClick={() => history.push('/cohorts')}
      />
    </GoBackContainer>
    <TitleWrapper>
      <Title>
        Create cohorts
      </Title>
    </TitleWrapper>
    <TabsWrapper>
      <Tab
        selected={selectedTab === 0}
        onClick={() => setSelectedTab(0)}
        highlight={selectedTab === 1}
      >
        1 Cohort Details
      </Tab>
      <Tab
        selected={selectedTab === 1}
        onClick={() => setSelectedTab(1)}
        disabled={!shouldReviewEnable()}
      >
        2 Review
      </Tab>
    </TabsWrapper>
    {
      selectedTab === 1
        ? (
          <ReviewBulkCohort
            selectedInstitution={selectedInstitution}
            selectedCourses={selectedCourses}
            selectedDates={selectedDates}
            durationInfo={durationInfo}
            price={price}
            quantity={quantity}
          />)
        : <>
          <Institutions>
            <span>Credit granting institutions</span>
            {CREDIT_GRANTING_INSTITUTIONS.map((institution) => {
              return (
                <div key={institution}>
                  <CustomRadioButton
                    label={institution}
                    name='institution'
                    value={institution}
                    checked={selectedInstitution === institution}
                    onChange={onInstitutionChange}
                  />
                </div>)
            })}
          </Institutions>
          <DurationsWrapper>
            <InputLabel marginBottom='38px'>
              <span>Duration</span>
            </InputLabel>
            {DURATION_OPTIONS.map((option, index) => {
              const { checkBoxLabel, excludeInstitutions } = option
              const { cohortType, duration } = durationInfo || {}
              const shouldDisable = !cohortType
              const checked = durationInfo.cohortType === checkBoxLabel
              const shouldDurationDisable =
                excludeInstitutions?.includes(selectedInstitution) ||
                !selectedInstitution

              return (
                <DurationOptionsWrapper
                  expanded={checked}
                  key={index}
                >
                  <CustomRadioButton
                    label={`${option.checkBoxLabel} cohort`}
                    disabled={shouldDurationDisable}
                    name='cohortType'
                    checked={checked}
                    onChange={() => onDurationChange(option)}
                  />
                  {durationInfo.cohortType === checkBoxLabel && !shouldDurationDisable &&
                  <>
                    <Durations>
                      {option.radioOptions.map((radioOption, i) => (
                        <div key={i}>
                          <CustomRadioButton
                            disabled={shouldDisable || shouldYesOrNoCheckboxDisable()}
                            label={radioOption.label}
                            name={radioOption.name}
                            value={radioOption.value}
                            checked={durationInfo.duration === radioOption.value}
                            onChange={(value) => setDurationInfo(prev => ({
                              ...prev,
                              ...((value === '15' || value === '8') && { linkCohortSchedules: false }),
                              duration: value
                            }))}
                          />
                        </div>
                      ))}
                    </Durations>
                    {option.actions.map((action, actionIndex) => {
                      const { value, title, description } = action
                      return (
                        <ActionsWrapper
                          key={actionIndex}
                          disabled={shouldDisable}
                        >
                          <CheckBox
                            disabled={shouldDisable || shouldYesOrNoCheckboxDisable(duration, value)}
                            value={value}
                            checked={durationInfo[action.value]}
                            onChange={(checked) => setDurationInfo(prev => ({
                              ...prev,
                              [action.value]: !prev[action.value]
                            }))}
                            secondary={shouldYesOrNoCheckboxDisable(duration, value)}
                          />
                          <ActionText disabled={shouldYesOrNoCheckboxDisable(duration, value)}>
                            <ActionTitle>{title}</ActionTitle>
                            <ActionDescription>{description}</ActionDescription>
                          </ActionText>
                        </ActionsWrapper>)
                    })}
                  </>}
                </DurationOptionsWrapper>)
            })}
          </DurationsWrapper>
          <CourseSelect
            selectedCourses={selectedCourses}
            setSelectedCourses={setSelectedCourses}
            isDisabled={!durationInfo.duration}
          />
          {selectedInstitution &&
            <>
              {selectedCourses.length
                ? (
                  <>
                    <CoursePoolWrapper data-testid='course-pool'>
                      <CoursePoolLabel>Courses:</CoursePoolLabel>
                      <CoursePool scrollable>
                        {selectedCourses.map(course => {
                          if (!course.label) return null
                          return (
                            <CoursePoolItem
                              data-testid='course-pool-item'
                              key={course.value}
                            >
                              <span>{course.label}</span>
                              <Cross
                                data-testid='deselect-icon'
                                onClick={() => setSelectedCourses(
                                  selectedCourses.filter(c => c.value !== course.value)
                                )}
                                src={cross}
                                alt='cross-icon' />
                            </CoursePoolItem>
                          )
                        })}
                      </CoursePool>
                    </CoursePoolWrapper>
                    <DateCarousel
                      selectedDates={selectedDates}
                      setSelectedDates={setSelectedDates}
                    />
                    {selectedInstitution !== GGU &&
                    durationInfo.cohortType !== EXTENDED.checkBoxLabel &&
                      <TextFieldsWrapper>
                        <InputLabel>
                          <span>Price</span>
                          <TextFieldContainer>
                            <TextField
                              type='text'
                              value={price}
                              onChange={(e) => setPrice(e.target.value)}
                            />
                            {price.length ? <i onClick={() => setPrice(0)} /> : null}
                          </TextFieldContainer>
                        </InputLabel>
                        <InputLabel showTooltip>
                          <span>Quantity</span>
                          <TextFieldContainer>
                            <TextField
                              type='text'
                              value={quantity}
                              onChange={(e) => setQuantity(e.target.value)}
                            />
                            {quantity.length ? <i onClick={() => setQuantity(0)} /> : null}
                          </TextFieldContainer>
                        </InputLabel>
                      </TextFieldsWrapper>}
                    <ReviewButton
                      disabled={!shouldReviewEnable()}
                      onClick={() => setSelectedTab(1)}
                    >
                      Review
                    </ReviewButton>
                  </>
                )
                : null
              }
            </>}
        </>}
  </CohortContainer>
}

CreateBulkCohort.displayName = 'CreateBulkCohort'
export default CreateBulkCohort

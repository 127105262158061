import React from 'react'
import PropTypes from 'prop-types'
import {
  SingleValue,
  CustomSelect as CustomSelectStyle,
  courseSelectStyles
} from './UpdatedSelect.style'

const CustomSingleValue = ({ selectProps, ...rest }) => {
  const {
    value, options, name, placeholder
  } = selectProps || {}

  const placeholderText = placeholder || 'Select...'

  const getSingleValueText = () => {
    if (!value?.length) return placeholderText

    const isAllSelected = value.length === options.length
    if (isAllSelected) {
      return `All ${name || 'selected'}`
    }

    const isMultipleSelected = value.length > 1
    if (isMultipleSelected) {
      return `${value.length} ${name || 'Selected'}`
    }

    return value[0]?.label
  }

  const singleValueText = getSingleValueText()
  const isPlaceHolder = singleValueText === placeholderText

  return (
    <SingleValue
      {...rest}
      isPlaceHolder={isPlaceHolder}
    >
      {getSingleValueText()}
    </SingleValue>
  )
}

const CustomSelect = ({
  isMulti,
  options,
  onChange,
  ...rest
}) => {
  const components = isMulti ? {
    SingleValue: CustomSingleValue
  } : {}

  return (
    <CustomSelectStyle
      {...rest}
      options={options}
      onChange={onChange}
      components={{ ...rest.components, ...components }}
      classNamePrefix='select'
      styles={courseSelectStyles}
    />
  )
}

CustomSelect.displayName = 'CustomSelect'
CustomSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
}

export default CustomSelect

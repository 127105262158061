import React, { useState } from 'react'
import { toast } from 'react-toastify'
import ModalComponent from '../../../Components/ModalComponent/ModalComponent'
import {
  ButtonContainer,
  CloseIcon,
  CustomTextArea,
  FieldGroup,
  Label,
  ModalBody,
  RadioSelectionContainer,
  ReadyToSubmit,
  SanctionSelect,
  SelectLabel,
  ToastContainer,
  Value
} from './styles'
import { Separator } from '../styles'
import CustomRadioButton from '../../../Components/CustomRadioButton/CustomRadioButton'
import { fields, sanctionOptions } from './constants'
import {
  PrimaryButton,
  SecondaryButton
} from '../../../Components/global.styled'
import { ReactComponent as SuccessIcon } from '../../../assets/icons/icon-success.svg'
import api from '../../../api'

const Toast = () => {
  return (
    <ToastContainer data-testid='record-updated'>
      <SuccessIcon />
      <span>Successfully updated</span>
    </ToastContainer>
  )
}

function ReportModal ({ violation, handleClose, updateViolations }) {
  const [formState, setFormState] = useState({
    approvalStatus: null,
    sanction: null,
    outlierNotes: '',
    denialReason: ''
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { approvalStatus, sanction, outlierNotes, denialReason } = formState

  const handleSubmit = async () => {
    setIsSubmitting(true)
    const { cohort, student, assessment } = violation
    const success = await api.updateAIViolation(
      {
        cohortId: cohort[0].id,
        studentId: student[0].fields.id,
        assessmentName: assessment
      },
      {
        approvalStatus,
        reviewedbySuccess: true,
        ...(approvalStatus === 'Denied' && {
          rejectedOn: new Date().toISOString()
        }),
        ...(sanction && { sanction: sanction?.value }),
        ...(outlierNotes && { outlierNotes }),
        ...(denialReason && { denialReason })
      }
    )
    if (success) {
      updateViolations(violation)
      handleClose()
      toast(<Toast />, {
        className: 'toastContainer',
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        closeButton: <CloseIcon />
      })
    }
    setIsSubmitting(false)
  }

  const getDisabledState = () => {
    if (approvalStatus === 'Approved') {
      return !sanction
    }
    if (approvalStatus === 'Denied') {
      return !denialReason
    }
    return true
  }
  const isSubmittingDisabled = getDisabledState()

  return (
    <ModalComponent
      title='Academic integrity report'
      show
      width='450px'
      isResponsive
      handleClose={handleClose}
    >
      <ModalBody>
        {fields.map(({ label, value }) => (
          <FieldGroup key={label} gap='4px'>
            <Label>{label}</Label>
            <Value>{value(violation) ?? '--'}</Value>
          </FieldGroup>
        ))}
        <Separator space='8px' />
        <RadioSelectionContainer>
          <p>
            Do you want to log this report as part of the student's Outlier
            record?
          </p>
          <CustomRadioButton
            label='Yes'
            name='approvalStatus'
            onChange={() =>
              setFormState({ ...formState, approvalStatus: 'Approved' })
            }
            checked={approvalStatus === 'Approved'}
          />
          <CustomRadioButton
            label='No'
            name='approvalStatus'
            onChange={() =>
              setFormState({ ...formState, approvalStatus: 'Denied' })
            }
            checked={approvalStatus === 'Denied'}
          />
        </RadioSelectionContainer>
        {approvalStatus === 'Approved' && (
          <>
            <FieldGroup gap='8px'>
              <SelectLabel>Sanction</SelectLabel>
              <SanctionSelect
                isClearable
                placeholder='Select a sanction'
                value={sanction}
                options={sanctionOptions}
                onChange={(sanction) => {
                  setFormState({ ...formState, sanction })
                }}
              />
            </FieldGroup>
            <FieldGroup gap='8px'>
              <Label>Notes (optional)</Label>
              <CustomTextArea
                placeholder='Do you have any notes?'
                value={outlierNotes}
                onChange={(event) =>
                  setFormState({
                    ...formState,
                    outlierNotes: event.target.value
                  })
                }
                data-testid='outlier-notes'
              />
            </FieldGroup>
          </>
        )}
        {approvalStatus === 'Denied' && (
          <FieldGroup gap='8px'>
            <Label>Reason for denying</Label>
            <CustomTextArea
              placeholder='Please provide a Reason for proctor report'
              value={denialReason}
              onChange={(event) =>
                setFormState({
                  ...formState,
                  denialReason: event.target.value
                })
              }
              data-testid='denial-reason'
            />
          </FieldGroup>
        )}
        {!isSubmittingDisabled && (
          <ReadyToSubmit>
            Ready to submit? You will not be able to edit or delete your
            submission.
          </ReadyToSubmit>
        )}
        <ButtonContainer>
          <PrimaryButton
            marginRight='12px'
            onClick={handleSubmit}
            disabled={isSubmittingDisabled || isSubmitting}
          >
            Submit
            {isSubmitting && <i className='fa fa-spinner fa-spin' />}
          </PrimaryButton>
          <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
        </ButtonContainer>
      </ModalBody>
    </ModalComponent>
  )
}

export default ReportModal

import React from 'react'
import ReactPaginate from 'react-paginate'
import './css/index.css'

const Pagination = ({
  numberOfPages,
  selectedPage,
  handlePaginationBtnClick
}) => {
  return (
    <ReactPaginate
      previousLabel='<'
      nextLabel='>'
      breakLabel='...'
      breakClassName='break-me'
      pageCount={numberOfPages}
      forcePage={selectedPage}
      marginPagesDisplayed={1}
      pageRangeDisplayed={3}
      onPageChange={handlePaginationBtnClick}
      containerClassName='pagination'
      subContainerClassName='pages pagination'
      activeClassName='active'
    />
  )
}

export default Pagination

import React, { useState, useMemo } from 'react'
import { useStudentGradeContext } from '../../contexts/StudentGradeContext'
import './StudentGradeSection.css'
import CourseraActivityGradeDetails from './CourseraActivityGradeDetails'

function CourseraActivityGradeSection ({ section }) {
  const { title: activityName, courseraActivityType } = section || {}
  const [expanded, setExpanded] = useState(false)
  const { studentProgress, sectionOverviewData, gradeData } = useStudentGradeContext()

  const sectionScore = useMemo(() => {
    if (!studentProgress) return

    return studentProgress?.courseraActivities?.[activityName]
  }, [activityName, studentProgress])

  const studentSectionData = (sectionOverviewData || [])
    .find(s => s.id === gradeData.id || s.email === gradeData.email)
  const attempts = studentSectionData?.[activityName]?.[activityName] > 0 ? 1 : 0

  return (
    <>
      <div
        className={expanded ? 'table-grid-body expanded' : 'table-grid-body'}
        onClick={() => setExpanded(!expanded)}
      >
        <span className='button-container'>
          <div className={expanded ? 'expand-btn-expanded' : 'expand-btn'} />
        </span>
        <span className='section-container' id='sectionName'>{activityName}</span>
        <span className='header'>Final Score</span>
        <span id='sectionScore'>{isNaN(sectionScore) ? '--' : `${sectionScore}%`}</span>
        <span className='header'>Attempts</span>
        <span id='attempts'>{attempts}</span>
        <span className='header'>Progress</span>
        <span id='progress'>--</span>
      </div>
      {expanded && <div className='details-tr-expanded'>
        <span />
        <span className='details-td'>
          <CourseraActivityGradeDetails score={sectionScore} label={courseraActivityType} />
        </span>
      </div>}
    </>
  )
}

CourseraActivityGradeSection.displayName = 'CourseraActivityGradeSection'

export default CourseraActivityGradeSection

import { AssessmentMessage, Content, Data, DataRow, OldDeadline } from './style'
import React from 'react'
import { diffDays, getDateString } from '../../../utilities/dateTimeUtil'

const SingleAssessmentReview = ({
  assessment,
  newDeadlineDate,
  isEditMode
}) => {
  const formattedLockDate =
    getDateString(assessment.lockTime, 'MM/DD/YY')
  const formattedNewDeadline =
    getDateString(newDeadlineDate, 'MM/DD/YY')
  const numberOfDaysAdded = diffDays(formattedNewDeadline, formattedLockDate)

  return (
    <>
      {!isEditMode && (
        <Content>
          <AssessmentMessage>
          By default, all assessments close at 12pm ET.
          </AssessmentMessage>
        </Content>
      )}
      <Content>
        <DataRow>
          <span>{assessment.examTitle}</span>
        </DataRow>
        <DataRow style={{ paddingLeft: '24px' }}>
          <OldDeadline>{formattedLockDate}</OldDeadline>
          <Data>{`${formattedNewDeadline} (${numberOfDaysAdded} day(s) added)`}</Data>
        </DataRow>
      </Content>
    </>
  )
}
SingleAssessmentReview.displayName = 'SingleAssessmentReview'
export default SingleAssessmentReview

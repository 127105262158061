import React from 'react'
import {
  JumpToLink,
  LinkIcon,
  StudentTokens,
  StudentTokensColumn,
  StudentTokensColumnNumber,
  StudentTokensColumnText,
  StudentTokensSummaryContainer,
  StudentTokensSummaryHeader,
  StudentTokensSummaryHeaderNumber,
  StudentTokensSummaryHeaderText,
  StudentTokensSummaryWrapper
} from './style'
import arrowDownIcon from '../../../assets/icons/arrow-down.svg'
import {
  getTokensCountByStatus
} from './studentRecordDetailsUtils'

const StudentTokensSummary = ({ tokensData, scrollToTokens }) => {
  const tokens = getTokensCountByStatus(tokensData)
  const tokensCount = tokensData.length
  const tokensText = tokensCount === 1 ? 'Token' : 'Tokens'

  return (
    <StudentTokensSummaryWrapper>
      <StudentTokensSummaryContainer>
        <StudentTokensSummaryHeader>
          <StudentTokensSummaryHeaderNumber>{tokensCount}</StudentTokensSummaryHeaderNumber>
          <StudentTokensSummaryHeaderText>{tokensText}</StudentTokensSummaryHeaderText>
        </StudentTokensSummaryHeader>
        <StudentTokens>
          {tokens.map((token, index) => {
            return (
              <StudentTokensColumn key={index}>
                <StudentTokensColumnNumber>{token.count}</StudentTokensColumnNumber>
                <StudentTokensColumnText>{token.statusDisplayName}</StudentTokensColumnText>
              </StudentTokensColumn>
            )
          }
          )}
        </StudentTokens>
        <JumpToLink onClick={() => { scrollToTokens() }}>jump to tokens & Purchases
          <LinkIcon src={arrowDownIcon} alt='arrow-down-icon' />
        </JumpToLink>
      </StudentTokensSummaryContainer>
    </StudentTokensSummaryWrapper>
  )
}
StudentTokensSummary.displayName = 'StudentTokensSummary'
export default StudentTokensSummary

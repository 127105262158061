import { filterNullAndJoin } from './utils'

export const flagsAndReportsHeaders = [
  { title: 'email' },
  { title: 'assessments' },
  { title: 'sanctions received' }
]

export const tables = [
  {
    key: 'warnings',
    title: 'Received a warning',
    description:
      'Student clicked away from exam or approved materials for the first time',
    headers: [
      { title: 'email', accessor: (record) => record.email },
      {
        title: 'assessments',
        accessor: (record) => filterNullAndJoin(record.assessments)
      },
      {
        title: 'sanctions received',
        accessor: (record) => filterNullAndJoin(record.sanctions)
      }
    ]
  },
  {
    key: 'removals',
    title: 'Removed at least once',
    description:
      'Student clicked away from exam or approved materials for the second time',
    headers: [
      { title: 'email', accessor: (record) => record.email },
      {
        title: 'assessments',
        accessor: (record) => filterNullAndJoin(record.assessments)
      },
      {
        title: 'sanctions received',
        accessor: (record) => filterNullAndJoin(record.sanctions)
      }
    ]
  },
  {
    key: 'proctorReports',
    title: 'Reported by facilitator',
    description: null,
    headers: [
      { title: 'email', accessor: (record) => record.email },
      {
        title: 'assessments',
        accessor: (record) => filterNullAndJoin(record.assessments)
      },
      {
        title: 'sanctions received',
        accessor: (record) => filterNullAndJoin(record.sanctions)
      }
    ]
  },
  {
    key: 'deniedRecords',
    title: 'Rejected flags & reports',
    description: null,
    headers: [
      { title: 'email', accessor: (record) => record.email },
      {
        title: 'assessments',
        accessor: (record) => filterNullAndJoin(record.assessments)
      },
      {
        title: 'outlier notes',
        accessor: (record) => filterNullAndJoin(record.outlierNotes)
      }
    ]
  }
]

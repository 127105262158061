import styled from 'styled-components'
import { Input } from 'reactstrap'
import {
  courseSelectStyles,
  CustomDatePicker,
  inputStyles
} from '../../global.styled'
import clear from '../../../assets/icons/clear-icon.svg'
import calendar from '../../../assets/icons/calendar_2.svg'
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg'

export const FormTitle = styled.h1`
  color: #FFF;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 16px;
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
`

export const EnrollStudentForm = styled.div`
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 0 48px;
  align-self: stretch;
  flex-wrap: wrap;
  max-width: 1280px;
`

export const FormLabel = styled.label`
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 12px;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
`

export const FormInput = styled(Input)`
  ${inputStyles};
  background: var(--black-tertiary);
  border: ${props => props.error ? '1px solid #FF8064' : '1px solid var(--border-secondary)'};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 16px;
  color: #B1BFC5;
  font-size: 16px;
  line-height:24px;
  height: auto;
  width: 400px;
  
  &:disabled {
    border: none;
  }
  
  &:hover {
    &:disabled {
      border: none;
    }
    outline: none;
    background: var(--black-tertiary);
    color: #B1BFC5;
    border: 1px solid var(--primary-color);
    box-shadow: none;
  }`

export const FormDescription = styled.div`
  color: #FFFFFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 8px;
  & p {
    font-size: 16px;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 0;
  }
  
  & a {
    font-size: 14px;
    font-weight: 400;
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
  }
`

export const AddStudentButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  &:hover, :active, :focus, :visited {
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: var(--primary-color);
  }
  
  border-radius: 5px;
  background: #0A2A2D;
  color: var(--primary-color);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 14px;
  margin-top: 2px;
  margin-bottom: 24px;
  width: fit-content;
`

export const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
  margin-top: 25px;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const ActionButtonStyle = {
  padding: '16px 24px',
  height: 'auto',
  width: 'auto'
}

export const StudentName = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 4px;
  `

export const StudentTokens = StudentName

export const DotSeparator = styled.span`
  margin: 0 8px;
  color: #98A4AE;
  vertical-align: 3px;
  font-weight: 900;
`

export const Badge = styled.span`
  margin-left: 8px;
  padding: 6px 8px;
  border-radius: 3px;
  background: #30303A;
  color: #FFF;
  text-align: center;
  font-weight: 400;
  line-height: 24px;
  font-size: 12px;
`

export const SoftWarningMessage = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  background-color: #342215;
  color: #FFFFFF;
  padding: 16px;
  max-width: 400px;
  border-radius: 5px;
  margin-bottom: 12px;
  & p {
    margin-bottom: 0;
  }
  & a {
    cursor: pointer;
    text-decoration: underline;
    color: #FFFFFF;
  }
  & > svg {
    min-width: 24px;
    height: 24px;
    align-self: center;
    color: var(--warning-color);
    margin-right: 16px;
  }
`

export const WarningMessage = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FF8064;
  margin-bottom: 8px;
  margin-top: 0;
  max-width: 400px;

  & img {
    margin-right: 8px;
  }

  & p {
    margin-bottom: 0;
  }
`

export const DropDownWrapper = styled.div`
  margin-right: 16px;
  margin-bottom: 48px;  
`

export const customDropDownStyles = {
  ...courseSelectStyles,
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#5B6067'
    }
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#FFFFFF',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    padding: state.selectProps.menuIsOpen ? '0 0 0 17.5px' : '0 17.5px 0 0',
    '&:hover': {
      color: 'white'
    },
    ...(state.hasValue && { display: 'none' })
  }),
  clearIndicator: base => ({
    ...base,
    paddingRight: '17.5px'
  }),
  input: provided => ({
    ...provided,
    color: 'white'
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: 'var(--gray-primary)',
    width: '400px'
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '8px 16px',
    backgroundColor: state.isFocused ? 'var(--gray-secondary)' : 'var(--gray-primary)',
    color: 'var(--sub-text-secondary)',
    '&:hover': {
      backgroundColor: 'var(--gray-secondary)'
    }
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    color: 'var(--text-dark-off)',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '16px',
    padding: '8px 16px'
  }),
  control: (base, state) => ({
    ...base,
    width: '400px',
    height: '56px',
    background: 'var(--black-tertiary)',
    color: 'white',
    border: '1px solid #5B6067',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    opacity: state.isDisabled ? 0.3 : 1,
    '&:hover': {
      border: '1px solid #5FC4B8'
    },
    '&:focus-within': {
      borderColor: 'white'
    }
  })
}

export const DatePickerWrapper = styled.div`
  position: relative;

  .react-datepicker-ignore-onclickoutside {
    border: 1px solid var(--border-primary)!important;
  }

  #clear-icon {
    display: ${props => props.selected ? 'block' : 'none'};
    width: 16px;
    height: 16px;
    background: url(${clear});
    background-size: contain;
    background-position: center;
    position: absolute;
    right: 16px;
    top: 19.5px;
    cursor: pointer;
    z-index: 1;
  }
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container::after {
      ${props => props.selected && 'display: none'};
      content: url(${calendar});
      position: absolute;
      right: 16px;
      top: 19.5px;
      pointer-events: none;
    }
  }

  .react-datepicker {
    width: 279px;
    padding-top: 10px;
    font-family: "Lato";
    background: var(--gray-primary);
    border: none;
    box-shadow: 0px 0px 20px var(--shadow-primary);
    border-radius: 5px;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 16px;
    color: var(--text-primary);
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 14px;
    color: var(--text-primary);
  }
  .react-datepicker__day {
    border: 1px solid var(--brand-regular);
    border-radius: 5px;
    color: var(--brand-regular);
  }
  .react-datepicker__day--disabled {
    color: var(--text-primary-off)!important;
    border: none!important;
  }
  .react-datepicker__month-container {
    width: 100%;
    background: var(--gray-primary);
    border-radius: 5px;
  }
  .react-datepicker__month {
    margin: 0px;
    padding: 6px 0px 12px 0px;
  }
  .react-datepicker__day-names {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 9px 20px 6px 20px;
  }
  .react-datepicker__week {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding: 0 20px;
  }
  .react-datepicker__header {
    background: var(--gray-primary);
    border-bottom: 1px solid var(--border-primary);
  }

  .react-datepicker__time-list {
    background: var(--gray-primary);
  }

  .react-datepicker__time-list-item {
    font-size: 14px;
    color: var(--text-primary);
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__time-list-item--selected {
    background: var(--brand-regular) !important;
    color: var(--text-dark) !important;
  }

  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__time-container {
    border-left: 1px solid var(--border-primary);
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__time-list-item:hover {
    background-color: var(--brand-regular-hover);
    color: var(--text-dark);
  }

  .react-datepicker__day--disabled:hover {
    background-color: transparent;
  }

  .react-datepicker__time-box {
    width: 84px !important;
  }

  .react-datepicker__time-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--shadow-primary);
    border-radius: 10px;
    background-color: transparent;
  }

  .react-datepicker__time-list::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }

  .react-datepicker__time-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--shadow-primary);
    background: rgba(255, 255, 255, 0.1);
  }

  .react-datepicker__navigation {
    top: 22px;
  }

  .react-datepicker__navigation--previous {
    left: 21px;
  }

  .react-datepicker__navigation--next {
    right: 21px;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 91px;
  }
`

export const DatePicker = styled(CustomDatePicker)`
  width: 279px;
  height: 56px;
  cursor: pointer;

  :focus {
    border: 1px solid var(--border-primary);
  }

  :hover {
    border: 1px solid var(--brand-regular);
  }
`

export const Info = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`

export const EnrollmentOptions = styled.p`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: var(--text-dark-off);
  text-transform: uppercase;
  margin-bottom: 8px;
`

export const FormSubtitle = EnrollmentOptions

export const RelationshipLabel = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;
  color: var(--text-primary);
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 400;
  line-height: 24px;
  border-radius: 3px;
  width: fit-content;
  background: #30303A;
`
export const AvailableStudentTokens = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin: 12px 0;
`

export const RemainingTokens = styled.div`
  color: #FFFFFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
`

export const ReviewStudentName = RemainingTokens

export const PaymentDetailsDescription = styled.div`
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  max-width: 500px;
`

export const TokenBalance = styled.div`
  margin-left: 24px;
`

export const RecentOrder = styled.div`
  margin-top: 24px;
`

export const InfoText = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-style: italic;
  color: var(--sub-text-secondary);
`

export const EditButton = styled.span`
  color: var(--brand-regular);
  cursor: pointer;
  font-size: 14px;
  margin-left: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  &:hover {
    color: var(--brand-regular-hover);
  }

  &:active {
    color: var(--brand-regular-active);
  }
`

export const ReviewWrapper = styled.div`
  margin-bottom: 24px;
`

export const SubText = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: var(--sub-text-secondary);
`

import React, { useMemo, useState } from 'react'
import {
  GGUApplicationsWrapper,
  SearchTextField,
  SearchContainer,
  SearchNote,
  SearchField,
  SearchIcon
} from './style/index.style'
import magnifyIcon from '../../assets/icons/icon-magnify.svg'
import clearIcon from '../../assets/icons/clear-icon.svg'
import ApplicationsTable from './ApplicationsTable'
import { useDebouncedEffect } from '../../Hooks/useDebounce'
import api from '../../api'

const GGUApplications = () => {
  const [applicationsData, setApplicationsData] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isSortedAscending, setIsSortedAscending] = useState(null)
  const [noFoundEntry, setNoFoundEntry] = useState(null)

  useDebouncedEffect(() =>
    fetchGGUProspects(),
  [searchText], 1000)

  const fetchGGUProspects = async () => {
    if (noFoundEntry !== null) setNoFoundEntry(null)
    if (searchText === '') return

    const prospectsList = await api.getGGUProspects({
      search: searchText,
      sort: false
    })

    if (prospectsList?.length < 1) {
      setNoFoundEntry(searchText)
      return
    }
    setApplicationsData(prospectsList)
  }

  const handleTextChange = (value) => {
    setSearchText(value)
    if (value === '') {
      setApplicationsData([])
      setIsSortedAscending(null)
    }
  }

  const handleSort = () => {
    if (applicationsData.length < 1) return
    if (isSortedAscending === null) {
      setIsSortedAscending(true)
    } else setIsSortedAscending(!isSortedAscending)
    sortApplications()
  }

  const sortApplications = () => {
    const sortedList = applicationsData.sort((a, b) => {
      const dateA = new Date(a.submissionTime)
      const dateB = new Date(b.submissionTime)

      return isSortedAscending ? dateA - dateB : dateB - dateA
    })
    setApplicationsData(sortedList)
  }
  const renderApplicationsTable = useMemo(() => {
    return (
      <ApplicationsTable
        applicationsData={applicationsData}
        handleSort={handleSort}
        isSortedAscending={isSortedAscending}
        noFoundEntry={noFoundEntry}
      />
    )
    // eslint-disable-next-line
  }, [applicationsData, isSortedAscending, noFoundEntry])

  const inputIcon = searchText === '' ? magnifyIcon : clearIcon

  return (
    <GGUApplicationsWrapper>
      <SearchContainer>
        <SearchField>
          <SearchTextField
            placeholder='Search for a student'
            value={searchText}
            onChange={
              (e) => handleTextChange(e.target.value)
            }
          />
          <SearchIcon
            src={inputIcon}
            alt='search-icon'
            onClick={() => handleTextChange('')}
          />
        </SearchField>
        <SearchNote>Email or id (including GGU)</SearchNote>
      </SearchContainer>
      {renderApplicationsTable}
    </GGUApplicationsWrapper>
  )
}
GGUApplications.displayName = 'GGUApplications'
export default GGUApplications

import React, { useEffect, useRef, useState } from 'react'
import { ReactComponent as CheckMark } from '../../../assets/icons/icon-checkmark.svg'
import api from '../../../api'
import Toast from '../../ToastComponent/Toast'
import GoToPreviousPageButton
from '../../GoToPreviousPageButton/GoToPreviousPageButton'
import { VOID_LICENSE_OPTIONS } from '../constants'
import { ToastTitle } from '../../MergeAccounts/styled'
import PartnerDetails from './PartnerDetails'
import SelectLicense from './SelectLicense'
import VoidAllLicenses from './VoidAllLicenses'
import {
  Container,
  GoToPreviousPageButtonWrapper,
  Header,
  HeaderDescription,
  ButtonWrapper,
  SubmitButton,
  CancelButton
} from './styled'
import VoidCourseLicenses from './VoidCourseLicenses'
import VoidLicensesByDate from './VoidLicensesByDate'
import VoidInProgress from './VoidInProgress'
import ErrorScreen from './ErrorScreen'

function VoidLicenses ({ routerProps }) {
  const {
    history
  } = routerProps

  const [isRelationshipsLoading, setRelationshipsLoading] = useState(true)
  const [relationships, setRelationships] = useState([])
  const [coursesLicenses, setCoursesLicenses] = useState([])
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [selectedRelationship, setSelectedRelationship] = useState(null)
  const [selectedLicenseOption, setSelectedLicenseOption] = useState(null)
  const [tokensToVoid, setTokensToVoid] = useState([])
  const [voidStatus, setVoidStatus] = useState(null)
  const [fetchRelationships, setFetchRelationships] = useState(false)

  const toast = useRef()

  useEffect(() => {
    if (voidStatus === 'success') {
      toast.current.display(
        <ToastTitle>
          <CheckMark /><div>Licenses successfully voided</div>
        </ToastTitle>
      )
    }
  }, [voidStatus])

  useEffect(() => {
    const getRelationships = async () => {
      setRelationshipsLoading(true)
      const relationships = await api.getStudentRelationships({ useStream: false })
      const coursesLicenses = await api.getCoursesLicenses()
      const relationShipOptions = relationships
        .sort((a, b) => a.relationshipName.localeCompare(b.relationshipName))
        .map(
          relationship => {
            return {
              ...relationship,
              value: relationship.id,
              label: relationship.relationshipName
            }
          }
        )
      setCoursesLicenses(coursesLicenses)
      setRelationships(relationShipOptions)
      setRelationshipsLoading(false)
    }
    getRelationships()
    setFetchRelationships(false)
  }, [fetchRelationships])

  const handleSubmit = async () => {
    setVoidStatus('in_progress')
    const { success } = await api.voidTokens(tokensToVoid)
    if (!success) {
      return setVoidStatus('error')
    }
    setSelectedDistrict(null)
    setSelectedRelationship(null)
    setSelectedLicenseOption(null)
    setVoidStatus('success')
    setFetchRelationships(true)
  }

  const relationshipsWithDistrict =
    relationships.filter(relationship => relationship.districtName)
  const relationshipDistricts =
    [...new Set(relationshipsWithDistrict.map(relationship => relationship.districtName))]
  const relationshipDistrictOptions = relationshipDistricts
    .sort((a, b) => a.localeCompare(b))
    .map(district => ({ value: district, label: district }))

  const filteredRelationships = relationships.filter(relationship =>
    (!selectedDistrict?.value || relationship.districtName === selectedDistrict?.value))

  if (voidStatus === 'in_progress') {
    return <VoidInProgress />
  }

  if (voidStatus === 'error') {
    return <ErrorScreen
      setVoidStatus={setVoidStatus}
    />
  }

  return (
  <>
    <Toast toast={toast} />
    <Container>
      <GoToPreviousPageButtonWrapper>
        <GoToPreviousPageButton
          text='Manage Relationships'
          handleClick={() => { history.push('/relationships') }}
        />
      </GoToPreviousPageButtonWrapper>
      <Header>Void course licenses</Header>
      <HeaderDescription>
        Fill out the form below to void available course licenses. To void pending licenses, visit <a href='#/enrollment-management'>Enrollment Management</a>.
      </HeaderDescription>
      <PartnerDetails
        isRelationshipsLoading={isRelationshipsLoading}
        relationshipDistrictOptions={relationshipDistrictOptions}
        coursesLicenses={coursesLicenses}
        selectedDistrict={selectedDistrict}
        setSelectedDistrict={setSelectedDistrict}
        selectedRelationship={selectedRelationship}
        setSelectedRelationship={setSelectedRelationship}
        setSelectedLicenseOption={setSelectedLicenseOption}
        filteredRelationships={filteredRelationships}
      />
      {
        selectedRelationship && (
          <SelectLicense
            selectedOption={selectedLicenseOption}
            setSelectedOption={setSelectedLicenseOption}
            setTokensToVoid={setTokensToVoid}
          />
        )
      }
      {
        selectedLicenseOption &&
        selectedLicenseOption === VOID_LICENSE_OPTIONS.ALL && (
          <VoidAllLicenses
            selectedRelationship={selectedRelationship}
            coursesLicenses={coursesLicenses}
            setTokensToVoid={setTokensToVoid}
          />
        )
      }
      {
        selectedLicenseOption &&
        selectedLicenseOption === VOID_LICENSE_OPTIONS.BY_COURSE && (
          <VoidCourseLicenses
            selectedRelationship={selectedRelationship}
            coursesLicenses={coursesLicenses}
            setTokensToVoid={setTokensToVoid}
          />
        )
      }
      {
        selectedLicenseOption &&
        selectedLicenseOption === VOID_LICENSE_OPTIONS.BY_DATE && (
          <VoidLicensesByDate
            selectedRelationship={selectedRelationship}
            coursesLicenses={coursesLicenses}
            setTokensToVoid={setTokensToVoid}
          />
        )
      }
      <ButtonWrapper>
        <SubmitButton
          disabled={!tokensToVoid.length}
          onClick={handleSubmit}
        >
          Submit
        </SubmitButton>
        <CancelButton>
          Cancel
        </CancelButton>
      </ButtonWrapper>
    </Container>
  </>
  )
}

export default VoidLicenses

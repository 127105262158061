import React from 'react'
import ModalComponent from '../../ModalComponent/ModalComponent'
import { Label } from 'reactstrap'
import {
  ButtonContainer,
  FormContainer,
  InputError,
  InputHint,
  ModalContents,
  ModalWrapper
} from './styles'
import { FormInput, WarningMessage } from '../styles'
import { PrimaryButton, SecondaryButton } from '../../global.styled'
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg'

function AddViewModalRedesign ({
  handleClose,
  handleAddView,
  newView,
  setNewView,
  errorMessage,
  inputError,
  setInputError,
  isAddViewLoading
}) {
  return (
    <ModalWrapper>
      <ModalComponent
        data-testid='add-view-modal'
        title='Add a custom view'
        show
        width='449px'
        handleClose={handleClose}
      >
        <ModalContents>
          <FormContainer>
            <Label>View name</Label>
            {inputError && (
              <InputError data-testid='input-error'>
                <WarningIcon />
                {inputError}
              </InputError>
            )}
            <FormInput
              type='text'
              error={inputError}
              autoFocus
              placeholder='Personal view'
              onChange={(e) => {
                if (inputError) {
                  setInputError('')
                }
                setNewView({ ...newView, name: e.target.value })
              }}
            />
          </FormContainer>
          <InputHint>
            You can rename, delete, and apply filters to your view.
          </InputHint>
        </ModalContents>
        {errorMessage && <WarningMessage>{errorMessage}</WarningMessage>}
        <ButtonContainer>
          <PrimaryButton
            data-testid='add-view-button'
            onClick={handleAddView}
            disabled={isAddViewLoading}
            marginRight='12px'
          >
            Create New View
            {isAddViewLoading && <i className='fa fa-spinner fa-spin' />}
          </PrimaryButton>
          <SecondaryButton
            data-testid='cancel-button'
            disabled={isAddViewLoading}
            onClick={handleClose}
          >
            Cancel
          </SecondaryButton>
        </ButtonContainer>
      </ModalComponent>
    </ModalWrapper>
  )
}

AddViewModalRedesign.displayName = 'AddViewModalRedesign'
export default AddViewModalRedesign

import React, { useEffect, useRef, useState } from 'react'
import api from '../../api'
import { HeaderSection, PageWrapper } from './style'
import { BodyText, PageTitle } from '../../styles/text'
import { Separator } from '../../styles'
import Filters from './Filters'
import { addLabels, getSortedProspects, processProspects } from './utils'
import {
  PAGES,
  TABLES,
  initialEnrollmentStatus,
  initialFiltersData,
  initialProspectsData,
  initialSelectedData
} from './utils/constants'
import ProspectsTable from './ProspectsTable'
import ProspectPage from './ProspectPage'
import Toast from '../../Components/ToastComponent/Toast'
import { SuccessIcon } from '../../Components/AdminStudentDrop/styled'

function GGURegistration () {
  const toast = useRef(null)

  const [filtersData, setFiltersData] = useState(initialFiltersData)
  const [selectedData, setSelectedData] = useState(initialSelectedData)

  const [activeTable, setActiveTable] = useState(TABLES.review)
  const [prospectsData, setProspectsData] = useState(initialProspectsData)

  const [activePage, setActivePage] = useState(PAGES.main)
  const [activeProspect, setActiveProspect] = useState(null)

  const [enrollmentStatus, setEnrollmentStatus] = useState(initialEnrollmentStatus)
  const [refetchProspects, setRefetchProspects] = useState(false)

  useEffect(() => {
    const fetchFiltersData = async () => {
      const [degrees, advisors] = await Promise.all([
        api.getDegrees(),
        api.getAdvisors()
      ])

      setFiltersData({
        degrees: addLabels(degrees),
        advisors: addLabels(advisors, 'advisorName')
      })

      setSelectedData({
        degrees: addLabels(degrees),
        advisors: addLabels(advisors, 'advisorName')
      })
    }

    fetchFiltersData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fetchProspects = async () => {
      const hasNoProspects = !prospectsData[activeTable].length
      const shouldFetch = refetchProspects || hasNoProspects
      if (!shouldFetch) return

      setProspectsData(prev => ({ ...prev, isLoading: true }))

      const isApprovedTable = activeTable === TABLES.approved
      const additionalParams = isApprovedTable
        ? { emptyTempCart: true, degreeStatus: 'Active' }
        : {}
      const mode = isApprovedTable ? {} : { mode: activeTable }

      const prospects = await api.getGGUProspects({
        limit: isApprovedTable ? 5000 : 500,
        sort: isApprovedTable ? 'advisorApprovedUpdatedAt' : false,
        ...mode,
        ...additionalParams
      })

      const sortKey = activeTable === TABLES.review
        ? 'tempCartUpdatedAt' : 'advisorApprovedUpdatedAt'
      const sortedProspects = getSortedProspects(prospects, sortKey)
      setProspectsData(prev => ({
        ...prev,
        [activeTable]: processProspects(sortedProspects),
        isLoading: false
      }))

      if (activeTable === TABLES.approved) setRefetchProspects(false)
    }

    fetchProspects()
    // eslint-disable-next-line
  }, [activeTable, refetchProspects])

  const showToast = (studentName) => {
    const { current: { display } } = toast
    display(
      <>
        <SuccessIcon /> {studentName} successfully enrolled!
      </>
    )

    setEnrollmentStatus(initialEnrollmentStatus)
  }

  if (activePage === PAGES.prospect) {
    return (
      <ProspectPage
        setActivePage={setActivePage}
        prospectId={activeProspect?.prospectId}
        filtersData={filtersData}
        showToast={showToast}
        enrollmentStatus={enrollmentStatus}
        setEnrollmentStatus={setEnrollmentStatus}
        setRefetchProspects={setRefetchProspects}
      />
    )
  }

  return (
    <PageWrapper>
      <Toast toast={toast} />

      <HeaderSection>
        <PageTitle>
          GGU Course Approval
        </PageTitle>
        <Separator />
        <BodyText>
          The following students have selected their schedule for the next semester. <br />
          Select a student to enroll them in their requested cohort(s).
        </BodyText>

        <Filters
          filtersData={filtersData}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      </HeaderSection>

      <ProspectsTable
        activeTable={activeTable}
        setActiveTable={setActiveTable}
        selectedData={selectedData}
        prospectsData={prospectsData}
        setActivePage={setActivePage}
        setActiveProspect={setActiveProspect}
      />
    </PageWrapper>
  )
}

GGURegistration.displayName = 'GGURegistration'
export default GGURegistration

import styled from 'styled-components'

export const PageWrapper = styled.div`
  margin: 24px 24px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  & * {
    --sb-track-color: transparent;
    --sb-thumb-color: #5b6067;
    --sb-size: 18px;

    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);

    &::-webkit-scrollbar {
      width: var(--sb-size);
      height: var(--sb-size);
    }

    &::-webkit-scrollbar-track {
      background: var(--sb-track-color);
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--sb-thumb-color);
      border-radius: 10px;
      border: 5px solid var(--sb-track-color);
      background-clip: content-box;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #98a4ae;
      border-radius: 10px;
      border: 5px solid var(--sb-track-color);
      background-clip: content-box;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
`
export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--border-secondary);
`
export const PageDescription = styled.p`
  color: var(--sub-text-secondary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
`
export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const SelectLabel = styled.span`
  color: var(--sub-text-secondary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
export const CohortContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
export const CohortName = styled.h2`
  display: flex;
  gap: 8px;
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  margin: 0;
`
export const ViolationsOverviewContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
`
export const ViolationsOverview = styled.p`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
  padding: 16px 24px;
  border-radius: 5px;
  border: 1px solid var(--border-secondary);

  & span {
    font-size: 48px;
  }
`
export const SelectOption = styled.div`
  display: flex;
  flex-direction: column;
  & > p {
    color: #fff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
  }

  & > p:last-child {
    height: 24px;
    align-content: center;
    font-size: 14px;
    line-height: normal;
  }
`
export const TableContainer = styled.div`
  position: relative;
  min-width: 100%;
  height: 100%;
  overflow: auto;
  border-width: 0.5px;
  border-style: solid;
  border-color: rgb(91, 96, 103);
  border-radius: 5px;
`
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  & th {
    color: var(--text-secondary);
    padding: 12px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border: 1px solid #5b6067;
    height: 47px;
  }

  & td {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding: 5px 12px;
    border: 1px solid #5b6067;
    height: 36px;
  }
`
export const ShowRejectedTableButton = styled.button`
  display: flex;
  gap: 8px;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  background-color: transparent;
  padding: 0;
  border: 0;
  width: fit-content;

  & svg {
    transform: ${({ showRejectedTable }) =>
    showRejectedTable ? 'rotate(180deg)' : 'rotate(0deg)'};
  }

  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
`
export const RejectedTableContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding-top: 8px;
`

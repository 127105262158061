export const ENROLLED = 'Enrolled'
export const COMPLETED = 'Completed'
export const WITHDRAW = 'Withdraw'
export const AUDIT = 'Audit'
export const MELT = 'Melt'
export const MELT_ID = 'recDqENwjfjTuOq1X'
export const DROP = 'Drop'
export const DROP_ID = 'recjsrTvt3bIOk3HY'
export const ADMINISTRATIVE_DROP = 'Administrative Drop'
export const ADMINISTRATIVE_DROP_ID = 'recRVfzY0fw60d1MN'
export const PRE_DROP = 'PreDrop'
export const AUDIT_TRANSCRIPT = 'Audit Transcript'
export const SIGNED_UP = 'Signed Up'
export const DROP_NR = 'DropNR'
export const DROP_NR_ID = 'recy4ESR6eb6MGSmu'
export const DROP_NV = 'DropNV'
export const INCOMPLETE = 'Incomplete'
export const UNOFFICIAL_WITHDRAW = 'Unofficial Withdraw'
export const NEEDS_REVIEWING = 'Needs Reviewing'
export const NEEDS_REVIEWING_DEGREE = 'Needs Reviewing - Degree'

export const EXIT_STATUSES = [
  MELT,
  DROP,
  ADMINISTRATIVE_DROP,
  DROP_NR,
  DROP_NV,
  WITHDRAW,
  UNOFFICIAL_WITHDRAW,
  COMPLETED
]

export const EXCLUDE_STATUSES_IDS = [
  ADMINISTRATIVE_DROP_ID,
  DROP_NR_ID,
  DROP_ID,
  MELT_ID
]

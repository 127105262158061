import React, { useState } from 'react'
import {
  ClearIcon,
  Container,
  CustomInput,
  Header,
  InputLabel,
  InputWrapper,
  Separator,
  StudentSearchWrapper
} from './style'
import GoToPreviousPageButton
from '../GoToPreviousPageButton/GoToPreviousPageButton'
import { dropDownStyles } from '../global.styled'
import magnifyIcon from '../../assets/icons/icon-magnify.svg'
import clearIcon from '../../assets/icons/clear-icon.svg'
import StudentsTable from './StudentsTable'

const StudentRecords = ({ routerProps: { history } }) => {
  const [filterText, setFilterText] = useState('')

  const onFilterTextChange = (value) => {
    setFilterText(value)
  }
  const inputIcon = filterText === '' ? magnifyIcon : clearIcon

  return (
    <Container>
      <GoToPreviousPageButton
        handleClick={() => history.push('/')}
        text='Admin Home'
      />
      <Header>
        Student records search
      </Header>
      <Separator />
      <StudentSearchWrapper>
        <InputLabel>Find a student</InputLabel>
        <InputWrapper data-testid='input-wrapper'>
          <CustomInput
            placeholder='Enter the student’s name, email, or ID to view records.'
            value={filterText}
            onChange={(e) =>
              onFilterTextChange(e.target.value)}
            styles={dropDownStyles}
          />
          <ClearIcon
            src={inputIcon}
            alt=''
            data-testid='clear-icon'
            onClick={() => {
              onFilterTextChange('')
            }}
          />
        </InputWrapper>
      </StudentSearchWrapper>
      {filterText.trim() !== ''
        ? <StudentsTable filterText={filterText} />
        : null
      }
    </Container>
  )
}
StudentRecords.displayName = 'StudentRecords'
export default StudentRecords

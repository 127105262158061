import React from 'react'
import PropTypes from 'prop-types'
import ModalComponent from '../ModalComponent/ModalComponent'
import CloseIcon from '../../assets/icons/cross.svg'
import {
  Wrapper,
  Title,
  TitleText,
  EarnedGrade,
  Score,
  Question,
  ButtonWrapper,
  PrimaryButton,
  SecondaryButton
} from './styled'

function EditCodingAssignmentModal (props) {
  const {
    title,
    score,
    show,
    handleCodingAssignment,
    handleOverallGrades,
    showModalHandler
  } = props

  return (
    <ModalComponent show={show} handleClose={showModalHandler}>
      <Wrapper>
        <Title>
          <TitleText>
            {title}
          </TitleText>
          <img
            data-testid='img-close'
            src={CloseIcon}
            alt='Close Modal'
            onClick={showModalHandler}
          />
        </Title>
        <EarnedGrade>
          Earned Grade
        </EarnedGrade>
        <Score>
          {score}%
        </Score>
        <Question>
          How would you like to adjust your grade?
        </Question>
        <ButtonWrapper>
          <PrimaryButton
            onClick={() => handleCodingAssignment()}
          >
            By Category
          </PrimaryButton>
          <SecondaryButton
            onClick={handleOverallGrades}
          >
            Overall Grade
          </SecondaryButton>
        </ButtonWrapper>
      </Wrapper>
    </ModalComponent>
  )
}

EditCodingAssignmentModal.propTypes = {
  handleOverallGrades: PropTypes.func.isRequired,
  score: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  showModalHandler: PropTypes.func.isRequired
}

export default EditCodingAssignmentModal

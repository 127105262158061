export const COURSES = [
  'Calculus I',
  'Introduction to Astronomy',
  'Introduction to Psychology',
  'Introduction to Statistics',
  'Introduction to Philosophy',
  'Introduction to Microeconomics'
]

export const TERM_LENGTHS = [
  'Standard (14 weeks)',
  'Intensive (7 weeks)'
]

export const UNIVERSITY_PARTNERS = [
  'University of Pittsburgh',
  'Other Partners'
]

export const ENROLLMENT_STATUSES = [
  'Enrolled',
  'Completed',
  'Melt',
  'Drop',
  'Administrative Drop',
  'Withdraw',
  'Audit'
]

export const OTHER_INFORMATION = [
  'Only scholarship students',
  'Include contractor employees'
]

export const FINAL_GRADES = 'Fetch final grades'

export const FETCH_GRADE_DETAILS = 'Fetch grade details'

export const COURSE_GRADE = 'Course final grade'

export const STUDENT_IDENTIFICATIONS = [
  'First name',
  'Last name',
  'Full legal name',
  'Preferred name',
  'Student ID',
  'Attempt ID',
  'Date of birth',
  'Gender',
  'Country of citizenship'
]

export const STUDENT_CONTACT_INFO = [
  'Email address',
  'Phone number',
  'Mailing address'
]

export const OTHER_STUDENT_INFO = [
  'Course final grade (numerical)',
  'Course final grade (letter)',
  'Enrollment status',
  'Status change date'
]

export const COHORT_INFO = [
  'Cohort name',
  'Start date',
  'Duration',
  'Partner university'
]

export const GRADE_DETAILS = [
  'Section final grade',
  'Section progress',
  'Guesswork progress',
  'Lecture progress',
  'Active Learning progress',
  'Practice exercises progress',
  'Quiz Progress',
  'Quiz grades',
  'Writing assignment grades',
  'Assessment timestamps',
  'Midterm grades',
  'Final exam grade',
  'Participation grade'
]

export const PRESET_TYPE = [
  [
    'Partner Presets',
    'University of Pittsburg - Enrollment Preset'
  ],
  [
    'Student Grade Presets',
    'Course progress and grades'
  ]
]

export const PRESET_EXPORT_INFO_ARRAY = {
  [PRESET_TYPE[0][1]]: [
    'First Name',
    'Last Name',
    'Email',
    'Country of Citizenship',
    'Phone Number',
    'Sex',
    'Date of Birth',
    'Mailing Address 1',
    'Mailing Address 2',
    'City',
    'Zip',
    'State/Province',
    'Country'
  ],
  [PRESET_TYPE[1][1]]: [
    'Email address',
    'First name',
    'Last name',
    'Student ID',
    'Enrollment status',
    'Status change date',
    'Attempt ID',
    'Cohort name',
    'Duration',
    'Section progress',
    'Section final grade',
    'Guesswork progress',
    'Lecture progress',
    'Active Learning progress',
    'Practice exercises progress',
    'Quiz Progress',
    'Quiz grades',
    'Writing assignment grades',
    'Assessment timestamps',
    'Midterm grades',
    'Final exam grade',
    'Participation grade'
  ]
}

export const PITT_COURSE_NAMES = {
  recWwrn6UVHJrDpCu: 'Enroll for MATH 0217',
  rec1ynIPQchfXwSbE: 'Enroll for MATH 0217',
  rec8fOk6Tg7FYZxM2: 'Enroll for ASTRON 0085',
  recPisycn9maFrL3n: 'Enroll for PSY 0013',
  recZPHkfjwBQMTrBi: 'Enroll for STAT 0202',
  recFvXNr8WdO3Tw6G: 'Enroll for PHIL 0100',
  recwhpReUlDlTCuY4: 'Enroll for ECON 0106'
}

import React, { useState, useEffect } from 'react'
import { useCoursesContext, useCoursesActions } from '../../contexts/Courses'
import api from '../../api'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import AlertModal from '../AlertModal/AlertModal'
import {
  getAdditionalCourseWithAtId,
  getCourseNameById,
  getCoursesWithCourseId,
  getRelationshipsById
} from '../../utilities/courseUtils'
import {
  getDefaultCommonDates,
  getDefaultMidtermDates
} from '../../utilities/cohortUtils'
import { courseSelectStyles } from '../global.styled'
import { fixTimezoneAndFormat, getDate, getDateString } from '../../utilities/dateTimeUtil'
import GoToPreviousPageButton from '../GoToPreviousPageButton/GoToPreviousPageButton'
import {
  Container,
  CustomDatePicker,
  CustomFormGroup,
  CustomLabel,
  CustomInput,
  CustomSelect,
  DateContainer,
  ErrorText,
  Title,
  CustomCheckBox
} from './styled'
import Button from '../Button/Button'

export default function CreateCohort ({ routerProps }) {
  const {
    history,
    match: { params: { cohortId } },
    location: { pathname }
  } = routerProps

  const isEditPath = pathname === `/cohorts/${cohortId}/edit`
  const confirmBtnTitle = isEditPath ? 'update cohort' : 'Create Cohort'

  const [formState, setFormState] = useState({})
  const [nameError, setNameError] = useState(false)
  const [coursesError, setCoursesError] = useState(false)
  const [dateStartError, setStartDateError] = useState(false)
  const [durationError, setDurationError] = useState(false)
  const [priceQtyError, setPriceQtyError] = useState(false)
  const [courseOptions, setCourseOptions] = useState([])
  const [relationshipOptions, setRelationshipOptions] = useState([])
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isAlertModal, setAlertModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isCourseEdit, setIsCourseEdit] = useState(false)
  const [isRelationshipEdit, setIsRelationshipEdit] = useState(false)
  const [messageOnRequest, setMessageOnRequest] = useState('')

  const { fetchCourses } = useCoursesActions()
  const { allCourses } = useCoursesContext()

  const getRelationships = async () => {
    const allRelationship = await api.getStudentRelationships()
    setRelationshipOptions(
      allRelationship?.map(({ creditGrantingInstitution, id, relationshipName }) => ({
        creditGrantingInstitution,
        id,
        relationshipName
      })))
  }

  useEffect(() => {
    if (!allCourses.length) {
      fetchCourses()
      return
    }
    const filteredCourses = getCoursesWithCourseId(allCourses)
    setCourseOptions(
      filteredCourses.map(course => ({
        value: course.at_id,
        label: course.name,
        at_id: course.at_id
      }))
    )
    // eslint-disable-next-line
  }, [allCourses])

  useEffect(() => {
    if (!isEditPath) return
    fetchCohortById(cohortId)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getRelationships()
    // eslint-disable-next-line
  }, [])

  const fetchCohortById = async (cohortId) => {
    setIsLoading(true)
    const cohort = await api.getCohortById(cohortId)
    setFormState(getDatesWithTimezoneOffset(cohort, true))
    setIsLoading(false)
  }

  const getDatesWithTimezoneOffset = (cohort, format) => {
    let { midTerm1StartTime, midTerm1EndTime, midTerm2StartTime,
      midTerm2EndTime, finalExamStartTime, finalExamEndTime } = cohort

    midTerm1StartTime = fixTimezoneAndFormat(midTerm1StartTime, format)
    midTerm1EndTime = fixTimezoneAndFormat(midTerm1EndTime, format)
    midTerm2StartTime = fixTimezoneAndFormat(midTerm2StartTime, format)
    midTerm2EndTime = fixTimezoneAndFormat(midTerm2EndTime, format)
    finalExamStartTime = fixTimezoneAndFormat(finalExamStartTime, format)
    finalExamEndTime = fixTimezoneAndFormat(finalExamEndTime, format)

    return { ...cohort,
      midTerm1StartTime,
      midTerm1EndTime,
      midTerm2StartTime,
      midTerm2EndTime,
      finalExamStartTime,
      finalExamEndTime }
  }

  const {
    auditContentLock,
    name,
    course,
    dateStart,
    courseInfoUrl,
    isPartnerCohort,
    createShopifyVariant,
    variantVisibleToCustomers,
    auditVariant,
    price,
    quantity,
    notes,
    duration,
    finalRegistrationDate,
    finalDropDate,
    finalWithdrawalDate,
    midTerm1StartTime,
    midTerm1EndTime,
    midTerm2StartTime,
    midTerm2EndTime,
    finalExamStartTime,
    finalExamEndTime,
    endTime,
    contentGatingEnabled,
    gradesSubmittedToPitt,
    relationships,
    testCohort
  } = formState

  const onChange = (key, value) => {
    let defaultFinalRegistrationDate = {}
    let defaultCommonDates = {}
    let defaultMidtermDates = {}
    if (key === 'name') {
      value && setNameError(false)
    }
    if (key === 'course' && value) {
      setCoursesError(false)
      if (!isEditPath) {
        defaultMidtermDates =
          getDefaultMidtermDates(value, dateStart, duration)
      }
    }
    if (key === 'dateStart' && value) {
      setStartDateError(false)
      if (!isEditPath) {
        defaultFinalRegistrationDate = {
          finalRegistrationDate: value
        }
        defaultCommonDates = getDefaultCommonDates(value, duration, course)
        defaultMidtermDates =
          getDefaultMidtermDates(course, value, duration)
      }
    }
    if (key === 'duration' && value) {
      setDurationError(false)
      if (!isEditPath) {
        defaultCommonDates = getDefaultCommonDates(dateStart, Number(value), course)
        defaultMidtermDates =
          getDefaultMidtermDates(course, dateStart, Number(value))
      }
    }
    if (['price', 'quantity', 'duration'].includes(key)) {
      if (!(/^\d*$/).test(value)) return
      value = parseInt(value)
    }
    if (key === 'createShopifyVariant') {
      if (!createShopifyVariant && !variantVisibleToCustomers) {
        formState.quantity = 0
      } else {
        formState.quantity = ''
        formState.price = ''
        formState.variantVisibleToCustomers = false
      }
    }
    if (key === 'variantVisibleToCustomers') {
      !variantVisibleToCustomers
        ? formState.quantity = ''
        : formState.quantity = 0
    }
    setFormState(prevState => ({
      ...prevState,
      ...defaultFinalRegistrationDate,
      ...defaultCommonDates,
      ...defaultMidtermDates,
      [key]: value
    }))
  }

  const submitForm = () => {
    setMessageOnRequest('')
    if (!name) setNameError(true)
    if (!course) setCoursesError(true)
    if (!dateStart) setStartDateError(true)
    if (!duration) setDurationError(true)
    if (
      (createShopifyVariant && !price) ||
    (createShopifyVariant && variantVisibleToCustomers && !quantity)
    ) return setPriceQtyError(true)
    if (name && course && dateStart && duration) setShowConfirmModal(true)
  }

  const handleConfirmation = async () => {
    setShowConfirmModal(false)
    setIsLoading(true)
    const { addNewCohort, updateCohort } = api
    const courseSelected = course.value ? [getAdditionalCourseWithAtId(course.value, allCourses)?.at_id || course.value] : course[0]
    const relationshipIds = relationships?.map(({ id }) => id) || []
    const cohortData = {
      ...getDatesWithTimezoneOffset(formState, false),
      relationships: relationshipIds,
      course: courseSelected
    }
    const response = isEditPath
      ? await updateCohort(cohortId, cohortData)
      : await addNewCohort(cohortData)
    const { success, message } = response
    if (!success) {
      setMessageOnRequest(message)
    }
    setIsLoading(false)
    setAlertModal(true)
  }

  const redirect = () => {
    setAlertModal(false)
    if (messageOnRequest) return setMessageOnRequest('')
    if (isEditPath) {
      history.push(`/cohorts/${cohortId}`)
    } else {
      history.push(`/cohorts`)
    }
  }

  const relationshipValues = isEditPath && getRelationshipsById(relationshipOptions, relationships)
  return (
    <Container>
      <GoToPreviousPageButton
        text='Back to Cohorts'
        handleClick={redirect}
      />
      <Title>{isEditPath ? 'Update Cohort' : 'Create New Cohort'}</Title>
      <>
        <CustomFormGroup>
          <CustomLabel>Name</CustomLabel>
          {nameError && <ErrorText className='error'>Please fill the name field</ErrorText>}
          <CustomInput
            autoComplete='off'
            data-testid='cohortName-field'
            type='text'
            value={name}
            error={nameError}
            onChange={e => onChange('name', e.target.value)}
            reduced
          />
          <CustomLabel>Select Course</CustomLabel>
          {coursesError && <ErrorText className='error'>Please select the course</ErrorText>}
          <CustomSelect
            data-testid='select-input-course'
            options={courseOptions}
            isDisabled={!allCourses.length}
            value={
              (!isCourseEdit && isEditPath)
                ? {
                  value: course && course[0],
                  label: course && getCourseNameById(allCourses, course[0])
                } : course
            }
            onChange={course => {
              if (isEditPath) {
                setIsCourseEdit(true)
              }
              onChange('course', course)
            }}
            placeholder='Select Course'
            error={coursesError}
            styles={courseSelectStyles}
            classNamePrefix='select'
          />
          <CustomLabel>Start Date</CustomLabel>
          {dateStartError && <ErrorText className='error'>Please fill the start date field</ErrorText>}
          <DateContainer>
            <CustomDatePicker
              id='date-start'
              autoComplete='off'
              placeholderText='MM/DD/YYYY'
              selected={getDate(dateStart)}
              onChange={date => onChange('dateStart', getDateString(date))}
              error={dateStartError}
            />
          </DateContainer>
          <CustomLabel>Duration</CustomLabel>
          {durationError && <ErrorText className='error'>Please fill the duration field</ErrorText>}
          <CustomInput
            id='test-duration'
            autoComplete='off'
            type='text'
            value={duration || ''}
            onChange={e => onChange('duration', e.target.value)}
            error={durationError}
            reduced
          />
          <CustomLabel>Course Info Url</CustomLabel>
          <CustomInput
            autoComplete='off'
            type='text'
            value={courseInfoUrl}
            onChange={e => onChange('courseInfoUrl', e.target.value)}
            reduced
          />
          <CustomLabel>Notes</CustomLabel>
          <CustomInput
            autoComplete='off'
            type='textarea'
            value={notes}
            onChange={e => onChange('notes', e.target.value)}
            reduced
          />
          <CustomLabel>Final Registration Date</CustomLabel>
          <DateContainer>
            <CustomDatePicker
              id='finalRegistrationDate'
              autoComplete='off'
              placeholderText='MM/DD/YYYY'
              selected={getDate(finalRegistrationDate)}
              onChange={date => onChange('finalRegistrationDate', date)}
            />
          </DateContainer>
          <CustomLabel>Final Drop Date</CustomLabel>
          <DateContainer>
            <CustomDatePicker
              id='finalDropDate'
              autoComplete='off'
              placeholderText='MM/DD/YYYY'
              selected={getDate(finalDropDate)}
              onChange={date => onChange('finalDropDate', date)}
            />
          </DateContainer>
          <CustomLabel>Final Withdrawal Date</CustomLabel>
          <DateContainer>
            <CustomDatePicker
              id='finalWithdrawalDate'
              autoComplete='off'
              placeholderText='MM/DD/YYYY'
              selected={getDate(finalWithdrawalDate)}
              onChange={date => onChange('finalWithdrawalDate', date)}
            />
          </DateContainer>
          <CustomLabel>Mid Term1 Start Time</CustomLabel>
          <DateContainer>
            <CustomDatePicker
              id='midTerm1StartTime'
              autoComplete='off'
              placeholderText='MM/DD/YYYY --:--'
              dateFormat='MM/dd/yyyy, h:mm aa'
              showTimeSelect
              selected={getDate(midTerm1StartTime)}
              onChange={date => onChange('midTerm1StartTime', date)}
            />
          </DateContainer>
          <CustomLabel>Mid Term1 End Time</CustomLabel>
          <DateContainer>
            <CustomDatePicker
              id='midTerm1EndTime'
              autoComplete='off'
              placeholderText='MM/DD/YYYY --:--'
              dateFormat='MM/dd/yyyy, h:mm aa'
              showTimeSelect
              disabled={!midTerm1StartTime}
              minDate={getDate(midTerm1StartTime)}
              selected={getDate(midTerm1EndTime)}
              onChange={date => onChange('midTerm1EndTime', date)}
            />
          </DateContainer>
          <CustomLabel>Mid Term2 Start Time</CustomLabel>
          <DateContainer>
            <CustomDatePicker
              id='midTerm2StartTime'
              autoComplete='off'
              placeholderText='MM/DD/YYYY --:--'
              dateFormat='MM/dd/yyyy, h:mm aa'
              showTimeSelect
              selected={getDate(midTerm2StartTime)}
              onChange={date => onChange('midTerm2StartTime', date)}
            />
          </DateContainer>
          <CustomLabel>Mid Term2 End Time</CustomLabel>
          <DateContainer>
            <CustomDatePicker
              id='midTerm2EndTime'
              autoComplete='off'
              placeholderText='MM/DD/YYYY --:--'
              dateFormat='MM/dd/yyyy, h:mm aa'
              disabled={!midTerm2StartTime}
              minDate={getDate(midTerm2StartTime)}
              selected={getDate(midTerm2EndTime)}
              onChange={date => onChange('midTerm2EndTime', date)}
              showTimeSelect
            />
          </DateContainer>
          <CustomLabel>Final Exam Start Time</CustomLabel>
          <DateContainer>
            <CustomDatePicker
              id='finalExamStartTime'
              autoComplete='off'
              placeholderText='MM/DD/YYYY --:--'
              dateFormat='MM/dd/yyyy, h:mm aa'
              selected={getDate(finalExamStartTime)}
              onChange={date => onChange('finalExamStartTime', date)}
              showTimeSelect
            />
          </DateContainer>
          <CustomLabel>Final Exam End Time</CustomLabel>
          <DateContainer>
            <CustomDatePicker
              id='finalExamEndTime'
              autoComplete='off'
              placeholderText='MM/DD/YYYY --:--'
              dateFormat='MM/dd/yyyy, h:mm aa'
              disabled={!finalExamStartTime}
              selected={getDate(finalExamEndTime)}
              minDate={getDate(finalExamStartTime)}
              onChange={date => onChange('finalExamEndTime', date)}
              showTimeSelect
            />
          </DateContainer>
          <CustomLabel>End time</CustomLabel>
          <DateContainer>
            <CustomDatePicker
              id='endTime'
              autoComplete='off'
              placeholderText='MM/DD/YYYY --:--'
              dateFormat='MM/dd/yyyy, h:mm aa'
              selected={getDate(endTime)}
              onChange={date => onChange('endTime', date)}
              showTimeSelect
            />
          </DateContainer>
          <CustomLabel>Select Relationship</CustomLabel>
          <CustomSelect
            data-testid='select-input-relationships'
            options={relationshipOptions}
            getOptionLabel={option => option.relationshipName}
            getOptionValue={option => option.relationshipName}
            isMulti
            value={
              (!isRelationshipEdit && isEditPath)
                ? relationshipValues : relationships
            }
            onChange={event => {
              if (isEditPath) {
                setIsRelationshipEdit(true)
              }
              onChange('relationships', event)
            }}
            placeholder='Select Relationship'
            styles={courseSelectStyles}
            classNamePrefix='select'
          />
          <CustomCheckBox>
            <CustomLabel for='contentGatingEnabled'>Enable Content Gating</CustomLabel>
            <input
              checked={contentGatingEnabled}
              type='checkbox'
              id='contentGatingEnabled'
              value={contentGatingEnabled}
              onClick={() => onChange('contentGatingEnabled', !contentGatingEnabled)}
            />
            <span />
          </CustomCheckBox>
          {
            isEditPath &&
            <CustomCheckBox>
              <CustomLabel for='gradesSubmittedToPitt'>Grades Submitted to Pitt</CustomLabel>
              <input
                checked={gradesSubmittedToPitt}
                type='checkbox'
                id='gradesSubmittedToPitt'
                value={gradesSubmittedToPitt}
                onClick={() => onChange('gradesSubmittedToPitt', !gradesSubmittedToPitt)}
              />
              <span />
            </CustomCheckBox>
          }

          <CustomCheckBox>
            <CustomLabel for='isPartnerCohort'>Partner Cohort</CustomLabel>
            <input
              checked={isPartnerCohort}
              type='checkbox'
              id='isPartnerCohort'
              value={isPartnerCohort}
              onClick={() => onChange('isPartnerCohort', !isPartnerCohort)}
            />
            <span />
          </CustomCheckBox>
          {!isEditPath &&
          <>
            <CustomCheckBox>
              <CustomLabel for='createShopifyVariant'>Create Cohort Variant in Shopify</CustomLabel>
              <input
                checked={createShopifyVariant}
                type='checkbox'
                id='createShopifyVariant'
                value={createShopifyVariant}
                onClick={() => {
                  onChange('createShopifyVariant', !createShopifyVariant)
                }}
              />
              <span />
            </CustomCheckBox>
            <CustomLabel>Price</CustomLabel>
            {priceQtyError && !price && createShopifyVariant &&
              <ErrorText id='price-error' className='error'>Please fill the price field</ErrorText>}
            <CustomInput
              id='test-price'
              autoComplete='off'
              type='text'
              value={price || ''}
              onChange={e => onChange('price', e.target.value)}
              disabled={!createShopifyVariant}
              reduced
            />
            <CustomCheckBox>
              <CustomLabel for='variantVisibleToCustomers'>Variant Visible to Customers</CustomLabel>
              <input
                checked={variantVisibleToCustomers}
                type='checkbox'
                id='variantVisibleToCustomers'
                value={variantVisibleToCustomers}
                onClick={() => onChange('variantVisibleToCustomers', !variantVisibleToCustomers)}
                disabled={!createShopifyVariant}
              />
              <span />
            </CustomCheckBox>
            <CustomCheckBox>
              <CustomLabel for='auditVariant'>Audit Variant</CustomLabel>
              <input
                checked={auditVariant}
                type='checkbox'
                id='auditVariant'
                value={auditVariant}
                onClick={() => onChange('auditVariant', !auditVariant)}
                disabled={!createShopifyVariant}
              />
              <span />
            </CustomCheckBox>
            <CustomLabel>Quantity</CustomLabel>
            {
              priceQtyError && !quantity && variantVisibleToCustomers &&
              <ErrorText id='quantity-error' className='error'>Please fill the quantity field</ErrorText>
            }
            <CustomInput
              id='test-Quantity'
              autoComplete='off'
              type='text'
              value={!isNaN(quantity) ? quantity : ''}
              onChange={e => onChange('quantity', e.target.value)}
              disabled={!variantVisibleToCustomers}
              error={priceQtyError}
              reduced
            />
          </>
          }
          <CustomCheckBox>
            <CustomLabel for='auditContentLock'>Apply content locking to audit students</CustomLabel>
            <input
              checked={auditContentLock}
              type='checkbox'
              id='auditContentLock'
              value={auditContentLock}
              onClick={() => onChange('auditContentLock', !auditContentLock)}
            />
            <span />
          </CustomCheckBox>
          <CustomCheckBox>
            <CustomLabel for='testCohort'>Test cohort</CustomLabel>
            <input
              checked={testCohort}
              type='checkbox'
              id='testCohort'
              value={testCohort}
              onClick={() => onChange('testCohort', !testCohort)}
            />
            <span />
          </CustomCheckBox>
          <ConfirmationModal
            showModalHandler={() => setShowConfirmModal(false)}
            show={showConfirmModal}
            confirm={{ text: confirmBtnTitle, handler: () => handleConfirmation() }}
            cancel={{ text: 'Cancel', handler: () => setShowConfirmModal(false) }}
          >
            Do you want to continue?
          </ConfirmationModal>
          <AlertModal
            showModalHandler={() => setAlertModal(false)}
            confirm={{ text: 'ok', handler: () => redirect() }}
            show={isAlertModal}
          >
            {!messageOnRequest
              ? (isEditPath ? 'Cohort updated successfully' : 'Cohort created successfully')
              : messageOnRequest}.
          </AlertModal>
          {isLoading ? <i className='fa fa-spinner fa-spin' />
            : <Button className='cohort-create-button btn-custom btn-primary' onClick={submitForm}>
              {isEditPath ? 'Update' : 'Submit'}
            </Button>}
        </CustomFormGroup>
      </>
    </Container>
  )
}

import React, { memo, useEffect, useMemo, useState } from 'react'
import {
  customDropDownStyles,
  DatePickerWrapper,
  EnrollStudentForm,
  FormLabel,
  FormRow,
  FormTitle,
  PageWrapper,
  DatePicker,
  FormInput,
  FormDescription,
  DotSeparator,
  EnrollmentOptions,
  Info,
  SoftWarningMessage
} from '../style'
import Select from 'react-select'
import api from '../../../api'
import {
  getCreditGrantingInstitutionsOptions
} from '../../../utilities/CSVDownloadToolV2'
import { convertEasternTimeToLocalTime } from '../../../utilities/dateTimeUtil'
import RadioButtonSelector from '../../RadioButtonSelector'
import {
  getDurationOptions,
  getDurationOptionsByDate,
  getIsAuditOptions
} from '../../../utilities/EnrollStudentsTool'
import { CustomToolTip } from '../../Students/style'
import { GGU, UPitt } from '../../../Constants/institutions'
import { COMPLETED } from '../../../Constants/studentStatus'
import { ReactComponent as WarningIcon } from '../../../assets/icons/exclamation-circle.svg'
import {
  ClearIndicator,
  DropdownIndicator,
  Group
} from '../../CustomSelect/CustomSelectComponents'
import moment from 'moment/moment'
import { getFilteredCourses } from '../../../utilities/courseUtils'

const ENROLLMENT_OPTIONS_TOOLTIP = 'These are the enrollment options available to the partner.'
const DURATION_TOOLTIP = 'Depending on the start date, standard cohorts are either 14 or 15 weeks, and intensive cohorts are either 7 or 8 weeks.'

const GGU_WARNING_MESSAGE = 'This student completed the course 2 times, the maximum attempts allowed by Golden Gate University without the dean’s permission. “Submit” to complete the enrollment.'
const UPITT_WARNING_MESSAGE = 'This student completed the course 3 times, the maximum attempts allowed by University of Pittsburgh. “Submit” to complete the enrollment.'

const EnrollmentDetails = ({
  isDegreePlusStudent,
  studentData,
  selectedRelationship,
  onBehalfOfPartner,
  selectedCourse,
  setSelectedCourse,
  selectedDuration,
  setSelectedDuration,
  selectedIsAudit,
  setSelectedIsAudit,
  selectedDate,
  setSelectedDate,
  enrollmentDetailsRef,
  setSelectedCohort
}) => {
  const [creditGrantingInstitutions, setCreditGrantingInstitutions] = useState([])
  const [creditGrantingInstitutionLoading, setCreditGrantingInstitutionLoading] = useState(false)
  const [creditGrantingInstitution, setCreditGrantingInstitution] = useState(null)
  const [groupedCourses, setGroupedCourses] = useState([])
  const [courseLoading, setCourseLoading] = useState(false)
  const [startDates, setStartDates] = useState([])
  const [attemptWarning, setAttemptWarning] = useState({
    show: false,
    message: ''
  })

  const fetchCreditGrantingInstitutions = async () => {
    setCreditGrantingInstitutionLoading(true)
    const creditGrantingInstitutionsOptions = await getCreditGrantingInstitutionsOptions()
    setCreditGrantingInstitutions(creditGrantingInstitutionsOptions)
    setCreditGrantingInstitutionLoading(false)
  }

  const {
    enrollmentOptions = isDegreePlusStudent ? ['GGU Degrees+ Standard'] : [],
    enrollmentCertificate,
    creditGrantingInstitution: creditGrantingInstitutionName
  } = selectedRelationship || {}

  const fetchCourses = async (selectedCreditGrantingInstitution) => {
    setCourseLoading(true)
    const courses = await api.getCourses()

    if (enrollmentCertificate?.length) {
      const groupedCourses = await groupCoursesForCertificate(courses, selectedCreditGrantingInstitution)
      setGroupedCourses(groupedCourses)
      setCourseLoading(false)
      return
    }

    const isUPittSelected = selectedCreditGrantingInstitution === UPitt
    const filteredCourses = isUPittSelected
      ? getFilteredCourses(courses, selectedCreditGrantingInstitution)
      : courses?.reduce((filtered, course) => {
        if (course.creditGrantingInstitution === selectedCreditGrantingInstitution) {
          const { id, displayName } = course || {}
          filtered.push({
            ...course,
            value: id,
            label: displayName
          })
        }
        return filtered
      }, [])
    setGroupedCourses(filteredCourses)
    setCourseLoading(false)
  }

  const groupCoursesForCertificate = async (courses, selectedCreditGrantingInstitution) => {
    const courseOptions = []
    const certificates = await api.getStudentCertificates(studentData?.email)
    const enrollmentCertificates = certificates?.filter(
      ({ certificateId }) => enrollmentCertificate.includes(certificateId)
    ) || []
    enrollmentCertificates.forEach(enrollmentCertificates => {
      const { certificateName, courses: certificateCourses } = enrollmentCertificates || {}
      const certificateOption = {
        label: `${certificateName} Courses`,
        options: certificateCourses.map(course => {
          const { id, name } = course || {}
          return {
            ...courses.find(course => course.id === id),
            value: id,
            label: name
          }
        })
      }
      courseOptions.push(certificateOption)
    })

    // filter courses by courses not in certificate
    const otherCourses = courses.filter(course => {
      const { id } = course || {}
      return !enrollmentCertificate.includes(id) &&
        course.creditGrantingInstitution === selectedCreditGrantingInstitution
    })

    const coursesLabel = courseOptions.length
      ? 'Other Courses'
      : 'Partner’s enrollment options'

    const groupedCourses = getFilteredCourses(otherCourses, selectedCreditGrantingInstitution)

    courseOptions.push({
      label: coursesLabel,
      options: groupedCourses
    })
    return courseOptions
  }

  useEffect(() => {
    if (!selectedCourse) {
      setAttemptWarning({
        show: false,
        message: ''
      })
      return
    }
    fetchStudentAttempts()
    // eslint-disable-next-line
  }, [selectedCourse])

  useEffect(() => {
    fetchCreditGrantingInstitutions()
    if (!selectedRelationship) return
    const { creditGrantingInstitution } = selectedRelationship
    setCreditGrantingInstitution({
      value: creditGrantingInstitution,
      label: creditGrantingInstitution
    })
    setSelectedCourse(null)
    fetchCourses(creditGrantingInstitution)
    // eslint-disable-next-line
  }, [selectedRelationship])

  useEffect(() => {
    if (!isDegreePlusStudent) return
    setCreditGrantingInstitution({
      value: GGU,
      label: GGU
    })
    setSelectedCourse(null)
    fetchCourses(GGU)
    // eslint-disable-next-line
  } , [isDegreePlusStudent])

  const handleInstitutionChange = (selectedOption) => {
    setCreditGrantingInstitution(selectedOption)
    setSelectedCourse(null)
    setSelectedDate(null)
    setSelectedDuration({})
    setSelectedIsAudit({})
    if (selectedOption) fetchCourses(selectedOption.value)
  }

  const fetchStudentAttempts = async () => {
    if (!selectedCourse) return
    const { value: selectedCourseId } = selectedCourse
    const { email } = studentData || {}
    const attempts = await api.getStudentAttempts({ studentEmail: email })

    const completedCourseAttempts = attempts?.filter(attempt => {
      const {
        fields: {
          course: { fields: { id: courseId } = {}
          },
          studentStatus: { fields: { Name: studentStatus } = {} }
        }
      } = attempt
      return studentStatus === COMPLETED && selectedCourseId === courseId
    })

    const showPittAttemptWarning = creditGrantingInstitutionName === UPitt &&
      completedCourseAttempts.length >= 3
    const showGGUAttemptWarning = creditGrantingInstitutionName === GGU &&
      completedCourseAttempts.length >= 2

    showPittAttemptWarning && setAttemptWarning({
      show: true,
      message: UPITT_WARNING_MESSAGE
    })
    showGGUAttemptWarning && setAttemptWarning({
      show: true,
      message: GGU_WARNING_MESSAGE
    })
  }

  const handleCourseChange = async (selectedOption) => {
    if (!selectedOption) {
      setSelectedCourse(null)
      setSelectedDate(null)
      setSelectedCohort(null)
      setSelectedDuration({})
      setSelectedIsAudit({})
      return
    }

    if (selectedOption.courses?.length === 1) {
      selectedOption = {
        ...selectedOption.courses[0],
        value: selectedOption.courses[0].id,
        label: selectedOption.courses[0].displayName
      }
    } else if (selectedOption.courses?.length > 1) {
      selectedOption.cohorts = selectedOption.courses.reduce((cohortList, course) => {
        const courseCohorts = course.cohorts || []
        return [...cohortList, ...courseCohorts]
      }, [])
    }

    const cohorts = selectedOption?.cohorts || []

    setSelectedCourse(selectedOption)
    const startDates = cohorts.reduce((dates, cohort) => {
      const startDate = convertEasternTimeToLocalTime(cohort?.dateStart, 'T06:00:00')
      if (!dates.includes(startDate)) {
        dates.push(startDate)
      }
      return dates
    }, [])
    setStartDates(startDates)
    setSelectedDate(null)
  }

  const durationOptions = useMemo(() => {
    return selectedDate
      ? getDurationOptionsByDate(selectedDate, selectedCourse)
      : getDurationOptions(enrollmentOptions)
  }, [enrollmentOptions, selectedCourse, selectedDate])

  const findAndSetCohort = (date, duration) => {
    const { cohorts } = selectedCourse
    const selectedDateWithoutTime = moment(date).startOf('day')

    const cohort = cohorts?.length && cohorts.find(cohort => {
      const cohortStartDate = moment(cohort.dateStart).startOf('day')
      const is14wkStandard = cohort.duration === 14 || cohort.duration === 15
      const is7wkIntensive = cohort.duration === 7 || cohort.duration === 8
      const is39wkExtended = cohort.duration === 39
      return selectedDateWithoutTime.isSame(cohortStartDate, 'day') && (
        (duration === '14wk Standard' && is14wkStandard) ||
        (duration === '7wk Intensive' && is7wkIntensive) ||
        (duration === '39wk Extended' && is39wkExtended)
      )
    })

    setSelectedCohort(cohort)
  }

  const handleDateChange = (date) => {
    setSelectedDate(date)
    setSelectedDuration({})
  }

  const handleDurationChange = (selectedOption) => {
    setSelectedDuration(selectedOption)
    findAndSetCohort(selectedDate, selectedOption?.duration)
  }

  const handleIsAuditChange = (selectedOption) => {
    setSelectedIsAudit(selectedOption)
  }

  const { partner: isPartner } = onBehalfOfPartner || {}
  const disableAuditAndDuration = !isPartner && !creditGrantingInstitution

  function handleClearDate () {
    setSelectedDate(null)
    setSelectedCohort(null)
  }

  return (
    <PageWrapper ref={enrollmentDetailsRef}>
      <CustomToolTip
        className='tooltip'
        multiline
        effect='solid'
        place='right'
      />
      <FormTitle>Enrollment details</FormTitle>
      {attemptWarning.show && (
        <SoftWarningMessage>
          <WarningIcon />
          <p>{attemptWarning.message}</p>
        </SoftWarningMessage>
      )}
      {selectedRelationship && (
        <>
          <EnrollmentOptions>
            Partner product offering <Info data-tip={ENROLLMENT_OPTIONS_TOOLTIP} />
          </EnrollmentOptions>
          {!!enrollmentOptions?.length && (
            <FormDescription style={{ marginBottom: '22px' }}>
              {enrollmentOptions.map((option, index) => (
                <span key={index}>
                  <span>{option}</span>
                  {index !== enrollmentOptions.length - 1 &&
                    <DotSeparator>.</DotSeparator>
                  }
                </span>
              ))}
            </FormDescription>
          )}
        </>
      )}
      <EnrollStudentForm style={{ gap: '32px' }}>
        <FormRow>
          <FormLabel>Credit-granting institution</FormLabel>
          {!isDegreePlusStudent ? (
            <Select
              isClearable
              isLoading={creditGrantingInstitutionLoading}
              value={creditGrantingInstitution}
              id='creditGrantingInstitution'
              options={creditGrantingInstitutions}
              name='creditGrantingInstitution'
              data-testid='credit-granting-institution-dropdown'
              placeholder='Select a credit-granting institution'
              styles={{ ...customDropDownStyles, height: '56px' }}
              components={{ DropdownIndicator, ClearIndicator }}
              onChange={handleInstitutionChange}
            />
          ) : (
            <FormInput
              data-testid='disabled-credit-granting-institution-dropdown'
              value={GGU}
              disabled
              style={{ border: `1px solid #5B6067` }}
            />
          )}
        </FormRow>
        <FormRow>
          <FormLabel>Course</FormLabel>
          {creditGrantingInstitution ? (
            <Select
              isClearable
              isDisabled={!creditGrantingInstitution}
              isLoading={courseLoading}
              id='creditGrantingInstitution'
              options={groupedCourses}
              value={selectedCourse}
              name='creditGrantingInstitution'
              data-testid='relationship-dropdown'
              placeholder='Select a course'
              styles={{ ...customDropDownStyles, height: '56px' }}
              components={{ DropdownIndicator, ClearIndicator, Group }}
              onChange={handleCourseChange}
            />
          ) : (
            <FormInput disabled />
          )}
        </FormRow>
        <FormRow>
          <FormLabel>Start date</FormLabel>
          {selectedCourse ? (
            <DatePickerWrapper selected={selectedDate}>
              <i
                id='clear-icon'
                onClick={() => handleClearDate()}
              />
              <DatePicker
                data-testid='date-picker'
                isLoading={creditGrantingInstitutionLoading}
                selected={selectedDate}
                onChange={handleDateChange}
                disabledKeyboardNavigation
                includeDates={startDates}
                placeholderText='Select date'
              />
            </DatePickerWrapper>
          ) : (
            <FormInput style={{ width: '279px' }} disabled />
          )}

        </FormRow>
        <FormRow>
          <RadioButtonSelector
            headerText={'Duration'}
            tooltipText={DURATION_TOOLTIP}
            exposeKey='duration'
            options={durationOptions}
            values={selectedDuration}
            setValue={handleDurationChange}
            isDisabled={disableAuditAndDuration || !selectedDate}
            noMargin
          />
        </FormRow>
        {isDegreePlusStudent ? (
          <FormRow>
            <FormLabel>Is the student auditing?</FormLabel>
            <FormDescription>
              GGU students cannot audit courses.
            </FormDescription>
          </FormRow>
        ) : (
          <FormRow>
            <RadioButtonSelector
              headerText={'Is the student auditing?'}
              exposeKey='isAudit'
              options={getIsAuditOptions()}
              values={selectedIsAudit}
              setValue={handleIsAuditChange}
              isDisabled={disableAuditAndDuration}
              noMargin
            />
          </FormRow>
        )}
      </EnrollStudentForm>
    </PageWrapper>
  )
}

export default memo(EnrollmentDetails)

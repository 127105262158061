import React from 'react'
import { Container, Dot } from './styled'

const HorizontalEllipsis = () => {
  return (
    <Container>
      <Dot />
      <Dot />
      <Dot />
    </Container>
  )
}

export default HorizontalEllipsis

import styled from 'styled-components'
import Button from '../../Button/Button'
import { DropdownItem, DropdownMenu } from 'reactstrap'

export const EditWrapper = styled.div`
  padding: 24px;
  width: 490px;
  min-height: 647px !important;

  & > .text-area {
    margin-bottom: unset !important;
  }
`

export const DropdownWrapper = styled.div`
  & > div > button {
    border: 1px solid rgba(255, 255, 255, 0.3) !important;
    box-shadow: unset !important;

    &:active,
    &:focus {
      border: 1px solid #5fc4b8 !important;
    }
  }
`

export const SectionTitle = styled.h1`
  font-family: 'Lato';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
`

export const SectionSubTitle = styled.h2`
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #b1bfc5;
  margin: 18px 0 12px 0;
  display: flex;
  align-items: baseline;
`

export const CodingAssignmentGradeTitle = styled(SectionSubTitle)`
  margin: 18px 0 9px 0;
`

export const GradeText = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 18px;
`

export const CodingAssignmentGrade = styled(GradeText)`
  letter-spacing: 1px;
  margin-left: 8px;
  margin-bottom: 0px;
`

export const TextInput = styled.input`
  height: 42px !important;
  width: 442px !important;
`

export const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  border-left: 1px solid var(--brand-regular);
`

export const StepName = styled.div`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
`

export const ScoreContainer = styled.div`
  color: var(--text-dark-off);
  display: flex;
  gap: 8px;
  align-items: center;
`

export const ScoreInput = styled.input`
  height: 36px !important;
  width: 35px !important;
  margin-bottom: 0px;
  &:hover {
    border-color: var(--brand-regular);
  }
  &:focus {
    border-color: var(--white);
  }
`

export const TextArea = styled.textarea`
  height: ${(props) =>
    props.height ? `${props.height} !important` : '72px !important'};
`

export const Text = styled.div`
  font-family: 'Lato';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  margin-bottom: 9px;
`

export const CustomButton = styled(Button)`
  font-size: 14px !important;
  line-height: 17px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px !important;
  font-weight: 700;
  height: 49px !important;
`

export const PrimaryButton = styled(CustomButton)`
  width: ${(props) =>
    props.width ? `${props.width} !important` : '107px !important'};
  height: 49px !important;
  background: #5fc4b8;
  &:hover {
    background: #b8fff7 !important;
  }
  &:disabled {
    opacity: 0.3;
  }
  &.btn-custom.btn-primary {
    font-size: 14px !important;
    line-height: 17px !important;
  }
`

export const SecondaryButton = styled(CustomButton)`
  border: 1px solid #5fc4b8;
  box-sizing: border-box;
  border-radius: 5px;
  width: ${(props) =>
    props.width ? `${props.width} !important` : '108px !important'};
  height: 49px !important;
  &.btn-custom.btn-secondary {
    border: 1px solid #5fc4b8;
    color: #5fc4b8;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-top: ${(props) =>
    props.top ? `${props.top} !important` : '24px !important'};
  ${(props) => props.bottom && `margin-bottom: ${props.bottom} !important; `}
`

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  > span {
    visibility: hidden;
    width: 255px;
    height: 62px;
    padding: 12px;
    background: #25272b;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: #ffffff;
    margin-bottom: -8px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: -50%;
    left: 400%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    > p {
      font-family: 'Lato';
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &:hover {
    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`
export const StyledDropdownMenu = styled(DropdownMenu)`
  background-color: transparent !important;
  padding: unset !important;
  margin: 13px unset unset unset !important;
`

export const DropDownMenuItem = styled(DropdownItem)`
  display: block !important;
  width: 100% !important;
  padding: 0.25rem 1.5rem !important;
  clear: both !important;
  font-weight: 400 !important;
  text-align: inherit !important;
  white-space: nowrap !important;
  background-color: transparent;
  width: 440px !important;
  height: 43px !important;
  background: #060606 !important;
  border: 2px solid rgba(255, 255, 255, 0.3) !important;
  box-sizing: border-box !important;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 50%) !important;
  border-radius: 5px !important;

  &:hover {
    background: #1f1f1f !important;
  }
`

export const CodingDropdownMenu = styled(DropdownMenu)`
  width: 100%;
  margin-top: 12px;
  padding: 0;
  border: 1px solid #383838;
  border-radius: 5px;
  background: #060606 !important;
`

export const CodingDropdownItem = styled(DropdownItem)`
  background: #060606;
  padding: 11px 14px;

  :first-child {
    border-radius: 5px 5px 0 0;
  }

  :last-child {
    border-radius: 0 0 5px 5px;
  }

  :not(:last-child) {
    border-bottom: 1px solid #383838;
  }
`

export const CodeGradeScoreNote = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-dark-off);
  margin-top: 4px;
`

import styled from 'styled-components'
import Select from 'react-select'
import mediaqueries from '../../../mediaQueries'

export const SingleValue = styled.div`
  ${props => props.isPlaceHolder && `
    color: #5B6067;
  `}
`

export const CustomSelect = styled(Select)`
  width: ${props => props.width || '300px'};
  min-height: ${props => props.height || '56px'};

  & > .select__control {
    width: 100%;
    height: auto;
    min-height: ${props => props.height || '56px'};
    padding: 0 12px;
    border: 1px solid var(--border-secondary);
    border-radius: 5px;

    &.select__control--is-focused {
      border: 1px solid #fff !important;

      .select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }

    .select__indicators {
      border: none;

      .select__clear-indicator {
        display: none;
      }

      .select__dropdown-indicator {
        padding: 0;
      }
    }

    .select__single-value {
      font-size: 16px;
    }

    .select__value-container {
      padding: 0;
      gap: 6px;
      font-size: 16px;

      .select__input {
        color: #fff;
      }

      .select__multi-value {
        display: flex;
        justify-content: space-between;

        width: 80px;
        height: 30px;
        background: var(--black-secondary);
        margin: 0;
        padding: 6px 8px 6px 12px;
        border-radius: 5px;

        .select__multi-value__label {
          padding: 0;
          color: #fff;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
        }

        .select__multi-value__remove {
        }

        .select__multi-value__remove:hover {
          background-color: transparent;
          color: #fff;
        }
      }
    }
  }

  & > .select__menu {
    width: 100%;
    max-height: 200px;
    color: white !important;
  }

  ${mediaqueries.smallMobile`
    width: 350px;
  `}

  @media (max-width: 375px) {
    width: 100%;
  }
`

export const courseSelectStyles = {
  indicatorSeparator: styles => ({ display: 'none' }),
  control: (base, state) => ({
    ...base,
    width: '100%',
    height: '40px',
    background: state.backgroundColor ? '#161618' : 'var(--black-tertiary)',
    color: 'white',
    border: '1px solid var(--border-secondary)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    opacity: state.isDisabled ? 0.3 : 1
  }),
  menu: base => ({
    ...base,
    width: 'fit-content',
    maxHeight: '296px !important',
    zIndex: 1000,
    padding: '8px 0',
    marginTop: '12px',
    background: 'var(--gray-primary)',
    border: 'none',
    borderRadius: '5px',
    boxSizing: 'border-box',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)'
  }),
  menuList: base => ({
    ...base,
    width: '100%',
    height: '100%',
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    width: '100%',
    height: 'fit-content',
    padding: '8px 16px',
    position: 'relative',
    fontSize: '16px',
    lineHeight: '24px',
    color: state.isSelected ? '#fff' : 'var(--sub-text-secondary)',
    backgroundColor: 'var(--gray-primary)',
    borderRadius: '5px',
    cursor: 'pointer',
    border: '1px solid transparent',
    borderTop: state.data.isDeleteOption ? '1px solid rgba(255, 255, 255, 0.1)' : 'none',
    marginTop: state.data.isDeleteOption ? '8px' : '0',
    '&:hover': {
      color: '#fff',
      border: '1px solid var(--brand-regular)'
    },
    '&:active': {
      backgroundColor: 'var(--gray-primary)',
      opacity: 0.3
    },
    '&:after': state.isSelected ? {
      content: '""',
      position: 'absolute',
      top: '13px',
      right: '18px',
      width: '12px',
      height: '8px',
      borderBottom: '1px solid var(--brand-regular)',
      borderLeft: '1px solid var(--brand-regular)',
      transform: `rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg)`
    } : { display: 'none' }
  }),
  placeholder: base => ({
    ...base,
    margin: 0,
    color: 'var(--border-secondary)',
    fontSize: '16px',
    lineHeight: '24px',
    paddingLeft: '2px'
  }),
  singleValue: base => ({
    ...base,
    color: '#fff',
    paddingLeft: '6px',
    margin: 0,
    fontSize: '18px',
    lineHeight: '22px'
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#fff'
  }),
  indicatorContainer: base => ({
    ...base,
    paddingLeft: 0
  })
}

import React, { useState, useEffect, useRef, useCallback } from 'react'
import {
  UploadingInput,
  FileUploaderContainer,
  FilePreview,
  Link,
  UploadIcon,
  TextOverlay
} from './styles'
import FileUploadIcon from '../../assets/icons/uploadIcon.svg'
import FileUploadRemoveIcon
from '../../assets/icons/icon-file-upload-remove.svg'
import { bytesToSize } from '../../utilities/fileUploadUtils'

const DownloadLink = ({ label, link, fileName, isSmallInput }) => {
  return (
    <>
      <Link
        isSmallInput={isSmallInput}
        href={link}
        target='_blank'
        download={fileName}>{label}</Link>
    </>
  )
}

export default function FileUploader ({
  isSmallInput,
  onFileSelect,
  isDisabled = false,
  uploadedContent,
  onUnsupportedFormat,
  onEmpty }) {
  const [file, setFile] = useState(undefined)
  const [showLink, setShowLink] = useState(false)
  const fileUploadRef = useRef(null)
  const fileUploadContainerRef = useRef(null)

  useEffect(() => {
    setFile(uploadedContent)
    uploadedContent && setShowLink(true)
    // eslint-disable-next-line
  }, [uploadedContent])

  const preventDefault = useCallback((e) => {
    e.stopPropagation()
    e.preventDefault()
  }, [])

  const drop = useCallback((e) => {
    preventDefault(e)

    const dt = e.dataTransfer
    const file = dt.files[0]

    handleFileUpload(file)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const fileUploadContainerNode = fileUploadContainerRef.current

    fileUploadContainerNode
      .addEventListener('dragenter', preventDefault, false)
    fileUploadContainerNode
      .addEventListener('dragover', preventDefault, false)
    fileUploadContainerNode
      .addEventListener('drop', drop, false)

    return () => {
      fileUploadContainerNode.removeEventListener('dragenter', preventDefault)
      fileUploadContainerNode.removeEventListener('dragover', preventDefault)
      fileUploadContainerNode.removeEventListener('drop', drop)
    }
    // eslint-disable-next-line
  }, [preventDefault, drop])

  const handleFileUpload = async (fileObj) => {
    const fileExtention = fileObj.name.match(/\.[0-9a-z]+$/i)[0]
    const allowedExtentions = ['.csv']

    if (fileObj.size > 20000000) {
      alert('Maximum file size: 20MB')
      return
    }

    if (!allowedExtentions.includes(fileExtention)) {
      onUnsupportedFormat(fileExtention)
      return
    }

    setFile(fileObj)
    setShowLink(false)
    if (!fileObj) return
    await onFileSelect(fileObj)
  }

  const removeFile = () => {
    setFile(undefined)
    onEmpty()
  }

  return (
    <>
      <FileUploaderContainer
        isSmallInput={isSmallInput}
        ref={fileUploadContainerRef}
        hasFile={file}
        isDisabled={isDisabled}
      >
        {!isSmallInput && <UploadIcon src={FileUploadIcon} alt='Upload Icon' />}
        <TextOverlay data-testid='upload-text'>
          Drag and drop your .CSV file here {!isSmallInput && <br />}
          or
          <span onClick={() => {
            fileUploadRef.current.click()
          }}>
            {file ? 'Replace file' : 'BROWSE'}
          </span>
        </TextOverlay>
        <UploadingInput type='file'
          multiple={false}
          value={''}
          data-testid='upload-input'
          accept='.csv'
          onChange={evt => {
            handleFileUpload(evt.target.files[0])
          }}
          ref={fileUploadRef}
        />
        <div id='overlay' />
      </FileUploaderContainer>
      {file &&
      <FilePreview>
        <p data-testid='file-preview-text'>
          {showLink ? <DownloadLink
            isSmallInput={isSmallInput}
            fileName={file.name}
            label={`${file.name || 'View Uploaded File'}`}
            link={file.url} /> : file.name}
        </p>

        <p id='remove' onClick={removeFile}>
          <span>{bytesToSize(file.size)}</span>
          <img
            src={FileUploadRemoveIcon}
            width='9'
            height='9'
            alt='file-upload-remove'
          />
        </p>
      </FilePreview>
      }
    </>
  )
}

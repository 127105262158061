import React, { useReducer } from 'react'
import api from '../api'
import {
  getCurrentAssignment,
  getFilteredSubmissions } from '../utilities/writingGradeCenterUtils'
import { GRADING_STATUS, SUBMISSION_STATUS } from '../Constants'
import {
  SET_ALL_ASSIGNMENTS,
  SET_COURSES_WITH_ASSIGNMENTS,
  SET_CURRENT_ASSIGNMENT,
  SET_DEFAULT_COHORTS,
  SET_SELECTED_ASSIGNMENTS,
  SET_SELECTED_COHORTS,
  SET_ACTIVE_COHORTS_SELECTED,
  SET_PAST_COHORTS_SELECTED,
  SET_SELECTED_COURSE,
  SET_SELECTED_GRADING_STATUSES,
  SET_SELECTED_SUBMISSION_STATUSES,
  SET_SUBMISSIONS_ASCENDING,
  SET_FILTERED_SUBMISSIONS,
  SET_STUDENTS_BY_COHORT
} from '../Constants/actionTypes'

const WritingGradeCenterContext = React.createContext()

const initialState = {
  coursesWithAssignments: [],
  selectedCourse: null,
  selectedGradingStatuses: [GRADING_STATUS[0]],
  selectedSubmissionStatuses: SUBMISSION_STATUS,
  selectedAssignments: [],
  defaultCohorts: [],
  selectedCohorts: [],
  isAllActiveCohortsSelected: false,
  isAllPastCohortsSelected: false,
  allAssignments: [],
  filteredAssignments: [],
  studentsByCohort: null,
  currentAssignment: null,
  isSubmissionsAscendingSort: false,
  filteredSubmissions: []
}

export function reducer (state, action) {
  switch (action.type) {
    case SET_COURSES_WITH_ASSIGNMENTS:
      return {
        ...state,
        coursesWithAssignments: action.courses
      }
    case SET_SELECTED_COURSE:
      return {
        ...state,
        selectedCourse: action.course
      }
    case SET_SELECTED_GRADING_STATUSES:
      return {
        ...state,
        selectedGradingStatuses: action.gradingStatuses
      }
    case SET_SELECTED_SUBMISSION_STATUSES:
      return {
        ...state,
        selectedSubmissionStatuses: action.submissionStatuses
      }
    case SET_SELECTED_ASSIGNMENTS:
      return {
        ...state,
        selectedAssignments: action.assignments
      }
    case SET_DEFAULT_COHORTS:
      return {
        ...state,
        defaultCohorts: action.cohorts
      }
    case SET_SELECTED_COHORTS:
      return {
        ...state,
        selectedCohorts: action.cohorts
      }
    case SET_ACTIVE_COHORTS_SELECTED:
      return {
        ...state,
        isAllActiveCohortsSelected: action.isAllActiveCohortsSelected
      }
    case SET_PAST_COHORTS_SELECTED:
      return {
        ...state,
        isAllPastCohortsSelected: action.isAllPastCohortsSelected
      }
    case SET_ALL_ASSIGNMENTS:
      return {
        ...state,
        allAssignments: action.assignments
      }
    case SET_FILTERED_SUBMISSIONS:
      return {
        ...state,
        filteredSubmissions: action.filteredSubmissions
      }
    case SET_SUBMISSIONS_ASCENDING:
      return {
        ...state,
        isSubmissionsAscending: action.isSubmissionsAscending
      }
    case SET_CURRENT_ASSIGNMENT:
      return {
        ...state,
        currentAssignment: action.currentAssignment
      }
    case SET_STUDENTS_BY_COHORT:
      return {
        ...state,
        studentsByCohort: {
          ...state.studentsByCohort,
          [action.cohortId]: action.studentsByCohort }
      }
    default: return { ...state }
  }
}

export default function WritingGradeCenterProvider ({ children, providedState }) {
  const [state, dispatch] = useReducer(reducer, providedState || initialState)

  const setCoursesWithAssignments = courses => {
    dispatch({ type: SET_COURSES_WITH_ASSIGNMENTS, courses })
  }

  const setAllAssignments = assignments => {
    dispatch({ type: SET_ALL_ASSIGNMENTS, assignments })
  }

  const setSelectedCourse = course => {
    dispatch({ type: SET_SELECTED_COURSE, course })
  }

  const setSelectedGradingStatuses = gradingStatuses => {
    dispatch({ type: SET_SELECTED_GRADING_STATUSES, gradingStatuses })
  }

  const setSelectedSubmissionStatuses = submissionStatuses => {
    dispatch({ type: SET_SELECTED_SUBMISSION_STATUSES, submissionStatuses })
  }

  const setSelectedAssignments = assignments => {
    dispatch({ type: SET_SELECTED_ASSIGNMENTS, assignments })
  }

  const setDefaultCohorts = cohorts => {
    dispatch({ type: SET_DEFAULT_COHORTS, cohorts })
  }

  const setSelectedCohorts = cohorts => {
    dispatch({ type: SET_SELECTED_COHORTS, cohorts })
  }

  const setAllActiveCohortsSelected = isAllActiveCohortsSelected => {
    dispatch({ type: SET_ACTIVE_COHORTS_SELECTED, isAllActiveCohortsSelected })
  }

  const setAllPastCohortsSelected = isAllPastCohortsSelected => {
    dispatch({ type: SET_PAST_COHORTS_SELECTED, isAllPastCohortsSelected })
  }

  const setSubmissionsAscending = isSubmissionsAscending => {
    dispatch({ type: SET_SUBMISSIONS_ASCENDING, isSubmissionsAscending })
  }

  const fetchCurrentAssignment = async ({
    courseId,
    assignmentUUID,
    cohortName
  }) => {
    const currentAssignment = await getCurrentAssignment({
      courseId,
      assignmentUUID,
      cohortName
    })

    dispatch({ type: SET_CURRENT_ASSIGNMENT, currentAssignment })
  }

  const fetchSubmissions = async ({
    courseId,
    cohortNamesList,
    assignmentList,
    fromCache
  }) => {
    const filteredSubmissions = await getFilteredSubmissions({
      courseId,
      cohortNamesList,
      assignmentList,
      fromCache
    })

    dispatch({ type: SET_FILTERED_SUBMISSIONS, filteredSubmissions })
  }

  const fetchStudentsByCohort = async (cohortId) => {
    const studentsByCohort = await api.getStudentsByCohort(cohortId)

    dispatch({ type: SET_STUDENTS_BY_COHORT, cohortId, studentsByCohort })
  }

  const actions = {
    setCoursesWithAssignments,
    setSelectedCourse,
    setSelectedGradingStatuses,
    setSelectedSubmissionStatuses,
    setSelectedAssignments,
    setDefaultCohorts,
    setSelectedCohorts,
    setAllActiveCohortsSelected,
    setAllPastCohortsSelected,
    setAllAssignments,
    fetchStudentsByCohort,
    fetchCurrentAssignment,
    setSubmissionsAscending,
    fetchSubmissions
  }

  return (
    <WritingGradeCenterContext.Provider value={{ state, actions }}>
      {children}
    </WritingGradeCenterContext.Provider>
  )
}

export function useWritingGradeCenterContext () {
  return React.useContext(WritingGradeCenterContext).state
}

export function useWritingGradeCenterActions () {
  return React.useContext(WritingGradeCenterContext).actions
}

import styled from 'styled-components/'
import { courseSelectStyles } from '../global.styled'
import mediaqueries, { BREAKPOINTS } from '../../mediaQueries'

export const Container = styled.div`
  margin: 64px 32px;
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${BREAKPOINTS.mobile + 0.98}px) {
    flex-direction: column;
    gap: 24px;
  }
`
export const Header = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 0;
`
export const ExtensionTitle = styled.span`
font-weight: 300;
font-size: 24px;
line-height: 32px;
`
export const AddExtensionButton = styled.a`
  width: 174px !important;
  line-height: 16px !important;
  font-size: 14px !important;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-top: 0;
  background-color: #5FC4B8 !important;

  &:hover {
    background-color: var(--brand-regular-hover)!important;
  }

  &:active {
    background-color: var(--brand-regular-active) !important;
    opacity: 0.3;
  }
`
export const Separator = styled.hr`
  margin: 32px 0 35px;
  height: 1px;
  border-color: rgba(255, 255, 255, 0.3);
`

export const TableHeaderWrapper = styled.div`
  padding: 0 17px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  ${mediaqueries.tablet`
    & > div > div:not(:last-child) {
      display: none;
    }
    & > div > div:last-child {
      width:100%;
    }
  `}
`
export const ExtensionRowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  ::before{
    width: ${props =>
    props.expandRow ? '2px' : 0
};
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    background-color: #5FC4B8;
    top: 0;
  }

`

export const Row = styled.div`
  display: flex;
  gap: 32px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

export const RecordRow = styled(Row)`
  cursor: auto;
  padding-top:  ${props =>
    props.showDivider ? '32px' : 0
};
  border-top:${props =>
    props.showDivider ? '1px solid #25272B' : 0
};
  padding-bottom: ${props =>
    props.differentCohortThanNext ? '32px' : 0
};

${({ isRowDisabled }) => isRowDisabled && `
  & > *:not(.zendesk-cell) {
    opacity: 0.3;
    pointer-events: none;
  }
`}

  ${mediaqueries.tablet`
    flex-direction: column;
    align-items: flex-start;
    gap:0;
  & > div {
    min-height: 50px;
    width: 100%!important;
  }
  `}
`

export const NoExtensionsMessage = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
`

export const ExtensionsTableWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`

export const HeaderCell = styled.div`
  width: 315px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #B1BFC5;
`
export const ExtensionRecordHeaderCell = styled(HeaderCell)`
  color: #89969F;
  display: flex;
`
export const ExtensionRecordLabel = styled(HeaderCell)`
  display: flex;
  color: var(--text-secondary);
  min-width: 150px;
  max-width: 150px;
  margin-right: 34px;
  white-space: pre-wrap;
  ${mediaqueries.mobile`
    min-width: 100px;
    max-width: 100px;
    margin-right: 25px;
  `}
`

export const StudentRow = styled(Row)`
  padding:0 17px 0 17px;
  &:hover{
    background-color: #1D1D1F;
  }
  ${mediaqueries.tablet`
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    gap:0;
    padding-block: 24px;
    & > div:not(:last-child) {
      min-height: 50px;
      width: 100%!important;
    }

    & > div:not(:last-child)::before {
      content: attr(data-label);
      text-transform: uppercase;
      width: min(150px, 150px);
      margin-right: 34px;
      font-weight: 700;
      font-size: 14px;
      color: var(--text-secondary)
    }

    & > div:last-child {
      min-height: 50px;
      position: absolute;
      right: 0;
      width: 100%;
      padding-right: 17px;
    }
  `}
  ${mediaqueries.mobile`
  & > div:not(:last-child)::before {
    width: min(100px, 100px);
    margin-right: 25px;
  }
`}
`
export const StudentCell = styled(HeaderCell)`
  word-break: break-word;
  min-height: 70px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  text-transform: none;
`
export const CohortCell = styled(StudentCell)`
  flex-direction: column;
  align-items: baseline;
  gap: 12px;
  ${mediaqueries.tablet`
    flex-direction: row;
    gap: 0;
  `}
`

export const CohortEnded = styled.span`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  color : #161618;
  background-color: #98A4AE;
  padding: 4px;
  border-radius: 5px;
  ${mediaqueries.tablet`
    margin-inline-start: 32px;
    text-align: center;
  `}
  ${mediaqueries.mobile`
    margin-inline-start: 20px;
  `}
`

export const ZendeskCell = styled(StudentCell)`
  & > a {
    color: #5FC4B8;
    text-decoration: underline;
    text-underline-offset: 4px;
    cursor: pointer;
  }
`
export const StudentCellLight = styled(StudentCell)`
font-weight: 300;
`
export const EditLinkCell = styled(StudentCell)`
  color: #5FC4B8;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
`
export const ToolTipContainer = styled.div`
  margin-left: 13px;
  position: relative;
  display: flex;
  &:hover {
    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`
export const ToolTip = styled.span`
  visibility: hidden;
  width: 225px;
  height: 120px;
  padding: 12px;
  position: absolute;
  left: 29px;
  line-height: 24px;
  color: #FFFFFF;
  text-transform: none;
  font-weight: normal;
  background-color: #25272B;
  top: 50%;
  transform: translateY(-50%);
  letter-spacing: normal;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
`
export const ExtensionsRecordsWrapper = styled.div`
  padding:0 0 32px 24px;
  ${mediaqueries.tablet`
    padding-block-start: 0;
    padding-block-end: 24px;
    padding-inline-start: 17px;
  `}
`
export const ExtensionRecordsHeaderWrapper = styled(Row)`
  padding: 25px 0 25px 0;
  cursor: auto;
  ${mediaqueries.tablet`
  padding: 0;
  & > div {
    display: none;
  }
  `}
`

export const PaginationContainer = styled.div`
  background-color: #1D1D1F;
  color: #5FC4B8;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
`
export const PageIndex = styled.button`
  background-color: #1D1D1F;
  border: none;
  padding: 0;
  color: ${props => props.isCurrentPage ? 'white' : '#5FC4B8'};
  &:hover {
    color: ${props => props.isBreak ? '#5FC4B8' : '#B8FFF7'};
  }
  &:focus {
    outline: none;
  }
  &:active {
    color: #B8FFF7;
  }
`

export const NavigationIcon = styled.img`
cursor: pointer;
`
export const PageIndicator = styled.span`
  color: #B1BFC5;
  margin-left: auto;
`
export const DropDownContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  padding: 16px 0 48px 0;
  ${mediaqueries.tablet`
    padding-bottom: 0;
    margin-bottom: -10px;
  `}
  @media (max-width: ${BREAKPOINTS.mobile + 0.98}px) {
    flex-direction: column;
  }
`
export const DropDownWrapper = styled.div`
  height: 56px;
  margin-right: 10px;
  margin-bottom: 10px;
  & select{
    height: 56px;
  }
`
export const DropDownLabel = styled.span`
  color: #B1BFC5;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
`
export const CustomInput = styled.input`
  height: 56px;
  width: 300px;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: normal;
  font-size: 16px;
  &::placeholder{
    color: #5B6067;
  }

  &:disabled, &:focus {
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    box-shadow: 0 0 0 0;
  }
  &:focus {
    border-color: #5FC4B8;
  }
  &:hover{
  border: 1px solid #5FC4B8;
  }
`
export const extensionDropDownStyles = {
  ...courseSelectStyles,
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#5B6067'
    }
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: '#FFFFFF',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
    padding: state.selectProps.menuIsOpen ? '0 0 0 17.5px' : '0 17.5px 0 0',
    '&:hover': {
      color: 'white'
    },
    ...(state.hasValue && { display: 'none' })
  }),
  clearIndicator: base => ({
    ...base,
    paddingRight: '17.5px'
  }),
  input: provided => ({
    ...provided,
    color: 'white'
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: 'black',
    width: '300px'
  }),
  control: (base, state) => ({
    ...base,
    width: '300px',
    height: '56px',
    background: ' rgba(0, 0, 0, 0.3)',
    color: 'white',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    opacity: state.isDisabled ? 0.3 : 1,
    '&:hover': {
      border: '1px solid #5FC4B8'
    },
    '&:focus-within': {
      borderColor: 'white'
    }
  })
}

export const InputWrapper = styled(DropDownWrapper)`
  position: relative;
`
export const ClearIcon = styled.img`
  position: absolute;
  right: 17.5px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
`

import styled from 'styled-components'

export const StyledContainer = styled.div`
  margin-top: 50px;
`

export const StyledFiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 50px;
`

export const DropDownWrapper = styled.div`
  margin-right: 10px;
  margin-bottom: 10px;  
`

export const DropDownLabel = styled.span`
  color: #B1BFC5;
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 400;
`

export const FilterLabel = styled.span`
  font-size: 14px;
  line-height: 16.8px;
  text-transform: uppercase;
  color: #5FC4B8;
  margin-left: 24px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 3px;

  img {
    margin-right: 5px;
    margin-bottom: 4px;
    height: 16px;
    width: 16px;
  }
`

export const ResetButton = styled(FilterLabel)`
  margin-left: 8px;
  cursor: pointer;
`

export const StyledButtonWrapper = styled.div`
  margin-left: 20px;
`

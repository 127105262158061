import React, { useState, useEffect } from 'react'
import papa from 'papaparse'
import { mapCsvToDatabase } from './helper'
import {
  ALERT, gguDecisionCSVColumns, steps, SUCCESS_MESSAGE_GGU, SUCCESS_MESSAGE_STUDENTS
} from './CSVImportConstants'
import {
  BackLink
} from '../PermissionManager/styles'
import ModalBackArrow from '../../assets/icons/modal-back-arrow.svg'
import { getBase64 } from '../../utilities/CSVDownloadTool'
import AlertModal from '../AlertModal/AlertModal'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import api from '../../api'
import CSVUpload from './CSVUpload'
import CSVColumnMapping from './CSVColumnMapping'
import CSVReview from './CSVReview'
import {
  IndicatorsContainer,
  ActiveIndicator,
  Container,
  Separator,
  Header
} from './style'
import { useHistory } from 'react-router-dom'

const BackLinkStyles = {
  marginTop: 15,
  marginLeft: 35
}

const CSVStudentImport = ({ isGGUAddmissionDecision }) => {
  const { UPLOAD_CSV, COLUMN_MAPPING, REVIEW } = steps
  const [step, setStep] = useState(UPLOAD_CSV)
  const [file, setFile] = useState(undefined)
  const [csvData, setCSVData] = useState([])
  const [csvColumns, setCSVColumns] = useState([])
  const [csvMapping, setCSVMapping] = useState({})
  const [databaseColumns, setDatabaseColumns] = useState([])
  const [fileError, setFileError] = useState(null)
  const [fileUrl, setFileUrl] = useState(undefined)
  const [isAlertModal, setIsAlertModal] = useState(ALERT.HIDE)
  const [alertModalMessage, setAlertModalMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [errorsTable, setErrorsTable] = useState([])
  const history = useHistory()

  useEffect(() => {
    const getMappedCsvData = async () => {
      if (!file) return
      const csvBase64 = await getBase64(file)
      const csvBase64String = csvBase64.split(',')[1]
      const csvString = Buffer.from(csvBase64String, 'base64').toString('ascii')

      /* Creates JSON array of objects from simple csv string, uses default delimiter */
      const { data } = papa.parse(csvString,
        { header: true, skipEmptyLines: true })

      let { columnData, csvData } = mapCsvToDatabase(data, csvColumns, isGGUAddmissionDecision)
      setCSVData(csvData)
      /* Unparse it back to csv string and create base64 from it */
      const newCsvString = Buffer.from(papa.unparse(csvData), 'ascii').toString('base64')

      /* remove prospects table name since the backend doesn't expect it */
      if (isGGUAddmissionDecision) {
        columnData = columnData?.map(e => {
          return { ...e, databaseColumn: e.databaseColumn }
        })
      }

      setCSVMapping({ csvString: newCsvString, csvColumns: columnData })
    }

    getMappedCsvData()
  }, [file, csvColumns, isGGUAddmissionDecision])

  const resetState = () => {
    setStep(UPLOAD_CSV)
    setFile(undefined)
    setCSVColumns([])
    setDatabaseColumns([])
    setFileUrl(undefined)
    setIsAlertModal(ALERT.HIDE)
    setAlertModalMessage('')
    setIsLoading(false)
    setErrorsTable([])
  }

  const renderSteps = (step) => {
    switch (step) {
      case UPLOAD_CSV:
        return <CSVUpload
          file={file}
          setFile={setFile}
          handleUploadedFile={handleUploadedFile}
          fileError={fileError}
          setStep={setStep}
          isGGUAddmissionDecision={isGGUAddmissionDecision}
        />
      case COLUMN_MAPPING:
        return <CSVColumnMapping
          csvColumns={csvColumns}
          databaseColumns={databaseColumns}
          handleColumnInputChange={handleColumnInputChange}
          handleSubmit={handleSubmit}
          setStep={setStep}
          isGGUAddmissionDecision={isGGUAddmissionDecision}
        />
      case REVIEW:
        return <CSVReview
          file={file}
          fileUrl={fileUrl}
          csvData={csvData}
          csvColumns={csvColumns}
          errorsTable={errorsTable}
          handleUploadedFile={handleUploadedFile}
          handleImport={handleImport}
          setStep={setStep}
          setFile={setFile}
          isGGUAddmissionDecision={isGGUAddmissionDecision}
          setErrorsTable={setErrorsTable}
        />
      default:
        return <></>
    }
  }

  const parseUploadedFile = async fileObj => {
    setFile(fileObj)

    const blob = new Blob([fileObj], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)
    const endpoint = isGGUAddmissionDecision
      ? api.mapGGUAdmissionCSVHeaders
      : api.mapCSVHeaders
    const fieldName = isGGUAddmissionDecision
      ? 'prospects'
      : 'students'

    setFileUrl(url)

    const formData = new FormData()
    formData.append(fieldName, fileObj)
    setIsLoading(true)
    const res = await endpoint(formData)
    setIsLoading(false)

    const { error } = res
    if (error) {
      setCSVColumns([])
      setDatabaseColumns([])
      setFile(undefined)
      setFileError(error)
      return
    }

    setCSVColumns(res.csvColumns)
    setDatabaseColumns(res.databaseColumns)
  }

  const handleUploadedFile = async (fileObj) => {
    setFileError(null)

    if (!isGGUAddmissionDecision) {
      await parseUploadedFile(fileObj)
      return
    }

    papa.parse(
      fileObj,
      {
        header: true,
        skipEmptyLines: true,
        complete: async results => {
          const { name, type } = fileObj || {}
          const newCsvArray = results?.data?.reduce((acc, row, index) => {
            const newRow = {}
            const lowerCaseColumns = gguDecisionCSVColumns.map(value => value.toLowerCase())

            Object.keys(row).forEach(column => {
              const sampleColumnEquivalent = lowerCaseColumns.find(value => {
                return value.includes(column.toLowerCase())
              })
              if (!sampleColumnEquivalent) return
              newRow[column] = row[column]
            })

            acc.push(newRow)
            return acc
          }, []) || []

          const newCSVString = papa.unparse(newCsvArray)
          const blob = new Blob([newCSVString], { type: 'text/csv;charset=utf-8;' })
          const newFile = new File([blob], name, { type })
          await parseUploadedFile(newFile)
        }
      }
    )
  }

  const handleColumnInputChange = (index, value) => {
    const updatedCsvColumns = csvColumns.map((column, i) => {
      return i === index ? value : column
    })
    setCSVColumns(updatedCsvColumns)
  }

  const handleImport = async () => {
    const endpoint = isGGUAddmissionDecision
      ? api.importGGUAdmissionDecision
      : api.importStudentData
    const SUCCESS_MESSAGE = isGGUAddmissionDecision
      ? SUCCESS_MESSAGE_GGU
      : SUCCESS_MESSAGE_STUDENTS

    try {
      setIsLoading(true)
      const { success, errors, error } = await endpoint({
        flags: {
          import: true,
          tokens: true
        },
        csvBase64String: csvMapping.csvString,
        importData: true,
        columnData: csvMapping.csvColumns
      })
      setIsLoading(false)

      if (success) {
        setAlertModalMessage(SUCCESS_MESSAGE)
        setIsAlertModal(ALERT.SUCCESS)
        return
      }

      setErrorsTable(errors || [])
      if (error) {
        setAlertModalMessage(error)
        setIsAlertModal(ALERT.ERROR)
      }
    } catch (e) {
      console.error('Error when importing csv: ', e.message)
    }
  }

  const handleSubmit = async () => {
    const endpoint = isGGUAddmissionDecision
      ? api.importGGUAdmissionDecision
      : api.importStudentData

    try {
      setIsLoading(true)
      setStep(REVIEW)
      const { success, errors } = await endpoint({
        flags: {
          import: false,
          tokens: true
        },
        csvBase64String: csvMapping.csvString,
        columnData: csvMapping.csvColumns
      })
      if (!success) {
        setErrorsTable(errors || [])
      }
      setIsLoading(false)
    } catch (e) {
      console.error('Error which checking uploaded csv: ', e.message)
    }
  }

  return (
    <>
      <BackLink onClick={() => history.goBack()}style={BackLinkStyles} >
        <img src={ModalBackArrow} alt='back-arrow' />
        <span>GO BACK</span>
      </BackLink>

      <Container style={{ marginTop: 32 }}>
        <Header>{isGGUAddmissionDecision
          ? 'GGU ADMISSION DECISION IMPORT'
          : 'add new student - CSV Import'}</Header>
        <Separator />

        <IndicatorsContainer style={{ marginBottom: 36 }}>
          <ActiveIndicator active={step === UPLOAD_CSV}>
            <span>1</span> Upload CSV
          </ActiveIndicator>
          <ActiveIndicator active={step === COLUMN_MAPPING}>
            <span>2</span> Column Mapping
          </ActiveIndicator>
          <ActiveIndicator active={step === REVIEW}>
            <span>3</span> Review
          </ActiveIndicator>
        </IndicatorsContainer>

        {isLoading ? <LoadingSpinner /> : renderSteps(step)}
      </Container>

      <AlertModal
        showModalHandler={() => setIsAlertModal(ALERT.HIDE)}
        confirm={{
          text: 'ok',
          handler: () => {
            (isAlertModal === ALERT.SUCCESS) ? resetState() : setIsAlertModal(ALERT.HIDE)
          }
        }}
        show={!!isAlertModal}
      >
        {alertModalMessage}
      </AlertModal>
    </>
  )
}

CSVStudentImport.displayName = 'CSVStudentImport'

export default CSVStudentImport

import styled from 'styled-components'
import { courseSelectStyles } from '../global.styled'

export const GradingCheckWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px 48px;
  gap: 24px;
`

export const GradingCheckHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13.6px;
`

export const GradingCheckHeaderTitle = styled.p`
  color: #FFF;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 48px;
  margin: unset;
`

export const Divider = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.3);
`

export const GradingIssuesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const GradingCheckTitle = styled.p`
  color: #F5F5F5;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin: unset;
`
export const GradingIssuesHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
`

export const GradingIssueRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`

export const GradingIssueRowText = styled.p`
  color: #B1BFC5;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  margin: unset;
`

export const CoursesViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const dropDownStyles = {
  ...courseSelectStyles,
  control: (base) => ({
    ...base,
    width: '170px',
    height: 'auto',
    background: 'none',
    color: 'white',
    border: 'none',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:focus-within': {
      borderColor: 'var(--brand-regular)'
    },
    justifyContent: 'unset'
  }),
  menu: (base, state) => ({
    ...base,
    width: state.selectProps.width ? state.selectProps.width : '285px',
    background: ' #060606',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    zIndex: 1000
  }),
  valueContainer: base => ({
    ...base,
    fontSize: '24px',
    backgroundColor: 'inherit',
    color: '#F5F5F5',
    lineHeight: '32px',
    border: 'none',
    borderRadius: '5px',
    padding: 'none',
    flex: 'unset'
  }),
  input: base => ({
    ...base,
    color: 'var(--text-primary)',
    height: 'auto'
  }),
  placeholder: base => ({
    ...courseSelectStyles.placeholder({}),
    fontSize: '24px',
    margin: 'unset'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null
  })
}

export const CoursesViewTitle = styled.p`
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; 
  margin: unset;
`

export const CoursesViewDescription = styled.p`
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin: unset;
`

export const CourseType = styled.p`
  color: #B1BFC5;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  margin: unset;
`

export const DotsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 28.3px;
  margin-top: 24px;
`

export const TooltipContainer = styled.div`
  position: relative;
  &:hover {
    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const ToolTip = styled.span`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  width: max-content;
  top: -3px;
  transform: translateY(-100%);
  transition: opacity 0.2s ease-in-out;
  left: 50%;
  color: #B1BFC5;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`
export const CourseDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px 48px;
  gap: 34px;
`
export const CourseMismatchText = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
`
export const CourseMatchText = styled.div`
  color: #F5F5F5;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`
export const CourseDetailsLink = styled.a`
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  color: var(--brand-regular);
  line-height: 17px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  &:hover {
    color: var(--brand-regular-hover);
    text-decoration: none;
  }
`
export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`
export const RowContainer = styled.div`
  display: flex;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent!important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 20px;
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: column;
`
export const RowHeader = styled.span`
  border: 1px solid var(--5B6067---Gray-3, #5B6067);
  padding: 8px;
  color: #CCD4D8;
  text-align: center;
  text-transform: capitalize;
  white-space: nowrap;
`
export const StudentRowHeader = styled(RowHeader)`
  width: 163px;
`
export const RowContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border: 1px solid var(--5B6067---Gray-3, #5B6067);
  padding: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #FFF;
`
export const RowGrade = styled(RowContent)`
  color: ${props => props.isTotalGradeAndMatched ? '#FFF' : '#F2765A'};
  justify-content: left;
`

import styled from 'styled-components'
import { CustomButton } from '../StudentLevel/styled'

export const VoucherContainer = styled.div`
 display: flex;
`

export const StyledButton = styled(CustomButton)`
  width: 200px;
  height: 50px;
`

export const StyledButtonsWrapper = styled.div`
  margin-left: 20px;
`

export const NoVouchersHeading = styled.h6`
  width: 100%;
`

import React from 'react'
import { EmailItem } from './styles'

const MultipleValueTextInputItem = (props) => {
  const { value, handleItemRemove, deleteButton } = props
  return (
    <EmailItem>
      {value}
      <span
        data-value={value}
        tabIndex='-1'
        role='button'
        onKeyPress={() => handleItemRemove(value)}
        onClick={() => handleItemRemove(value)}
      >
        {deleteButton}
      </span>
    </EmailItem>
  )
}

export default MultipleValueTextInputItem

import React, { useEffect, useState } from 'react'
import api from '../../../api'
import Pagination from '../../Pagination/Pagination'
import NotificationItem from './Item'
import { PaginationContainer } from './styled'

const NotificationList = () => {
  const [notifications, setNotifications] = useState([])
  const [currentNotifications, setCurrentNotifications] = useState([])
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [notificationPerPage] = useState(10)

  useEffect(() => {
    const fetchNotifications = async () => {
      const notifications = await api.getAllNotifications()
      setNotifications(notifications)
    }

    fetchNotifications()
  }, [])

  useEffect(() => {
    if (!notifications.length) return

    const indexOfLastNotification = currentPage * notificationPerPage
    const indexOfFirstNotification = indexOfLastNotification - notificationPerPage

    setCurrentNotifications(
      notifications.slice(indexOfFirstNotification, indexOfLastNotification)
    )

    setNumberOfPages(Math.ceil(notifications.length / 10))
  }, [notifications, currentPage, notificationPerPage])

  const handlePaginationBtnClick = selectedNumber => {
    const number = selectedNumber.selected + 1
    if (currentPage === number) return

    setCurrentPage(number)
  }

  const showPagination = numberOfPages > 1
  return (
    <>
      {
        currentNotifications.map((notification, i) => {
          return <NotificationItem
            key={i}
            notification={notification}
          />
        })
      }
      {
        showPagination &&
          (
            <PaginationContainer>
              <Pagination
                numberOfPages={numberOfPages}
                handlePaginationBtnClick={handlePaginationBtnClick}
              />
            </PaginationContainer>
          )
      }
    </>
  )
}

export default NotificationList

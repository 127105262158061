import React from 'react'
import InformationCard from '../InformationCard/InformationCard'
import { useStudentGradeContext } from '../../contexts/StudentGradeContext'
import { WITHDRAW, AUDIT } from '../../Constants/studentStatus'

const STUDENT_STATUS_MAPPING = {
  [WITHDRAW]: 'W',
  [AUDIT]: 'Audit'
}

const FinalGradeWidget = ({ cohort }) => {
  const { gradeData, cohortsEndInfo } = useStudentGradeContext()
  if (!Object.keys(gradeData).length) return null

  const isCohortEnd = cohortsEndInfo[cohort]
  // This widget will only be displayed in cohorts which are end.
  if (!isCohortEnd) return null

  const { projectedGrade, studentStatus } = gradeData
  const finalGrade = STUDENT_STATUS_MAPPING[studentStatus] || projectedGrade

  return (
    <InformationCard
      title='Final Grade'
      subTitle={finalGrade}
    />
  )
}

export default FinalGradeWidget

export const replaceBlanksWithInputElem = (
  question,
  questionText
) => {
  return questionText.replace(
    /_{2,}/g,
    '<input disabled type="text" autocomplete="off" role="presentation"' +
    ' class="active__learning-blank" name="blank[]">'
  )
}

export const getCorrectAnswer = questionConfiguration => {
  const m = /<!\[CDATA\[(.+?)]]>/.exec(questionConfiguration)

  if (m) return m[1]

  const [, answer = ''] = /<correctAnswer>(.*)<\/correctAnswer>/
    .exec(questionConfiguration) || []

  return answer.trim()
}

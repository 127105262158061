import React from 'react'
import {
  ApplicationsColumnHeader,
  ApplicationsTableContainer,
  ApplicationsTableDefaultText,
  ApplicationsTableHeader,
  ApplicationRow,
  ApplicationsTableWrapper,
  SortIcon
} from './style/index.style'
import disabledSortIcon from '../../assets/icons/caret-sort-disable.png'
import descSortIcon from '../../assets/icons/caret-sort-desc.png'
import useWindowSize from '../../Hooks/useWindowSize'
import { BREAKPOINTS } from '../../mediaQueries'
import ApplicationsTableRow from './ApplicationsTableRow'

const ApplicationsTable = ({
  applicationsData,
  handleSort,
  isSortedAscending,
  noFoundEntry
}) => {
  const [windowWidth] = useWindowSize()
  const isMobile = windowWidth < BREAKPOINTS.mobile

  const goToProspectPage = (prospectId) => {
    window.location.href = `#/ggu-app-management/prospect/${prospectId}`
  }

  const renderTableContent = () => {
    if (noFoundEntry) {
      return (
        <ApplicationsTableDefaultText>
          Hmm.... we can’t find any results for {noFoundEntry}. Please check the spelling!
        </ApplicationsTableDefaultText>
      )
    }
    if (applicationsData?.length < 1) {
      return (
        <ApplicationsTableDefaultText>
          Lookup a student to populate records.
        </ApplicationsTableDefaultText>
      )
    }
    return applicationsData?.map((application, index) => {
      const { prospectId } = application
      return (
        <ApplicationRow
          key={index}
          onClick={() => goToProspectPage(prospectId)}
        >
          <ApplicationsTableRow
            application={application}
            isMobile={isMobile}
          />
        </ApplicationRow>
      )
    })
  }

  return (
    <ApplicationsTableWrapper>
      <ApplicationsTableContainer>
        <ApplicationsTableHeader>
          {isMobile && <ApplicationsColumnHeader width={16} />}
          <ApplicationsColumnHeader width={281}>email</ApplicationsColumnHeader>
          <ApplicationsColumnHeader width={281}>prospect status</ApplicationsColumnHeader>
          <ApplicationsColumnHeader width={219}>admission decision</ApplicationsColumnHeader>
          <ApplicationsColumnHeader width={204}>degree status</ApplicationsColumnHeader>
          <ApplicationsColumnHeader
            width={281}
            onClick={() => handleSort()}
          >
            submission time
            <SortIcon
              src={isSortedAscending === null ? disabledSortIcon : descSortIcon}
              isSortedAscending={isSortedAscending}
            />
          </ApplicationsColumnHeader>
        </ApplicationsTableHeader>
        {renderTableContent()}
      </ApplicationsTableContainer>
    </ApplicationsTableWrapper>
  )
}

export default ApplicationsTable

import React, { useState } from 'react'
import { useAuth0 } from '../Auth0Provider/Auth0Provider'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { SpinnerWrap } from '../LoadingSpinner/styles'
import NoAccess from '../NoAccess/NoAccess'

function AppGuard ({ children }) {
  const { isAuthenticated, loginWithRedirect, user } = useAuth0()
  const [loader, setLoader] = useState(false)
  const forceLoginRedirect = () => {
    setLoader(true)
    setTimeout(() => {
      loginWithRedirect({
        redirect_uri: window.location.origin,
        appState: { targetUrl: window.location.hash }
      })
      setLoader(false)
    }, 1000)
    return true
  }

  if (loader) return <SpinnerWrap><LoadingSpinner /></SpinnerWrap>
  if (!isAuthenticated) return forceLoginRedirect()
  if (!user.permissions?.length) return <NoAccess />
  return children
}

export default AppGuard

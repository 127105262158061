import styled from 'styled-components'

export const Label = styled.label`
  span {
    display: inline-block;
    margin-left: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }
  cursor: pointer;
  margin: 0px;
  ${({ disabled }) => disabled && `filter: brightness(0.5);`};
`
export const Radio = styled.input`
  position: relative;
  top: 2px;
  width: 16px;
  height: 16px;
  appearance: none;
  border: 1px solid #5fc4b8;
  border-radius: 50%;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 2.5px;
    left: 2.5px;
    width: 9px;
    height: 9px;
    background-color: #5FC4B8;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  :checked::before {
    transform: scale(1);
  }
`

import React from 'react'
import Select, { components } from 'react-select'
import {
  CoursesViewDescription,
  CoursesViewTitle,
  CoursesViewWrapper,
  CourseType,
  DotsContainer,
  dropDownStyles,
  TooltipContainer,
  ToolTip
} from './style'
import chevronIcon from '../../assets/icons/chevron-down2.svg'
import greenEllipseIcon from '../../assets/icons/ellipse-green.svg'
import orangeEllipseIcon from '../../assets/icons/ellipse-orange.svg'

const CoursesView = ({ coursesList, history }) => {
  const getDropDownCoursesList = () => {
    return coursesList.map(course => {
      return {
        value: course,
        label: course?.courseTabName
      }
    })
  }

  const getCourseTypes = () => {
    const courseTypesWithCourses = [
      {
        courseTypeName: 'Intensive',
        courses: coursesList
          .filter(course => course?.courseTabName?.includes('7 Week'))

      },
      {
        courseTypeName: 'Standard',
        courses: coursesList
          .filter(course => course?.courseTabName?.includes('14 Week'))
      },
      {
        courseTypeName: 'GGU',
        courses: coursesList
          .filter(course => course?.courseTabName?.includes('GGU'))
      },
      {
        courseTypeName: 'Extended',
        courses: coursesList
          .filter(course => course?.courseTabName?.includes('EXT'))
      }
    ]
    return courseTypesWithCourses
  }

  const courseTypes = getCourseTypes()

  const goToCourseDetails = (course) => {
    console.log(course, 'course')
    history.push({
      pathname: `/grading-check/course-details`,
      state: { course: course }
    })
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chevronIcon} alt='chevron-icon' />
      </components.DropdownIndicator>
    )
  }

  return (
    <CoursesViewWrapper>
      <Select
        options={getDropDownCoursesList()}
        placeholder='All Courses'
        styles={dropDownStyles}
        components={{ DropdownIndicator }}
        onChange={(selectedCourse) => {
          goToCourseDetails(selectedCourse.value)
        }}
      />
      <CoursesViewTitle>All Courses Dot View</CoursesViewTitle>
      <CoursesViewDescription>
        Click on a circle below to see the grading systems.
        Courses turn red when there is a grading issue.
      </CoursesViewDescription>
      {courseTypes.map((courseType, index) => {
        const { courseTypeName, courses } = courseType
        return (
          <div key={index}>
            <CourseType>{courseTypeName}</CourseType>
            <DotsContainer>
              {courses.map((course, index) => {
                if (!course) return null
                const iconToDisplay = course?.hasMismatchedGrades
                  ? orangeEllipseIcon
                  : greenEllipseIcon
                return (
                  <TooltipContainer key={index}>
                    <ToolTip>{course?.courseTabName}</ToolTip>
                    <img
                      src={iconToDisplay}
                      alt='ellipse-icon'
                      key={index}
                      onClick={() => {
                        goToCourseDetails(course)
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </TooltipContainer>
                )
              })}
            </DotsContainer>
          </div>
        )
      })}
    </CoursesViewWrapper>
  )
}
export default CoursesView
CoursesView.displayName = 'CoursesView'

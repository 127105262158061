import styled from 'styled-components'

export const EmailContainer = styled.div`
  background-color: rgba(0,0,0,0.3);
  border: 1px solid rgba(255,255,255,0.3);
  border-radius: 0.25rem;
  margin: 6px 0 24px;
  min-height: 41px;
  padding: 5px 9px;
  width: 300px;
  display: flex;
  flex-wrap: wrap;
`

export const EmailItem = styled.span`
  background: #1D1D1F;
  border-radius: 5px;
  padding: 6px 12px;
  margin: 3px;
  white-space: nowrap;
`

export const EmailInput = styled.input`
  background-color: rgba(0,0,0,0);
  border: none;
  color: white;
  width: 100%;

  &:focus {
    outline: none;
  }
`

import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledWrapper,
  StyledRadioButton,
  Info
} from './styled'

const RadioButtonSelector = props => {
  const {
    headerText,
    tooltipText,
    exposeKey,
    values,
    setValue,
    className,
    options,
    noMargin,
    isDisabled
  } = props

  const selectedValue = values[exposeKey]

  const handleOnChange = option => {
    setValue({
      [exposeKey]: option
    })
  }

  const renderChild = (handler, value) => {
    const isNotActive = value !== selectedValue
    if (isNotActive || !handler) return null

    return handler(values, setValue)
  }

  return (
    <StyledWrapper noMargin={noMargin}>
      {
        headerText && (
          <p>
            {headerText}{tooltipText && <Info data-tip={tooltipText} />}
          </p>
        )
      }
      {
        options.map(option => {
          const {
            name,
            handler,
            value,
            disabled
          } = option

          return (
            <React.Fragment key={value}>
              <StyledRadioButton
                marginTop='15px'
                marginBottom='10px'
                className={className || ''}
                disabled={disabled || isDisabled}
              >
                {name}
                <input
                  id={exposeKey}
                  type='radio'
                  name={exposeKey}
                  checked={value === selectedValue}
                  onChange={() => handleOnChange(value)}
                  disabled={disabled || isDisabled}
                />
                <span />
              </StyledRadioButton>
              {renderChild(handler, value)}
            </React.Fragment>
          )
        })
      }
    </StyledWrapper>
  )
}

RadioButtonSelector.propTypes = {
  headerText: PropTypes.string,
  values: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  exposeKey: PropTypes.string.isRequired,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    handler: PropTypes.func
  }))
}

RadioButtonSelector.displayName = 'RadioButtonSelector'

export default RadioButtonSelector

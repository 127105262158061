import {
  isVipCohort,
  getIsCohortActive,
  getActiveCohortSortedVipFirst
} from '../../../utilities/cohortUtils'
import {
  getGroupedCourses,
  getCreditGrantingInstitutions
} from '../../../utilities/courseUtils'
import { FPU } from '../../../Constants/institutions'
import { getSortedCourses } from '../../../utilities/gradeReportUtils'
import { EXCLUDED_UPDATE_STATUSES } from './constants'

export const excludedCourses = [
  'Calculus I - FP', 'Content Onboarding Site', 'College Success', 'Certificate',
  'Test Course', 'Bundle'
]

export const excludedInstitutions = [FPU]

export const getFilterInstitutions = (courses) => {
  if (!courses?.length) return []

  const institutions = getCreditGrantingInstitutions(courses)
  const filteredInstitutions = institutions
    ?.filter(institution => !excludedInstitutions.includes(institution))

  return filteredInstitutions?.map(institution => ({
    label: institution, value: institution
  }))
}

export function getFilterCourses (courses) {
  if (!courses?.length) return []

  const sortedCourses = getSortedCourses(courses)
  const groupedCourses = getGroupedCourses(sortedCourses)
  const filteredCourses = groupedCourses
    .filter(course => !excludedCourses.includes(course.displayName))

  filteredCourses.forEach(course => {
    course.label = course.displayName
    course.value = course.displayName
  })

  return filteredCourses
}

export const getFilterCohorts = (courses, institution) => {
  if (!courses?.length) return []

  const allCohorts = courses.reduce((acc, course) => {
    if (!course.cohorts?.length) return acc
    if (course.creditGrantingInstitution !== institution) return acc
    course.cohorts.forEach(cohort => { cohort.courseName = course.displayName })
    return [...acc, ...course.cohorts]
  }, [])

  const filteredCohorts = allCohorts.filter(cohort =>
    (!cohort?.testCohort && getIsCohortActive(cohort)) || isVipCohort(cohort)
  )
  const activeCohorts = getActiveCohortSortedVipFirst(filteredCohorts)

  return activeCohorts.map(cohort => {
    const cohortLabel = cohort?.name?.replace(`${cohort.courseName} -`, '')?.trim()
    return {
      ...cohort,
      label: cohortLabel,
      value: cohort.name
    }
  })
}

export const getFilterRelationships = (relationships) => {
  if (!relationships?.length) return []

  return relationships.map(relationship => ({
    ...relationship,
    label: relationship.relationshipName,
    value: relationship.relationshipName
  }))
}

export const getFilterStatuses = (statuses) => {
  if (!statuses?.length) return []

  return statuses.map(status => ({
    ...status,
    label: status.name,
    value: status.name
  }))
}

export const getSelectableStatuses = (statuses) => {
  if (!statuses?.length) return []

  return statuses.filter(status => !EXCLUDED_UPDATE_STATUSES.includes(status?.name))
}

export const getOrderedStudents = (students) => {
  if (!students?.length) return []

  students.sort((a, b) => {
    const aLastName = a?.name?.split(' ')?.[1]
    const bLastName = b?.name?.split(' ')?.[1]
    return aLastName?.localeCompare(bLastName)
  })

  return students
}

export const getFilteredStudents = (students, statuses, relationship) => {
  const selectedStatuses = statuses?.map(status => status?.value)

  if (!students?.length || !selectedStatuses.length) return []

  const filteredStudents = students?.filter(student => {
    const isStatusSelected = selectedStatuses.includes(student.studentStatus)
    const isNotTestAttempt = !student?.isTestAttempt
    const isRelationshipSelected = !relationship ? true :
      student?.relationships?.includes(relationship?.id)
    return isStatusSelected && isNotTestAttempt && isRelationshipSelected
  })

  return filteredStudents
}

export const getSubmitStudents = (students, newStatus, zendeskUrl) => {
  if (!students?.length) return []

  const submitStudents = students.map(student => ({
    email: student.email,
    from: student.studentStatus,
    to: newStatus?.value,
    zendeskUrl
  }))

  return submitStudents
}

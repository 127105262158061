export const VOUCHER_STATUS = [
  'Pending Approval',
  'Outstanding voucher (Past drop deadline)',
  'Outstanding voucher (Less than 17 days until drop)',
  'Outstanding voucher (More than 17 days until drop)',
  'Rejected by Amazon',
  'Cancelled by Student',
  'All Vouchers Approved',
  'Pending Payment Request',
  'Outstanding Payment Request (More than 1 day before start date)',
  'Outstanding Payment Request (Less than 1 day before start date)',
  'Outstanding Payment Request (Past start date)'
]

export const AMAZON_STUDENT_DROPPED_EVENT = 'Amazon Student Dropped'
export const AMAZON_VOUCHER_APPROVED_EVENT = 'Amazon Voucher Approved'
export const AMAZON_VOUCHER_REJECTED_EVENT = 'Amazon Voucher Rejected'
export const AMAZON_CANCELLED_BY_STUDENT_EVENT = 'Amazon Cancelled by Student'
export const AMAZON_REJECTED_BY_AMAZON_EVENT = 'Amazon Rejected by Amazon'
export const AMAZON_PAYMENT_REQUEST_APPROVED_EVENT = 'Amazon Payment Request Approved'
export const AMAZON_PAYMENT_REQUEST_PENDING_EVENT = 'Amazon Payment Request Pending'
export const AMAZON_PAYMENT_REQUEST_REJECTED_EVENT = 'Amazon Payment Request Rejected'

export const VOUCHER_DENIAL_REASONS = [
  'Missing Student Signature',
  'Voucher Issued After Course Start Date',
  'Employee Eligibility Date After Course Start Date',
  'Incorrect School Listed',
  'Incorrect Document',
  'Name on Voucher Does Not Match Enrolled Student Name'
]

import React from 'react'
import { PAGES } from './constants'
import uploadArrow from '../../assets/icons/upload-arrow.svg'
import chevronRight from '../../assets/icons/chevron-right.svg'
import journalArrowDown from '../../assets/icons/journal-arrow-down.svg'
import {
  PageContainer, PageHeader, Separator, OptionsContainer, OptionContainer,
  TitleContainer, Title, Description, OptionTitle
} from './style'

function MainPage ({ setCurrentPage }) {
  const onELTUploadClick = () => {
    setCurrentPage(PAGES.ELTUpload)
  }

  const onELTArchiveClick = () => {
    setCurrentPage(PAGES.ELTArchive)
  }

  const onPCAdmissionsUploadClick = () => {
    setCurrentPage(PAGES.PC_AdmissionsUpload)
  }

  const onPCELTUploadClick = () => {
    setCurrentPage(PAGES.PC_ELTUpload)
  }

  return (
    <PageContainer margin='64px'>
      <PageHeader>
        Guild
      </PageHeader>
      <Separator margin='24px 0' />

      <OptionTitle>Pathways</OptionTitle>
      <OptionsContainer>
        <OptionContainer
          onClick={onELTUploadClick}
        >
          <TitleContainer>
            <img src={uploadArrow} alt='upload-icon' />
            <Title>Upload a new ELT</Title>
            <img src={chevronRight} alt='chevron-right' />
          </TitleContainer>

          <Description>
            Upload a new ELT file
          </Description>
        </OptionContainer>

        <OptionContainer
          onClick={onELTArchiveClick}
        >
          <TitleContainer>
            <img src={journalArrowDown} alt='journal-icon' />
            <Title>View past uploads</Title>
            <img src={chevronRight} alt='chevron-right' />
          </TitleContainer>

          <Description>
            View past ELT uploads
          </Description>
        </OptionContainer>
      </OptionsContainer>

      <OptionTitle>Professional Certificates & AA</OptionTitle>
      <OptionsContainer>
        <OptionContainer onClick={onPCAdmissionsUploadClick}>
          <TitleContainer>
            <img src={uploadArrow} alt='upload-icon' />
            <Title>Upload Admissions file</Title>
            <img src={chevronRight} alt='chevron-right' />
          </TitleContainer>

          <Description>Upload a new Admissions file</Description>
        </OptionContainer>

        <OptionContainer onClick={onPCELTUploadClick}>
          <TitleContainer>
            <img src={uploadArrow} alt='upload-icon' />
            <Title>Upload ELT</Title>
            <img src={chevronRight} alt='chevron-right' />
          </TitleContainer>

          <Description>Upload a new ELT file</Description>
        </OptionContainer>
      </OptionsContainer>
    </PageContainer>
  )
}

MainPage.displayName = 'MainPage'
export default MainPage

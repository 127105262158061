import React, { memo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  DropdownIndicator
} from '../../Components/CustomSelect/CustomSelectComponents'
import api from '../../api'
import { getFilterRelationships } from '../EnrollmentManagement/utils'
import CustomSelect from '../../Components/CustomSelect/UpdatedSelect'
import {
  DropDownWrapper,
  FormLabel,
  FormRow,
  FormTitle,
  FormWrapper,
  RelationShipDetails,
  RelationShipDetailsItem
} from './index.styled'

function RelationshipSelector ({
  selectedRelationship,
  setSelectedRelationship
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [relationShipsOptions, setRelationShipsOptions] = useState([])

  const {
    label: selectedRelationshipLabel,
    relationshipAdminEmail
  } = selectedRelationship || {}

  const handleRelationshipChange = (selectedOption) => {
    setSelectedRelationship(selectedOption)
  }

  const fetchRelationships = async () => {
    setIsLoading(true)
    const relationships = await api.getStudentRelationships()
    setRelationShipsOptions(getFilterRelationships(relationships))
    setIsLoading(false)
  }

  useEffect(() => {
    fetchRelationships()
  }, [])

  return (
    <>
      <FormTitle>Partner</FormTitle>
      <FormWrapper>
        <FormRow>
          <DropDownWrapper>
            <FormLabel htmlFor='relationships'>Select relationship</FormLabel>
            <CustomSelect
              isClearable
              isLoading={isLoading}
              id='relationships'
              options={relationShipsOptions}
              name='relationships'
              data-testid='relationship-dropdown'
              placeholder='Select a relationship'
              width={'400px'}
              components={{ DropdownIndicator }}
              onChange={handleRelationshipChange}
              value={selectedRelationship?.label === '--' ? null : selectedRelationship}
            />
          </DropDownWrapper>
        </FormRow>
        <FormRow grow>
          <FormLabel>Relationship details</FormLabel>
          <RelationShipDetails>
            <RelationShipDetailsItem>
              <div>Name</div>
              <div>
                {selectedRelationshipLabel}
              </div>
            </RelationShipDetailsItem>
            <RelationShipDetailsItem>
              <div>Admin email</div>
              <div>{relationshipAdminEmail}</div>
            </RelationShipDetailsItem>
          </RelationShipDetails>
        </FormRow>
      </FormWrapper>
    </>
  )
}

RelationshipSelector.propTypes = {
  selectedRelationship: PropTypes.object,
  setSelectedRelationship: PropTypes.func.isRequired
}

RelationshipSelector.displayName = 'RelationshipSelector'
export default memo(RelationshipSelector)

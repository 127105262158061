import styled from 'styled-components'
import { CustomButton as Button } from '../../Students/style'

export const Container = styled.div`
  margin: 28px 4px 0px 11px;
`

export const Heading = styled.h1`
  font-family: Lato;
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  padding-bottom: 36px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`

export const FormContainer = styled.div`
  width: 602px;
  margin-left: 12px;
  margin-top: 24px;
  & > p, li {
    font-family: Lato;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
  }
`

export const CustomButton = styled(Button)`
  margin-top: ${({ selection, step }) =>
    selection === 'students' ? (step === 1 ? '59.97px' : '16px') : '4px'};
`

export const RadioContainer = styled.div`
  & label {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    display: block !important;
    line-height: 22px;
  }
  & label:nth-child(2) {
    margin-top: 24px !important;
  }
`

export const Wrapper = styled.div`
  padding-top: 0px;
  padding-left: 10px;
`

export const SearchContainer = styled.div`
  margin-left: 41px;
`

export const CohortSearchContainer = styled.div`
  display: flex;
  margin-top: 45px;
`

export const CohortField = styled.div`
  flex: 1;
  ${({ marginRight }) => 'margin-right: 15px;'}
`

export const Header = styled.div`
  display: flex;
  margin-top: ${({ step, selection }) =>
    step === 2 && selection === 'students' ? '39px' : '46px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '11px'};
  & > span {
    margin-right: 21px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
  }

  & > span.last-step-heading {
    text-transform: uppercase;
  }

  & > div {
    display: inline-block;
  }
`

export const ConfirmationListItem = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 130%;
`

export const SubText = styled.p`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: ${({ marginBottom }) => marginBottom || '1rem'};
`

export const Message = styled.p`
  margin-bottom: 30px;
`

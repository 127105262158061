export const CONTENT_CREATOR_VIEW = 'contentCreator.view'
export const DATABASE_EDIT = 'dbViewer.edit'

export const MAPPER_PERMISSIONS = [{
  category: 'Student data',
  permissions: [{
    label: 'CSV download',
    value: 'csvDownload.view'
  }, {
    label: 'Grade report',
    value: 'gradeReport.view',
    description: 'By default, only Admins have write access.'
  }, {
    label: 'Database table',
    value: 'dbViewer.view',
    description: null,
    children: [{
      label: 'Enable write access',
      value: 'dbViewer.edit',
      description: 'By default, only Admins have write access.'
    }]
  }, {
    label: 'Progress cloning',
    value: 'progressCloning.view'
  }, {
    label: 'Progress reset',
    value: 'progressReset.view'
  }, {
    label: 'Writing grade center',
    value: 'writingGradeCenter.view'
  }]
}, {
  category: 'Course',
  permissions: [{
    label: 'Assessment extensions',
    value: 'assessmentExtensions.view'
  }, {
    label: 'Cohorts',
    value: 'cohorts.view'
  }, {
    label: 'Cohort transfer',
    value: 'cohortTransfer.view'
  }, {
    label: 'Content gating',
    value: 'contentGating.view'
  }, {
    label: 'Exam key override',
    value: 'examKeyOverride.view'
  }, {
    label: 'Exam retakes',
    value: 'examRetakes.view'
  }, {
    label: 'Proctorio gradebook',
    value: 'proctorioGradebook.view'
  }, {
    label: 'Special days',
    value: 'specialDays.view'
  }, {
    label: 'Yellowdig URLs',
    value: 'yellowdigURLs.view'
  }]
}, {
  category: 'Enrollment',
  permissions: [{
    label: 'Add students',
    value: 'addStudents.view'
  }, {
    label: 'Administrative drop students',
    value: 'adminDropStudents.view'
  }, {
    label: 'Enrollment management',
    value: 'enrollmentManagement.view'
  }, {
    label: 'Merge accounts',
    value: 'mergeAccounts.view'
  }, {
    label: 'Student records',
    value: 'studentRecords.view'
  },
  {
    label: 'VIP users',
    value: 'vipUsers.view'
  }]
}, {
  category: 'Partnerships',
  permissions: [{
    label: 'Amazon refund',
    value: 'amazonRefund'
  }, {
    label: 'Amazon student progress',
    value: 'amazonStudentProgress.view'
  }, {
    label: 'GGU admission import',
    value: 'gguAdmissionImport.view'
  }, {
    label: 'GGU course approval',
    value: 'gguCourseApproval.view'
  }, {
    label: 'GGU application management',
    value: 'gguAppManagement.view'
  }, {
    label: 'Guild',
    value: 'guild.view'
  }, {
    label: 'Relationships',
    value: 'relationships.view'
  }, {
    label: 'Vouchers',
    value: 'vouchers.view'
  }]
}, {
  category: 'Other',
  permissions: [{
    label: 'Permissions manager',
    value: 'permissionManager.view'
  }, {
    label: 'Voucher codes',
    value: 'voucherCodes.view'
  }]
}]

import React, { memo } from 'react'
import PropTypes from 'prop-types'
import {
  FormTitle,
  PageDescription,
  ReviewStateLabel,
  ReviewStateValue
} from './index.styled'

const ReviewState = ({
  locked,
  selectedCohort,
  selectedCourse,
  selectedExam,
  selectedRelationship
}) => {
  if (!selectedRelationship || !selectedCourse || !selectedCohort || !selectedExam) return null

  const { displayName: relationshipName } = selectedRelationship || {}
  const { displayName: courseName } = selectedCourse || {}
  const { label: cohortName } = selectedCohort || {}
  const { label: examName } = selectedExam || {}

  return (
    <>
      <FormTitle>Review</FormTitle>
      <PageDescription mb='16px' mt='16px'>
        Please review before <span>{locked ? 'locking' : 'unlocking'}</span> the exam.
      </PageDescription>
      <ReviewStateLabel>Partner</ReviewStateLabel>
      <ReviewStateValue>{relationshipName}</ReviewStateValue>
      <ReviewStateLabel>Course</ReviewStateLabel>
      <ReviewStateValue mb='0'>{courseName}</ReviewStateValue>
      <ReviewStateValue>{cohortName}</ReviewStateValue>
      <ReviewStateLabel>Exam</ReviewStateLabel>
      <ReviewStateValue mb='0'>{examName}</ReviewStateValue>
    </>
  )
}

ReviewState.propTypes = {
  locked: PropTypes.bool,
  selectedCohort: PropTypes.object,
  selectedCourse: PropTypes.object,
  setSelectedExam: PropTypes.func
}

export default memo(ReviewState)

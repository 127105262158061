import uniq from 'lodash/uniq'
import { getDateString } from '../../../utilities/dateTimeUtil'
import api from '../../../api'
import { EXAM } from '../../../Constants/chapter'
import { isCourseWithoutFinalExam } from '../../../utilities/courseUtils'

export const getFormattedDateAndTime = (stringDate) => {
  const date = new Date(stringDate)
  if (date.toString() === 'Invalid Date') return []

  const formattedDate = getDateString(new Date(date))
  const formattedTime = new Date(date)
    .toLocaleTimeString(
      [],
      { hour: '2-digit', minute: '2-digit'
      })
    .toLowerCase()
  return [formattedDate, formattedTime]
}

export const getExtensionRecordData = (recordRow) => {
  const [
    assessment,
    originalDeadlineKey,
    extendedDeadlineKey
  ] = getAssessmentNameAndDeadlineKeys(recordRow)
  let type, extendedDeadline

  if (recordRow[extendedDeadlineKey]) {
    type = 'Manual'
    extendedDeadline = recordRow[extendedDeadlineKey]
  } else {
    type = extendedDeadlineKey?.startsWith('exam') ? 'Manual' : 'Automatic'
    extendedDeadline = recordRow[extendedDeadlineKey + 'Ext']
  }
  const originalDeadline = recordRow[originalDeadlineKey]
  const [originalDeadlineDate, originalDeadlineTime] =
    getFormattedDateAndTime(originalDeadline)
  const [extendedDeadlineDate, extendedDeadlineTime] =
    getFormattedDateAndTime(extendedDeadline)
  const displayedOriginalDeadline =
    `${originalDeadlineDate}   ${originalDeadlineTime}`
  const displayedExtendedDeadline =
    `${extendedDeadlineDate}   ${extendedDeadlineTime}`
  return [
    assessment,
    type,
    displayedOriginalDeadline,
    displayedExtendedDeadline
  ]
}

export const getExamExtendedKey = exam => {
  const {
    midterm1Deadline,
    midterm1DeadlineExt,
    midterm2Deadline,
    midterm2DeadlineExt,
    exam3DeadlineExt,
    exam4DeadlineExt,
    exam5DeadlineExt,
    finalDeadline,
    finalDeadlineExt
  } = exam

  const extensions = {
    midterm1Deadline,
    midterm1DeadlineExt,
    midterm2Deadline,
    midterm2DeadlineExt,
    exam3DeadlineExt,
    exam4DeadlineExt,
    exam5DeadlineExt,
    finalDeadline,
    finalDeadlineExt
  }

  const extendedExamKey = Object.keys(extensions).find(key => {
    return !!extensions[key]
  })
  return extendedExamKey
}

export const getExamNumber = exam => {
  const extendedExamKey = getExamExtendedKey(exam)
  if (!extendedExamKey) return 0

  const examNumber = ['finalDeadline', 'finalDeadlineExt'].includes(extendedExamKey)
    ? exam?.exams?.length
    : Number(extendedExamKey.replace(/[^0-9]/g, ''))

  return examNumber
}

export const getAssessmentNameAndDeadlineKeys = (recordRow) => {
  const extendedExamKey = getExamExtendedKey(recordRow)

  if (!extendedExamKey) {
    const regEx = '(?<=\\d{1,2}/\\d{1,2}/\\d{2,4} - )(.+)'
    const assessment =
      recordRow.milestoneName?.match(regEx)?.[1] ||
      recordRow.milestoneName?.split(' - ')?.at(-1) ||
      'Untitled Milestone'
    return [assessment, 'milestoneLockTime', 'milestoneDeadline']
  }

  const examNumber = getExamNumber(recordRow)
  const { exams, courseId } = recordRow || {}
  const hasFinalExam = !isCourseWithoutFinalExam(courseId)
  const originalDeadlineKey = hasFinalExam && examNumber === exams?.length
    ? 'finalExamEndTime'
    : extendedExamKey.replace(/Deadline(,?.*)/, 'EndTime')
  return [
    exams?.[examNumber - 1]?.title,
    originalDeadlineKey,
    extendedExamKey.replace('Ext', '')
  ]
}

export const getFilteredRecords = ({
  courseFilter,
  cohortFilter,
  studentExtensionsRecords
}) => {
  if (!cohortFilter && !courseFilter) return studentExtensionsRecords

  const filterByCourse = studentExtensionsRecords.filter(
    (record) => record.courseRecId === courseFilter.at_id
  )

  if (cohortFilter) {
    const filterByCohort = filterByCourse.filter(
      (record) => record.cohortId === cohortFilter.id
    )
    return filterByCohort
  }
  return filterByCourse
}

export const getExtensionRecordsWithExams = async (extensionRecords, allCourses) => {
  if (!extensionRecords?.length) return

  const studentRecordsWithCourseIds = extensionRecords.map(record => {
    const course = allCourses.find(course => course.at_id === record.courseRecId)
    return {
      ...record,
      courseId: course?.id
    }
  })
  if (!studentRecordsWithCourseIds?.length) return

  const uniqueCourseIds = uniq(
    studentRecordsWithCourseIds.map(record => record.courseId)
  )
  const examsByCourseId = await Promise.all(
    uniqueCourseIds.map(async courseId => {
      const courseData = await api.getCourseData(courseId)
      return { courseId, exams: courseData?.chapters?.filter(chapter => chapter.type === EXAM) }
    })
  )
  const studentRecordsWithExams = studentRecordsWithCourseIds.map(record => {
    const exams = examsByCourseId.find(course => course.courseId === record.courseId)?.exams
    return { ...record, exams, examNumber: getExamNumber(record) }
  })
  return studentRecordsWithExams
}

import React from 'react'
import ModalComponent from '../ModalComponent/ModalComponent'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { FormContainer, FormLabel, InputContainer, ModalContents } from './styles'
import RecordInput from './RecordInput'

const ExpandedItem = (props) => {
  const { isLoading, tableName, selectedItem, handleClose } = props
  const { rowDetails, columnMetaData } = selectedItem || {}
  const { rows } = rowDetails || {}
  const { fields } = rows?.[0] || {}

  return (
    <ModalComponent
      title={tableName}
      show
      width='572px'
      handleClose={handleClose}
    >
      <ModalContents>
        {isLoading && <LoadingSpinner />}
        {!isLoading &&
          columnMetaData?.map((field) => {
            const { columnName, isAutoGenerated } = field
            if (columnName === 'id' || isAutoGenerated) return null
            return (
              <FormContainer key={columnName}>
                <FormLabel>{columnName}</FormLabel>
                <InputContainer >
                  <RecordInput
                    field={field}
                    row={fields}
                  />
                </InputContainer>
              </FormContainer>
            )
          })
        }
      </ModalContents>
    </ModalComponent>
  )
}

export default ExpandedItem

import React from 'react'
import PropTypes from 'prop-types'
import ModalComponent from '../ModalComponent/ModalComponent'
import Button from '../Button/Button'
import './alert-modal.css'

function AlertModal ({ show, showModalHandler, confirm, children }) {
  return (
    <ModalComponent show={show} handleClose={() => showModalHandler(false)}>
      <div className='confirmation-modal-wrapper'>
        {children}
        <div className='btn-wrapper ok-btn'>
          <Button
            className={{
              'btn-custom': true,
              'btn-primary': true
            }}
            onClick={() => {
              confirm.handler()
            }}
          >
            {confirm.text}
          </Button>
        </div>
      </div>
    </ModalComponent>
  )
}

AlertModal.propTypes = {
  show: PropTypes.bool.isRequired,
  showModalHandler: PropTypes.func.isRequired,
  confirm: PropTypes.shape({
    text: PropTypes.string.isRequired,
    handler: PropTypes.func.isRequired
  }),
  children: PropTypes.any.isRequired
}

export default AlertModal

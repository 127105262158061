import React from 'react'
import { PaginationCount, PaginationItems, PaginationWrapper, Separator } from './styled'
import { usePagination, DOTS } from './utils'

const Pagination = (props) => {
  const {
    isUpdateStyle,
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize
  } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const pageItemsCount = (currentPage * pageSize) - (pageSize - 1)
  const pageItemsRange = Math.min(pageItemsCount + pageSize - 1, totalCount)

  return (
    <>
      <PaginationWrapper isUpdateStyle={isUpdateStyle}>
        <PaginationItems isUpdateStyle={isUpdateStyle}>
          {paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return <span key={index}>&#8230;</span>
            }

            return (
              <span
                key={index}
                className={currentPage === pageNumber ? 'active' : ''}
                onClick={() => onPageChange(pageNumber)}
              >
                {pageNumber}
              </span>
            )
          })}
        </PaginationItems>
        <PaginationCount>
          {pageItemsCount}-{pageItemsRange} of {totalCount}{' '}
            items
        </PaginationCount>
      </PaginationWrapper>
      {!isUpdateStyle && <Separator />}
    </>
  )
}

export default Pagination

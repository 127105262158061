export const PAGE_DESCRIPTION = 'This list will update to include students eligible for DropNR 2 days after a cohort’s drop deadline (Friday) at 3 PM (ET). The students eligible for Administrative Drop will be added 6 days after a cohort’s drop deadline (Tuesday) at 12 PM (ET).'
export const KEYS = ['dropNR', 'adminDrop', 15, 8]

export const DROP_NR_LABEL = 'Drop NR'
export const ADMIN_DROP_LABEL = 'Administrative Drop'

export const DROP_TYPE = [
  {
    value: 'dropNR',
    label: DROP_NR_LABEL,
    description: 'Incomplete registration form'
  },
  {
    value: 'adminDrop',
    label: ADMIN_DROP_LABEL,
    description: 'Lack of course engagement'
  }
]

export const TERM_LENGTH = [
  { value: 15, label: '14 Week Standard' },
  { value: 8, label: '7 Week Intensive' }
]

export const MODAL_TEXT = {
  DROP_MODAL: {
    p1: 'The selected students will be assigned DropNR or Administrative Drop, depending on their eligibility.',
    p2: 'Students will lose course access and receive an automated confirmation email.'
  },
  REMOVE_MODAL: {
    p1: (email) => `Are you sure you want to remove ${email} from the list? Removing them from the list will not drop the student.`
  }
}

export const PARTNER_MESSAGE = 'Dropping this student will return a token to the partner automatically.'
export const POST_TOKEN_STUDENT_MESSAGE = 'Dropping this student will return a token automatically.'
export const PRE_TOKEN_MESSAGE = 'No token exists for this attempt. Dropping this student will generate a new token for them.'

import { formatDate } from './utils'

export const tables = [
  {
    key: 'uniqueApproved',
    headers: [
      {
        title: 'Assessment',
        accessor: (record) => record.assessment
      },
      {
        title: 'Warnings',
        accessor: (record) => record.warnings
      },
      {
        title: 'Warning Time',
        accessor: (record) => formatDate(record.warningTime)
      },
      {
        title: 'Removals',
        accessor: (record) => record.removals
      },
      {
        title: 'Removal Time',
        accessor: (record) => formatDate(record.removalTime)
      },
      {
        title: 'Reports',
        accessor: (record) => record.reports
      },
      {
        title: 'Reported on',
        accessor: (record) => formatDate(record.reportTime)
      },
      {
        title: 'Sanction',
        accessor: (record) => record.sanction
      },
      {
        title: 'Notes from facilitator',
        accessor: (record) => record.partnerNotes
      },
      {
        title: 'Notes from Outlier',
        accessor: (record) => record.outlierNotes
      }
    ]
  },
  {
    key: 'uniqueDenied',
    headers: [
      {
        title: 'Assessment',
        accessor: (record) => record.assessment
      },
      {
        title: 'Incident',
        accessor: (record) => record.incident
      },
      {
        title: 'Flagged on',
        accessor: (record) => formatDate(record.flaggedOn)
      },
      {
        title: 'Rejected on',
        accessor: (record) => formatDate(record.rejectedOn)
      },
      {
        title: 'Notes from Facilitator',
        accessor: (record) => record.partnerNotes
      },
      {
        title: 'Reason from Outlier',
        accessor: (record) => record.denialReason
      }
    ]
  }
]

import React, { useState, useEffect } from 'react'
import { Form, Label, Input, Button } from 'reactstrap'
import { VipCheckBox as CheckBox } from '../VipUser/styled'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import api from '../../api'
import './voucher-code.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

function VoucherCode () {
  const [cohorts, setCohorts] = useState([])
  const [students, setStudents] = useState([])
  const [currentCohort, setCurrentCohort] = useState('')
  const [currentStudent, setCurrentStudent] = useState('')
  const [discountCode, setDiscountCode] = useState('')
  const [startDate, setStartDate] = useState(new Date())
  const [sendEmail, setSendEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [validForm, setValidForm] = useState({
    cohort: true, student: true, discountCode: true, startDate: true
  })
  const [message, setMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    fetchAllCohorts()
  }, [])

  useEffect(() => {
    if (!currentCohort) return
    fetchStudents(currentCohort.value)
  }, [currentCohort])

  useEffect(() => {
    setCurrentStudent('')
  }, [students])

  const fetchAllCohorts = async () => {
    const cohorts = await api.getAllCohorts()
    setIsLoading(false)
    if (!cohorts || !cohorts.length) {
      setCohorts([])
      return
    }
    setCohorts(cohorts.sort((a, b) =>
      new Date(b.dateStart) - new Date(a.dateStart)).map(cohort => {
      return { label: cohort.name, value: cohort.at_id }
    }))
  }

  const fetchStudents = async (cohortId) => {
    setIsLoading(true)
    const students = await api.getStudentsByCohort(cohortId)
    setIsLoading(false)
    if (!students || !students.length) {
      setStudents([])
      return
    }
    setStudents(students.map(student => {
      return { value: student.id, label: student.email }
    }))
  }

  const onDiscountCodeChange = value => {
    const pattern = /[^-A-Z0-9]/g
    value = value.toUpperCase()
    setDiscountCode(value.replace(pattern, ''))
  }

  const submitForm = async () => {
    if (!validateForm()) return
    setIsLoading(true)
    setMessageProps(false, '')
    const response = await api.createVoucherCode({
      sendEmail,
      discountCode,
      startDate: startDate.getTime(),
      studentId: currentStudent.value,
      cohortId: currentCohort.value })
    setIsLoading(false)
    const { code, message } = response || {}
    code && resetForm()
    setMessageProps(!!code, code || `Error: ${message}`)
  }

  const validateForm = () => {
    const validForm = {
      cohort: !!currentCohort.value,
      student: !!currentStudent.value,
      discountCode: !!discountCode,
      startDate: !!startDate }
    setValidForm(validForm)
    return Object.keys(validForm).every(key => {
      return validForm[key]
    })
  }

  const resetForm = () => {
    setCurrentCohort('')
    setCurrentStudent('')
    setDiscountCode('')
    setStartDate(new Date())
    setSendEmail(false)
  }

  const setMessageProps = (isSuccess, message) => {
    setIsSuccess(isSuccess)
    setMessage(message)
  }

  const setValidFormFields = (key, value) => {
    setValidForm({ ...validForm, [key]: value })
    setMessageProps(false, '')
  }

  const copyCode = () => {
    const selection = window.getSelection()
    selection.empty()
    const range = document.createRange()
    range.selectNodeContents(document.querySelector('p#message'))
    selection.addRange(range)
    document.execCommand('copy')
  }

  return (
    <div className='p-3 voucher-code-page__container'>
      <h3>Create Voucher Code</h3>
      <div className='form-wrapper'>
        {message &&
          <div id='message-container'>
            <div className={isSuccess ? 'text-success' : 'error'}>
              {isSuccess ? 'Voucher Code created successfully' : message}
              {isSuccess && <div><p id='message'>{message}</p>
                <FontAwesomeIcon icon={faCopy} onClick={copyCode} />
              </div>}
            </div>
          </div>}
        <Form>
          <Label>Cohort</Label>
          <div className='text-field'>
            <Select
              options={cohorts}
              value={currentCohort}
              isSearchable
              isLoading={isLoading}
              onChange={selectedCohort => {
                setCurrentCohort(selectedCohort)
                setValidFormFields('cohort', true)
              }}
              placeholder='Search Cohort'
            />
            {!validForm.cohort && <p className='error'>Select a Cohort</p>}
          </div>

          <Label>Student</Label>
          <div className='text-field'>
            <Select
              options={students}
              value={currentStudent}
              isSearchable
              isLoading={isLoading}
              onChange={selectedStudent => {
                setCurrentStudent(selectedStudent)
                setValidFormFields('student', true)
              }}
              placeholder='Search Student'
            />
            {!validForm.student && <p className='error'>Select a Student</p>}
          </div>

          <Label>Voucher Code</Label>
          <div className='text-field'>
            <Input pattern='[-A-Z0-9]*' value={discountCode} id='voucher'
              onChange={event => {
                onDiscountCodeChange(event.target.value)
                setValidFormFields('discountCode', true)
              }} />
            {!validForm.discountCode &&
              <p className='error'>Voucher Code is empty</p>}
          </div>

          <Label>Active Start Date and Time</Label>
          <div className='text-field'>
            <DatePicker
              className='date-input'
              selected={startDate}
              onChange={date => {
                setStartDate(date)
                setValidFormFields('startDate', true)
              }}
              showTimeSelect
              dateFormat='Pp'
              minDate={new Date()}
            />
            {!validForm.startDate &&
              <p className='error'>Select a Start Date and Time</p>}
          </div>

          <CheckBox>
            Send student automated email
            <input
              type='checkbox'
              data-testid='sendAutomatedEmail'
              id='sendAutomatedEmail'
              name='sendEmail'
              value={sendEmail}
              onChange={e => setSendEmail(e.target.checked)}
              checked={sendEmail}
            />
            <span />
          </CheckBox>

          {isLoading ? <i className='fa fa-spinner fa-spin' />
            : <div className='mt-2'>
              <Button className='submit-button' color='primary'
                onClick={submitForm}>
                Create
              </Button>
              <Button className='ml-3 submit-button'
                color='primary' onClick={() => {
                  resetForm()
                  setMessageProps(false, '')
                }}>
                Reset
              </Button>
            </div>}
        </Form>
      </div>
      <hr />
    </div>
  )
}

export default VoucherCode

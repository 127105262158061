import React from 'react'
import { Radio, Label } from './styled'

const CustomRadioButton = ({ label, onChange, checked, disabled, ...rest }) => {
  return (
    <Label disabled={disabled}>
      <Radio
        checked={checked}
        onChange={(e) => onChange(e.target.value)}
        type='radio'
        {...rest}
        disabled={disabled}
      />
      <span>{label}</span>
    </Label>
  )
}

export default CustomRadioButton

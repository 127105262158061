import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ModalComponent from '../ModalComponent/ModalComponent'
import { FormGroup, Button, Input, Label, Form, Spinner } from 'reactstrap'
import { validateEmail } from '../../utilities/userUtils'
import { useStudentGradeContext } from '../../contexts/StudentGradeContext'
import { generatePath } from 'react-router'
import api from '../../api'
import { StudentStatusEditModalWrapper } from '../StudentStatus/style'
import { WarningIcon } from '../Guild/style'

export default function EditStudentEmailModal ({
  show,
  handleClose,
  currentEmail,
  match,
  setEmailEditStatus,
  history
}) {
  const [newEmail, setNewEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [loadingSave, setloadingSave] = useState(false)
  const { gradeData } = useStudentGradeContext()

  const saveEmail = async () => {
    const { path, params: { courseName, courseId, cohortName } } = match
    const { id: studentId } = gradeData

    if (!validateEmail(newEmail)) return setEmailError('Invalid Email Format')
    setloadingSave(true)

    try {
      const response = await api.putStudentData(studentId, { email: newEmail })
      setEmailEditStatus(response)
      handleClose()
      history.push(generatePath(path, { studentEmail: newEmail, courseName, courseId, cohortName }))
    } catch (err) {
      if (err?.response?.status === 400) {
        const { data: { message } } = err.response
        setEmailError(message)
      }
    }
    setloadingSave(false)
  }

  const handleEdit = ({ target }) => {
    if (emailError) setEmailError('')
    setNewEmail(target.value)
  }

  return (
    <ModalComponent show={show} handleClose={handleClose}>
      <StudentStatusEditModalWrapper>
        <h3>Update Student Email</h3>
        <Form>
          <FormGroup>
            <Label className='section-title'>Current Email</Label>
            <Input
              type='text'
              disabled
              value={currentEmail}
            />
          </FormGroup>
          <FormGroup>
            <Label className='section-title'>Updated Email</Label>
            {emailError && <p className='error d-flex align-items-center'>
              <WarningIcon />
              {emailError}
            </p>}
            <Input
              type='text'
              className={`${emailError && 'input-error-border'}`}
              value={newEmail}
              onChange={handleEdit}
            />
          </FormGroup>

          <FormGroup className='buttons-container mt-4'>
            <Button
              className='btn btn-custom btn btn-secondary'
              onClick={handleClose}
            >
              CANCEL
            </Button>

            <Button className='btn btn-custom next-btn' onClick={saveEmail}>
              {loadingSave ? <Spinner size='sm' /> : 'SAVE'}
            </Button>
          </FormGroup>
        </Form>
      </StudentStatusEditModalWrapper>
    </ModalComponent>
  )
}

EditStudentEmailModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentEmail: PropTypes.string.isRequired
}

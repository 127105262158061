import styled from 'styled-components'

export const CustomButton = styled.button`
    &.btn-custom {
        background-color: var(--brand-regular);
        text-transform: uppercase;
        font-family: 'Lato';
        font-weight: bold;
        border-radius: 5px !important;
        padding: .4em;
        width: 152.47px;
        height: 40px;
        letter-spacing: 1px;
        box-shadow: none;
        border: none;
        outline: none !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }

    &.btn-custom.btn-primary {
        color: var(--text-dark) !important;
        background-color: var(--brand-regular);
    }

    &.btn-custom.btn-primary:not(:disabled):hover {
        color: var(--text-dark) !important;
        background-color: var(--brand-regular-hover)
    }

    &.btn-custom.btn-primary:focus {
        border: none !important;
        box-shadow: none !important;
    }

    &.btn-custom.btn-primary:not(:disabled):not(.disabled):active, .btn-custom.btn-primary:not(:disabled):not(.disabled).active {
        background-color: transparent;
        border-color: transparent;
        color: var(--brand-regular-active) !important;
    }

    &.disabled.btn-custom.btn-primary, .btn-custom.btn-primary:disabled {
        color: var(--text-dark) !important;
        background-color: var(--brand-regular-disabled) !important;
        border-color: var(--brand-regular-disabled) !important;
    }

    &.btn-custom.btn-secondary {
        background: none!important;
        border: 1px solid var(--brand-regular)!important;
        color: var(--brand-regular)!important;
    }

    &.btn-custom.btn-secondary:not(:disabled):hover {
        color: var(--brand-regular-hover) !important;
        border:1px solid  var(--brand-regular-hover) !important;
    }

    &.btn-custom.btn-secondary:focus {
        box-shadow: none !important;
    }

    &.btn-custom.btn-secondary:not(:disabled):not(.disabled):active, .btn-custom.btn-secondary:not(:disabled):not(.disabled).active {
        background-color: var(--canvas-dark)!important;
        color: var(--white) !important;
    }

    &.btn-custom.btn-tertiary {
        color: var(--brand-regular) !important;
        font-weight: bold !important;
        font-size: 14px;
        text-transform: uppercase;
        text-align: right;
        cursor: pointer;
        height: 30px;
        padding: 0px;
        align-self: center;
        border: none;
        width: auto;
        background: none !important;
    }

    &.btn-custom.btn-tertiary:focus {
        border: none !important;
        box-shadow: none !important;
    }

    &.btn-custom.btn-tertiary:active {
        color: var(--gray-secondary) !important;
    }

    &.reset-dates-btn.btn-custom {
        background: var(--brand-regular);
        font-size: 14px !important;
        line-height: 17px !important;
        padding: 11px;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        width: 120px;
        height: 40px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
`

import React, { useEffect, useRef, useState } from 'react'
import {
  DropDownMenu,
  DropDownMenuWrapper
} from './style'

const DropDownMenuComponent = ({
  iconSrc,
  iconWidth,
  iconHeight,
  children
}) => {
  const [showMenu, setShowMenu] = useState(false)
  const dropDownMenuRef = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  })

  const toggleMenu = (event) => {
    event.preventDefault()
    setShowMenu(prevState => !prevState)
  }

  const handleClickOutside = e => {
    if (showMenu &&
      dropDownMenuRef.current &&
      !dropDownMenuRef.current.contains(e.target)
    ) {
      setShowMenu(false)
    }
  }

  return (
    <DropDownMenuWrapper
      className='dropdown d-flex'
      id='nav_icon_hambars'
    >
      <div
        onClick={toggleMenu}
        className='ml-1 d-flex'
        id='dropdownMenuOffset'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
        data-offset='10,20'
      >
        <div className='d-flex'>
          <img
            className='d-inline-block icon-bars'
            src={iconSrc}
            width={iconWidth}
            height={iconHeight}
            alt='Menu Icons'
          />
        </div>
      </div>
      {showMenu && <DropDownMenu ref={dropDownMenuRef} data-testid='DropDownMenu'>
        {children}
      </DropDownMenu>}
    </DropDownMenuWrapper>
  )
}

export default DropDownMenuComponent

export const SET_SORT_HISTORY_DATA = 'SET_SORT_HISTORY_DATA'
export const SET_SECTION_DATA = 'SET_SECTION_DATA'
export const SET_GRADE_REPORT_DATA = 'SET_GRADE_REPORT_DATA'
export const SET_TABLE_DATA = 'SET_TABLE_DATA'
export const SET_EDIT_GRADES_RELEVANT_DATA = 'SET_EDIT_GRADES_RELEVANT_DATA'
export const SET_COURSES = 'SET_COURSES'
export const SET_IS_COHORT_END = 'SET_IS_COHORT_END'
export const SET_CURRENT_COURSE = 'SET_CURRENT_COURSE'
export const SET_SELECTED_STUDENT_COHORT = 'SET_SELECTED_STUDENT_COHORT'
export const SET_CURRENT_COHORT = 'SET_CURRENT_COHORT'
export const SET_COURSES_WITH_ASSIGNMENTS = 'set-courses-with-assignments'
export const SET_SELECTED_COURSE = 'set-selected-course'
export const SET_SELECTED_GRADING_STATUSES = 'set-selected-grading-statuses'
export const SET_SELECTED_SUBMISSION_STATUSES = 'set-selected-submission-statuses'
export const SET_SELECTED_ASSIGNMENTS = 'set-selected-assignments'
export const SET_DEFAULT_COHORTS = 'set-default-cohorts'
export const SET_SELECTED_COHORTS = 'set=selected-cohorts'
export const SET_ACTIVE_COHORTS_SELECTED = 'set-active-cohorts-selected'
export const SET_PAST_COHORTS_SELECTED = 'set-past-cohorts-selected'
export const SET_ALL_ASSIGNMENTS = 'set-all-assignments'
export const SET_FILTERED_SUBMISSIONS = 'set-filtered-submissions'
export const SET_CURRENT_ASSIGNMENT = 'set-current-assignment'
export const SET_STUDENTS_BY_COHORT = 'set-students-by-cohort'
export const SET_QUIZ_EXAM_DATA = 'setQuizExamData'
export const SET_SHOW_MODAL = 'setShowModal'
export const SET_DISCARD_MODAL = 'setDiscardModal'
export const SET_CHANGES = 'setChanges'
export const SET_LOADING = 'setLoading'
export const SET_STUDENT_STATUS = 'setStudentStatus'
export const SET_SUBMISSIONS_ASCENDING = 'setSubmissionsAscending'
export const SET_SEARCHED_EMAILS = 'setSearchedEmails'
export const SET_GRADE_RANGE = 'setGradeRange'
export const SET_CODING_ASSIGNMENT = 'setCodingAssignment'
export const SET_MAX_PARTICIPATION_GRADE = 'setMaxParticipationGrade'

import { CREDIT_GRANTING_INSTITUTIONS } from '../../../Constants/creaditGrantingInstitutions'

const [, GGU] = CREDIT_GRANTING_INSTITUTIONS

export const DURATION_OPTIONS = [
  {
    checkBoxLabel: 'Standard',
    default: [GGU],
    radioOptions: [
      { label: '14 weeks', name: 'standard-duration', value: '14' },
      { label: '15 weeks', name: 'standard-duration', value: '15' }
    ],
    actions: [
      {
        value: 'createAuditCohorts',
        title: 'Create audit cohorts',
        description:
          'Audit cohorts require its own variant. The duration will match your selection above.'
      },
      {
        value: 'linkCohortSchedules',
        title: 'Link cohort schedules',
        description:
          'Cohort schedules manage the dynamic schedule for the LMS and Partner Dashboard. Only available for 14wk cohorts.'
      }
    ]
  },
  {
    checkBoxLabel: 'Intensive',
    excludeInstitutions: [GGU],
    radioOptions: [
      { label: '7 weeks', name: 'intensive-duration', value: '7' },
      { label: '8 weeks', name: 'intensive-duration', value: '8' }
    ],
    actions: [
      {
        value: 'linkCohortSchedules',
        title: 'Link cohort schedules',
        description:
          'Cohort schedules manage the dynamic schedule for the LMS and Partner Dashboard. Only available for 7wk cohorts.'
      }
    ]
  },
  {
    checkBoxLabel: 'Extended',
    excludeInstitutions: [GGU],
    radioOptions: [
      {
        label: '39 weeks',
        name: 'extended-duration',
        value: '39',
        default: true
      }
    ],
    actions: [
      {
        value: 'linkCohortSchedules',
        title: 'Link cohort schedules',
        description: 'Cohort schedules manage the dynamic schedule for the LMS and Partner Dashboard.',
        default: true
      },
      {
        value: 'createAuditCohorts',
        title: 'Create audit cohorts',
        description: 'Audit cohorts require its own variant. The duration will match your selection above.',
        default: true
      }
    ]
  }
]

export const NUMBER_OF_ROWS_PER_PAGE = 50

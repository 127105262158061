import moment from 'moment'
import { proctorioExamUUIDReleaseDate } from '../config'

import {
  dateToSecondsSinceEpoch,
  secondsSinceEpoch
} from './dateTimeUtil'

export const getProctorioExamTag = (cohortName, examTitle) => {
  return `Outlier${formatTag(cohortName)}${formatTag(examTitle)}`
}

const formatTag = str => {
  return str.replace(/\s+/g, '')
}

export const shouldLockProctorioReviewLink = examEndTime => {
  if (!examEndTime) return false

  const endTime = moment(examEndTime)
  const endTimeAfterSixMonths = endTime.add(6, 'months')

  const endTimeAfterSixMonthsInSeconds = dateToSecondsSinceEpoch(
    endTimeAfterSixMonths.toDate()
  )

  return secondsSinceEpoch() >= endTimeAfterSixMonthsInSeconds
}

export const shouldUseExamUUIDInExamTag = cohortStartDate => {
  return cohortStartDate >= proctorioExamUUIDReleaseDate
}

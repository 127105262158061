import React, { useState, useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import { uniq, debounce } from 'lodash'
import { convertEasternTimeToLocalTime } from '../../utilities/dateTimeUtil'
import { getNextDropEligibleCohort } from '../../utilities/cohortUtils'
import { isAfterTokensLaunch } from '../../utilities/tokenUtils'
import api from '../../api'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import CustomSelect from './CustomSelect'
import StudentRowComponent from './StudentRowComponent'
import Modal from './Modal'
import Toast from '../ToastComponent/Toast'
import {
  PAGE_DESCRIPTION,
  KEYS,
  DROP_NR_LABEL,
  ADMIN_DROP_LABEL,
  DROP_TYPE,
  TERM_LENGTH
} from './constants/constants'
import {
  PageWrapper,
  Title,
  Instructions,
  FiltersWrapper,
  Label,
  DatePickerWrapper,
  DatePicker,
  DropStudentsButton,
  Row,
  OptionCheckBox,
  HeaderCell,
  InfoIcon,
  ToolTip,
  SortBtnsWrapper,
  SortAscending,
  SortDescending,
  StudentNameWrapper,
  StudentRowWrapper,
  NoStudentsMessage,
  SuccessIcon,
  FailureIcon
} from './styled'

const [DROP_NR, ADMIN_DROP, STANDARD, INTENSIVE] = KEYS
function AdminStudentDrop () {
  const [dropType, setDropType] = useState({
    [DROP_NR]: true,
    [ADMIN_DROP]: true
  })
  const [termLength, setTermLength] = useState({
    [STANDARD]: true,
    [INTENSIVE]: true
  })
  const [selectedDate, setSelectedDate] = useState()
  const [selectedStudents, setSelectedStudents] = useState([])
  const [selectAllStudents, setSelectAllStudents] = useState(false)
  const [isSortedAscending, setIsSortedAscending] = useState(true)
  const [allCohorts, setAllCohorts] = useState([])
  const [isDropModalOpen, setIsDropModalOpen] = useState(false)
  const [students, setStudents] = useState([])
  const [startDates, setStartDates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isStudentsLoading, setIsStudentsLoading] = useState(false)
  const toast = useRef()
  const infoRef = useRef()

  const sortedStudentData = useMemo(() => {
    return students.sort((a, b) => {
      const newA = a?.cohortName?.toLowerCase()
      const newB = b?.cohortName?.toLowerCase()
      if (isSortedAscending) {
        return newA < newB ? -1 : 1
      }
      return newB < newA ? -1 : 1
    }).filter(student => {
      const { dropType: studentDropType, cohortDuration } = student

      const type = studentDropType === DROP_NR_LABEL
        ? DROP_NR : ADMIN_DROP
      const duration = cohortDuration <= INTENSIVE
        ? INTENSIVE : STANDARD

      return dropType[type] && termLength[duration]
    })
  }, [isSortedAscending, students, termLength, dropType])

  const nextDropDate = useMemo(() => {
    const nextDropEligibleCohort = getNextDropEligibleCohort(allCohorts)

    const finalDropDateLocal = convertEasternTimeToLocalTime(
      nextDropEligibleCohort?.finalDropDate,
      'T00:00:00'
    )

    if (!finalDropDateLocal) return 'N/A'

    return moment(finalDropDateLocal).format('MMM DD, YYYY')
  }, [allCohorts])

  useEffect(() => {
    (async () => {
      setIsLoading(true)

      let cohorts = await api.getAllCohorts() || []
      cohorts = cohorts.filter(cohort => {
        const cohortName = cohort?.name
        const isGGUCohort = cohortName?.toLowerCase().includes('ggu')
        return !cohort.testCohort && !isGGUCohort
      })

      setAllCohorts(cohorts)
      setIsLoading(false)

      if (!cohorts?.length) return

      const startDates = uniq(
        cohorts.map(cohort => (
          convertEasternTimeToLocalTime(cohort?.dateStart, 'T06:00:00')
        ))
      )

      setStartDates(startDates)
    })()
  }, [])

  useEffect(() => {
    fetchDropEligibleStudents()
    // eslint-disable-next-line
  }, [selectedDate])

  useEffect(() => {
    setSelectedStudents(
      selectAllStudents ? sortedStudentData.map(student => student.attemptId) : []
    )
  }, [selectAllStudents, sortedStudentData])

  const getStartDate = () => {
    const startDate = allCohorts?.find(cohort => {
      const startDateLocal = convertEasternTimeToLocalTime(
        cohort?.dateStart,
        'T06:00:00'
      )
      // use 'day' because the datePicker sometimes modifies outputs
      return moment(startDateLocal).isSame(selectedDate, 'day')
    })?.dateStart

    return startDate
  }

  const fetchDropEligibleStudents = async () => {
    if (!selectedDate) {
      return setStudents([])
    }

    const startDate = getStartDate()
    setIsStudentsLoading(true)

    const students = await api.getDropEligibleStudents(startDate) || []

    const dropStudents = students.filter(student => {
      const { isRegistered, completedAssignments, completedQuizzes } = student
      return !isRegistered || (!completedAssignments && !completedQuizzes)
    })

    setIsStudentsLoading(false)
    setStudents(dropStudents)
  }

  function onChange (stateObj) {
    const {
      selectedOption,
      options: [firstOption, secondOption],
      setState
    } = stateObj

    if (selectedOption === null) {
      return setState({ [firstOption]: false, [secondOption]: false })
    }

    if (selectedOption.value === firstOption) {
      return setState(preVal => (
        { ...preVal, [firstOption]: !preVal[firstOption] }
      ))
    }

    return setState(preVal => (
      { ...preVal, [secondOption]: !preVal[secondOption] }
    ))
  }

  const handleSubmit = async () => {
    setIsDropModalOpen(false)
    setIsStudentsLoading(true)

    const studentsToDrop = students
      .filter(student => selectedStudents.includes(student.attemptId))
      .map(student => {
        const { attemptId, createdAt, dropType } = student
        return {
          attemptId,
          isAfterTokensLaunch: isAfterTokensLaunch({ createdAt, attemptId }),
          studentStatus: dropType === 'Admin Drop' ? ADMIN_DROP_LABEL : 'DropNR'
        }
      })

    const success = await api.adminBulkDropStudents({
      list: [...studentsToDrop]
    })

    await fetchDropEligibleStudents()

    const { current: { display } = {} } = toast

    if (success) {
      return display(
        <><SuccessIcon /> Students successfully been dropped</>,
        "We've updated the student statuses and sent out the confirmation emails."
      )
    }

    return display(
      <><FailureIcon /> Something went wrong!</>,
      'Request was not successful. Please try again.'
    )
  }

  if (isLoading) return <LoadingSpinner />

  return (
    <PageWrapper>
      <Toast toast={toast} />
      <Modal
        isDropModal
        showModal={isDropModalOpen}
        handleClose={() => setIsDropModalOpen(false)}
        handleSubmit={debounce(handleSubmit, { leading: true }, 1000)}
      />
      <Title>Administrative Drop Students</Title>
      <Instructions>
        <p>{PAGE_DESCRIPTION}</p>
      </Instructions>
      <FiltersWrapper>
        <Label>
          <span>Drop type</span>
          <CustomSelect
            options={DROP_TYPE}
            value={{}}
            onChange={value => onChange({
              selectedOption: value,
              options: [DROP_NR, ADMIN_DROP],
              setState: setDropType
            })}
            selectedValue={dropType}
          />
        </Label>
        <Label>
          <span>Start Date</span>
          <DatePickerWrapper selected={selectedDate}>
            <i
              id='clear-icon'
              onClick={() => setSelectedDate(undefined)}
            />
            <DatePicker
              data-testid='date-picker'
              selected={selectedDate}
              onChange={date => setSelectedDate(date)}
              disabledKeyboardNavigation
              includeDates={startDates}
              placeholderText='Select date'
            />
          </DatePickerWrapper>
        </Label>
        <Label>
          <span>Term Length</span>
          <CustomSelect
            options={TERM_LENGTH}
            value={{}}
            onChange={value => onChange({
              selectedOption: value,
              options: [STANDARD, INTENSIVE],
              setState: setTermLength
            })}
            selectedValue={termLength}
            isTermLength
          />
        </Label>
        <DropStudentsButton
          onClick={() => setIsDropModalOpen(true)}
          disabled={!selectedStudents.length || isStudentsLoading}
        >
          Drop Students
        </DropStudentsButton>
      </FiltersWrapper>
      <StudentRowWrapper>
        <Row>
          <StudentNameWrapper>
            <OptionCheckBox
              data-testid='select-all-students'
              isChecked={selectAllStudents}
              onClick={() => setSelectAllStudents(preVal => !preVal)}
              marginRight='40'
            />
            <HeaderCell>Student</HeaderCell>
          </StudentNameWrapper>
          <HeaderCell>
            Cohort
            <SortBtnsWrapper>
              <SortDescending
                data-testid='descending-btn'
                onClick={() => setIsSortedAscending(false)}
                isSelected={!isSortedAscending}
              />
              <SortAscending
                data-testid='ascending-btn'
                onClick={() => setIsSortedAscending(true)}
                isSelected={isSortedAscending}
              />
            </SortBtnsWrapper>
          </HeaderCell>
          <HeaderCell>RelationShip</HeaderCell>
          <HeaderCell>Drop Type</HeaderCell>
          <HeaderCell>
            Drop Reason
            <InfoIcon ref={infoRef} />
            <ToolTip
              target={infoRef}
              placement='left'
              innerClassName='tooltip-content'
              arrowClassName='tooltip-arrow'
            >
              Students must meet the following criteria to remain in the course.
            </ToolTip>
          </HeaderCell>
        </Row>
      </StudentRowWrapper>
      {isStudentsLoading
        ? <LoadingSpinner position='static' />
        : sortedStudentData?.map((studentData, index) => (
          <StudentRowComponent
            key={index}
            studentData={studentData}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
            fetchDropEligibleStudents={fetchDropEligibleStudents}
            setIsStudentsLoading={setIsStudentsLoading}
            toast={toast}
          />
        ))
      }
      {(!students?.length && selectedDate && !isStudentsLoading) && (
        <NoStudentsMessage>
          There are no students eligible for either Admin Drop or DropNR. The upcoming deadline is {nextDropDate}.
        </NoStudentsMessage>
      )}
    </PageWrapper>
  )
}

AdminStudentDrop.displayName = 'AdminStudentDrop'
export default AdminStudentDrop

import styled from 'styled-components'
import mediaqueries from '../../mediaQueries'

export const Container = styled.div`
  padding: 48px 32px 0 32px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`
export const PageTitle = styled.h1`
  margin: 0;
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  color: var(--text-primary);
`

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--border-primary);
  margin: ${({ margin }) => margin || '24px 0'};
`

export const PageDescription = styled.p`
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  color: var(--text-primary);
  margin-bottom: ${({ mb }) => mb || '32px'};
  margin-top: ${({ mt }) => mt || '0'};
  span {
    font-weight: 400;
  }
`

export const FormLabel = styled.label`
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  color: var(--text-primary);
  margin-bottom: ${({ mb }) => mb || '8px'};
`

export const FormTitle = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  color: var(--text-primary);
  margin-bottom: 16px;
`

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  ${({ grow }) => grow && 'flex: 1;'} 
`

export const DropDownWrapper = styled.div`
  width: 100%;
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => gap || '48px'};
  max-width: 1122px;
  ${mediaqueries.tablet`
    flex-direction: column;
    gap: ${({ gap }) => gap || '32px'};
  `}
`

export const RelationShipDetails = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: 16px;
  width: 100%;
`

const labelStyles = `
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--sub-text-secondary);
`

export const RelationShipDetailsItem = styled.div`
  display: flex;
  flex: 50%;
  flex-direction: column;
  & div {
    ${labelStyles}
  }
  & div:last-child {
    color: var(--text-primary);
  }
`

export const SelectLabel = styled.label`
  ${labelStyles}
`

export const ReviewStateLabel = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  color: var(--text-secondary);
  margin-bottom: 8px;
`

export const ReviewStateValue = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: var(--text-primary);
  margin-bottom: ${({ mb }) => mb || '24px'};
`

export const ReviewStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
`

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
  position: absolute;
  top: 64px;
  left: 0;
  background-color: var(--canvas-primary);
  height: calc(100% - 64px);
  width: 100%;
  padding: 64px 32px 0 32px;
  z-index: 99;

  & > .btn-custom {
    ${mediaqueries.mobile` width: 100% !important;`}
  }
`

export const ErrorTitile = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  margin-bottom: 16px;
  margin-top: 24px;
`

export const ErrorDescription = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 32px;
`

export const ActionButtonStyle = {
  padding: '16px 24px',
  height: 'auto',
  width: 'auto'
}

export const ToastDescription = styled.div`
  font-size: 16px;
  line-height: normal;
  font-weight: 400;
  padding-top: 4px;
`

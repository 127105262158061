import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  FiltersContainer,
  GradeReportContainer,
  FilterLabel,
  DropDownWrapper,
  DropDownLabel,
  DropDownContainer,
  CheckBox,
  ResetButton,
  CustomButton,
  TableContainer,
  TableHeader,
  BottomLine,
  FilterHeading
} from './styles'
import {
  getCsvData,
  getSortedCourses
} from '../../utilities/gradeReportUtils'
import api from '../../api'
import {
  useStudentGradeActions,
  useStudentGradeContext
} from '../../contexts/StudentGradeContext'
import Select, { components } from 'react-select'
import { getDefaultCourse } from '../../utilities/courseUtils'
import {
  formatGradesLastActiveTimeStamp
} from '../../utilities/gradeUtils'
import Highlight from '../../assets/icons/Highlight.svg'
import Search from '../../assets/icons/Search.svg'
import {
  CheckedBox,
  CustomCheckBox,
  CustomOptionContainer
} from '../WritingGradeCenter/styled'
import {
  defaultStatuses,
  getAdditionalCourseId,
  getReportByActiveDate
} from './util'
import { CSVLink } from 'react-csv'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import GradeReport from '../GradeReport/GradeReport'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import SearchModal from './SearchModal'
import HighlightModal from './HighlightModal'
import { GRADING_SCALE } from './gradingScale'
import { dropDownStyles } from '../global.styled'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Toast from '../ToastComponent/Toast'
import { FailureIcon, SuccessIcon } from '../AdminStudentDrop/styled'
import {
  COLLEGE_WRITING_I_GGU_V1_NAME,
  COLLEGE_WRITING_I_GGU_V2_NAME
} from '../../Constants/courses'
import { CWI_DATE_OVERLAP_VALUE } from '../../Constants'
function GradeReportWrapper (props) {
  const location = window.location
  const [isSearchModalShow, setIsSearchModalShow] = useState(false)
  const [isHighlightModalShow, setIsHighlightModalShow] = useState(false)

  const {
    courses,
    cohortsEndInfo,
    currentCourse,
    selectedStatus,
    currentCohort,
    maxParticipationGrade
  } = useStudentGradeContext()
  const {
    fetchGradeReport,
    fetchCourses,
    setIsCohortEnd,
    setCurrentCourse,
    setGradeRange,
    setSelectedStatus,
    setSearchedEmails,
    setCurrentCohort,
    getMaxParticipationGrade
  } = useStudentGradeActions()
  const toast = useRef()
  const [allCourses, setAllCourses] = useState([])
  const [isCohortLoading, setIsCohortLoading] = useState(true)
  const [isCourseLoading, setIsCourseLoading] = useState(true)
  const [isStatusLoading, setIsStatusLoading] = useState(true)
  const [isCsvButtonDisabled, setIsCsvButtonDisabled] = useState(true)
  const [isCohortEndLocalState, setIsCohortEndLocalState] = useState(false)
  const [isApiFailed, setIsApiFailed] = useState(false)
  const [filterGradeReport, setFilterGradeReport] = useState([])
  const [gradeData, setGradeData] = useState([])
  const [cohortData, setCohortData] = useState([])
  const [csvData, setCsvData] = useState([])
  const [chapters, setChapters] = useState([])
  const [showTestAttempts, setShowTestAttempts] = useState(false)
  const [allStatus, setStatuses] = useState([])
  const mountedRef = useRef(false)
  const { id: currentCourseId, name: currentCourseName } = currentCourse
  const [emailFilteredStudents, setEmailFilteredStudents] = useState([])
  const [dateRangeFilterStart, setDateRangeFilterStart] = useState('')
  const [dateRangeFilterEnd, setDateRangeFilterEnd] = useState('')
  const [startRangeLetterGrade, setStartRangeLetterGrade] = useState('')
  const [endRangeLetterGrade, setEndRangeLetterGrade] = useState('')
  const [endRangeNumericGrade, setEndRangeNumericGrade] = useState('')
  const [startRangeNumericGrade, setStartRangeNumericGrade] = useState('')
  const [isHighlightFilterApplied, setIsHighlighFilterApplied] = useState('')
  const [isSearchEmailFilterApplied, setIsSearchEmailFilterApplied] = useState('')
  const [showFinalGradesModal, setShowFinalGradesModal] = useState(false)
  const isFloridaPolyCohort = currentCourse.name.includes('- FP')
  const gradingScale = GRADING_SCALE[isFloridaPolyCohort ? 'FP' : 'main']

  useEffect(() => {
    const fetchChapters = async () => {
      const courseId = getAdditionalCourseId(currentCourseId, currentCohort)
      const { chapters } = await api.getCourseData(courseId)
      setChapters(chapters)
    }

    if (!currentCourseId || !currentCohort) return
    fetchChapters()
  }, [currentCohort, currentCourseId])

  useEffect(() => {
    const isCohortEnd = cohortsEndInfo[currentCohort.name]

    const courseGradeInfo = {
      grade: filterGradeReport,
      isCohortEnd
    }

    const courseId = getAdditionalCourseId(currentCourseId, currentCohort)

    setCsvData(
      getCsvData({
        courseGradeInfo,
        chapters,
        courseId: courseId,
        cohortStartDate: currentCohort?.dateStart,
        maxParticipationGrade
      })
    )
    // eslint-disable-next-line
  }, [cohortsEndInfo, filterGradeReport, showTestAttempts, currentCohort?.dateStart])

  useEffect(() => {
    if (!currentCohort) return
    setIsCsvButtonDisabled(true)
    const courseId = getAdditionalCourseId(currentCourseId, currentCohort)
    fetchData({
      courseId: courseId,
      cohortName: currentCohort.value
    })
    // eslint-disable-next-line
  }, [currentCohort])

  const handleSearchModalClick = () => {
    setIsHighlightModalShow(false)
    setIsSearchModalShow(!isSearchModalShow)
  }

  const handleHighlightModalClick = () => {
    setIsSearchModalShow(false)
    setIsHighlightModalShow(!isHighlightModalShow)
  }

  const handleUserSelected = useCallback((user) => {
    const { email } = user
    const courseId = getAdditionalCourseId(currentCourseId, currentCohort)

    const useCWIV2 = currentCourse.name === COLLEGE_WRITING_I_GGU_V1_NAME &&
      new Date(currentCohort?.dateStart) >= new Date(CWI_DATE_OVERLAP_VALUE)
    const newCourseName = useCWIV2 ? COLLEGE_WRITING_I_GGU_V2_NAME : currentCourseName

    const launchURL = `${location.origin}/#/student-grade-detail/${courseId}/${newCourseName}/${email}/${encodeURIComponent(currentCohort.name)}`
    const newWindow = window.open(launchURL, '_blank')
    newWindow.focus()
    // eslint-disable-next-line
  }, [currentCohort.name, currentCourseId, currentCourseName, location.origin])

  const fetchData = async ({ courseId, cohortName }) => {
    setIsApiFailed(false)
    try {
      const courseID = getAdditionalCourseId(courseId, currentCohort)
      const studentGrades = await fetchGradeReport({
        courseId: courseID,
        cohortName
      })
      if (!mountedRef.current) return setIsCohortLoading(false)

      setGradeData([...gradeData, ...studentGrades])

      if (!studentGrades.length) {
        setIsCsvButtonDisabled(false)
        return setIsCohortLoading(false)
      }

      const courseGradeInfo = {
        isCohortEnd: cohortsEndInfo[cohortName],
        grade: [...gradeData, ...studentGrades]
      }
      const [user] = studentGrades

      const maxParticipationGrade = await getMaxParticipationGrade(courseID, currentCohort.dateStart)
      setCsvData(
        getCsvData({
          courseGradeInfo,
          chapters: undefined,
          courseId: courseID,
          cohortStartDate: currentCohort?.dateStart,
          maxParticipationGrade
        })
      )
      setIsCsvButtonDisabled(false)
      setIsCohortEnd(user, currentCourseName, cohortName).then((res) => {
        setIsCohortEndLocalState(res || cohortsEndInfo[cohortName])
        setIsCohortLoading(false)
      })
    } catch (e) {
      console.log('Error: ', e)
      setIsApiFailed(true)
    } finally {
      setIsCohortLoading(false)
    }
  }

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    fetchCourses()
    fetchStatuses()
    window.location.hash = '#/student-grade-report'
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!courses || !courses.length) return

    const sortedCourses = getSortedCourses(courses)
      .filter(({ name }) => name !== COLLEGE_WRITING_I_GGU_V2_NAME)
    setAllCourses(sortedCourses)
  }, [courses])

  const fetchStatuses = async () => {
    setIsStatusLoading(true)
    const studentStatuses = await api.getStudentStatuses()
    const filtered = studentStatuses.map((data) => {
      return {
        value: data.name,
        label: data.name
      }
    })
    setStatuses(filtered)
    setSelectedStatus(
      !selectedStatus.length ? defaultStatuses : selectedStatus
    )
    setIsStatusLoading(false)
  }

  useEffect(() => {
    if (!allCourses.length) return

    setCurrentCourse(currentCourse.name
      ? currentCourse : getDefaultCourse(allCourses))
    // eslint-disable-next-line
  }, [allCourses])

  const fetchCohortData = async () => {
    let cohortData = currentCourse.cohorts

    // If the current course is CWI V1, add CWI V2 cohorts to the list
    if (currentCourse?.name === COLLEGE_WRITING_I_GGU_V1_NAME) {
      const CWI_V2 = courses.find(course => course.name === COLLEGE_WRITING_I_GGU_V2_NAME) || {}
      cohortData = [...cohortData, ...CWI_V2?.cohorts || []]
    }

    if (!mountedRef.current || !cohortData) return

    const sortedCohortList = cohortData.sort((a, b) =>
      new Date(b.dateStart) - new Date(a.dateStart)).map(cohort =>
      ({ ...cohort, value: cohort.name, label: cohort.name }))
    if (!sortedCohortList) return

    setCohortData(sortedCohortList)
    setCurrentCohort(currentCohort || sortedCohortList[0])
    setIsCourseLoading(false)
    setIsCohortLoading(false)
  }

  useEffect(() => {
    if (!currentCourseId) return
    fetchCohortData()
    setIsCohortLoading(true)
    // eslint-disable-next-line
  }, [currentCourse])

  const onCourseChange = course => {
    if (currentCourseId === course.id) return
    // Show loading if course has an id, no id no gradeReport
    course.id && setIsCourseLoading(true)
    setCurrentCourse(course)
    setCohortData([])
    setGradeData([])
    setCsvData([])
    setCurrentCohort('')
  }

  const onCohortChange = cohort => {
    if (cohort === currentCohort) return
    setGradeData([])
    setCsvData([])
    setCurrentCohort(cohort)
    setIsCohortLoading(true)
  }

  const handleSearchFilter = () => {
    const filterEmails = emailFilteredStudents.map(({ value }) => value)
    setSearchedEmails(filterEmails)
    getFilterGradeReport()
    setIsSearchEmailFilterApplied(!!filterEmails.length)
  }

  const handleHighlight = () => {
    let startingGrade
    let endingGrade
    if (startRangeLetterGrade) {
      const startRanges = startRangeLetterGrade.value.split('-')
      const endRanges = endRangeLetterGrade.value ? endRangeLetterGrade.value.split('-') : []
      startingGrade = Math.max(...startRanges, ...endRanges)
      endingGrade = Math.min(...startRanges, ...endRanges)
    } else {
      const startingRange = Math.max(startRangeNumericGrade, endRangeNumericGrade)
      const endingRange = Math.min(startRangeNumericGrade, endRangeNumericGrade)
      startingGrade = startingRange || endingRange
      endingGrade = endingRange || startingRange
    }
    setGradeRange(startingGrade, endingGrade)
    setIsHighlighFilterApplied(startingGrade || endingGrade)
  }
  const getFilterReportByStatus = (gradeReport) => {
    const selectedStatusArray = selectedStatus.map(({ value }) => value)
    const filteredReport = gradeReport.filter(({ studentStatus }) => {
      return selectedStatusArray.includes(studentStatus)
    })

    return filteredReport
  }

  const filterReportByTestAttempts = (gradeReport) => {
    if (showTestAttempts) return gradeReport
    const filteredReport = gradeReport.filter(({ testAttempt }) => {
      return !testAttempt
    })

    return filteredReport
  }

  const getFilterGradeReport = () => {
    if (!gradeData) return
    const formattedGrade = formatGradesLastActiveTimeStamp(gradeData)
    const gradeDataWithoutContractor = formattedGrade
      .filter(data => !data.contractorEmployee)
    const reportFilteredByStatus =
      getFilterReportByStatus(gradeDataWithoutContractor)
    const filteredReportByActiveDate = getReportByActiveDate({
      gradeData: reportFilteredByStatus,
      startDate: dateRangeFilterStart,
      endDate: dateRangeFilterEnd
    })
    const reportFilterByTestAttempts =
     filterReportByTestAttempts(filteredReportByActiveDate)

    setFilterGradeReport(reportFilterByTestAttempts)
  }

  const getFilterLabelText = () => {
    const isStartOrEndDate = dateRangeFilterStart || dateRangeFilterEnd
    const isBothFiltersApplied = isStartOrEndDate &&
      isSearchEmailFilterApplied

    if (!isStartOrEndDate && isSearchEmailFilterApplied) {
      return 'SEARCH (ID)'
    } else if (!isSearchEmailFilterApplied && isStartOrEndDate) {
      return 'SEARCH (LAST ACTIVE)'
    } else if (isBothFiltersApplied) {
      return 'SEARCH (ID / LAST ACTIVE)'
    } else {
      return 'SEARCH'
    }
  }

  const getHighlightLabelText = () => {
    const isStartAndEndGradeRange = startRangeLetterGrade || endRangeLetterGrade
    const isStartAndEndNumericRange = startRangeNumericGrade || endRangeNumericGrade

    if ((isStartAndEndNumericRange || isStartAndEndGradeRange) &&
     isHighlightFilterApplied) {
      return 'Highlight (Grade Range)'
    } else {
      return 'Highlight'
    }
  }

  useEffect(() => {
    getFilterGradeReport()

    // eslint-disable-next-line
  }, [selectedStatus, gradeData, showTestAttempts])

  // custom option for react-select
  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <CustomOptionContainer>
          <span>{children}</span>
          <CheckedBox type='checkbox' defaultChecked={props.isSelected} />
          <CustomCheckBox className='check-mark' />
        </CustomOptionContainer>
      </components.Option>
    )
  }

  // custom value container for react-select
  const ValueContainer = props => {
    const { getValue, hasValue, children, options } = props
    const newChildren = [...children]
    const selectedValuesCount = getValue().length
    const singleSelectedValue = getValue()[0]
    const allSelected = selectedValuesCount === options.length

    const getChildren = () => {
      if (!hasValue) {
        return newChildren
      } else if (selectedValuesCount === 1) {
        newChildren[0] = singleSelectedValue.label
      } else {
        newChildren[0] = `${allSelected ? 'All' : selectedValuesCount} Selected`
      }
      return newChildren
    }

    return (
      <components.ValueContainer {...props}>
        {getChildren()}
      </components.ValueContainer>
    )
  }

  const resetStatus = () => {
    setSelectedStatus(defaultStatuses)
  }

  const submitFinalGrades = async () => {
    const keysToInclude = [
      'attemptId',
      'grade',
      'email',
      'id',
      'studentStatus',
      'projectedGrade',
      'letterGrade',
      'statusChangeDate'
    ]
    const studentsGrade = gradeData.map(record => {
      return Object.keys(record).filter(
        key => keysToInclude.includes(key)).reduce((obj, key) => {
        obj[key] = record[key]
        return obj
      }, {})
    })
    const { id: cohortId } = currentCohort
    const result = await api.setFinalGrades(studentsGrade, cohortId, currentCourseId)
    setShowFinalGradesModal(false)
    const { success } = result
    const { current: { display } = {} } = toast

    return success
      ? display(<><SuccessIcon /> Grades Successfully Submitted.</>)
      : display(
        <><FailureIcon /> Something went wrong!</>,
        `Request was not successful. Please try again or notify the Product team.`
      )
  }
  return (
    <GradeReportContainer>
      <Toast toast={toast} />
      <ConfirmationModal
        showModalHandler={() => setShowFinalGradesModal(false)}
        show={showFinalGradesModal}
        confirm={{ text: 'Submit', handler: submitFinalGrades }}
        cancel={{ text: 'Cancel', handler: () => setShowFinalGradesModal(false) }}
      >
        Are you sure that you want to submit the final grades for this cohort?
      </ConfirmationModal>
      <FiltersContainer>
        <FilterHeading>Filters</FilterHeading>
        <FilterLabel
          onClick={handleSearchModalClick}
        >
          <img src={Search} alt='search.svg' />
          {getFilterLabelText()}
        </FilterLabel>
        <FilterLabel
          onClick={handleHighlightModalClick}
        >
          <img src={Highlight} alt='highlight.svg' />
          {getHighlightLabelText()}
        </FilterLabel>
      </FiltersContainer>

      {isSearchModalShow &&
        <SearchModal
          setIsSearchModalShow={setIsSearchModalShow}
          emailFilteredStudents={emailFilteredStudents}
          setEmailFilteredStudents={setEmailFilteredStudents}
          startDate={dateRangeFilterStart}
          setStartDate={setDateRangeFilterStart}
          handleFilter={handleSearchFilter}
          endDate={dateRangeFilterEnd}
          setEndDate={setDateRangeFilterEnd}
        />
      }
      {isHighlightModalShow &&
        <HighlightModal
          setIsHighlightModalShow={setIsHighlightModalShow}
          startRangeLetterGrade={startRangeLetterGrade}
          setStartRangeLetterGrade={setStartRangeLetterGrade}
          endRangeLetterGrade={endRangeLetterGrade}
          setEndRangeLetterGrade={setEndRangeLetterGrade}
          startRangeNumericGrade={startRangeNumericGrade}
          setStartRangeNumericGrade={setStartRangeNumericGrade}
          endRangeNumericGrade={endRangeNumericGrade}
          setEndRangeNumericGrade={setEndRangeNumericGrade}
          gradingScale={gradingScale}
          handleHighlight={handleHighlight}
        />
      }

      <DropDownContainer>
        <DropDownWrapper>
          <DropDownLabel>
            Course
          </DropDownLabel>
          <Select
            options={allCourses}
            value={currentCourse}
            isCohortLoading={isCourseLoading}
            onChange={(course) => onCourseChange(course)}
            placeholder='Select Course'
            styles={dropDownStyles}
            isDisabled={isCohortLoading}
          />
        </DropDownWrapper>

        <DropDownWrapper>
          <DropDownLabel>
            Cohort
          </DropDownLabel>
          <Select
            options={cohortData}
            value={currentCohort}
            isCohortLoading={isCohortLoading}
            onChange={(cohort) => onCohortChange(cohort)}
            placeholder='Select Cohort'
            styles={dropDownStyles}
            isDisabled={isCohortLoading}
          />
        </DropDownWrapper>

        <DropDownWrapper>
          <DropDownLabel>
            Student Status
          </DropDownLabel>
          <ResetButton onClick={resetStatus}>
            Reset
          </ResetButton>
          <Select
            options={allStatus}
            value={selectedStatus}
            onChange={(status) => setSelectedStatus(status)}
            isCohortLoading={isStatusLoading}
            placeholder='Select Status'
            styles={dropDownStyles}
            components={{ Option, ValueContainer }}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            isClearable={false}
            isMulti
          />
        </DropDownWrapper>

        <CheckBox>
          Show test attempts
          <input
            type='checkbox'
            defaultChecked={showTestAttempts}
            onChange={(e) => setShowTestAttempts(e.target.checked)}
          />
          <span />
        </CheckBox>
      </DropDownContainer>

      <CustomButton
        disabled={isCohortLoading || !csvData.length || isCsvButtonDisabled}
        className='btn-custom btn-primary'
      >
        <CSVLink
          data={csvData}
          filename={'student-grade-report.csv'}
        >
          {
            isCohortLoading
              ? <><FontAwesomeIcon spin icon={faSpinner} /> loading</>
              : 'generate csv'
          }
        </CSVLink>
      </CustomButton>
      <CustomButton
        style={{ marginLeft: '14px', width: '200px' }}
        disabled={isCohortLoading || !csvData.length || !isCohortEndLocalState}
        className='btn-custom btn-primary'
        onClick={() => setShowFinalGradesModal(true)}
      >
        {
          'Submit final grades'
        }
      </CustomButton>
      {currentCohort &&
        <TableContainer>
          <TableHeader>
            {currentCohort.name}
          </TableHeader>
          <BottomLine />
          {isCohortLoading
            ? <LoadingSpinner left={150} />
            : isApiFailed
              ? <p>Something went wrong</p>
              : <GradeReport
                grade={filterGradeReport}
                isCohortEnd={isCohortEndLocalState}
                onUserSelect={handleUserSelected}
                chapters={chapters}
              />
          }
        </TableContainer>
      }
    </GradeReportContainer>
  )
}

export default GradeReportWrapper

import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { CustomButton } from '../../CSVDownloadTool/style'

const DownloadButton = props => {
  const {
    isLoading,
    disableButton,
    handleClick
  } = props

  return (
    <>
      <CustomButton
        className='btn-custom btn-primary'
        disabled={disableButton || isLoading}
        onClick={handleClick}
      >
        {
          isLoading
            ? <><FontAwesomeIcon style={{ marginRight: '5px' }} spin icon={faSpinner} /> loading</>
            : <span>Generate CSV</span>
        }
      </CustomButton>
    </>
  )
}

DownloadButton.propTypes = {
  isLoading: PropTypes.bool,
  disableButton: PropTypes.bool,
  handleClick: PropTypes.func
}

DownloadButton.displayName = 'DownloadButton'

export default DownloadButton

import React from 'react'
import { components } from 'react-select'
import { ReactComponent as RightMarkSvg } from '../../assets/icons/icon-right-mark.svg'
import {
  FirstOptionWrapper,
  SelectLink,
  CustomOption,
  SelectWithCustomScroller,
  SelectLabel,
  multiSelectstyles
} from './styled'

const DefaultIconOption = (onSelectAll, onDeselectAll) => props => {
  if (props.data.value === 'SelectAll') {
    return (
      <FirstOptionWrapper>
        <SelectLink onClick={onSelectAll}>Select All</SelectLink>
        &nbsp;/&nbsp;
        <SelectLink onClick={onDeselectAll}>Deselect All</SelectLink>
      </FirstOptionWrapper>
    )
  }
  return (
    <components.Option {...props}>
      <CustomOption>
        {props.data.label}
        {props.isSelected ? <RightMarkSvg /> : null}
      </CustomOption>
    </components.Option>
  )
}

const CustomValueContainer = ({ children, getValue, ...props }) => {
  // Filter out the selected values from the children to hide them
  const newChildren = React.Children.toArray(children).filter(
    child => child && child.type !== components.MultiValue
  )

  // Count the number of selected options
  const count = getValue().length

  return (
    <components.ValueContainer {...props}>
      {newChildren}
      {count > 0 && `${count} course${count > 1 ? 's' : ''} selected`}
    </components.ValueContainer>
  )
}

export default function CourseSelect ({
  selectedCourses,
  setSelectedCourses,
  ...rest
}) {
  const options = [
    { value: 'SelectAll', label: null },
    { value: '1', label: 'Calculus I' },
    { value: '2', label: 'Precalculus' },
    { value: '3', label: 'Intro to Microeconomics' },
    { value: '4', label: 'Intro to Macroeconomics' },
    { value: '5', label: 'Intro to Statistics' },
    { value: '6', label: 'Computer Science I' },
    { value: '7', label: 'Into to Sociology' },
    { value: '8', label: 'College Writing I' },
    { value: '9', label: 'Professional Communication' },
    { value: '10', label: 'college Algebra' }
  ]

  return (
    <SelectLabel>
      <span id='select-label-text'>Course</span>
      <SelectWithCustomScroller
        isMulti
        styles={multiSelectstyles}
        value={selectedCourses}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        onChange={option => setSelectedCourses(option)}
        options={options}
        components={{
          Option: DefaultIconOption(
            () => setSelectedCourses(options.filter(o => o.value !== 'SelectAll')),
            () => setSelectedCourses([])
          ),
          ValueContainer: CustomValueContainer,
          ClearIndicator: () => null
        }}
        placeholder='Select the course(s)'
        isSearchable
        {...rest}
      />
    </SelectLabel>

  )
}

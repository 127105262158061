import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../ModalComponent/ModalComponent'
import {
  ModalHeader,
  ModalSeparator,
  ModalContent,
  RequestDetail
} from './styled'
import CloseIcon from '../../assets/icons/cross.svg'
import approveIcon from '../../assets/icons/icon-correct.svg'
import denyIcon from '../../assets/icons/icon-incorrect.svg'
import { getDateString } from '../../utilities/dateTimeUtil'

function HistoryModal ({ show, setShowHistoryModal, request }) {
  const {
    voucherNumber,
    requestReceived,
    certificateName,
    studentName,
    studentEmail,
    refundAmount,
    decision,
    decisionDate,
    purchaseDate,
    latestRefundDate: refundDate,
    earliestDropDate: dropDate
  } = request || {}
  const requestReceivedDate = new Date(requestReceived)
  const earliestDropDate = new Date(dropDate)
  const latestRefundDate = new Date(refundDate)
  const requestReceivedDateString = getDateString(
    requestReceivedDate,
    'MMM DD, YYYY'
  )
  const purchaseDateString = getDateString(purchaseDate, 'MM/DD/YY')
  const latestRefundDateString = getDateString(latestRefundDate, 'MM/DD/YY')
  const earliestDropDateString = getDateString(earliestDropDate, 'MM/DD/YY')
  const withinRefundWindow = requestReceivedDate < latestRefundDate
  const withinDropDeadline = requestReceivedDate < earliestDropDate
  const decisionDateString = getDateString(decisionDate, 'MM/DD/YY')
  return (
    <Modal handleClose={setShowHistoryModal} show={show}>
      <ModalHeader>
        <p>{voucherNumber}</p>
        <img
          data-testid='modal-close'
          src={CloseIcon}
          alt='Close Exit Modal'
          onClick={() => setShowHistoryModal(false)}
        />
      </ModalHeader>
      <ModalSeparator />
      <ModalContent>
        <div>
          <p>Name: {studentName}</p>
          <p>Email: {studentEmail}</p>
          <p>Request received: {requestReceivedDateString}</p>
          <p>Purchase: {certificateName}</p>
          <p>Purchase Date: {purchaseDateString}</p>
        </div>
        <div>
          <RequestDetail {...(withinRefundWindow ? {} : { color: '#FF8064' })}>
            <img
              src={withinRefundWindow ? approveIcon : denyIcon}
              alt='Within Refund Window'
            />
            Within refund window ({latestRefundDateString})
          </RequestDetail>
          <RequestDetail {...(withinDropDeadline ? {} : { color: '#FF8064' })}>
            <img
              src={withinDropDeadline ? approveIcon : denyIcon}
              alt='Within Drop Deadline'
            />
            Within drop deadline ({earliestDropDateString})
          </RequestDetail>
        </div>
        <div>
          <p>Decision: {decision}</p>
          <p>Decision made: {decisionDateString}</p>
          <p>
            Refund:{' '}
            {refundAmount[0] === '$' ? refundAmount : `$${refundAmount}`}
          </p>
        </div>
      </ModalContent>
    </Modal>
  )
}

HistoryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowHistoryModal: PropTypes.func,
  request: PropTypes.object
}

export default HistoryModal

import styled from 'styled-components'
import { selectInputStyles } from '../styled'

export const multiSelectstyles = {
  ...selectInputStyles,
  control: (base, state) => ({
    ...base,
    width: '400px',
    background: state.isDisabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.3)',
    color: '#FFFFFF',
    border: `1px solid ${
      state.isFocused
        ? 'rgba(255, 255, 255, 0.3)'
        : state.isDisabled
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.3)'
    }`,
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid rgba(255, 255, 255, 0.3)'
    }
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled && 'rgba(255, 255, 255, 0.3)'
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'var(--sub-text-secondary)',
    minHeight: '40px',
    paddingLeft: '16px',
    paddingRight: '18px',
    background: '#25272B!important',
    border: '1px solid transparent',
    ...(state.isSelected
      ? {
        color: '#FFFFFF'
      }
      : {}),
    '&:hover': {
      border: '1px solid #5FC4B8',
      borderRadius: '5px',
      color: '#FFFFFF',
      cursor: 'pointer'
    },
    '&:focus': {
      border: '1px solid #98A4AE'
    }
  })
}

export const DropdownHeader = styled.div`
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;

  display: flex;
  align-items: center;
  background: #25272b !important;
  height: 56px;
  height: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 16px;
  border-bottom: 1px solid #98a4ae;
`
export const CustomOption = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`

const NUMBER_OF_NOTIFICATIONS = 99
export const NOTIFICATIONS = Array(NUMBER_OF_NOTIFICATIONS).fill(0).map((item, i) => ({
  time: 'Dec 6, 2020 1:00PM EST',
  numberOfRecipients: i,
  headline: 'This is the entire notification message' + i,
  additionalDetail: 'This is some extra information' + i,
  actionLinks: [
    { title: 'Action Text' + i, value: 'https://google.com' },
    { title: 'Action Text' + (i + 1), value: 'https://google.com' }
  ],
  recipients: [],
  enrollmentStatus: []
}))
export const mockEnrollStudentResponse = {
  success: true,
  enrolledStudent: {
    student: {
      id: 'recDEt2nMeHi3IQqF',
      fields: {
        _sync_inserted_at: '2023-08-04T20:22:52.318Z',
        _sync_updated_at: '2024-03-22T22:28:12.381Z',
        admins: null,
        admins_2: null,
        amazonvoucher: null,
        amazonvoucher_2: [],
        contentgatingenabled: false,
        ispartnercohort_from_cohorts: [
          null
        ],
        pretestattempt: [],
        pretestoverrides: [],
        record_id: 'recDEt2nMeHi3IQqF',
        guildrcstatus: null,
        guildadmissionstatus: null,
        guildpartnerprogramname: null,
        admins_3: null,
        id: 'sclkx3e1x9000cuwjueijw51h3',
        instrideProfileId: null,
        instrideId: null,
        instrideRecipient: null,
        instrideDirectBill: null,
        dateSubscriptionCancelled: null,
        instrideTos: null,
        dateEligibilityLost: null,
        instrideEligible: null,
        instrideSubscriptionStatus: null,
        instrideGoodStanding: null,
        instrideAppliedForGraduation: null,
        relationships: [],
        instrideRefundAdjustments: null,
        academicPeriodIdentifier: null,
        termEligibilityStatus: null,
        termIneligibilityReasonCode: null,
        termEligibilityLockedDate: null,
        termEligibilityChange: null,
        financialStatus: null,
        fundingMax: null,
        fundingRemaining: null,
        partnerProgramId: null,
        employerName: null,
        guildExternalId: null,
        guildUpdatedAt: null,
        notes: null,
        courses: [
          {
            id: 'rec8TAMnQ2qXqFtTq',
            fields: {
              _sync_inserted_at: '2023-08-01T21:01:48.102Z',
              _sync_updated_at: '2024-03-22T22:28:00.701Z',
              cohortschedulesext: [],
              csatresponses: null,
              npsexitresponses: '5, 7',
              npsresponsesv2: null,
              pretestattempt: [
                'recFaU9JDM8ZymVs2'
              ],
              pretestoverrides: [
                'recxmcUmn2joQDt5I'
              ],
              record_id: 'rec8TAMnQ2qXqFtTq',
              relationships_2: [
                'recwnBZBVOHfulerp',
                'recgKy7j2UupmjSF9'
              ],
              upittoverrides: [],
              gguappcomprecsubstitutes: [],
              ggubusadminrecsubstitutes: [],
              gguliberalstudiesrecsubstitutes: [],
              outliercourselicense: [
                'recPcuNsHhsxVhJ6B'
              ],
              id: 'b227c462-332c-40e0-8735-ea3af6f11661',
              name: 'Calculus I',
              displayName: 'Calculus I',
              students: [
                'rec03fjdSevlc3U6z',
                'recULgDQA5uMpgnNs'
              ],
              cohorts: [
                'recYYwCfiAt7YHHzO',
                'recgCqFh7VoqGyewP'

              ],
              pittCourseCode: null,
              tutors: 'Test Tutor',
              participationGrades: '1, 4, 6',
              creditTransfers: '1',
              npsResponses: null,
              created_time: '2019-07-04T23:55:27.000Z',
              relationships: null,
              certificates: [
                'recGYXG8YfUZzRzdU',
                'rec8AzWSW9tJcd7xL',
                'recpmbIxhVyLSP4d0',
                'recUPRiQ6tjBpJkdA'
              ],
              certificates_2: [],
              certificates_3: [],
              certificates_4: [],
              prospects: [
                'recfB6jYeOQuWuJ4l',
                'rec8JesoxMjYhNpWy'
              ],
              prospects_2: [],
              prospects_3: [],
              gguAppCompRec: [],
              gguBusAdminRec: [],
              gguLiberalStudiesRec: [],
              standardSyllabus: [
                'https://proxy.sequin.io/attachment/eyJhdHRhY2htZW50X2lkIjoiYXR0MFpUUHdlY1V5NFc4UEYiLCJhdHRhY2htZW50c19maWVsZCI6InN0YW5kYXJkU3lsbGFidXMiLCJiYXNlX2lkIjoiYXBweFdwREluNU1XSk9hYVgiLCJyZWNvcmRfaWQiOiJyZWM4VEFNblEycVhxRnRUcSIsInRhYmxlX25hbWUiOiJ0YmxyU3hmVjBkMlRadzYzayIsInR5cGUiOiJkeW5hbWljX3VybCJ9'
              ],
              intensiveSyllabus: [
                'https://proxy.sequin.io/attachment/eyJhdHRhY2htZW50X2lkIjoiYXR0R1ptUUg0SUNFdEtHcnMiLCJhdHRhY2htZW50c19maWVsZCI6ImludGVuc2l2ZVN5bGxhYnVzIiwiYmFzZV9pZCI6ImFwcHhXcERJbjVNV0pPYWFYIiwicmVjb3JkX2lkIjoicmVjOFRBTW5RMnFYcUZ0VHEiLCJ0YWJsZV9uYW1lIjoidGJsclN4ZlYwZDJUWnc2M2siLCJ0eXBlIjoiZHluYW1pY191cmwifQ=='
              ],
              assignmentList: [],
              creditGrantingInstitution: 'University of Pittsburgh',
              profCert: false,
              updatedAt: '2024-03-22T22:27:43.000Z',
              createdAt: '2019-07-04T23:55:27.000Z',
              beta: true,
              skuId: 1,
              students_2: [
                'recaf9nT4yAbjqE7r'

              ],
              active: true,
              attempts: [
                'rec21xUX84m5YDi71',
                'recpODPZRHkeesdDY'

              ],
              gguCourseCode: null
            }
          }
        ],
        name: null,
        cohorts: [
          {
            id: 'rec7KRjhOmuzARHuj',
            fields: {
              _sync_inserted_at: '2024-02-13T21:28:15.636Z',
              _sync_updated_at: '2024-03-22T22:27:53.636Z',
              csatresponses: null,
              newdrop_copy: null,
              npsresponses: null,
              prospects_2: null,
              record_id_function: 'rec7KRjhOmuzARHuj',
              relationships_2: [],
              name: 'Calculus - 2/1/24',
              notes: null,
              created_time: '2024-02-13T21:27:58.000Z',
              course: [
                'rec8TAMnQ2qXqFtTq'
              ],
              students: [
                'recC3jIY7Abfzqr8y',
                'reco0H6NNuV4o1GxH',
                'recDEt2nMeHi3IQqF'
              ],
              dateStart: '2024-03-20',
              duration: 14,
              courseInfoUrl: null,
              finalGradeDate: null,
              finalRegistrationDate: '2024-02-10',
              finalWithdrawalDate: '2024-02-29',
              midTerm1StartTime: '2024-02-25T05:28:00.000Z',
              midTerm1EndTime: '2024-02-27T05:28:00.000Z',
              midTerm2StartTime: '2024-03-23T06:28:00.000Z',
              midTerm2EndTime: '2024-03-31T06:28:00.000Z',
              finalExamStartTime: '2024-02-21T05:28:00.000Z',
              finalExamEndTime: '2024-05-31T05:29:00.000Z',
              midTerm1KeyOverride: [],
              midTerm2KeyOverride: [],
              exam3KeyOverride: [],
              exam4KeyOverride: [],
              exam5KeyOverride: [],
              finalExamKeyOverride: [],
              exam3StartTime: null,
              exam3EndTime: null,
              exam4StartTime: null,
              exam4EndTime: null,
              exam5StartTime: null,
              exam5EndTime: null,
              cohortMilestones: [],
              relationships: [],
              usersnapMenuOverride: null,
              calendarId: null,
              calendarKey: null,
              instructors: [],
              students_2: [],
              finalDropDate: '2024-02-24',
              isPartnerCohort: false,
              gradesSubmittedToPitt: false,
              dateGradesSubmitted: null,
              purchases: [
                'reco7zeQBvLlzwPVs'
              ],
              updatedAt: '2024-03-22T22:27:43.000Z',
              createdAt: '2024-02-13T21:27:58.000Z',
              skuId: 1539,
              drop: [],
              syllabus: [],
              withdraw: [],
              audit: [],
              exception: [],
              midterm1Key: null,
              midterm2Key: null,
              exam3Key: null,
              exam4Key: null,
              exam5Key: null,
              finalExamKey: null,
              specialDays: [],
              disabled: false,
              examExtensions: [],
              cohortSchedulesIntensive: [],
              cohortSchedules: [
                'recYqf5m4fI195iAq'
              ],
              cohortSchedulesExt: [],
              contentGatingEnabled: false,
              testCohort: false,
              auditContentLock: false,
              term: [],
              eventsUpdatedAt: '2024-02-21T18:36:12.000Z',
              hideStudentGradeDisplay: false,
              tutors: null,
              studyGroups: null,
              specialWeeks: null,
              attempts: [
                'recZC08V1I2LPhgdD',
                'recFKn7u9or3DJxTH',
                'recsm81okEfWddhB1',
                'rec9fJnkyDAoHNQfb'
              ],
              newDrop: [],
              newWithdraw: [],
              examRetakes: [],
              tuitionVoucher: [],
              creditGrantingInstitution_from_relationships: [],
              finalAdminDropDate: null,
              prospects: []
            }
          }
        ],
        username: null,
        vip: false,
        pwaOnboard: false,
        vipGradedContent: false,
        contractorEmployee: false,
        email: 'joyceqastudent+t10@gmail.com',
        notStudent: false,
        noAssessments: false,
        enrollingStudentCertification: null,
        citizenship: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        postalCode: null,
        phoneNumber: null,
        gender: null,
        dateOfBirth: null,
        dateTOS: null,
        firstName: null,
        lastName: null,
        pastCohorts: [],
        pastCourses: [],
        preferredName: null,
        country: null,
        hasHighSchoolEducation: null,
        currentUniversity: null,
        reachedOutToInstitution: null,
        expectedHsGraduation: null,
        isPITStudent: null,
        institutionsApplyingTo: null,
        major: null,
        minor: null,
        ethnicity: null,
        householdIncome: null,
        fafsaContribution: null,
        employmentStatus: null,
        firstGenCollegeStudent: null,
        veteranOrServiceMember: null,
        collegeLevel: null,
        eligibleOrReceivingPellGrants: null,
        creditTransferDestination: null,
        source: null,
        isRegistered: false,
        attemptID: null,
        hasAIF: false,
        hasOLRF: false,
        primaryGoal: null,
        settingGoalsAndDeadlines: null,
        goodReasonForOnlineCourse: null,
        finishProjects: null,
        doNotQuit: null,
        onTrackAndTime: null,
        learnEasily: null,
        learnFromHearing: null,
        learnFromReading: null,
        problemSolving: null,
        learnSolvingAlone: null,
        learnInGroupAndAlone: null,
        willInteractWithStrangers: null,
        workplaceWithoutDistractions: null,
        canIgnoreDistractions: null,
        willSpend20HoursWeekly: null,
        recordAssignmentDeadlines: null,
        planWorkInAdvance: null,
        surroundingPeopleWontDistract: null,
        useOnlineToolsForHelp: null,
        goodAtUsingComputers: null,
        canSurfInternet: null,
        searchesBookmarksDownloadFiles: null,
        softwareAndConfigurationSettings: null,
        accessToComputerHelp: null,
        reliableInternet: null,
        computerAccess: null,
        windowsOrMac: null,
        haveGoogleChrome: null,
        haveWebcamMicrophone: null,
        goodSpaceForExams: null,
        'OLRF Score': null,
        grantedException: false,
        cohortUpdate: '2024-03-22T22:27:42.000Z',
        attempts: [
          'rec9fJnkyDAoHNQfb'
        ],
        dateToRemoveAccess: null,
        examExtensions: [],
        under13: false,
        prospectInfo: [],
        skooliUsername: null,
        updatedAt: '2024-03-22T22:27:43.000Z',
        createdAt: '2023-08-04T20:22:52.000Z',
        studentGuardians: [],
        tokens: [
          'recHg9cB5dEAu8Vrx',
          'recBRxVJnspw7XPNO',
          'recbsBnQcOcrq42mM'
        ],
        examRetakes: [],
        guildFileName: null,
        studyGroups: null,
        participationGrades: null,
        creditTransfers: null,
        studentCommEmail: null,
        planToTransferCredit: null,
        typeformResponseId: null,
        klarnaConsent: false,
        mAddress1: null,
        mAddress2: null,
        mCity: null,
        mState: null,
        mPostalCode: null,
        mCountry: null,
        mResults: null,
        mLatitude: null,
        mLongitude: null,
        mCounty: null,
        mCountyFIPS: null,
        mAddressKey: null,
        mAddressKeyBase: null,
        npsResponses: null,
        lastNameChangedFrom: null,
        created_time: '2023-08-04T20:22:52.000Z',
        csatResponses: null,
        npsResponsesV2: null,
        upittOverrides: [],
        npsExitResponses: null,
        previousEmail: null,
        secondaryGuildEmail: null,
        outlierExternalId: null,
        gguStudentId: null,
        gguCertification1: false,
        gguCertification2: false,
        gguCertification3: false,
        isProfCertRegistered: false,
        zoomConsent: false,
        gguParentCertification1: false,
        gguParentCertification2: false,
        gguParentCertification3: false
      }
    },
    attempt: {
      _table: {
        _base: {
          _airtable: {
            requestTimeout: 300000
          },
          _id: 'appxWpDIn5MWJOaaX'
        },
        id: null,
        name: 'attempts'
      },
      id: 'reczQYTwSD2ANnhdN',
      _rawJson: {
        id: 'reczQYTwSD2ANnhdN',
        createdTime: '2024-03-23T04:13:47.000Z',
        fields: {
          attemptID: 'clu3ksalz0000e4grewq5477i',
          cohort: [
            'recQLhKIZ28bKHEfd'
          ],
          studentStatus: [
            'rec8GvanKI4AJn46C'
          ],
          outlierID: [
            'recDEt2nMeHi3IQqF'
          ],
          course: [
            'recbcQJwfDk4LVvSe'
          ],
          tokenId: [
            'recbsBnQcOcrq42mM'
          ],
          statusChangeDate: '2024-03-23T04:13:47.000Z',
          'email (from students tab)': [
            'joyceqastudent+t10@gmail.com'
          ],
          'finalDropDate (from cohort)': [
            '2024-01-25'
          ],
          'dateStart (from cohort)': [
            '2024-01-11'
          ],
          'courseEndTime (from cohort)': [
            '2024-04-17T15:00:00.000Z'
          ],
          createdAt: '2024-03-23T04:13:47.000Z',
          'skuId (from course)': [
            5
          ],
          'skuId (from cohort)': [
            1358
          ],
          sku: '5-1358',
          'finalExamEndTime (from cohort)': [
            '2024-04-17T15:00:00.000Z'
          ],
          recId: 'reczQYTwSD2ANnhdN'
        }
      },
      fields: {
        attemptID: 'clu3ksalz0000e4grewq5477i',
        cohort: [
          'recQLhKIZ28bKHEfd'
        ],
        studentStatus: [
          'rec8GvanKI4AJn46C'
        ],
        outlierID: [
          'recDEt2nMeHi3IQqF'
        ],
        course: [
          'recbcQJwfDk4LVvSe'
        ],
        tokenId: [
          'recbsBnQcOcrq42mM'
        ],
        statusChangeDate: '2024-03-23T04:13:47.000Z',
        'email (from students tab)': [
          'joyceqastudent+t10@gmail.com'
        ],
        'finalDropDate (from cohort)': [
          '2024-01-25'
        ],
        'dateStart (from cohort)': [
          '2024-01-11'
        ],
        'courseEndTime (from cohort)': [
          '2024-04-17T15:00:00.000Z'
        ],
        createdAt: '2024-03-23T04:13:47.000Z',
        'skuId (from course)': [
          5
        ],
        'skuId (from cohort)': [
          1358
        ],
        sku: '5-1358',
        'finalExamEndTime (from cohort)': [
          '2024-04-17T15:00:00.000Z'
        ],
        recId: 'reczQYTwSD2ANnhdN'
      }
    },
    oldCohortMigration: false
  }
}

export const mockGradingCompDataResponse = [
  {
    courseTabName: 'College Algebra - 7 Week',
    students: [
      {
        atGradeData: {
          totalGrade: 62.39,
          participationGrade: 59.35,
          quizzesGrade: 37.86115384615385,
          midtermGrade: 13.750500000000002,
          finalExamGrade: 7.8125,
          finalExamPercent: 0.25,
          midExamsPercent: 0.1,
          sectionQuizzesPercent: 0.5,
          participationPercent: 0.05
        },
        projectedGrade: {
          totalGrade: 61.25361111111111,
          midExamsPercent: 20,
          finalExamPercent: 25,
          participationPercent: 5,
          sectionQuizzesPercent: 50,
          writingAssignmentsPercent: 0,
          finalExamGrade: 7.8125,
          midtermGrade: 13.750499999999999,
          quizzesGrade: 36.57111111111111,
          participationGrade: 3.1195,
          writingAssignmentGrade: 0,
          assignmentGrade: 0,
          courseraGrade: 0
        }
      },
      {
        atGradeData: {
          totalGrade: 80.22,
          participationGrade: 55.95,
          quizzesGrade: 38.82711538461539,
          midtermGrade: 18.1,
          finalExamGrade: 20.5,
          finalExamPercent: 0.25,
          midExamsPercent: 0.1,
          sectionQuizzesPercent: 0.5,
          participationPercent: 0.05
        },
        projectedGrade: {
          totalGrade: 81.0284074074074,
          midExamsPercent: 20,
          finalExamPercent: 25,
          participationPercent: 5,
          sectionQuizzesPercent: 50,
          writingAssignmentsPercent: 0,
          finalExamGrade: 20.5,
          midtermGrade: 18.1,
          quizzesGrade: 38.41740740740741,
          participationGrade: 4.011,
          writingAssignmentGrade: 0,
          assignmentGrade: 0,
          courseraGrade: 0
        }
      },
      {
        atGradeData: {
          totalGrade: 44.44,
          participationGrade: 6.85,
          quizzesGrade: 28.473653846153844,
          midtermGrade: 8.75,
          finalExamGrade: 6.875,
          finalExamPercent: 0.25,
          midExamsPercent: 0.1,
          sectionQuizzesPercent: 0.5,
          participationPercent: 0.05
        },
        projectedGrade: {
          totalGrade: 45.72903703703704,
          midExamsPercent: 20,
          finalExamPercent: 25,
          participationPercent: 5,
          sectionQuizzesPercent: 50,
          writingAssignmentsPercent: 0,
          finalExamGrade: 6.875,
          midtermGrade: 8.75,
          quizzesGrade: 27.88203703703704,
          participationGrade: 2.222,
          writingAssignmentGrade: 0,
          assignmentGrade: 0,
          courseraGrade: 0
        }
      },
      {
        atGradeData: {
          totalGrade: 100,
          participationGrade: 100,
          quizzesGrade: 50,
          midtermGrade: 20,
          finalExamGrade: 25,
          finalExamPercent: 0.25,
          midExamsPercent: 0.1,
          sectionQuizzesPercent: 0.5,
          participationPercent: 0.05
        },
        projectedGrade: {
          totalGrade: 100,
          midExamsPercent: 20,
          finalExamPercent: 25,
          participationPercent: 5,
          sectionQuizzesPercent: 50,
          writingAssignmentsPercent: 0,
          finalExamGrade: 25,
          midtermGrade: 20,
          quizzesGrade: 50,
          participationGrade: 5,
          writingAssignmentGrade: 0,
          assignmentGrade: 0,
          courseraGrade: 0
        }
      },
      {
        atGradeData: {
          totalGrade: 95.15,
          participationGrade: 95.65,
          quizzesGrade: 48.00692307692308,
          midtermGrade: 19.142000000000003,
          finalExamGrade: 23.215,
          finalExamPercent: 0.25,
          midExamsPercent: 0.1,
          sectionQuizzesPercent: 0.5,
          participationPercent: 0.05
        },
        projectedGrade: {
          totalGrade: 94.76801851851852,
          midExamsPercent: 20,
          finalExamPercent: 25,
          participationPercent: 5,
          sectionQuizzesPercent: 50,
          writingAssignmentsPercent: 0,
          finalExamGrade: 23.215,
          midtermGrade: 19.142000000000003,
          quizzesGrade: 47.653518518518524,
          participationGrade: 4.7575,
          writingAssignmentGrade: 0,
          assignmentGrade: 0,
          courseraGrade: 0
        }
      }
    ]
  }
]

export const mockCoursesLicensesDataResponse = [{
  id: 'outgYbt9LQk8FPVd3',
  course: {
    name: 'College Algebra',
    id: 'reciA6sD51Ze9TJrc',
    id_: 'ckw6tiqy100003e5xexynd0dz',
    displayname: 'College Algebra'
  },
  tokenNumber: 'tclw86378s000123s6csrkd8px',
  courseLicense: 'MATH.0019.7w',
  usedStatus: null,
  relationshipName: '39W Live Proctoring Test',
  districtName: null
}, {
  id: 'recHd3ZhkdLuVNWo5',
  course: {
    name: 'Calculus I',
    id: 'recWwrn6UVHJrDpCu',
    id_: 'b227c462-332c-40e0-8735-ea3af6f11661',
    displayname: 'Calculus I'
  },
  tokenNumber: 'tcluinufxl000431s6fndje9tg',
  courseLicense: 'MATH.0217.14w',
  usedStatus: 'pending',
  relationshipName: '39W Live Proctoring Test',
  districtName: null
}]

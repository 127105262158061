import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'

export const TextOverlay = styled.p`
    color: var(--text-primary) !important;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 19.2px !important;
    text-align: center;
    z-index: 100;
    margin-bottom: 0;

    span {
      color: var(--brand-regular);
      cursor: pointer;
      margin-left: 2px;
    }
`

export const FileUploaderContainer = styled.div`
  width: 100%;
  position: relative;
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  border: 1px dashed rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  height: ${props => !props.isSmallInput ? 190 : 46}px;
  
  ${props => props.isSmallInput && `
    p{
      margin: 10px !important;
      line-height: 24px !important;
    }
  `}
  
  ${mediaqueries.mobile`
    height: 66px;
  `}
  
  #overlay {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgb(0 0 0 / 54%);
    border-radius: 5px;
    ${props => !props.isDisabled && `
    display: none;
  `}
  }
`
export const FileUploadLabel = styled.label`
 font-size: 14px;
 font-weight: 400;
 line-height: 16.8px;
 margin-bottom: 12px;
 width: 100%;
 display: flex;
 justify-content: space-between;

 > P {
  padding: 0;
  margin: 0;
 }

 img {
   margin-left: 5px;
 }
`
export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  > span {
    visibility: hidden;
    width: 183.59px;
    height: 75.9px;
    background: rgba(79, 79, 79, 0.8);
    color: var(--text-primary);
    text-align: center;
    padding: 8px;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: -184%;
    left: 482%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    > p {
      &:first-child {
        margin-bottom: 5px;
      }

      font-weight: 700;
      font-size: 14px;
      line-height: 16.8px;
    }
  }

  &:hover {

    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const FilePreview = styled.div`
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid var(--border-primary);
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 18px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 12px 6px 9px;
  ${mediaqueries.mobile`height: fit-content;`}
  
  p {
    color: var(--text-primary);
    text-align: left;
    display: flex;
    align-content: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.8px;
    padding: 0;
    margin:0;
    img {
      align-self: center;
    }
  }

  #remove {
    cursor: pointer;
    
    span {
      margin-right: 15px;
    }
  }
`

export const UploadingInput = styled.input`
  &::-webkit-file-upload-button {
    display: none;
  }

  &:focus{
    outline: none;
  }

  background-color: var(--white);
  width: 100%;
  height: 39px;
  text-align: center;
  background: transparent;
  position: absolute;
  color: transparent;
  bottom: 0;
  visibility: hidden;
  left: 0;
`
export const Link = styled.a`
  color: var(--brand-regular);
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  
  ${(props) => props.isSmallInput && `
    text-transform: none;
    color: var(--brand-regular) !important;
    font-weight: 400 !important;
  `}
  
  :hover {
    text-decoration: none;
    color: var(--brand-regular-hover);
  }
`

export const UploadIcon = styled.img`
  margin: 50px auto 19px auto; 
  display: block;
`

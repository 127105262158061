import React from 'react'
import {
  CohortCell,
  CohortEnded,
  EditLinkCell,
  ExtensionRecordLabel,
  StudentCell,
  StudentCellLight,
  ToolTip,
  ToolTipContainer,
  ZendeskCell
} from './style'
import iconTooltip from '../../assets/icons/icon-tooltip.svg'

const ExtensionRecordsTablet = ({ recordToDisplay, recordRow, handleEditExtension, isCohortEnded }) => {
  const {
    cohortName,
    assessment,
    type,
    zendeskUrl,
    displayedOriginalDeadline,
    displayedExtendedDeadline
  } = recordToDisplay
  return (
    <>
      <CohortCell>
        <ExtensionRecordLabel>Cohort</ExtensionRecordLabel>
        {cohortName}
        {isCohortEnded && <CohortEnded>Cohort Ended</CohortEnded>}
      </CohortCell>
      <StudentCell>
        <ExtensionRecordLabel>Assessment</ExtensionRecordLabel>
        {assessment}
      </StudentCell>
      <StudentCellLight>
        <ExtensionRecordLabel>
          Type
          <ToolTipContainer>
            <img src={iconTooltip} alt='icon-tooltip' />
            <ToolTip>
              Automatic extensions are from the extension request form. Student
              Success creates manual extensions.
            </ToolTip>
          </ToolTipContainer>
        </ExtensionRecordLabel>
        {type}
      </StudentCellLight>
      <ZendeskCell className='zendesk-cell'>
        <ExtensionRecordLabel>Ticket</ExtensionRecordLabel>
        {zendeskUrl && (
          <a href={zendeskUrl} target='_blank' rel='noopener noreferrer'>
            Zendesk ticket
          </a>
        )}
      </ZendeskCell>
      <StudentCellLight style={{ whiteSpace: 'pre' }}>
        <ExtensionRecordLabel>
          Original Deadline
        </ExtensionRecordLabel>
        {displayedOriginalDeadline}
      </StudentCellLight>
      <StudentCell style={{ whiteSpace: 'pre' }}>
        <ExtensionRecordLabel>New Deadline</ExtensionRecordLabel>
        {displayedExtendedDeadline}
        <EditLinkCell
          style={{ marginInlineStart: '32px' }}
          onClick={() => handleEditExtension(recordRow)}
        >
          Edit
        </EditLinkCell>
      </StudentCell>
    </>
  )
}

ExtensionRecordsTablet.displayName = 'ExtensionRecordsTablet'
export default ExtensionRecordsTablet

import styled from 'styled-components'
import mediaqueries from '../../mediaQueries'

export const ModalWrapper = styled.div`
  & h2 {
    margin-right: 24px;
  }
  & .modal-wrapper {
    ${mediaqueries.tablet`
        margin: 0 64px;
    `}

    ${mediaqueries.smallMobile`
        margin: 0 16px;
        width: auto;
    `}
  }
`

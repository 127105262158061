import React, { memo } from 'react'
import ReactPlayer from 'react-player'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { FileDeletedTextContainer } from './styled'
import useFetchVideo from './useFetchVideo'

const CustomVideo = memo(({ isCohortEndFourYearsAgo, videoUrl, ...rest }) => {
  const { url, isUrlLoading, isFileNotFound } =
    useFetchVideo(videoUrl)

  if (isUrlLoading) {
    return <LoadingSpinner position='static' marginTop={0} height={0} />
  }

  if (isFileNotFound && isCohortEndFourYearsAgo) {
    return (
      <FileDeletedTextContainer>
        <span>This file has been deleted per Savvas' data retention policy</span>
      </FileDeletedTextContainer>
    )
  }

  return <ReactPlayer url={url} {...rest} light />
})

export default CustomVideo

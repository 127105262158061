import React from 'react'
import {
  HeaderItem,
  PlainCell,
  TableBody,
  TableContainer,
  TableDescription,
  TableDescriptionContainer,
  TableHeader,
  TableRow,
  TableTitle
} from './styles'
import { flagsAndReportsHeaders } from './constants'

const defaultTables = [
  {
    name: 'Received a warning',
    description:
      'Student clicked away from exam or approved materials for the first time'
  },
  {
    name: 'Removed at least once',
    description:
      'Student clicked away from exam or approved materials for the second time'
  }
]

function DefaultState () {
  return defaultTables.map((table, index) => (
    <TableContainer key={index}>
      <TableDescriptionContainer>
        <TableTitle>{table.name}</TableTitle>
        <TableDescription>{table.description}</TableDescription>
      </TableDescriptionContainer>
      <TableHeader isDefault>
        {flagsAndReportsHeaders.map((header, index) => (
          <HeaderItem key={index}>{header.title}</HeaderItem>
        ))}
      </TableHeader>
      <TableBody>
        {[1, 2].map((_, index) => (
          <TableRow isDefault key={index}>
            <PlainCell />
            <PlainCell />
            <PlainCell />
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  ))
}

export default DefaultState

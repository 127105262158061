import React, { useState } from 'react'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
  Label
} from 'recharts'
import { getPieData, getStatusText } from './studentRecordDetailsUtils'
import { StudentAttemptsChartContainer } from './style'

const StudentAttemptsChart = ({ attempts }) => {
  const [selectedCategory, setSelectedCategory] = useState(null)

  const pieData = getPieData(attempts)

  const onClickHandler = (e) => {
    if (e.name === selectedCategory?.name) {
      setSelectedCategory(null)
      return
    }
    const category = pieData.find(category => category.name === e.name)
    setSelectedCategory(category)
  }
  const labelDefaultText = 'Select a section to view details!'
  const CustomLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox
    const statuses = selectedCategory?.statuses || []
    const statusCount = statuses.length
    return (
      <>
        <text x={cx} y={cy - (13 * statusCount)} textAnchor='middle' style={{ textAnchor: 'middle' }} className='class-1'>
          {statuses.map((status, index) => {
            const statusText = getStatusText(status.name)
            return (
              <tspan
                key={index}
                x={cx}
                dy='1.5em'
                style={{
                  fontSize: 16,
                  fill: '#CCD4D8',
                  fontStyle: !selectedCategory ? 'italic' : 'normal',
                  width: 111,
                  lineHeight: '24px'
                }}
              >
                {`${status.count} ${statusText}\n`}
              </tspan>
            )
          }
          )}
        </text>
      </>
    )
  }

  return (
    <StudentAttemptsChartContainer>
      <ResponsiveContainer width={264} height='100%'>
        <PieChart>
          <Pie
            data={pieData}
            dataKey='value'
            cx='50%'
            cy='50%'
            innerRadius={80}
            outerRadius={105}
            paddingAngle={0}
            fill='#8884d8'
            onClick={(e) => { onClickHandler(e) }}
          >
            {pieData.map((entry, index) => (
              <Cell key={index} fill={entry.color} stroke='none'
                style={{ opacity: selectedCategory?.name && selectedCategory?.name !== entry.name ? '30%' : '100%' }}
              />
            ))}
            <Label
              position='center'
              fontSize={16}
              fill='#CCD4D8'
              fontStyle={!selectedCategory ? 'italic' : 'normal'}
              width={111}
              lineHeight={'24px'}
              content={selectedCategory
                ? <CustomLabel />
                : () => { return labelDefaultText }}
            />
          </Pie>
          <Legend verticalAlign='bottom' align='left' height={80} width={287} wrapperStyle={{ fontSize: '14px', bottom: '9px' }} />
        </PieChart>
      </ResponsiveContainer>
    </StudentAttemptsChartContainer>
  )
}
StudentAttemptsChart.displayName = 'StudentAttemptsChart'
export default StudentAttemptsChart

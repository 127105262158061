import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'
import {
  selectInputStyles,
  StyledWrapper,
  LabelContainer,
  CheckBox,
  SelectAllButton,
  DeselectAllButton,
  CheckedBox,
  CustomCheckBox,
  CustomOptionContainer
} from './styled'
import Select, { components } from 'react-select'

const areEqual = (prevProps, nextProps) => {
  const { getOptions, setValue, ...prevRest } = prevProps
  const {
    getOptions: nextOptions,
    setValue: nextSetValue,
    ...nextRest
  } = nextProps

  return isEqual(prevRest, nextRest)
}

const defaultInitialValue = []

const CheckboxSelector = props => {
  const {
    label,
    exposeKey,
    showButtons,
    disabledOptions,
    values,
    selection,
    getOptions,
    setValue,
    initialValue = defaultInitialValue,
    isPittCourses = false,
    type
  } = props

  const [options, setOptions] = useState(props.options || [])
  const [loading, setLoading] = useState(true)
  const [selectedOptions, setSelectedOptions] = useState([])
  const selectedValues = values[exposeKey] || []
  const isMultiSelect = type && type === 'multiselect'

  useEffect(() => {
    if (!getOptions) return

    fetchOptions()
    if (isMultiSelect && selectedValues.length !== 0) {
      setSelectedOptions(options.filter(
        ({ value }) => values[exposeKey].includes(value))
      )
    }
    // eslint-disable-next-line
  }, [isPittCourses])

  useEffect(() => {
    if (!disabledOptions.length) return

    const isValidOption = value => !isOptionDisabled(value)
    const validValues = selectedValues.filter(isValidOption)

    handleValueChange(validValues.length ? validValues : initialValue)
    // eslint-disable-next-line
  }, [disabledOptions, selectedValues])

  const fetchOptions = async () => {
    const options = isPittCourses
      ? await getOptions(isPittCourses)
      : await getOptions()
    if (!Array.isArray(options)) return
    options
      .filter(option => option.label)
      .sort((current, next) => current.label.localeCompare(next.label))
    setOptions(options)
    selection && (selection.options = options)
    setLoading(false)
  }

  const handleOptionChange = (e, value) => {
    const { target: { checked } } = e

    const newValues = checked
      ? handleCheck(value)
      : handleUncheck(value)

    handleValueChange(newValues.length ? newValues : initialValue)
  }

  const handleUncheck = value => {
    if (!selectedValues.includes(value)) return selectedValues

    return selectedValues.filter(v => v !== value)
  }

  const handleCheck = value => {
    if (selectedValues.includes(value)) return selectedValues

    return [...selectedValues, value]
  }

  const handleSelectAll = () => {
    const filteredOptions = options.filter(({ value }) => !isOptionDisabled(value))
    const allValues = filteredOptions.map(({ value }) => value)

    setSelectedOptions(filteredOptions)
    handleValueChange(allValues)
  }

  const handleDeselectAll = () => {
    setSelectedOptions([])
    handleValueChange(initialValue)
  }

  const handleValueChange = values => {
    setValue({
      [exposeKey]: values
    })
  }

  const areAllFieldsDisabled = () => {
    if (!Array.isArray(options)) return
    return options.every(({ value }) => isOptionDisabled(value))
  }

  const isOptionDisabled = optionValue => disabledOptions.includes(optionValue)

  const shouldShowButtons = showButtons && !areAllFieldsDisabled()

  const handleSelectedOptions = (options) => {
    if (!options) return handleDeselectAll()
    // sort the selected options
    const orderedOptions = options.sort(
      (current, next) => current.label.localeCompare(next.label)
    )
    setSelectedOptions(orderedOptions)
    const allValues = orderedOptions.map(({ value }) => value)
    handleValueChange(allValues)
  }

  // custom option for react-select
  const Option = ({ children, ...props }) => {
    return (
      <components.Option {...props}>
        <CustomOptionContainer>
          <span>{children}</span>
          <CheckedBox type='checkbox' defaultChecked={props.isSelected} />
          <CustomCheckBox className='check-mark' />
        </CustomOptionContainer>
      </components.Option>
    )
  }

  return (
    <StyledWrapper>
      <LabelContainer>
        <label>{label}</label>
        {
          shouldShowButtons && (
            <>
              <SelectAllButton onClick={handleSelectAll}>
                Select All
              </SelectAllButton>
              <span>/</span>
              <DeselectAllButton onClick={handleDeselectAll}>
                Deselect All
              </DeselectAllButton>
            </>
          )
        }
      </LabelContainer>
      {isMultiSelect
        ? <Select
          data-testid='multi-select'
          options={options}
          value={selectedOptions}
          key={exposeKey}
          placeholder={`Select ${label.toLowerCase()}`}
          onChange={handleSelectedOptions}
          components={{ Option }}
          styles={selectInputStyles}
          isOptionDisabled={({ value }) => isOptionDisabled(value)}
          isDisabled={!shouldShowButtons}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isLoading={loading}
          isMulti
        />
        : options.map(({ name, value }) => {
          const isDisabled = isOptionDisabled(value)
          return (
            <CheckBox key={value} disabled={isDisabled}>
              {name}
              <input
                type='checkbox'
                disabled={isDisabled}
                name={'csv-' + exposeKey}
                onChange={e => handleOptionChange(e, value)}
                checked={selectedValues.includes(value)}
              />
              <span />
            </CheckBox>
          )
        })
      }
    </StyledWrapper>
  )
}

CheckboxSelector.defaultProps = {
  showButtons: true,
  disabledOptions: []
}

CheckboxSelector.propTypes = {
  label: PropTypes.string.isRequired,
  exposeKey: PropTypes.string.isRequired,
  values: PropTypes.object,
  disabledOptions: PropTypes.array,
  setValue: PropTypes.func.isRequired,
  getOptions: PropTypes.func,
  showButtons: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType(
      [PropTypes.string, PropTypes.number, PropTypes.bool]
    ).isRequired
  }))
}

CheckboxSelector.displayName = 'CheckboxSelector'

export default React.memo(CheckboxSelector, areEqual)

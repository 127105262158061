import React, { useEffect } from 'react'
import Select from 'react-select'
import {
  useStudentGradeActions
} from '../../contexts/StudentGradeContext'
import { FilterWrapper, FilterLabel, FiltersContainer } from './styled'
import { SubHeading, ResetFilterButton } from '../WritingGradeCenter/styled/index'
import { dropDownStyles } from '../global.styled'

const Filters = ({
  currentCourses,
  setStudents,
  setCurrentCohort,
  currentCohort,
  currentExam,
  setCurrentExam,
  cohortData,
  setCohortData,
  courseExams,
  setCourseExams,
  selectedCourse,
  setSelectedCourse,
  setCurrentStudent
}) => {
  const onCourseChange = (course) => {
    const sortedCohortList = course.cohorts.sort((a, b) =>
      new Date(b.dateStart) - new Date(a.dateStart)).map(cohort =>
      ({ ...cohort, value: cohort.name, label: cohort.name }))
    setSelectedCourse(course)
    setCohortData(sortedCohortList)
    setCourseExams(course.exams)
    setCurrentCohort('')
    setCurrentExam('')
  }
  const {
    fetchGradeReport
  } = useStudentGradeActions()

  const fetchStudentData = async (courseId, cohortName, courseName) => {
    try {
      let students = await fetchGradeReport(
        {
          courseId,
          cohortName
        }
      )
      students = students.map(student => {
        return {
          ...student,
          label: student.name,
          value: student.name
        }
      })
      setStudents(students)
    } catch (error) {
      console.error('Error: ', error)
    }
  }
  useEffect(() => {
    if (!selectedCourse || !currentCohort) return
    setCurrentStudent('')
    setStudents([])
    const { id: courseId } = selectedCourse
    const { name: cohortName } = currentCohort
    const { name: courseName } = selectedCourse
    fetchStudentData(courseId, cohortName, courseName)
    // eslint-disable-next-line
  }, [currentCohort])
  return (
    <>
      <FilterWrapper>
        <FilterLabel isFirstLabel >Course</FilterLabel>
        <Select
          options={currentCourses}
          value={selectedCourse}
          onChange={(course) => onCourseChange(course)}
          data-testid='course-dropdown'
          placeholder='Select Course'
          styles={dropDownStyles}
        />
        <FiltersContainer
          isExamRetakeFilter
          data-testid='reset-filter'
        >
          <SubHeading
            isExamRetakeFilter
          >
            Filters
          </SubHeading>
          <ResetFilterButton
            onClick={() => {
              setCurrentCohort('')
              setCurrentExam('')
            }}>
              reset filters
          </ResetFilterButton>
        </FiltersContainer>
      </FilterWrapper>
      <FiltersContainer>
        <FilterWrapper>
          <FilterLabel>Exam</FilterLabel>
          <Select
            options={courseExams}
            placeholder='Filter By Exam'
            styles={dropDownStyles}
            value={currentExam}
            onChange={(e) => setCurrentExam(e)}
            data-testid='exam-dropdown'
          />
        </FilterWrapper>
        <FilterWrapper>
          <FilterLabel>Cohort</FilterLabel>
          <Select
            options={cohortData}
            value={currentCohort}
            onChange={(e) => setCurrentCohort(e)}
            placeholder='Filter By Cohort'
            styles={dropDownStyles}
            data-testid='cohort-dropdown'
          />
        </FilterWrapper>
      </FiltersContainer>
    </>
  )
}

export default Filters

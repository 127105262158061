import React from 'react'
import {
  StudentCell,
  ExtensionRecordsHeaderWrapper,
  ExtensionsRecordsWrapper,
  ZendeskCell,
  StudentCellLight,
  ExtensionRecordHeaderCell,
  EditLinkCell,
  ToolTipContainer,
  ToolTip,
  RecordRow,
  CohortEnded,
  CohortCell
} from './style'
import ExtensionRecordsTablet from './ExtensionRecordsTablet'
import iconTooltip from '../../assets/icons/icon-tooltip.svg'
import {
  getExtensionRecordData,
  getFilteredRecords
} from './util/ExtensionRecordsUtils'
import {
  dateToSecondsSinceEpoch,
  secondsSinceEpoch
} from '../../utilities/dateTimeUtil'
import useWindowSize from '../../Hooks/useWindowSize'
import { BREAKPOINTS } from '../../mediaQueries'

const ExtensionRecords = ({
  studentExtensionsRecords,
  cohortFilter,
  courseFilter,
  goToEditExtension
}) => {
  const [windowWidth] = useWindowSize()
  const isTablet = windowWidth < BREAKPOINTS.tablet

  const filteredRecords = getFilteredRecords({
    cohortFilter,
    courseFilter,
    studentExtensionsRecords
  })

  const hasCohortEnded = (date) => {
    date = new Date(date)
    const convertedDate = dateToSecondsSinceEpoch(date)
    return convertedDate < secondsSinceEpoch()
  }

  const handleEditExtension = (recordRow) => {
    goToEditExtension(recordRow)
  }

  return (
    <ExtensionsRecordsWrapper>
      <ExtensionRecordsHeaderWrapper>
        <ExtensionRecordHeaderCell>Cohort</ExtensionRecordHeaderCell>
        <ExtensionRecordHeaderCell>Assessment</ExtensionRecordHeaderCell>
        <ExtensionRecordHeaderCell>
          Type
          <ToolTipContainer>
            <img
              src={iconTooltip}
              alt='icon-tooltip'
            />
            <ToolTip>
              Automatic extensions are from the extension request form.
              Student Success creates manual extensions.
            </ToolTip>
          </ToolTipContainer>
        </ExtensionRecordHeaderCell>
        <ExtensionRecordHeaderCell>Ticket</ExtensionRecordHeaderCell>
        <ExtensionRecordHeaderCell>Original deadline</ExtensionRecordHeaderCell>
        <ExtensionRecordHeaderCell>New deadline</ExtensionRecordHeaderCell>
        <ExtensionRecordHeaderCell />
      </ExtensionRecordsHeaderWrapper>
      {filteredRecords.map((recordRow, index) => {
        let showCohortName = true
        let differentCohortThanNext = false
        let showDivider = false
        const {
          cohortName,
          zendeskUrl,
          finalDeadline,
          finalDeadlineExt,
          finalExamEndTime,
          endTime
        } = recordRow
        const [
          assessment,
          type,
          displayedOriginalDeadline,
          displayedExtendedDeadline
        ] = getExtensionRecordData(recordRow)

        const studentExtensionsRecordsLength = filteredRecords.length
        let isRowDisabled = false

        if (index > 0) {
          showCohortName =
            cohortName !== filteredRecords[index - 1].cohortName
          showDivider = showCohortName
        }
        if (index < studentExtensionsRecordsLength - 1) {
          differentCohortThanNext =
            cohortName !== filteredRecords[index + 1].cohortName
        }

        if (
          studentExtensionsRecordsLength > 1 &&
          index > 0 &&
          index <= studentExtensionsRecordsLength - 1
        ) {
          const previousAssessment = getExtensionRecordData(filteredRecords[index - 1])
          const previousAssessmentName = previousAssessment[0]
          const previousAssessmentExtendedDeadline = previousAssessment[3]
          const sameCohortAsPrevious = cohortName === filteredRecords[index - 1].cohortName
          isRowDisabled =
            sameCohortAsPrevious &&
            assessment === previousAssessmentName &&
            displayedExtendedDeadline < previousAssessmentExtendedDeadline
        }

        const cohortEndTime = endTime ||
        finalDeadline || finalDeadlineExt || finalExamEndTime
        const isCohortEnded = hasCohortEnded(cohortEndTime)
        return (
          <RecordRow
            isRowDisabled={isRowDisabled}
            key={index}
            showDivider={showDivider || isTablet}
            differentCohortThanNext={differentCohortThanNext}
            data-testid='extension-record-row'
          >
            {isTablet ? (
              <ExtensionRecordsTablet
                recordToDisplay={{
                  cohortName,
                  assessment,
                  type,
                  displayedOriginalDeadline,
                  displayedExtendedDeadline
                }}
                recordRow={recordRow}
                isCohortEnded={isCohortEnded}
                handleEditExtension={handleEditExtension}
              />
            ) : (
              <>
                <CohortCell>
                  {showCohortName && cohortName}
                  {isCohortEnded && showCohortName && (
                    <CohortEnded>Cohort Ended</CohortEnded>
                  )}
                </CohortCell>
                <StudentCell>{assessment}</StudentCell>
                <StudentCellLight>{type}</StudentCellLight>
                <ZendeskCell className='zendesk-cell'>
                  {zendeskUrl && (
                    <a
                      href={zendeskUrl}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Zendesk ticket
                    </a>
                  )}
                </ZendeskCell>
                <StudentCellLight style={{ whiteSpace: 'pre' }}>
                  {displayedOriginalDeadline}
                </StudentCellLight>
                <StudentCell style={{ whiteSpace: 'pre' }}>
                  {displayedExtendedDeadline}
                </StudentCell>
                <EditLinkCell onClick={() => handleEditExtension(recordRow)}>
                  Edit
                </EditLinkCell>
              </>
            )}
          </RecordRow>
        )
      })}
    </ExtensionsRecordsWrapper>
  )
}

ExtensionRecords.displayName = 'ExtensionRecords'
export default ExtensionRecords

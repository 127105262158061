import React, { useEffect, useState } from 'react'
import { ClearIcon } from '../style'
import { dropDownStyles } from '../../global.styled'
import clearIcon from '../../../assets/icons/clear-icon.svg'
import unselectedIcon from '../../../assets/icons/unselected.svg'
import selectedIcon from '../../../assets/icons/selected.svg'
import {
  ButtonsContainer,
  Container,
  CustomButton,
  CustomButtonSecondary,
  CustomInput,
  CustomTextArea,
  DropDownStyles,
  Error,
  InputLabel,
  InputWrapper,
  AssessmentsContainer,
  RadioContainer,
  RowContainer,
  StyledRadioButton
} from './style'
import Select from 'react-select'
import { useDebouncedEffect } from '../../../Hooks/useDebounce'
import api from '../../../api'
import {
  getActiveCourses,
  getAttemptId,
  getSortedAssessments,
  getSortedCourses,
  getUnsubmittedAssessments
} from './AddExtensionUtils'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import MultipleAssessments from './MultipleAssessments'
import SingleAssessment from './SingleAssessment'
import {
  getAssessmentNameAndDeadlineKeys
} from '../util/ExtensionRecordsUtils'
import { getStudentProgress } from '../../../utilities/gradeReportUtils'
import { DropdownIndicator } from '../../CustomSelect/CustomSelectComponents'

const StudentExtensionDetails = ({
  isOneAssessmentModeReviewData,
  extensionReviewData,
  extensionToEdit,
  isEditMode,
  handleNextStep,
  goBack
}) => {
  const [studentFilter, setStudentFilter] = useState('')
  const [studentData, setStudentData] = useState(null)
  const [studentCourses, setStudentCourses] = useState([])
  const [studentCohorts, setStudentCohorts] = useState([])
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedCohort, setSelectedCohort] = useState(null)
  const [selectedAssessment, setSelectedAssessment] = useState(null)
  const [assessmentsList, setAssessmentsList] = useState([])
  const [isOneAssessmentMode, setIsOneAssessmentMode] = useState(null)
  const [showStudentNotFound, setShowStudentNotFound] = useState(false)
  const [isLoadingCourses, setIsLoadingCourses] = useState(false)
  const [isLoadingAssessments, setIsLoadingAssessments] = useState(false)
  const [newDeadlineDate, setNewDeadlineDate] = useState(null)
  const [selectedNumber, setSelectedNumber] = useState(null)
  const [selectedUnitLength, setSelectedUnitLength] = useState(null)
  const [zendeskLink, setZendeskLink] = useState('')
  const [extensionReason, setExtensionReason] = useState('')

  useEffect(() => {
    if (extensionToEdit) {
      const {
        studentId,
        cohortId,
        cohortName,
        zendeskUrl,
        extensionReason,
        finalExamEndTime,
        milestoneName
      } = extensionToEdit
      if (!selectedCohort) {
        const fetchSingleCohort = async (cohortId) => {
          const cohort = await api.getCohortById(cohortId)
          const { dateStart } = cohort
          setSelectedCohort({ label: cohort.name, value: cohort.name, dateStart, cohortEndTime: finalExamEndTime })
        }
        fetchSingleCohort(cohortId)
      }
      setStudentFilter(studentId)
      const courseName = cohortName.split('-')[0]
      setSelectedCourse({ label: courseName, value: courseName, name: courseName })
      setIsOneAssessmentMode(true)
      const [
        assessment,
        ,
        extendedDeadlineKey
      ] = getAssessmentNameAndDeadlineKeys(extensionToEdit)
      const assessmentData = {
        label: assessment,
        value: assessment,
        examTitle: assessment,
        assessmentName: milestoneName,
        lockTime: extensionToEdit[extendedDeadlineKey] ||
          extensionToEdit[extendedDeadlineKey + 'Ext']
      }
      setAssessmentsList([assessmentData])
      setZendeskLink(zendeskUrl)
      if (extensionReason) { setExtensionReason(extensionReason) }
    }
    // eslint-disable-next-line
  }, [extensionToEdit])

  useEffect(() => {
    if (extensionReviewData) {
      const {
        studentFilter,
        studentData,
        selectedCourse,
        selectedCohort,
        assessmentsList,
        selectedAssessment,
        newDeadlineDate,
        selectedNumber,
        selectedUnitLength,
        zendeskLink,
        extensionReason
      } = extensionReviewData
      setStudentFilter(studentFilter)
      setStudentData(studentData)
      setSelectedCourse(selectedCourse)
      setSelectedCohort(selectedCohort)
      setAssessmentsList(assessmentsList)
      setSelectedAssessment(selectedAssessment)
      setSelectedNumber(selectedNumber)
      setSelectedUnitLength(selectedUnitLength)
      setNewDeadlineDate(newDeadlineDate)
      setZendeskLink(zendeskLink)
      setExtensionReason(extensionReason)
      setIsOneAssessmentMode(isOneAssessmentModeReviewData)
    }
    // eslint-disable-next-line
  }, [])
  useDebouncedEffect(
    () =>
      fetchStudentDetails(),
    [studentFilter],
    1000)

  useEffect(() => {
    if (!studentData || extensionToEdit) return
    const fetchStudentCourses = async () => {
      setIsLoadingCourses(true)
      const { courses } =
        await api.getStudentCourses({ studentEmail: studentData.email })
      const activeCourses = getActiveCourses(courses)
      const sortedCourses = getSortedCourses(activeCourses)
      setStudentCourses(sortedCourses)
      if (sortedCourses.length === 1) {
        const course = sortedCourses[0]
        handleCourseChange(course)
      }
      setIsLoadingCourses(false)
    }
    fetchStudentCourses()
    // eslint-disable-next-line
  }, [studentData, extensionToEdit])
  const fetchStudentDetails = async () => {
    if (studentFilter === '') return
    const studentData = await api.getStudentDataByEmailOrId(studentFilter)
    const studentNotFound = Object.keys(studentData).length === 0
    if (studentNotFound) {
      return setShowStudentNotFound(true)
    }
    setShowStudentNotFound(false)
    setStudentData(studentData)
  }

  const fetchStudentCourseAssessments = async (course, studentProgress) => {
    setIsLoadingAssessments(true)
    const unSubmittedAssessments = await getUnsubmittedAssessments(course, studentProgress)
    const sortedAssessments = getSortedAssessments(unSubmittedAssessments)

    setAssessmentsList(sortedAssessments)
    setIsLoadingAssessments(false)
  }

  const handleCourseChange = async (course) => {
    setSelectedCourse(course)
    const activeCohorts = [
      {
        ...course.cohort,
        label: course.cohort.name,
        value: course.cohort.id
      }
    ]
    setStudentCohorts(activeCohorts)
    setSelectedCohort(activeCohorts[0])
    setIsOneAssessmentMode(null)
    setSelectedAssessment(null)
    const studentProgress = await
    getStudentProgress({
      courseId: course.id,
      cohortId: course.cohort.id,
      isCurrentCohort: true,
      studentEmail: studentData.email
    })
    await fetchStudentCourseAssessments(course, studentProgress)
  }
  const handleAssessmentChange = (assessment) => {
    setSelectedAssessment(assessment)
    if (!assessment) return
    const newDeadline = assessment.lockTime
      ? new Date(assessment.lockTime)
      : new Date()

    setNewDeadlineDate(newDeadline)
  }
  const clearSelections = () => {
    setShowStudentNotFound(false)
    setStudentData(null)
    setSelectedCourse(null)
    setSelectedCohort(null)
    setIsOneAssessmentMode(null)
  }

  const handleReviewEditData = () => {
    const { extensionId } = extensionToEdit
    const extensionData = {
      studentFilter,
      studentData,
      selectedCourse,
      selectedCohort,
      selectedAssessment,
      newDeadlineDate,
      zendeskLink,
      extensionReason,
      extensionId
    }
    handleNextStep(isOneAssessmentMode, extensionData)
  }
  const handleReviewData = () => {
    const attemptID = getAttemptId(selectedCohort, selectedCourse)
    const extensionData = {
      studentFilter,
      studentData,
      selectedCourse,
      selectedCohort,
      assessmentsList,
      selectedAssessment,
      newDeadlineDate,
      selectedNumber,
      selectedUnitLength,
      zendeskLink,
      extensionReason,
      attemptID
    }
    handleNextStep(isOneAssessmentMode, extensionData)
  }
  const isStudentClearVisible = studentFilter && studentFilter !== ''
  const isAssessmentDataFilled = isOneAssessmentMode
    ? selectedAssessment && newDeadlineDate
    : selectedNumber && selectedUnitLength && assessmentsList.length > 0
  const isNextButtonDisabled = !studentData ||
    !selectedCourse ||
    !selectedCohort ||
    isOneAssessmentMode === null ||
    !isAssessmentDataFilled ||
    zendeskLink === ''
  return (
    <Container data-testid='student-extension-details'>
      <RowContainer>
        <InputLabel>Find a student</InputLabel>
        {showStudentNotFound &&
          <Error>Student not found in our database.</Error>
        }
        <InputWrapper data-testid='input-wrapper'>
          <CustomInput
            disabled={isEditMode}
            placeholder='Enter email or ID'
            value={studentFilter}
            onChange={(e) => {
              clearSelections()
              setStudentFilter(e.target.value)
            }}
            styles={dropDownStyles}
          />
          {isStudentClearVisible && <ClearIcon
            src={clearIcon}
            alt=''
            onClick={() => {
              clearSelections()
              setStudentFilter('')
            }}
          />}
        </InputWrapper>
      </RowContainer>
      {studentData &&
        <RowContainer style={{ gap: '0' }}>
          <span>{studentData?.name}</span>
          <span>ID: {studentData?.id}</span>
          <span>Email: {studentData?.email}</span>
        </RowContainer>
      }
      <RowContainer>
        <InputLabel>Course</InputLabel>
        <Select
          isLoading={isLoadingCourses}
          components={{ DropdownIndicator }}
          options={studentCourses}
          value={selectedCourse}
          placeholder='Select course'
          onChange={course => {
            handleCourseChange(course)
          }}
          styles={{ ...DropDownStyles }}
          isDisabled={!studentData || isLoadingCourses}
        />
      </RowContainer>
      <RowContainer>
        <InputLabel>Cohort</InputLabel>
        <Select
          components={{ DropdownIndicator }}
          options={studentCohorts}
          value={selectedCohort}
          placeholder='Select cohort'
          onChange={cohort => {
            setSelectedCohort(cohort)
          }}
          styles={{ ...DropDownStyles }}
          isDisabled={!selectedCourse}
        />
      </RowContainer>
      {!extensionToEdit && (<RowContainer>
        <h5>Would you like to extend one or all assessments?</h5>
        {isLoadingAssessments && <LoadingSpinner />}
        <RadioContainer isDisabled={!selectedCohort || isLoadingAssessments}>
          <StyledRadioButton>
            <img src={isOneAssessmentMode === true ? selectedIcon : unselectedIcon} alt='' />
            <span>One</span>
            <input
              type='radio'
              checked={!!isOneAssessmentMode}
              onChange={() => {
                setIsOneAssessmentMode(true)
              }}
              disabled={!selectedCohort}
            />
          </StyledRadioButton>
          <StyledRadioButton>
            <img src={isOneAssessmentMode === false ? selectedIcon : unselectedIcon} alt='' />
            <span>All assessments</span>
            <input
              type='radio'
              checked={isOneAssessmentMode === false}
              onChange={() => {
                setIsOneAssessmentMode(false)
              }}
              disabled={!selectedCohort}
            />
          </StyledRadioButton>
        </RadioContainer>
      </RowContainer>)}
      {isOneAssessmentMode !== null &&
        <AssessmentsContainer>
          {isOneAssessmentMode
            ? <SingleAssessment
              assessmentsList={assessmentsList}
              selectedAssessment={selectedAssessment}
              handleAssessmentChange={handleAssessmentChange}
              setNewDeadline={setNewDeadlineDate}
              newDeadline={newDeadlineDate}
              isEditMode={isEditMode}
            />
            : <MultipleAssessments
              assessmentsList={assessmentsList}
              selectedNumber={selectedNumber}
              setSelectedNumber={setSelectedNumber}
              selectedUnitLength={selectedUnitLength}
              setSelectedUnitLength={setSelectedUnitLength}
            />
          }
          <RowContainer>
            <InputLabel>Zendesk ticket</InputLabel>
            <CustomInput
              placeholder='Paste link to ticket'
              value={zendeskLink}
              onChange={(e) => {
                setZendeskLink(e.target.value)
              }}
            />
          </RowContainer>
          <RowContainer>
            <InputLabel>Extension reason (optional)</InputLabel>
            <CustomTextArea
              placeholder='Have any internal notes?'
              value={extensionReason}
              onChange={(e) => {
                setExtensionReason(e.target.value)
              }}
            />
          </RowContainer>
        </AssessmentsContainer>
      }
      <ButtonsContainer>
        <CustomButton
          className='btn-custom btn-primary'
          disabled={isNextButtonDisabled}
          onClick={() => {
            !isEditMode ? handleReviewData() : handleReviewEditData()
          }}
        >
          Next
        </CustomButton>
        <CustomButtonSecondary
          className='btn-custom'
          onClick={() => {
            goBack()
          }}
        >
          Cancel
        </CustomButtonSecondary>
      </ButtonsContainer>
    </Container>
  )
}

StudentExtensionDetails.displayName = 'StudentExtensionDetails'
export default StudentExtensionDetails

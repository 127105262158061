import React from 'react'
import { components } from 'react-select'
import chevronIcon from '../../assets/icons/chevron-down.svg'
import clearIcon from '../../assets/icons/clear-icon.svg'
import { GroupSelectStyle } from './style'

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <img src={clearIcon} alt='clear-icon' />
    </components.ClearIndicator>
  )
}

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={chevronIcon} alt='chevron-icon' />
    </components.DropdownIndicator>
  )
}

export const Group = (props) => (
  <div style={GroupSelectStyle}>
    <components.Group {...props} />
  </div>
)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import {
  STUDENT_GRADES_PRESET_SELECTION
} from '../../../Constants/CSVDownloadToolOptions'
import DownloadButton from '../DownloadButton'
import {
  areMainFieldsSelected,
  changeDatesToISOString,
  formatMultiSelectValue,
  getCSVFileName,
  getZIPFileName,
  saveTextFile
} from '../../../utilities/CSVDownloadToolV2'
import { additionalCourseIdToName } from '../../../config'
import api from '../../../api'
import { CWI_DATE_OVERLAP_VALUE } from '../../../Constants'
import { dateToISOString } from '../../../utilities/dateTimeUtil'
import { COLLEGE_WRITING_I_GGU_V1_ID } from '../../../Constants/courses'

const PresetDownloadButton = props => {
  const [isLoading, setIsLoading] = useState(false)

  const {
    criteria,
    criteria: {
      courses,
      termLengths,
      cohortStartRange,
      cohortEndRange,
      type,
      creditGrantingInstitutions,
      relationships,
      selectedPreset
    }
  } = props

  const shouldEnableButton = () => {
    const mainFieldsSelected = areMainFieldsSelected(criteria)

    return mainFieldsSelected && selectedPreset && !isLoading
  }

  const getCSVData = async courses => {
    const startRangeISOString = changeDatesToISOString(cohortStartRange) || []
    const endRangeISOString = changeDatesToISOString(cohortEndRange) || []

    const body = {
      courses,
      termLengths,
      format: 'csv',
      relationships,
      creditGrantingInstitutions: formatMultiSelectValue(creditGrantingInstitutions),
      ...(startRangeISOString.length && { cohortStartRange: startRangeISOString }),
      ...(endRangeISOString.length && { cohortEndRange: endRangeISOString }),
      type
    }

    const csvData = await api.getFilteredStudentsV2(body)
    return csvData
  }

  const getAdditionalCourse = () => {
    return courses.map(course => {
      const isCWIGGUCourse = course === COLLEGE_WRITING_I_GGU_V1_ID
      if (!isCWIGGUCourse) return course

      const startRangeISOString = changeDatesToISOString(cohortStartRange) || []
      const CWIV2StartDate = dateToISOString(new Date(CWI_DATE_OVERLAP_VALUE))

      const isCWIV2StartDateBeforeStartRange = CWIV2StartDate >= startRangeISOString[0]
      const isCWIV2StartDateAfterStartRange = CWIV2StartDate <= startRangeISOString[1]
      const isCWIV2StartDateInStartRange = isCWIV2StartDateBeforeStartRange && isCWIV2StartDateAfterStartRange
      const isCWIV2StartAfterStartRange = CWIV2StartDate <= startRangeISOString[0]

      const shouldAddV2Course = isCWIV2StartAfterStartRange || isCWIV2StartDateInStartRange
      const shouldAddV1Course = startRangeISOString[0] < CWIV2StartDate

      const additionalCourses = []

      if (shouldAddV2Course) {
        additionalCourses.push(additionalCourseIdToName(course)?.[1])
      }

      if (shouldAddV1Course) {
        additionalCourses.push(course)
      }

      return additionalCourses
    }).flat()
  }

  const handleGenerateClick = async () => {
    setIsLoading(true)

    const { options } = STUDENT_GRADES_PRESET_SELECTION
    const isCourseGradePreset = options.some(({ value }) => value === type)

    // Use additional course if CW I GGU V1 is selected and the start date is after the CW V2 start date
    const additionalCourses = getAdditionalCourse()
    const shouldDownloadZIP = isCourseGradePreset && additionalCourses.length > 1

    if (shouldDownloadZIP) {
      await downloadZIP(additionalCourses)
      setIsLoading(false)
      return
    }

    await downloadCSV(additionalCourses)
    setIsLoading(false)
  }

  const downloadCSV = async (courses) => {
    const data = await getCSVData(courses)
    const csvName = getCSVFileName(cohortStartRange)

    saveTextFile(data, csvName)
  }

  const downloadZIP = async (courses) => {
    const courseData = {}

    await Promise.all(courses.map(async (course) => {
      const csvData = await getCSVData([course])
      const fileName = `${course}_${getCSVFileName(cohortStartRange)}`

      courseData[fileName] = csvData
    }))

    const zip = new JSZip()
    for (const [fileName, csvData] of Object.entries(courseData)) {
      zip.file(fileName, csvData)
    }

    const zipContent = await zip.generateAsync({ type: 'blob' })
    saveAs(zipContent, getZIPFileName(cohortStartRange))
  }

  const disableButton = !shouldEnableButton()

  return (
    <DownloadButton
      disableButton={disableButton}
      handleClick={handleGenerateClick}
      isLoading={isLoading}
    />
  )
}

PresetDownloadButton.propTypes = {
  criteria: PropTypes.object
}

PresetDownloadButton.displayName = 'PresetDownloadButton'

export default PresetDownloadButton

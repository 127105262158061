import { css } from 'styled-components'

// These are the max limit of the BREAKPOINTS i.e. mobile: 576 - 767
export const BREAKPOINTS = {
  foldable: 320,
  smallMobile: 575,
  mobile: 767,
  tablet: 991,
  desktop: 1199
}

// iterate through the sizes and create a media template
const mediaqueries = Object.keys(BREAKPOINTS).reduce((accumulator, label) => {
  const size = BREAKPOINTS[label]
  accumulator[label] = (...args) => css`
    @media (max-width: ${size}px) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export default mediaqueries

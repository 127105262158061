import React from 'react'
import {
  Dot
} from '../styled'
import {
  Container,
  Text
} from './styled'

const ActionLink = ({ links }) => {
  return (
    <Container>
      {
        links.map((link, index) => {
          const { title } = link
          return (
            <React.Fragment key={`${title}-${index}`}>
              <Dot />
              <Text>{ title }</Text>
            </React.Fragment>
          )
        })
      }
    </Container>
  )
}

export default ActionLink

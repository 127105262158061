import React, { useEffect, useState } from 'react'
import {
  FilterHeader,
  FilterTitle,
  FilterReset,
  FilterContainer,
  FilterLabel,
  FilterSelect,
  FilterWrapper,
  StatusSelect
} from './styles/index.styled'
import PropTypes from 'prop-types'
import { TOKEN_USED_STATUSES } from '../constants'

const LicenseDashboardFilter = (
  {
    relationships,
    setFilteredRelationships
  }
) => {
  const [selectedSchool, setSelectedSchool] = useState(null)
  const [selectedDistrict, setSelectedDistrict] = useState(null)
  const [selectedUsedStatuses, setSelectedUsedStatuses] = useState([])

  const schoolOptions = relationships.map(({ school }) =>
    ({ value: school, label: school }))

  const districtOptions =
  [...new Set(relationships.map(({ district }) => district))]
    .filter(Boolean)
    .sort((a, b) => a.localeCompare(b))
    .map(district => ({ value: district, label: district }))

  const handleUsedStatusChange = event => {
    if (selectedUsedStatuses.includes(event)) {
      const selectedStatuses = selectedUsedStatuses.filter(status => status !== event)
      return setSelectedUsedStatuses(selectedStatuses)
    }
    const selectedStatuses = [...selectedUsedStatuses, event]
    setSelectedUsedStatuses(selectedStatuses)
  }

  useEffect(() => {
    if (!relationships.length) return
    if (!selectedSchool && !selectedDistrict && !selectedUsedStatuses.length) { return setFilteredRelationships(relationships) }

    const filteredRelationships = relationships.filter(relationship =>
      (!selectedSchool || relationship.school === selectedSchool.value) &&
    (!selectedDistrict || relationship.district === selectedDistrict.value))

    const usedStatusFilteredRelationships = filteredRelationships.filter(relationship => {
      if (selectedUsedStatuses.length === 0) return true
      if (relationship.licenses.length === 0) return false
      return selectedUsedStatuses.some(status =>
        relationship.licenses.some(license => license.usedStatus === status.value))
    })

    setFilteredRelationships(usedStatusFilteredRelationships)
    // eslint-disable-next-line
	}, [selectedSchool, selectedDistrict, selectedUsedStatuses])

  return (
    <>
      <FilterHeader>
        <FilterTitle>
          Filters
        </FilterTitle>
        <FilterReset
          disabled={!selectedSchool && !selectedDistrict && !selectedUsedStatuses.length}
          onClick={() => {
            setSelectedSchool(null)
            setSelectedDistrict(null)
            setSelectedUsedStatuses([])
          }}
        >
          reset
        </FilterReset>
      </FilterHeader>
      <FilterContainer data-testid='license-dashboard-filter'>
        <FilterWrapper>
          <FilterLabel>Relationship</FilterLabel>
          <FilterSelect
            options={schoolOptions}
            placeholder='Select a school'
            onChange={setSelectedSchool}
            value={selectedSchool}
          />
        </FilterWrapper>
        <FilterWrapper>
          <FilterLabel>District</FilterLabel>
          <FilterSelect
            options={districtOptions}
            placeholder='Select a district'
            onChange={setSelectedDistrict}
            value={selectedDistrict}
          />
        </FilterWrapper>
        <FilterWrapper>
          <FilterLabel>License used status</FilterLabel>
          <StatusSelect
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            value={selectedUsedStatuses}
            options={TOKEN_USED_STATUSES}
            onChange={handleUsedStatusChange}
            placeholder={'Select statuses'}
          />
        </FilterWrapper>
      </FilterContainer>
    </>
  )
}

LicenseDashboardFilter.propTypes = {
  relationships: PropTypes.array,
  selectedSchool: PropTypes.object,
  selectedDistrict: PropTypes.object,
  setSelectedSchool: PropTypes.func,
  setSelectedDistrict: PropTypes.func
}

LicenseDashboardFilter.displayName = 'LicenseDashboardFilter'
export default LicenseDashboardFilter

import styled from 'styled-components'
import {
  ReactComponent as Download
} from '../../../assets/icons/download.svg'
import Button from '../../Button/Button'

export const CodingGradeWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`

export const NameContainer = styled.div`
  font-size: 18px;
  line-height: 22px;
  color: #89969F;
  margin-bottom: 10px;
`

export const CohortContainer = styled.div`
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 14px;
`

export const AssignmentContainer = styled.div`
  font-size: 22px;
  line-height: 26px;
  margin-bottom: 24px;
`

export const DownloadContainer = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--brand-regular);
  margin-bottom: 48px;
  cursor: pointer;
`

export const DownloadIcon = styled(Download)`
  margin-right: 8px;
  margin-top: -8px;
  fill: var(--brand-regular);
  width: 20px;
  height: 20px;
`

export const GradesContainer = styled.div`
  margin: 0 32px 48px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const GradeSuite = styled.div`
  background: #1D1D1F;
  border-radius: 5px;
`

export const GradeStep = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  padding: 8px 16px;
`

export const GradeHeader = styled(GradeStep)`
  background: #232327;
  border-radius: 5px 5px 0 0;
  font-weight: 400;
`

export const ModifyButton = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--brand-regular);
  cursor: pointer;
`

export const OriginalScoreContainer = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
`

export const RevisedScoreContainer = styled.div`
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 48px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
`

export const CustomButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px !important;
  padding: 16px 24px;
  text-transform: uppercase;
  font-family: 'Lato';
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1px;
  text-align: center;
  outline: none;
  white-space: nowrap;

  &:focus {
    box-shadow: none;
  }
`

export const PrimaryButton = styled(CustomButton)`
  background: #5fc4b8;
  border: none;


  &:focus {
    border: none;
    outline:none
  }

  &:active {
    background: #25272b;
    color: #5fc4b8;
  }
`

export const SecondaryButton = styled(CustomButton)`
  border: 1px solid #5fc4b8;
  box-sizing: border-box;
  background: #161618;
  color: #5fc4b8;

  &:active {
    background-color: #000000;
    color: #ffffff;
    outline: none;
    border: 1px solid #8888;
  }

  &:focus {
    box-shadow: none !important;
    outline: none;
    border: 1px solid #8888;
  }
`

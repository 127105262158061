import React, { useMemo, useState } from 'react'
import filterIcon from '../../assets/icons/arrow-top-bottom.svg'
import { getDateString } from '../../utilities/dateTimeUtil'
import HistoryModal from './HistoryModal'
import Pagination from './Pagination'
import {
  Separator,
  HeaderItem,
  GridWrapper,
  TableItem,
  TableItemWrapper
} from './styled'
import { SortIcon } from '../global.styled'

const HistoryTable = ({ data, sortBy }) => {
  const COLUMN_HEADER = [
    { key: 'Voucher #', value: 'voucherNumber' },
    { key: 'Request date', value: 'requestReceived' },
    { key: 'Student email', value: 'studentEmail' },
    { key: 'Decision', value: 'decision' },
    { key: 'Decision date', value: 'decisionDate' },
    { key: 'Refund', value: 'refundAmount' }
  ]
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState(null)

  const handleShowModal = (item) => {
    setModalContent(item)
    setShowModal((prevState) => !prevState)
  }

  const PageSize = 10
  const [currentPage, setCurrentPage] = useState(1)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize
    const lastPageIndex = firstPageIndex + PageSize
    return data.slice(firstPageIndex, lastPageIndex)
  }, [PageSize, currentPage, data])

  return (
    <div>
      <Separator className='mt-unset' />
      <GridWrapper>
        {COLUMN_HEADER.map((item, index) => (
          <HeaderItem key={index}>
            {item.key}
            <SortIcon onClick={() => sortBy(item.value)} src={filterIcon} />
          </HeaderItem>
        ))}
      </GridWrapper>
      <Separator className='mb-unset' />
      {currentTableData.map((item, index) => {
        const {
          voucherNumber,
          studentEmail,
          requestReceived,
          refundAmount,
          decisionId,
          decision,
          decisionDate
        } = item
        const requestDate = getDateString(new Date(requestReceived), 'MM/DD/YY')
        const decisionDateToDateString = getDateString(
          new Date(decisionDate),
          'MM/DD/YY'
        )
        return (
          <TableItemWrapper
            className={
              index === currentTableData.length - 1 ? 'border-bottom-none' : ''
            }
            key={`${decisionId} - ${index}`}
          >
            <TableItem onClick={() => handleShowModal(item)}>
              {voucherNumber}
            </TableItem>
            <TableItem date-testid='request-date'>{requestDate}</TableItem>
            <TableItem>{studentEmail}</TableItem>
            <TableItem date-testid='decision'>{decision}</TableItem>
            <TableItem>{decisionDateToDateString}</TableItem>
            <TableItem date-testid='refund-amount'>
              {refundAmount[0] === '$' ? refundAmount : `$${refundAmount}`}
            </TableItem>
          </TableItemWrapper>
        )
      })}
      {showModal && (
        <HistoryModal
          request={modalContent}
          show={showModal}
          setShowHistoryModal={handleShowModal}
        />
      )}
      <Separator className='mt-unset' />
      <Pagination
        currentPage={currentPage}
        totalCount={data.length}
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </div>
  )
}

export default HistoryTable

import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 16px;
  cursor: pointer;
`

export const Dot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: linear-gradient(0deg, #5FC4B8, #5FC4B8), #5FC4B8;
`

export const csvHeaders = ['Email', 'Full Name']

export const sampleCSV = [
  csvHeaders,
  ['facilitator1@sampletestschool.org', 'John Smith']
]

export const CREDIT_GRANTING_INSTITUTIONS = [
  {
    label: 'University of Pittsburgh',
    value: 'University of Pittsburgh'
  },
  {
    label: 'Golden Gate University',
    value: 'Golden Gate University'
  }
]

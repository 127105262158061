import React, { useEffect, useMemo, useState } from 'react'
import {
  DropDownWrapper,
  FormLabel,
  FormRow,
  FormWrapper,
  SelectLabel
} from './index.styled'
import {
  FormInput, SoftWarningMessage
} from '../../Components/EnrollStudentsTool/style'
import CustomSelect from '../../Components/CustomSelect/UpdatedSelect'
import {
  DropdownIndicator
} from '../../Components/CustomSelect/CustomSelectComponents'
import api from '../../api'
import { getActiveExams } from '../../utilities/examUtils'
import { ReactComponent as WarningIcon } from '../../assets/icons/exclamation-circle.svg'

const ExamSelector = ({
  locked,
  selectedCohort,
  selectedCourse,
  setSelectedExam,
  selectedExam
}) => {
  const [courseData, setCourseData] = useState(null)
  const [cohortData, setCohortData] = useState(null)
  const [isExamLoading, setIsExamLoading] = useState(false)

  useEffect(() => {
    if (!selectedCourse) return
    const fetchCourseData = async () => {
      const courseData = await api.getCourseData(selectedCourse?.id)
      setCourseData(courseData)
    }
    fetchCourseData()
  }, [selectedCourse])

  useEffect(() => {
    if (!selectedCohort) return
    setIsExamLoading(true)
    const fetchCohortData = async () => {
      const cohortData = await api.getCohortById(selectedCohort?.at_id)
      setCohortData(cohortData)
      setIsExamLoading(false)
    }
    fetchCohortData()
  }, [selectedCohort])

  const filteredExams = useMemo(() => {
    if (!selectedCohort || !courseData) return []
    const activeExams = getActiveExams(courseData, cohortData)
      .filter(exam => exam.hasKeyOverride === locked)
    if (activeExams.length === 1) setSelectedExam(activeExams[0])
    if (activeExams.length === 0) setSelectedExam(null)
    return activeExams
    // eslint-disable-next-line
  }, [courseData, cohortData, locked, selectedCohort])

  const shouldShowWarning = !isExamLoading && selectedCohort && filteredExams.length === 0
  return (
    <>
      <FormLabel mb='12px'>Which exam would you like to update?</FormLabel>
      {shouldShowWarning && (
        <SoftWarningMessage>
          <WarningIcon />
          <p>This cohort does not have any exams to {locked ? 'lock' : 'unlock'}.</p>
        </SoftWarningMessage>
      )}
      <FormWrapper gap='24px'>
        <FormRow>
          <DropDownWrapper>
            <SelectLabel htmlFor='courses'>Exam</SelectLabel>
            {!selectedCohort ? (
              <FormInput
                data-testid='disabled-exam-dropdown'
                disabled
                style={{ width: '250px', marginBottom: '0' }}
              />
            ) : (
              <CustomSelect
                isClearable
                isLoading={isExamLoading}
                id='courses'
                options={filteredExams}
                name='exams'
                data-testid='exam-dropdown'
                placeholder='Select an exam'
                width={'250px'}
                components={{ DropdownIndicator }}
                onChange={(exam) => setSelectedExam(exam)}
                value={selectedExam}
              />
            )}
          </DropDownWrapper>
        </FormRow>
      </FormWrapper>
    </>
  )
}

ExamSelector.displayName = 'ExamSelector'
export default ExamSelector

import Papa from 'papaparse'

export function readCSVFile (file) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: results => resolve(results.data),
      error: err => reject(err)
    })
  })
}

import React, { useEffect, useState } from 'react'
import {
  TableHeader,
  NoDataAvailable,
  StudentDeadlineHeader,
  TableRow,
  Deadline,
  CohortName,
  GridButton,
  IconsContainer
} from '../WritingGradeCenter/styled'
import {
  ExamSeparator,
  ExamViewAllWrapper,
  SubPageHeading,
  TableHeaderText,
  TableMainWrapper,
  TableWrapper
} from '../ExamGradeBook/styled'
import YellowdigButton from './YellowdigButton'

const ascendingSort = (a, b) => new Date(a.dateStart) - new Date(b.dateStart)
const descendingSort = (a, b) => new Date(b.dateStart) - new Date(a.dateStart)

const CohortsTable = props => {
  const {
    heading,
    isCompletedTable,
    currentCourse,
    cohorts
  } = props

  const VIEW_LESS_COUNT = 3
  const [viewAll, setViewAll] = useState(false)
  const [filteredCohorts, setFilteredCohorts] = useState([])
  const [isDateStartClicked, setIsDateStartClicked] = useState(!isCompletedTable)

  useEffect(() => {
    setFilteredCohorts([...cohorts].sort(isCompletedTable ? descendingSort : ascendingSort))
    // eslint-disable-next-line
  }, [cohorts])

  const handleDeadlineClick = () => {
    setIsDateStartClicked(!isDateStartClicked)
    setFilteredCohorts([...filteredCohorts].reverse())
  }

  const handleViewAll = () => setViewAll(!viewAll)

  const hasCohorts = !!filteredCohorts.length
  const showViewAllSection = filteredCohorts.length > VIEW_LESS_COUNT
  const { name: courseName } = currentCourse
  const isGGUorEXTCourse = courseName?.toLowerCase()?.includes('ggu') ||
    courseName?.toLowerCase()?.includes('ext')
  const type = isGGUorEXTCourse ? 'community' : 'group'

  return (
    <TableMainWrapper data-testid='cohortsTable'>
      <SubPageHeading mt={isCompletedTable ? 22 : 30}>
        {heading}
      </SubPageHeading>
      <ExamSeparator />
      <TableWrapper>
        <TableHeader>
          <StudentDeadlineHeader onClick={handleDeadlineClick}>
            <TableHeaderText>Start Date</TableHeaderText>
            <IconsContainer>
              <div className='fa-icons'>
                <i
                  className={`fa fa-chevron-up ${!isDateStartClicked &&
                    'clicked-icon'}`}
                />
                <i
                  className={`fa fa-chevron-down ${isDateStartClicked &&
                    'clicked-icon'}`}
                />
              </div>
            </IconsContainer>
          </StudentDeadlineHeader>
          <TableHeaderText>Cohort</TableHeaderText>
          <span />
        </TableHeader>
        {
          hasCohorts ? (
            filteredCohorts.slice(0, viewAll ? filteredCohorts.length : VIEW_LESS_COUNT)
              .map(cohort => {
                const {
                  cohortId,
                  dateStart,
                  cohortName
                } = cohort

                return (
                  <TableRow data-testid='tableRow' key={`${cohortName}`}>
                    <Deadline>{dateStart}</Deadline>
                    <CohortName>{cohortName}</CohortName>
                    <GridButton>
                      <YellowdigButton
                        courseId={currentCourse.id}
                        cohortId={cohortId}
                        yellowdigIncluded={currentCourse.yellowdigIncluded}
                        type={type}
                      />
                    </GridButton>
                  </TableRow>
                )
              })
          ) : (
            <NoDataAvailable>
              There is no data available for the selected filter.
            </NoDataAvailable>
          )
        }
        {showViewAllSection && <ExamViewAllWrapper onClick={handleViewAll}>
          {viewAll ? (
            <i className='fa fa-chevron-up' alt='up-arrow-caret' />
          ) : (
            <i className='fa fa-chevron-down' alt='down-arrow-caret' />
          )}
          <span>{`View ${viewAll ? 'Less' : 'All'}`}</span>
        </ExamViewAllWrapper>}
      </TableWrapper>
    </TableMainWrapper>
  )
}

export default CohortsTable

export const GRADING_SCALE = {
  main: [
    {
      label: 'A (100 - 92.5)',
      value: '100-92.5'
    },
    {
      label: 'A- (92.49 - 89.5)',
      value: '92.49-89.5'
    },
    {
      label: 'B+ (89.49 - 86.5)',
      value: '89.49-86.5'
    },
    {
      label: 'B (86.49 - 82.5)',
      value: '86.49-82.5'
    },
    {
      label: 'B- (82.49 - 79.5)',
      value: '82.49-79.5'
    },
    {
      label: 'C+ (79.49 - 76.5)',
      value: '79.49-76.5'
    },
    {
      label: 'C (76.49 - 72.5)',
      value: '76.49-72.5'
    },
    {
      label: 'C- (72.49 - 69.5)',
      value: '72.49-69.5'
    },
    {
      label: 'D (69.49 - 59.5)',
      value: '69.49-59.5'
    },
    {
      label: 'F (59.49 - 0)',
      value: '59.49-0'
    }
  ],
  // FP means florida poly
  FP: [
    {
      label: 'A (100 - 90)',
      value: '100-90'
    },
    {
      label: 'A- (89.99 - 87)',
      value: '89.99-87'
    },
    {
      label: 'B+ (86.99 - 84)',
      value: '86.99-84'
    },
    {
      label: 'B (83.99 - 80)',
      value: '83.99-80'
    },
    {
      label: 'B- (79.99 - 77)',
      value: '79.99-77'
    },
    {
      label: 'C+ (76.99 - 74)',
      value: '76.99-74'
    },
    {
      label: 'C (73.99 - 70)',
      value: '73.99-70'
    },
    {
      label: 'C- (69.99 - 67)',
      value: '69.99-67'
    },
    {
      label: 'D+ (66.99 - 64)',
      value: '66.99-64'
    },
    {
      label: 'D (63.99 - 60)',
      value: '63.99-60'
    },
    {
      label: 'D- (59.99 - 57)',
      value: '59.99-57'
    },
    {
      label: 'F (56.99 - 0)',
      value: '56.99-0'
    }
  ]
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse } from 'reactstrap'
import Modal from './Modal'
import api from '../../api'
import { isAfterTokensLaunch } from '../../utilities/tokenUtils'
import { ReactComponent as ExandBtn } from '../../assets/icons/expand-icon.svg'
import { ReactComponent as CollapseBtn } from '../../assets/icons/collapse-icon.svg'
import {
  PRE_TOKEN_MESSAGE,
  POST_TOKEN_STUDENT_MESSAGE,
  PARTNER_MESSAGE
} from './constants/constants'
import { PARTNER_RETURN } from '../../Constants/token'

import {
  StudentRowWrapper,
  Row,
  StudentNameWrapper,
  OptionCheckBox,
  StudentCell,
  ExpandCollapseButton,
  StudentStatusWrapper,
  StudentStatus,
  StatusTitle,
  StatusInfo,
  RemoveStudent,
  TrashBin,
  TokenStatus
} from './styled'

const StudentRowComponent = ({
  studentData = {},
  selectedStudents,
  setSelectedStudents,
  fetchDropEligibleStudents,
  setIsStudentsLoading,
  toast = {}
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const {
    attemptId,
    studentEmail,
    cohortName,
    relationshipName,
    isRegistered,
    hasAIF,
    hasOLRF,
    orderNumber,
    returnTo,
    createdAt,
    completedAssignments,
    completedQuizzes,
    totalAssignments,
    totalQuizzes,
    dropType,
    dropReason
  } = studentData || {}
  const { current: { display } = {} } = toast

  const getTokenStatusMessage = () => {
    const isAfterTokens = isAfterTokensLaunch({ createdAt, attemptId })

    if (!isAfterTokens || orderNumber) return PRE_TOKEN_MESSAGE

    if (returnTo === PARTNER_RETURN) return PARTNER_MESSAGE

    return POST_TOKEN_STUDENT_MESSAGE
  }

  const handleCheckboxClick = () => {
    setSelectedStudents(preVal => {
      if (preVal.find(id => id === attemptId)) {
        return preVal.filter(id => id !== attemptId)
      }
      return [...preVal, attemptId]
    })
  }

  const getStatusText = (isComplete) => {
    return isComplete ? 'Complete' : 'Incomplete'
  }

  const getProgressText = (completed, total) => {
    if (!total) return 'N/A'
    return `${completed}/1`
  }

  const handleSubmit = async () => {
    setIsStudentsLoading(true)
    setIsRemoveModalOpen(false)
    const success = await api.removeStudentFromDropList(attemptId)
    await fetchDropEligibleStudents()

    if (success) {
      return display(
        'Student removed from the list',
        `${studentEmail} has been removed.`
      )
    }

    return display(
      'Something went wrong!',
      'Request was not successful. Please try again.'
    )
  }

  const getStudentStatus = () => {
    if (!isRegistered) {
      return (
        <>
          <StatusTitle>Registration Forms</StatusTitle>
          <StatusInfo isComplete={isRegistered}>
            Registration:<span>{getStatusText(isRegistered)}</span>
          </StatusInfo>
          <StatusInfo isComplete={hasAIF}>
            Academic Integrity:<span>{getStatusText(hasAIF)}</span>
          </StatusInfo>
          <StatusInfo isComplete={hasOLRF}>
            Online Readiness:<span>{getStatusText(hasOLRF)}</span>
          </StatusInfo>
        </>
      )
    }

    return (
      <>
        <StatusTitle>Course progress</StatusTitle>
        <StatusInfo isComplete={completedQuizzes || !totalQuizzes}>
          Quiz:<span>{getProgressText(completedQuizzes, totalQuizzes)}</span>
        </StatusInfo>
        <StatusInfo isComplete={completedAssignments || !totalAssignments}>
          Writing assignment:
          <span>{getProgressText(completedAssignments, totalAssignments)}</span>
        </StatusInfo>
      </>
    )
  }

  return (
    <StudentRowWrapper
      data-testid='comp-wrapper'
      isStudentCell
    >
      <Modal
        showModal={isRemoveModalOpen}
        handleClose={() => setIsRemoveModalOpen(false)}
        email={studentEmail}
        handleSubmit={handleSubmit}
      />
      <Row
        isStudentRow
        onClick={() => setIsOpen(!isOpen)}
      >
        <StudentNameWrapper>
          <OptionCheckBox
            isChecked={selectedStudents?.some(id => attemptId === id)}
            onClick={(e) => {
              e.stopPropagation()
              handleCheckboxClick()
            }}
            marginRight='40'
          />
          <StudentCell>
            {studentEmail}
          </StudentCell>
        </StudentNameWrapper>
        <StudentCell data-testid='cohort-name'>{cohortName}</StudentCell>
        <StudentCell>{relationshipName}</StudentCell>
        <StudentCell>{dropType}</StudentCell>
        <StudentCell>{dropReason}</StudentCell>
        <ExpandCollapseButton
          data-testid='expand-collapse-btn'
        >
          {isOpen ? <CollapseBtn /> : <ExandBtn />}
        </ExpandCollapseButton>
      </Row>
      <Collapse isOpen={isOpen}>
        <StudentStatusWrapper>
          <StudentStatus>
            {getStudentStatus()}
            <div
              onClick={() => setIsRemoveModalOpen(true)}
              style={{ marginTop: '7px' }}
              data-testid='remove-student-btn'
            >
              <TrashBin />
              <RemoveStudent>
                Remove Student
              </RemoveStudent>
            </div>
          </StudentStatus>
          <TokenStatus>
            <StatusTitle>Token status</StatusTitle>
            <p data-testid='token-message'>{getTokenStatusMessage()}</p>
          </TokenStatus>
        </StudentStatusWrapper>
      </Collapse>
    </StudentRowWrapper>
  )
}

StudentRowComponent.propTypes = {
  data: PropTypes.object,
  selectedStudents: PropTypes.array,
  setSelectedStudents: PropTypes.func
}
StudentRowComponent.displayName = 'StudentRowComponent'
export default StudentRowComponent

import React from 'react'
import Select, { components } from 'react-select'
import { CustomOption, DropdownHeader, multiSelectstyles } from './styles'
import { ButtonAsLink } from '../../shared/buttons'
import { ReactComponent as RightMarkSvg } from '../../../assets/icons/icon-right-mark.svg'

const SelectDropdown = (props) => {
  const { onSelectAll, onDeselectAll } = props.selectProps
  return (
    <components.Menu {...props}>
      <DropdownHeader>
        <ButtonAsLink onClick={onSelectAll}>Select All</ButtonAsLink>
        &nbsp;/&nbsp;
        <ButtonAsLink onClick={onDeselectAll}>Deselect All</ButtonAsLink>
      </DropdownHeader>
      {props.children}
    </components.Menu>
  )
}

const Option = (props) => {
  return (
    <components.Option {...props}>
      <CustomOption>
        {props.data.label}
        {props.isSelected ? <RightMarkSvg /> : null}
      </CustomOption>
    </components.Option>
  )
}

const CustomValueContainer = ({ children, getValue, options, ...props }) => {
  const count = getValue().length

  if (count === options.length) {
    return (
      <components.ValueContainer {...props}>
        All courses
      </components.ValueContainer>
    )
  }

  return (
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  )
}

function CourseSelect ({
  selectedCourses = [],
  setSelectedCourses,
  options,
  ...rest
}) {
  return (
    <Select
      data-testid='select-input'
      options={options}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      value={selectedCourses}
      onChange={setSelectedCourses}
      placeholder='Select Courses'
      styles={multiSelectstyles}
      onSelectAll={() => setSelectedCourses(options)}
      onDeselectAll={() => setSelectedCourses([])}
      components={{
        Menu: SelectDropdown,
        ValueContainer: CustomValueContainer,
        Option
      }}
      {...rest}
    />
  )
}

CourseSelect.displayName = 'CourseSelect'
export default CourseSelect

import React from 'react'
import {
  ButtonsContainer,
  Content,
  CustomButton,
  CustomButtonSecondary,
  Data,
  DataRow,
  ExtensionReason,
  Header,
  InfoRow,
  Label,
  ReviewContainer,
  ReviewMessage,
  ZendeskLabel,
  ZendeskTicket
} from './style'
import personIcon from '../../../assets/icons/person-fill.svg'
import bookIcon from '../../../assets/icons/book-white.svg'
import calendarIcon from '../../../assets/icons/calendar-plus-white.svg'
import notebookIcon from '../../../assets/icons/notebook.svg'
import SingleAssessmentReview from './SingleAssessmentReview'
import MultipleAssessmentsReview from './MultipleAssessmentsReview'
import {
  getDateStringWithComma,
  getDateStringWithoutYear
} from '../../../utilities/dateTimeUtil'

const StudentExtensionReview = ({
  isOneAssessmentMode,
  extensionReviewData,
  handlePreviousStep,
  handleConfirmation,
  isEditMode
}) => {
  const {
    studentData,
    selectedCourse,
    selectedCohort,
    assessmentsList,
    selectedAssessment,
    newDeadlineDate,
    selectedNumber,
    selectedUnitLength,
    zendeskLink,
    extensionReason
  } = extensionReviewData

  const { dateStart, cohortEndTime } = selectedCohort
  const formattedDateStart = getDateStringWithoutYear(dateStart)
  const formattedCohortEndDate = getDateStringWithComma(cohortEndTime)

  return (
    <ReviewContainer data-testid='student-extension-review'>
      <ReviewMessage>Please review before confirming.</ReviewMessage>
      <InfoRow>
        <Header>
          <img src={personIcon} alt='person-icon' />
          <span>student</span>
        </Header>
        <Content>
          <DataRow>
            <Label>Name</Label>
            <Data>{studentData?.name}</Data>
          </DataRow>
          <DataRow>
            <Label>Email</Label>
            <Data>{studentData?.email}</Data>
          </DataRow>
          <DataRow>
            <Label>Id</Label>
            <Data>{studentData?.id}</Data>
          </DataRow>
        </Content>
      </InfoRow>
      <InfoRow>
        <Header>
          <img src={bookIcon} alt='book-icon' />
          <span>Course information</span>
        </Header>
        <Content>
          <DataRow>
            <Label>Course</Label>
            <Data>{selectedCourse.name}</Data>
          </DataRow>
          <DataRow>
            <Label>Cohort</Label>
            <Data>{`${formattedDateStart} - ${formattedCohortEndDate}`}</Data>
          </DataRow>
        </Content>
      </InfoRow>
      <InfoRow>
        <Header>
          <img src={calendarIcon} alt='calendar-icon' />
          <span>Extension details</span>
        </Header>
        {
          isOneAssessmentMode
            ? <SingleAssessmentReview
              assessment={selectedAssessment}
              newDeadlineDate={newDeadlineDate}
              isEditMode={isEditMode}
            />
            : <MultipleAssessmentsReview
              assessmentsList={assessmentsList}
              selectedNumber={selectedNumber}
              selectedUnitLength={selectedUnitLength}
            />
        }
      </InfoRow>
      <InfoRow>
        <Header>
          <img src={notebookIcon} alt='notebook-icon' />
          <span>extension reason</span>
        </Header>
        <Content>
          <DataRow>
            <ZendeskLabel>Zendesk</ZendeskLabel>
            <ZendeskTicket
              href={zendeskLink}
              target='_blank'
            >
              View ticket
            </ZendeskTicket>
          </DataRow>
        </Content>
        <DataRow>
          <ExtensionReason>
            {extensionReason}
          </ExtensionReason>
        </DataRow>
      </InfoRow>
      <ButtonsContainer>
        <CustomButton
          className='btn-custom btn-primary'
          style={{ width: '197px' }}
          onClick={() => {
            handleConfirmation()
          }}
        >
          {!isEditMode ? 'create extension' : 'update extension'}
        </CustomButton>
        <CustomButtonSecondary
          className='btn-custom'
          onClick={() => {
            handlePreviousStep()
          }}
        >
          Cancel
        </CustomButtonSecondary>
      </ButtonsContainer>
    </ReviewContainer>
  )
}
StudentExtensionReview.displayName = 'StudentExtensionReview'
export default StudentExtensionReview

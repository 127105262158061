import React, { useState } from 'react'
import api from '../../../api'
import { changeParticipationPercentToGrade } from '../../../utilities/gradeUtils'
import EditParticipationModal from '../../EditGrades/EditParticipationModal'
import { useStudentGradeContext } from '../../../contexts/StudentGradeContext'

const ParticipationDetail = props => {
  const {
    cohortId,
    gradeData,
    canUserEditGrades,
    specificHistory,
    courseId,
    cohort
  } = props

  const {
    maxParticipationGrade
  } = useStudentGradeContext()

  const [showModal, setShowModal] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)

  const handleEditParticipation = async updatedParticipation => {
    const { studentEmail, courseId } = props

    setIsUpdating(true)
    await api.putParticipationModifications(
      studentEmail,
      { courseId,
        cohortId: gradeData.pastCohortData ? cohortId : null },
      updatedParticipation
    )
    setIsUpdating(false)
    setShowModal(false)

    // refresh the page
    window.location.reload()
  }

  const participationGrade = gradeData && changeParticipationPercentToGrade({
    percent: gradeData.grade,
    maxParticipationGrade
  })
  const isValidGrade = typeof participationGrade === 'number'

  return (
    <div className='learning'>
      <div className='learning-section'>
        <span className='title'>Scores</span>
        <div>
          <div className='section-data-item'>
            <div className='section-data-quiz'>Participation Grade:</div>
            <div className='section-data-quiz'>
              {isValidGrade ? `${participationGrade}` : '--'}
            </div>
            {canUserEditGrades && typeof participationGrade === 'number' &&
              <div className='section-data-quiz__edit'
                onClick={() => setShowModal(true)}>edit</div>
            }
          </div>
        </div>
      </div>
      {!!specificHistory.length && <div className='learning-section' style={{ gridArea: 'edit' }}>
        <span className='title'>Change notes</span>
        {
          specificHistory.map((change, index) => {
            return (
              <div className='change-item' key={index}>
                <div className='change-item__date'>
                  <span>{change.dateTime}</span>
                </div>
                <div>
                  <div>
                    {`${change.user} changed ${change.section} grade from ${change.from || 0} to ${change.to}`}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>}
      {showModal && <EditParticipationModal
        close={() => setShowModal(false)}
        show={showModal}
        participationGrade={participationGrade}
        handleEditParticipation={handleEditParticipation}
        isUpdating={isUpdating}
        courseId={courseId}
        cohort={cohort}
      />}
    </div>
  )
}

export default ParticipationDetail

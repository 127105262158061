import React from 'react'
import PropTypes from 'prop-types'
import {
  Header,
  Separator,
  IndicatorsContainer
} from '../../CSVDownloadTool/style'
import { ActiveIndicator } from './styled'
import { PAGE_TYPE } from '../../../Constants/CSVDownloadToolV2'

const CSVHeader = props => {
  const { pageType } = props

  const isExportedPage = pageType === PAGE_TYPE.EXPORTED_INFORMATION

  return (
    <>
      <Header>csv generator</Header>
      <Separator />

      <IndicatorsContainer>
        <ActiveIndicator isActive={!isExportedPage}>
          <span>1</span> student criteria
        </ActiveIndicator>
        <ActiveIndicator isActive={isExportedPage}>
          <span>2</span> exported information
        </ActiveIndicator>
      </IndicatorsContainer>
    </>
  )
}

CSVHeader.propTypes = {
  pageType: PropTypes.string
}

CSVHeader.displayName = 'CSVHeader'

export default CSVHeader

import styled from 'styled-components'

export const Container = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin: 64px;
`

export const Header = styled.h2`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: var(--text-primary);
  margin-bottom: 0;
`

export const InputGroup = styled.span`
  position: relative;
  width: 288px;
  height: 40px;
`

export const Search = styled.input`
  color: var(--text-primary);
  width: 288px;
  height: 40px;
  padding: 6px;
  background: var(--black-tertiary);
  border: 1px solid var(--border-secondary);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 8px 0px;
  margin-top: 24px;
  margin-bottom: 25px;

  &:focus {
    outline: none;
  }
`

export const SearchIcon = styled.img`
  position: absolute;
  height: 16px;
  width: 16px;
  right: 8px;
  top: 2px;
`

export const Separator = styled.hr`
  height: 0;
  border: 1px solid var(--border-secondary);

  &.mt-unset {
    margin-top: unset;
  }

  &.mb-unset {
    margin-bottom: unset;
  }
`

export const HeaderItem = styled.span`
  margin: 0 16px;
  line-height: 24px;
  color: var(--text-dark-off);

  &:nth-child(2),
  &:nth-child(5) {
    width: 120px;
  }

  &:first-child,
  &:nth-child(3) {
    width: 192px;
  }

  &:nth-child(4) {
    width: 96px;
  }

  &:nth-child(6) {
    width: 160px;
  }
`

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, auto);
  gap: 32px;
`

export const TableItemWrapper = styled(GridWrapper)`
  border-bottom: 1px solid var(--gray-primary);
  height: 56px;
  &.border-bottom-none {
    border-bottom: none;
  }
`

export const TableItem = styled.span`
  color: var(--text-primary);
  margin: 16px;

  &:first-child {
    color: var(--brand-regular);
    cursor: pointer;
    width: 192px;
  }

  &:nth-child(2),
  &:nth-child(5) {
    width: 120px;
  }

  &:nth-child(3) {
    width: 192px;
    margin: auto;
    word-break: break-word;
  }

  &:nth-child(4) {
    width: 96px;
  }

  &:nth-child(6) {
    width: 160px;
  }
`

export const PaginationWrapper = styled.div`
  margin: ${({ isUpdateStyle }) => isUpdateStyle ? '10px 0 0' : '0 16px'};
  display: flex;
  justify-content: space-between;
  ${({ isUpdateStyle }) => isUpdateStyle && `
    height: 56px;
    padding: 0 16px;
    align-items: center;
    background: var(--black-secondary);
  `}
`

export const PaginationItems = styled.span`
  display: flex;
  gap: 24px;

  & > span {
    color: var(--brand-regular);
    cursor: pointer;

    &:hover {
      color: var(--text-primary);
    }

    &:active {
      text-decoration: ${({ isUpdateStyle }) => isUpdateStyle ? 'none' : 'underline'};
      color: var(--text-primary);
    }

    &.active {
      text-decoration: ${({ isUpdateStyle }) => isUpdateStyle ? 'none' : 'underline'};
      color: var(--text-primary);
    }
  }
`

export const PaginationCount = styled.span`
  color: var(--text-secondary);
`

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;

  & > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
    margin-bottom: 0;
  }

  & > img {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
`

export const ModalSeparator = styled.hr`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border: 0 none;
  margin: 0;
`

export const ModalContent = styled.div`
  width: 400px;
  padding: 24px;

  & > div {
    margin-bottom: 24px;

    img {
      margin-right: 8px;
      margin-top: -3px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > p {
    margin-bottom: 24px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
`
export const RequestDetail = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => color || 'var(--text-primary)'};
  margin-bottom: 0;

  & > span {
    color: ${({ decisionColor }) => decisionColor || 'var(--text-primary)'};
  }
`

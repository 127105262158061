import React from 'react'
import moment from 'moment'
import chevronRight from '../../assets/icons/chevron-right-teal.svg'
import {
  ApplicationsRowField,
  CustomIcon,
  IconContainer
} from './style/index.style'

const ApplicationsTableRow = ({ application, isMobile }) => {
  const {
    email,
    prospectStatus,
    admissionDecision,
    degreeStatus,
    submissionTime
  } = application || {}

  const formattedSubmissionTime = moment(submissionTime).format('M/D/YYYY h:mm a')

  return (
    <>
      {isMobile && (
        <IconContainer>
          <CustomIcon src={chevronRight} alt='chevron-icon' />
        </IconContainer>
      )}
      <ApplicationsRowField width={281}>{email}</ApplicationsRowField>
      <ApplicationsRowField width={281}>{prospectStatus}</ApplicationsRowField>
      <ApplicationsRowField width={219}>{admissionDecision}</ApplicationsRowField>
      <ApplicationsRowField width={204}>{degreeStatus}</ApplicationsRowField>
      <ApplicationsRowField width={281}>{formattedSubmissionTime}</ApplicationsRowField>
      {!isMobile && (
        <IconContainer>
          <CustomIcon src={chevronRight} alt='chevron-icon' />
        </IconContainer>
      )}
    </>
  )
}

export default ApplicationsTableRow

import React, { useState, useEffect } from 'react'
import cuid from 'cuid'
import {
  CustomFormGroup,
  CustomLabel,
  CustomInput,
  CustomSelect,
  ErrorText,
  EmailError,
  CustomCheckbox,
  DatePickerWrapper,
  CustomButton, Info, CustomToolTip
} from './style'
import { components } from 'react-select'
import api from '../../api'
import { ReactComponent as RemoveIcon } from '../../assets/icons/modal-close.svg'
import { useHistory, useLocation } from 'react-router-dom'
import { validateEmail } from '../../utilities/userUtils'
import { useDebouncedEffect } from '../../Hooks/useDebounce'
import { checkDeepEqual, generateOrderNumber, parseOrderNumber } from '../../utilities/addStudentUtils'
import usePrevious from '../../Hooks/usePrevious'
import { CustomDatePicker } from '../VipUser/styled'
import { getDateString } from '../../utilities/dateTimeUtil'
import { PARTNERSHIP, SCHOLARSHIP } from '../../Constants'
import { courseSelectStyles } from '../global.styled'
import { getCoursesWithCourseId, getAdditionalCourseDetails } from '../../utilities/courseUtils'

const NOTES_TOOLTIP = 'Notes will be saved in the Student record.'

function StudentInformation (props) {
  const {
    studentRelationshipState,
    coursesState,
    cohortState,
    formState: formStateHandler
  } = props

  const history = useHistory()
  const [formState, setFormState] = formStateHandler
  const [allCourses, setCourses] = coursesState
  const [allCohorts, setCohorts] = cohortState
  const [allStudentsRelations, setStudentRelations] = studentRelationshipState
  const [loading, setLoading] = useState()
  const [errors, setErrors] = useState({})
  const prevState = usePrevious(formState)
  const location = useLocation()
  const { previousClicked } = location.state || {}

  useEffect(() => {
    const { id } = formState
    id || generateId()
    const shouldFetch = !allCourses.length || !allCohorts.length || !allStudentsRelations.length
    if (shouldFetch) fetchDropdownData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const { cohorts, courses } = formState
    const filtered = filterCohorts(courses, cohorts)
    onChange('cohorts', filtered || [])
    // eslint-disable-next-line
  }, [formState.courses])

  useDebouncedEffect(() => checkUserExists(), [formState.email], 1000)

  const checkUserExists = async () => {
    const { email, emailExistError } = formState
    if (validateEmail(email)) {
      const isExist = await api.checkEmailExists(email)
      return onChange('emailExistError', isExist)
    }
    emailExistError && onChange('emailExistError', false)
  }

  const fetchDropdownData = async () => {
    setLoading(true)
    const { getCourses, getAllCohorts, getStudentRelationships } = api
    const promises = [getCourses(), getAllCohorts(), getStudentRelationships()]
    const [courses, cohorts, relations] = await Promise.all(promises)
    const allCourses = courses.map(course =>
      ({ value: course.at_id, label: course.name, ...course }))
    courses && setCourses(allCourses)
    cohorts && setCohorts(
      cohorts.map(cohort =>
        ({ value: cohort.at_id, label: cohort.name, ...cohort }))
    )
    !relations.error && setStudentRelations(
      relations.map(relation =>
        ({ value: relation.id, label: relation.relationshipName, ...relation }))
    )
    setLoading(false)
  }

  const generateId = () => {
    const id = cuid()
    onChange('id', `s${id}`)
  }

  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <RemoveIcon />
      </components.MultiValueRemove>
    )
  }

  const onChange = (name, value) => {
    setFormState((prevState) => ({ ...prevState, [name]: value }))
  }

  const onClickNextPage = async () => {
    const {
      email,
      cohorts,
      name,
      relationship: { relationshipType: [type] = [] }
    } = formState

    const isGreaterThan255Chars = !name?.trim() || name?.trim()?.length >= 255
    if (isGreaterThan255Chars) {
      return setErrors({ name: 'Name cannot be empty spaces or greater than 255 Characters' })
    }

    if (!validateEmail(email)) return setErrors({ email: 'Invalid Email' })

    let latestOrderNumber
    const isPartnership = type === PARTNERSHIP
    const lastOrders = await api.getLastOrders()
    const {
      scholarships: { orderNumber: scholarshipOrderNumber = '' } = {}
    } = lastOrders

    onChange('lastOrders', lastOrders)

    latestOrderNumber = parseOrderNumber(scholarshipOrderNumber)

    const isEqual = checkDeepEqual(formState, prevState, previousClicked)
    if (!isEqual) {
      const orders = []
      for (const cohort of cohorts) {
        const { purchases = {}, attempts = {} } = formState.orders.find(
          ({ cohort: selected }) => cohort.at_id === selected.value
        ) || {}
        const orderDetails = {}
        latestOrderNumber++
        orderDetails.purchases = {
          paymentStatus: '',
          pmtMethod: '',
          paidAmount: '',
          testOrder: false,
          cohort: cohort.value,
          ...purchases,
          orderNumber: generateOrderNumber(latestOrderNumber, isPartnership),
          purchaseDate: new Date(),
          isPartnerStudent: type === PARTNERSHIP
        }
        orderDetails.attempts = {
          attemptID: cuid(),
          status: '',
          note: '',
          testAttempt: false,
          comments: '',
          cohort: cohort.value,
          ...attempts,
          scholarship: type === SCHOLARSHIP
        }
        orderDetails.cohort = { ...cohort }
        orders.push({ ...orderDetails })
      }
      onChange('orders', orders)
    }

    history.push({
      pathname: '/students/attempts-and-purchases',
      state: {
        allowView: true
      }
    })
  }

  const filteredCourses = getCoursesWithCourseId(allCourses)

  const filterCohorts = (selectedCourses, cohorts) => {
    if (!selectedCourses || !selectedCourses.length) return []
    const courseToAdd = getAdditionalCourseDetails(allCourses, selectedCourses)
    const courses = courseToAdd ? [...selectedCourses, courseToAdd] : selectedCourses
    return cohorts.filter((cohort) =>
      courses.some((course) =>
        cohort.course.includes(course.at_id)
      )
    )
  }

  const {
    id,
    email,
    name,
    notes,
    dateOfBirth,
    courses,
    cohorts,
    emailExistError,
    contractorEmployee,
    disableOnboarding
  } = formState

  const isDisabled = !email || !name || !courses.length || !cohorts.length || emailExistError

  return (
    <CustomFormGroup>
      <CustomToolTip
        className='tooltip'
        multiline
        effect='solid'
        place='right'
      />
      <CustomLabel>ID</CustomLabel>
      <CustomInput type='text' value={id} disabled />

      <CustomLabel>Student Email</CustomLabel>
      <div>
        {emailExistError && (
          <EmailError>
            This email already exists in our database. Please create a new user
            with another email.
          </EmailError>
        )}
        <CustomInput
          data-testid='email-field'
          error={emailExistError}
          type='email'
          value={email}
          onChange={e => onChange('email', e.target.value)}
        />
        <ErrorText>{errors && errors.email}</ErrorText>
      </div>

      <CustomLabel>Name</CustomLabel>
      <CustomInput
        data-testid='name-field'
        type='text'
        value={name}
        onChange={e => onChange('name', e.target.value)}
      />
      <ErrorText>{errors?.name}</ErrorText>

      <CustomLabel>Date of Birth </CustomLabel>
      <DatePickerWrapper disabled={false}>
        <CustomDatePicker
          data-testid='date-field'
          className='date'
          autoComplete='off'
          placeholderText='MM/DD/YYYY'
          maxDate={new Date()}
          selected={dateOfBirth ? new Date(dateOfBirth) : dateOfBirth}
          onChange={date => onChange(
            'dateOfBirth', getDateString(date)
          )}
          disabled={false}
        />
      </DatePickerWrapper>

      <CustomLabel>Courses</CustomLabel>
      <CustomSelect
        components={{ MultiValueRemove }}
        onChange={(courses) => {
          onChange('courses', courses || [])
        }}
        value={courses}
        styles={courseSelectStyles}
        classNamePrefix='select'
        options={filteredCourses}
        isClearable={false}
        placeholder=''
        isMulti
      />

      <CustomLabel>Cohorts</CustomLabel>
      <CustomSelect
        components={{ MultiValueRemove }}
        onChange={(cohorts) => {
          onChange('cohorts', cohorts || [])
        }}
        value={cohorts}
        styles={courseSelectStyles}
        classNamePrefix='select'
        options={filterCohorts(courses, allCohorts)}
        isClearable={false}
        placeholder=''
        isMulti
      />

      <CustomLabel>
        Notes <Info data-tip={NOTES_TOOLTIP} />
      </CustomLabel>
      <CustomInput
        type='textArea'
        value={notes}
        onChange={e => onChange('notes', e.target.value)}
      />

      <CustomCheckbox>
        Test account
        <input
          type='checkbox'
          data-testid='contractorEmployee'
          id='contractorEmployee'
          name='contractorEmployee'
          value={contractorEmployee}
          onChange={e => onChange(
            'contractorEmployee', !contractorEmployee
          )}
          checked={contractorEmployee}
        />
        <span />
      </CustomCheckbox>

      <CustomCheckbox>
        Disable onboarding forms
        <input
          type='checkbox'
          data-testid='notStudent'
          id='notStudent'
          name='notStudent'
          value={disableOnboarding}
          onChange={e => onChange(
            'disableOnboarding', !disableOnboarding
          )}
          checked={disableOnboarding}
        />
        <span />
      </CustomCheckbox>

      {loading ? <i className='fa fa-spinner fa-spin' />
        : (
          <CustomButton
            data-testid='next-button'
            onClick={onClickNextPage}
            className='btn-custom btn-primary'
            disabled={isDisabled}
          >
          Next Page
          </CustomButton>
        )}
    </CustomFormGroup>
  )
}

StudentInformation.displayName = 'StudentInformation'
export default StudentInformation

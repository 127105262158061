import React, { useMemo, useState } from 'react'
import {
  BoxArrowIcon,
  ColoredButton,
  EllipseIcon,
  HistoryBody,
  HistoryContainer,
  HistoryItem,
  HistoryTitle,
  SectionBody,
  SectionContainer,
  SectionHeader,
  SeeAllButton
} from '../style/ProspectPage'
import { BodyText, Label, SectionTitle } from '../../../styles/text'
import {
  extractFields,
  getInprogressAttemptsCohorts,
  getCohortsFromAttempts
} from '../utils'
import { HISTORY_LIMIT } from '../utils/constants'

function AcademicHistory ({
  prospect,
  studentAttempts
}) {
  const { allAttempts, inprogressCohorts, transferredCredits } = useMemo(() => {
    const allAttempts = extractFields(getCohortsFromAttempts(studentAttempts))
    const inprogressCohorts = extractFields(getInprogressAttemptsCohorts(studentAttempts))
    const transferredCredits = extractFields(prospect?.transferredCredit)

    return { allAttempts, inprogressCohorts, transferredCredits }
  }, [prospect, studentAttempts])

  const openStudentRecordPage = () => {
    const { outlierId, email } = prospect || {}
    window.open(`#/student-record-details/${outlierId}/${email}`, '_blank')
  }

  const [showStatus, setShowStatus] = useState({
    active: false, transferred: false, allAttempts: false
  })

  const displayRecords = (records, key) => {
    if (showStatus[key]) return records
    return records.slice(0, HISTORY_LIMIT)
  }

  const handleSeeAll = (key) => {
    setShowStatus((prev) => ({
      ...prev,
      [key]: !prev[key]
    }))
  }

  return (
    <SectionContainer fill gap='20px'>
      <SectionHeader>
        <SectionTitle>Academic history</SectionTitle>
        {!!prospect?.prospectId && (
          <ColoredButton
            onClick={() => openStudentRecordPage()}
          >
            <BoxArrowIcon />
            <Label>view student record</Label>
          </ColoredButton>
        )}
      </SectionHeader>

      <SectionBody gap='32px'>
        <HistoryContainer>
          <HistoryTitle>
            <BodyText>Courses in progress</BodyText>
            <EllipseIcon />
            <BodyText>{inprogressCohorts?.length} total</BodyText>
          </HistoryTitle>

          {!!inprogressCohorts?.length && (
            <HistoryBody>
              {displayRecords(inprogressCohorts, 'active')?.map(cohort => (
                <HistoryItem key={cohort?.id}>{cohort?.name}</HistoryItem>
              ))}

              {inprogressCohorts?.length > HISTORY_LIMIT && (
                <SeeAllButton
                  onClick={() => handleSeeAll('active')}
                >
                  see {showStatus.active ? 'less' : 'all'}
                </SeeAllButton>
              )}
            </HistoryBody>
          )}
        </HistoryContainer>

        <HistoryContainer>
          <HistoryTitle>
            <BodyText>Earned transfer credits</BodyText>
            <EllipseIcon />
            <span>{transferredCredits?.length} total</span>
          </HistoryTitle>

          {!!transferredCredits?.length && (
            <HistoryBody>
              {displayRecords(transferredCredits, 'transferred')?.map(cohort => (
                <HistoryItem key={cohort?.id}>{cohort?.displayName}</HistoryItem>
              ))}

              {transferredCredits?.length > HISTORY_LIMIT && (
                <SeeAllButton
                  onClick={() => handleSeeAll('transferred')}
                >
                  see {showStatus.transferred ? 'less' : 'all'}
                </SeeAllButton>
              )}
            </HistoryBody>
          )}
        </HistoryContainer>

        <HistoryContainer>
          <HistoryTitle>
            <BodyText>Earned general electives credits</BodyText>
            <EllipseIcon />
            <span>{prospect?.generalElectiveCredits || 0}</span>
          </HistoryTitle>
        </HistoryContainer>

        <HistoryContainer>
          <HistoryTitle>
            <BodyText>All attempts</BodyText>
            <EllipseIcon />
            <span>{allAttempts?.length} total</span>
          </HistoryTitle>

          {!!allAttempts?.length && (
            <HistoryBody>
              {displayRecords(allAttempts, 'allAttempts')?.map(cohort => (
                <HistoryItem key={cohort?.id}>
                  {cohort?.name} ({cohort?.studentStatus})
                </HistoryItem>
              ))}

              {allAttempts?.length > HISTORY_LIMIT && (
                <SeeAllButton
                  onClick={() => handleSeeAll('allAttempts')}
                >
                  see {showStatus.allAttempts ? 'less' : 'all'}
                </SeeAllButton>
              )}
            </HistoryBody>
          )}
        </HistoryContainer>
      </SectionBody>
    </SectionContainer>
  )
}

AcademicHistory.displayName = 'AcademicHistory'
export default AcademicHistory

import isEmpty from 'lodash/isEmpty'

export const getOutstandingRequests = requests => {
  if (!requests?.length) return []

  return requests.filter(request => {
    const { decisions = [] } = request || {}
    return isEmpty(decisions)
  })
}

export const getPassedRequirements = ({
  requestReceived,
  earliestDropDate,
  latestRefundDate
}) => {
  const requestReceivedDate = new Date(requestReceived)
  const requirementValue1 = requestReceivedDate < new Date(earliestDropDate) ? 1 : 0
  const requirementValue2 = requestReceivedDate < new Date(latestRefundDate) ? 1 : 0
  return requirementValue1 + requirementValue2
}

export const sortRequests = (requests, sortField) => {
  if (!requests?.length) return []

  return requests.sort((a, b) => {
    const requestReceived1 = new Date(a[sortField])
    const requestReceived2 = new Date(b[sortField])
    return requestReceived1 - requestReceived2
  })
}

import React, { useReducer, useCallback } from 'react'
import { SET_COURSES } from '../Constants/index'
import api from '../api'

const CoursesContext = React.createContext()

const initialState = {
  allCourses: []
}

export function reducer (state, action) {
  switch (action.type) {
    case SET_COURSES:
      return {
        ...state,
        allCourses: action.allCourses
      }
    default: return { ...state }
  }
}
// eslint-disable-next-line
export default function CoursesProvider({ children, providedState }) {
  const [state, dispatch] = useReducer(reducer, providedState || initialState)

  const fetchCourses = useCallback(async () => {
    const allCourses = await api.getCourses()
    dispatch({ type: SET_COURSES, allCourses })
  }, [])

  const actions = {
    fetchCourses
  }

  return (
    <CoursesContext.Provider value={{ state, actions }}>
      {children}
    </CoursesContext.Provider>
  )
}

export function useCoursesContext () {
  return React.useContext(CoursesContext).state
}

export function useCoursesActions () {
  return React.useContext(CoursesContext).actions
}

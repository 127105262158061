import React, { useEffect, useState } from 'react'
import {
  CardGroup,
  CardsContainer,
  Container,
  GoToPreviousPageButtonWrapper,
  Header,
  ProspectId,
  Separator,
  StudentName
} from './styles'
import GoToPreviousPageButton from '../../../Components/GoToPreviousPageButton/GoToPreviousPageButton'
import AdmissionDetails from './AdmissionDetails'
import DegreeDetails from './DegreeDetails'
import api from '../../../api'
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner'
import CommentsSection from './CommentsSection'
import StudentDetails from './StudentDetails'

function ProspectPage ({ routerProps }) {
  const { match, history } = routerProps
  const {
    params: { prospectId }
  } = match

  const [prospect, setProspect] = useState({})
  const {
    firstName,
    middleName,
    lastName,
    preferredName,
    prospectATId
  } = prospect
  const fullName = `${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''}`

  useEffect(() => {
    const getProspectDetails = async () => {
      const prospect = await api.getProspectDetails(
        encodeURIComponent(prospectId)
      )
      setProspect({
        ...prospect,
        degree: prospect.degreeName,
        gguSemesters: prospect.currentSemester
      })
    }
    getProspectDetails()
  }, [prospectId])

  if (!Object.keys(prospect).length) {
    return <LoadingSpinner />
  }

  return (
    <Container>
      <GoToPreviousPageButtonWrapper>
        <GoToPreviousPageButton
          text='Go back'
          handleClick={() => {
            history.push('/ggu-app-management')
          }}
        />
      </GoToPreviousPageButtonWrapper>
      <Header>
        <StudentName>{preferredName || fullName}</StudentName>
        <ProspectId>Prospect ID: {prospectId}</ProspectId>
      </Header>
      <Separator />
      <CardsContainer>
        <StudentDetails prospect={prospect} setProspect={setProspect} />
        <CardGroup>
          <AdmissionDetails prospect={prospect} setProspect={setProspect} />
          <DegreeDetails prospect={prospect} setProspect={setProspect} />
        </CardGroup>
        <CommentsSection prospectATId={prospectATId} />
      </CardsContainer>
    </Container>
  )
}

ProspectPage.displayName = 'ProspectPage'
export default ProspectPage

import React, { useState, useRef } from 'react'
import moment from 'moment/moment'
import DatePicker from 'react-datepicker'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import leftArrow from '../../assets/icons/icon-left-arrow.svg'
import rightArrow from '../../assets/icons/icon-right-arrow.svg'
import filledRightArrow from '../../assets/icons/icon-filled-right-arrow.svg'
import filledLeftArrow from '../../assets/icons/icon-filled-left-arrow.svg'
import {
  OverrideStyles,
  carouselSsettings,
  DatesHeader,
  LabelText,
  Link,
  SliderArrowWrapper,
  FilledSliderArrow,
  SliderWrapper,
  SliderContainer,
  SliderArrow
} from './styled'

const DateCarousel = ({
  selectedDates,
  setSelectedDates
}) => {
  const getMonths = length =>
    Array.from({ length }, (_, i) => {
      const monthStart = moment().add(i, 'months').startOf('month').toDate()
      const monthEnd = moment().add(i, 'months').endOf('month').toDate()
      return {
        monthStart: new Date(monthStart),
        monthEnd: new Date(monthEnd)
      }
    })

  const [months, setMonths] = useState(getMonths(5))
  const [activeSlide, setActiveSlide] = useState(0)

  const sliderRef = useRef()

  const next = () => {
    const lastIndex = months.length
    const monthStart = moment().add(lastIndex, 'months').startOf('month').toDate()
    const monthEnd = moment().add(lastIndex, 'months').endOf('month').toDate()

    setMonths(prev => [
      ...prev,
      {
        monthStart: new Date(monthStart),
        monthEnd: new Date(monthEnd)
      }
    ])

    sliderRef.current.slickNext()
    setActiveSlide(prev => prev + 1)
  }

  const handleDateChange = (i, date) => {
    if (
      !selectedDates[i]?.find(selectedDate =>
        moment(selectedDate).isSame(date, 'day')
      )
    ) {
      return setSelectedDates(prev => ({
        ...prev,
        [i]: [...(selectedDates[i] || []), date]
      }))
    }

    return setSelectedDates(prev => {
      const selectedDates = prev[i]?.filter(
        selectedDate => !moment(selectedDate).isSame(date, 'day')
      ) || []

      return {
        ...prev,
        [i]: selectedDates
      }
    })
  }

  const previous = () => {
    sliderRef.current.slickPrev()
    if (activeSlide === 0) return
    setActiveSlide(prev => prev - 1)
  }

  return (
    <>
      <OverrideStyles />
      <DatesHeader>
        <LabelText>
          <span>Start Dates</span>
          <Link
            onClick={() => setSelectedDates({})}
          >
            Reset
          </Link>
        </LabelText>
        <SliderArrowWrapper>
          <FilledSliderArrow
            src={filledLeftArrow}
            alt='filled-slider-arrow-icon'
            onClick={previous}
          />
          <FilledSliderArrow
            src={filledRightArrow}
            alt='filled-slider-arrow-icon'
            onClick={next}
          />
        </SliderArrowWrapper>
      </DatesHeader>
      <SliderWrapper>
        {activeSlide !== 0 &&
          <SliderArrow
            src={leftArrow}
            onClick={previous}
          />
        }
        <SliderContainer
          isAtStart={activeSlide === 0}
        >
          <Slider ref={sliderRef} {...carouselSsettings}>
            {months.map((month, index) => {
              const { monthStart, monthEnd } = month
              return (
                <DatePicker
                  onChange={date => handleDateChange(index, date)}
                  key={index}
                  openToDate={monthStart}
                  startDate={index === 0 && new Date()}
                  minDate={monthStart}
                  maxDate={monthEnd}
                  highlightDates={selectedDates[index]}
                  inline
                  fixedHeight
                />)
            })}
          </Slider>
        </SliderContainer>
        <SliderArrow
          src={rightArrow}
          onClick={next}
        />
      </SliderWrapper>
    </>
  )
}

DateCarousel.displayName = 'DateCarousel'
export default DateCarousel

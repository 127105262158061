import {
  ADMINISTRATIVE_DROP,
  AUDIT, AUDIT_TRANSCRIPT, DROP, DROP_NR, DROP_NV, ENROLLED,
  INCOMPLETE, MELT, NEEDS_REVIEWING, NEEDS_REVIEWING_DEGREE, PRE_DROP,
  SIGNED_UP,
  UNOFFICIAL_WITHDRAW
} from './studentStatus'

export const IN_PROGRESS_STATUSES = [SIGNED_UP, PRE_DROP, ENROLLED]
export const AUDIT_STATUSES = [AUDIT, AUDIT_TRANSCRIPT]
export const EXITED_STATUSES = [DROP, MELT]
export const NEEDS_REVIEWING_STATUSES = [NEEDS_REVIEWING, NEEDS_REVIEWING_DEGREE]
export const MANUAL_DROPS_STATUSES = [
  DROP_NR,
  DROP_NV,
  ADMINISTRATIVE_DROP,
  INCOMPLETE,
  UNOFFICIAL_WITHDRAW
]

export const CHART_IN_PROGRESS = 'In progress'
export const CHART_AUDIT = 'Audit'
export const CHART_COMPLETED = 'Completed'
export const CHART_WITHDRAW = 'Withdraw'
export const CHART_MANUAL_DROPS = 'Manual drops'
export const CHART_EXITED = 'Exited'
export const CHART_NEEDS_REVIEW = 'Needs review'

export const PIE_DATA = [
  {
    name: CHART_IN_PROGRESS,
    color: '#5FC4B8',
    count: 0,
    value: 0,
    statuses: []
  },
  {
    name: CHART_AUDIT,
    color: '#2A7C9E',
    count: 0,
    value: 0,
    statuses: []
  },
  {
    name: CHART_COMPLETED,
    color: '#564AB8',
    count: 0,
    value: 0,
    statuses: []
  },
  {
    name: CHART_WITHDRAW,
    color: '#874198',
    count: 0,
    value: 0,
    statuses: []
  },
  {
    name: CHART_MANUAL_DROPS,
    color: '#969BFF',
    count: 0,
    value: 0,
    statuses: []
  },
  {
    name: CHART_EXITED,
    color: '#98CA70',
    count: 0,
    value: 0,
    statuses: []
  },
  {
    name: CHART_NEEDS_REVIEW,
    color: '#FFBB55',
    count: 0,
    value: 0,
    statuses: []
  }
]

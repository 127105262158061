import styled from 'styled-components'
import { Input } from 'reactstrap'
import { PrimaryButton } from '../../../Components/global.styled'

export const CardTitle = styled.h3`
  color: #fff;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  margin: 0;
`

export const CommentsCard = styled.div`
  display: flex;
  flex: 1;
  padding: 24px 5px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 5px 5px 0 0;
  border: 1px solid var(--border-secondary, #5b6067);
`

export const CommentsNote = styled.span`
  color: #CCD4D8;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
`

export const CommentsContainerWrapper = styled.div`
  width: 100%;
  overflow: auto;

  & {
    &::-webkit-scrollbar {
      width: 8px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--border-secondary);
      border-radius: 10px;
      visibility: visible;
    }
  }
`

export const CommentsContainer = styled.div`
  max-height: 220px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const CommentsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const CommentItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

export const CommentItemInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`

export const CommentItemAuthor = styled.span`
  color: #CCD4D8;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const CommentItemDate = styled.span`
  color: #98A4AE;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
`

export const CommentItemText = styled.span`
  width: fit-content;
  max-width: 600px;
  display: flex;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #232327;
  border-radius: 5px;
  color: #FFF;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--border-secondary, #5b6067);
  border-top: none;
  border-radius: 0 0 5px 5px;
`

export const CommentInput = styled(Input)`
  width: 100%;
  height: 48px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #5B6067;
  background-color: #000000;
  border-radius: 5px;
  border: 1px solid #5B6067;

  &:focus {
    border: 1px solid #FFFFFF;
    background-color: var(--input-primary);
    color: var(--text-primary);
    box-shadow: none;
  }
`

export const CommentButtonContainer = styled.div`
  display: ${props => props.isTextInputFocused ? 'flex' : 'none'};
  gap: 12px;
  padding-top: 16px;
`

export const CommentButton = styled(PrimaryButton)`
  padding: 8px;
`

import React, { useState } from 'react'
import { Collapse } from 'reactstrap'
import Button from '../Button/Button'
import './css/collapse.css'

export default function NotesCollapse ({ changeNotesDetails }) {
  const [isOpen, setIsOpen] = useState(false)
  const isAdjusted = changeNotesDetails?.registrar
   && changeNotesDetails?.registrar.email && 
   changeNotesDetails.sectionUuid !== 'participation'

  if (isAdjusted) {
    const { reason, zendeskUrl, note } = changeNotesDetails
    return (
      <>
        <Collapse isOpen={isOpen}>
          <div className={`notes-collapse ${isOpen ? 'show' : ''}`}>

            {zendeskUrl && <span>
                Zendesk URL: &nbsp;&nbsp;
              <Button
                className={'btn-custom btn-tertiary'}
                onClick={() => window.open(zendeskUrl, '_blank')}
              >
                  View
              </Button>
            </span>}
            {reason && <span>{`Change Reason: ${reason}`}</span>}
            {note && <span>{`Note: ${note}.`}</span>}
          </div>
        </Collapse>
        <Button
          className={'btn-custom btn-tertiary for-note'}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? 'hide' : 'read'} Comments
        </Button>
      </>
    )
  }

  return (
    <>
      <Collapse isOpen={isOpen}>
        {changeNotesDetails?.map((question, index) => {
          const {
            correct,
            zendeskUrl,
            number,
            reason,
            note,
            isParticipation,
            statusNote
          } = question
          const changesStatement = correct ? 'Incorrect to Correct' : 'Correct to Incorrect'

          if (isParticipation) return null
          return (
            <div className={`notes-collapse ${isOpen ? 'show' : ''}`} key={index + '-collapse'}>
              {correct !== null && (
                <span className='question-details'>
                  {`Question ${number} changed ${changesStatement}`}
                </span>
              )}

              {zendeskUrl && <span>
                Zendesk URL: &nbsp;&nbsp;
                <Button
                  className={'btn-custom btn-tertiary'}
                  onClick={() => window.open(zendeskUrl, '_blank')}
                >
                  View
                </Button>
              </span>}
              {reason && <span>{`Change Reason: ${reason}`}</span>}
              {statusNote && <span>Status Note: {statusNote}</span>}
              {note && <span>{`Note: ${note}.`}</span>}
            </div>
          )
        })}
      </Collapse>
      <Button
        className={'btn-custom btn-tertiary for-note'}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'hide' : 'read'} Comments
      </Button>
    </>
  )
}

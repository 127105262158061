import React from 'react'
import {
  JumpToLink,
  LinkIcon,
  StudentAttemptsRecordsContainer,
  StudentAttemptsRecordsHeader,
  StudentAttemptsRecordsHeaderNumber,
  StudentAttemptsRecordsHeaderText,
  StudentAttemptsRow,
  StudentAttemptsRowContainer,
  StudentAttemptsRowHeader,
  StudentCohortRelationshipItem,
  StudentItemsContainer
} from './style'
import arrowDownIcon from '../../../assets/icons/arrow-down.svg'
import {
  getInProgressCohortsAndRelationships
} from './studentRecordDetailsUtils'

const StudentAttemptsSummary = ({ attempts, scrollToAttempts }) => {
  const { inProgressCohorts, relationships } =
    getInProgressCohortsAndRelationships(attempts)

  const attemptsCount = attempts.length
  const inProgressCohortsCount = inProgressCohorts.length
  const relationshipsCount = relationships.length
  const attemptText = attemptsCount === 1 ? 'Attempt record' : 'Attempt records'
  const cohortText = inProgressCohortsCount === 1 ? 'Cohort' : 'Cohorts'
  const relationshipText = relationshipsCount === 1
    ? 'Relationship' : 'Relationships'

  return (
    <StudentAttemptsRecordsContainer>
      <StudentAttemptsRecordsHeader>
        <StudentAttemptsRecordsHeaderNumber>
          {attemptsCount}
        </StudentAttemptsRecordsHeaderNumber>
        <StudentAttemptsRecordsHeaderText>
          {attemptText}
        </StudentAttemptsRecordsHeaderText>
      </StudentAttemptsRecordsHeader>
      <StudentAttemptsRowContainer>
        <StudentAttemptsRow>
          <StudentAttemptsRowHeader>
            {inProgressCohortsCount} {cohortText} in progress
          </StudentAttemptsRowHeader>
          <StudentItemsContainer>
            {inProgressCohorts.map((cohort, index) => {
              return (
                <StudentCohortRelationshipItem key={index}>
                  {cohort.cohortName}
                </StudentCohortRelationshipItem>
              )
            })
            }
          </StudentItemsContainer>
        </StudentAttemptsRow>
        <StudentAttemptsRow>
          <StudentAttemptsRowHeader>
            {relationshipsCount} {relationshipText} active
          </StudentAttemptsRowHeader>
          <StudentItemsContainer>
            {relationships.map((attempt, index) => {
              return (
                <StudentCohortRelationshipItem key={index}>
                  {attempt.relationshipName}
                </StudentCohortRelationshipItem>
              )
            })
            }
          </StudentItemsContainer>
        </StudentAttemptsRow>
      </StudentAttemptsRowContainer>
      <JumpToLink onClick={() => { scrollToAttempts() }}>Jump to attempts records
        <LinkIcon src={arrowDownIcon} alt='arrow-down-icon' />
      </JumpToLink>
    </StudentAttemptsRecordsContainer>
  )
}
StudentAttemptsSummary.displayName = 'StudentAttemptsSummary'
export default StudentAttemptsSummary

const operatorMapping = [
  {
    label: 'IS',
    value: 'is',
    supportsString: true,
    supportsOptions: true,
    supportsNumber: true,
    supportsDate: true,
    supportsBoolean: true
  },
  {
    label: 'Greater than',
    value: 'gt',
    supportsDate: true,
    supportsNumber: true
  },
  {
    label: 'Greater than or equal',
    value: 'gte',
    supportsDate: true,
    supportsNumber: true
  },
  {
    label: 'Less than',
    value: 'lt',
    supportsDate: true,
    supportsNumber: true
  },
  {
    label: 'Less than or equal',
    value: 'lte',
    supportsDate: true,
    supportsNumber: true
  },
  {
    label: 'Not equal',
    value: 'neq',
    supportsString: true,
    supportsOptions: true,
    supportsNumber: true
  },
  // {
  //   label: 'LIKE',
  //   value: 'like',
  //   supportsString: true,
  //   supportsNumber: true
  // },
  {
    label: 'contains',
    value: 'ilike',
    supportsString: true,
    supportsNumber: true
  },
  {
    label: 'does not contain',
    value: 'nilike',
    supportsString: true,
    supportsNumber: true
  },
  // {
  //   label: '~',
  //   value: 'match'
  // },
  // {
  //   label: '~*',
  //   value: 'imatch'
  // },
  {
    label: 'IN',
    value: 'in',
    supportsSimpleArray: true
  }
  // {
  //   label: 'IS DISTINCT FROM',
  //   value: 'isdistinct'
  // },
]

export default operatorMapping

import React, { useState } from 'react'
import { ModalWrapper } from './styles'
import ModalComponent from '../ModalComponent/ModalComponent'

function SmallDeviceWarning () {
  const [showModal, setShowModal] = useState(true)
  return (
    <ModalWrapper>
      <ModalComponent
        show={showModal}
        handleClose={() => setShowModal(false)}
        title='For the best experience, please open the Admin Dashboard on a
      desktop or laptop.'
      />
    </ModalWrapper>
  )
}

SmallDeviceWarning.displayName = 'SmallDeviceWarning'
export default SmallDeviceWarning

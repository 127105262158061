import React, { useEffect } from 'react'
import {
  AvailableStudentTokens,
  FormInput,
  FormLabel,
  FormTitle,
  Info,
  InfoText,
  PageWrapper,
  PaymentDetailsDescription,
  RecentOrder,
  RelationshipLabel,
  RemainingTokens,
  TokenBalance
} from '../style'
import RadioButtonSelector from '../../RadioButtonSelector'
import { CustomToolTip } from '../../Students/style'

const SHOP_ORDER_NUMBER_TOOLTIP = 'We will check the database and use the associated token or create the missing purchase and token record if necessary.'

const PaymentDetails = ({
  onBehalfOfPartner,
  selectedRelationship,
  isDegreePlusStudent,
  unusedStudentTokens,
  studentTokens,
  setShowNextStep,
  shopifyOrderNumber,
  setShopifyOrderNumber,
  tokenOption,
  setTokenOption,
  tokenOptionType,
  setTokenOptionType,
  paymentDetailsRef,
  partnerAvailableTokens
}) => {
  const studentTokensOptions = studentTokens
    ? Object.keys(studentTokens).map((token) => ({
      name: `${token} token`,
      value: token
    })) : []

  useEffect(() => {
    if (studentTokensOptions.length === 1 &&
      studentTokensOptions[0]?.value === 'Purchased') {
      setTokenOptionType({ tokenType: studentTokensOptions[0]?.value })
    }
  }, [setTokenOptionType, studentTokensOptions])

  const handleTokenOptionChange = (selectedOption) => {
    setTokenOption(selectedOption)
    setShopifyOrderNumber('')
  }

  const getTokensOptions = () => {
    return [
      {
        name: `Token balance · ${unusedStudentTokens.length} available`,
        value: 'tokenBalance',
        handler: () => (
          <TokenBalance>
            <RadioButtonSelector
              headerText={''}
              exposeKey='tokenType'
              options={studentTokensOptions}
              values={tokenOptionType}
              setValue={setTokenOptionType}
              noMargin
            />
          </TokenBalance>
        )
      },
      {
        name: 'Recent order',
        value: 'recentOrder',
        handler: () => (
          <RecentOrder>
            <CustomToolTip
              className='tooltip'
              multiline
              effect='solid'
              place='right'
            />
            <FormLabel>
              Shopify order number <Info data-tip={SHOP_ORDER_NUMBER_TOOLTIP} />
            </FormLabel>
            <FormInput
              type='text'
              name='shopifyOrderNumber'
              placeholder='Enter order number'
              onChange={(e) => setShopifyOrderNumber(e.target.value)}
              value={shopifyOrderNumber}
            />
          </RecentOrder>
        )
      }
    ]
  }

  const { partner: isPartner } = onBehalfOfPartner || {}
  const {
    label: relationshipName
  } = selectedRelationship || {}
  const isDegreeStudentEnrollment = isDegreePlusStudent
  const partnerRemainingTokens = partnerAvailableTokens - 1

  useEffect(() => {
    if (isDegreeStudentEnrollment || isPartner) {
      setShowNextStep(true)
      return
    }

    const { option } = tokenOption || {}
    const { tokenType } = tokenOptionType || {}

    if (option === 'tokenBalance' && (tokenType || unusedStudentTokens.length === 0)) {
      setShowNextStep(true)
      return
    }

    if (option === 'recentOrder' && shopifyOrderNumber) {
      setShowNextStep(true)
      return
    }

    setShowNextStep(false)
    // eslint-disable-next-line
  }, [isPartner, partnerAvailableTokens, tokenOptionType, unusedStudentTokens.length ,isDegreeStudentEnrollment, shopifyOrderNumber, tokenOption])

  if (isDegreeStudentEnrollment) {
    return (
      <PageWrapper ref={paymentDetailsRef}>
        <FormTitle>Payment details</FormTitle>
        <PaymentDetailsDescription>
          The system will automatically use or generate a token for the student.
          Golden Gate University manages the billing and payments.
        </PaymentDetailsDescription>
      </PageWrapper>
    )
  }

  if (isPartner) {
    return (
      <PageWrapper ref={paymentDetailsRef}>
        <FormTitle>Payment details</FormTitle>
        <RelationshipLabel>{relationshipName}</RelationshipLabel>
        <AvailableStudentTokens>
          {partnerAvailableTokens} Tokens available
        </AvailableStudentTokens>
        {partnerAvailableTokens === 0 ? (
          <PaymentDetailsDescription>
            By continuing enrollment, you agree to send ar@outlier.org
            an invoice request to bill the partner for 1 token.
          </PaymentDetailsDescription>
        ) : (
          <RemainingTokens>
            Balance after enrollment: {partnerRemainingTokens} Tokens
          </RemainingTokens>
        )}
      </PageWrapper>
    )
  }

  return (
    <PageWrapper ref={paymentDetailsRef}>
      <FormTitle>Payment details</FormTitle>
      <RadioButtonSelector
        headerText={'Would you like to use the student’s token balance or enroll from a recent order?'}
        exposeKey='option'
        options={getTokensOptions()}
        values={tokenOption}
        setValue={(selectedOption) => handleTokenOptionChange(selectedOption)}
        noMargin
      />
      {(tokenOption?.option === 'tokenBalance' && unusedStudentTokens.length === 0) && (
        <InfoText>
          Select “Token balance” to generate a new token
        </InfoText>
      )}
    </PageWrapper>
  )
}

export default PaymentDetails

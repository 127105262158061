import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ListLevel from './ListLevel'
import StudentLevel from './StudentLevel/StudentLevel'
import VoucherLevel from './VoucherLevel/VoucherLevel'
import {
  StyledContainer,
  Header,
  Separator
} from './styled'
import api from '../../api'

const Voucher = () => {
  const [students, setStudents] = useState({
    isLoading: false,
    records: []
  })
  const { studentId, voucherId } = useParams()

  useEffect(() => {
    fetchStudents()
  }, [])

  const fetchStudents = async () => {
    setStudents({ isLoading: true, records: [] })
    const students = await api.getTuitionVouchers()
    setStudents({ isLoading: false, records: students || [] })
  }

  const getComponentByType = () => {
    let Comp = ListLevel

    if (studentId) Comp = StudentLevel
    if (studentId && voucherId) Comp = VoucherLevel

    return (
      <Comp
        students={students}
        studentId={studentId}
        voucherId={voucherId}
      />
    )
  }

  return (
    <StyledContainer>
      <Header>Voucher Admin</Header>
      <Separator />
      {getComponentByType()}
    </StyledContainer>
  )
}

export default Voucher

import React, { useEffect, useState } from 'react'
import {
  NavigationIcon,
  PageIndex,
  PageIndicator,
  PaginationContainer
} from './style'
import previousIcon from '../../assets/icons/back-arrow-caret.svg'
import nextIcon from '../../assets/icons/front-arrow-caret.svg'
import { getPageList } from '../AssessmentExtensions/util/PaginationBarUtils'

const PaginationBar = ({
  recordsCount,
  numberOfPages,
  pageChangeHandler
}) => {
  const [selectedPage, setSelectedPage] = useState(1)
  const [pagesLayout, setPagesLayout] = useState([])
  recordsCount = numberOfPages > 50 ? 500 : recordsCount
  numberOfPages = numberOfPages > 50 ? 50 : numberOfPages
  const startingPageIndex = 1

  useEffect(() => {
    if (numberOfPages.length < 1) return
    setSelectedPage(startingPageIndex)
    changePage(startingPageIndex)
    // eslint-disable-next-line
  }, [numberOfPages])

  const changePage = (page) => {
    if (page === 0) return
    pageChangeHandler(page)
    setSelectedPage(page)
    const pages = getPageList(numberOfPages, page, 5)
    setPagesLayout(pages)
  }
  const getPagesIndexes = () => {
    if (numberOfPages === 0) return [0, 0]
    const pageFirstItemIndex = 1 + (10 * selectedPage) - 10
    const projectedLastItemIndex = pageFirstItemIndex + 9
    const pageLastItemIndex = projectedLastItemIndex > recordsCount
      ? recordsCount
      : projectedLastItemIndex
    return [pageFirstItemIndex, pageLastItemIndex]
  }

  const [pageFirstItemIndex, pageLastItemIndex] = getPagesIndexes()
  const isNotFirstPage = selectedPage !== 1
  const isNotLastPage = selectedPage !== numberOfPages
  return (
    <PaginationContainer>
      {isNotFirstPage &&
        <NavigationIcon
          src={previousIcon}
          alt='previous-icon'
          onClick={() => { changePage(selectedPage - 1) }}
        />
      }
      {
        pagesLayout.map((pageNumber, index) => {
          const isCurrentPage = selectedPage === pageNumber
          const isBreak = pageNumber === 0
          return (
            <PageIndex
              isCurrentPage={isCurrentPage}
              onClick={() => changePage(pageNumber)}
              disabled={isBreak || isCurrentPage}
              isBreak={isBreak}
              key={index}
            >
              {isBreak ? '...' : pageNumber}
            </PageIndex>
          )
        })
      }
      {isNotLastPage &&
        <NavigationIcon
          src={nextIcon}
          alt='next-icon'
          onClick={() => { changePage(selectedPage + 1) }}
        />
      }
      <PageIndicator>
        {pageFirstItemIndex}-{pageLastItemIndex} of {recordsCount} items
      </PageIndicator>
    </PaginationContainer>
  )
}

PaginationBar.displayName = 'PaginationBar'
export default PaginationBar

import {
  EXAM_1,
  EXAM_2,
  EXAM_3,
  EXAM_4,
  FINAL_EXAM,
  FINAL_EXAM_A,
  FINAL_EXAM_I,
  FINAL_EXAM_II,
  MIDTERM_EXAM_1,
  MIDTERM_EXAM_1A,
  MIDTERM_EXAM_2,
  MIDTERM_EXAM_2A,
  PRACTICE_EXAM,
  VERSIONS
} from '../../../Constants/examTypes'
import { getCourseIds } from '../../../config'
import { getIsExtendedCohort } from '../../../utilities/cohortUtils'

export const formatTag = str => {
  return str.replace(/\s+/g, '')
}

export const isPhilosophyCourse = cohortName => {
  if (!cohortName) return false

  return cohortName.toLowerCase().includes('philosophy')
}

export const addExamsInCourse = ({
  examChapters,
  courseUUID,
  isExamGradeBook
}) => {
  const { philosophy } = getCourseIds()
  const isPhilosophy = courseUUID === philosophy
  const allExams = []
  examChapters
    .forEach(exam => {
      const examVersions = exam.examVersions
      const isCreateVersions = examVersions.length &&
       isPhilosophy && isExamGradeBook

      if (isCreateVersions) {
        const allVersionExams = examVersions.map((examVersion, index) =>
          ({
            label: `${exam.title}${VERSIONS[index + 1]}`,
            value: examVersion.uuid
          }))
        const firstExamVersion = {
          label: `${exam.title}${VERSIONS[0]}`,
          value: exam.chapter_uuid
        }
        allExams.push(firstExamVersion, ...allVersionExams)
        return allExams
      }
      allExams.push({
        label: exam.title,
        value: exam.chapter_uuid
      })
    })
  return allExams
}

export const getExamEndTime = (exam, cohort) => {
  const isExtendedCohort = getIsExtendedCohort(cohort)

  const {
    midTerm1EndTime,
    midTerm2EndTime,
    exam3EndTime,
    exam4EndTime,
    exam5EndTime,
    finalExamEndTime
  } = cohort || {}

  const mapping = {
    [PRACTICE_EXAM]: finalExamEndTime,
    [MIDTERM_EXAM_1]: midTerm1EndTime,
    [MIDTERM_EXAM_1A]: midTerm1EndTime,
    [MIDTERM_EXAM_2]: midTerm2EndTime,
    [MIDTERM_EXAM_2A]: midTerm2EndTime,
    [FINAL_EXAM]: finalExamEndTime,
    [FINAL_EXAM_A]: finalExamEndTime
  }

  const extendedMapping = {
    [PRACTICE_EXAM]: finalExamEndTime,
    [EXAM_1]: midTerm1EndTime,
    [EXAM_2]: midTerm2EndTime,
    [EXAM_3]: exam3EndTime,
    [EXAM_4]: exam4EndTime,
    [FINAL_EXAM_I]: exam5EndTime,
    [FINAL_EXAM_II]: finalExamEndTime
  }

  if (isExtendedCohort) return extendedMapping[exam]

  return mapping[exam]
}

import React, { useState, useMemo, useEffect, useRef } from 'react'
import { Collapse } from 'reactstrap'
import TablePaginator from './TablePaginator'
import { NUMBER_OF_ROWS_PER_PAGE } from './constants'
import filledRightArrow from '../../assets/icons/icon-filled-right-arrow.svg'
import {
  ReviewTableWrapper,
  ReviewTitle,
  MessageAndLinkWrapper,
  Message,
  DownloadCSVLink,
  TableSlidersContainer,
  FilledSliderArrow,
  GradientOverlay,
  TableWrapper,
  Table,
  TableHeader,
  TableRow,
  NameCell,
  DurationCell,
  DateCell,
  ScheduleCell,
  SortButtonsWrapper,
  SortAscendingButton,
  SortDescendingButton
} from './styled'

const ReviewTable = ({ tableData }) => {
  const [isOpen, setIsOpen] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortInfo, setSortInfo] = useState({
    sortBy: 'name',
    ascending: true
  })
  const [tableHorizontalScroll, setTableHorizontalScroll] = useState(0)
  const [shouldRightScrollDisable, setShouldRightScrollDisable] = useState(false)
  const tableWrapperRef = useRef()
  const tableRef = useRef()

  useEffect(() => {
    const tableWrapper = tableWrapperRef.current
    setShouldRightScrollDisable(Math.abs(
      tableWrapper.scrollWidth -
      tableWrapper.clientWidth -
      tableWrapper.scrollLeft) < 2
    )

    const onScroll = () => {
      const maxScroll = tableWrapper.scrollWidth - tableWrapper.clientWidth
      const currentScroll = tableWrapper.scrollLeft

      if (Math.abs(currentScroll - maxScroll) < 2) {
        setShouldRightScrollDisable(true)
      } else {
        setShouldRightScrollDisable(false)
      }

      setTableHorizontalScroll(tableWrapper.scrollLeft)
    }

    tableWrapper.addEventListener('scroll', onScroll)
    window.addEventListener('resize', onScroll)

    return () => {
      tableWrapper.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onScroll)
    }
  }, [])

  const rows = useMemo(() => {
    return tableData
      .sort((a, b) => {
        if (sortInfo.sortBy === 'name') {
          return sortInfo.ascending
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name)
        }
        if (sortInfo.sortBy === 'startDate') {
          return sortInfo.ascending
            ? new Date(a.startDate) - new Date(b.startDate)
            : new Date(b.startDate) - new Date(a.startDate)
        }
        if (sortInfo.sortBy === 'endDate') {
          return sortInfo.ascending
            ? new Date(a.endDate) - new Date(b.endDate)
            : new Date(b.endDate) - new Date(a.endDate)
        }
        return 0
      })
      .slice((currentPage - 1) *
        NUMBER_OF_ROWS_PER_PAGE, currentPage *
        NUMBER_OF_ROWS_PER_PAGE)
  }, [sortInfo, currentPage, tableData])

  const shouldScrollLeftDisable = () => {
    return tableHorizontalScroll === 0
  }

  const onSort = (sortBy, ascending) => setSortInfo({ sortBy, ascending })
  const onScrollButtonClick = (type) => {
    const tableWrapper = tableWrapperRef.current
    if (type === 'left') {
      return tableWrapper.scrollBy({
        left: -100,
        behavior: 'smooth'
      })
    }

    tableWrapper.scrollBy({
      left: 100,
      behavior: 'smooth'
    })
  }

  return (
    <ReviewTableWrapper>
      <ReviewTitle isOpen={isOpen} onClick={() => setIsOpen(prev => !prev)}>
        Cohort Breakdown
      </ReviewTitle>
      <Collapse isOpen={isOpen}>
        <MessageAndLinkWrapper>
          <Message>Please review the cohort details.</Message>
          <DownloadCSVLink>Download CSV</DownloadCSVLink>
          <TableSlidersContainer>
            <FilledSliderArrow
              src={filledRightArrow}
              alt='filled-slider-arrow-icon'
              disabled={shouldScrollLeftDisable()}
              onClick={() => onScrollButtonClick('left')}
              rotate='left'
            />
            <FilledSliderArrow
              src={filledRightArrow}
              alt='filled-slider-arrow-icon'
              disabled={shouldRightScrollDisable}
              onClick={onScrollButtonClick}
            />
          </TableSlidersContainer>
        </MessageAndLinkWrapper>
        <GradientOverlay>
          <TableWrapper ref={tableWrapperRef} >
            <Table ref={tableRef} >
              <TableHeader>
                <NameCell isHeaderCell>
                  <span>Name</span>
                  <SortButtonsWrapper>
                    <SortAscendingButton
                      onClick={() => onSort('name', false)}
                      selected={
                        sortInfo.sortBy === 'name' && !sortInfo.ascending
                      }
                    />
                    <SortDescendingButton
                      onClick={() => onSort('name', true)}
                      selected={
                        sortInfo.sortBy === 'name' && sortInfo.ascending
                      }
                    />
                  </SortButtonsWrapper>
                </NameCell>
                <DurationCell isHeaderCell>
                  <span>Duration</span>
                </DurationCell>
                <DateCell isHeaderCell>
                  <span>Start Date</span>
                  <SortButtonsWrapper>
                    <SortAscendingButton
                      onClick={() => onSort('startDate', false)}
                      selected={
                        sortInfo.sortBy === 'startDate' && !sortInfo.ascending
                      }
                    />
                    <SortDescendingButton
                      onClick={() => onSort('startDate', true)}
                      selected={
                        sortInfo.sortBy === 'startDate' && sortInfo.ascending
                      }
                    />
                  </SortButtonsWrapper>
                </DateCell>
                <DateCell isHeaderCell>
                  <span>End Date</span>
                  <SortButtonsWrapper>
                    <SortAscendingButton
                      onClick={() => onSort('endDate', false)}
                      selected={
                        sortInfo.sortBy === 'endDate' && !sortInfo.ascending
                      }
                    />
                    <SortDescendingButton
                      onClick={() => onSort('endDate', true)}
                      selected={
                        sortInfo.sortBy === 'endDate' && sortInfo.ascending
                      }
                    />
                  </SortButtonsWrapper>
                </DateCell>
                <ScheduleCell isHeaderCell>
                  <span>Schedule</span>
                </ScheduleCell>
              </TableHeader>
              {rows.map((row, index) => (
                <TableRow key={row.name + index}>
                  <NameCell>{row.name}</NameCell>
                  <DurationCell>{row.duration}</DurationCell>
                  <DateCell>{row.startDate}</DateCell>
                  <DateCell>{row.endDate}</DateCell>
                  <ScheduleCell>{row.schedule}</ScheduleCell>
                </TableRow>
              ))}
            </Table>
          </TableWrapper>
        </GradientOverlay>
        <TablePaginator
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rows={tableData}
          rowsPerPage={NUMBER_OF_ROWS_PER_PAGE}
        />
      </Collapse>
    </ReviewTableWrapper>
  )
}

ReviewTable.displayName = 'ReviewTable'
export default ReviewTable

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button, ButtonGroup, FormGroup, Input, InputGroup, InputGroupAddon, Label
} from 'reactstrap'
import { useStudentGradeContext } from '../../contexts/StudentGradeContext'

let flag = false
export default function PaginationBar (props) {
  const {
    entriesPerPage
  } = useStudentGradeContext()
  const [selectedPage, handlePage] = useState(1)
  const [visiblePages, handleVisiblePages] = useState([])
  const {
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    handleSearch,
    searchByGrade,
    text,
    state: { pageSize },
    handleGrade,
    grade
  } = props
  const paginationRanges = [25, 50, 75, 100]

  useEffect(() => {
    const { value } = entriesPerPage || {}
    if (!value) return

    setPageSize(entriesPerPage.value)
    // eslint-disable-next-line
  }, [entriesPerPage])

  const navigationPages = data => {
    if (data) {
      return data.map((page, index, array) => {
        return <Button key={index} className='pagination-button'
          disabled={selectedPage === index}
          onClick={() => changePage(index + 1)}
        >
          {array[index - 1] + 2 < page ? `...${page}` : page}
        </Button>
      })
    } else {
      !flag && setPageVisibility(null, pageCount)
      flag = true
    }
  }

  useEffect(() => {
    setPageVisibility(null, pageCount)
    // eslint-disable-next-line
  }, [pageCount])

  const setPageVisibility = (page, total) => {
    handlePage(1)
    const data = getVisiblePages(page, total)
    handleVisiblePages(data)
  }

  const getVisiblePages = (page, total) => {
    if (page % 3 >= 0 && page > 2 && page + 1 < total) {
      return [page - 1, page, page + 1]
    } else if (page % 3 >= 0 && page > 2 && page + 1 >= total) {
      return [total - 2, total - 1, total]
    } else {
      return total >= 3 ? [1, 2, 3] : total > 1 ? [1, 2] : [1]
    }
  }

  const changePage = (page) => {
    setPageVisibility(page, pageCount)
    gotoPage(page - 1)
    handlePage(page)
  }

  const moveNextPage = () => {
    setPageVisibility(selectedPage + 1, pageCount)
    handlePage(selectedPage + 1)
    nextPage()
  }

  const movePreviousPage = () => {
    setPageVisibility(selectedPage - 1, pageCount)
    handlePage(selectedPage - 1)
    previousPage()
  }

  const moveFirstPage = () => {
    setPageVisibility(1, pageCount)
    handlePage(1)
    gotoPage(0)
  }

  const moveLastPage = () => {
    setPageVisibility(pageCount, pageCount)
    gotoPage(pageCount - 1)
    handlePage(pageCount)
  }

  const changePageRecords = page => {
    setPageSize(page)
  }

  const search = () => {
    return (
      <div className='form-control-wrapper'>
        <Label className='input-label'>Search by keyword:</Label>
        <InputGroup className='search-bar'>
          <Input
            value={text || ''}
            onChange={e => {
              handleSearch(e.target.value || undefined)
            }}
            placeholder='Enter text...'
          />
          <InputGroupAddon
            addonType='append'
          >
            <Button className='search-button'
              onClick={() => handleSearch('')}
            >
              x
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    )
  }

  const searchGrade = () => {
    return (
      <div className='form-control-wrapper'>
        <Label className='input-label'>Search by grade:</Label>
        <InputGroup className='grade-search-bar'>
          <Input value={grade} type='number'
            onChange={e => handleGrade(e.target.value)}
            placeholder='Enter Grade...' />
          <InputGroupAddon addonType='append'>
            <Button className='search-button'
              onClick={() => handleGrade('')}>
              x
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    )
  }

  const pagination = () => {
    !flag && navigationPages()
    return (
      <ButtonGroup className='pt-4'>
        <Button className='pagination-button' onClick={moveFirstPage}
          disabled={selectedPage === 1}>
          First
        </Button>
        <Button className='pagination-button' disabled={selectedPage === 1}
          onClick={movePreviousPage}
        >
          Prev
        </Button>
        {
          visiblePages.map((page, index, array) => {
            return <Button key={index} className='pagination-button'
              disabled={selectedPage === page}
              onClick={() => changePage(page)}
            >
              {array[index - 1] + 2 < page ? `${page}` : page}
            </Button>
          })
        }
        <Button className='pagination-button'
          disabled={selectedPage === pageCount || !pageCount}
          onClick={moveNextPage}
        >
          Next
        </Button>
        <Button className='pagination-button'
          disabled={selectedPage === pageCount || !pageCount}
          onClick={moveLastPage}
        >
          Last
        </Button>
      </ButtonGroup>
    )
  }

  return (
    <div className='pagination-bar'>
      {search()}
      {searchByGrade && searchGrade()}
      <div className='page-entries'>
        <p>Show</p>
        <FormGroup>
          <Input type='select' name='select' id='exampleSelect'
            value={pageSize}
            onChange={e => changePageRecords(Number(e.target.value))}
          >
            {paginationRanges.map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Input>
        </FormGroup>
        <p>entries</p>
      </div>
      {pagination()}
    </div>
  )
}

PaginationBar.propTypes = {
  searchByGrade: PropTypes.bool
}

PaginationBar.defaultProps = {
  searchByGrade: true
}

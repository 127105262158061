import { range } from 'lodash'

// Returns an array of maxLength (or less) page numbers
// where a 0 in the returned array denotes a gap in the series.
// Parameters:
//   totalPages:     total number of pages
//   page:           current page
//   maxLength:      maximum size of returned array
export function getPageList (totalPages, page, maxLength) {
  const sideWidth = 1; const leftWidth = 1; const rightWidth = 1

  if (totalPages <= maxLength) {
    // no breaks in list
    return range(1, totalPages + 1)
  }
  if (page <= maxLength - 3) {
    // no break on left of page
    return range(1, maxLength - 1)
      .concat(0, range(totalPages - sideWidth + 1, totalPages + 1))
  }
  if (page >= totalPages - 2) {
    // no break on right of page
    return range(1, sideWidth + 1)
      .concat(0, range(totalPages - 3, totalPages + 1))
  }
  // breaks on both sides
  return range(1, sideWidth + 1)
    .concat(0, range(page - leftWidth, page + rightWidth + 1),
      0, range(totalPages - sideWidth + 1, totalPages + 1))
}

import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import MultipleValueTextInput from '../MultipleValueTextInput/MultipleValueTextInput'
import api from '../../api'
import { isValidUrl } from '../../utilities/validationUtils'
import { validateEmail } from '../../utilities/userUtils'
import { tooltipTexts, getTooltipText } from './utils/tooltipUtils'
import { Modal } from 'reactstrap'
import { COURSE_ACCESS_TYPES } from './constants'
import {
  relationshipTypes,
  accessTypes,
  institutions,
  returnTos,
  signingPartnerNameOptions,
  refunds,
  partnerTypeOptions
} from '../../Constants/relationships'
import {
  ConfirmationModal,
  ModalText,
  ModalOKButton,
  Label,
  StandAloneLabel,
  Input,
  Select,
  AsyncSelectComponent,
  componentStyles,
  DatePicker,
  CurrencyInput,
  CourseAccessSection,
  Button
} from './styles'
import {
  Container,
  HeaderContainer,
  Header,
  Separator,
  DatePickerWrapper,
  CustomCheckbox
} from '../Students/style'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { ReactComponent as RemoveIcon } from '../../assets/icons/modal-close.svg'

function AddRelationships () {
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidLink, setInvalidLink] = useState(false)
  const [isRequestLoading, setIsRequestLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [successModal, setSuccessModal] = useState(false)
  const [formState, setformState] = useState({
    test: false,
    hideTOS: false,
    liveProctoring: true,
    partnerDocumentsLink: null,
    tokenExpirationDate: null,
    tokenPrice: '400',
    purchasedSeats: null,
    relationshipOtherEmails: null
  })

  const getDistrictNameOptions = async () => {
    const data = await api.getDataTableMetadata({ tableName: 'relationships' })
    const districtNames =
      data.find(({ camelCase }) => camelCase === 'districtName')
    return districtNames?.options.map((value) => ({ value, label: value }))
  }

  useEffect(() => {
    const { relationshipAdminEmail, partnerDocumentsLink } = formState

    if (partnerDocumentsLink) {
      setInvalidLink(!isValidUrl(partnerDocumentsLink))
    }

    if (relationshipAdminEmail) {
      setInvalidEmail(!validateEmail(relationshipAdminEmail))
    }
    // eslint-disable-next-line
  }, [formState.partnerDocumentsLink, formState.relationshipAdminEmail])

  function inputChangeHandler (key, value) {
    setformState((prevState) => ({
      ...prevState,
      [key]: value
    }))
  }

  function keyPressHandler (event) {
    if (['-', 'e', 'E'].includes(event.key)) {
      event.preventDefault()
    }
  }

  function shouldBtnDisable () {
    const {
      test,
      hideTOS,
      partnerDocumentsLink,
      tokenExpirationDate,
      purchasedSeats,
      tokenPrice,
      relationshipOtherEmails,
      districtName,
      signingPartnerName,
      partnerType,
      enrollmentOptions,
      ...rest } = formState
    const keys = Object.keys(rest)
    const invalidUrl = (invalidLink && formState.partnerDocumentsLink.length)
    const invalidEntries = !formState.relationshipName?.length ||
     !formState.displayName?.length ||
     !formState.relationshipAdminEmail?.length ||
     !formState.relationshipStartDate?.length

    const hasEnrollmentOptions = enrollmentOptions?.length

    if (
      keys.length !== 10 ||
      invalidUrl ||
      invalidEmail ||
      invalidEntries ||
      !hasEnrollmentOptions
    ) return true
  }

  async function onAddRelnshipBtnClick () {
    const {
      accessType,
      relationshipName,
      relationshipType,
      returnTo,
      refundability,
      creditGrantingInstitution,
      tokenPrice,
      districtName,
      signingPartnerName,
      partnerType,
      purchasedSeats,
      enrollmentOptions
    } = formState

    const body = {
      ...formState,
      accessType: accessType.value,
      creditGrantingInstitution: creditGrantingInstitution?.value,
      refundability: refundability.value,
      relationshipType: relationshipType.value,
      returnTo: returnTo.value,
      ...(districtName ? { districtName: districtName.value } : {}),
      ...(signingPartnerName ? { signingPartnerName: signingPartnerName.value } : {}),
      ...(partnerType ? { partnerType: partnerType.value } : {}),
      tokenPrice: tokenPrice?.length ? Number(tokenPrice) : null,
      purchasedSeats: purchasedSeats?.length ? Number(purchasedSeats) : null,
      ...(enrollmentOptions?.length ? { enrollmentOptions } : {})
    }

    setformState({
      relationshipName,
      cohorts: null,
      test: false,
      hideTOS: false
    })

    setIsRequestLoading(true)
    const data = await api.addRelationship(body)
    if (!data) return setIsError(true)
    setSuccessModal(true)
    return setIsRequestLoading(false)
  }

  function getOptions (optionsObj) {
    return Object.values(optionsObj)
  }

  const onEnrollmentOptionsSelect = (option) => {
    setformState(prev => {
      if (prev.enrollmentOptions?.includes(option)) {
        return {
          ...prev,
          enrollmentOptions: prev.enrollmentOptions.filter(
            enrollmentOption => enrollmentOption !== option
          )
        }
      }

      return {
        ...prev,
        enrollmentOptions: [...(prev.enrollmentOptions || []), option]
      }
    })
  }

  const startDate = formState.relationshipStartDate
    ? new Date(formState.relationshipStartDate)
    : null
  const tokenExpirationDate = formState.tokenExpirationDate
    ? new Date(formState.tokenExpirationDate)
    : null

  const { expiryDate, returnTo, refund } = tooltipTexts

  if (isError) return null
  if (isRequestLoading) return <LoadingSpinner />

  return (
    <Container>
      <Modal isOpen={successModal} centered>
        <ConfirmationModal>
          <ModalText>
            <div>
              `{formState.relationshipName}` has been successfully created.
            </div>
            <div>
              Click OK to return to Manage Relationships page.
            </div>
          </ModalText>
          <div>
            <a href='#/relationships'>
              <ModalOKButton
                data-testid='ok-button'
              >
                OK
              </ModalOKButton>
            </a>
          </div>
        </ConfirmationModal>
      </Modal>
      <ReactTooltip
        effect='solid'
        place='right'
        textColor='white'
        html
        scrollHide
      />
      <HeaderContainer>
        <Header>add new relationship</Header>
      </HeaderContainer>
      <Separator />
      <Label htmlFor='name'>
        Relationship Name
        <Input
          id='relationshipName'
          type='text'
          value={formState.relationshipName}
          onChange={(e) =>
            inputChangeHandler('relationshipName', e.target.value)
          }
        />
      </Label>
      <Label htmlFor='display-name'>
        Display Name
        <Input
          id='displayName'
          type='text'
          value={formState.displayName}
          onChange={(e) => inputChangeHandler('displayName', e.target.value)}
        />
      </Label>
      <StandAloneLabel>Relationship Type</StandAloneLabel>
      <Select
        styles={componentStyles}
        placeholder=''
        value={formState.relationshipType}
        options={getOptions(relationshipTypes)}
        onChange={(course) => inputChangeHandler('relationshipType', course)}
      />
      <StandAloneLabel>Access Type</StandAloneLabel>
      <Select
        styles={componentStyles}
        placeholder=''
        value={formState.accessType}
        options={getOptions(accessTypes)}
        onChange={(type) => inputChangeHandler('accessType', type)}
      />
      <StandAloneLabel>Start Date</StandAloneLabel>
      <DatePickerWrapper>
        <DatePicker
          placeholderText='MM/DD/YYYY'
          selected={startDate}
          onChange={(date) =>
            setformState((prevState) => ({
              ...prevState,
              relationshipStartDate: date?.toISOString().slice(0, 10)
            }))
          }
        />
      </DatePickerWrapper>
      <Label htmlFor='relationshipAdminEmail'>
        Admin Email
        <Input
          id='relationshipAdminEmail'
          invalid={invalidEmail}
          value={formState.relationshipAdminEmail}
          type='text'
          onChange={(e) => inputChangeHandler('relationshipAdminEmail', e.target.value)}
        />
      </Label>
      <Label htmlFor='relationshipOtherEmails'>
        Other Partner Emails
        <MultipleValueTextInput
          onItemAdded={(item, allItems) =>
            inputChangeHandler('relationshipOtherEmails', allItems.join('\n'))}
          onItemDeleted={(item, allItems) =>
            inputChangeHandler('relationshipOtherEmails', allItems.join('\n'))}
          name='relationshipOtherEmails'
          deleteButton={<RemoveIcon />}
        />
      </Label>
      <Label htmlFor='partnerDocumentsLink'>
        Partner Documents Folder
        <Input
          id='partnerDocumentsLink'
          invalid={(invalidLink && formState.partnerDocumentsLink.length)}
          value={formState.partnerDocumentsLink}
          type='text'
          onChange={(e) => inputChangeHandler('partnerDocumentsLink', e.target.value)}
        />
      </Label>
      <StandAloneLabel>Credit Granting Institution</StandAloneLabel>
      <Select
        data-testid='test-creditGrantingInstitution'
        styles={componentStyles}
        placeholder=''
        options={getOptions(institutions)}
        value={formState.creditGrantingInstitution}
        onChange={(institution) =>
          inputChangeHandler('creditGrantingInstitution', institution)
        }
      />
      <StandAloneLabel>
        Token Expiration Date{' '}
        <span data-tip={getTooltipText(expiryDate.text1, expiryDate.text2)} />
      </StandAloneLabel>
      <DatePickerWrapper>
        <DatePicker
          placeholderText='MM/DD/YYYY'
          selected={tokenExpirationDate}
          onChange={(date) =>
            setformState((prevState) => ({
              ...prevState,
              tokenExpirationDate: date?.toISOString().slice(0, 10)
            }))
          }
        />
      </DatePickerWrapper>
      <StandAloneLabel>
        Return To
        <span data-tip={getTooltipText(returnTo.text1, returnTo.text2)} />
      </StandAloneLabel>
      <Select
        styles={componentStyles}
        placeholder=''
        options={getOptions(returnTos)}
        value={formState.returnTo}
        onChange={(returnTo) => inputChangeHandler('returnTo', returnTo)}
      />
      <StandAloneLabel>
        Refunds
        <span data-tip={getTooltipText(refund.text1, refund.text2)} />
      </StandAloneLabel>
      <Select
        styles={componentStyles}
        placeholder=''
        options={getOptions(refunds)}
        value={formState.refundability}
        onChange={(refundability) =>
          inputChangeHandler('refundability', refundability)
        }
      />
      <Label htmlFor='purchasedSeats'>
        Purchased Seats
        <Input
          id='purchasedSeats'
          value={formState.purchasedSeats}
          type='number'
          onKeyPress={(event) => keyPressHandler(event)}
          onChange={(e) => inputChangeHandler('purchasedSeats', e.target.value)}
        />
      </Label>
      <Label htmlFor='tokenPrice'>
        Token Purchase Price
        <CurrencyInput
          id='tokenPrice'
          onValueChange={(value) => { inputChangeHandler('tokenPrice', value) }}
          value={formState.tokenPrice}
          prefix='$'
          reduced
        />
      </Label>
      <StandAloneLabel>
        District Name
      </StandAloneLabel>
      <AsyncSelectComponent
        styles={componentStyles}
        defaultOptions
        cacheOptions
        placeholder=''
        loadOptions={getDistrictNameOptions}
        value={formState.districtName}
        onChange={(districtName) =>
          inputChangeHandler('districtName', districtName)
        }
      />
      <StandAloneLabel>
        Signing PartnerName
      </StandAloneLabel>
      <Select
        styles={componentStyles}
        placeholder=''
        options={signingPartnerNameOptions}
        value={formState.signingPartnerName}
        onChange={(signingPartnerName) =>
          inputChangeHandler('signingPartnerName', signingPartnerName)
        }
      />
      <StandAloneLabel>
        Partner Type
      </StandAloneLabel>
      <Select
        styles={componentStyles}
        placeholder=''
        options={partnerTypeOptions}
        value={formState.partnerType}
        onChange={(partnerType) =>
          inputChangeHandler('partnerType', partnerType)
        }
      />
      <CourseAccessSection>
        Course Access
        {COURSE_ACCESS_TYPES.map(({ value, label, id }) => (
          <CustomCheckbox
            mb='0'
            key={value}
          >
            {label}
            <input
              type='checkbox'
              data-testid={value}
              id={id}
              name={label}
              checked={formState.enrollmentOptions?.includes(value) || false}
              onChange={() => onEnrollmentOptionsSelect(value)}
            />
            <span />
          </CustomCheckbox>
        ))}
      </CourseAccessSection>
      <CourseAccessSection>
        Others
        <CustomCheckbox
          mb='0'
        >
          In-person proctoring
          <input
            type='checkbox'
            data-testid='In-person-proctoring'
            id='In-person-proctoring'
            name='In-person-proctoring'
            checked={formState.liveProctoring}
            onChange={() =>
              setformState((prevState) => ({
                ...prevState,
                liveProctoring: !formState.liveProctoring
              }))
            }
          />
          <span />
        </CustomCheckbox>
        <CustomCheckbox
          mb='0'
        >
          Hide TOS
          <input
            type='checkbox'
            data-testid='Hide-TOS'
            id='Hide-TOS'
            name='Hide-TOS'
            checked={formState.hideTOS}
            onChange={() =>
              setformState((prevState) => ({
                ...prevState,
                hideTOS: !formState.hideTOS
              }))
            }
          />
          <span />
        </CustomCheckbox>
        <CustomCheckbox
          mb='0'
        >
          Test Relationship
          <input
            type='checkbox'
            data-testid='test-relationship'
            id='test-relationship'
            name='test-relationship'
            checked={formState.test}
            onChange={() =>
              setformState((prevState) => ({
                ...prevState,
                test: !formState.test
              }))
            }
          />
          <span />
        </CustomCheckbox>
      </CourseAccessSection>
      <Button
        data-testid='add-relationship'
        className='btn-custom btn-primary'
        disabled={shouldBtnDisable()}
        onClick={onAddRelnshipBtnClick}
      >
        add relationship
      </Button>
    </Container>
  )
}

AddRelationships.displayName = 'AddRelationships'
export default AddRelationships

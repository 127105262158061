import React, { useState, useEffect } from 'react'
import DraggableModalComponent from '../DraggableModalComponent'
import Select from 'react-select'
import {
  customSelectStyles,
  QuickViewBody,
  QuickViewFooter,
  QuickViewHeader,
  QuickViewSubmitButton,
  QuickViewTitle
} from './style'
import CloseButton from '../../assets/icons/modal-close.svg'
import { getStudentGrade } from '../../utilities/writingGradeCenterUtils'
import api from '../../api'

const QuickViewFeedbackModal = ({
  params,
  history,
  gradedAssignments,
  setShowQuickView
}) => {
  const [selectedAssignment, setSelectedAssignment] = useState(null)
  const [writingAssignments, setWritingAssignments] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const { courseId, studentEmail, cohortName } = params

  useEffect(() => {
    const fetchWritingAssignments = async () => {
      setIsLoading(true)
      const courseData = await api.getCourseData(courseId)
      if (!courseData || !courseData.chapters || !courseData.chapters.length) {
        return {}
      }
      const writingAssignments = courseData.chapters
        .filter(chapter => chapter.type === 'WritingAssignmentChapterRecord')
        .map(writingAssignment => ({
          ...writingAssignment,
          assignmentUUID: writingAssignment.chapter_uuid,
          label: writingAssignment.title,
          value: writingAssignment.chapter_uuid
        }))
      setWritingAssignments(writingAssignments)
      setIsLoading(false)
    }
    if (!courseId) return

    fetchWritingAssignments()
  }, [courseId])

  const studentGrade =
    selectedAssignment &&
    getStudentGrade(selectedAssignment.grade, selectedAssignment.maxScore)
  const studentGradedAssignments =
    gradedAssignments &&
    gradedAssignments
      .map(assignment => {
        if (!writingAssignments) return null
        const currentAssignment = writingAssignments.find(
          a => a.assignmentUUID === assignment.assignmentUUID
        )

        return { ...assignment, ...currentAssignment }
      })
      .filter(Boolean)

  const handleViewSubmissionClick = () => {
    if (!selectedAssignment) return

    const { assignmentUUID } = selectedAssignment
    history.push(
      `/student-submission/${courseId}/${cohortName}/${assignmentUUID}/${studentEmail}`
    )
  }

  return (
    <DraggableModalComponent
      width={340}
      height={464}
      defaultPosition={{ right: 64, top: 104 }}
    >
      <QuickViewHeader>
        <Select
          options={studentGradedAssignments}
          value={selectedAssignment}
          onChange={assignment => {
            setSelectedAssignment(assignment)
          }}
          id='quickViewAssignmentSelect'
          isLoading={isLoading}
          placeholder={isLoading ? 'Loading' : 'Select an assignment'}
          styles={customSelectStyles}
        />
        <img
          onClick={() => setShowQuickView(false)}
          src={CloseButton}
          data-testid='quickViewCloseBtn'
          alt='close button'
        />
      </QuickViewHeader>
      <QuickViewBody>
        <QuickViewTitle>
          Grade: <span>{studentGrade}</span>
        </QuickViewTitle>
        <QuickViewTitle>Feedback:</QuickViewTitle>
        <div
          id='quickViewFeedback'
          dangerouslySetInnerHTML={{ __html: selectedAssignment?.feedback ?? '' }}
        />
      </QuickViewBody>
      <QuickViewFooter>
        <QuickViewSubmitButton
          disabled={!selectedAssignment}
          onClick={handleViewSubmissionClick}
          type='submit'
        >
          go to submission
        </QuickViewSubmitButton>
      </QuickViewFooter>
    </DraggableModalComponent>
  )
}

QuickViewFeedbackModal.displayName = 'QuickViewFeedbackModal'
export default QuickViewFeedbackModal

import styled from 'styled-components'

export const LayoutHeader = styled.div`
  height: 64px;
`
export const Content = styled.div`
  flex: 1;
  background: var(--canvas-primary);
  color: var(--text-primary);
  overflow: auto;
  height: calc(100vh - 64px);
  padding-bottom: ${({ hasNoBottomPadding }) => hasNoBottomPadding ? '0' : '64px'};
`

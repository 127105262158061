import React, { useEffect, useRef, useState } from 'react'
import {
  HideTools, ModalButtonContainer, ModalContent,
  RoleDescription,
  Table,
  TableCell,
  TableHeader,
  TableRow,
  ToolItem,
  ToolsList,
  ViewTools
} from './styles/index.styled'
import chevronDown from '../../assets/icons/chevron-down2.svg'
import RoleUsersModal from './RoleUsersModal'
import ModalComponent from '../../Components/ModalComponent/ModalComponent'
import Button from '../../Components/Button/Button'
import api from '../../api'
import Toast from '../../Components/ToastComponent/Toast'
import { SuccessIcon } from '../../Components/Guild/style'

const RolesTable = ({ roles, setUsers, setSelectedUser }) => {
  const [showTools, setShowTools] = useState(false)
  const [selectedRole, setSelectedRole] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const toast = useRef()
  const [userToRemove, setUserToRemove] = useState(null)

  useEffect(() => {
    if (!userToRemove) return
    setShowDeleteModal(true)
  }, [userToRemove])

  const handleDeleteUser = async () => {
    const { email } = userToRemove
    const response = await api.deleteAdmin(email)
    if (response.success) {
      const { data } = await api.getAllAdminsV2()
      setUsers(data)
    }
    const { display } = toast.current || {}
    display && display(
      response?.success
        ? <><SuccessIcon /> User successfully removed!</>
        : <>Error adding user</>
    )
  }

  return (
    <>
      <Toast ref={toast} />
      {selectedRole && <RoleUsersModal
        setUserToRemove={setUserToRemove}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        setSelectedUser={setSelectedUser}
      />}
      <ModalComponent
        width='455px'
        show={showDeleteModal}
        title='Remove user'
        handleClose={() => setShowDeleteModal(false)}
      >
        <ModalContent style={{ background: '#25272B' }}>
          Are you sure you want to remove this user from the Admin Dashboard?
          <ModalButtonContainer>
            <Button
              style={{ padding: '16px 24px', height: '48px' }}
              onClick={() => handleDeleteUser() && setShowDeleteModal(false)}
              className={{
                'btn-custom': true,
                'btn-primary': true
              }}>
              Yes, remove
            </Button>
            <Button
              style={{ padding: '16px 24px', height: '48px', width: 'auto' }}
              onClick={() => setShowDeleteModal(false)}
              className={{
                'btn-custom': true,
                'btn-secondary': true
              }}>
              Cancel
            </Button>
          </ModalButtonContainer>
        </ModalContent>
      </ModalComponent>
      <Table>
        <thead>
          <TableRow>
            <TableHeader inline>Role</TableHeader>
            <TableHeader inline>Description</TableHeader>
            <TableHeader />
          </TableRow>
        </thead>
        <tbody>
          {roles.map((role, index) => (
            <TableRow key={index} onClick={() => setSelectedRole(role.role)}>
              <TableCell inline width={'240px'}>{role.role}</TableCell>
              <TableCell inline width={'550px'}>
                <RoleDescription>{role.description}</RoleDescription>
              </TableCell>
              <TableCell
                className={'view-users'}
              >View users
              </TableCell>
            </TableRow>))}
          <TableRow>
            <TableCell />
            <TableCell noPadding>
              {!showTools && <ViewTools onClick={() => setShowTools(true)}>
                <img src={chevronDown} alt='chevron-down' />
                <span>View tools</span>
              </ViewTools>}
              {showTools && <ToolsList>
                <ToolItem>
            Add students, Administrative drop students, Assessment extensions,
            Cohorts, Cohort transfer, CSV
            download, Database table, Enrollment management, Exam retakes,
            Student grade report, Guild, Proctorio
            gradebook, Relationships, Student records, VIP users, Writing grade
            center, and Yellowdig URLs.
                </ToolItem>
                <HideTools onClick={() => setShowTools(false)}>
                  <img src={chevronDown} alt='chevron-down' />
                  <span>Hide tools</span>
                </HideTools>
              </ToolsList>}
            </TableCell>
            <TableCell />
          </TableRow>
        </tbody>
      </Table>
    </>
  )
}

export default RolesTable

import styled from 'styled-components'
import { ReactComponent as CorrectIcon } from '../../assets/icons/icon-correct.svg'
import { ReactComponent as IncorrectIcon } from '../../assets/icons/icon-incorrect-answer.svg'
import { ReactComponent as UnselectedIcon } from '../../assets/icons/icon-unselected.svg'
import { ReactComponent as CorrectAnswerIcon } from '../../assets/icons/icon-correct-answer.svg'

export const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: self-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
`

export const StudentCorrectIcon = styled(CorrectAnswerIcon)`
  margin-right: 12px;
`

export const IncorrectAnswerIcon = styled(IncorrectIcon)`
  margin-right: 12px;
`

export const UnselectedAnswerIcon = styled(UnselectedIcon)`
  margin-right: 12px;
`

export const CorrectAnswer = styled(CorrectIcon)`
  margin-right: 12px;
`

export const CardText = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
`

export const CardOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 24px 0;
  width: 100%;
`

export const CardOptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  width: 100%;
`

export const CardOption = styled.div`
  display: flex;
  width: calc(100% - 32px);
  box-sizing: border-box;
  margin: 6px 0;
  ${({ isTheCorrectAnswer }) => isTheCorrectAnswer && 'border: 1px solid #FFF;'}
  border: 1px solid ${({ isOptionCorrect, isOptionSelected, isTheCorrectAnswer }) =>
    isOptionCorrect
      ? '#5FC4B8'
      : isOptionSelected ? '#FFA463' : isTheCorrectAnswer ? '#FFF' : 'rgba(255, 255, 255, 0.1)'};
  border-radius: 5px;
  padding: 24px;
`

export const CardOptionText = styled.div`
  p { margin: 0;}
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
`

export const CardOptionIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-left: 12px;
`

export const AnswerStatus = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;  
  justify-content: flex-start;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
`

export const CorrectAnswerText = styled.div`
  margin-bottom: 12px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
`

export const AnswerTitle = styled.div`
  margin-bottom: 12px;
  color: #c1cfd9;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
`

export const AnswerText = styled.div`
  p{ margin: 0; }
  font-size: 18px;
  font-weight: 300;
  line-height: 21px;
`

export const CardPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 320px;
  max-width: 500px;
  margin: 0;
  `

export const MathTypeWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  ::-webkit-scrollbar {
    display: none;
  } 

  .mathTypeWrapper {
    width: 500px!important;
  }
`

export const StudentAnswerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(150, 150, 150, 0.05);
  z-index: 3;
  `

export const OffsetContainer = styled.div`
  width: 615px;
  margin-left: -58px;
`

export const JDoodleContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  & > iframe {
    height: 100vh;
  }
`

export const StudentAnswer = styled.div`
  padding: 24px;
  background: #1E1F22;
`

export const PreviewText = styled.div`
  color: #CCD4D8;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
`

export const Separator = styled.hr`
  margin: ${({ margin }) => margin || '24px 0 48px'};
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`

export const CodePreview = styled.pre`
  width: 100%;
  max-width: 500px;
  height: 268px;
  border-radius: 5px 0 0 0;
  background: rgba(13, 13, 15, 1);
  color: #FFFFFF;
  padding: 12px;
  overflow: scroll;
  font-family: "Fira Code", monospace;
  font-optical-sizing: auto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`

import styled from 'styled-components'
import Select from 'react-select'
import mediaqueries from '../../../mediaQueries'
import { InformationCardTitle } from '../../InformationCard/style'
import { courseSelectStyles } from '../../global.styled'

export const Container = styled.div`
  margin-left: 36px;
  margin-right: 36px;
  padding-top: ${(props => props.padding && '64px')};
`

export const BreadCrumb = styled.h3`
  display: flex;
  margin-top: 15px;
  margin-bottom: 32px;
  text-transform: uppercase;
  cursor: pointer;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  color: var(--brand-regular);

  p {
    margin-left: 12px;
    margin-bottom: 0px;
  }
`

export const ViewAllWrapper = styled.div`
  position: fixed;
  cursor: pointer;
  width: 100%;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  bottom: -1px;
  height: 126px;
  padding-top: 81px;
  background: linear-gradient(180deg, rgba(22, 22, 24, 0) 0%, #161618 79.17%);

  i {
    margin-right: 12px;
    margin-bottom: 3px;
  }
`
export const TableHeader = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 100%;
  font-weight: bold;
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  padding: 0 25px;
  margin-bottom: 13px;
  margin-top: 9px;
  ${mediaqueries.mobile`
    grid-template-columns: 100%;
    gap: 12px;
  `}
`

export const TableRow = styled(TableHeader)`
  position: relative;
  text-transform: none;
  background: var(--black-secondary);
  border-radius: 5px;
  margin-bottom: 12px;
  padding: 26px 25px;
  font-weight: normal;
`

export const StudentDeadlineHeader = styled.span`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
`

export const IconsContainer = styled.div`
  margin-left: 10px;
  margin-top: -15px;

  .fa-icons {
    display: inline-flex;
    flex-direction: column;
    position: relative;

    .fa-chevron-down {
      position: absolute;
      top: 10px;
    }

    .clicked-icon {
      color: var(--border-tertiary);
    }
  }
`

export const NoDataAvailable = styled(TableRow)`
  letter-spacing: 0;
  font-size: 16px;
  grid-template-columns: 100%;
`

export const Deadline = styled.span`
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--text-dark-off);
  display: flex;
  align-items: center;
`

export const AssignmentTitle = styled.span`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0;
  color: var(--text-primary);
  display: flex;
  align-items: center;
`

export const CohortName = styled(AssignmentTitle)`
  font-weight: 300;
  display: flex;
  align-items: center;
`

export const GridButton = styled(Deadline)`
  justify-content: flex-end;
  color: var(--text-primary);

  .btn-custom {
    position: absolute;
    right: 24px;
    top: 15px;
    width: 120px;
    font-size: 14px !important;
    line-height: 17px !important;
  }
`

export const PageHeading = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  text-transform: uppercase;
  margin-bottom: 0;
`

export const SubHeading = styled(InformationCardTitle)`
  font-weight: ${props => (props.hasCourse ? 400 : 300)};
  margin-top: ${props => props.isExamRetakeFilter && '16px'};
`

export const SubHeadingMarginBottom = styled(InformationCardTitle)`
  font-weight: ${props => (props.hasCourse ? 400 : 300)};
  margin-top: 24px;
  margin-bottom: ${props => props.hasCourse ? 12 : 24}px;
  display: block;
`

export const FilterHeader = styled.span`
  font-size: 18px;
  line-height: 22px;
  color: var(--text-primary);
  font-weight: 400;
`

export const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  margin: 24px 12px 12px;
  ${mediaqueries.mobile`
    margin: 18px 0;
    gap: 0px;
  `}
`

export const SelectFiltersContainer = styled(FiltersContainer)`
  justify-content: flex-start;
  width: 100%;
`

export const ResetFilterButton = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--brand-regular);
  cursor: pointer;
  transition: color 100ms ease;
  ${({ disabled }) => disabled && 'opacity: 0.3; pointer-events: none;'}
  &:hover {
    color: var(--brand-regular-hover);
  }
  &:active {
    color: var(--brand-regular-active);
  }
`

export const FilterWrapper = styled.span`
  width: 260px;
  height: 93px;
  margin-right: 12px;
`

export const CohortFilterWrapper = styled.span`
  width: 322px;
  height: 93px;
  margin-right: 12px;
`

export const FilterLabel = styled.div`
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: var(--text-secondary);
`

export const CohortSelectContainer = styled.div`
  position: relative;
`

export const CohortSelect = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-primary);
  border-radius: 5px;
  padding: 9px 13.5px 12px 12px;
  height: 40px;
  background: var(--input-primary);
  cursor: pointer;
  position: relative;
  &:active {
    border: 1px solid var(--brand-regular);
  }

  > span {
    width: 1px;
    height: 86%;
    background: var(--border-primary);
    position: absolute;
    right: 32px;
    top: 3px;
  }
`

export const CohortSelectDropdown = styled.div`
  position: absolute;
  width: 322px;
  border: 1px solid var(--border-primary);
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px var(--shadow-primary);
  background: var(--black-primary);
  z-index: 1000;
  padding: 24px;
`

export const CohortSearchBar = styled.input`
  width: 100%;
  border: 1px solid var(--border-primary);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 11px 12px 10px;
  background: var(--input-primary);
  font-size: 16px;
  font-weight: normal;
  line-height: 19px;
  color: var(--text-dark-off);
  outline: none;
  &:hover {
    border: 1px solid var(--brand-regular);
  }
`

export const CohortHeaderText = styled.div`
  margin-top: 24px;
  border-top: 1px solid var(--border-primary);
  padding-top: 24px;
  color: rgba(177,191,197,1);
  font-size: 16px;
  line-height: 19px;
`

export const CohortSelectionList = styled.div`
  display: flex;
  flex-direction: column;
  height: 222px;
  overflow-y: auto;

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`

export const CohortOption = styled.label`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 19px;
  color: var(--text-primary);

  svg {
    margin-right: 8px;
  }

  input {
    display: none;
  }
`

export const Separator = styled.hr`
  margin: 36px 0px;
  border: 1px solid var(--border-primary);
  height: 0px;
`

export const PaginationEntries = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: flex-start;
  margin-top: 32px;
  align-items: center;
`

/* Customize the label (the container) */
export const CustomOptionContainer = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  padding-left: 30px;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  :hover input ~ .check-mark {
    background-color: var(--brand-regular-hover);
  }

  input:checked ~ .check-mark {
    background-color: var(--brand-regular);
  }

  input ~ .check-mark:after {
    display: none;
  }

  input:checked ~ .check-mark:after {
    display: block;
  }
`

/* Hide the browser's default checkbox */
export const CheckedBox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

/* Create a custom checkbox */
export const CustomCheckBox = styled.span`
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  background: var(--border-secondary);
  border-radius: 3px;

  :after {
    content: '';
    position: absolute;
    left: 6px;
    top: 3.5px;
    width: 7px;
    height: 10px;
    border: 1px solid var(--text-dark);
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`

export const AssignmentTableContainer = styled.div`
  margin-top: 36px;

  .table-header,
  .table-row {
    display: grid;
    grid-template-columns: 10% 14.4% 14.4% 14.4% 14.4% 14.4% 9% 9%;
    border-bottom: 1px solid var(--white);
    overflow-wrap: anywhere;
  }

  .right {
    text-align: right;
    margin-right: 22px;
    white-space: nowrap;
  }

  .table-row {
    border-bottom: 1px solid #2a2e31;
  }

  .submission-header {
    cursor: pointer;
  }

  .submission-header,
  .submission-row {
    position: relative;
    justify-content: start;
    padding-right: 24px;
    padding-bottom: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    &.time {
      text-transform: uppercase;
    }

    .icons-container {
      position: absolute;
      top: -5px;
      left: 45px;

      .fa-icons {
        display: inline-flex;
        flex-direction: column;
        position: relative;

        .fa-chevron-down {
          position: absolute;
          top: 10px;
        }

        .clicked-icon {
          color: #606060;
        }
      }
    }
  }

  .submission-row {
    margin-top: 34.34px;
    padding-bottom: 17px;
    font-weight: normal;
  }

  .submission-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .submission-btn {
      height: 40px;
      width: 115px;
      font-size: 14px;
      line-height: 17px;
    }

    .btn-primary {
      background: var(--brand-regular);
      color: var(--text-dark);

      &:hover {
        background: var(--brand-regular-hover);
      }
    }

    .btn-secondary {
      border: 1px solid var(--brand-regular);
      color: var(--brand-regular);

      &:hover {
        border: 1px solid var(--brand-regular-hover);
        color: var(--brand-regular-hover);
      }
    }
  }
`

export const DropDownImg = styled.img`
  margin-left: 10px;
  transform: ${props => (props.isSortedAscending ? 'rotate(180deg)' : 'rotate(0deg)')};
`

export const PaginationContainer = styled.div`
  position: absolute;
  width: 95%;
  bottom: 0px;
  padding-top: 78px;
  padding-bottom: 31px;
  margin-bottom: 0;
  background: linear-gradient(180deg, rgba(22,22,24,0), rgba(22,22,24,100));
`

export const filterSelectStyles = {
  ...courseSelectStyles,
  control: (base, state) => ({
    ...base,
    height: '40px',
    background: 'var(--input-primary)',
    color: 'var(--text-primary)',
    marginTop: '10px',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid var(--border-primary)'
    }
  }),
  valueContainer: base => ({
    ...base,
    fontSize: '16px',
    paddingLeft: '13px',
    backgroundColor: 'inherit',
    color: 'var(--text-primary)',
    lineHeight: '19px',
    border: 'none'
  }),
  menu: base => ({
    ...base,
    width: '100%',
    background: 'var(--canvas-dark)',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    marginTop: '0px'
  }),
  option: (base, state) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#060606',
    borderBottom: '1px solid var(--border-primary)',
    cursor: 'pointer',
    fontSize: '18px',
    lineHeight: '22px',
    '&:last-child': {
      borderBottom: 'none',
      borderRadius: '0 0 5px 5px'
    },
    '&:first-of-type': {
      borderRadius: '5px 5px 0 0'
    },
    '&:hover': {
      backgroundColor: 'var(--canvas-dark-off)'
    }
  }),
  placeholder: base => ({
    ...base,
    color: 'var(--white)',
    fontSize: '16px',
    lineHeight: '19px'
  }),
  multiValue: base => ({
    ...base,
    display: 'none'
  })
}

export const cohortSelectStyles = {
  ...filterSelectStyles,
  input: base => ({
    ...base,
    display: 'inline-block',
    opacity: 0
  })
}

export const paginationSelectStyles = {
  indicatorSeparator: styles => ({ display: 'none' }),
  control: base => ({
    ...base,
    width: '73px',
    height: '34px',
    margin: '0 8px 0 0',
    background: 'var(--black-tertiary)',
    color: 'var(--white)',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid var(--border-primary)'
    }
  }),
  menu: base => ({
    ...base,
    width: '73px',
    marginTop: '0px',
    background: ' #060606',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    zIndex: 1000,
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px'
  }),
  menuList: base => ({
    ...base,
    width: '100%',
    height: '100%'
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: !state.isSelected ? '#060606' : 'rgba(255, 255, 255, 0.1)',
    borderBottom: '1px solid var(--border-primary)',
    cursor: 'pointer',
    '&:nth-of-type(4)': {
      borderBottom: 'none'
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    }
  }),
  singleValue: base => ({
    ...base,
    color: 'var(--white)',
    paddingLeft: '6px',
    margin: 0
  }),
  dropdownIndicator: base => ({
    ...base,
    color: 'var(--white)',
    padding: '0 8px 0 0',
    '&:hover': {
      color: 'var(--white)'
    }
  }),
  indicatorContainer: base => ({
    ...base,
    paddingLeft: 0
  })
}

export const CustomSelect = styled(Select)`
  min-height: 40px;
  margin-bottom: ${({ reduced }) => reduced ? '15px' : '24px'};
  background-color: var(--input-primary);
  max-width: 350px;
  width: 100%;
  & > .select__control {
    height: auto;
    min-height: 40px;

    &.select__control--is-focused {
      border: 1px solid var(--brand-regular) !important;
    }

    .select__indicators {
      border-left: 1px solid var(--border-primary);
      padding-left: 7px;
      margin: 3px auto;

      .select__clear-indicator {
        padding-left: 1px;
      }
    }

    .select__single-value {
      font-size: 16px;
    }

    .select__value-container {
      font-size: 16px;

      .select__input {
        color: var(--text-primary);
      }

      .select__multi-value {
        background: var(--canvas-secondary);
        padding: 6px 3px 6px 3px;
        border-radius: 5px;

        .select__multi-value__label {
          color: var(--text-primary);
          font-size: 16px;
          font-weight: 400;
          line-height: 19.2px;
        }

        .select__multi-value__remove:hover {
          background-color: transparent;
          color: var(--text-primary);
        }
      }
    }
  }
  & > .select__menu {
    width: 100%;
  }
`

import React, { useEffect, useRef, useState } from 'react'
import api from '../../../api'
import {
  CloseIconWrapper,
  CloseIcon,
  ColoredButton,
  CourseIcon,
  CourseItem,
  CourseName,
  ModalTitleContainer,
  ModalWrapper,
  PlusIcon,
  SectionBody,
  SectionContainer,
  ModalBody,
  ModalItem,
  ModalHeaderContainer
} from '../style/ProspectPage'
import { ClearIcon, SearchField, SearchIcon, SearchTextField } from '../style'
import { Description, Label, SectionTitle } from '../../../styles/text'
import LoadingSpinner from '../../../Components/LoadingSpinner/LoadingSpinner'
import { getGGUCohorts } from '../../../utilities/cohortUtils'
import {
  getBeforeDropCohorts
} from '../utils'

function SelectedCourses ({
  originalProspect,
  editedProspect,
  setEditedProspect,
  gguCohorts,
  setGGUCohorts
}) {
  const modalRef = useRef()

  const selectedCohorts = editedProspect?.selectedCohorts || []
  const selectedCohortsNames = selectedCohorts.map(cohort => cohort?.name)

  const [availableCohorts, setAvailableCohorts] = useState([])
  const [isLoadingCohorts, setIsLoadingCohorts] = useState(false)
  const [showCoursesModal, setShowCoursesModal] = useState(false)
  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    const fetchAllCohorts = async () => {
      setIsLoadingCohorts(true)
      const cohorts = await api.getAllCohorts()
      setIsLoadingCohorts(false)

      const beforeDropGGUCohorts = getBeforeDropCohorts(getGGUCohorts(cohorts))
      setGGUCohorts(beforeDropGGUCohorts)
      setAvailableCohorts(beforeDropGGUCohorts
        ?.filter(cohort => !selectedCohortsNames.includes(cohort?.name))
      )
    }

    fetchAllCohorts()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    function handleClickOutside (event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleModalClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const addCohort = (cohort) => {
    const wasSelectedAndRemoved =
      originalProspect?.selectedCohorts?.find(i => i?.name === cohort?.name) &&
      editedProspect?.removedCohorts?.find(i => i?.name === cohort?.name)

    if (wasSelectedAndRemoved) {
      setEditedProspect(prev => ({
        ...prev,
        removedCohorts: prev?.removedCohorts?.filter(i => i?.name !== cohort?.name)
      }))
    }

    setEditedProspect(prev => ({
      ...prev,
      selectedCohorts: [
        ...prev.selectedCohorts,
        cohort
      ]
    }))
    setAvailableCohorts(availableCohorts.filter(i => i?.name !== cohort?.name))
    handleModalClose()
  }

  const removeCohort = (cohort) => {
    const wasSelected = originalProspect?.selectedCohorts?.find(i => i?.name === cohort?.name)

    if (wasSelected) {
      setEditedProspect(prev => ({
        ...prev,
        removedCohorts: [
          ...(prev?.removedCohorts || []),
          cohort
        ]
      }))
    }

    setEditedProspect(prev => ({
      ...prev,
      selectedCohorts: prev.selectedCohorts.filter(i => i?.name !== cohort?.name)
    }))
    setAvailableCohorts(prev => [
      cohort,
      ...prev
    ])
  }

  const handleModalClose = () => {
    setShowCoursesModal(false)
  }

  const handleTextChange = (value) => {
    setSearchText(value)
    setAvailableCohorts(gguCohorts
      ?.filter(cohort => cohort?.name.toLowerCase().includes(value.toLowerCase()))
      ?.filter(cohort => !selectedCohortsNames.includes(cohort?.name))
    )
  }

  return (
    <SectionContainer>
      <SectionTitle>Selected courses</SectionTitle>
      <Description>
        Use this section to approve or modify the student’s course selections
        and finalize their registration for the upcoming semester.
      </Description>

      <SectionBody gap='8px' style={{ minHeight: '228.8px' }}>
        {selectedCohorts.map((cohort, index) => (
          <CourseItem key={index}>
            <CourseName>{cohort?.name}</CourseName>
            <CourseIcon>
              <ClearIcon
                onClick={() => removeCohort(cohort)}
              />
            </CourseIcon>
          </CourseItem>
        ))}
      </SectionBody>

      <div style={{ position: 'relative' }}>
        <ColoredButton
          disabled={selectedCohorts.length >= 4}
          onClick={() => setShowCoursesModal(true)}
        >
          <PlusIcon />
          <Label>Add course</Label>
        </ColoredButton>

        {showCoursesModal && (
          <ModalWrapper top='40px' ref={modalRef}>
            <ModalHeaderContainer>
              <ModalTitleContainer>
                <Description>Add a course</Description>
                <CloseIconWrapper onClick={handleModalClose}>
                  <CloseIcon />
                </CloseIconWrapper>
              </ModalTitleContainer>

              <SearchField>
                <SearchTextField
                  placeholder='Search for a cohort'
                  value={searchText}
                  onChange={(e) => handleTextChange(e.target.value)}
                />
                {searchText ? (
                  <ClearIcon onClick={() => handleTextChange('')} />
                ) : (
                  <SearchIcon />
                )}
              </SearchField>
            </ModalHeaderContainer>

            {isLoadingCohorts && (
              <ModalBody style={{ height: '100px' }}>
                <LoadingSpinner />
              </ModalBody>
            )}

            <ModalBody>
              {availableCohorts?.map((cohort, index) => (
                <ModalItem
                  key={index}
                  onClick={() => addCohort(cohort)}
                >
                  {cohort?.name}
                </ModalItem>
              ))}
            </ModalBody>
          </ModalWrapper>
        )}
      </div>
    </SectionContainer>
  )
}

SelectedCourses.displayName = 'SelectedCourses'
export default SelectedCourses

import React, { useCallback, useRef, useState } from 'react'
import {
  Container,
  PageDescription,
  PageTitle,
  Separator,
  ToastDescription
} from './index.styled'
import RelationshipSelector from './RelationshipSelector'
import ExamLockUnlock from './ExamLockUnlock'
import CourseSelector from './CourseSelector'
import ExamSelector from './ExamSelector'
import ReviewState from './ReviewState'
import { BackButton, ButtonsWrapper } from '../EnrollmentManagement/index.style'
import { PrimaryButton } from '../../Components/global.styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Toast from '../../Components/ToastComponent/Toast'
import ErrorState from './ErrorState'
import api from '../../api'

function ExamKeyOverride () {
  const [selectedRelationship, setSelectedRelationship] = useState(
    { label: '--', relationshipAdminEmail: '--' }
  )
  const [locked, setLocked] = useState(
    { locked: 'none' }
  )
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [selectedCohort, setSelectedCohort] = useState(null)
  const [selectedExam, setSelectedExam] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState(false)
  const toast = useRef()

  const resetState = () => {
    setSelectedRelationship({ label: '--', relationshipAdminEmail: '--' })
    setLocked({ locked: 'none' })
    setSelectedCourse(null)
    setSelectedCohort(null)
    setSelectedExam(null)
  }

  const handleSubmitClick = useCallback(async () => {
    setIsSubmitting(true)
    const { relationshipName } = selectedRelationship || {}
    const { value: cohortId } = selectedCohort || {}
    const { value: examName, label: examTitle } = selectedExam || {}
    const isExamLocked = locked.locked === true

    const payload = {
      relationshipName: relationshipName,
      cohortId: cohortId,
      examName: examName,
      locked: isExamLocked
    }

    const data = await api.putExamKeyOverride(payload)
    setIsSubmitting(false)

    if (data.error) {
      setError(true)
      return
    }
    resetState()
    toast.current.display(
      <ToastDescription>
        <b>{examTitle} successfully {isExamLocked ? 'locked' : 'unlocked'} for </b>
        {relationshipName}
      </ToastDescription>
    )
  }, [selectedRelationship, selectedCohort, selectedExam, locked])

  const handleCancelClick = useCallback(() => {
    resetState()
  }, [])

  return (
    <Container>
      <Toast toast={toast} />
      <PageTitle>Exam Key Override</PageTitle>
      <Separator />
      <PageDescription>
        Unlock exams for all students in a relationship.
      </PageDescription>
      <RelationshipSelector
        data-testid='relationship-selector'
        selectedRelationship={selectedRelationship}
        setSelectedRelationship={setSelectedRelationship}
      />
      <Separator margin='32px 0' />
      <ExamLockUnlock
        isSelectedRelationshipEmpty={selectedRelationship?.label === '--'}
        locked={locked}
        setLocked={setLocked}
      />
      <Separator margin='32px 0' />
      <CourseSelector
        isSelectedRelationshipEmpty={selectedRelationship?.label === '--'}
        selectedRelationship={selectedRelationship}
        isLockedEmpty={locked?.locked === 'none'}
        selectedCourse={selectedCourse}
        setSelectedCourse={setSelectedCourse}
        selectedCohort={selectedCohort}
        setSelectedCohort={setSelectedCohort}
      />
      <Separator margin='32px 0' />
      <ExamSelector
        locked={locked.locked === true}
        selectedExam={selectedExam}
        setSelectedExam={setSelectedExam}
        selectedCourse={selectedCourse}
        selectedCohort={selectedCohort}
      />
      {selectedExam &&
        <>
          <Separator margin='32px 0' />
          <ReviewState
            locked={locked.locked === true}
            selectedCourse={selectedCourse}
            selectedCohort={selectedCohort}
            selectedExam={selectedExam}
            selectedRelationship={selectedRelationship}
          />
          <ButtonsWrapper>
            <PrimaryButton
              disabled={isSubmitting}
              style={{ width: 'fit-content', minWidth: '107px' }}
              onClick={handleSubmitClick}
            >
              {isSubmitting
                ? <FontAwesomeIcon spin icon={faSpinner} />
                : 'submit'
              }
            </PrimaryButton>
            <BackButton
              onClick={handleCancelClick}
              data-testid='back-button'
            >
              Cancel
            </BackButton>
          </ButtonsWrapper>
        </>
      }
      { error && <ErrorState handleGoBack={() => setError(false)} /> }
    </Container>
  )
}

export default ExamKeyOverride

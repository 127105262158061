import styled, { css } from 'styled-components'
import { ContentTitle } from '../../../styles/text'
import CustomSelect from '../../../Components/CustomSelect/UpdatedSelect'
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg'

export const TabsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: flex-start;
`
export const Tab = styled(ContentTitle)`
  color: var(--sub-text-secondary);
  cursor: pointer;

  ${(props) =>
    props.isActive &&
    css`
      color: var(--text-primary);
      padding-bottom: 6px;
      border-bottom: 1px solid #fff;
    `}

  &:hover {
    color: var(--text-primary);
  }
`
export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-top: 32px;
`
export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 11fr 20fr 16fr 22fr 59px;
  grid-gap: 16px;
  overflow-wrap: anywhere;
  padding: 0 16px 16px;
  margin-bottom: 8px;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid var(--border-secondary);
  position: sticky;
  top: 0;
  z-index: 8;
  background-color: var(--content-background-color);
`
export const HeaderItem = styled.span`
  color: var(--text-secondary);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
`
export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  gap: 8px;
  min-height: 200px;
  max-height: 590px;
`
export const NoPendingApprovals = styled.p`
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  padding: 12px;
`
export const TableRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 59px;
  padding: 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 5px;
  background: var(--black-secondary);
`
export const CellContainer = styled.div`
  display: grid;
  grid-template-columns: 11fr 20fr 16fr 22fr;
  gap: 16px;
`

export const Cell = styled.span`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-primary);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:first-of-type {
    color: var(--sub-text-secondary);
  }
`
export const ModalBody = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 500px;
  overflow-y: auto;
`
export const FieldGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => gap};
`
export const Label = styled.span`
  color: var(--sub-text-secondary);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
export const Value = styled.span`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
export const RadioSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & input {
    cursor: pointer;
  }

  & label {
    display: flex;
  }
  & p {
    color: #fff;
    margin: 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`
export const SanctionSelect = styled(CustomSelect)`
  min-height: 48px;
  width: 100%;

  & > .select__control {
    min-height: 48px;
  }
`
export const SelectLabel = styled(Label)`
  line-height: 24px;
`
export const CustomTextArea = styled.textarea`
  min-height: 100px;
  resize: none;
  max-width: 400px;
  padding: 12px 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid var(--border-secondary);
  border-radius: 5px;
  color: #ffffff;
  font-weight: normal;
  font-size: 16px;
  background: var(--black-tertiary);
  &::placeholder {
    color: var(--border-secondary);
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 24px;
`
export const ToastContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & > svg {
    color: var(--sub-text-secondary);
  }
  & > span {
    font-weight: 700;
    color: var(--white);
  }
`
export const CloseIcon = styled(CrossIcon)`
  align-self: center;
  cursor: pointer;
`
export const ReadyToSubmit = styled.p`
  color: var(--sub-text-secondary);
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 0;
  margin-top: 12px;
`

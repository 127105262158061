import React, { useEffect, useState } from 'react'
import {
  TableHeader,
  NoDataAvailable,
  StudentDeadlineHeader,
  TableRow,
  Deadline,
  AssignmentTitle,
  CohortName,
  IconsContainer
} from '../WritingGradeCenter/styled'
import ExamGradeBookButton from './Button'
import {
  ExamSeparator,
  ExamViewAllWrapper,
  SubPageHeading,
  TableHeaderText,
  TableMainWrapper,
  TableWrapper
} from './styled'

const ascendingSort = (a, b) => new Date(a.examEndTime) - new Date(b.examEndTime)
const descendingSort = (a, b) => new Date(b.examEndTime) - new Date(a.examEndTime)

const ExamCenterTable = props => {
  const {
    heading,
    isCompletedTable,
    exams
  } = props

  const [viewAll, setViewAll] = useState(false)
  const [filteredExams, setFilteredExams] = useState([])
  const [isDeadlineClicked, setIsDeadlineClicked] = useState(!isCompletedTable)

  useEffect(() => {
    setFilteredExams([...exams].sort(isCompletedTable ? descendingSort : ascendingSort))
    // eslint-disable-next-line
  }, [exams])

  const handleDeadlineClick = () => {
    setIsDeadlineClicked(!isDeadlineClicked)
    setFilteredExams([...filteredExams].reverse())
  }

  const handleViewAll = () => setViewAll(!viewAll)

  const hasExams = !!filteredExams.length
  const showViewAllSection = filteredExams.length > 6

  return (
    <TableMainWrapper>
      <SubPageHeading mt={isCompletedTable ? 22 : 30}>
        {heading}
      </SubPageHeading>
      <ExamSeparator />
      <TableWrapper>
        <TableHeader>
          <StudentDeadlineHeader onClick={handleDeadlineClick}>
            <TableHeaderText>Deadline</TableHeaderText>
            <IconsContainer>
              <div className='fa-icons'>
                <i
                  className={`fa fa-chevron-up ${!isDeadlineClicked &&
                    'clicked-icon'}`}
                />
                <i
                  className={`fa fa-chevron-down ${isDeadlineClicked &&
                    'clicked-icon'}`}
                />
              </div>
            </IconsContainer>
          </StudentDeadlineHeader>
          <TableHeaderText>Exam</TableHeaderText>
          <TableHeaderText>Cohort</TableHeaderText>
          <span />
        </TableHeader>
        {
          hasExams ? (
            filteredExams.slice(0, viewAll ? filteredExams.length : 6)
              .map((exam, index) => {
                const {
                  examName,
                  deadline,
                  examEndTime,
                  proctorioExamTitle,
                  cohortName
                } = exam

                return (
                  <TableRow key={`${cohortName}-${examName}`}>
                    <Deadline>{deadline}</Deadline>
                    <AssignmentTitle>{examName}</AssignmentTitle>
                    <CohortName>{cohortName}</CohortName>
                    <ExamGradeBookButton
                      cohortName={cohortName}
                      examEndTime={examEndTime}
                      proctorioExamTitle={proctorioExamTitle}
                    />
                  </TableRow>
                )
              })
          ) : (
            <NoDataAvailable>
              There is no data available for the selected filter.
            </NoDataAvailable>
          )
        }
        {showViewAllSection && <ExamViewAllWrapper onClick={handleViewAll}>
          {viewAll ? (
            <i className='fa fa-chevron-up' alt='up-arrow-caret' />
          ) : (
            <i className='fa fa-chevron-down' alt='down-arrow-caret' />
          )}
          <span>{`View ${viewAll ? 'Less' : 'All'}`}</span>
        </ExamViewAllWrapper>}
      </TableWrapper>
    </TableMainWrapper>
  )
}

export default ExamCenterTable

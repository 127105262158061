import React from 'react'
import FileUploader from '../FileUploader/FileUploader'
import { CSV_SAMPLES, steps } from './CSVImportConstants'
import {
  DownloadExampleCSVLink,
  InstructionsParagraph,
  InstructionsHeading,
  ErrorTextExtended,
  InstructionsArea,
  CustomContainer,
  CustomButton
} from './style'

const getSampleCsv = (isGGUAddmissionDecision) => {
  const CSV = isGGUAddmissionDecision
    ? CSV_SAMPLES.ADMISSION_DECISION
    : CSV_SAMPLES.STUDENT
  const csvFile = new Blob([CSV], { type: 'text/csv' })
  if (process.env.NODE_ENV === 'test') return
  return window.URL.createObjectURL(csvFile)
}

function CSVUpload (props) {
  const { COLUMN_MAPPING } = steps
  const {
    file,
    setFile,
    handleUploadedFile,
    fileError,
    setStep,
    isGGUAddmissionDecision
  } = props

  const sampleCsv = getSampleCsv(isGGUAddmissionDecision)
  return (
    <>
      <InstructionsArea>
        <InstructionsHeading>Instructions</InstructionsHeading>
        <CustomContainer margin='12px 0 0 0'>
          {isGGUAddmissionDecision
            ? (
              <InstructionsParagraph>
                This tool allows us to import GGU’s roster file, which contains
                their admission decision. To start, please upload the CSV file
                containing GGU’s admission decision.
              </InstructionsParagraph>
            )
            : (
              <>
                <InstructionsParagraph>
                  This is where you will be able to add multiple students into our
                  database (i.e., Airtable) at once. To start, please upload a CSV
                  file containing the student information you would like to
                  import. Feel free to use the sample CSV linked below as
                  reference.
                </InstructionsParagraph>
                <CustomContainer margin='12px 0 0 0'>
                  <InstructionsParagraph>
                    Please keep in mind the data type of the Airtable columns you
                    are importing into and its constraints. For example, Airtable
                    columns with checkboxes like `isRegistered` should be 0s and
                    1s in the uploaded CSV. Lastly, linked Airtable columns like
                    `courses`, `cohorts`, and `relationship` will only allow
                    imports with records that already exist, so if you are adding
                    students into a new cohort, make sure that cohort name has
                    been created on Airtable.
                  </InstructionsParagraph>
                </CustomContainer>
              </>
            )}
        </CustomContainer>
      </InstructionsArea>

      <CustomContainer margin='32px 0 12px 0'>
        <DownloadExampleCSVLink
          target='_blank'
          href={sampleCsv}
          download='sample.csv'
        >
          download sample CSV
        </DownloadExampleCSVLink>
      </CustomContainer>

      <InstructionsParagraph margin='12px 0 0 0'>
        Note: This tool only allows one CSV per import.
        Attaching another CSV file will replace the existing file.
      </InstructionsParagraph>

      <CustomContainer margin='12px 0 36px 0'>
        <FileUploader
          uploadedContent={file}
          onFileSelect={handleUploadedFile}
          isDisabled={false}
          onEmpty={() => {
            setFile(undefined)
          }} />
      </CustomContainer>

      {fileError && <ErrorTextExtended>{fileError}</ErrorTextExtended>}

      <CustomButton
        style={{ marginTop: '18px', display: 'block' }}
        data-testid='upload-csv-btn-next'
        className='btn btn-custom btn-primary'
        disabled={!file}
        onClick={() => setStep(COLUMN_MAPPING)}
      >Next Page</CustomButton>
    </>
  )
}

CSVUpload.displayName = 'CSVUpload'
export default CSVUpload

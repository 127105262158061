import styled from 'styled-components'
import mediaqueries from '../../../../mediaQueries'

export const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    position: absolute;
    top: 64px;
    left: 0;
    background-color: var(--canvas-primary);
    height: calc(100% - 64px);
    width: 100%;
    padding: 64px 32px 0 32px;
    z-index: 99;

    & > .btn-custom {
        ${mediaqueries.mobile` width: 100% !important;`}
    }
`

export const ErrorTitile = styled.div`
    font-size: 24px;
    font-weight: 300;
    line-height: 32px;
    margin-bottom: 16px;
    margin-top: 24px;
`

export const ErrorDescription = styled.div`
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 32px;
`

export const relationshipTypes = {
  PARTNERSHIP: {
    value: 'partnership',
    label: 'Partnership'
  },
  SCHOLARSHIP: {
    value: 'scholarship',
    label: 'Scholarship'
  }
}

export const accessTypes = {
  SEATS: {
    value: 'seats',
    label: 'Seats'
  },

  STUDIO: {
    value: 'studio',
    label: 'Studio'
  }
}

export const institutions = {
  UPITTS: {
    value: 'University of Pittsburgh',
    label: 'University of Pittsburgh'
  },
  GGU: {
    value: 'Golden Gate University',
    label: 'Golden Gate University'
  },
  FLORIDA_POLY: {
    value: 'Florida Poly',
    label: 'Florida Poly'
  },
  EMPTY: {
    value: null,
    label: '--'
  }
}

export const returnTos = {
  PARTNER: {
    value: 'partnerReturn',
    label: 'Partner'
  },

  STUDENT: {
    value: 'studentReturn',
    label: 'Student'
  }
}

export const refunds = {
  RELATIONSHIP: {
    value: 'relationshipRefundable',
    label: 'Relationship'
  },

  STUDENT: {
    value: 'studentRefundable',
    label: 'Student'
  },

  NON_REFUNDABLE: {
    value: 'nonRefundable',
    label: 'Non-refundable'
  }
}

export const signingPartnerNameOptions = [
  {
    label: 'Mosaic',
    value: 'Mosaic'
  }
]

export const partnerTypeOptions = [
  {
    value: 'bootcamp',
    label: 'Bootcamp'
  },
  {
    value: 'distribution',
    label: 'Distribution'
  },
  {
    value: 'enterprise',
    label: 'Enterprise'
  },
  {
    value: 'high school',
    label: 'High School'
  },
  {
    value: 'higher ed',
    label: 'Higher Ed'
  }
]

export const GUILD = 'Guild'

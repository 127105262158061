import React from 'react'
import { HeaderSection, PageWrapper, Separator } from './styles'
import { PageTitle } from '../../styles/text'
import AcademicIntegrityTables from './AcademicIntegrityTables'

function AcademicIntegrity () {
  return (
    <PageWrapper>
      <HeaderSection>
        <PageTitle>Academic integrity</PageTitle>
      </HeaderSection>
      <Separator space='24px' />
      <AcademicIntegrityTables />
    </PageWrapper>
  )
}

export default AcademicIntegrity

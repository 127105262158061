import { EXAM_DATE_KEYS } from '../Constants/examTypes'
import moment from 'moment'

export const getActiveExams = (courseData, cohort) => {
  const exams = courseData?.chapters?.filter(chapter => chapter.type === 'exam')
  const noOfExams = exams?.length
  if (!noOfExams) return []

  const noOfExamTypes = EXAM_DATE_KEYS.length
  const { examExtensions } = cohort || {}
  if (examExtensions?.length) {
    const examExt = getLatestDeadlineExtensions(examExtensions)
    cohort = { ...cohort, ...examExt }
  }

  return exams.map((exam, index) => {
    const accessIndex = index === noOfExams - 1 ? noOfExamTypes - 1 : index
    const { start, end, extension, keyOverride } = EXAM_DATE_KEYS[accessIndex]
    let value = keyOverride.slice(0, keyOverride.indexOf('KeyOverride')).toLowerCase()
    value = value === 'finalexam' ? 'finalExam' : value

    return {
      label: exam?.title,
      startDate: cohort?.[start],
      endDate: cohort?.[extension] || cohort?.[end],
      originalEndDate: cohort?.[end],
      hasExtension: !!cohort?.[extension],
      hasKeyOverride: cohort?.[keyOverride]?.length > 0,
      value: value,
      id: exam?.chapter_uuid,
      accessIndex
    }
  }).filter(exam =>
    moment(exam.startDate).isValid() &&
    moment(exam.endDate).isValid() &&
    moment(new Date()).isBetween(exam.startDate, exam.endDate)
  )
}

function getLatestDeadlineExtensions (examExtensions) {
  const latestDeadlines = {
    midterm1DeadlineExt: null,
    midterm2DeadlineExt: null,
    exam3DeadlineExt: null,
    exam4DeadlineExt: null,
    exam5DeadlineExt: null,
    finalDeadlineExt: null
  }

  examExtensions.forEach(extension => {
    Object.keys(latestDeadlines).forEach(key => {
      const deadlineExt = extension?.fields && extension?.fields[key]
      if (!deadlineExt) return
      if (deadlineExt && (!latestDeadlines[key] || new Date(deadlineExt) > new Date(latestDeadlines[key]))) {
        latestDeadlines[key] = deadlineExt
      }
    })
  })

  return latestDeadlines
}

export const getExamGrade = (studentProgress, examSection) => {
  if (!studentProgress || !examSection) return null

  const { chapter_uuid: chapterUUID, examVersions } = examSection || {}
  const examVersion = examVersions.length
    ? examVersions[examVersions.length - 1]
    : { uuid: chapterUUID }

  return studentProgress['exam-percentage'][examVersion.uuid] ||
    studentProgress['exam-percentage'][chapterUUID]
}

import moment from 'moment'

export {
  secondsToHHMMSS,
  changeTimezone,
  timeStampToFormattedDate,
  dateToSecondsSinceEpoch,
  secondsSinceEpoch,
  fixTimezoneAndFormat,
  getTimezoneShort,
  formatWithoutYear,
  substractHoursFromDate,
  addDotBtwDateAndTime,
  getDateStringWithComma,
  getUSDateStringFormat,
  getShortDateString,
  getDate,
  getTimeString,
  getDateString,
  getLastMonthDateFromNow,
  getDateAfterDays,
  getDateAfterWeeks,
  getMondayBeforeDate,
  getFormatTime12PM,
  getDefaultKpiStartDate,
  dateStringToFormattedDate,
  dateToISOString,
  getTodayMidNight,
  getMidNightDateFromISOString,
  getMidNightTimeStamp,
  getSecondBeforeMidNightTimeStamp,
  getDaysDifference,
  addYearsToDate,
  standardizeToEasternTime,
  convertEasternTimeToLocalTime,
  weeksToSeconds,
  addMonthsToDate,
  diffDays,
  getDateStringWithoutYear,
  formatDateTerm,
  getTimeAgo,
  getDateTimeWithComma
}

function timeStampToFormattedDate (timestamp, hasTime, monthStyle = 'long') {
  if (!timestamp) return 'N/A'
  const date = new Date(timestamp)
  const dateWithTime = { year: 'numeric', month: monthStyle, day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }
  return date.toLocaleString('en-US', hasTime ? dateWithTime : { dateStyle: 'long' })
}

function dateStringToFormattedDate (dateString) {
  if (!dateString) return 'N/A'

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December']
  const [year, month, day] = dateString.split('-')
  const formattedDate = `${monthNames[Number(month) - 1]} ${day}, ${year}`
  return formattedDate
}

// Given a date, it returns that date/time in seconds.
// Does not need tests because that would require mocking Date.now and is not
// worth the effort since this method is already well known. Instead see:
// https://stackoverflow.com/questions/3830244/get-current-date-time-in-seconds
function dateToSecondsSinceEpoch (date) {
  return Math.floor(date.getTime() / 1000)
}

// Returns the current date/time in seconds.
// Does not need tests because that would require mocking Date.now and is not
// worth the effort since this method is already well known. Instead see:
// https://stackoverflow.com/questions/3830244/get-current-date-time-in-seconds
function secondsSinceEpoch () {
  return Math.floor(Date.now() / 1000)
}

function fixTimezoneAndFormat (date, removeSecondsAndZone) {
  if (!date) return
  const timezone = moment().utcOffset() / 60
  const timezoneFixed = moment(date).utcOffset(timezone)
  if (!removeSecondsAndZone) return timezoneFixed.format()
  return timezoneFixed.format('YYYY-MM-DDTHH:mm')
}

function formatWithoutYear (date) {
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    hour12: true
  })
}

function addDotBtwDateAndTime (dateString) {
  const formattedDate = dateString.replace(', ', ' · ')
  const lastSpacePosition = formattedDate.lastIndexOf(' ')

  return (
    formattedDate.substring(0, lastSpacePosition) +
    formattedDate.substring(lastSpacePosition + 1)
  )
}

function weeksToSeconds (weeks) {
  // Days, hours, minutes, seconds
  return parseFloat(weeks) * 7 * 24 * 60 * 60
}

function getTimezoneShort (seconds) {
  const date = new Date(seconds * 1000)
  const dateString = date.toLocaleString('en-US', { timeZoneName: 'long' })
  const timeZoneStartIndex = dateString.search(/( AM | PM )/)
  const timezoneLong = dateString.substring(timeZoneStartIndex + 4)
  if (timezoneLong === 'Coordinated Universal Time') return 'UTC'

  // Extract first character from each word of timezoneLong
  const timezoneShort = timezoneLong
    .split(' ')
    .map(word => word[0])
    .join('')
  return timezoneShort
}

/**
 * @param {Date|String} date A date in the format of a Date or a String
 * @returns {String} A string date in the format of (MON D, YEAR | Feb 08, 2021)
 */
function getDateStringWithComma (date) {
  const toDate = new Date(date)

  if (toDate.toString() === 'Invalid Date') return

  return toDate.toLocaleString(
    'en-US', { year: 'numeric', month: 'short', day: 'numeric' }
  )
}

/**
 * @returns date in format m-d-yyyy.
 * June 14, 2021 is returned as 06-14-2021.
 */
function getShortDateString (date) {
  const toDate = new Date(date)

  if (toDate.toString() === 'Invalid Date') return

  return toDate.toLocaleDateString(
    'en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }
  ).replace(/\//g, '-')
}

/**
 * @param {date} Date || String
 * @returns A date string in the format MM-DD-YYYY, or undefined if provided
 *   invalid Date format
 */
function getUSDateStringFormat (date) {
  const newDate = fixTimezoneAndFormat(date, true)
  if (!newDate) return

  const dateValue = newDate.split('T')[0]
  const [year, month, day] = dateValue.split('-')

  return `${month}-${day}-${year}`
}

// Returns number of seconds since epoch after substracting given hours from date.
function substractHoursFromDate (endTime, hour) {
  const date = new Date(endTime)
  date.setHours(date.getHours() - hour)
  return dateToSecondsSinceEpoch(date)
}

// Changes the underlying date object to a new time zone while preserving the
// date and the time. For example, if a UTC date of 2019-Sep-24 1am is
// given and the IANATimeZone is 'America/Los_Angeles', it will return a date
// object that is 2019-Sep-24 1am for Los Angeles.
function changeTimezone (date, IANATimezone) {
  // Create a new date in the requested timezone.
  var invdate = new Date(date.toLocaleString('en-US', {
    timeZone: IANATimezone
  }))

  // Get the difference between the given date and the new timezone.
  var diff = date.getTime() - invdate.getTime()

  // Calculate a new date adjusted using the difference.
  return new Date(date.getTime() + diff)
}

function getDaysDifference (startDate, endDate) {
  const date1 = new Date(startDate)
  const date2 = new Date(endDate)
  let diff = (date1.getTime() - date2.getTime()) / 1000
  diff /= (60 * 60 * 24)
  return Math.abs(Math.round(diff))
}

function getTimeString (date) {
  return new Date(date).toLocaleTimeString()
}

function getDate (date) {
  return date ? new Date(date) : date
}

function getDateString (date, format = 'MM/DD/YYYY') {
  if (!date) return ''

  return moment(date).format(format)
}

function getMidNightDateFromISOString (isoString) {
  return moment(isoString.split('T')[0]).toDate()
}

function getTodayMidNight () {
  const now = new Date()
  now.setHours(0, 0, 0, 0)

  return now
}

function dateToISOString (date) {
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString()
}

function getLastMonthDateFromNow () {
  const now = getTodayMidNight()
  now.setMonth(now.getMonth() - 1)

  return now
}

function getDateAfterDays (dateStart, days) {
  if (!dateStart || !days) return

  if (String(new Date(dateStart)) === 'Invalid Date') return

  const dateAfterDays = new Date(dateStart)
  dateAfterDays.setDate(dateAfterDays.getDate() + days)
  return dateAfterDays
}

function getDateAfterWeeks (dateStart, weeks) {
  if (!dateStart || !weeks) return

  if (String(new Date(dateStart)) === 'Invalid Date') return

  const WEEKDAYS = 7
  const daysDuration = WEEKDAYS * weeks
  const dateAfterWeeks = new Date(dateStart)
  dateAfterWeeks.setDate(dateAfterWeeks.getDate() + daysDuration)
  return dateAfterWeeks
}

function getMondayBeforeDate (date) {
  if (!date) return

  const mondayBeforeDate = new Date(date)
  if (mondayBeforeDate.toString() === 'Invalid Date') return

  const WEEKDAYS = 6
  const MONDAY = 1
  const SUNDAY = 0
  const dateDayOfTheWeek = mondayBeforeDate.getDay()
  const adjustWhenSunday = dateDayOfTheWeek === SUNDAY ? -WEEKDAYS : MONDAY

  const daysDifference =
    mondayBeforeDate.getDate() - dateDayOfTheWeek + adjustWhenSunday
  mondayBeforeDate.setDate(daysDifference)

  return mondayBeforeDate
}

function getFormatTime12PM (date, IANATimezone) {
  if (!date) return

  let formatTime12PMDate = new Date(date)
  if (formatTime12PMDate.toString() === 'Invalid Date') return

  formatTime12PMDate.setHours(12, 0, 0, 0)
  formatTime12PMDate = changeTimezone(formatTime12PMDate, IANATimezone)
  formatTime12PMDate = fixTimezoneAndFormat(formatTime12PMDate, true)
  return formatTime12PMDate
}

function getDefaultKpiStartDate () {
  const now = getTodayMidNight()
  now.setDate(now.getDate() - 7)

  return now
}

function addYearsToDate (date, nOfYears) {
  return new Date(date.setFullYear(date.getFullYear() + nOfYears))
}

function secondsToHHMMSS (seconds) {
  const secondsString = String(seconds % 60).padStart(2, '0')
  const minutes = Math.floor(seconds / 60)
  const minutesString = String(minutes % 60).padStart(2, '0')
  const hoursString = String(Math.floor(minutes / 60)).padStart(2, '0')
  return `${hoursString}:${minutesString}:${secondsString}`
}

function getMidNightTimeStamp (date) {
  if (isNaN(Date.parse(date))) return
  const newDate = new Date(date)
  newDate.setHours(0, 0, 0)
  return newDate.getTime()
}

function getSecondBeforeMidNightTimeStamp (date) {
  if (isNaN(Date.parse(date))) return
  const newDate = new Date(date)
  newDate.setHours(23, 59, 59)
  return newDate.getTime()
}

function standardizeToEasternTime (date) {
  if (!date || String(new Date(date)) === 'Invalid Date') return

  const convertedDate = new Date(date).toLocaleString('en-US', { timeZone: 'America/New_York' })
  const time = convertedDate.split(', ')[1].replace(/:\d\d\s/, ' ')
  const dateObj = new Date(convertedDate)

  let day = String(dateObj.getDate())
  day = day.length === 1 ? `0${day}` : day

  let month = String(dateObj.getMonth() + 1)
  month = month.length === 1 ? `0${month}` : month

  const year = String(dateObj.getFullYear()).slice(2, 4)

  return `${month}-${day}-${year} ${time}`
}

function convertEasternTimeToLocalTime (
  dateString,
  timeString = 'T23:59:00'
) {
  if (!dateString || typeof dateString !== 'string') return

  const date = new Date(dateString + timeString)
  if (!date || date.toString() === 'Invalid Date') return

  const easterTimeDate = changeTimezone(date, 'America/New_York')
  return easterTimeDate
}

function addMonthsToDate (dateString, months) {
  const date = new Date(dateString)
  if (date.toString() === 'Invalid Date') return

  const newDate = new Date(date.setMonth(date.getMonth() + months))

  return newDate
}

function diffDays (startDate, endDate, isRounded = true) {
  const date1 = new Date(startDate)
  const date2 = new Date(endDate)
  const invalidDate = date1.toString() === 'Invalid Date' ||
    date2.toString() === 'Invalid Date'
  if (invalidDate) return

  let diff = (date1.getTime() - date2.getTime()) / 1000
  diff /= (60 * 60 * 24)

  return Math.abs(isRounded ? Math.round(diff) : diff)
}

function getDateStringWithoutYear (date) {
  const toDate = new Date(date)

  if (toDate.toString() === 'Invalid Date') return

  return toDate.toLocaleDateString(
    'en-US', { month: 'short', day: '2-digit' }
  )
}

function formatDateTerm (date) {
  if (!date) return
  const parts = date.split(' ')
  const month = parts[0].substring(0, 3)
  const year = parts[1]
  const term = 'term'

  return month + ' ' + year + ' ' + term
}

function getTimeAgo (date) {
  const toDate = new Date(date)

  if (toDate.toString() === 'Invalid Date') return

  const currentDateSeconds = secondsSinceEpoch()
  const previousDateSeconds = dateToSecondsSinceEpoch(toDate)

  const elapsedSeconds = currentDateSeconds - previousDateSeconds
  const elapsedMinutes = Math.floor(elapsedSeconds / 60)
  const elapsedHours = Math.floor(elapsedMinutes / 60)

  if (elapsedSeconds < 60) {
    return elapsedSeconds + ' sec ago'
  } else if (elapsedMinutes < 60) {
    return elapsedMinutes + ' min ago'
  } else if (elapsedHours < 24) {
    return elapsedHours + ' hr ago'
  }

  // If it's more than 24 hours, return false
  return null
}

function getDateTimeWithComma (date) {
  const toDate = new Date(date)
  if (toDate.toString() === 'Invalid Date') return

  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZone: 'America/New_York',
    timeZoneName: 'short',
    hour12: true
  }
  return toDate.toLocaleDateString('en-US', dateOptions)
}

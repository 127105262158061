import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import {
  Container,
  BreadCrumb,
  PageHeading,
  Separator
} from './styled'
import Filters from './Filters'
import SubmissionsTable from './SubmissionsTable'
import {
  useWritingGradeCenterActions,
  useWritingGradeCenterContext
} from '../../contexts/WritingGradeCenterContext'
import { PAGINATION_OPTIONS } from '../../Constants/WritingGradeCenterPagination'

function WritingGradeCenter () {
  const { selectedCourse } = useWritingGradeCenterContext()
  const { setSelectedCourse } = useWritingGradeCenterActions()
  const [isLoadingGrid, setIsLoadingGrid] = useState(false)
  const [isFetchingMilestones, setIsFetchingMilestones] = useState(false)
  const [isFetchingSubmissions, setIsFetchingSubmissions] = useState(false)
  const [entriesPerPage, setEntriesPerPage] = useState(PAGINATION_OPTIONS[0])

  return (
    <Container
      padding={!selectedCourse}
    >
      { selectedCourse &&
        <BreadCrumb onClick={() => setSelectedCourse(null)} >
          <FontAwesomeIcon icon={faAngleLeft} />{' '}
          <p>Dashboard</p>
        </BreadCrumb>
      }
      <PageHeading>Writing Grade Center</PageHeading>
      <Separator />
      <Filters
        paginationOptions={PAGINATION_OPTIONS}
        setEntriesPerPage={setEntriesPerPage}
        entriesPerPage={entriesPerPage}
        isLoadingGrid={isLoadingGrid}
        setIsLoadingGrid={setIsLoadingGrid}
        setIsFetchingMilestones={setIsFetchingMilestones}
        setIsFetchingSubmissions={setIsFetchingSubmissions}
      />
      {
        selectedCourse && (
          <SubmissionsTable
            courseId={selectedCourse.id}
            entriesPerPage={entriesPerPage}
            isLoading={
              isLoadingGrid || isFetchingMilestones || isFetchingSubmissions}
          />
        )
      }
    </Container>
  )
}

export default WritingGradeCenter

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { cloneDeep, isEqual } from 'lodash'
import {
  FormGroup,
  Dropdown,
  DropdownToggle
} from 'reactstrap'
import DropdownIcon from '../../assets/icons/dropdown-arrow.svg'
import ModalComponent from '../ModalComponent/ModalComponent'
import { calculateRevisedScore, isValidUrl } from '../../utilities/gradeUtils'
import EditCodingIndividualGradeStep from './EditCodingIndividualGradeStep'
import './EditGradeModal.css'
import {
  ButtonWrapper,
  EditWrapper,
  CodingAssignmentGradeTitle,
  CodingAssignmentGrade,
  PrimaryButton,
  SecondaryButton,
  SectionSubTitle,
  SectionTitle,
  Text,
  TextArea,
  CodeGradeScoreNote,
  CodingDropdownMenu,
  CodingDropdownItem,
  DropdownWrapper
} from './styled/AssessmentModalStyles'

const changeReasons = [
  'Academic integrity violation',
  'Frontend bug or display error',
  'Answer format requirements were not clear',
  'Student error',
  'Grade updated after further review',
  'Other'
]

const selectReason = 'Please select a reason for this change'
function EditCodingIndividualGradeModal ({
  assignmentUuid,
  showModalHandler,
  show,
  stepResults,
  setEditData,
  setStepResults,
  totalScore,
  editIndex
}) {
  const { header, steps } = stepResults[editIndex]

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [notes, setNotes] = useState('')
  const [zendeskUrl, setZendeskUrl] = useState('')
  const [reason, setReason] = useState(selectReason)
  const [categorySteps, setCategorySteps] = useState(steps)
  const [updatedScore, setUpdatedScore] = useState('__')

  useEffect(() => {
    const codingGrades = cloneDeep(stepResults)
    codingGrades[editIndex].steps = categorySteps
    const newScore = calculateRevisedScore(codingGrades)
    if (newScore === totalScore) setUpdatedScore('__')
    else setUpdatedScore(newScore)
    //eslint-disable-next-line
  }, [categorySteps])

  const toggleDropdownMenu = () => setDropdownOpen((prevState) => !prevState)
  const isSubmitEnabled =
    !isEqual(steps, categorySteps) &&
    (!zendeskUrl || isValidUrl(zendeskUrl)) &&
    notes &&
    (reason !== selectReason) &&
    (updatedScore !== totalScore)

  const handleSubmit = () => {
    const codingGrades = cloneDeep(stepResults)
    codingGrades[editIndex].steps = categorySteps

    const editData = {
      assignmentUuid,
      from: totalScore,
      to: updatedScore,
      note: notes,
      reason,
      updatedStepResults: codingGrades,
      zendeskUrl,
      categoryModifications: {
        stepResultHeader: header
      }
    }
    setStepResults(codingGrades)
    setEditData(editData)
    showModalHandler()
  }

  return (
    <>
      <ModalComponent show={show} handleClose={showModalHandler}>
        <EditWrapper>
          <SectionTitle>Modifying {header}</SectionTitle>
          <CodingAssignmentGradeTitle>
            Earned Grade:
            <CodingAssignmentGrade>
              {totalScore}%
            </CodingAssignmentGrade>
          </CodingAssignmentGradeTitle>
          <CodingAssignmentGradeTitle>
            Updated Grade:
            <CodingAssignmentGrade>
              {updatedScore}%
            </CodingAssignmentGrade>
          </CodingAssignmentGradeTitle>
          {categorySteps.map((step, index) =>
            <EditCodingIndividualGradeStep
              key={step?.name}
              index={index}
              originalSteps={steps}
              categorySteps={categorySteps}
              setCategorySteps={setCategorySteps}
            />
          )}
          <CodeGradeScoreNote>
            By default, the student’s original CodeGrade score is shown in the field. 
          </CodeGradeScoreNote>
          <SectionSubTitle>
            Change Reason
          </SectionSubTitle>
          <FormGroup className='form-group'>
            <DropdownWrapper>
              <Dropdown
                className='dropdown'
                isOpen={dropdownOpen}
                toggle={toggleDropdownMenu}
              >
                <DropdownToggle
                  style={{
                    ...dropdownStyle
                  }}
                >
                  {reason}
                  <img
                    style={{
                      transform: dropdownOpen ? '' : 'rotate(180deg)',
                      transition: 'transform 0.3s'
                    }}
                    src={DropdownIcon}
                    alt='dropdown-icon'
                  />
                </DropdownToggle>

                <CodingDropdownMenu>
                  {changeReasons.map((reason, index) => (
                    <CodingDropdownItem
                      key={index}
                      onClick={() => {
                        setReason(reason)
                      }}
                    >
                      {reason}
                    </CodingDropdownItem>
                  ))}
                </CodingDropdownMenu>
              </Dropdown>
            </DropdownWrapper>
          </FormGroup>
          <SectionSubTitle>Internal notes</SectionSubTitle>
          {reason === 'Other' && (
            <Text data-testid='toggle-other-field'>
              This will be visible to students on the course homepage.
            </Text>
          )}
          <TextArea
            height='37px'
            className={
              'text-area'
            }
            value={notes}
            onChange={(e) => {
              setNotes(e.target.value)
            }}
          />
          <SectionSubTitle> Zendesk URL</SectionSubTitle>
          <TextArea
            height='42px'
            className={
              'text-area'
            }
            value={zendeskUrl}
            onChange={(e) => {
              setZendeskUrl(e.target.value)
            }}
          />
          <ButtonWrapper top='22px'>
            <PrimaryButton
              disabled={!isSubmitEnabled}
              className='btn-custom btn-primary button'
              onClick={handleSubmit}
            >
              Submit
            </PrimaryButton>
            <SecondaryButton
              className='btn-custom btn-secondary button'
              onClick={showModalHandler}
            >
              Cancel
            </SecondaryButton>
          </ButtonWrapper>
        </EditWrapper>
      </ModalComponent>
    </>
  )
}

const dropdownStyle = {
  width: '100%',
  border: '1px solid',
  background: '#1D1D1F',
  boxSizing: 'border-box',
  borderRadius: '5px',
  display: 'flex',
  borderColor: '#77B1AF',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '19px',
  marginBottom: '12px',
  height: '40px'
}

EditCodingIndividualGradeModal.propTypes = {
  editIndex: PropTypes.number.isRequired,
  preferredName: PropTypes.string.isRequired,
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  stepResults: PropTypes.array.isRequired
}
EditCodingIndividualGradeModal.displayName = 'EditCodingIndividualGradeModal'
export default EditCodingIndividualGradeModal

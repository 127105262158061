import { ENROLLED, PRE_DROP, SIGNED_UP } from '../../../Constants/studentStatus'

export function addLabels (records, labelKey = 'name') {
  if (!records?.length) return []

  return records.map((record = {}) => {
    if (!record?.[labelKey]) return record
    return {
      ...record,
      label: record?.[labelKey],
      value: record?.[labelKey]
    }
  })
}

export function getSortedProspects (prospects = [], key) {
  if (!prospects?.length) return []
  return prospects.sort((a, b) =>
    !a?.[key] ? 1 : !b?.[key] ? -1 : new Date(b?.[key]) - new Date(a?.[key])
  )
}

export function processProspects (prospectsData = []) {
  if (!prospectsData?.length) return []

  return prospectsData.map(prospect => processProspect(prospect))
}

export function processProspect (prospect = {}) {
  if (!prospect?.firstName && !prospect?.email) return prospect

  const {
    firstName,
    middleName,
    lastName
  } = prospect || {}

  const tempCart = prospect?.tempCart?.map(i => ({
    id: i?.id,
    ...(i?.fields || {})
  })) || []

  return {
    ...prospect,
    name: `${firstName || ''} ${lastName || ''}`.trim(),
    fullName: `${firstName || ''} ${middleName ? middleName + ' ' : ''}${lastName || ''}`.trim(),
    tempCart,
    selectedCohorts: tempCart
  }
}

export function getFilteredProspects (prospects = [], filters) {
  if (!prospects?.length) return []

  const filteredProspects = prospects.filter(prospect => {
    const {
      email, gguEmail, studentId, gguStudentId
    } = prospect || {}
    const gguId = `0${gguStudentId}`

    const isSearchMatch = filters?.searchText
      ? [email, gguEmail, studentId, gguId].some(field =>
          field?.toLowerCase()?.includes(filters?.searchText?.toLowerCase()))
      : true

    const isDegreeMatch =
      filters?.degrees?.find(degree => prospect?.degree === degree?.name)

    const isAdvisorMatch =
      filters?.advisors?.find(advisor => prospect?.advisorName === advisor?.advisorName)

    return isSearchMatch && isDegreeMatch && isAdvisorMatch
  })

  return filteredProspects
}

export function getBeforeDropCohorts (cohorts = []) {
  if (!cohorts?.length) return []

  const filteredCohorts = cohorts.filter(cohort => {
    const isNotTest = !cohort?.testCohort
    const isBeforeDropDeadline = new Date(cohort?.finalDropDate).getTime() > Date.now()
    return isNotTest && isBeforeDropDeadline
  })

  return filteredCohorts.map(cohort => ({ ...cohort, id: cohort?.at_id }))
}

export function getCohortsFromAttempts (attempts = []) {
  if (!attempts?.length) return []

  return attempts.map(attempt => {
    const cohort = attempt?.fields?.cohort || {}
    if (!cohort?.fields?.name) return {}

    return {
      id: cohort?.id,
      ...cohort?.fields,
      studentStatus: attempt?.fields?.studentStatus?.fields?.Name
    }
  })
}

export function getActiveAttemptsCohorts (attempts = [], relationship = '') {
  if (!attempts?.length) return []

  const cohorts = getCohortsFromAttempts(attempts)
  if (!cohorts?.length) return []

  if (relationship) {
    cohorts.filter(
      cohort => cohort?.relationshipName?.toLowerCase() === relationship
    )
  }

  return cohorts.filter(
    cohort => [SIGNED_UP, PRE_DROP].includes(cohort?.studentStatus)
  )
}

export function getInprogressAttemptsCohorts (attempts = [], relationship = '') {
  if (!attempts?.length) return []

  const cohorts = getCohortsFromAttempts(attempts)
  if (!cohorts?.length) return []

  if (relationship) {
    cohorts.filter(
      cohort => cohort?.relationshipName?.toLowerCase() === relationship
    )
  }

  return cohorts.filter(
    cohort => [ENROLLED, PRE_DROP].includes(cohort?.studentStatus)
  )
}

export function extractFields (records = []) {
  if (!records?.length) return []

  return records.map(record => {
    if (!record?.fields) return record
    return { id: record?.id, ...(record?.fields || {}) }
  })
}

import React, { useEffect, useState } from 'react'
import {
  AttemptRowWrapper,
  AttemptsTableWrapper,
  ChevronIcon,
  HeaderCell,
  HeaderRow,
  NoRecords,
  ShowAllButton,
  StudentAttemptRecordsHeader,
  StudentAttemptRecordsWrapper,
  StudentCell,
  StudentRow,
  TableHeaderWrapper
} from './style'
import graphIcon from '../../../assets/icons/graph.svg'
import filledGraphIcon from '../../../assets/icons/filled-graph.svg'
import { getDateString } from '../../../utilities/dateTimeUtil'
import ellipseIcon from '../../../assets/icons/ellipse.svg'
import chevronDownIcon from '../../../assets/icons/green-chevron-down.svg'

const StudentAttemptRecords = ({ studentEmail, attempts, attemptsScrollRef }) => {
  const [sortedAttempts, setSortedAttempts] = useState([])
  const [visibleAttempts, setVisibleAttempts] = useState([])
  const [isExpanded, setIsExpanded] = useState(false)
  const [hoveredRow, setHoveredRow] = useState(null)

  useEffect(() => {
    const sortedAttempts = attempts.sort(
      (a, b) => new Date(b.fields.createdAt) - new Date(a.fields.createdAt)
    )
    setSortedAttempts(sortedAttempts)
    setVisibleAttempts(sortedAttempts.slice(0, 4))
  }, [attempts])

  const location = window.location
  const navigateToGradeDetails = (courseId, courseName, cohortName) => {
    const link = `${location.origin}/#/student-grade-detail/${courseId}/${courseName}/${studentEmail}/${encodeURIComponent(cohortName)}`
    const newWindow = window.open(link, '_blank')
    newWindow.focus()
  }

  const toggleShowAll = () => {
    setVisibleAttempts(isExpanded ? sortedAttempts.slice(0, 4) : sortedAttempts)
    setIsExpanded(!isExpanded)
  }

  const handleMouseEnter = (rowIndex) => {
    setHoveredRow(rowIndex)
  }

  const handleMouseLeave = () => {
    setHoveredRow(null)
  }

  return (
    <StudentAttemptRecordsWrapper ref={attemptsScrollRef}>
      <StudentAttemptRecordsHeader>Attempt records</StudentAttemptRecordsHeader>
      <AttemptsTableWrapper>
        <TableHeaderWrapper>
          <HeaderRow>
            <HeaderCell>course</HeaderCell>
            <HeaderCell>cohort</HeaderCell>
            <HeaderCell>status</HeaderCell>
            <HeaderCell>relationship</HeaderCell>
            <HeaderCell>certificate</HeaderCell>
            <HeaderCell>attempt no.</HeaderCell>
            <HeaderCell />
          </HeaderRow>
        </TableHeaderWrapper>
        { !attempts?.length &&
          <NoRecords>
            No attempt records.
          </NoRecords>
        }
        { attempts?.length > 0 &&
        <AttemptRowWrapper data-testid='extensionsData'>
          {visibleAttempts.map((attempt, index) => {
            const {
              fields: {
                course: {
                  fields: {
                    id: courseId, name: courseName, displayName: courseDisplayName
                  } = {}
                },
                cohort: { fields: { dateStart, duration, name: cohortName } = {} },
                studentStatus: { fields: { Name: studentStatus } = {} },
                relationshipName,
                certificateName,
                attemptID
              }
            } = attempt

            const formattedStartDate = getDateString(dateStart, 'MM/DD/YY')

            return (
              <StudentRow key={index}>
                <StudentCell>{courseDisplayName}</StudentCell>
                <StudentCell>
                  <span>{formattedStartDate}</span>
                  <img src={ellipseIcon} alt='ellipse' />
                  {` ${duration}wk`}
                </StudentCell>
                <StudentCell>{studentStatus}</StudentCell>
                <StudentCell>{relationshipName || '--'}</StudentCell>
                <StudentCell>{certificateName || '--'}</StudentCell>
                <StudentCell>{attemptID}</StudentCell>
                <StudentCell>
                  <img
                    src={hoveredRow === index ? filledGraphIcon : graphIcon}
                    alt='graph-icon'
                    onMouseEnter={() => { handleMouseEnter(index) }}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => { navigateToGradeDetails(courseId, courseName, cohortName) }}
                  />
                </StudentCell>
              </StudentRow>
            )
          })}
        </AttemptRowWrapper>}
      </AttemptsTableWrapper>
      { attempts?.length >= 4 &&
      <ShowAllButton onClick={() => {
        toggleShowAll()
      }}
      >
        <ChevronIcon
          src={chevronDownIcon}
          alt='chevron-down-icon'
          isExpanded={isExpanded}
        />
        show {isExpanded ? 'less' : 'all'}
      </ShowAllButton>}
    </StudentAttemptRecordsWrapper>
  )
}
StudentAttemptRecords.displayName = 'StudentAttemptRecords'
export default StudentAttemptRecords

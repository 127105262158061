import React, { useState, useEffect } from 'react'
import Button from '../Button/Button'
import api from '../../api'
import { sortByDateDescOrder } from '../../utilities/cohortUtils'
import { useCoursesContext, useCoursesActions } from '../../contexts/Courses'
import { sortBy } from 'lodash'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import AlertModal from '../AlertModal/AlertModal'
import { validateEmail } from '../../utilities/userUtils'
import { EXIT_STATUSES } from '../../utilities/studentStatusUtils'
import { filterAttemptsByStatus } from '../../utilities/attemptUtils'
import { additionalCourseIdToName } from '../../config'
import {
  ASTRONOMY_NAME,
  ASTRONOMY_V2_NAME,
  COLLEGE_WRITING_I_GGU_V1_NAME, COLLEGE_WRITING_I_GGU_V2_NAME
} from '../../Constants/courses'
import { CWI_DATE_OVERLAP_VALUE, DATE_OVERLAP_VALUE } from '../../Constants'
import { getSortedCourses } from '../../utilities/gradeReportUtils'
import { courseSelectStyles } from '../global.styled'
import {
  Container,
  CustomCheckBox,
  CustomFormGroup,
  CustomInput,
  CustomLabel,
  CustomSelect,
  ErrorText,
  Title
} from './styled'

export default function CohortTransfer () {
  const [studentId, setStudentId] = useState('')
  const [oldStudentId, setOldStudentId] = useState()
  const [oldCohortName, setOldCohortName] = useState()
  const [courses, setCourses] = useState([])
  const [oldCourseName, setOldCourseName] = useState()
  const [oldCourseID, setOldCourseID] = useState('')
  const [newCohortName, setNewCohortName] = useState()
  const [newCourseName, setNewCourseName] = useState()
  const [error, setError] = useState(false)
  const [cohortData, setCohortData] = useState([])
  const [newCohortData, setNewCohortData] = useState([])
  const [resetProgress, setResetProgress] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [isAlertModal, setAlertModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingStudentCourses, setIsLoadingStudentCourses] = useState(false)
  const [messageOnRequest, setMessageOnRequest] = useState('')
  const [studentStatus, setStudentStatus] = useState('')
  const [statusNotesOptions, setStatusNotesOptions] = useState('')
  const [studentStatuses, setStudentStatuses] = useState('')
  const [statusNote, setStatusNote] = useState('')
  const [comments, setComments] = useState('')
  const [studentCourses, setStudentCourses] = useState([])
  const [isCoursesNotFound, setIsCoursesNotFound] = useState(false)

  const { fetchCourses } = useCoursesActions()
  const { allCourses } = useCoursesContext()

  useEffect(() => {
    if (!allCourses.length) {
      fetchCourses()
      return
    }
    const filteredCourses = getSortedCourses(allCourses)
      .filter(course => course.label !== 'Bundle' && course.name !== COLLEGE_WRITING_I_GGU_V2_NAME)
    setCourses(filteredCourses)
    fetchStatuses()
    // eslint-disable-next-line
  }, [allCourses])

  const clearAllFields = () => {
    setStudentId('')
    setOldCourseName('')
    setOldCohortName('')
    setNewCohortName('')
    setNewCourseName('')
    setNewCohortData([])
    setCohortData([])
    setStudentStatus('')
    setStatusNote('')
    setComments('')
    setStudentCourses([])
    setError(false)
    setResetProgress(false)
  }

  const submit = () => {
    setMessageOnRequest('')
    if (
      !studentId || !oldCourseName || !oldCohortName ||
      !newCourseName || !newCohortName || !statusNote ||
      !studentStatus || (oldCohortName === newCohortName)
    ) return setError(true)
    setShowModal(true)
  }

  const resolveSelectOptions = (option) => ({
    value: option.id,
    label: option.name
  })

  const filterStudentStatuses = ({ label }) => {
    return EXIT_STATUSES.includes(label)
  }

  const fetchStatuses = async () => {
    setIsLoading(true)
    const [studentStatuses, statusNotes] = await Promise.all([
      api.getStudentStatuses(),
      api.getStatusNotes()
    ])

    const sortedStudentStatuses = sortBy(
      studentStatuses, (status) => status.name
    )

    const sortedStatusNotes = sortBy(
      statusNotes, (status) => status.name
    )

    const filteredStatuses = sortedStudentStatuses
      .map(resolveSelectOptions)
      .filter(filterStudentStatuses)

    setStudentStatuses(filteredStatuses)
    setStatusNotesOptions(sortedStatusNotes.map(resolveSelectOptions))
    setIsLoading(false)
  }

  const resetStudentCourseStates = () => {
    setOldCourseName('')
    setStudentCourses([])
    setCohortData([])
    setOldCohortName('')
  }

  const fetchStudentCourses = async () => {
    if (!studentId || studentId === oldStudentId) {
      if (!studentId) {
        resetStudentCourseStates()
        setOldStudentId()
      }
      return setIsLoadingStudentCourses(false)
    }

    setOldStudentId(studentId)
    setIsLoadingStudentCourses(true)
    resetStudentCourseStates()
    const response = await api.getStudentAttempts({
      [validateEmail(studentId) ? 'studentEmail' : 'studentId']: studentId
    })
    const filteredAttemptsCourses = filterAttemptsByStatus(response)
    if (!filteredAttemptsCourses?.length) {
      setIsCoursesNotFound(true)
      setOldCourseName('')
      return setIsLoadingStudentCourses(false)
    }
    setStudentCourses(filteredAttemptsCourses)
    setOldCourseName(filteredAttemptsCourses[0].label)
    setOldCourseID(filteredAttemptsCourses[0].value)
    setIsLoadingStudentCourses(false)
  }

  useEffect(() => {
    if (!studentCourses.length) return
    fetchCohortData(studentCourses[0].value)
    // eslint-disable-next-line
  }, [studentCourses])

  const onConfirmation = async () => {
    setIsLoading(true)
    setShowModal(false)
    let courseName = newCourseName
    const { putCohortTransfer } = api
    if (courseName === ASTRONOMY_NAME) {
      const cohortDetails = newCohortData.find(({ label }) => label === newCohortName)
      if (new Date(cohortDetails?.dateStart) >= new Date(DATE_OVERLAP_VALUE)) {
        courseName = ASTRONOMY_V2_NAME
      }
    }
    if (courseName === COLLEGE_WRITING_I_GGU_V1_NAME) {
      const cohortDetails = newCohortData.find(({ label }) => label === newCohortName)
      if (new Date(cohortDetails?.dateStart) >= new Date(CWI_DATE_OVERLAP_VALUE)) {
        courseName = COLLEGE_WRITING_I_GGU_V2_NAME
      }
    }
    const cohortTransferData = {
      oldCourseName,
      newCourseName: courseName,
      oldCohortName,
      newCohortName,
      statusNote: statusNote.value,
      studentStatus: studentStatus.value,
      comments,
      resetProgress
    }
    const response = await putCohortTransfer(studentId, oldCourseID, cohortTransferData)
    const { success, message } = response
    if (!success) {
      setMessageOnRequest(message)
    }
    setIsLoading(false)
    setShowModal(false)
    setAlertModal(true)
    setIsCoursesNotFound(false)
  }

  const fetchCohortData = async (courseId, key) => {
    if (key === 'newCourse') {
      const additionalIds = additionalCourseIdToName(courseId)
      let cohortData = await Promise.all(additionalIds.map(id => api.getCohortList(id)))
      cohortData = cohortData.flat()
      if (!cohortData.length) return
      sortByDateDescOrder(cohortData)
      const defaultValue = cohortData[0].name
      const cohorts = cohortData.map(cohort =>
        ({ value: cohort.name, label: cohort.name, dateStart: cohort.dateStart }))
      setNewCohortData(cohorts)
      setNewCohortName(defaultValue)
    } else {
      const selectedCourseCohorts = studentCourses
        .find(course => course.value === courseId)?.cohorts || []
      const preparedCohorts = selectedCourseCohorts.map(cohort => ({
        value: cohort.id,
        label: cohort.name
      }))
      setCohortData(preparedCohorts)
      preparedCohorts[0] && setOldCohortName(preparedCohorts[0].label)
    }
  }

  const onAlertClose = () => {
    setAlertModal(false)
    if (!messageOnRequest) clearAllFields()
  }

  return (
    <Container>
      <Title>Transfer Student Cohort</Title>
      <CustomFormGroup>
        <CustomLabel>Student ID or Email</CustomLabel>
        <CustomInput
          id='student-field'
          type='text'
          value={studentId}
          onChange={e => setStudentId(e.target.value)}
          onBlur={() => fetchStudentCourses()}
        />
        {error && !studentId && <ErrorText className='error'>
          Please fill with Student ID or Email</ErrorText>}
        <CustomLabel>Select Prior Course</CustomLabel>
        <CustomSelect
          isLoading={isLoadingStudentCourses}
          id='course-field'
          options={studentCourses}
          isDisabled={!studentCourses.length}
          value={oldCourseName && { value: oldCourseName, label: oldCourseName }}
          onChange={course => {
            setOldCourseName(course.label)
            setOldCourseID(course.value)
            fetchCohortData(course.value)
          }}
          placeholder='Select Course'
          styles={courseSelectStyles}
          classNamePrefix='select'
        />
        {error && !oldCourseName && !isCoursesNotFound &&
          <ErrorText className='error'>Please Select the Prior Course</ErrorText>}
        {isCoursesNotFound && !studentCourses.length &&
          <ErrorText className='error'>Courses Not Found</ErrorText>}
        <CustomLabel>Select Prior Cohort</CustomLabel>
        <CustomSelect
          isLoading={isLoadingStudentCourses}
          id='cohort-field'
          options={cohortData}
          value={oldCohortName && { value: oldCohortName, label: oldCohortName }}
          isDisabled={!oldCourseName && !cohortData.length}
          onChange={cohort => {
            setOldCohortName(cohort.label)
          }}
          placeholder='Select Cohort'
          styles={courseSelectStyles}
          classNamePrefix='select'
        />
        <CustomLabel>Select Student Status for Prior Cohort</CustomLabel>
        <CustomSelect
          id='student-status'
          options={studentStatuses}
          isLoading={!studentStatuses.length && isLoading}
          value={studentStatus}
          onChange={studentStatus => setStudentStatus(studentStatus)}
          placeholder='Select Student Status'
          styles={courseSelectStyles}
          classNamePrefix='select'
        />
        {error && !studentStatus && <ErrorText className='error'>
          Please Select student Status for Prior Cohort</ErrorText>}
        <CustomLabel>Select Status Note for Prior Cohort</CustomLabel>
        <CustomSelect
          id='note-status'
          options={statusNotesOptions}
          isLoading={!statusNotesOptions.length && isLoading}
          value={statusNote}
          onChange={statusNote => setStatusNote(statusNote)}
          placeholder='Select Status Note'
          styles={courseSelectStyles}
          classNamePrefix='select'
        />
        {error && !statusNote && <ErrorText className='error'>
          Please Select the Status for Prior Cohort</ErrorText>}
        <CustomLabel>Select New Course</CustomLabel>
        <CustomSelect
          id='new__course-field'
          options={courses}
          isDisabled={!oldCohortName}
          value={newCourseName && { value: newCourseName, label: newCourseName }}
          onChange={course => {
            setNewCourseName(course.label)
            fetchCohortData(course.value, 'newCourse')
          }}
          placeholder='Select Course'
          styles={courseSelectStyles}
          classNamePrefix='select'
        />
        <CustomLabel>Select New Cohort</CustomLabel>
        <CustomSelect
          id='new__cohort-field'
          options={newCohortData}
          value={newCohortName && { value: newCohortName, label: newCohortName }}
          isDisabled={!newCourseName}
          onChange={cohort => setNewCohortName(cohort.label)}
          placeholder='Select Cohort'
          styles={courseSelectStyles}
          classNamePrefix='select'
        />
        {(error && oldCohortName) && oldCohortName === newCohortName &&
          <ErrorText className='error'>Prior Cohort and New Cohort are same</ErrorText>
        }
        <CustomLabel>Comments</CustomLabel>
        <CustomInput
          id='comments'
          type='text'
          value={comments}
          onChange={e => setComments(e.target.value)}
        />
        <CustomCheckBox>
          <CustomLabel for='progressCheckbox'>Reset progress for the prior cohort</CustomLabel>
          <input
            type='checkbox'
            value={resetProgress}
            id='progressCheckbox'
            onClick={() => setResetProgress(!resetProgress)}
          />
          <span />
        </CustomCheckBox>
        <ConfirmationModal
          showModalHandler={() => setShowModal(false)}
          show={showModal}
          confirm={{ text: 'Transfer', handler: () => onConfirmation() }}
          cancel={{ text: 'Cancel', handler: () => setShowModal(false) }}
        >
          {resetProgress
            ? <span>
              This student will be transferred
              and their progress will be reset
              <br />Do you want to continue?
            </span>
            : <span className='transfer-cohort-short-message'>
              This student will be transferred.
              <br />Do you want to continue?
            </span>}
        </ConfirmationModal>
        <AlertModal
          showModalHandler={() => onAlertClose()}
          confirm={{ text: 'ok', handler: () => onAlertClose() }}
          show={isAlertModal}
        >
          {!messageOnRequest
            ? <span>
              {studentId} has been successfully transferred
              from  "{oldCohortName}" to "{newCohortName}"
            </span>
            : <span className='transfer-cohort-short-message'>
              {messageOnRequest}
            </span>
          }
        </AlertModal>
        {(oldCourseName &&
          newCourseName &&
          oldCohortName &&
          newCohortName) && isLoading
          ? <i className='fa fa-spinner fa-spin' />
          : <Button className='submit-button btn-custom btn-primary' onClick={submit} >
            Submit
          </Button>}
      </CustomFormGroup>
      <hr />
    </Container>
  )
}

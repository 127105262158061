import { useEffect } from 'react'

// effect is the function you want to run
export const useDebouncedEffect = (
  effect,
  dependencies,
  delayInMilliseconds
) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delayInMilliseconds)

    return () => clearTimeout(handler)
    // eslint-disable-next-line
  }, [...(dependencies || []), delayInMilliseconds])
}

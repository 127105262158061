import styled from 'styled-components'
import clearIcon from '../../../assets/icons/clear-icon.svg'
import mediaqueries from '../../../mediaQueries'

export const PageWrapper = styled.div`
  margin: 64px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const HeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const GGUApplicationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const SearchField = styled.div`
  width: 400px;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 24px;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #5B6067;
  background: #0D0D0F;
`

export const SearchTextField = styled.input`
  width: 100%;
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  background-color: inherit;
  border: none;
  &::placeholder{
    color: #5B6067;
  }
  &:focus {
    outline: none;
  }
`

export const SearchIcon = styled.img`
  ${props => props.src === clearIcon && `
      cursor: pointer;
  `}
`

export const SearchNote = styled.span`
  color: #98A4AE;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
`

export const ApplicationsTableWrapper = styled.div`
  display: flex;
  overflow: auto;

  & {

    &::-webkit-scrollbar {
      height: 8px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--border-secondary);
      border-radius: 10px;
      visibility: visible;
    }
  }
`

export const ApplicationsTableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${mediaqueries.tablet`
    width: fit-content;
  `}
`

export const ApplicationsTableHeader = styled.div`
  display: flex;
  padding: 4px 16px 16px 12px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
`

export const ApplicationsColumnHeader = styled.span`
  width: ${props => props.width}px;
  color: #B1BFC5;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 114.286% */
  letter-spacing: 1px;
  text-transform: uppercase;
`

export const ApplicationsTableDefaultText = styled.span`
  padding: 28px 12px 12px 12px;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 24px; /* 133.333% */
`

export const ApplicationRow = styled.div`
  display: flex;
  padding: 16px 16px 16px 12px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
  cursor: pointer;
  
  &:hover {
    background-color: #1D1D1F;
  }
`

export const ApplicationsRowField = styled.span`
  width: ${props => props.width}px;
  color:  #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const IconContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: end;
`

export const CustomIcon = styled.img`
  cursor: pointer;
  align-self: flex-end;
`

export const SortIcon = styled.img`
  margin-left: 10px;
  cursor: pointer;
  transform: ${props => (props.isSortedAscending ? 'rotate(180deg)' : 'rotate(0deg)')};
`

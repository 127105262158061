import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { jsPDF as JSPDF } from 'jspdf'
import { useHistory } from 'react-router-dom'
import {
  VoucherListContainer,
  Header,
  Cell,
  LoaderContainer,
  Table
} from '../StudentLevel/styled'
import { InfoContainer, Separator } from '../styled'
import StudentDetails from '../StudentDetails'
import VoucherDetails from '../VoucherDetails'
import {
  VoucherContainer,
  NoVouchersHeading,
  StyledButtonsWrapper,
  StyledButton
} from './styled'
import api from '../../../api'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import { getAmazonVoucherEvent, getStudentDetails, getVoucherDetails } from '../utils'
import { getDateString } from '../../../utilities/dateTimeUtil'
import { getDownloadFileName, getInvoiceText } from '../../../utilities/voucher'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import {
  AMAZON_CANCELLED_BY_STUDENT_EVENT,
  AMAZON_PAYMENT_REQUEST_APPROVED_EVENT,
  AMAZON_PAYMENT_REQUEST_PENDING_EVENT,
  AMAZON_PAYMENT_REQUEST_REJECTED_EVENT,
  AMAZON_REJECTED_BY_AMAZON_EVENT,
  AMAZON_VOUCHER_APPROVED_EVENT,
  AMAZON_VOUCHER_REJECTED_EVENT
} from '../../../Constants/voucher'

export const STATUS_APPROVED = 'approved'
export const STATUS_DENIED = 'denied'
export const STATUS_CANCELLED_BY_STUDENT = 'cancelled_by_student'
export const STATUS_REJECTED_BY_AMAZON = 'rejected_by_amazon'
export const STATUS_APPROVED_PAYMENT_REQUEST = 'approved_payment_request'
export const STATUS_REJECTED_PAYMENT_REQUEST = 'outstanding_payment_request'
export const STATUS_PENDING_PAYMENT_REQUEST = 'pending_payment_request'

export const APPROVED_STATUS = 'Approved Voucher'
export const OUTSTANDING_STATUS = 'Outstanding Voucher'
export const PENDING_STATUS = 'Pending Approval'
export const CANCELLED_BY_STUDENT_STATUS = 'Cancelled by Student'
export const REJECTED_BY_AMAZON_STATUS = 'Rejected by Amazon'
export const OUTSTANDING_PAYMENT_REQUEST_STATUS = 'Outstanding Payment Request'
export const APPROVED_PAYMENT_REQUEST_STATUS = 'Approved Payment Request'
export const PENDING_PAYMENT_REQUEST_STATUS = 'Pending Payment Request'

const NON_CANCELLED_STATUSES = [
  APPROVED_STATUS, OUTSTANDING_STATUS, PENDING_STATUS
]
const DO_NOT_SHOW_NON_CANCEL_BUTTONS = [
  APPROVED_STATUS, CANCELLED_BY_STUDENT_STATUS, REJECTED_BY_AMAZON_STATUS
]

export const mappedStatuses = {
  [STATUS_APPROVED]: APPROVED_STATUS,
  [STATUS_DENIED]: OUTSTANDING_STATUS,
  [STATUS_CANCELLED_BY_STUDENT]: CANCELLED_BY_STUDENT_STATUS,
  [STATUS_REJECTED_BY_AMAZON]: REJECTED_BY_AMAZON_STATUS,
  [STATUS_APPROVED_PAYMENT_REQUEST]: APPROVED_PAYMENT_REQUEST_STATUS,
  [STATUS_REJECTED_PAYMENT_REQUEST]: OUTSTANDING_PAYMENT_REQUEST_STATUS,
  [STATUS_PENDING_PAYMENT_REQUEST]: PENDING_PAYMENT_REQUEST_STATUS
}

export const mapAmazonEventType = {
  [STATUS_APPROVED]: AMAZON_VOUCHER_APPROVED_EVENT,
  [STATUS_DENIED]: AMAZON_VOUCHER_REJECTED_EVENT,
  [STATUS_CANCELLED_BY_STUDENT]: AMAZON_CANCELLED_BY_STUDENT_EVENT,
  [STATUS_REJECTED_BY_AMAZON]: AMAZON_REJECTED_BY_AMAZON_EVENT,
  [STATUS_APPROVED_PAYMENT_REQUEST]: AMAZON_PAYMENT_REQUEST_APPROVED_EVENT,
  [STATUS_REJECTED_PAYMENT_REQUEST]: AMAZON_PAYMENT_REQUEST_REJECTED_EVENT,
  [STATUS_PENDING_PAYMENT_REQUEST]: AMAZON_PAYMENT_REQUEST_PENDING_EVENT
}

const HEADERS = [
  {
    label: 'Course',
    key: 'courseName'
  },
  {
    label: 'Start Date',
    key: 'dateStart'
  },
  {
    label: 'End Date',
    key: 'dateEnd'
  },
  {
    label: 'action',
    key: 'button'
  }
]

const VoucherLevel = ({ studentId, voucherId }) => {
  const [apiCallInProgress, setApiCallInProgress] = useState(false)
  const [showModal, setShowModal] = useState()
  const [response, setResponse] = useState()
  const [currentStatus, setStatus] = useState()

  const [voucher, setVoucher] = useState(null)
  const [studentDetails, setStudentDetails] = useState()
  const [voucherDetails, setVoucherDetails] = useState()
  const [amazonTransfers, setAmazonTransfers] = useState()
  const [file, setFile] = useState(null)
  const [isLoading, setLoading] = useState(true)
  const [courses, setCourses] = useState()
  const [denialStatus, setDenialStatus] = useState()
  const history = useHistory()

  const readFileContent = (data, fileType = 'application/pdf') => {
    const blob = new Blob([data], { type: fileType })
    setFile({
      url: URL.createObjectURL(blob),
      type: fileType
    })
  }

  const voucherHandler = async (status, denialNotes) => {
    setApiCallInProgress(true)
    const isDenied = status === STATUS_DENIED ||
      status === STATUS_REJECTED_PAYMENT_REQUEST

    const eventType = mapAmazonEventType[status]

    const denialNote = denialNotes?.map(({ value }) => value)

    const res = await api.setVoucherStatus(voucherId, {
      status,
      ...(isDenied && { denialNote })
    })
    setApiCallInProgress(false)
    setShowModal(true)
    if (!res) {
      return setResponse('failed')
    }
    setStatus(status)
    setResponse('success')
    api.submitTrackedEvent(getAmazonVoucherEvent(eventType, studentId))
  }

  const fetchVoucherDetails = async () => {
    const [data, amazonTransfers, voucherFile] = await Promise.all([
      api.fetchVoucherDetails(studentId, voucherId),
      api.getVoucherAttempts(studentId, voucherId),
      api.fetchVoucherFile(voucherId)
    ])
    voucherFile && readFileContent(voucherFile)
    if (data) {
      const { courses, ...res } = data
      setVoucher(data)
      amazonTransfers && setAmazonTransfers(amazonTransfers)
      setStudentDetails(getStudentDetails(res))
      setVoucherDetails(getVoucherDetails(res))
      setCourses(courses)
      return setLoading(false)
    }
    return history.push({
      pathname: '/voucher'
    })
  }

  useEffect(() => {
    fetchVoucherDetails()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    voucherDetails && updateStatus(currentStatus)
    // eslint-disable-next-line
  }, [currentStatus])

  const updateStatus = (currentStatus) => {
    const updatedStatus = mappedStatuses[currentStatus]
    setVoucherDetails({
      ...voucherDetails,
      voucherStatus: updatedStatus
    })
  }

  const handleDownloadInvoice = () => {
    const doc = new JSPDF()

    doc.text(getInvoiceText(voucher), 10, 10)
    doc.save(getDownloadFileName(voucher))
  }

  const showNonCancellationButtons =
    file && !DO_NOT_SHOW_NON_CANCEL_BUTTONS.includes(voucherDetails?.voucherStatus)

  const showCancellationButtons =
    NON_CANCELLED_STATUSES.includes(voucherDetails?.voucherStatus)

  if (isLoading) {
    return (
      <LoaderContainer>
        <LoadingSpinner position='relative' />
      </LoaderContainer>
    )
  }

  return (
    <>
      <ConfirmationModal
        submit={(denialNotes) => voucherHandler(denialStatus, denialNotes)}
        showModalHandler={() => setShowModal(!showModal)}
        setApiCallInProgress={setApiCallInProgress}
        apiCallInProgress={apiCallInProgress}
        setResponse={setResponse}
        response={response}
        show={showModal}
      />
      <InfoContainer>
        <StudentDetails {...studentDetails} />
        <VoucherDetails {...voucherDetails}
          amazonTransfers={amazonTransfers} />
      </InfoContainer>
      <Separator />
      <VoucherListContainer id='voucher-level'>
        <Table>
          <thead>
            <tr>
              {HEADERS.map(({ label, key }) => (
                <Header key={key} label={label}>
                  {label}
                </Header>
              ))}
            </tr>
          </thead>
          <tbody>
            {courses && courses.map((node) => {
              const { courseName } = node
              return (
                <tr key={courseName}>
                  {HEADERS.map(({ key }) => {
                    const isButton = key === 'button'
                    const isDateField = ['dateEnd', 'dateStart'].includes(
                      key
                    )
                    const formattedDate =
                        isDateField && getDateString(node[key])
                    return (
                      <Cell
                        {...(isButton && { width: '100' })}
                        className='cell'
                        keyName={key}
                        key={courseName + key}
                      >
                        {formattedDate || node[key]}
                      </Cell>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </VoucherListContainer>
      <VoucherContainer>
        {file ? (
          <iframe
            src={file.url}
            title='voucher'
            style={{ width: '100%', height: '100vh' }}
          />
        ) : (
          <NoVouchersHeading>Voucher not uploaded.</NoVouchersHeading>
        )}
        <StyledButtonsWrapper>
          {showNonCancellationButtons && (
            <>
              <div>
                <StyledButton
                  onClick={() => {
                    voucherHandler(STATUS_APPROVED)
                  }}
                  className='btn-custom btn-primary'
                  disabled={apiCallInProgress}
                  bottomMargin
                >
                  {!apiCallInProgress ? (
                    'Approve'
                  ) : (
                    <i className='fa fa-spinner fa-spin' />
                  )}
                </StyledButton>
              </div>
              <div>
                <StyledButton
                  className='btn-custom btn-primary'
                  onClick={() => {
                    setDenialStatus(STATUS_DENIED)
                    setShowModal(true)
                  }}
                  disabled={apiCallInProgress}
                >
                Deny
                </StyledButton>
              </div>
            </>
          )}
          <div>
            <StyledButton
              className='btn-custom btn-primary'
              onClick={handleDownloadInvoice}
            >
              Download Invoice
            </StyledButton>
          </div>

          {showCancellationButtons && (
            <>
              <div>
                <StyledButton
                  onClick={() => { voucherHandler(STATUS_CANCELLED_BY_STUDENT) }}
                  className='btn-custom btn-primary'
                  disabled={apiCallInProgress}
                  fontSize='13.6px'
                >
                  {apiCallInProgress
                    ? <i className='fa fa-spinner fa-spin' />
                    : 'cancelled by student'
                  }
                </StyledButton>
              </div>

              <div>
                <StyledButton
                  onClick={() => { voucherHandler(STATUS_REJECTED_BY_AMAZON) }}
                  className='btn-custom btn-primary'
                  disabled={apiCallInProgress}
                >
                  {apiCallInProgress
                    ? <i className='fa fa-spinner fa-spin' />
                    : 'rejected by amazon'
                  }
                </StyledButton>
              </div>
            </>
          )}
          <div>
            <StyledButton
              onClick={() => { voucherHandler(STATUS_APPROVED_PAYMENT_REQUEST) }}
              className='btn-custom btn-primary'
              disabled={apiCallInProgress}
            >
              {apiCallInProgress
                ? <i className='fa fa-spinner fa-spin' />
                : 'Approve Payment Request'
              }
            </StyledButton>
          </div>
          <div>
            <StyledButton
              onClick={() => { voucherHandler(STATUS_PENDING_PAYMENT_REQUEST) }}
              className='btn-custom btn-primary'
              disabled={apiCallInProgress}
            >
              {apiCallInProgress
                ? <i className='fa fa-spinner fa-spin' />
                : 'Pending Payment Request'
              }
            </StyledButton>
          </div>
          <div>
            <StyledButton
              className='btn-custom btn-primary'
              onClick={() => {
                setDenialStatus(STATUS_REJECTED_PAYMENT_REQUEST)
                setShowModal(true)
              }}
              disabled={apiCallInProgress}
            >
              Reject Payment Request
            </StyledButton>
          </div>
        </StyledButtonsWrapper>
      </VoucherContainer>
    </>
  )
}

VoucherLevel.propTypes = {
  studentId: PropTypes.string.isRequired,
  voucherId: PropTypes.string.isRequired
}

export default VoucherLevel

import React from 'react'
import { CustomButton, ConfirmationListItem, Message } from './styles'

function Confirmation ({
  selection,
  setStep,
  cohort: { label },
  error,
  isCohort,
  resetState,
  students
}) {
  const getConfirmationText = () => {
    const noStudentUpdated = !isCohort && !students.length
    return (
      <Message>
        {noStudentUpdated ? (
          <>No student was found matching the criteria.</>
        ) : !error ? (
          <>
            Content Gating has been enabled for the following{' '}
            {isCohort ? selection : <>students in {label}</>}:
          </>
        ) : (
          <>
            Something went wrong! Content gating for {selection} not enabled,
            please try again.
          </>
        )}
      </Message>
    )
  }

  return (
    <>
      {getConfirmationText()}
      {!error && isCohort && <ConfirmationListItem>{label}</ConfirmationListItem>}
      {!isCohort &&
        students.map(({ name, studentId, email }) => {
          return (
            <ConfirmationListItem key={studentId}>{name} ({email})</ConfirmationListItem>
          )
        })}
      <CustomButton
        onClick={() => {
          setStep(0)
          resetState()
        }}
        selection={selection}
        className='btn-custom btn-primary'
      >
        SEARCH AGAIN
      </CustomButton>
    </>
  )
}

export default Confirmation

import styled from 'styled-components'
import clearIcon from '../../assets/icons/clear-icon.svg'

export const Container = styled.div`
  margin: 24px 32px 64px 32px;
`
export const Header = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 38.4px;
  padding-top: 23px;
  margin-bottom: 0;
`
export const Separator = styled.hr`
  margin: 32px 0 35px;
  height: 1px;
  border-color: rgba(255, 255, 255, 0.3);
`

export const StudentSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const InputWrapper = styled.div`
  width: fit-content;
  height: 56px;
  position: relative;
`
export const InputLabel = styled.label`
  font-size: 18px;
  color: #FFFFFF;
  line-height: 24px;
  padding-bottom: 16px;
  margin: 0;
`
export const CustomInput = styled.input`
  height: 56px;
  width: 460px;
  padding: 16px;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  &::placeholder{
    color: #5B6067;
  }

  &:disabled, &:focus {
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    box-shadow: 0 0 0 0;
  }
  &:focus {
    border-color: #5FC4B8;
  }
  &:hover{
  border: 1px solid #5FC4B8;
  }
`
export const ClearIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  ${props => props.src === clearIcon && `
      cursor: pointer;
  `}
`
export const TableHeaderWrapper = styled.div`
  padding: 52px 0 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const StudentRecordRowWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const Row = styled.div`
  display: flex;
  gap: 32px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
`
export const HeaderCell = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #B1BFC5;
`

export const StudentRow = styled.div`
  display: flex;
  gap: 32px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;

  :hover {
    background-color: var(--black-secondary);
  }
`
export const StudentCell = styled(HeaderCell)`
  padding: 24px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  text-transform: none;
  word-break: break-word;
`
export const PaginationContainer = styled.div`
  background-color: #1D1D1F;
  color: #5FC4B8;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 16px;
`
export const PageIndex = styled.button`
  background-color: #1D1D1F;
  border: none;
  padding: 0;
  color: ${props => props.isCurrentPage ? 'white' : '#5FC4B8'};
  &:hover {
    color: ${props => props.isBreak ? '#5FC4B8' : '#B8FFF7'};
  }  
  &:focus {
    outline: none;
  }
  &:active {
    color: #B8FFF7;
  }
`

export const NavigationIcon = styled.img`
cursor: pointer;
`
export const PageIndicator = styled.span`
  color: #B1BFC5;
  margin-left: auto;
`
export const StudentRecordNotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 24px 0;
`
export const NotFoundText = styled.span`
  width: 584px;
  text-align: center;
  padding: 24px 0 32px;
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
`
export const NotFoundIcon = styled.img`
  width: 64px;
`
export const NotFoundButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
`
export const NotFoundButton = styled.a`
  display: flex;
  padding: 16px 24px;
  border-radius: 5px;
  background-color: var(--brand-dark) ;
  color: var(--brand-regular) !important;
  text-decoration: none !important;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background-color: #214B52;
    color: #B8FFF7 !important;
  }
  &:active {
    background-color: #214B52;
    color: #B8FFF7 !important;
  }
`

import React, { useMemo, useState, useEffect } from 'react'
import {
  ButtonContainer, CategoryContainer, CheckboxDescription, DeleteUser,
  HeaderSection, Label, ModalButtonContainer, ModalContent,
  PageWrapper, PermissionCategory,
  PermissionsContainer,
  PermissionsForm,
  PermissionsGrid, SubCheckBox,
  SubTitle
} from './styles/index.styled'
import { PageTitle, SectionTitle } from '../../styles/text'
import { Separator } from '../../styles'
import Button from '../../Components/Button/Button'
import CustomSelect from '../../Components/CustomSelect/UpdatedSelect'
import { CustomCheckbox } from '../../Components/Students/style'
import { MAPPER_PERMISSIONS } from '../../Constants/permissions'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash-icon.svg'
import { components } from 'react-select'
import ModalComponent from '../../Components/ModalComponent/ModalComponent'
import api from '../../api'

const EditUserPermissions = ({
  selectedUser,
  setSelectedUser,
  roles,
  handleDeleteUser,
  setUsers,
  setUpdateUserStatus
}) => {
  const [selectedRole, setSelectedRole] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [checkedPermissions, setCheckedPermissions] = useState([])
  const [defaultPermissions, setDefaultPermissions] = useState([])
  const [formUpdated, setFormUpdated] = useState(false)

  const rolesOptions = useMemo(() => {
    const options = roles.map(role => ({
      value: role.role,
      label: role.role.charAt(0).toUpperCase() + role.role.slice(1),
      ...role
    }))
    options.push({ value: 'delete', label: 'Delete user', isDeleteOption: true })
    return options
  }, [roles])

  const allPermissions = useMemo(() => {
    return MAPPER_PERMISSIONS.reduce((acc, category) => {
      return [...acc, ...category.permissions.map((permission) => {
        if (permission.children) {
          return [...permission.children.map(child => child.value), permission.value]
        } else {
          return [permission.value]
        }
      })]
    }, []).flat()
  }, [])

  useEffect(() => {
    if (!selectedUser) return
    const { extendedPermissions } = selectedUser || {}
    const defaultPermissions = getAllPermissionsForRole(selectedUser.role)
    if (selectedUser.role === 'admin') {
      setCheckedPermissions(allPermissions)
    } else {
      setCheckedPermissions([...defaultPermissions || [], ...extendedPermissions || []])
    }
    setDefaultPermissions(defaultPermissions)
    // eslint-disable-next-line
  }, [selectedUser, rolesOptions])

  const getAllPermissionsForRole = (roleName) => {
    const role = roles.find(r => r.role === roleName)
    if (!role) return []

    let permissions = [...role.permissions]

    // If the role inherits from other roles, recursively get their permissions
    if (role.inheritFromRoles && role.inheritFromRoles.length > 0) {
      role.inheritFromRoles.forEach(inheritedRoleName => {
        permissions = [...permissions, ...getAllPermissionsForRole(inheritedRoleName)]
      })
    }

    // Remove duplicate permissions
    return [...new Set(permissions)]
  }

  const handleSelectChange = (selectedOption) => {
    if (selectedOption.isDeleteOption) {
      setShowDeleteModal(true)
      return
    }
    const { extendedPermissions } = selectedUser || {}
    const defaultPermissions = getAllPermissionsForRole(selectedOption.role)
    setDefaultPermissions(defaultPermissions)
    if (selectedOption.role === 'admin') {
      setCheckedPermissions(allPermissions)
    } else {
      setCheckedPermissions([...defaultPermissions || [], ...extendedPermissions || []])
    }
    setSelectedRole(selectedOption)
    setFormUpdated(true)
  }

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target
    const newPermissions = checked ? [...checkedPermissions, name] : checkedPermissions.filter(permission => permission !== name)
    setCheckedPermissions(newPermissions)
    setFormUpdated(true)
  }

  const CustomOption = props => {
    return (<components.Option {...props}>
      {props.data.isDeleteOption ? (
        <DeleteUser>
          <TrashIcon style={{ marginRight: '8px' }} />
          {props.data.label}
        </DeleteUser>
      ) : (
        props.data.label
      )}
    </components.Option>
    )
  }

  const isCheckboxDisabled = (permission) => {
    if (selectedRole?.role === 'admin') return true
    if (selectedUser.role === 'admin') return true
    return defaultPermissions.includes(permission.value)
  }

  const handleSaveChanges = async () => {
    const response = await api.updateAdminPermissions(selectedUser.email, {
      email: selectedUser.email,
      firstName: selectedUser.firstName,
      lastName: selectedUser.lastName,
      role: selectedRole?.role,
      extendedPermissions: checkedPermissions.filter(permission => !defaultPermissions.includes(permission))
    })
    if (response.success) {
      setUpdateUserStatus('saved')
      const { data } = await api.getAllAdminsV2()
      setUsers(data)
    }
    setSelectedUser(null)
  }

  return (
    <PageWrapper>
      <ModalComponent
        width='455px'
        show={showDeleteModal}
        title='Remove user '
        handleClose={() => setShowDeleteModal(false)}
      >
        <ModalContent style={{ background: '#25272B' }}>
          Are you sure you want to remove this user from the Admin Dashboard?
          <ModalButtonContainer>
            <Button
              style={{ padding: '16px 24px', height: '48px' }}
              onClick={() => handleDeleteUser() && setShowDeleteModal(false)}
              className={{
                'btn-custom': true,
                'btn-primary': true
              }}>
              Yes, remove
            </Button>
            <Button
              style={{ padding: '16px 24px', height: '48px', width: 'auto' }}
              onClick={() => setShowDeleteModal(false)}
              className={{
                'btn-custom': true,
                'btn-secondary': true
              }}>
              Cancel
            </Button>
          </ModalButtonContainer>
        </ModalContent>
      </ModalComponent>
      <ModalComponent
        width='455px'
        show={showConfirmationModal}
        title='Cancel changes'
        handleClose={() => setShowConfirmationModal(false)}
      >
        <ModalContent style={{ background: '#25272B' }}>
          Are you sure you want to undo your changes?
          <ModalButtonContainer>
            <Button
              style={{ padding: '16px 24px', height: '48px' }}
              onClick={() => setSelectedUser(null)}
              className={{
                'btn-custom': true,
                'btn-primary': true
              }}>
              Yes, undo
            </Button>
            <Button
              style={{ padding: '16px 24px', height: '48px', width: 'auto' }}
              onClick={() => setShowConfirmationModal(false)}
              className={{
                'btn-custom': true,
                'btn-secondary': true
              }}>
              Cancel
            </Button>
          </ModalButtonContainer>
        </ModalContent>
      </ModalComponent>
      <HeaderSection>
        <PageTitle>
          {selectedUser.firstName} {selectedUser.lastName}
          <SubTitle>{selectedUser.email}</SubTitle>
        </PageTitle>
        <ButtonContainer>
          <Button
            style={{ padding: '16px 24px', height: '48px' }}
            onClick={() => setShowConfirmationModal(true)}
            className={{
              'btn-custom': true,
              'btn-tertiary': true
            }}>
            Cancel
          </Button>
          <Button
            disabled={!formUpdated}
            style={{ padding: '16px 24px', height: '48px', width: '100%' }}
            onClick={handleSaveChanges}
            className={{
              'btn-custom': true,
              'btn-primary': true,
              disabled: !formUpdated
            }}>
            Save changes
          </Button>
        </ButtonContainer>
      </HeaderSection>
      <Separator />
      <PermissionsContainer>
        <PermissionsForm>
          <SectionTitle>Permissions</SectionTitle>
          <div>
            <Label>Role</Label>
            <CustomSelect
              id='role'
              options={rolesOptions}
              placeholder='Select role'
              height='48px'
              backgroundColor='#161618'
              value={selectedRole || rolesOptions.find(option => option.value === selectedUser.role)}
              onChange={handleSelectChange}
              components={{ Option: CustomOption }}
            />
          </div>
          <PermissionsGrid>
            {MAPPER_PERMISSIONS.map((category, index) => (
              <CategoryContainer key={index}>
                <PermissionCategory>
                  {category.category}
                </PermissionCategory>
                {category.permissions.map((permission, index) => (
                  <React.Fragment key={index}>
                    <CustomCheckbox mb='0'
                      disabled={isCheckboxDisabled(permission)}>
                      {permission.label}
                      <input
                        type='checkbox'
                        data-testid={permission.label}
                        id={permission.label}
                        name={permission.value}
                        checked={checkedPermissions.includes(permission.value)}
                        disabled={isCheckboxDisabled(permission)}
                        onChange={handleCheckboxChange}
                      />
                      <span />
                    </CustomCheckbox>
                    {permission?.children?.map((child, index) => (
                      <SubCheckBox key={index}>
                        <CustomCheckbox mb='0'
                          disabled={isCheckboxDisabled(child)}>
                          {child.label}
                          <input
                            type='checkbox'
                            data-testid={child.label}
                            id={child.label}
                            name={child.value}
                            checked={checkedPermissions.includes(child.value)}
                            disabled={isCheckboxDisabled(child)}
                            onChange={handleCheckboxChange}
                          />
                          <span />
                        </CustomCheckbox>
                        <CheckboxDescription>
                          {child?.description}
                        </CheckboxDescription>
                      </SubCheckBox>
                    ))}
                  </React.Fragment>
                ))}
              </CategoryContainer>
            ))}
          </PermissionsGrid>
        </PermissionsForm>
      </PermissionsContainer>
    </PageWrapper>
  )
}

export default EditUserPermissions

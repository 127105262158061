import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  AnswerContainer,
  AnswersText,
  AnswerWrapper, AssessmentContainer,
  AssessmentHeader,
  AssessmentSubmissionTime,
  BreadCrumb,
  ContentHeader,
  CorrectAnswerIcon,
  CustomQuestionContainer, IncorrectAnswerIcon, ModifyResultButton,
  QuestionsWrapper,
  QuestionTitle,
  StyledBreadCrumbIcon,
  ViewQuestionIcon
} from './styled/EditGrades.styled'
import {
  SET_DISCARD_MODAL
} from '../../Constants/actionTypes'
import CardPreview from '../CardPreview/CardPreview'
import EditGradeModalV2 from './EditGradeModalV2'
import {
  AdjustmentButtonsWrapper,
  AdjustmentsGrade,
  AdjustmentsTitle, AdjustmentWrapper, Dot,
  PrimaryButton, SecondaryButton
} from './styles'

import api from '../../api'
import Toast from '../ToastComponent/Toast'
import { SuccessIcon, WarningIcon } from '../Guild/style'

const EditGradesV2 = ({
  studentProgress,
  quizExamData,
  sortedQuestions,
  dispatch,
  changes,
  setChanges,
  history,
  match,
  location,
  cohortId
}) => {
  const toast = useRef()

  const [selectedQuestion, setSelectedQuestion] = useState(sortedQuestions[0])
  const [showEditGradeModal, setShowGradeEditModal] = useState(false)
  const [questionsList, setQuestionsList] = useState([])
  const [modifiedStudentProgress, setModifiedStudentProgress] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const [tempChanges, setTempChanges] = useState([])
  const [savedScore, setSavedScore] = useState(null)

  useEffect(() => {
    if (!changes.length) {
      setQuestionsList(sortedQuestions)
      return
    }
    const updatedQuestions = sortedQuestions.map(question => {
      const updatedQuestion = changes.find(
        change => change.questionUuid === question.Question_uuid
      )
      return { ...question, ...updatedQuestion }
    })
    setQuestionsList(updatedQuestions)
    // eslint-disable-next-line
  }, [changes])

  useEffect(() => {
    if (!changes.length) {
      setModifiedStudentProgress(studentProgress)
      return
    }
    setTempChanges(changes)
    setModifiedStudentProgress(prev => ({
      ...prev,
      studentAnswers: {
        ...prev?.studentAnswers,
        ...changes.reduce((acc, { questionUuid, correct }) => {
          acc[questionUuid] = { correct }
          return acc
        }, {})
      }
    }))
    // eslint-disable-next-line
  }, [changes])

  const newScore = useMemo(() => {
    const totalQuestions = sortedQuestions?.length || 0
    const correctAnswers = tempChanges.concat(
      questionsList.filter(q => !tempChanges.find(mq => (
        mq.questionUuid === q.questionUuid
      )))
    ).filter(q => q.correct)?.length || 0

    // rounded to 2 decimal places
    return Math.round((correctAnswers / totalQuestions) * 10000) / 100
  }, [tempChanges, questionsList, sortedQuestions.length])

  const oldScore = useMemo(() => {
    const totalQuestions = sortedQuestions?.length || 0
    const correctAnswers = sortedQuestions?.filter(q => q.correct)?.length || 0
    return Math.round((correctAnswers / totalQuestions) * 10000) / 100
  }, [sortedQuestions])

  const saveChanges = async () => {
    setIsSaving(true)
    const { courseUuid, studentEmail } = match.params
    const query = new URLSearchParams(location.search)
    const quizUuid = query.get('quizUuid')
    const sectionUuid = query.get('sectionUuid')
    const { sectionGrade } = quizExamData
    const body = {
      sectionUuid: sectionUuid || quizExamData.chapter_uuid,
      quizUuid: quizUuid || quizExamData.chapter_uuid,
      sectionGrade: sectionGrade > newScore ? sectionGrade : newScore,
      quizGrade: newScore,
      questionsModifications: changes.map(change => ({
        ...change,
        reason: change.reason?.value
      }))
    }

    const { success } = await api.putStudentExamModifications(
      studentEmail,
      { courseId: courseUuid, cohortId: cohortId },
      body)

    setTempChanges([])
    setSavedScore(newScore)
    setIsSaving(false)
    const { current: { display } = {} } = toast

    return success ? display(
      <><SuccessIcon /> Grade successfully adjusted</>
    ) : display(
      <><WarningIcon /> Failed to adjust grade</>
    )
  }

  const handleCancelClick = () => {
    if (tempChanges.length === 0) history.goBack()
    else dispatch({ type: SET_DISCARD_MODAL, showDiscardModal: true })
  }

  return (
    <AssessmentContainer data-testid='assessment-container'>
      <Toast toast={toast} />
      {showEditGradeModal && <EditGradeModalV2
        setShowGradeEditModal={setShowGradeEditModal}
        showEditGradeModal={showEditGradeModal}
        question={selectedQuestion}
        setChanges={setChanges}
        changes={changes}
      />}
      <BreadCrumb
        data-testid='bread-crumb-link'
        to='/'
        onClick={handleCancelClick}
      >
        <StyledBreadCrumbIcon />
      Student Report
      </BreadCrumb>
      <AssessmentHeader data-testid='assessment-header'>
        <ContentHeader data-testid='content-header'>
          {quizExamData.title}
        </ContentHeader>
        <AssessmentSubmissionTime>
        Submission time : --
        </AssessmentSubmissionTime>
      </AssessmentHeader>
      <AnswerWrapper data-testid='answer-wrapper'>
        <QuestionsWrapper>
          <AnswersText data-testid='answers-text'>
            <div>Answers</div>
            <p>
            Select a question to view the content and modify the result.
            </p>
          </AnswersText>
          {questionsList.map((question, index) => {
            const {
              title,
              Question_uuid: QuestionUUID,
              cmsTitle,
              correct
            } = question || {}
            const displayTitle = title || cmsTitle?.match(/(Question\s\d+)/)?.[0]
            return (
              <CustomQuestionContainer
                key={index}
                data-testid='question-container'
                selected={selectedQuestion?.Question_uuid === QuestionUUID}
                onClick={() => setSelectedQuestion(question)}
              >
                {correct
                  ? <CorrectAnswerIcon data-testid='correct-answer-icon' />
                  : <IncorrectAnswerIcon data-testid='incorrect-answer-icon'
                  />}
                <QuestionTitle>
                  {displayTitle}
                </QuestionTitle>
                <ViewQuestionIcon
                  data-testid='view-question'
                />
              </CustomQuestionContainer>
            )
          })}
        </QuestionsWrapper>
        <AnswerContainer
          data-testid='answer-container'
          showQuestionsList>
          <CardPreview
            previewMode
            question={selectedQuestion || sortedQuestions[0]}
            studentProgress={modifiedStudentProgress}
          />
          <ModifyResultButton
            onClick={() => setShowGradeEditModal(true)}
          >
            Modify Result
          </ModifyResultButton>
        </AnswerContainer>
      </AnswerWrapper>
      <>
        <AdjustmentWrapper>
          <AdjustmentsTitle>Adjustments</AdjustmentsTitle>
          {!tempChanges?.length
            ? (
              <>
                <p>You did not make any changes.</p>
                <AdjustmentsGrade>
                  <span data-testid='original-grade'>Grade: {savedScore || oldScore}%</span>
                </AdjustmentsGrade>
              </>
            )
            : (
              <>
                <p>
                  You modified the results for {tempChanges?.length} questions.
                </p>
                <AdjustmentsGrade>
                  <span data-testid='original-grade'>
                    Original Grade: {savedScore || oldScore}%
                  </span>
                  <Dot />
                  <span className='last' data-testid='new-grade'>
                    Revised Grade: {newScore}%
                  </span>
                </AdjustmentsGrade>
              </>
            )}
          <AdjustmentButtonsWrapper>
            <PrimaryButton
              width='fit-content'
              className='btn-custom btn-primary button'
              onClick={saveChanges}
              disabled={tempChanges?.length === 0}
            >
              {isSaving ? 'Saving...' : 'save changes'}
            </PrimaryButton>
            <SecondaryButton
              className='btn-custom btn-secondary button'
              onClick={handleCancelClick}
            >
              Cancel
            </SecondaryButton>
          </AdjustmentButtonsWrapper>
        </AdjustmentWrapper>
      </>
    </AssessmentContainer>
  )
}

export default EditGradesV2

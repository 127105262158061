import React, { useEffect, useMemo, useState } from 'react'
import {
  Table, TableRow, TableHeader, TableCell, DropDownImg
} from './styles/index.styled'
import DropdownImgURL from '../../assets/icons/caret-sort-desc.png'
import DisabledDropdownImgURL from '../../assets/icons/caret-sort-disable.png'
import TooltipIcon from '../../assets/icons/icon-tooltip.svg'
import ArrowRightIcon from '../../assets/icons/caret-arrow-right.svg'
import Tooltip from '../../Components/Tooltip/Tooltip'

const UsersTable = ({ users, setSelectedUser }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' })
  const [tooltipPosition, setTooltipPosition] = useState('top')

  useEffect(() => {
    const updatePosition = () => {
      const width = window.innerWidth
      if (width >= 1200) {
        setTooltipPosition('right')
      } else {
        setTooltipPosition('top')
      }
    }

    updatePosition()
    window.addEventListener('resize', updatePosition)

    return () => window.removeEventListener('resize', updatePosition)
  }, [])

  const sortedUsers = useMemo(() => {
    const sortableUsers = [...users]
    if (sortConfig.key !== null) {
      sortableUsers.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1
        }
        return 0
      })
    }
    return sortableUsers
  }, [users, sortConfig])

  const requestSort = (key) => {
    let direction = 'asc'
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  const getIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? DropdownImgURL : DropdownImgURL
    }
    return DisabledDropdownImgURL
  }

  const handleSelectUser = (user) => () => {
    setSelectedUser(user)
  }

  return (<Table>
    <thead>
      <TableRow>
        <TableHeader width='250px' onClick={() => requestSort('firstName')}>
          User Name
          <DropDownImg
            src={getIcon('firstName')}
            alt='dropdown'
            isSortedAscending={sortConfig.direction === 'asc' && sortConfig.key === 'firstName'}
          />
        </TableHeader>
        <TableHeader width='240px' onClick={() => requestSort('role')}>
          Role
          <DropDownImg
            src={getIcon('role')}
            alt='dropdown'
            isSortedAscending={sortConfig.direction === 'asc' && sortConfig.key === 'role'}
          />
        </TableHeader>
        <TableHeader>
          Additional Access
          <Tooltip
            icon={<img src={TooltipIcon} alt='tooltip' />}
            position={tooltipPosition}
            text='Users have access to tools outside of their assigned role.'
          />
        </TableHeader>
        <TableHeader />
      </TableRow>
    </thead>
    <tbody>{sortedUsers.map((user, index) => (
      <TableRow key={index} onClick={handleSelectUser(user)}>
        <TableCell width={'250px'}>
          {user.firstName} {user.lastName}
          <br />
          <span>{user.email}</span>
        </TableCell>
        <TableCell width={'240px'}>{user.role}</TableCell>
        <TableCell>{user.extendedPermissions?.join(', ')}</TableCell>
        <TableCell
          style={{ textAlign: 'right' }}
        >
          <img src={ArrowRightIcon} alt='tooltip' />
        </TableCell>
      </TableRow>))}
    </tbody>
  </Table>)
}

export default UsersTable

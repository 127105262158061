import { isBoolean, isEqual, isString } from 'lodash'
import moment from 'moment'

export const getEditMessage = ({ type, editor, author, currentUserEmail }) => {
  switch (type) {
    case 'edit': {
      if (editor === null) {
        return 'Updated via API'
      }
      if (editor?.toLowerCase() === 'sequin') {
        return 'Sequin System Sync updated via API'
      }
      if (editor === currentUserEmail) {
        return (
          `You updated this record`
        )
      }
      return (
        `${editor} updated this record`
      )
    }
    case 'comment': {
      if (author === currentUserEmail) {
        return (
          `You commented`
        )
      }

      return (
        `${author} commented`
      )
    }
    default: return ''
  }
}

export const stringToBoolean = (str) => {
  if (['true', 'false'].includes(str)) return str === 'true'
  return str
}

export const addUTCTimezone = (dateString) => {
  // Regular expression to match 'Z' or a +/- followed by a time offset
  const timezoneRegex = /Z|([+-])([01]\d|2[0-3]):?([0-5]\d)$/
  const hastTimezone = timezoneRegex.test(dateString)
  if (hastTimezone) return dateString
  return `${dateString}Z`
}

export const areValuesEqual = (value1, value2) => {
  if (
    isString(value1) &&
    isString(value2) &&
    moment(new Date(value1)).isValid() &&
    moment(new Date(value2)).isValid()
  ) {
    const date1 = addUTCTimezone(value1)
    const date2 = addUTCTimezone(value2)
    return moment(new Date(date1)).isSame(new Date(date2))
  }
  // Check if both values are arrays
  if (Array.isArray(value1) && Array.isArray(value2)) {
    // If they are arrays, compare their lengths
    if (value1.length !== value2.length) {
      return false
    }
    // If lengths are equal, compare each element
    for (let i = 0; i < value1.length; i++) {
      if (
        ['true', 'false'].includes(value1[i]) ||
        ['true', 'false'].includes(value2[i])
      ) {
        return stringToBoolean(value1[i]) === stringToBoolean(value2[i])
      }
      if (
        isString(value1[i]) &&
        isString(value2[i]) &&
        moment(new Date(value1[i])).isValid() &&
        moment(new Date(value2[i])).isValid()
      ) {
        const date1 = addUTCTimezone(value1[i])
        const date2 = addUTCTimezone(value2[i])
        return moment(new Date(date1)).isSame(new Date(date2))
      }
      if (
        !isEqual(value1[i], value2[i]) ||
        (value2?.[i]?.id && !isEqual(value1[i], value2?.[i].id))
      ) {
        return false
      }
    }
    return true // Arrays are equal
  } else {
    // If they are not arrays, compare them directly
    return value1 === value2
  }
}

export const getFieldChange = (nextChangedField) => {
  if (Array.isArray(nextChangedField)) {
    if (nextChangedField.length === 1 && nextChangedField?.[0] === null) {
      return ['null']
    }

    if (
      nextChangedField?.[0] &&
      Object.keys(nextChangedField?.[0]).includes('id')
    ) {
      return nextChangedField?.map(({ id }) => id)
    }

    return nextChangedField
  }

  if (nextChangedField === null) {
    return 'null'
  }

  if (isBoolean(nextChangedField)) {
    return nextChangedField.toString()
  }

  return nextChangedField
}

import styled from 'styled-components'

export const Container = styled.div`
  margin: 64px;
`

export const Header = styled.h2`
  font-weight: 300;
  font-size: 32px;
  line-height: 38px;
  color: var(--text-primary);
  margin-bottom: 0;
`

export const Separator = styled.hr`
  margin: 32px 0;
  height: 0;
  border: 1px solid var(--border-primary);
`

export const ModalSeparator = styled.hr`
  height: 1px;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  border: 0 none;
  margin: 0;
`

export const SectionContainer = styled.div`
`

export const RequestsContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    align-items: center;
    height: 64px;
    background: var(--canvas-secondary);
    border-radius: 5px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-primary);
    margin-bottom: 16px;

    & > span {
      margin-right: 32px;

      &:first-child {
        margin-left: 24px;
      }

      &:last-child {
        margin-right: 24px;
      }

      &.flex {
        flex: 1;
      }

      &.link {
        color: var(--brand-regular);
        cursor: pointer;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: 2px;
      }

      & > img {
        margin-right: 8px;
        margin-top: -2px;
      }
    }
  }
`

export const SectionHeader = styled.p`
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  color: var(--text-primary);
  margin-bottom: 24px;
`

export const ViewAll = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  color: var(--text-primary);
  margin-bottom: 0;
  margin-top: 8px;
  cursor: pointer;
`

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 24px;

  & > p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
    margin-bottom: 0;
  }

  & > img {
    cursor: pointer;
    width: 14px;
    height: 14px;
  }
`

export const ModalContent = styled.div`
  width: 400px;
  padding: 24px;

  & > div {
    margin-bottom: 24px;

    img {
      margin-right: 8px;
      margin-top: -3px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > p {
    margin-bottom: 24px;
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
`

export const RequestDetail = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ color }) => color || 'var(--text-primary)'};
  margin-bottom: 0;

  & > span {
    color: ${({ decisionColor }) => decisionColor || 'var(--text-primary)'};
  }
`

export const ButtonsContainer = styled.div`
  margin-top: 24px;
  display: flex;
`

export const CustomButton = styled.button`
  border-radius: 5px !important;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 16px 24px;

  &:not(:disabled):not(.disabled):active {
    background-color: transparent;
    border-color: transparent;
  }
`

export const PrimaryButton = styled(CustomButton)`
  width: auto;
  background-color: var(--brand-regular);
  color: var(--text-dark);
  border: none;
  width: ${props => props.width || 'auto'};
  margin-right: ${props => props.marginRight || '16px'};

  &:active {
    background-color: var(--brand-regular) !important;
    color: var(--text-dark) !important;
    border: 0 none !important;
  }

  &:focus {
    outline: 0;
    background-color: var(--brand-regular);
    color: var(--text-dark);
    border: none;
    box-shadow: unset;
  }

  &:hover {
    background-color: var(--brand-regular-hover);
    color: var(--text-dark);
    border: none;
  }

  &:disabled {
    color: var(--text-dark);
    background-color: var(--brand-regular-disabled);
    border-color: var(--brand-regular-disabled);
    pointer-events: none;
  }
`

export const SecondaryButton = styled(CustomButton)`
  background-color: var(--gray-primary);
  color: var(--brand-regular);
  border: 1px solid var(--brand-regular);
  width: ${props => props.width || 'auto'};

  &:active {
    background-color: var(--gray-primary) !important;
    color: var(--brand-regular) !important;
    border: 1px solid var(--brand-regular) !important;
  }

  &:focus {
    outline: 0;
    background-color: var(--gray-primary);
    color: var(--brand-regular);
    border: 1px solid var(--brand-regular);
    box-shadow: unset;
  }

  &:hover {
    color: var(--brand-regular-hover);
    border: 1px solid var(--brand-regular-hover);
    background-color: var(--gray-primary);
  }

  &:disabled {
    background-color: var(--canvas-primary);
    border-color: var(--brand-regular-disabled);
    color: var(--brand-regular-disabled);
    pointer-events: none;
  }
`

import React from 'react'
import ellipseIcon from '../../../assets/icons/ellipse.svg'
import { formatDateTerm } from '../../../utilities/dateTimeUtil'
import { StudentCertDegreeName, StudentCertDegreeRow, StudentDegreeStatus } from './style'

function StudentDegreeInfo ({ prospect }) {
  const {
    applicationSubmitted,
    degree: degreeName,
    degreeStatus,
    studyMode,
    gguSemesters
  } = prospect
  const term = gguSemesters?.[0]?.term
  const formattedTerm = formatDateTerm(term)
  const status = degreeStatus || (applicationSubmitted && 'App Submitted')
  const isCompleted = degreeStatus === 'Complete'
  return (
    <StudentCertDegreeRow>
      <StudentCertDegreeName isCompleted={isCompleted}>
        {degreeName}
      </StudentCertDegreeName>
      <StudentDegreeStatus isCompleted={isCompleted}>
        {status}
        {status && <img src={ellipseIcon} alt='ellipse' />}
        {studyMode}
        {formattedTerm && <img src={ellipseIcon} alt='ellipse' />}
        {formattedTerm}
      </StudentDegreeStatus>
    </StudentCertDegreeRow>
  )
}

StudentDegreeInfo.displayName = 'StudentDegreeInfo'
export default StudentDegreeInfo

import React from 'react'
import { HeaderSection, PageWrapper } from './style/index.style'
import { BodyText, PageTitle } from '../../styles/text'
import { Separator } from '../../styles'
import GGUApplications from './GGUApplications'

function GGUAppManagement () {
  return (
    <PageWrapper>
      <HeaderSection>
        <PageTitle>
          GGU Application Management
        </PageTitle>
        <Separator />
        <BodyText>
          Review or update GGU applications. You can defer students to future terms, facilitate <br />
          application withdrawals, enable major changes, and reassign advisors.
        </BodyText>
      </HeaderSection>
      <GGUApplications />
    </PageWrapper>
  )
}

GGUAppManagement.displayName = 'GGUAppManagement'
export default GGUAppManagement

export function mapCsvToDatabase (sourceData, databaseColumns, isGGUAdmissionDecision) {
  const columnData = []
  const csvData = []

  sourceData.forEach(row => {
    let newRow = {}
    Object.entries(row).forEach(([key, value]) => {
      /*
        not having a dot in the csv column indicates that user has custom column in the source csv,
        which we should map to correct column

        key = 'Student Email'
        col.csvColumn = 'Student Email'
        databaseColumn = 'students.email'
        */
      const mappedColumn = (databaseColumns.find(col => col.csvColumn === key) || {})?.databaseColumn
      if (!isGGUAdmissionDecision) { key = !key.includes('.') ? mappedColumn : key }

      columnData.push({ csvColumn: key, databaseColumn: mappedColumn })
      newRow = { ...newRow, [key]: value }
    })

    csvData.push(newRow)
  })

  return { columnData, csvData }
}

import React, { useState, useEffect } from 'react'
import { Label } from 'reactstrap'
import Select from 'react-select'
import { CohortSearchContainer, CohortField } from './styles'
import api from '../../api'
import {
  COLLEGE_WRITING_I_GGU_V1_NAME, COLLEGE_WRITING_I_GGU_V2_AT_ID,
  COLLEGE_WRITING_I_GGU_V2_NAME
} from '../../Constants/courses'

const CohortSearchForm = ({ course, cohort, handleChange }) => {
  const [allCourses, setCourses] = useState([])
  const [allCohorts, setCohorts] = useState([])

  const fetchDropdownData = async () => {
    const { getCourses, getAllCohorts } = api
    const promises = [getCourses(), getAllCohorts()]
    const [courses, cohorts] = await Promise.all(promises)

    // filter out CW1 V2
    const filteredCourses = courses.filter(course => course.name !== COLLEGE_WRITING_I_GGU_V2_NAME)

    filteredCourses && setCourses(
      filteredCourses.map(course => ({ value: course.at_id, label: course.name }))
    )
    cohorts &&
      setCohorts(
        cohorts
          .sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart))
          .map((cohort) => ({
            value: cohort.at_id,
            label: cohort.name,
            course: cohort.course || []
          }))
      )
  }

  useEffect(() => {
    if (cohort.course) {
      const isCohortInCourse = cohort.course.includes(course.value)
      !isCohortInCourse && handleChange('cohort', '')
    }
    // eslint-disable-next-line
  }, [course])

  useEffect(() => {
    fetchDropdownData()
  }, [])

  const filterCohorts = (selectedCourse, cohorts) => {
    const isCWI = selectedCourse?.label === COLLEGE_WRITING_I_GGU_V1_NAME
    return cohorts.filter(({ course }) => {
      return course.includes(selectedCourse?.value) ||
        (isCWI && course.includes(COLLEGE_WRITING_I_GGU_V2_AT_ID))
    })
  }

  return (
    <CohortSearchContainer>
      <CohortField marginRight>
        <Label>Select Course:</Label>
        <div className='text-field'>
          <Select
            options={allCourses}
            value={course}
            onChange={(val) => handleChange('course', val)}
            placeholder='Select Course'
          />
        </div>
      </CohortField>
      <CohortField>
        <Label>Select Cohort:</Label>
        <div className='text-field'>
          <Select
            options={filterCohorts(course, allCohorts)}
            onChange={(val) => handleChange('cohort', val)}
            value={cohort}
            placeholder='Select Cohort'
          />
        </div>
      </CohortField>
    </CohortSearchContainer>
  )
}

export default CohortSearchForm

import React, { useEffect, useState } from 'react'
import {
  Container,
  PageHeading,
  Separator
} from '../WritingGradeCenter/styled'
import CohortsTable from './CohortsTable'
import Filters from './Filters'

const CreateCommunity = () => {
  const [currentCohorts, setCurrentCohorts] = useState([])
  const [currentCourse, setCurrentCourse] = useState(null)
  const [upcomingCohorts, setUpcomingCohorts] = useState([])
  const [otherCohorts, setOtherCohorts] = useState([])

  useEffect(() => {
    const others = []
    const upcoming = []

    currentCohorts.forEach(cohort => {
      const { id: cohortId, name: cohortName, dateStart } = cohort
      if (new Date() < new Date(dateStart)) {
        return upcoming.push({ dateStart, cohortName, cohortId })
      }
      others.push({ dateStart, cohortName, cohortId })
    })

    setUpcomingCohorts(upcoming)
    setOtherCohorts(others)
  }, [currentCohorts])

  return (
    <Container padding>
      <PageHeading>Yellowdig Launch URLs</PageHeading>
      <Separator />
      <Filters
        currentCourse={currentCourse}
        setCurrentCourse={setCurrentCourse}
        currentCohorts={currentCohorts}
        setCurrentCohorts={setCurrentCohorts}
      />
      {currentCourse && <CohortsTable
        currentCourse={currentCourse}
        cohorts={upcomingCohorts}
        heading='Upcoming Cohorts'
      />}
      {currentCourse && <CohortsTable
        currentCourse={currentCourse}
        cohorts={otherCohorts}
        heading='In Progress and Completed Cohorts'
        isCompletedTable
      />}
    </Container>
  )
}

export default CreateCommunity

import styled from 'styled-components'
import Button from '../../Button/Button'

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
`

export const ModalCloseIcon = styled.img`
  cursor: pointer;
`

export const Wrapper = styled.div`
  padding: 24px;
  width: 490px;
  min-height: 232px;
`

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 18px;
`

export const TitleText = styled.h1`
  font-family: 'Lato';
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: unset;
`

export const GrayText = styled.p`
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #b1bfc5;
`

export const Text = styled.p`
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: flex-start;
`

export const CustomButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px !important;
  padding: 8px;
  height: 33px;
  text-transform: uppercase;
  font-family: 'Lato';
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 1px;
  text-align: center;
  outline: none;
  white-space: nowrap;

  &:focus {
    box-shadow: none;
  }
`

export const PrimaryButton = styled(CustomButton)`
  background: #5fc4b8;
  border: none;


  &:focus {
    border: none;
    outline:none
  }

  &:active {
    background: #25272b;
    color: #5fc4b8;
  }
`
export const SecondaryButton = styled(CustomButton)`
  border: 1px solid #5fc4b8;
  box-sizing: border-box;
  background: #25272b;
  color: #5fc4b8;

  &:active {
    background-color: #000000;
    color: #ffffff;
    outline: none;
    border: 1px solid #8888;
  }

  &:focus {
    box-shadow: none !important;
    outline: none;
    border: 1px solid #8888;
  }
`

export const ConfirmationModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 246px;
  width: 517px;
`

export const ConfirmationModalText = styled.div`
  margin: 45px 90px 32px 91px;
  width: 336px;
  height: 78px;
  font-family: 'Lato';
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.183333px;
  text-align: center;

  & > span {
    font-weight: 800;
  }
`

export const EarnedGrade = styled(GrayText)`
  margin-bottom: 12px;
`

export const Score = styled(Text)`
  margin-bottom: 18px;
`

export const Question = styled(GrayText)`
  margin-bottom: 24px;
`

import styled, { createGlobalStyle, css } from 'styled-components'
import Select from 'react-select'
import {
  Label,
  FormGroup,
  Input
} from 'reactstrap'
import mediaqueries from '../../../mediaQueries'
import ReactDatePicker from 'react-datepicker'
import calendar from '../../../assets/icons/calendar_2.svg'
import checkMark from '../../../assets/icons/check-mark.svg'
import clearIcon from '../../../assets/icons/clear-icon.svg'
import toolTipIcon from '../../../assets/icons/icon-tooltip.svg'
import chevronRight from '../../../assets/icons/chevron-right.svg'
import chevronRightTeal from '../../../assets/icons/chevron-right-teal.svg'
import warningIcon from '../../../assets/icons/warning-yellow.svg'
import downloadIcon from '../../../assets/icons/download-link.svg'
import sortAscendingActiveIcon from '../../../assets/icons/icon-sort-asc-active.svg'
import sortAscendingInactiveIcon from '../../../assets/icons/icon-sort-asc-inactive.svg'
import { selectInputStyles } from '../../CheckboxSelector/styled'

export const OverrideStyles = createGlobalStyle`
  .react-datepicker{
    font-size: 12px;
    font-family: Lato;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #0D0D0F;
    border: 1px solid #5B6067;
  }

  .react-datepicker__day--keyboard-selected, 
  .react-datepicker__day--selected {
    background-color: transparent!important;
    color: white!important;
  }

  .react-datepicker__day--highlighted{
    background-color: #5FC4B8!important;
    color: black!important;
  }

  .react-datepicker__month-container {
    width: 100%;
    padding: 0 20.5px
  }

  .react-datepicker__month {
    margin: 0px;
   }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;

    .react-datepicker__day-name:nth-child(1),
    .react-datepicker__day-name:nth-child(7) {
      display: none;
    }
  }

  .react-datepicker {
    width: 403px;

    ${mediaqueries.tablet`
      width: 304px;
    `}
  }

  .react-datepicker__header {
    border-bottom: none!important;
    background-color: transparent!important;
  }

  .react-datepicker__current-month {
    color: white;
  }

  .react-datepicker__week {
    display: flex;
    justify-content: space-between;

    .react-datepicker__day:nth-child(1),
    .react-datepicker__day:nth-child(7) {
      display: none!important;
    }
  }

  .react-datepicker__day, 
  .react-datepicker__day-name {
    width: 36px;
    height: 36px;
    padding-top: 3px;
    border-radius: 50%!important;
    color: white;
  }  

  .react-datepicker__day:hover {
    background-color: transparent;
    color: #5FC4B8;
    border: 1px solid #5FC4B8!important;
  }
  
  .react-datepicker__day--disabled {
    color: gray;
  }

  .react-datepicker__day-name {
    color: transparent;
    position: relative;

    ::after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      color: white!important;
    }
  }

  .react-datepicker__day-name:nth-child(2) {
    ::after {
      content: 'M';
    }
  }

  .react-datepicker__day-name:nth-child(3),
  .react-datepicker__day-name:nth-child(5) {
    ::after {
      content: 'T';
    }
  }

  .react-datepicker__day-name:nth-child(4) {
    ::after {
      content: 'W';
    }
  }

  .react-datepicker__day-name:nth-child(6) {
    ::after {
      content: 'F';
    }
  }

`
export const carouselSsettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  nextArrow: null,
  prevArrow: null,
  swipeToSlide: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1449,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 788,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 660,
      settings: {
        slidesToShow: 1.25,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true
      }
    }
  ]
}

export const Container = styled.div`
  padding: 64px 64px 0 36px;
  margin: 0 auto;
  ${mediaqueries.desktop`
    min-width: 100%;
    max-width: 100%;
  `}
  ${mediaqueries.mobile`
    padding: 32px 32px 32px 24px;
  `}
`

export const CohortContainer = styled.div`
  max-width: 1920px;
  min-width: 470px;
  margin: 0 auto;
  padding: 0 24px;
`

export const GoBackContainer = styled.div`
  padding: 24px 0 23px;
`

export const Title = styled.h1`
  font-weight: 300;
  font-size: 32px;
  color: var(--text-primary);
  margin: 0;
`

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--border-primary);
`

export const TableGrid = styled.div`
  display: grid;
  grid-template-columns: 25% 22% repeat(4, 1fr);
  border-bottom: 1px solid var(--border-primary);
  padding: 28px 0px;

  .cohort-text {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--text-secondary);
  }

  ${mediaqueries.mobile`
    display: none;
  `}
`

export const TableBody = styled.div`
  display: grid;
  grid-template-columns: 25% 22% repeat(4, 1fr);
  border-bottom: 1px solid var(--border-primary);
  align-items: center;
  padding: 24px 0;
  position: relative;

  .header {
    display: none;
  }

  > .detail-btn {
    width: 24px;
    height: 18px;
    color: var(--brand-regular);
    justify-self: end;
    cursor: pointer;
  }

  .course-text {
    justify-content: center;
  }

  .placeholder {
    display: none;
  }

  ${mediaqueries.mobile`
    grid-template-columns: 60% 40%;
  
    .header {
      display: block;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: var(--text-secondary);
    }

    .course-text {
      justify-content: flex-start;
    }

    .detail-btn {
      position: absolute;
      top: 22px;
      right: 20px;
    }

    .placeholder {
      display: block;
    }
  `}
`

export const Tooltip = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;

  > span {
    visibility: hidden;
    width: 235px;
    height: 80px;
    padding: 12px;
    background: var(--gray-primary);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    color: var(--text-primary);
    margin-bottom: -8px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: -50%;
    left: 400%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;

    > p {
      font-family: 'Lato';
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  &:hover {
    > span {
      visibility: visible;
      opacity: 1;
    }
  }
`
export const CohortNote = styled.div`
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  & > div > div:first-of-type {
    width: 215px;
    margin-right: 16px;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-primary);
  }

  & div > div:last-of-type {
    color: var(--sub-text-secondary);
    width: 343px;
  }

  & div > div:last-of-type,
  & div > div:last-of-type > a {
    font-size: 16px;
    line-height: 24px;
  }

  & div > div:last-of-type > a {
    color: var(--brand-regular);
    cursor: pointer;
  }

  @media (max-width: 820px) {
    display: none;   
  }
`
export const CohortDetails = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    & > div:first-child {
      font-size: 18px;
      line-height: 24px;
      width: 215px;
      margin-right: 16px;
    }

    & > div:last-child {
      font-size: 16px;
      line-height: 24px;
      color: var(--sub-text-secondary);
      width: 343px;
    }
  }

  & > .mobile-view {
    display: none;
  }

  & > .mb-unset-reset {
    margin-bottom: unset;
  }

  @media (max-width: 820px) {
    & > .mobile-view {
      display: flex;

      > div:last-child > a {
        font-size: 16px;
        line-height: 24px;
        color: var(--brand-regular);
        line-break: anywhere;
      }
    }

    & > div > div:last-child {
      width: 409px;
    }

    & > .mb-unset-reset {
      margin-bottom: 16px;
    }

    & > .mb-unset {
      margin-bottom: unset;
    }
  }

  @media (max-width: 480px) {
    & > div > div:first-child {
      width: 180px;
    }

    & > div > div:last-child {
      width: 131px;
    }
  }
`

export const BtnGroupHeading = styled.h3`
  &.admin-home {
    width: unset;
  }

  @media (max-width: 820px) {
    &.admin-home {
      padding-left: unset;
    }
  }
`

export const BtnGroupItems = styled.h3`
  @media (max-width: 480px) {
    margin-bottom: unset !important;
    height: 29px !important;
    padding-top: 8px;
  }
`
export const StartDate = styled.h1`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--text-dark-off);
  margin-right: 31px;

  @media (max-width: 820px) {
    margin-right: 24px;
  }

  @media (max-width: 480px) {
    margin-bottom: unset;
  }
`
export const CohortName = styled.h1`
  font-weight: 300;
  font-size: 32px;
  line-height: 48px;
`

export const CustomFormGroup = styled(FormGroup)`
  width: 400px;
  display: flex;
  flex-direction: column;
  ${mediaqueries.mobile`
    width: 100%;
  `}
`

export const CustomLabel = styled(Label)`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
`

export const inputStyles = css`
  width: 100%;
  min-height: 40px;
  height: 40px;
  margin-bottom: ${({ reduced }) => reduced ? '15px' : '24px'};
  background-color: var(--input-primary);
  border: 1px solid ${props => props.error
    ? 'var(--error-color)' : 'var(--border-primary)'};
  color: var(--text-primary);
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  &:disabled {
    background-color: var(--input-primary);
    border: 1px solid var(--border-primary);
    color: var(--text-primary);
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    box-shadow: var(--shadow-primary);
  }
  &:focus, &:hover:not(:disabled) {
    border: 1px solid var(--brand-regular);
    background-color: var(--input-primary);
    color: var(--text-primary);
    box-shadow: none;
  }
`

export const CustomInput = styled(Input)`
  ${inputStyles}
`

export const CustomSelect = styled(Select)`
  min-height: 40px;
  margin-bottom: ${({ reduced }) => reduced ? '15px' : '24px'};
  background-color: var(--input-primary);
  color: var(--text-primary);
  & > .select__control {
    width: 100%;
    height: auto;
    min-height: 40px;
    border: 1px solid ${props => props.error
    ? 'var(--error-color)' : 'var(--border-primary)'};
    &.select__control--is-focused {
      border: 1px solid var(--brand-regular) !important;
    }
    .select__indicators {
      border-left: 1px solid var(--border-primary);
      padding-left: 7px;
      margin: 3px auto;
      .select__clear-indicator {
        padding-left: 1px;
      }
    }
    .select__single-value {
      font-size: 16px;
    }
    .select__value-container {
      font-size: 16px;
      .select__input {
        color: var(--text-primary);
      }
      .select__multi-value {
        background: var(--canvas-secondary);
        padding: 6px 3px 6px 3px;
        border-radius: 5px;
        .select__multi-value__label {
          color: var(--text-primary);
          font-size: 16px;
          font-weight: 400;
          line-height: 19.2px;
        }
        .select__multi-value__remove:hover {
          background-color: transparent;
          color: var(--text-primary);
        }
      }
    }
  }
  & > .select__menu {
    width: 100%;
    height: 200px;
    color: white!important;
  }
`

export const CustomDatePicker = styled(ReactDatePicker)`
  background: var(--input-primary);
  outline: none;
  border: 1px solid ${props => props.error
    ? 'var(--error-color)' : 'var(--border-primary)'};
  padding-left: 12px;
  border-radius: 5px;
  color: var(--text-primary);
  width: 400px;
  ${mediaqueries.mobile`
    width: 100%;
  `}
  height: 40px;
  &::placeholder,
  &::-webkit-input-placeholder {
    color: var(--text-dark-off);
    font-size: 16px;
    line-height: 19px;
  }
  &:hover:not(:disabled) {
    border: 1px solid var(--brand-regular);
  }
  &:focus {
    border: 1px solid var(--brand-regular);
  }
  &:disabled {
    color: var(--gray-tertiary);
    background: var(--input-primary);
    border: 1px solid var(--border-primary);
    &::placeholder,
    &::-webkit-input-placeholder {
      color: var(--gray-tertiary);
    }
  }
`

export const DateContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
    margin-bottom: 15px;
    .react-datepicker__input-container::after {
      content: url(${calendar});
      position: absolute;
      right: 3%;
      top: 29%;
    }
  }
  .react-datepicker {
    font-family: "Lato";
    background: var(--black-quaternary);
    border: none;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 16px;
    color: var(--text-primary);
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 14px;
    color: var(--text-primary);
  }
  .react-datepicker__month-container {
    background: var(--black-quaternary);
  }
  .react-datepicker__header {
    background: var(--black-quaternary);
    border-bottom: 1px solid var(--border-primary);
  }
  .react-datepicker__time-list {
    background: var(--black-quaternary);
  }
  .react-datepicker__time-list-item {
    font-size: 14px;
    color: var(--text-primary);
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__time-list-item--selected {
    background: var(--brand-regular) !important;
    color: var(--text-dark) !important;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__time-container {
    border-left: 1px solid var(--border-primary);
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__time-list-item:hover {
    color: var(--text-dark);
    background: var(--brand-regular-hover)!important;
  }
  .react-datepicker__day--disabled:hover {
    color: var(--text-dark-off)!important;
    background: transparent!important;
  }
  .react-datepicker__time-box {
    width: 84px !important;
  }
  .react-datepicker__time-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--shadow-primary);
    border-radius: 10px;
    background-color: transparent;
  }
  .react-datepicker__time-list::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
  }
  .react-datepicker__time-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px var(--shadow-primary);
    background: rgba(255, 255, 255, 0.1);
  }
  .react-datepicker__navigation {
    top: 14px;
  }
  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 91px;
  }
`

export const CustomCheckBox = styled.label`
  display: block;
  width: max-content;
  position: relative;
  padding-left: 36px;
  margin-bottom: 17px;
  cursor: pointer;
  font-size: 16px;
  line-height: 19px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
  }

  span {
    position: absolute;
    top: -3px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: var(--text-dark);
    border: 2px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 5px;
  }

  input:disabled ~ span {
    border: 2px solid var(--brand-regular-disabled);
  }

  input:checked ~ span {
    background-color: var(--brand-regular);
    border: 3px solid var(--brand-regular);
    box-sizing: border-box;
    border-radius: 5px;
  }

  span::after {
    display: none;
    position: absolute;
    content: url(${checkMark});
  }

  input:checked ~ span::after {
    left: 2px;
    display: block;
  }
`

export const ErrorText = styled.span`
  float: left;
  font-size: 14px;
  padding: 0;
  margin-bottom: 8px;
  color: var(--error-color)!important;
`
export const TabsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-top: 32px;
  margin-bottom: 36px;

  button:first-child {
    margin-right: 16px;
  }
`
export const Tab = styled.button`
  padding: 12px 18px 12px 16px;
  height: 48px;
  background: #1D1D1F;
  border: 1px solid ${props => props.highlight
    ? '#5FC4B8'
    : props.selected ? '#FFFFFF' : 'transparent'};
  border-radius: 5px;
  color: ${props => props.highlight ? '#5FC4B8' : '#FFFFFF'};

  :disabled {
    color: #98A4AE;
    border-color: transparent;
  }
`
export const Institutions = styled.label`
  display: flex;
  flex-flow: column;
  margin-bottom: 17px;

  div:first-child {
    margin-bottom: 12px;
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    margin-bottom: 12px;
  }
`
export const multiSelectstyles = {
  ...selectInputStyles,
  control: (base, state) => ({
    ...base,
    height: '56px',
    width: '400px',
    background: state.isDisabled ? 'rgba(0, 0, 0, 0.1)' : 'rgba(0, 0, 0, 0.3)',
    color: '#FFFFFF',
    border: `1px solid ${
      state.isFocused ? '#5FC4B8'
        : state.isDisabled
          ? 'rgba(255, 255, 255, 0.1)'
          : 'rgba(255, 255, 255, 0.3)'

    }`,
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid #5FC4B8'
    }
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    color: state.isDisabled && 'rgba(255, 255, 255, 0.3)',
    marginRight: '12px'
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'var(--sub-text-secondary)',
    minHeight: '40px',
    paddingLeft: '16px',
    paddingRight: '18px',
    background: '#25272B!important',
    border: '1px solid transparent',
    ...(state.isSelected
      ? {
        color: '#FFFFFF'
      }
      : {}),
    '&:hover': {
      border: '1px solid #5FC4B8',
      borderRadius: '5px',
      color: '#FFFFFF',
      cursor: 'pointer'
    },
    '&:focus': {
      border: '1px solid #98A4AE'
    }
  })
}
export const SelectLabel = styled.label`
  #select-label-text {
    display: inline-block;
    margin-bottom: 6px;
  }
`
export const CustomOption = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content:space-between;
  align-items: center;
`
export const FirstOptionWrapper = styled.div`
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;

  display: flex;
  background: #25272B!important;
  height: 56px;
  height: 56px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 16px;
  border-bottom: 1px solid #98A4AE;
`
export const SelectLink = styled.a`
  color: #5FC4B8!important;
  cursor: pointer;
  padding: 0px;
  margin: 0px;

  :hover {
    color: #B8FFF7!important;
  }

  :active {
    filter: brightness(0.8);
  }
`
export const SelectWithCustomScroller = styled(Select)`
  .css-ccfn4t-MenuList {
    scrollbar-width: thin;
    scrollbar-color: #1D1D1F transparent;
    /* For Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: #25272B!important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #1D1D1F;
      border-radius: 20px;
    }
  }
`
export const CoursePoolWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 24px;
  padding-left: 32px;
`
export const CoursePoolLabel = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #CCD4D8;
  margin: 3.5px 12px 0px 0px;
`
export const CoursePool = styled.div`
  width: 100%;
  height: fit-content;
  max-height: 93px;
  margin-bottom: ${props => props.mb || '-8px'};
  ${props => props.mt && `margin-top: ${props.mt};`}
  display: flex;
  flex-flow: row wrap;
  justify-content: start;

  ${props => props.scrollable && css`
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #1D1D1F transparent;
    /* For Chrome, Edge, and Safari */
    &::-webkit-scrollbar {
      width: 6px;
    }
  
    &::-webkit-scrollbar-track {
      background: transparent!important;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: gray;
      border-radius: 20px;
    }
  `}
`
export const CoursePoolItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 23px;
  padding: 4px 8px;
  background: #25272B;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #B1BFC5;
  margin: 0 12px 12px 0;
  

  span {
    display: inline-block;
    max-width: 194px;
    margin-right: ${props => props.mr ? props.mr : '10.5px'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
export const Selection = styled(CoursePoolItem)`
  margin: 0 0 0 8px;
`
export const Cross = styled.img`
  width: 7px;
  height: 7px;
  cursor: pointer;
`
export const DatesHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-top: 36px;
`
export const SliderArrowWrapper = styled.div`
  display: none;
  flex-flow: row nowrap;
  img:first-child {
    margin-right: 16px;
  }
  ${mediaqueries.smallMobile`
    display: flex;
  `}
`
export const FilledSliderArrow = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  ${props => props.rotate && 'transform: rotate(180deg);'}
  
  ${props => props.disabled && 'filter: brightness(0.5)'}
  ${props => !props.disabled && css`
    :hover {
      filter: brightness(1.2);
    }

    :active {
      filter: brightness(0.5);
    }
  `}
`
export const SliderArrow = styled.img`
  width: 37.3px;
  height: 16px;
  cursor: pointer;
  ${mediaqueries.smallMobile`
    display: none;
  `}
`
export const LabelText = styled.label`
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline!important;
  width: 200px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: ${[props => props.marginBottom || '0px']};
`
export const InputLabel = styled(LabelText)`
  flex-flow: column nowrap;
  align-items: start!important;
  ${mediaqueries.smallMobile`
    width: 100%;
  `}

  span {
    display: block!important;
    margin-bottom: 8px;

    ${({ showTooltip }) => showTooltip && css`
      ::after {
        content: '';
        display: inline-block;
        height: 16px;
        width: 16px;
        background: url(${toolTipIcon});
        margin-left: 8px;
      }
    `}
  }
`
export const Link = styled.a`
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5fc4b8 !important;
  margin: 0px 0px 0px 12px;
  cursor: pointer;
  :hover {
    color: #b8fff7 !important;
  }
  :active {
    filter: brightness(0.8);
  }
`
export const SliderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 340px;
  margin-bottom: 37px;
`
export const SliderContainer = styled.div`
  width: calc(100% - 104px);
  height: 300px;
  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`
export const DurationsWrapper = styled.div`
  width: 100%;
  margin-bottom: -5px;

  div:last-child {
    margin-bottom: 26px;
  }

`
export const DurationOptionsWrapper = styled.div`
  margin-top: -34px;
  margin-bottom: ${props => props.expanded ? '55px' : '42px'};

  div:last-child {
    margin-bottom: 0px;
  }
`
export const Durations = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-left: 24px;
  margin-top: 7px;
  margin-bottom: 20px;

  div:first-child {
    margin-bottom: 7px;
  }
`
export const ActionsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin-left: 24px;
  margin-bottom: 16px;

  ${({ disabled }) => disabled && `filter: brightness(0.5);`}

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`
export const ActionText = styled.div`
  margin-left: 8px;
  ${props => props.disabled && 'filter: brightness(0.5)'};
`
export const ActionTitle = styled.h2`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 4px;
`
export const ActionDescription = styled.p`
  font-size: 16px;
  width: 340px;
  color: #98A4AE;

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`
export const TextFieldsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 424px;
  justify-content: space-between;
  margin-bottom: 16px;

  ${mediaqueries.smallMobile`
    width: 100%;
    margin-bottom: 55px;
  `}
`
export const TextFieldContainer = styled.div`
  position: relative;
  width: 200px;
  margin-bottom: 24px;

  i {
    position: absolute;
    height: 16px;
    width: 16px;
    display: block;
    background-image: url(${clearIcon});
    right: 16px;
    top: 20px;
    z-index: 4;
    cursor: pointer;
  }

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`
export const TextField = styled.input`
  width: 100%;
  height: 56px;
  background: #0D0D0F;
  border: 1px solid #5B6067;
  border-radius: 5px;
  color: white;
  padding: 16px;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  position: relative;

  :hover {
    border: 1px solid #5FC4B8;
  }
`
export const ReviewButton = styled.button`
  padding: 16px 24px;
  background: #5FC4B8;
  border: none!important;
  outline: none!important;
  border-radius: 5px;
  color: black;

  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;

  ${mediaqueries.smallMobile`
    width: 100%;
  `}

  :hover:not(:disabled) {
    background: #B8FFF7!important;
  }

  :disabled,
  :active {
    filter: brightness(0.5);
  }
`
export const ReviewPageWrapper = styled.div`
  width: 100%;
  margin-top: -4px;
`
export const ReviewTitle = styled.h1`
  width: 100%;
  color: #CCD4D8;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  padding-bottom: 12px;
  margin-bottom: 0px;
  cursor: pointer;
  ${props => props.showBorder && 'border-bottom: 1px solid rgba(255, 255, 255, 0.1);'}

  ::after {
    content: '';
    display: inline-block;
    background-image: url(${chevronRight});
    transition: ease-in-out 0.3s;
    transform: rotate(${props => props.isOpen ? '-90deg' : '90deg'});
    ${props => props.isOpen && ''}
    height: 16px;
    width: 16px;
    margin-left: 12px;
  }
`
export const SectionWrapper = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`
export const SectionTitle = styled.h2`
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-top: 32px;
  color: #CCD4D8;
  font-size: 18px;
  font-family: Lato;
  line-height: 24px;

  span {
    color: #FFF;
  }
`
export const SectionInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: '#98A4AE';
  font-size: 16px;
  font-family: Lato;
  line-height: 24px;

  ${props => props.isSectionInfo && css`
    font-style: italic;
    color: #CCD4D8;
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
  `}

  ${props => props.showWarning && css`
    ::before {
      content: '';
      display: inline-block;
      margin-right: 8px;
      width: 16px;
      height: 16px;
      background-image: url(${warningIcon});
    }
  `}
`
export const Dot = styled.div`
  display: inline-block;
  margin: 0 8px;
  height: 2px;
  width: 2px;
  border-radius: 2px;
  background: #98A4AE;
`
export const ReviewTableWrapper = styled.div`
  margin-top: 32px;
`
export const MessageAndLinkWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 12px;
`
export const Message = styled.h2`
  color: #FFF;
  font-size: 16px;
  line-height: 24px;
  margin-right: 12px;
  margin-bottom: 12px;

  ${mediaqueries.smallMobile`
    width: 100%;
  `}
`
export const DownloadCSVLink = styled.button`
  display: flex;
  align-items: baseline;
  color: #5fc4b8;
  font-size: 14px;
  font-family: Lato;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  outline: none!important;
  margin-bottom: 12px;

  ::before {
    content: '';
    width: 16px;
    height: 15px;
    display: inline-block;
    margin-right: 8px;
    background-image: url(${downloadIcon});
  }

  :hover {
    filter: brightness(1.2);
  }

  :active {
    filter: brightness(0.8);
  }
`

export const TableSlidersContainer = styled.div`
  display: flex;
  width: 64px;
  justify-content: space-between;
  margin-left: auto;
  margin-bottom: 12px;
`
export const GradientOverlay = styled.div`
  width: 100%;
  position: relative;

  ::after {
    content: '';
    display: none;
    position: absolute;
    width: calc(100% - 5px);
    z-index: 2;
    height: calc(100% - 7px);
    top: 1px;
    left: 0;
    background: linear-gradient(to right, transparent 0%, transparent 70%, #161618);
    pointer-events: none;

    ${mediaqueries.tablet`display: block;`}
  }
`
export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: 654px;
  overflow: scroll;

  scrollbar-width: thin;
  scrollbar-color: #5B6067 transparent;
  /* For Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent!important;
  }

  &::-webkit-scrollbar-thumb {
    background-color: gray;
    border-radius: 20px;
  }
`
export const Table = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  min-width: 930px;
  width: 100%;
  border: 1px solid #232327;
  border-radius: 5px 0px 0px 5px;
  border-bottom: none;
`
export const TableHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  align-items: center;
  height: 54px;
  padding: 16px;
  border-bottom: 1px solid #232327;
  background-color: var(--canvas-primary);
`
export const TableRow = styled(TableHeader)`
  position: relative;
  padding: 0 16px;
  z-index: 0;
  height: fit-content;
  min-height: 67px;
`
export const NameCell = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${props => props.isHeaderCell
    ? css`
      color: #B1BFC5;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 1px;
      text-transform: uppercase;`
    : css`
      color: #FFF;
      font-size: 16px;
    `}
  width: 300px;
  margin-right: 24px;

  span {
    display: inline-block;
    margin-right: 8px;
  }
`
export const DurationCell = styled(NameCell)`
  width: 100px;
`
export const DateCell = styled(NameCell)`
  width: 150px;
`
export const ScheduleCell = styled(NameCell)`
  margin-right: 0px;
  width: calc(100% - 728px);
`
export const SortButtonsWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`
export const SortAscendingButton = styled.button`
  border: none;
  outline: none!important;
  width: 12px;
  height: 12px;
  background: ${props => props.selected
    ? `url(${sortAscendingActiveIcon})`
    : `url(${sortAscendingInactiveIcon})`};
  margin-bottom: -2px;
  padding: 0!important;
`
export const SortDescendingButton = styled(SortAscendingButton)`
  transform: rotate(180deg);
  margin-bottom: 0px;
`
export const PaginationWrapper = styled.div`
  width: 100%;
  height: 56px;
  padding: 16px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background-color: #1D1D1F;
`
export const PaginationButtonsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 16px;
`
export const PaginationButton = styled.button`
  color: ${props => props.isSelected ? '#FFF' : '#5FC4B8'};
  min-width: 10px;
  padding: 0;
  background: transparent;
  margin-right: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  outline: none!important;

  ${props => (props.type === 'Next' || props.type === 'Previous') && css`
    background: url(${chevronRightTeal});
    width: 16px;
    height: 16px;
    ${props => props.type === 'Previous' && 'transform: rotate(180deg);'}
  `}
  
  ${props => props.type === '...' && 'cursor: default!important;'}

  :hover {
    ${props => props.type !== '...' && 'filter: brightness(1.5);'}
  }
`
export const PaginationText = styled.span`
  display: block;
  color: #B1BFC5;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

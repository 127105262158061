export const FETCH_GRADES = 'fetchGrades'
export const FETCH_GRADE_DETAILS = 'fetchGradeDetails'

export const PAGE_TYPE = {
  STUDENT_CRITERIA: 'student-criteria',
  EXPORTED_INFORMATION: 'exported-information'
}

export const GRADE_DETAILS_MAP = {
  PRACTICE_TERMS: 'Practice Terms'
}

export const QUIZ_GRADES = {
  ALL: 'Quiz grades - All',
  HIGHEST: 'Quiz grades - Highest'
}

export const TERM_LENGTHS = {
  EXTENDED: 39,
  STANDARD: 14,
  INTENSIVE: 7,
  STUDIOOTHER: 'Studio/Other'
}

export const EXPOSE_KEYS = {
  COURSES: 'courses',
  TERM_LENGTHS: 'termLengths',
  COHORT_START_RANGE: 'cohortStartRange',
  COHORT_END_RANGE: 'cohortEndRange',
  USE_PRESET: 'usePreset',
  PARTNERS: 'partners',
  RELATIONSHIPS: 'relationships',
  CREDIT_GRANTING_INSTITUTIONS: 'creditGrantingInstitutions',
  STUDENT_STATUS: 'studentStatus',
  STUDENT_GRADES: 'studentGrades',
  OTHER_INFORMATION: 'other',
  SELECTED_CSV_INFO: 'selectedCSVInfo',
  STUDENT_IDENTIFICATION: 'studentIdentification',
  STUDENT_CONTACT_INFO: 'studentContactInfo',
  OTHER_STUDENT_INFO: 'otherStudentInfo',
  COHORT_INFO: 'cohortInfo',
  LOGIN_ACTIVITY: 'loginActivity',
  GRADE_DETAILS: 'gradeDetails',
  CREATE_ZIP: 'createZIP',
  SELECTED_PRESET: 'selectedPreset'
}

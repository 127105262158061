import api from '../api'

export {
  validateOutlierEmail,
  getRoleDescription,
  getAvailableRoles,
  getStatusDescription,
  canDisableRole,
  canUserEditSpecificRole,
  checkPermission
}

function validateOutlierEmail (email) {
  // eslint-disable-next-line
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const emailInLowerCase = email.toLowerCase()
  const isValidEmail = re.test(email)
  if (!isValidEmail) return false

  return (
    emailInLowerCase.endsWith('@outlier.org') ||
    emailInLowerCase.endsWith('@engineering.outlier.org') ||
    emailInLowerCase.endsWith('@consultant.outlier.org') ||
    emailInLowerCase.endsWith('@savvas.com')
  )
}

function getRoleDescription (roleName, allRoles) {
  const getRoleObject = () => allRoles.find(role => role.role === roleName)
  return getRoleObject(roleName) ? getRoleObject(roleName).description : ''
}

function capitalizeTheFirstLetterOfEachWord (words) {
  var separateWord = words.toLowerCase().split(' ')
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
      separateWord[i].substring(1)
  }
  return separateWord.join('')
}

function isStringIncludedInArray (array, string) {
  return array.some(item => item.includes(string))
}

function getAvailableRoles (roles, userPermissions) {
  const availableRoles = roles?.map(role => {
    if (isStringIncludedInArray(userPermissions, 'add' + capitalizeTheFirstLetterOfEachWord(role))) return role
    return false
  })
  return availableRoles?.filter(role => role !== false)
}

function canUserEditSpecificRole (role, userPermissions) {
  return isStringIncludedInArray(
    userPermissions,
    'edit' + capitalizeTheFirstLetterOfEachWord(role)
  ) || isStringIncludedInArray(
    userPermissions,
    'disable' + capitalizeTheFirstLetterOfEachWord(role)
  )
}

function canDisableRole (role, userPermissions) {
  if (!role) return false
  return isStringIncludedInArray(userPermissions, 'disable' + capitalizeTheFirstLetterOfEachWord(role))
}

function getStatusDescription (isActive) {
  return isActive ? `Can log into the Admin Dashboard and regain the permissions associated with their role.`
    : `Cannot log into the Admin Dashboard.
       This user’s information will still appear in change notes throughout the experience.
       Can be activated again later.`
}

async function checkPermission (permissionName) {
  try {
    const { permissions } = await api.getCurrentUserPermissions()
    const containAddPermission = permissions.some(permission => permission === permissionName)
    return containAddPermission
  } catch (err) {
    console.error(err)
  }
}

export function getContentCreatorText (isContentViewer) {
  return isContentViewer
    ? 'Content Creator: Can access Dato via the LMS.'
    : 'Content Creator (Disabled): Cannot access Dato via the LMS. This user will have the default course view.'
}

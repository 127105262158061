import React, { useState } from 'react'
import {
  ClearIcon,
  DarkSearchField,
  FiltersHeader,
  FiltersSection,
  FiltersWrapper,
  SearchButtonIcon,
  SearchIcon,
  SearchTextField
} from './style'
import { ContentTitle } from '../../styles/text'
import { TransparentButton } from '../../styles/buttons'
import CustomSelect from '../../Components/CustomSelect/UpdatedSelect'

function Filters ({
  filtersData,
  selectedData,
  setSelectedData
}) {
  const noAdvisorsData = !filtersData.advisors?.length
  const noDegreesData = !filtersData.degrees?.length

  const [showSearch, setShowSearch] = useState(false)

  const handleRecordsChange = (record, records) => {
    const { value } = record || {}

    const isSelected = selectedData?.[records]?.find(r => r?.value === value)
    if (isSelected) {
      return setSelectedData(prev => ({
        ...prev,
        [records]: prev[records].filter(r => r?.value !== value)
      }))
    }

    setSelectedData(prev => ({
      ...prev,
      [records]: [...prev[records], record]
    }))
  }

  const handleReset = () => {
    setShowSearch(false)
    setSelectedData(({ ...filtersData, searchText: '' }))
  }

  const handleTextChange = (value) => {
    setSelectedData(prev => ({
      ...prev,
      searchText: value
    }))
  }

  return (
    <FiltersSection>
      <FiltersHeader>
        <ContentTitle>Filters</ContentTitle>
        <TransparentButton
          onClick={handleReset}
          disabled={noAdvisorsData || noDegreesData}
        >
          reset
        </TransparentButton>
      </FiltersHeader>

      <FiltersWrapper>
        <CustomSelect
          isMulti
          height='48px'
          name='advisors'
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isDisabled={noAdvisorsData}
          value={selectedData.advisors}
          options={filtersData.advisors}
          placeholder='Select an advisor'
          onChange={event => handleRecordsChange(event, 'advisors')}
        />

        <CustomSelect
          isMulti
          height='48px'
          name='degrees'
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          isDisabled={noDegreesData}
          value={selectedData.degrees}
          options={filtersData.degrees}
          placeholder='Select a degree'
          onChange={event => handleRecordsChange(event, 'degrees')}
        />

        {!showSearch ? (
          <SearchButtonIcon
            onClick={() => setShowSearch(true)}
          />
        ) : (
          <DarkSearchField isDark style={{ width: '300' }}>
            <SearchTextField
              placeholder='Search for a student'
              value={selectedData?.searchText}
              onChange={(e) => handleTextChange(e.target.value)}
            />
            {selectedData?.searchText ? (
              <ClearIcon onClick={() => handleTextChange('')} />
            ) : (
              <SearchIcon />
            )}
          </DarkSearchField>
        )}
      </FiltersWrapper>
    </FiltersSection>
  )
}

Filters.displayName = 'Filters'
export default Filters

import React, { useEffect } from 'react'
import {
  AssessmentsList,
  AssessmentsDescription,
  AssessmentsHeader,
  AssignmentDeadline,
  AssignmentRow,
  AssignmentTitle,
  ExtensionLength,
  ExtensionLengthSelectors,
  InputLabel,
  NumberDropDownStyles,
  TimeUnitDropDownStyles
} from './style'
import Select from 'react-select'
import { getNumberAndUnitsLists } from './AddExtensionUtils'
import { getDateString } from '../../../utilities/dateTimeUtil'
import orderBy from 'lodash/orderBy'
import { DropdownIndicator } from '../../CustomSelect/CustomSelectComponents'

const MultipleAssessments = ({
  assessmentsList,
  selectedNumber,
  setSelectedNumber,
  selectedUnitLength,
  setSelectedUnitLength
}) => {
  const { numbersList, unitsList } = getNumberAndUnitsLists()
  useEffect(() => {
    setSelectedUnitLength(unitsList[0])
    // eslint-disable-next-line
  }, [])
  const sortedAssessmentsList = orderBy(assessmentsList, 'unlockTime', 'asc')

  return (
    <AssessmentsList>
      <AssessmentsHeader>Assessments</AssessmentsHeader>
      <AssessmentsDescription>
        {`Mastery assignments that have submissions from the student will
                not receive an extension. All assessments close at 12pm ET.`}
      </AssessmentsDescription>
      {sortedAssessmentsList.map((assessment, index) => {
        const formattedLockDate = getDateString(assessment.lockTime, 'MM/DD/YY')
        return (
          <AssignmentRow key={index}>
            <AssignmentTitle>{assessment.examTitle}</AssignmentTitle>
            <AssignmentDeadline>Original deadline: {formattedLockDate}</AssignmentDeadline>
          </AssignmentRow>
        )
      })}
      <ExtensionLength>
        <InputLabel>Extension length</InputLabel>
        <ExtensionLengthSelectors>
          <Select
            components={{ DropdownIndicator }}
            options={numbersList}
            placeholder='Select length'
            styles={{ ...NumberDropDownStyles }}
            onChange={(option) => {
              setSelectedNumber(option)
            }}
            value={selectedNumber}
            isDisabled={false}
          />
          <Select
            components={{ DropdownIndicator }}
            options={unitsList}
            placeholder='Select length'
            styles={{ ...TimeUnitDropDownStyles }}
            onChange={(option) => {
              setSelectedUnitLength(option)
            }}
            value={selectedUnitLength}
            isDisabled={false}
          />
        </ExtensionLengthSelectors>
      </ExtensionLength>
    </AssessmentsList>
  )
}
MultipleAssessments.displayName = 'MultipleAssessments'
export default MultipleAssessments

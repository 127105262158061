import React, { useState } from 'react'
import {
  FormContainer,
  FormLabel,
  HideFieldsContainer,
  InputContainer,
  ToggleHiddenFieldsContainer,
  ToggleHiddenFieldsDropdown
} from './styles'
import RecordInput from './RecordInput'
import { ReactComponent as DropdownArrow } from '../../assets/icons/dropdown-arrow.svg'

const HiddenFields = ({ hiddenColumns, row, setRow, canUserEditDatabase }) => {
  const [isHiddenFieldsVisible, setIsHiddenFieldsVisible] = useState(false)

  if (!hiddenColumns.length) return null

  return (
    <>
      <ToggleHiddenFieldsContainer>
        <hr />
        <ToggleHiddenFieldsDropdown
          isHiddenFieldsVisible={isHiddenFieldsVisible}
          onClick={() =>
            setIsHiddenFieldsVisible(
              (isHiddenFieldsVisible) => !isHiddenFieldsVisible
            )
          }
          data-testid='toggle-hidden-fields-dropdown'
        >
          {isHiddenFieldsVisible ? 'hide' : 'show'} {hiddenColumns.length}{' '}
          hidden field{hiddenColumns.length > 1 ? 's' : ''}
          <DropdownArrow />
        </ToggleHiddenFieldsDropdown>
        <hr />
      </ToggleHiddenFieldsContainer>
      <HideFieldsContainer
        isHiddenFieldsVisible={isHiddenFieldsVisible}
        data-testid='hidden-fields-container'
      >
        {hiddenColumns.map((field) => {
          const { columnName, isAutoGenerated } = field
          if (columnName === 'id' || isAutoGenerated) return null
          return (
            <FormContainer key={columnName}>
              <FormLabel>{columnName}</FormLabel>
              <InputContainer>
                <RecordInput
                  field={field}
                  row={row}
                  setRow={setRow}
                  canUserEditDatabase={canUserEditDatabase}
                />
              </InputContainer>
            </FormContainer>
          )
        })}
      </HideFieldsContainer>
    </>
  )
}

HiddenFields.displayName = 'HiddenFields'
export default HiddenFields

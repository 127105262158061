import React, { useMemo } from 'react'
import { getDateString } from '../../utilities/dateTimeUtil'
import ModalComponent from '../ModalComponent/ModalComponent'
import NotesCollapse from './ChangeNotesCollapse'
import './css/change-note-modal.css'

function ChangeNotesModal ({
  show,
  setModalShowHide,
  history,
  courseData,
  studentProgress
}) {
  const { studentAnswers = {} } = studentProgress || {}

  const sortedHistory = useMemo(() => {
    return history.map(change => {
      if (!change.isExam) return change

      return {
        ...change,
        ...(change.questions ? {
          questions: change.questions.map(question => {
            const { questionUuid, number } = question
            const { questionIndex } = studentAnswers[questionUuid] || {}
            return {
              ...question,
              number: (questionIndex || questionIndex === 0) ? questionIndex + 1 : number
            }
          })
        } : {})
      }
    })
  }, [history, studentAnswers])

  return (
    <>
      <ModalComponent show={show} handleClose={() => setModalShowHide(false)}>
        <div className={`change-note-modal__container ${show ? 'show' : ''}`}>
          <div className='change-note-modal__header'>
            <span>Change Notes ({history.length})</span>
            <span className='close-icon' onClick={() => setModalShowHide(false)} >
              <img src={require('../../assets/icons/cross.png')} alt='Close Modal' />
            </span>
          </div>
          <div className='changes-notes'>
            {sortedHistory.map((change, index) => {
              const isAdjusted = change?.registrar &&
              change?.registrar.email && change.sectionUuid !== 'participation'

              if (isAdjusted) {
                const { timestamp, from, to, sectionUuid, isAssignment } = change
                const { email } = change?.registrar
                const chapterSectionTitle = courseData?.chapters
                  .find(chapter => chapter.chapter_uuid === sectionUuid)?.title
                const section = isAssignment ? change.section : chapterSectionTitle
                return (
                  <div className='note-li' key={index + '-note'}>
                    <span>
                      {getDateString(timestamp, 'MMM D, YYYY h:mmA')}
                    </span>
                    <span>
                      {`${email} changed ${section} grade from ${from || 0}% to ${to}%`}
                    </span>
                    <NotesCollapse changeNotesDetails={change} />
                  </div>
                )
              }

              const { dateTime, isExam, user, section, quiz, from,
                to, questions, statusModificationDetails, isAssignment,
                isParticipation,
                assignmentModificationDetails,
                participationModificationDetails
              } = change
              const changeNotesDetails = questions ||
              statusModificationDetails ||
              participationModificationDetails ||
              assignmentModificationDetails

              return (
                <div className='note-li' key={index + '-note'}>
                  <span>
                    {dateTime}
                  </span>
                  {section ? (
                    <span>
                      {`${user} changed ${section} ${
                        isExam || isAssignment || isParticipation ? '' : `Quiz ${quiz} `
                      }grade from ${from || 0}${isParticipation ? '' : '%'} to ${to}${isParticipation ? '' : '%'}`}
                    </span>
                  ) : (
                    <span>
                      {`${user} changed course status from ${from} to ${to}`}
                    </span>
                  )}

                  <NotesCollapse changeNotesDetails={changeNotesDetails} />
                </div>
              )
            })}
          </div>
        </div>
      </ModalComponent>
    </>
  )
}

export default ChangeNotesModal

import React from 'react'
import { Tab, TabWrapper } from './styles/index.styled'

const TabComponent = ({ activeTab, setActiveTab }) => {
  return (
    <TabWrapper>
      <Tab
        active={activeTab === 'users'}
        onClick={() => setActiveTab('users')}>
      Users
      </Tab>
      <Tab
        active={activeTab === 'roles'}
        onClick={() => setActiveTab('roles')}>
      Roles
      </Tab>
    </TabWrapper>
  )
}

export default TabComponent

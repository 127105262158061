import React, { useEffect, useState } from 'react'
import { DatabaseViewerContainer } from './styles'
import TableViewComponent from './TableViewComponent'
import { useHistory, useParams } from 'react-router-dom'
import RecordModal from './RecordModal'
import AddViewModal from './AddViewModal'
import dataTables from './dataTables'
import api from '../../api'
import { getFilteredMetaData, getModifiedMetaData } from './utils'
import DBViewerHeader from './DBViewerRedesign/DBViewerHeader'
import {
  getDBViewerPreferences,
  saveDBViewerPreferences
} from '../../utilities/dataBaseUtils'
import { DATABASE_EDIT } from '../../Constants/permissions'

const defaultView = {
  label: 'Default',
  value: ''
}

const HIDDEN_FIELDS = [
  'id',
  '_sync_inserted_at',
  '_sync_updated_at',
  'created_time',
  'createdon',
  'createdat'
]

function DatabaseViewerComponent () {
  const allTables = dataTables.map((table) => {
    return {
      label: table,
      value: table
    }
  })
  const [currentView, setCurrentView] = useState({})
  const [currentTable, setCurrentTable] = useState({})
  const [views, setViews] = useState([])
  const [showAddRecordModal, setShowAddRecordModal] = useState(false)
  const [showAddViewModal, setShowAddViewModal] = useState(false)
  const [refreshTable, setRefreshTable] = useState(false)
  const [metaData, setMetaData] = useState([])
  const [totalRows, setTotalRows] = useState(0)
  const [sortBy, setSortBy] = useState([])
  const [canUserEditDatabase, setCanUserEditDatabase] = useState(null)

  const history = useHistory()
  const { tableName, viewName } = useParams()

  const tableHeaders = currentView?.columnOrder || []

  useEffect(() => {
    const fetchEditPermission = async () => {
      const { permissions } = await api.getCurrentUserPermissions() || {}
      const containEditPermission = permissions.some(permission => permission === DATABASE_EDIT)
      setCanUserEditDatabase(containEditPermission)
    }

    fetchEditPermission()
  }, [])

  useEffect(() => {
    if (!tableName) {
      const lastVisitedTable =
        getDBViewerPreferences()?.lastVisitedTable || allTables[0].value
      const lastVisitedView =
        getDBViewerPreferences()?.[lastVisitedTable]?.lastVisitedView ||
        'Default'

      history.push(`/database-viewer/${lastVisitedTable}/${lastVisitedView}`)
      return
    }
    if (!viewName) {
      const lastVisitedView =
        getDBViewerPreferences()?.[tableName]?.lastVisitedView || 'Default'

      history.push(`/database-viewer/${tableName}/${lastVisitedView}`)
    }
  }, [allTables, history, tableName, viewName])

  useEffect(() => {
    if (!tableName) return
    if (!dataTables.includes(tableName)) history.push('/')

    const table = {
      label: tableName,
      value: tableName
    }
    setCurrentTable(table)

    setSortBy([])

    const getTableMetadata = async () => {
      const metaData = await api.getDataTableMetadata({
        tableName: tableName.toLowerCase()
      })
      const filteredMetaData = getFilteredMetaData(metaData, HIDDEN_FIELDS)
      const modifiedMetaData = getModifiedMetaData(filteredMetaData)
      setMetaData(modifiedMetaData)
    }
    getTableMetadata()
  }, [tableName, history])

  const getViews = async (fromCache) => {
    const views = await api.getTableView({
      tableName,
      fromCache
    })
    const viewsWithLabel = views?.map((view) => {
      return {
        label: view.name,
        value: view.name,
        ...view
      }
    })
    const viewsIncludingDefault = [defaultView, ...(viewsWithLabel || [])]
    setViews(viewsIncludingDefault)
  }

  useEffect(() => {
    if (!tableName) return
    getViews(true)
    // eslint-disable-next-line
  }, [tableName])

  useEffect(() => {
    if (!viewName || !views.length) return
    const selectedView =
        views.find((view) => view.label === viewName) ||
        defaultView

    setCurrentView(selectedView)
    saveDBViewerPreferences({
      [tableName]: { lastVisitedView: selectedView.label },
      lastVisitedTable: tableName
    })
  }, [viewName, views, tableName])

  const handleCloseModal = () => {
    setShowAddRecordModal(false)
    setRefreshTable(!refreshTable)
  }

  return (
    <>
      {showAddRecordModal && (
        <RecordModal
          tableName={tableName}
          metaData={metaData}
          handleClose={handleCloseModal}
          action='create'
          canUserEditDatabase={canUserEditDatabase}
          view={currentView}
        />
      )}
      {showAddViewModal && (
        <AddViewModal
          currentView={currentView}
          getViews={getViews}
          tableName={tableName}
          handleClose={() => setShowAddViewModal(false)}
        />
      )}
      <DatabaseViewerContainer>
        <DBViewerHeader
          allTables={allTables}
          currentTable={tableName}
          views={views}
          getViews={getViews}
          currentView={currentView}
          setCurrentView={setCurrentView}
          tableHeaders={tableHeaders}
          setShowAddRecordModal={setShowAddRecordModal}
          setShowAddViewModal={setShowAddViewModal}
          metaData={metaData}
          canUserEditDatabase={canUserEditDatabase}
        />
        {Object.keys(currentView).length > 0 && (
          <TableViewComponent
            refreshTable={refreshTable}
            tableName={currentTable.value}
            metaData={metaData}
            view={currentView}
            setView={setCurrentView}
            sortBy={sortBy}
            setSortBy={setSortBy}
            pageSize={25}
            totalRows={totalRows}
            setTotalRows={setTotalRows}
            tableHeaders={tableHeaders}
            canUserEditDatabase={canUserEditDatabase}
          />
        )}
      </DatabaseViewerContainer>
    </>
  )
}

export default DatabaseViewerComponent

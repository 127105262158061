import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormGroup, Dropdown, DropdownToggle, DropdownMenu,
  DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalComponent from '../ModalComponent/ModalComponent'
import './EditGradeModal.css'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button/Button'
import { isValidUrl } from '../../utilities/gradeUtils'

const changeReasons = [
  'Academic integrity issue',
  'Frontend bug or display error',
  'Answer format requirements were not clear',
  'Student error',
  'Regrading after further review',
  'Other'
]

const selectReason = 'Please select a reason for this change'

function EditAssignmentModal ({
  showModalHandler,
  show,
  section,
  studentProgress,
  handleEditAssignmentModal,
  isUpdating
}) {
  const [cohortMenuOpen, setCohortMenuOpen] = useState(false)
  const [notes, setNotes] = useState('')
  const [score, setScore] = useState('')
  const [feedback, setFeedback] = useState('')
  const [zendeskUrl, setzendeskUrl] = useState('')
  const [reason, setReason] = useState(selectReason)
  const [error, setError] = useState(false)
  const [scoreError, setScoreError] = useState(false)
  const [urlError, setUrlError] = useState(false)

  const { title, maxScore, chapter_uuid: chapterUuid } = section
  const assignmentProgress = studentProgress['assignment-progress'][chapterUuid]
  const assignmentGrade = assignmentProgress &&
      assignmentProgress.grade

  const toggleCohortMenu = () => setCohortMenuOpen(prevState => !prevState)

  const checkFields = () => {
    if (isUpdating) return
    if (notes === '' && reason === 'Other') return setError(true)

    const hasNoReasonAndScore = reason === selectReason || score === ''
    const hasNoFeedback = feedback === ''

    if (hasNoReasonAndScore || hasNoFeedback) return setError(true)

    setError(false)
    if (score > maxScore) return setScoreError(true)
    if (!isValidUrl(zendeskUrl)) return setUrlError(true)

    const editData = {
      assignmentUuid: section.chapter_uuid,
      to: parseFloat((parseFloat(score) / maxScore * 100).toFixed(2)),
      from: assignmentGrade || 0,
      reason,
      feedback: feedback
    }

    if (notes !== '') editData.note = notes
    if (zendeskUrl !== '') {
      if (!isValidUrl(zendeskUrl)) return setUrlError(true)
      editData.zendeskUrl = zendeskUrl
    }

    handleEditAssignmentModal(editData)
  }

  return (
    <ModalComponent show={show} handleClose={() => showModalHandler()}>
      <div className='edit-grade-wrapper'>
        <span>
          {title} Modification
        </span>
        <span className='section-title'>
          Earned Grade
        </span>
        <div className={classNames({
          questionStatus: true,
          correct: true
        })}>The current recorded score is
          <div> {assignmentGrade || 0}</div>%
        </div>

        <span className={classNames({
          'section-title': true,
          sectionError: error && reason === selectReason
        })}>
          Change Reason
        </span>
        {error && reason === selectReason && <div className='text-error'>
          Select a reason for the change</div>}
        <FormGroup className='form-group'>
          <div>
            <Dropdown
              className='dropdown'
              isOpen={cohortMenuOpen}
              toggle={toggleCohortMenu}
            >
              <DropdownToggle
                style={{ ...DropdownStyle,
                  borderColor: error && reason === selectReason ? '#E1774F' : '#77B1AF' }}>
                {reason}
                <FontAwesomeIcon icon={faCaretDown} color={'#77B1AF'} />
              </DropdownToggle>

              <DropdownMenu style={{ width: '100%' }}>
                {changeReasons.map((reason, index) =>
                  (
                    <DropdownItem key={index} onClick={() => { setReason(reason) }}>
                      {reason}
                    </DropdownItem>
                  ))}

              </DropdownMenu>
            </Dropdown>
          </div>
        </FormGroup>
        <span className='section-title'>
          Score (Please enter a number between 0 and {maxScore})
        </span>
        {((error && (score === '')) || scoreError) && <div className='text-error'>Please enter a number between 0 and {maxScore}</div>}
        <input type='number' className={((error && (score === '')) || scoreError) ? 'text-area-error' : 'text-area'}
          value={score} onChange={(e) => { setScore(e.target.value) }} />
        <span className='section-title'>
            Feedback
        </span>
        {error && (feedback === '') && <div className='text-error'>Feedback field is required</div>}
        <textarea className={error && (feedback === '') ? 'text-area-error' : 'text-area'}
          value={feedback} onChange={(e) => { setFeedback(e.target.value) }} />
        <span className='section-title'>
            Notes
        </span>
        {error && (notes === '' && reason === 'Other') && <div className='text-error'>Add a note about the change</div>}
        <textarea className={error && (notes === '' && reason === 'Other') ? 'text-area-error' : 'text-area'}
          value={notes} onChange={(e) => { setNotes(e.target.value) }} />
        <span className='section-title'>
          Zendesk URL
        </span>
        {error && zendeskUrl === '' && <div className='text-error'>Add a Zendesk Ticket URL</div>}
        {!error && urlError && <div className='text-error'>Add a Valid Zendesk Ticket URL</div>}
        <input className={(error && zendeskUrl === '') || urlError ? 'text-area-error' : 'text-area'}
          value={zendeskUrl} onChange={(e) => { setzendeskUrl(e.target.value) }} />
      </div>

      <div className='buttons'>
        <Button className='btn-custom btn-secondary button' onClick={() => showModalHandler()}>CANCEL</Button>
        <Button className='btn-custom btn-primary button' onClick={() => checkFields()}>{isUpdating ? <i className='fa fa-spinner fa-spin' /> : 'SAVE'}</Button>
      </div>
    </ModalComponent>
  )
}

const DropdownStyle = {
  width: '100%',
  border: '1px solid',
  background: '#1D1D1F',
  boxSizing: 'border-box',
  borderRadius: '5px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  lineHeight: '19px',
  marginBottom: '12px'
}

EditAssignmentModal.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired
}

export default EditAssignmentModal

import React, { useEffect, useState } from 'react'
import StudentExtensionDetails from './StudentExtensionDetails'
import StudentExtensionReview from './StudentExtensionReview'
import { Container, Header, Separator } from '../style'
import {
  IndicatorsContainer,
  ReviewExtensionIndicator,
  StudentExtensionIndicator,
  HeaderContainer
} from './style'
import GoToPreviousPageButton
from '../../GoToPreviousPageButton/GoToPreviousPageButton'
import {
  getAssessmentsToExtend,
  getSingleAssessmentToExtend
} from './AddExtensionUtils'
import api from '../../../api'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import { isCourseWithoutFinalExam } from '../../../utilities/courseUtils'
import { EXAM } from '../../../Constants/chapter'

const STUDENT_EXTENSION_DETAILS = 'student-extension-details'
const STUDENT_EXTENSION_REVIEW = 'student-extension-review'

const AddExtension = ({ routerProps: { history } }) => {
  const [step, setStep] = useState(STUDENT_EXTENSION_DETAILS)
  const [isOneAssessmentMode, setIsOneAssessmentMode] = useState(null)
  const [extensionReviewData, setExtensionReviewData] = useState(null)
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [extensionToEdit, setExtensionToEdit] = useState(null)

  useEffect(() => {
    const editedExtension = history?.location?.state?.extensionToEdit
    if (editedExtension) {
      setIsEditMode(true)
      setExtensionToEdit(editedExtension)
    }
    // eslint-disable-next-line
  }, [])

  const handleNextStep = (isOneAssessmentMode, extensionReviewData) => {
    setIsOneAssessmentMode(isOneAssessmentMode)
    setExtensionReviewData(extensionReviewData)
    setStep(STUDENT_EXTENSION_REVIEW)
  }
  const handlePreviousStep = () => {
    setStep(STUDENT_EXTENSION_DETAILS)
  }
  const goBack = () => {
    if (step === STUDENT_EXTENSION_DETAILS) {
      history.push({ pathname: '/assessment-extensions' })
    } else {
      handlePreviousStep()
    }
  }

  const addAssessmentsExtension = async () => {
    if (isOneAssessmentMode === null) return
    const {
      studentData: { id: studentId },
      selectedCohort: { id: cohortId },
      selectedCourse,
      assessmentsList,
      newDeadlineDate,
      selectedNumber,
      selectedUnitLength,
      attemptID: attemptId,
      zendeskLink,
      extensionReason
    } = extensionReviewData
    let assessmentsToExtend
    const hasFinalExam = !isCourseWithoutFinalExam(selectedCourse?.id)
    const courseData = await api.getCourseData(selectedCourse?.id)
    const numberOfExams = courseData?.chapters?.filter(chapter => chapter.type === EXAM)?.length
    if (isOneAssessmentMode) {
      const { selectedAssessment } = extensionReviewData
      assessmentsToExtend = getSingleAssessmentToExtend({
        assessment: selectedAssessment, newDeadlineDate, numberOfExams, hasFinalExam
      })
    } else {
      assessmentsToExtend = getAssessmentsToExtend({
        hasFinalExam,
        assessments: assessmentsList,
        selectedNumber,
        selectedUnitLength,
        numberOfExams
      })
    }

    const extensionData = {
      studentId,
      cohortId,
      attemptId,
      ...assessmentsToExtend,
      zendeskUrl: zendeskLink,
      ...(extensionReason !== '' && { extensionReason })
    }
    setShowLoadingSpinner(true)
    await api.addAssessmentExtensions(extensionData)
    setShowLoadingSpinner(false)
    history.push({
      pathname: '/assessment-extensions',
      state: { extensionToastMessage: 'created' }
    })
  }

  const updateAssessmentExtension = async () => {
    const {
      extensionId,
      newDeadlineDate,
      zendeskLink,
      extensionReason,
      selectedAssessment
    } = extensionReviewData
    const numberOfExams = extensionToEdit?.exams?.length
    const hasFinalExam = !isCourseWithoutFinalExam(extensionToEdit?.courseId)
    const assessmentsToExtend =
        getSingleAssessmentToExtend({
          assessment: { ...selectedAssessment, examNumber: extensionToEdit?.examNumber },
          hasFinalExam,
          newDeadlineDate,
          numberOfExams
        })
    const extensionData = { extensions: [{
      extensionId,
      ...assessmentsToExtend,
      zendeskUrl: zendeskLink,
      ...(extensionReason !== '' && { extensionReason })
    }] }

    setShowLoadingSpinner(true)
    await api.putAssessmentExtension(extensionData)
    setShowLoadingSpinner(false)
    history.push({
      pathname: '/assessment-extensions',
      state: { extensionToastMessage: 'updated' }
    })
  }

  const handleConfirmation = async () => {
    if (isEditMode) {
      updateAssessmentExtension()
      return
    }
    if (isOneAssessmentMode === null) return
    addAssessmentsExtension()
  }
  const renderStep = () =>
    ({
      [STUDENT_EXTENSION_DETAILS]: (
        <StudentExtensionDetails
          isOneAssessmentModeReviewData={isOneAssessmentMode}
          extensionReviewData={extensionReviewData}
          extensionToEdit={extensionToEdit}
          isEditMode={isEditMode}
          handleNextStep={handleNextStep}
          goBack={goBack}
        />
      ),
      [STUDENT_EXTENSION_REVIEW]: (
        <StudentExtensionReview
          isOneAssessmentMode={isOneAssessmentMode}
          extensionReviewData={extensionReviewData}
          handlePreviousStep={handlePreviousStep}
          handleConfirmation={handleConfirmation}
          isEditMode={isEditMode}
        />
      )
    }[step])

  return (
    <Container>
      <HeaderContainer>
        <GoToPreviousPageButton handleClick={goBack} text={'back'} />
        <Header>
          {!isEditMode ? 'Add an extension' : 'Edit extension'}
        </Header>
      </HeaderContainer>

      <Separator />

      <IndicatorsContainer>
        <StudentExtensionIndicator
          {...(step === STUDENT_EXTENSION_REVIEW &&
            { onClick: () => handlePreviousStep() })}
          active={step === STUDENT_EXTENSION_DETAILS}>
          <span>1</span> Student and extension details
        </StudentExtensionIndicator>
        <ReviewExtensionIndicator active={step === STUDENT_EXTENSION_REVIEW}>
          <span>2</span> Review
        </ReviewExtensionIndicator>
      </IndicatorsContainer>
      {renderStep()}
      {showLoadingSpinner && <LoadingSpinner />}
    </Container>
  )
}

AddExtension.displayName = 'AddExtension'
export default AddExtension

import styled from 'styled-components'
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg'

export const StyledWrapper = styled.div`
  margin-top: ${props => props.noMargin ? '0px' : '25px'};
  margin-bottom: ${props => props.noMargin ? '0px' : '25px'};
`

export const StyledRadioButton = styled.label`
  display: block;
  width: max-content;
  position: relative;
  padding-left: 36px;
  margin-top: ${props => props.marginTop || '0px'};
  margin-bottom: ${props => props.marginBottom || '24px'};
  cursor: pointer;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  ${props => props.disabled && `
    opacity: 0.5;
    cursor: not-allowed;
  `}
  
  &.preset-item {
    font-size: 16px;
    line-height: 19px;
    margin-top: 0;
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    &:disabled {
      cursor: not-allowed;
    }
  }

  span {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 20px;
    width: 20px;
    background-color: #161618;
    border: 2px solid #5FC4B8;
    box-sizing: border-box;
    border-radius: 100%;
  }

  input:checked ~ span {
    background-color: #5FC4B8;
    border: 3px solid #5FC4B8;
    box-sizing: border-box;
    border-radius: 100%;
  }
  
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`

export const Info = styled(InfoIcon)`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`

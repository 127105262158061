import styled from 'styled-components'
import mediaqueries from '../../../mediaQueries'
import { DropdownMenu } from 'reactstrap'
import { FormInput } from '../styles'

export const dropDownStyles = {
  indicatorSeparator: () => ({ display: 'none' }),
  control: (base, state) => ({
    ...base,
    width: state.selectProps.width || '200px',
    height: '56px',
    background: 'var(--black-tertiary)',
    color: 'var(--text-primary)',
    border: '1px solid var(--border-secondary)',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    '&:hover': {
      border: '1px solid var(--brand-regular)'
    },
    '&:focus-within': {
      borderColor: 'var(--white)'
    }
  }),
  menu: (base) => ({
    ...base,
    width: '350px',
    maxWidth: '350px',
    height: 'auto',
    zIndex: 1000,
    background: '#25272b',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    marginTop: '12px',
    isolation: 'isolate'
  }),
  menuList: (base) => ({
    ...base,
    width: '100%',
    height: '100%',
    padding: '8px 0',
    borderRadius: '5px'
  }),
  option: (base, state) => ({
    ...base,
    padding: '8px 15px',
    width: '100%',
    backgroundColor: '#25272B',
    color: state.isSelected ? 'var(--white)' : 'var(--sub-text-secondary)',
    cursor: 'pointer',
    fontSize: '16px',
    lineHeight: 'normal',
    border: '1px solid transparent',
    '&:hover': {
      border: '1px solid var(--brand-regular)',
      borderRadius: '5px'
    },
    '&:active': {
      background: '#25272b'
    }
  }),
  placeholder: (base) => ({
    ...base,
    color: 'var(--border-secondary)',
    marginLeft: '5px',
    fontSize: '16px',
    lineHeight: '22px'
  }),
  singleValue: (base) => ({
    ...base,
    color: 'var(--white)',
    paddingLeft: '6px',
    margin: 0,
    fontSize: '16px',
    lineHeight: '22px'
  }),
  multiValue: () => ({
    display: 'none'
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: 'var(--white)',
    padding: '0 8px 0 0',
    '&:hover': {
      color: 'var(--white)'
    }
  }),
  indicatorContainer: (base) => ({
    ...base,
    paddingLeft: 0
  }),
  input: (base) => ({
    ...base,
    color: 'var(--text-primary)',
    height: 'auto'
  })
}

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  align-items: end;
`
export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const Button = styled.button`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  display: flex;
  height: 56px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  background-color: var(--brand-dark);
  color: var(--brand-regular);

  &:hover {
    background-color: #214b52;
    color: var(--brand-regular-hover);
  }

  :active {
    opacity: 0.3;
  }

  :focus {
    outline: none;
  }
  :focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
`
export const Separator = styled.hr`
  margin-top: 24px;
  margin-bottom: 16px;
  ${mediaqueries.mobile`
    margin-bottom: 48px;
  `}
  height: 0;
  width: 100%;
  border: 0.5px solid var(--border-secondary);
`
export const ViewName = styled.p`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 24px;
  font-weight: 300;
  cursor: pointer;
  width: fit-content;
  margin-bottom: 0;
  & > svg {
    ${(props) => props.isDropdownOpen && `transform: rotate(180deg);`}
  }
`
export const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 16px 16px 0;
  letter-spacing: 1px;
  line-height: 16px;
  & > span {
    color: var(--text-dark-off);
  }
`
export const ButtonAsLink = styled.button`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  padding: 0;
  color: var(--brand-regular);
  &:hover {
    color: var(--brand-regular-hover);
  }
  &:active {
    opacity: 0.3;
  }
  &:focus {
    outline: none;
  }
  &:focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &:disabled:hover {
    color: var(--brand-regular);
  }
`
export const ModalContents = styled.div`
  padding: 24px;
  background: var(--black-secondary);
`
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ButtonContainer = styled.div`
  display: flex;
  padding: 16px 24px 36px;
  background: var(--black-secondary);
`
export const InputHint = styled.p`
  color: var(--text-dark-off);
  font-style: italic;
  margin: 0;
  margin-top: 8px;
`
export const ModalWrapper = styled.div`
  & h2:first-of-type {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`

export const HideFieldsDropdown = styled(DropdownMenu)`
  width: 350px;
  margin-top: 12px;
  padding-top: 0;
  padding-bottom: 0;
  &.dropdown-menu.show {
    display: flex;
    flex-direction: column;
  }
`
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;

  & > .dot {
    color: var(--text-dark-off);
  }
`
export const SearchInputContainer = styled.div`
  position: relative;
  display: flex;
  & > svg {
    position: absolute;
    right: 32px;
    top: 44%;
  }
`
export const SearchInput = styled(FormInput)`
  height: 40px;
  padding: 8px 16px;
  margin: 16px 16px 8px;
  &::placeholder {
    color: var(--border-secondary);
  }
`
export const FieldsContainer = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-bottom-left-radius: 5px;
`
export const Field = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 15px;
  border: 1px solid transparent;
  background-color: #25272b;

  &:last-of-type {
    margin-bottom: 8px;
  }

  & > span {
      color: var(--white);
      line-height: normal;
      max-width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ${(props) => props.columnHidden && `color: var(--text-dark-off);`}
  }

  &:hover > span {
      overflow: visible;
      white-space: normal;
      word-wrap: break-word;
  }
  &:hover {
    border: 1px solid var(--brand-regular);
    border-radius: 5px;
  }
`
export const HideUnhideButton = styled.div`
  cursor: pointer;
  color: var(--white);
  margin-left: auto;
`
export const ReorderButton = styled.span`
  display: flex;
  color: var(--text-dark-off);
  cursor: grab;
  flex-shrink: 0
`
export const InputError = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ff8064;
  font-size: 16px;
  margin-bottom: 0.5rem;
`
export const ModalBody = styled.div`
  margin: 24px 24px 0;
  background: var(--black-secondary);
  max-height: calc(100% - 268px);
  ${props => props.hasOptions && 'overflow: auto;'}
`
export const ModalFooter = styled.div`
  padding: 0 24px 36px;
  background: var(--black-secondary);
`
export const NoFiltersMessage = styled.span`
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
`

export const AddConditionButton = styled(ButtonAsLink)`
  padding: 32px 24px;
  width: fit-content;
`
export const FiltersWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  margin-bottom: 24px;
`
export const DiscardModalBody = styled.div`
  & > p {
    margin: 0;
    margin-bottom: 36px;
    font-size: 18px;
  }
  padding: 24px;
`
export const SubHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 16px;
`
export const SubHeaderDropdown = styled(DropdownMenu)`
  width: 250px;
  margin-top: 8px;
  padding: 8px 0;
  background-color: #25272b;
  border-radius: 5px;
`
export const SubHeaderDropdownItem = styled(ButtonAsLink)`
  justify-content: flex-start;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  gap: 16px;
  padding: 8px 15px;
  border: 1px solid transparent;
  background-color: #25272b;
  color: var(--sub-text-secondary);
  line-height: normal;
  cursor: pointer;
  text-transform: none;

  &:hover {
    & > span {
      color: var(--white);
    }
    & > svg {
      color: var(--sub-text-secondary);
    }
    border: 1px solid var(--brand-regular);
    border-radius: 5px;
  }
  &:disabled:hover {
    border: 1px solid transparent;
    & > span {
      color: var(--sub-text-secondary);
    }
  }
`
export const DropdownGroupSeparator = styled.hr`
  margin-top: 10px;
  margin-bottom: 10px;
  height: 0;
  border: 0.5px solid #3c3d40;
`
export const AddRecordButton = styled(Button)`
  height: auto;
  padding: 8px;
  gap: 6px;
`
export const RenameViewContainer = styled.form`
  display: flex;
  gap: 12px;
  align-items: flex-end;
  & > input,
  & > input:hover,
  & > input:focus {
    color: var(--white);
  }
  & button {
    height: 56px;
  }
`
export const DeletedViewToastContainer = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    color: var(--sub-text-secondary);
  }
  & > span {
    margin-left: 8px;
    font-weight: 700;
    color: var(--white);
  }
`
export const RenameInputContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const ViewNameInput = styled(FormInput)`
  color: var(--white);
  &:focus {
    color: var(--white);
  }
  &:hover {
    color: var(--white);
  }
`

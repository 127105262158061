import React, { useEffect, useRef, useState } from 'react'
import api from '../../api'
import {
  PageWrapper,
  PageTitle,
  HorizontalLine
} from './index.style'
import Toast from '../../Components/ToastComponent/Toast'
import SelectionPage from './SelectionPage'
import ReviewPage from './ReviewPage'
import {
  getFilterCourses,
  getFilterInstitutions,
  getFilterRelationships,
  getFilterStatuses
} from './utils'
import { useCoursesActions, useCoursesContext } from '../../contexts/Courses'
import {
  PAGES,
  initialFiltersData,
  initialSelectedData
} from './utils/constants'
import { sortBy } from 'lodash'

function EnrollmentManagement () {
  const pageRef = useRef(null)
  const toastRef = useRef(null)
  const [currentPage, setCurrentPage] = useState(PAGES.selection)

  const [filtersData, setFiltersData] = useState(initialFiltersData)
  const [selectedData, setSelectedData] = useState(initialSelectedData)

  const { fetchCourses } = useCoursesActions()
  const { allCourses } = useCoursesContext()

  useEffect(() => {
    const fetchFiltersData = async () => {
      if (!allCourses?.length) fetchCourses()

      const studentStatuses = await api.getStudentStatuses()
      const orderedStatuses = sortBy(getFilterStatuses(studentStatuses), 'name')

      setFiltersData(prev => ({
        ...prev,
        statuses: orderedStatuses
      }))
      setSelectedData(prev => ({
        ...prev,
        statuses: orderedStatuses
      }))

      const relationships = await api.getStudentRelationships()
      setFiltersData(prev => ({
        ...prev,
        relationships: getFilterRelationships(relationships)
      }))
    }

    fetchFiltersData()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!allCourses?.length) return
    const courses = getFilterCourses(allCourses)
    const institutions = getFilterInstitutions(allCourses)

    setFiltersData(prev => ({
      ...prev, allCourses: courses, institutions
    }))
  }, [allCourses])

  useEffect(() => {
    if (!pageRef?.current) return
    pageRef.current.scrollIntoView()
  }, [currentPage])

  const showSelectionPage = currentPage === PAGES.selection
  const showReviewPage = currentPage === PAGES.review

  return (
    <PageWrapper ref={pageRef}>
      <Toast toast={toastRef} />

      <PageTitle>
        Enrollment management
      </PageTitle>
      <HorizontalLine />

      {showSelectionPage &&
      <SelectionPage
        filtersData={filtersData}
        setFiltersData={setFiltersData}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        setCurrentPage={setCurrentPage}
      />
      }

      {showReviewPage && (
        <ReviewPage
          toastRef={toastRef}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setCurrentPage={setCurrentPage}
          studentStatuses={filtersData.statuses}
        />
      )}
    </PageWrapper>
  )
}

EnrollmentManagement.displayName = 'EnrollmentManagement'
export default EnrollmentManagement

import styled from 'styled-components'
import iconRightMark from '../../../assets/icons/icon-right-mark.svg'
import { Input } from 'reactstrap'

export const ActivityPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  background-color: var(--black-secondary);
  border-left: 1px solid var(--border-secondary);
`

export const ActivitySelectStyles = {
  indicatorSeparator: styles => ({ display: 'none' }),
  container: base => ({
    ...base,
    width: 'fit-content'
  }),
  control: (base, state) => ({
    ...base,
    width: '100%',
    background: 'var(--black-secondary)',
    border: 'none',
    color: 'white',
    boxSizing: 'border-box',
    boxShadow: 'none',
    cursor: 'pointer',
    padding: '16px 12px',
    gap: '12px'
  }),
  menu: base => ({
    ...base,
    width: '180px',
    zIndex: 1000,
    background: 'var(--canvas-dark)',
    border: '1px solid var(--border-primary)',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px var(--shadow-primary)',
    borderRadius: '5px',
    marginTop: '0px',
    transform: 'translateX(43px)'
  }),
  menuList: base => ({
    ...base,
    width: '100%',
    height: '100%',
    padding: 0
  }),
  option: (base, state) => ({
    ...base,
    width: '100%',
    position: 'relative',
    backgroundColor: 'var(--gray-primary)',
    cursor: 'pointer',
    fontSize: '18px',
    lineHeight: '22px',
    '&:hover': {
      border: '1px solid var(--brand-regular)'
    },
    '&:after': state.isSelected ? {
      width: '16px',
      content: `url(${iconRightMark})`,
      display: 'inline-block',
      position: 'absolute',
      right: '16px'
    } : { display: 'none' }
  }),
  singleValue: base => ({
    ...base,
    color: 'var(--text-secondary)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '16px',
    letterSpacing: '1px',
    textTransform: 'uppercase',
    overflow: 'visible',
    position: 'static',
    transform: 'none',
    margin: '0'
  }),
  dropdownIndicator: base => ({
    ...base,
    color: 'var(--white)',
    padding: '0 8px 0 0',
    '&:hover': {
      color: 'var(--white)'
    }
  }),
  indicatorContainer: base => ({
    ...base,
    paddingLeft: 0
  })
}

export const Separator = styled.hr`
  margin: 0;
  height: 1px;
  border-color: var(--border-secondary);
`

export const ActivitiesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: ${props => props.isTextAreaFocused ? '293px' : '397px'};
  overflow: auto;
  padding: 16px 12px;
  margin-right: 2px;
  gap: 16px;

  & {

    &::-webkit-scrollbar {
      width: 8px !important;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--border-secondary);
      border-radius: 10px;
      visibility: visible;
    }
  }
`

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-top: 1px solid var(--border-secondary);

`

export const CommentInput = styled(Input)`
  width: 218px;
  height: 24px;
  min-height: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #5B6067;
  background-color: #161618;
  border-radius: 5px;
  border: 1px solid #5B6067;
  overflow: hidden;

  &:focus {
    border: 1px solid #FFFFFF;
    background-color: var(--input-primary);
    color: var(--text-primary);
    box-shadow: none;
  }

`

export const ActivityItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActivityItemEditMessage = styled.span`
  color: var(--sub-text-secondary);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const ActivityItemDate = styled.span`
  color: var(--text-dark-off);
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 16px;
`

export const ActivityContentList = styled.span`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
  padding: 8px;
  background: #232327;
  border-radius: 5px;
`

export const ActivityContentItem = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActivityFieldName = styled.span`
  color: var(--sub-text-secondary);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
`

export const ActivityFieldChange = styled.span`
  color: #FFFFFF;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`

export const CommentButtonContainer = styled.div`
 display: ${props => props.isTextAreaFocused ? 'flex' : 'none'};
  gap: 12px;
  padding-top: 16px;
`

export const CommentButton = styled.button`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 5px;
  background: #0A2A2D;
  color: var(--brand-regular);
  cursor: pointer;
  text-transform: uppercase;
  min-width: 100px;
  border: none;

  &:not(:disabled):hover {
    background-color: #214b52;
    color: var(--brand-regular-hover);
  }

  :active {
    opacity: 0.3;
  }

  :focus {
    outline: none;
  }
  :focus-visible {
    outline: 5px auto -webkit-focus-ring-color;
  }

  &:disabled {
    background-color: var(--brand-dark-disabled);
    color: #2c4a48;
    cursor: default;
  }
`

export const CommentCancelButton = styled.span`
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 5px;
  color: var(--brand-regular);
  cursor: pointer;
  text-transform: uppercase;
`

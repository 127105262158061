import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { multiTextInputStyles } from './styles'

const createOption = (label) => ({
  label,
  value: label
})

const components = {
  DropdownIndicator: null,
  ClearIndicator: null
}

const MultiTextInput = (props) => {
  const {
    emailFilteredStudents,
    setEmailFilteredStudents
  } = props
  const [inputValue, setInputValue] = useState()

  const handleChange = (newValue) => {
    setEmailFilteredStudents(!newValue ? [] : newValue)
  }

  const addNewEmailToInput = () => {
    const tempValues = [...emailFilteredStudents]
    tempValues.push(createOption(inputValue.trim()))
    setEmailFilteredStudents(tempValues)
  }

  const handleBlur = () => {
    if (!inputValue) return
    addNewEmailToInput()
  }

  const handleKeyDown = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setEmailFilteredStudents(addNewEmailToInput)
        setInputValue('')
        break
      default:
        break
    }
  }

  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={(val) => { setInputValue(val) }}
      onKeyDown={(e) => handleKeyDown(e)}
      placeholder
      value={emailFilteredStudents}
      styles={multiTextInputStyles}
      onBlur={handleBlur}
    />
  )
}

export default MultiTextInput

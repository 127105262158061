import {
  NoCertDegree,
  StudentCertDegreeContainer,
  StudentCertDegreeName,
  StudentCertDegreeNumber,
  StudentCertDegreeRow,
  StudentCertDegreeText,
  StudentCertHeader,
  StudentCertStatus
} from './style'
import React, { useEffect, useState } from 'react'
import api from '../../../api'
import { getCertificateStatus } from './studentRecordDetailsUtils'

const StudentCertificates = ({ studentEmail }) => {
  const [certificatesList, setCertificatesList] = useState([])

  useEffect(() => {
    fetchStudentCertificates(studentEmail)
    // eslint-disable-next-line
  }, [])

  const fetchStudentCertificates = async (studentEmail) => {
    const studentCertificates = await api.getStudentCertificates(studentEmail)
    if (!studentCertificates?.length) return
    const studentPurchasedCertificates =
      studentCertificates.filter((certificate) => certificate.isPurchased)
    const certificatesWithStatus =
      studentPurchasedCertificates.map((certificate) => {
        return getCertificateStatus(certificate)
      })
    setCertificatesList(certificatesWithStatus)
  }
  const numberOfCertificates = certificatesList.length
  const certificateText = numberOfCertificates === 1
    ? 'Certificate' : 'Certificates'

  return (
    <StudentCertDegreeContainer>
      <StudentCertHeader>
        <StudentCertDegreeNumber>{numberOfCertificates}</StudentCertDegreeNumber>
        <StudentCertDegreeText>{certificateText}</StudentCertDegreeText>
      </StudentCertHeader>
      {
        numberOfCertificates === 0
          ? <NoCertDegree>
            This student has no certificates.
          </NoCertDegree>
          : certificatesList.map((certificate, index) => {
            const { certificateName, status } = certificate
            const isCompleted = status === 'Completed'
            return (
              <StudentCertDegreeRow key={index}>
                <StudentCertDegreeName isCompleted={isCompleted}>
                  {certificateName}
                </StudentCertDegreeName>
                <StudentCertStatus isCompleted={isCompleted}>
                  {status}
                </StudentCertStatus>
              </StudentCertDegreeRow>
            )
          }
          )
      }
    </StudentCertDegreeContainer>
  )
}
StudentCertificates.displayName = 'StudentCertificates'
export default StudentCertificates

import React, { useEffect, useState } from 'react'
import {
  Card,
  CardContainer,
  CardDescription,
  CardIcon,
  CardTitle,
  Container,
  Header,
  Separator
} from './styles'
import { Link } from 'react-router-dom'
import { NavLink } from 'reactstrap'
import chevronRight from '../../assets/icons/chevron-right.svg'
import api from '../../api'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { hasAccessToLink } from '../../utilities/userUtils'
import { cards, PARTNERSHIPS_MANAGER_CARDS } from '../../utilities/DashboardCards'
import { PARTNERSHIPS_MANAGER } from '../../Constants/roles'

function DashBoardTools () {
  const [loading, setLoading] = useState(true)
  const [permissions, setPermissions] = useState([])
  const [tools, setTools] = useState(cards)
  const [roles, setRoles] = useState([])

  useEffect(() => {
    checkPermissions()
  }, [])

  useEffect(() => {
    if (!cards?.length || !roles?.length) return
    const isPermissionsManagerRole = roles?.length > 0 &&
      roles?.every(item => item === PARTNERSHIPS_MANAGER)
    const cardsList = isPermissionsManagerRole
      ? PARTNERSHIPS_MANAGER_CARDS
      : cards
    const getCardContent = async () => {
      const menuItems = await Promise.all(
        cardsList.map(async card => {
          const { loadContentFunction, getCount } = card || {}
          if (!loadContentFunction) return card

          const refundRequests = await loadContentFunction()
          const count = getCount(refundRequests)
          return { ...card, count, content: refundRequests }
        })
      )
      setTools(menuItems)
    }

    getCardContent()
    // eslint-disable-next-line
  }, [cards, roles])

  const checkPermissions = async () => {
    try {
      const { permissions, roles } = await api.getCurrentUserPermissions() || {}
      setPermissions(permissions)
      setRoles(roles)
      setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  if (loading) {
    return (
      <div>
        <div className='layout-wrapper'>
          <div className='content'>
            <LoadingSpinner id='loading-spinner' />
          </div>
        </div>
      </div>
    )
  }

  return (
    <Container>
      <Header>Tools</Header>
      <Separator />
      <div className='d-flex flex-wrap'>
        {tools.map((card, index) => {
          const {
            to, name, icon, description, count, content, loadContentFunction
          } = card || {}
          const showSpinner = loadContentFunction && typeof count === 'undefined'
          return hasAccessToLink(card, permissions, roles) && (
            <CardContainer key={index} className='col-12 col-sm-6 col-md-6 col-lg-4'>
              <NavLink
                tag={Link}
                to={{
                  pathname: to,
                  ...(loadContentFunction ? { content } : {})
                }}
                style={{ padding: 0 }}
              >
                <Card>
                  <CardIcon src={icon} alt={name} />
                  <CardTitle>
                    {name}{`${showSpinner ? ' ' : ''}`}
                    <span>
                      {showSpinner
                        ? <i className='fa fa-spinner fa-spin' />
                        : count > 0 ? ' (' + count + ')' : ''}
                    </span>
                    <img src={chevronRight} alt='chevron-right' />
                  </CardTitle>
                  <CardDescription>{description}</CardDescription>
                </Card>
              </NavLink>
            </CardContainer>
          )
        })}
      </div>
    </Container>
  )
}

export default DashBoardTools

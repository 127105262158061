import React, { useEffect, useState } from 'react'
import {
  CustomButton,
  VoucherListContainer,
  Header,
  Cell,
  LoaderContainer,
  Table
} from './styled'
import PropTypes from 'prop-types'
import {
  InfoContainer,
  Separator
} from '../styled'
import StudentDetails from '../StudentDetails'
import { useHistory } from 'react-router-dom'
import api from '../../../api'
import { getAmazonVoucherEvent, getVoucherDetails } from '../utils'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import { OUTSTANDING_STATUS } from '../VoucherLevel/VoucherLevel'
import { AMAZON_STUDENT_DROPPED_EVENT } from '../../../Constants/voucher'

const HEADERS = [
  {
    label: 'Certificate or Course Name',
    key: 'name'
  },
  {
    label: 'Drop Deadline',
    key: 'dropDeadline'
  },
  {
    label: 'Voucher Status',
    key: 'voucherStatus'
  },
  {
    label: 'Drop Request Sent?',
    key: 'dropNotificationDate'
  },
  {
    label: 'action',
    key: 'button'
  },
  {
    label: 'drop',
    key: 'dropButton'
  }
]

const StudentLevel = ({ studentId }) => {
  const history = useHistory()
  const [studentDetails, setStudentDetails] = useState({})
  const [list, setList] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [voucherUpdated, setVoucherUpdated] = useState()
  const [apiCallInProgress, setApiCallInProgress] = useState(false)

  const fetchVouchers = async () => {
    const data = await api.fetchStudentVouchers(studentId)
    if (data) {
      const { student, vouchers } = data
      setStudentDetails(formatStudentData(student))
      setList(formatData(vouchers))
      return setLoading(false)
    }
    return history.push({
      pathname: '/voucher'
    })
  }

  const formatStudentData = ({ email, id, name }) => ({
    studentId: id,
    studentName: name,
    studentEmail: email
  })

  useEffect(() => {
    fetchVouchers()
    // eslint-disable-next-line
  }, []);

  const formatData = (data) => data.map((node) => {
    const { certificateName, courseName, ...rest } = { ...getVoucherDetails(node) }
    const isDropStudent = rest.voucherStatus === OUTSTANDING_STATUS
    return {
      ...rest,
      name: certificateName || courseName,
      ...(isDropStudent && { dropButton: getButtonProps(node, true) }),
      button: getButtonProps(node)
    }
  })

  const navigateToVoucherPage = voucherId => {
    history.push({
      pathname: `/voucher/${studentId}/${voucherId}`
    })
  }

  const sendDropNotification = async (voucherId) => {
    setVoucherUpdated(voucherId)
    setApiCallInProgress(true)
    const res = await api.sendDropNotification(voucherId)
    setApiCallInProgress(false)
    if (!res) {
      return alert('something went wrong!')
    }
    alert('notification sent successfully!')
    api.submitTrackedEvent(
      getAmazonVoucherEvent(AMAZON_STUDENT_DROPPED_EVENT, studentId)
    )
  }

  const getButtonProps = ({ voucherId }, isDrop) => {
    return (
      <CustomButton
        onClick={() =>
          isDrop
            ? sendDropNotification(voucherId)
            : navigateToVoucherPage(voucherId)
        }
        className='btn-custom btn-primary'
      >
        {!isDrop ? 'View' : 'Drop Student'}
      </CustomButton>
    )
  }

  if (isLoading) {
    return (
      <LoaderContainer>
        <LoadingSpinner position='relative' />
      </LoaderContainer>
    )
  }

  return (
    <>
      <InfoContainer>
        <StudentDetails {...studentDetails} />
      </InfoContainer>
      <Separator />
      <VoucherListContainer>
        <Table>
          <thead>
            <tr>
              {HEADERS.map(({ label, key }) => (
                <Header key={key} label={label}>
                  {label}
                </Header>
              ))}
            </tr>
          </thead>
          <tbody>
            {list.map(({ voucherId, ...node }) => {
              return (
                <tr key={voucherId}>
                  {HEADERS.map(({ key }) => {
                    const isVoucherUpdated =
                    key === 'dropButton' &&
                    apiCallInProgress &&
                    voucherUpdated === voucherId
                    return (
                      <Cell
                        isLoading={isVoucherUpdated}
                        className='cell'
                        keyName={key}
                        key={key}
                      >
                        {isVoucherUpdated ? (
                          <i className='fa fa-spinner fa-spin' />
                        ) : (
                          node[key]
                        )}
                      </Cell>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
      </VoucherListContainer>
    </>
  )
}

StudentLevel.propTypes = {
  studentId: PropTypes.string.isRequired
}

export default StudentLevel

import papa from 'papaparse'
import isEmpty from 'lodash/isEmpty'
import { isNonGradedChapter } from './chapterUtil'
import isNumber from 'lodash/isNumber'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import { changeParticipationPercentToGrade } from './gradeUtils'
import { EXAM } from '../Constants/chapter'
import {
  MIDTERM_EXAM_1,
  MIDTERM_EXAM_2,
  EXAM_1,
  EXAM_2,
  EXAM_3,
  EXAM_4,
  FINAL_EXAM,
  FINAL_EXAM_I,
  FINAL_EXAM_II,
  VERSION_B
} from '../Constants/examTypes'
import { round } from 'lodash'
import api from '../api'

export {
  addActivitiesToGradeData,
  addActivitiesToProgressData,
  filterGoogleDataAnalyticsIChapters,
  getGradeReport,
  getStudentProgress,
  jsonToTableData,
  csvToTableData,
  getCsvData,
  getSortedCourses,
  getGradeKeys,
  mergeGradeReportWithCurrentGrades
}

function csvToTableData (csv) {
  const parsedCSV = papa.parse(csv, null)
  if (
    !parsedCSV.data ||
    parsedCSV.data.length === 0 ||
    Object.keys(parsedCSV).length === 0
  ) {
    return { headerData: null, rowData: null }
  }

  const headerData = parsedCSV.data[0]
  const rowData = parsedCSV.data.slice(1)
  return { headerData, rowData }
}

function jsonToTableData ({ courseGradeInfo, chapters, maxParticipationGrade }) {
  const { grade, isCohortEnd } = courseGradeInfo
  if (!grade || (grade && grade.length === 0)) {
    return { headerData: null, rowData: null }
  }

  let sectionNames = []

  if (chapters && chapters.length) {
    chapters.forEach((chapter) => {
      if (isNonGradedChapter(chapter)) return
      if (chapter.sections) {
        sectionNames = [...sectionNames, ...chapter.sections.map(chapter => chapter.title)]
      } else {
        sectionNames = [...sectionNames, chapter.title]
      }
    })
  }

  const generateTemplate = (grade) => {
    const {
      email, name, preferredName, notes, lastActive, grade: participation,
      studentStatus, projectedGrade, currentGrade, letterGrade, attemptId, relationship, ...rest
    } = grade

    const gradeColumn = isCohortEnd
      ? { 'Final Grade': projectedGrade }
      : { 'Current Grade': currentGrade }

    replaceExamScoreWithVersionBScore(rest)

    return {
      email,
      name,
      'Preferred Name': preferredName,
      'Last Active': lastActive,
      'Student Status': studentStatus,
      Relationship: relationship,
      notes,
      ...gradeColumn,
      'Final Letter Grade': letterGrade,
      ...rest,
      participation: changeParticipationPercentToGrade({
        percent: participation,
        maxParticipationGrade
      }),
      'Attempt ID': attemptId
    }
  }

  const columnTemplate = generateTemplate(grade[0])

  let headerData = ['graph', ...Object.keys(columnTemplate)]
  const gradeName = isCohortEnd
    ? ['Final Grade']
    : ['Current Grade']

  if (sectionNames.length) {
    headerData = [
      'graph', 'email', 'name', 'Preferred Name', 'Last Active',
      'Student Status', 'Relationship', 'notes', 'id',
      ...gradeName, 'Final Letter Grade', ...sectionNames, 'participation',
      'Attempt ID'
    ]
  }
  headerData = uniq(headerData)

  const rowData = [...grade.map(row => {
    if (!sectionNames.length) {
      const {
        email, name, preferredName, relationship, notes, lastActive, grade: participation,
        studentStatus, projectedGrade, letterGrade, attemptId, ...rest
      } = row
      // empty string is `graph` column in each row
      return [
        '', email, name, preferredName, lastActive, studentStatus, relationship, notes,
        projectedGrade, letterGrade, ...Object.values(rest),
        changeParticipationPercentToGrade({
          percent: participation,
          maxParticipationGrade
        }), attemptId
      ]
    }
    const data = []
    const columnDataTemplate = generateTemplate(row)
    headerData.forEach(item => {
      data.push(item === 'graph' ? '' : columnDataTemplate[item])
    })
    return data
  })]
  return { headerData, rowData }
}

// for students with exam retakes.
const replaceExamScoreWithVersionBScore = (rest) => {
  const exams = [
    MIDTERM_EXAM_1, MIDTERM_EXAM_2, FINAL_EXAM, // standard exams
    EXAM_1, EXAM_2, EXAM_3, EXAM_4, FINAL_EXAM_I, FINAL_EXAM_II // 39 week cohort exams
  ]

  exams.forEach(exam => {
    const versionBScore = rest[`${exam} ${VERSION_B}`]
    if (isNumber(versionBScore)) {
      rest[exam] = versionBScore // replaces the original exam score with the version B score if one exists.
    }
  })
}

function getCsvData ({ courseGradeInfo, chapters, courseId, cohortStartDate, maxParticipationGrade }) {
  if (!courseGradeInfo || !courseGradeInfo.grade || !courseGradeInfo.grade.length) return []
  const filteredChapters = filterGoogleDataAnalyticsIChapters(chapters, courseId, cohortStartDate)
  const { headerData, rowData } = jsonToTableData({
    courseGradeInfo,
    chapters: filteredChapters,
    maxParticipationGrade
  })
  if (!headerData || !rowData) return []
  const rows = rowData.map(row => {
    const objRowData = {}
    headerData.forEach((item, index) => {
      objRowData[item.toUpperCase()] = row[index]
    })
    return objRowData
  })
  return rows
}

function getGradeKeys (gradeData, chapters) {
  if (!gradeData) return

  const {
    email, name, notes, lastActive, letterGrade, id, studentStatus,
    attemptId, statusChangeDate, contractorEmployee, testAttempt,
    grade, pastCohortData, preferredName, ...sections
  } = gradeData

  const gradeKeys = []
  const sectionKeys = Object.keys(sections)

  if (!chapters?.length) return sectionKeys

  chapters.forEach((chapter) => {
    if (isNonGradedChapter(chapter || {})) return

    if (!chapter?.sections?.length) {
      const gradeKey = sectionKeys.find(key => {
        const chapterUUID = Object.keys(sections[key] || {})?.[0]
        return chapterUUID === chapter?.chapter_uuid
      })
      if (!gradeKey) return
      gradeKeys.push(gradeKey)
      return
    }

    chapter.sections.forEach((section) => {
      const gradeKey = sectionKeys.find(key => {
        const sectionUUID = Object.keys(sections[key] || {})?.[0]
        return sectionUUID === section?.section_uuid
      })
      if (!gradeKey) return
      gradeKeys.push(gradeKey)
    })
  })

  return gradeKeys
}

function filterGoogleDataAnalyticsIChapters (chapters, courseId, dateStart) {
  if (!chapters?.length) return []

  const isGoogleDataAnalyticsI = courseId === 'clgb29uhc00003b67wtuupjqy'
  const startsOnOrAfter14Jan2024 = new Date(dateStart) >= new Date('2024-01-14')

  if (!isGoogleDataAnalyticsI || !startsOnOrAfter14Jan2024) return chapters

  return chapters.filter(({ type }) => type !== EXAM)
}

function getSortedCourses (courses) {
  const updatedCourses = sortBy(
    courses.map(course => ({
      ...course,
      value: course.id,
      label: course.name
    })),
    'name'
  )
  const testCourses = updatedCourses.filter(
    course =>
      course.name.toLowerCase().includes('test') || course.name === 'Bundle'
  )
  const mainCourses = updatedCourses.filter(
    course => !testCourses.includes(course)
  )

  return [...mainCourses, ...testCourses]
}

function mergeGradeReportWithCurrentGrades (gradeReport, currentGrades) {
  if (isEmpty(currentGrades)) return gradeReport

  return gradeReport.map((gradeReportEntry) => {
    const grade = currentGrades?.[gradeReportEntry.id]
    // TODO remove this round after BE makes the round in the backend
    const currentGrade = !isNaN(grade)
      ? round(grade, 2)
      : grade
    return {
      ...gradeReportEntry,
      currentGrade
    }
  })
}

function addActivitiesToGradeData ({
  sectionGrades, courseraGrades, populateQuizzes, studentEmail
}) {
  if (isEmpty(courseraGrades)) return sectionGrades

  const hasAllCohortStudents = Array.isArray(sectionGrades)
  const currentStudentIndex = hasAllCohortStudents &&
    sectionGrades.findIndex(student => student.email === studentEmail)

  Object.entries(courseraGrades).forEach(([key, value]) => {
    const gradeValue = populateQuizzes ? { [key]: value } : value

    if (hasAllCohortStudents && currentStudentIndex > -1) {
      sectionGrades[currentStudentIndex][key] = gradeValue
    } else sectionGrades[key] = gradeValue
  })

  return sectionGrades
}

async function getGradeReport ({
  courseId, cohortId, studentEmail, populateQuizzes, queryParams
}) {
  const [sectionGrades, courseraGrades] = await Promise.all([
    api.getGradeReport(courseId, queryParams),
    ...(studentEmail
      ? [api.getCourseraGrades({ courseId, cohortId, studentEmail })]
      : []
    )
  ])
  const gradeData = addActivitiesToGradeData({
    sectionGrades, courseraGrades, populateQuizzes, studentEmail
  })
  return gradeData
}

function addActivitiesToProgressData (studentProgress, courseraGrades) {
  if (isEmpty(courseraGrades)) return studentProgress

  studentProgress.courseraActivities = courseraGrades
  return studentProgress
}

async function getStudentProgress ({
  courseId, cohortId, studentEmail, isCurrentCohort
}) {
  const [studentProgress, courseraGrades] = await Promise.all([
    api.getStudentProgress({ courseId, cohortId, studentEmail, isCurrentCohort }),
    api.getCourseraGrades({ courseId, cohortId, studentEmail })
  ])

  const progressData = addActivitiesToProgressData(studentProgress, courseraGrades)
  return progressData
}

export const ASTRONOMY_NAME = 'Introduction to Astronomy'
export const CALCULUS_NAME = 'Calculus I'
export const MACROECONOMICS_NAME = 'Introduction to Macroeconomics'
export const MICROECONOMICS_NAME = 'Introduction to Microeconomics'
export const PHILOSOPHY_NAME = 'Introduction to Philosophy'
export const PRECALCULUS_NAME = 'Precalculus'
export const STATISTICS_NAME = 'Introduction to Statistics'
export const FINANCIAL_ACCOUNTING_NAME = 'Introduction to Financial Accounting'
export const PSYCHOLOGY_NAME = 'Introduction to Psychology'
export const SOCIOLOGY_NAME = 'Introduction to Sociology'
export const PRINCIPLES_OF_ECONOMICS_NAME = 'Principles of Economics'
export const ASTRONOMY_V2_NAME = 'Introduction to Astronomy V2'
export const BUSINESS_NAME = 'Introduction to Business'
export const COLLEGE_WRITING_I_NAME = 'College Writing I'
export const COLLEGE_ALGEBRA = 'College Algebra'
export const COMPUTER_SCIENCE_I_NAME = 'Computer Science I'

export const COLLEGE_WRITING_I_GGU_V1_NAME = 'College Writing I - GGU'
export const COLLEGE_WRITING_I_GGU_V1_ID = 'cl8bpkss900003b68lww2m8az'
export const COLLEGE_WRITING_I_GGU_V2_AT_ID = 'recMaauY8Uffs68cM'
export const COLLEGE_WRITING_I_GGU_V2_NAME = 'College Writing I V2 - GGU'

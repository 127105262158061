import React, { useEffect, useState } from 'react'
import {
  ExamButton,
  TextDisabled
} from './styled'
import api from '../../api'
import {
  shouldLockProctorioReviewLink,
  getProctorioExamTag
} from '../../utilities/proctorio'
import {
  isPhilosophyCourse
} from './utils'
import {
  MIDTERM_EXAM_1,
  SINGLE_MIDTERM_EXAM
} from '../../Constants/examTypes'

const getProctorioReviewUrl = async (cohortName, examName) => {
  const examTag = getProctorioExamTag(cohortName, examName)

  const reviewUrl = await api.getProctorioReviewUrl(examTag)
  return reviewUrl
}

const ExamGradeBookButton = props => {
  const [isLoading, setIsLoading] = useState(false)
  const [reviewUrl, setReviewUrl] = useState('')
  const [reviewUrlTwo, setReviewUrlTwo] = useState('')
  const {
    proctorioExamTitle,
    examEndTime,
    cohortName
  } = props

  useEffect(() => {
    if (!reviewUrl) return
    window.open(reviewUrl, '_blank')
  }, [reviewUrl])

  useEffect(() => {
    if (!reviewUrlTwo) return
    window.open(reviewUrlTwo, '_blank')
  }, [reviewUrlTwo])

  const handleButtonClick = async () => {
    setIsLoading(true)
    const reviewUrl = await getProctorioReviewUrl(cohortName, proctorioExamTitle)

    let reviewUrlTwo = ''
    const isPhilosophy = isPhilosophyCourse(cohortName)
    const isMidExam = proctorioExamTitle === MIDTERM_EXAM_1

    const shouldGenerateSecondUrl = isPhilosophy && isMidExam
    if (shouldGenerateSecondUrl) {
      reviewUrlTwo = await getProctorioReviewUrl(cohortName, SINGLE_MIDTERM_EXAM)
    }

    setIsLoading(false)
    setReviewUrl(reviewUrl)
    setReviewUrlTwo(reviewUrlTwo)
  }

  const lockExam = shouldLockProctorioReviewLink(examEndTime)
  if (lockExam) return <TextDisabled>LOCKED</TextDisabled>

  return (
    <ExamButton
      className='btn-custom btn-primary'
      onClick={handleButtonClick}
      disabled={isLoading}
    >
      {isLoading
        ? <i className='fa fa-spinner fa-spin' />
        : 'View Exam'
      }
    </ExamButton>
  )
}

export default ExamGradeBookButton

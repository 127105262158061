import api from '../api'
import jwtDecode from 'jwt-decode'

export {
  getUserEmail,
  validateEmail,
  hasAccessToLink
}

async function getUserEmail () {
  const token = await api.getToken()
  const decodedToken = jwtDecode(token)
  return decodedToken.email
}

/**
 * @param {object} userRoles
 * @param {object} link
 * @description We have 3 user types Admin, Registrar and Grader.
 * 1. Admins can access the links which are not exclusively specified as registrar and grader
 * 2. Registrars can access admin links as well as registrar links
 * 3. Graders can access only grader links. They cannot access admin or registrar links
 * Additionally, users can be customized to have access to pages that their roles
 * do not allow. They are added to usersWithExtraAccess array
 * @returns {boolean}
 */

const containPermission = (containedPermission, permissions) =>
  permissions?.some(permission => permission === containedPermission)

function hasAccessToLink (link, userPermissions, roles) {
  if (!link.requiredPermissions || roles?.includes('admin')) return true
  return link.requiredPermissions.some(permission => containPermission(permission, userPermissions))
}

function validateEmail (email) {
  if (!email) return null
  const format = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$/
  return email.match(format)
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  BackArrowIcon,
  BackLink,
  HeaderContainer,
  PageHeading,
  LinkContainer,
  Link,
  PageContainer,
  Separator,
  PageDescription
} from './styles/index.styled'
import LicenseDashboard from './LicenseDashboard'

const ViewLicenseDashboard = () => {
  const history = useHistory()

  const handleGoBack = () => {
    history.push('/relationships')
  }

  return (
    <PageContainer>
      <BackLink onClick={handleGoBack}>
        <BackArrowIcon />
        manage relationships
      </BackLink>
      <HeaderContainer>
        <PageHeading>License Dashboard</PageHeading>
        <LinkContainer>
          <Link
            onClick={() => history.push('/relationships/void-licenses')}
          >
            void licenses
          </Link>
          <Link
            onClick={() => history.push('/relationships/add-course-licenses')}
          >
            add course licenses
          </Link>
        </LinkContainer>
      </HeaderContainer>
      <Separator />
      <PageDescription>
        View course license balances for every partner
      </PageDescription>
      <LicenseDashboard />
    </PageContainer>
  )
}

export default ViewLicenseDashboard

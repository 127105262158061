import React from 'react'
import PropTypes from 'prop-types'
import { PRESET_SELECTION } from '../../../Constants/CSVDownloadToolOptions'
import RadioButtonSelector from '../../RadioButtonSelector'
import {
  StyledWrapper
} from './styled'

const PresetSelector = props => {
  const {
    values,
    setValue
  } = props

  return (
    <StyledWrapper>
      {
        PRESET_SELECTION.map((selection, i) => {
          const {
            headerText,
            exposeKey,
            options
          } = selection

          return <RadioButtonSelector
            key={exposeKey + i}
            exposeKey={exposeKey}
            headerText={headerText}
            values={values}
            setValue={setValue}
            options={options}
            className='preset-item'
          />
        })
      }
    </StyledWrapper>
  )
}

PresetSelector.propTypes = {
  values: PropTypes.object,
  setValue: PropTypes.func.isRequired
}

export default PresetSelector

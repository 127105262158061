import React from 'react'
import PropTypes from 'prop-types'
import InformationCard from '../InformationCard/InformationCard'

export default function StudentAttendance ({
  attendedDays,
  totalDays
}) {
  if (attendedDays === null) return null

  const percentage = Math.round(attendedDays / totalDays * 100)
  const subTitle = `${percentage}% (${attendedDays}/${totalDays}) days`

  return (
    <>
      <InformationCard
        title='Attendance'
        subTitle={subTitle}
        data-testid='student-attendance'
      />
    </>
  )
}

StudentAttendance.propTypes = {
  attendedDays: PropTypes.number.isRequired,
  totalDays: PropTypes.number.isRequired
}

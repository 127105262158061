import React, { useState } from 'react'
import { TooltipBox, TooltipContainer, Icon } from './styled'
const Tooltip = ({ icon, position, text }) => {
  const [visible, setVisible] = useState(false)

  const showTooltip = () => setVisible(true)
  const hideTooltip = () => setVisible(false)

  return (
    <TooltipContainer data-testid='tooltip-container'>
      <Icon
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        data-testid='tooltip-icon'
      >
        {icon}
      </Icon>
      {visible && (
        <TooltipBox position={position} data-testid='tooltip-box'>
          {text}
        </TooltipBox>
      )}
    </TooltipContainer>
  )
}

export default Tooltip

import React from 'react'
import DropdownImgURL from '../../assets/icons/caret-sort-desc.png'
import DisabledDropdownImgURL from '../../assets/icons/caret-sort-disable.png'
import {
  useWritingGradeCenterActions,
  useWritingGradeCenterContext
} from '../../contexts/WritingGradeCenterContext'
import {
  DropDownImg
} from './styled'

function SubmissionsTableHeader ({
  title,
  sortItem,
  sortColumn,
  setSortColumn
}) {
  const {
    isSubmissionsAscending
  } = useWritingGradeCenterContext()
  const {
    setSubmissionsAscending
  } = useWritingGradeCenterActions()

  return (
    <span
      className='submission-header'
      onClick={() => {
        sortColumn === sortItem
          ? setSubmissionsAscending(!isSubmissionsAscending)
          : setSortColumn(sortItem)
      }}
    >
      {title}
      <DropDownImg
        src={
          sortColumn === sortItem
            ? DropdownImgURL
            : DisabledDropdownImgURL
        }
        isSortedAscending={isSubmissionsAscending}
      />
    </span>
  )
}

export default SubmissionsTableHeader

import React, { useEffect, useState } from 'react'
import {
  faEdit,
  faToggleOn,
  faToggleOff,
  faAngleLeft
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  timeStampToFormattedDate,
  dateStringToFormattedDate
} from '../../utilities/dateTimeUtil'
import { getCourseNameById } from '../../utilities/courseUtils'
import { useCoursesContext, useCoursesActions } from '../../contexts/Courses'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import api from '../../api'
import './cohort.css'
import {
  BtnGroupHeading,
  BtnGroupItems,
  CohortDetails,
  CohortName,
  CohortNote,
  StartDate,
  Tooltip
} from './styled'
import TooltipIcon from '../../assets/icons/icon-tooltip.svg'

function CohortDetail ({ routerProps }) {
  const [cohort, setCohort] = useState({})
  const [courses, setCourses] = useState([])
  const [relation, setRelation] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [disabled, setDisabled] = useState(false)
  const [isLoadingForStatus, setIsLoadingForStatus] = useState(true)
  const [canEdit, setCanEdit] = useState(false)
  const { fetchCourses } = useCoursesActions()
  const { allCourses } = useCoursesContext()

  const {
    history,
    match: {
      params: { cohortId }
    }
  } = routerProps

  useEffect(() => {
    if (!allCourses.length) {
      fetchCourses()
      return
    }

    setCourses(
      allCourses.map((course) => ({ at_id: course.at_id, label: course.name }))
    )
    // eslint-disable-next-line
  }, [allCourses])

  useEffect(() => {
    getCohortAndRelationDetail()
    checkPermissions()
    // eslint-disable-next-line
  }, [])

  const checkPermissions = async () => {
    const EDIT_PERMISSION = 'cohorts.view'
    try {
      const { permissions } = await api.getCurrentUserPermissions()
      const containEditPermission = permissions.some(
        (permission) => permission === EDIT_PERMISSION
      )
      setCanEdit(containEditPermission)
    } catch (err) {
      console.error(err)
    }
  }

  const editCohort = (cohortId) => {
    history.push({ pathname: `/cohorts/${cohortId}/edit` })
  }

  const getCohortAndRelationDetail = async () => {
    const [cohort, relationship] = await Promise.all([
      api.getCohortById(cohortId),
      api.getStudentRelationships()
    ])
    setRelation(relationship)
    setCohort(cohort)
    setDisabled(cohort.disabled)
    setIsLoading(false)
    setIsLoadingForStatus(false)
  }

  const updateCohortStatus = async () => {
    setIsLoadingForStatus(true)
    const response = await api.updateCohort(cohortId, { disabled: !disabled })
    const { success } = response
    if (success) {
      setDisabled(!disabled)
      setIsLoadingForStatus(false)
    }
  }
  const {
    dateStart,
    finalRegistrationDate,
    finalDropDate,
    relationships = [],
    finalWithdrawalDate
  } = cohort

  const relations = relation.filter(({ id }) =>
    relationships.some((relId) => relId === id)
  )
  const getFilteredcreditGrantingInstitutions = () => {
    const creditGrantingInstitutions = []
    relations.forEach(({ creditGrantingInstitution = '' }) => {
      const isNotNullOrUndefinded =
        creditGrantingInstitution !== null &&
        creditGrantingInstitution !== undefined
      if (
        !creditGrantingInstitutions.includes(creditGrantingInstitution) &&
        isNotNullOrUndefinded
      ) {
        creditGrantingInstitutions.push(creditGrantingInstitution)
      }
    })

    return !creditGrantingInstitutions.length
      ? 'N/A'
      : creditGrantingInstitutions
        ?.map((creditGrantingInstitution, idx) => {
          const isLast = idx === creditGrantingInstitutions.length - 1
          return !isLast
            ? creditGrantingInstitution + ', '
            : creditGrantingInstitution
        })
        .join('')
  }

  const getFilteredRelationShipNames = () => {
    const relationshipNames = []
    relations.forEach(({ relationshipName = '' }) => {
      const isNotNullOrUndefinded =
            relationshipName !== null && relationshipName !== undefined
      if (
        !relationshipNames.includes(relationshipName) &&
            isNotNullOrUndefinded
      ) {
        relationshipNames.push(relationshipName)
      }
    })

    return !relationshipNames.length
      ? 'N/A'
      : relationshipNames
        ?.map((relationshipName, idx) => {
          const isLast = idx === relationshipNames.length - 1
          return !isLast ? relationshipName + ', ' : relationshipName
        })
        .join('')
  }

  return (
    <>
      <div className='grade-detail md-pl-40'>
        <div style={{ paddingRight: 'unset' }} className='btn-wrapper'>
          <h3
            style={{ width: 'unset' }}
            className='admin-home color--teal sm-pt-32 sm-pl-24 lg-pl-33'
          >
            <FontAwesomeIcon icon={faAngleLeft} />{' '}
            <p onClick={() => history.push('/cohorts')}>Back to cohorts</p>
          </h3>
          <div className='end-buttons'>
            {canEdit && (
              <BtnGroupHeading className='admin-home color--teal'>
                <FontAwesomeIcon icon={faEdit} />{' '}
                <p onClick={() => editCohort(cohortId)}>Edit Cohort</p>
              </BtnGroupHeading>
            )}
            <BtnGroupHeading className='admin-home btn-enable-disable color--teal'>
              {isLoadingForStatus ? (
                <i className='fa fa-spinner fa-spin' />
              ) : (
                <>
                  <FontAwesomeIcon icon={disabled ? faToggleOff : faToggleOn} />{' '}
                  <p id='enable-disable' onClick={() => updateCohortStatus()}>
                    {disabled ? 'Disabled' : 'Enabled'}
                  </p>
                </>
              )}
            </BtnGroupHeading>
          </div>
        </div>
        {!isLoading ? (
          <>
            <div style={{ marginBottom: 'unset' }} className={'cohort-info'}>
              <CohortName data-testid='cohort-name' className='sm-mb-12'>
                {cohort.name}
              </CohortName>
              <StartDate className='student-active'>
                Start Date: {dateStringToFormattedDate(dateStart)}
              </StartDate>
            </div>
            <div className='end-buttons-mob'>
              <BtnGroupItems style={{ paddingTop: 'unset' }}
                className='admin-home btn-enable-disable color--teal'>
                {isLoadingForStatus ? (
                  <i className='fa fa-spinner fa-spin' />
                ) : (
                  <>
                    <FontAwesomeIcon
                      icon={disabled ? faToggleOff : faToggleOn}
                    />{' '}
                    <p id='enable-disable' onClick={() => updateCohortStatus()}>
                      {disabled ? 'Disabled' : 'Enabled'}
                    </p>
                  </>
                )}
              </BtnGroupItems>
              {canEdit && (
                <BtnGroupItems className='admin-home color--teal'>
                  <FontAwesomeIcon icon={faEdit} />{' '}
                  <p onClick={() => editCohort(cohortId)}>Edit Cohort</p>
                </BtnGroupItems>
              )}
            </div>
            <div className='cohort-container md-pb-unset'>
              <div className='student-info-constainer info-container-margin'>
                <CohortDetails>
                  <div>
                    <div>Course Name</div>
                    <div>
                      {cohort.course
                        ? getCourseNameById(courses, cohort.course[0])
                        : 'N/A'}
                    </div>
                  </div>
                  <div>
                    <div>Duration</div>
                    <div>{cohort.duration || 'N/A'}</div>
                  </div>
                  <div>
                    <div>
                      Grades{' '}
                      <Tooltip>
                        <img
                          src={TooltipIcon}
                          className='ml-2 mb-1'
                          width='16'
                          height='16'
                          alt='tool-tip'
                        />
                        <span>
                          <p>
                            Used by Analytics to indicate when a cohort's grades
                            have been sent to Pitt.
                          </p>
                        </span>
                      </Tooltip>
                    </div>
                    <div>{cohort.gradesSubmittedToPitt ? 'Yes' : 'No'}</div>
                  </div>

                  <div>
                    <div>Partner Cohort</div>
                    <div>{cohort.isPartnerCohort ? 'Yes' : 'No'}</div>
                  </div>

                  <div>
                    <div>Relationship</div>
                    <div>{getFilteredRelationShipNames()}</div>
                  </div>

                  <div className='mb-unset-reset'>
                    <div>Credit Granting Institution</div>
                    <div>
                      {getFilteredcreditGrantingInstitutions() || 'N/A'}
                    </div>
                  </div>

                  <div className='mobile-view'>
                    <div>Notes</div>
                    <div>{cohort.notes}</div>
                  </div>

                  <div className='mobile-view mb-unset'>
                    <div>Course Info URL</div>
                    <div>
                      <a
                        className='course-info-url'
                        href={cohort.courseInfoUrl}
                        target='blank'
                      >
                        {cohort.courseInfoUrl}
                      </a>
                    </div>
                  </div>
                </CohortDetails>
                <CohortNote className='cohort-note'>
                  <div className='cohort-note-wrapper'>
                    <div>Notes</div>
                    <div>{cohort.notes}</div>
                  </div>
                  <div>
                    <div className='course-info-url'>Course Info URL</div>
                    <div>
                      <a
                        className='course-info-url'
                        href={cohort.courseInfoUrl}
                        target='blank'
                      >
                        {cohort.courseInfoUrl}
                      </a>
                    </div>
                  </div>
                </CohortNote>
              </div>
            </div>
            <div
              style={{ marginTop: '36px' }}
              className='cohort-container md-pt-unset date-section'
            >
              <CohortDetails>
                <div>
                  <div>Final Registration Date:</div>
                  <div>{dateStringToFormattedDate(finalRegistrationDate)}</div>
                </div>
                <div>
                  <div>Final Drop Date:</div>
                  <div>{dateStringToFormattedDate(finalDropDate)}</div>
                </div>
                <div>
                  <div>Final Withdrawal Date:</div>
                  <div>{dateStringToFormattedDate(finalWithdrawalDate)}</div>
                </div>
                <div>
                  <div>Mid Term1 Start Time:</div>
                  <div>
                    {timeStampToFormattedDate(cohort.midTerm1StartTime, true)}
                  </div>
                </div>
                <div>
                  <div>Mid Term1 End Time:</div>
                  <div>
                    {timeStampToFormattedDate(cohort.midTerm1EndTime, true)}
                  </div>
                </div>
                <div>
                  <div>Mid Term2 Start Time:</div>
                  <div>
                    {timeStampToFormattedDate(cohort.midTerm2StartTime, true)}
                  </div>
                </div>
                <div>
                  <div>Mid Term2 End Time:</div>
                  <div>
                    {timeStampToFormattedDate(cohort.midTerm2EndTime, true)}
                  </div>
                </div>
                <div>
                  <div>Final Exam Start Time:</div>
                  <div>
                    {timeStampToFormattedDate(cohort.finalExamStartTime, true)}
                  </div>
                </div>
                <div>
                  <div>Final Exam End Time:</div>
                  <div>
                    {timeStampToFormattedDate(cohort.finalExamEndTime, true)}
                  </div>
                </div>
                <div>
                  <div>End Time:</div>
                  <div data-testid='end-time'>{timeStampToFormattedDate(cohort.endTime, true)}</div>
                </div>
              </CohortDetails>
            </div>
          </>
        ) : (
          <LoadingSpinner />
        )}
      </div>
    </>
  )
}

export default CohortDetail

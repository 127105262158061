import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'

export { parseOrderNumber, generateOrderNumber, checkDeepEqual }

function parseOrderNumber (orderNumber) {
  if (!orderNumber) {
    return 0
  }
  return parseInt(orderNumber.replace(/P-|M-/g, ''))
}

function generateOrderNumber (orderNumber, isPartnership) {
  const orderId = `${orderNumber}`.padStart(6, '0')
  return (isPartnership ? 'P-' : 'M-') + orderId
}

function checkDeepEqual (currentState, prevState, isOmit) {
  const omitFields = isOmit
    ? ['email', 'name', 'notes', 'orders']
    : []
  return isEqual(omit(currentState, omitFields), omit(prevState, omitFields))
}

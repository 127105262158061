import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { Label } from 'reactstrap'
import isEqual from 'lodash/isEqual'
import ModalComponent from '../../ModalComponent/ModalComponent'
import {
  AddConditionButton,
  Button,
  FiltersWrapper,
  ModalBody,
  ModalFooter,
  ModalWrapper,
  NoFiltersMessage,
  SelectWrapper,
  dropDownStyles
} from './styles'
import { PrimaryButton, SecondaryButton } from '../../global.styled'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import { ReactComponent as DeleteIcon } from '../../../assets/icons/trash-icon-green.svg'
import FilterItem from './FilterItem'
import {
  getFilters, getFiltersWithRules, getOperators, getOptions
} from '../utils'
import api from '../../../api'
import DiscardModal from './DiscardModal'

const LOGICAL_OPERATORS = [
  {
    label: 'OR',
    value: 'OR'
  },
  {
    label: 'AND',
    value: 'AND'
  }
]

function FiltersModal ({
  metaData,
  closeFiltersModal,
  setFilters,
  currentView,
  currentTable
}) {
  const [options, setOptions] = useState([])
  const [logicalOperator, setLogicalOperator] = useState(LOGICAL_OPERATORS[0])
  const [showDiscardModal, setShowDiscardModal] = useState(false)

  const tableHeaders = useMemo(
    () =>
      metaData.map((header) => ({
        label: header.columnName,
        value: header.columnName
      })),
    [metaData]
  )

  const { filters } = currentView || {}

  useEffect(() => {
    const options = getOptions(filters, tableHeaders).filter(
      (option) => Object.keys(option).length > 0
    )

    if (filters?.logicalOperator) {
      setLogicalOperator({
        value: filters.logicalOperator,
        label: filters.logicalOperator
      })
    }
    setOptions(options)
  }, [filters, tableHeaders])

  const setOption = (index, option) => {
    const newOptions = [...options]
    newOptions[index] = option
    setOptions(newOptions)
  }
  const deleteOption = (index) => {
    const newOptions = [...options]
    newOptions.splice(index, 1)
    setOptions(newOptions)
  }

  const addOption = () => {
    const newOptions = [...options]
    const filterColumn = tableHeaders[0]
    const { value: columnValue } = filterColumn || {}
    const operators = getOperators(columnValue, metaData)

    const newOption = { filterColumn, filterOperator: operators[0] }

    newOptions.push(newOption)
    setOptions(newOptions)
  }

  const saveFilters = async () => {
    const filters = getFilters(options, logicalOperator)
    const filterWithRules = getFiltersWithRules(filters, tableHeaders)
    setFilters(filterWithRules)
    closeFiltersModal()

    if (currentView.label === 'Default') return

    const { id, label, value, ...rest } = currentView
    await api.updateTableView({
      tableName: currentTable,
      id,
      view: { ...rest, filters: filterWithRules }
    })
  }

  const onModalClose = () => {
    const filters = getFilters(options, logicalOperator)
    const currentFilters = currentView?.filters || {}

    if (isEqual(filters, currentFilters)) {
      closeFiltersModal()
      return
    }

    setShowDiscardModal(true)
  }

  return (
    <ModalWrapper data-testid='filters-modal'>
      <ModalComponent
        title='Filter'
        show
        width='929px'
        isResponsive
        handleClose={onModalClose}
      >
        <ModalBody hasOptions={options.length > 0}>
          {!options.length && (
            <NoFiltersMessage>
              No filters applied. Add a condition to filter the records.
            </NoFiltersMessage>
          )}
          {options.length > 0 &&
            options.map((option, index) => (
              <FiltersWrapper key={index}>
                <FilterItem
                  columnIndex={index}
                  option={option}
                  metaData={metaData}
                  tableHeaders={tableHeaders}
                  setOption={(option) => setOption(index, option)}
                />
                {index + 1 !== options.length && (
                  <SelectWrapper>
                    <Label>Logical Operator</Label>
                    <Select
                      options={LOGICAL_OPERATORS}
                      value={logicalOperator}
                      onChange={(operator) => setLogicalOperator(operator)}
                      styles={dropDownStyles}
                      width='120px'
                      height='56px'
                      menuPortalTarget={document.body}
                    />
                  </SelectWrapper>
                )}
                <Button
                  style={{ padding: '16px' }}
                  onClick={() => deleteOption(index)}
                  data-testid='delete-condition-button'
                >
                  <DeleteIcon />
                </Button>
              </FiltersWrapper>
            ))}
        </ModalBody>

        <AddConditionButton onClick={addOption}>
          <PlusIcon />
          Add condition
        </AddConditionButton>

        <ModalFooter>
          <PrimaryButton
            data-testid='apply-filters-button'
            marginRight='12px'
            onClick={saveFilters}
          >
            Apply Filter
          </PrimaryButton>
          <SecondaryButton
            data-testid='cancel-filters-button'
            onClick={onModalClose}
          >
            Cancel
          </SecondaryButton>
        </ModalFooter>
      </ModalComponent>
      {showDiscardModal && (
        <DiscardModal
          closeFiltersModal={closeFiltersModal}
          closeDiscardModal={() => setShowDiscardModal(false)}
        />
      )}
    </ModalWrapper>
  )
}

FiltersModal.displayName = 'FiltersModal'
export default FiltersModal

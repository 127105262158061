import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import styled, { css } from 'styled-components'

export const textSharedStyle = css`
  font-size: 16px;
  line-height: 19px;
`

export const Container = styled.div`
  height: 70px;
  border-radius: 5px;
  background: #1D1D1F;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding-left: 24px;
  padding-right: 24px;
`

export const Left = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
`
export const Right = styled.div`
  margin-left: 28px;
`

export const Time = styled.span`
  ${textSharedStyle}
  min-width: 216px;
  max-width: 216px;
  margin-right: 24px;
`

export const Icon = styled.img`
  margin-right: ${props => props.mr ? props.mr : 0}px;
`

export const ParticipantNumber = styled.span`
  ${textSharedStyle}
  min-width: 72px;
  max-width: 72px;
  margin-right: 24px;
`

export const Message = styled.span`
  ${textSharedStyle}
`

export const Dot = styled.div`
  min-width: 2px;
  max-width: 2px;
  height: 2px;
  background: #B1BFC5;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 50%;
`

export const StyledDropdown = styled(Dropdown)`
  line-height: 16px;
`

export const StyledDropdownToggle = styled(DropdownToggle)`
  padding: 0px;
  background: none;
  border: none;

  &:hover, &:active {
    background: none;
  }

  &:focus {
    box-shadow: none;
  }
`

export const StyledDropdownMenu = styled(DropdownMenu)`
  background-color: #25272B !important;

  button {
    height: 40px;
    &:focus, &:hover {
      background: rgba(255, 255, 255, 0.05) !important;
      border-radius: 5px !important;
    }
  }
`

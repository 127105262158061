export const COURSE_ACCESS_TYPES = [
  {
    label: 'UPitt intensive (7/8w)',
    value: 'UPitt Intensive',
    id: 'upitt-intensive'
  },
  {
    label: 'UPitt standard (14/15w)',
    value: 'UPitt Standard',
    id: 'upitt-standard'
  },
  {
    label: 'UPitt extended (39w)',
    value: 'UPitt Extended',
    id: 'upitt-extended'
  },
  {
    label: 'GGU Degrees+ Standard (14w)',
    value: 'GGU Degrees+ Standard',
    id: 'ggu-standard'
  },
  {
    label: 'GGU Professional Certificates',
    value: 'GGU Professional Certificates',
    id: 'ggu-certificates'
  }
]

export const COURSE_FILTER_OPTIONS = [
  {
    label: 'All Courses',
    value: 0
  },
  {
    label: '39-Week Courses',
    value: 39
  },
  {
    label: '14-Week Courses',
    value: 14
  },
  {
    label: '7-Week Courses',
    value: 7
  }
]

export const NO_DISTRICT_ERROR = 'noDistrict'
export const NO_RELATIONSHIP_ERROR = 'noRelationship'

export const VOID_LICENSE_OPTIONS = {
  ALL: 'ALL',
  BY_COURSE: 'BY_COURSE',
  BY_DATE: 'BY_DATE'
}

export const TOKEN_USED_STATUSES = [
  {
    label: 'Available',
    value: null
  },
  {
    label: 'Pending',
    value: 'pending'
  },
  {
    label: 'Refunded',
    value: 'refunded'
  },
  {
    label: 'Pending refund',
    value: 'pendingRefund'
  },
  {
    label: 'Expired',
    value: 'expired'
  }
]

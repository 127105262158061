import React from 'react'
import GoToPreviousPageButton from '../../GoToPreviousPageButton/GoToPreviousPageButton'
import { HeadingText, NotificationSeparator } from '../styled'
import {
  Wrapper,
  Content
} from '../Create/styled'
import {
  ReviewInfo
} from './styled'

const NotificationReviewAndUpdate = ({
  routerProps,
  headerText
}) => {
  const { history } = routerProps
  const handleGoBackClick = () => history.push('/notifications/create')

  return (
    <>
      <GoToPreviousPageButton
        text='create notification'
        handleClick={handleGoBackClick}
      />
      <Wrapper>
        <Content>
          <HeadingText>{headerText}</HeadingText>
          <NotificationSeparator />
          <ReviewInfo>
            Please review the following information before confirming.
          </ReviewInfo>
        </Content>
      </Wrapper>
    </>
  )
}

export default NotificationReviewAndUpdate

import styled from 'styled-components'
import { ReactComponent as Close } from '../../../assets/icons/modal-close.svg'
import mediaqueries from '../../../mediaQueries'

export const ToastWrapper = styled.div`
  display: ${props => props.showToast ? 'block' : 'none'};
  position: fixed;
  top: 105px;
  right: 62px;
  transition: all 0.3s ease-in-out;
  width: 350px;
  padding: 24px;
  background: #25272B;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 10;

  ${mediaqueries.smallMobile`
    right: unset;
    width: unset;
    margin: 0 10px;
  `}
`
export const ToastHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ToastTitle = styled.h1`
  margin: 0px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
`
export const ToastClose = styled(Close)`
  display: block;
  width: 14px;
  height: 14px;
  cursor: pointer;
`
export const ToastMessage = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  margin-top: 12px;

  p {
    margin: 0px;
  }
`

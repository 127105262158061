import React, { useState } from 'react'
import rectangleMinus from '../../../assets/icons/rectangle-minus.svg'
import rectanglePlus from '../../../assets/icons/rectangle-plus.svg'
import { RowWrapper, TokenCell, TokenRow } from './styles/index.styled'
import ExpandedRow from './ExpandedRow'

const TableRow = ({ relationship }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const {
    school,
    district,
    licenses
  } = relationship || {}

  const handleClick = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <RowWrapper expandRow={isExpanded}>
      <TokenRow onClick={() => { handleClick() }}>
        <TokenCell data-label='School' style={{ width: 319 }}>{school}</TokenCell>
        <TokenCell data-label='District' style={{ width: 403 }}>{district || '--'}</TokenCell>
        <TokenCell data-label='Total Licenses'>{licenses.length}</TokenCell>
        <TokenCell style={{ justifyContent: 'end' }}>
          <img
            src={isExpanded ? rectangleMinus : rectanglePlus}
            alt='rectangle-plus'
          />
        </TokenCell>
      </TokenRow>
      {isExpanded && (
        <ExpandedRow
          relationship={relationship}
        />
      )}
    </RowWrapper>
  )
}

TableRow.displayName = 'TableRow'
export default TableRow

import styled from 'styled-components'

export const NavBarItem = styled.div`
  cursor: pointer;
  color: var(--text-primary);
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 480px) {
    display: none;
  }
`
export const Overlay = styled.div`
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--canvas-dark);
  z-index: 9;
  @media (max-width: 576px) {
    height: fit-content;
  }
`
export const Container = styled.div`
  margin: 68px auto;
  max-width: 1072px;
  @media (max-width: 1072px) {
    padding: 0 16px;
  }
`
export const Title = styled.h2`
  color: var(--text-primary);
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0;
`
export const CloseOverlay = styled.img`
  height: 16px;
  width: 16px;
  vertical-align: middle;
  cursor: pointer;
`
export const Tools = styled.div``
export const Separator = styled.hr`
  margin: 30px 0 31px;
  height: 1px;
  border-color: var(--border-primary);
`
export const Tool = styled.div`
  padding: 8px 0;
  border-radius: 5px;
  margin-bottom: 16px;
  &:hover {
    text-decoration: none !important;
  }
`

export const ToolIcon = styled.img`
  width: 24px;
`

export const ToolName = styled.span`
  font-size: 18px;
  vertical-align: middle;
  color: var(--text-primary);
  margin-left: 16px;
  img {
    margin-left: 4px;
  }
  &:hover {
    text-decoration: none !important;
  }
`

import React, { useEffect, useState } from 'react'
import LicenseDashboardTable from './LicenseDashboardTable'
import api from '../../../api'
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner'
import LicenseDashboardFilter from './LicenseDashboardFilter'

const LicenseDashboard = () => {
  const [relationships, setRelationships] = useState([])
  const [relationshipsLoading, setRelationshipsLoading] = useState(false)
  const [filteredRelationships, setFilteredRelationships] = useState([])

  useEffect(() => {
    const getRelationships = async () => {
      setRelationshipsLoading(true)
      const relationships = await api.getStudentRelationships({
        useStream: false,
        partnerType: 'high school'
      })
      const coursesLicenses = await api.getCoursesLicenses()
      const relationShipData = relationships
        .sort((a, b) => a.relationshipName.localeCompare(b.relationshipName))
        .map(
          relationship => {
            return {
              id: relationship.id,
              school: relationship.relationshipName,
              district: relationship.districtName,
              licenses: coursesLicenses?.filter(({ relationshipName }) =>
                relationshipName === relationship.relationshipName)
            }
          }
        )

      setRelationships(relationShipData)
      setRelationshipsLoading(false)
    }
    getRelationships()
  }, [])

  if (relationshipsLoading) return <LoadingSpinner />

  return (
    <>
      <LicenseDashboardFilter
        relationships={relationships}
        setFilteredRelationships={setFilteredRelationships}
      />
      <LicenseDashboardTable
        relationships={filteredRelationships}
      />
    </>
  )
}

export default LicenseDashboard

import React, { useState } from 'react'
import {
  PageIndex,
  PageIndicator,
  PaginationContainer
} from './styles/index.styled'

const PaginationBar = ({ partnersCount, numberOfPages, pageChangeHandler }) => {
  const [selectedPage, setSelectedPage] = useState(1)

  const getPagesIndexes = () => {
    if (numberOfPages === 0) return [0, 0]
    const pageFirstItemIndex = 1 + (20 * selectedPage) - 20
    const projectedLastItemIndex = pageFirstItemIndex + 19
    const pageLastItemIndex = projectedLastItemIndex > partnersCount
      ? partnersCount
      : projectedLastItemIndex
    return [pageFirstItemIndex, pageLastItemIndex]
  }

  const pageList = Array.from(
    { length: numberOfPages }, (_, i) => i + 1
  ) || []

  const [pageFirstItemIndex, pageLastItemIndex] = getPagesIndexes()

  return (
    <PaginationContainer>
      {pageList.map(page => (
        <PageIndex
          key={page}
          onClick={() => {
            setSelectedPage(page)
            pageChangeHandler(page - 1)
          }}
          selected={page === selectedPage}
          data-testid={`page-${page}`}
        >
          {page}
        </PageIndex>
      ))}
      <PageIndicator data-testid='pageIndicator'>
        {pageFirstItemIndex}-{pageLastItemIndex} of {partnersCount} items
      </PageIndicator>
    </PaginationContainer>
  )
}

PaginationBar.displayName = 'PaginationBar'
export default PaginationBar
